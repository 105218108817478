import React from 'react'
import { components } from 'react-select'

import { IconChevronDown } from '@tabler/icons-react'

import { Dropdown } from './styles'

const DropdownIndicator: typeof components.DropdownIndicator = props => {
  // TODO: find a better way to satisfy the type
  const StyledDropdown = Dropdown as any
  return (
    <StyledDropdown {...props} as={components.DropdownIndicator}>
      <IconChevronDown size={16} />
    </StyledDropdown>
  )
}

export default DropdownIndicator
