import React from 'react'

import { pick } from '@styled-system/props'

import Text from 'Components/UI/Text'

import { Container, IContainer } from './styles'

export interface IBadge extends IContainer {
  counter?: string | number
}

const Badge = ({ counter, large, small, ...rest }: IBadge) => {
  return (
    <Container {...pick(rest)} large={large} small={small}>
      {large && counter && (
        <Text contrast header5>
          {counter}
        </Text>
      )}
    </Container>
  )
}

export default Badge
