export function wrapSymbolWithSpan(value: string, symbol: string) {
  const escapedSymbol = symbol.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')
  return value.replace(
    new RegExp(`(${escapedSymbol})`, 'gi'),
    `<span>$1</span>`,
  )
}

const IMAGE_COUNT = 10

export function getDefaultAvatar(): string {
  const index = Math.floor(Math.random() * IMAGE_COUNT)
  return `/img/avatars/community${index + 1}.svg`
}

export function resizeImageUrl(
  url?: string,
  size: 'Full' | '128' | '512' = '128',
): string | undefined {
  if (!url) return undefined

  const extensionIndex = url.lastIndexOf('.')
  if (extensionIndex === -1) {
    return url // No extension found, return original url
  }

  const prefix = url.slice(0, extensionIndex)
  const extension = url.slice(extensionIndex)

  return size === 'Full' ? url : `${prefix}_${size}${extension}`
}
