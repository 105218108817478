import React, { useCallback, useMemo, useRef, useState } from 'react'
import { Form } from 'react-final-form'
import { PropTypes } from 'prop-types'

import inviteUserByEmailMutation from 'GraphQL/Mutations/User/inviteUserByEmail.graphql'
import validate from 'validate.js'

import get from 'lodash/get'
import noop from 'lodash/noop'

import { Button, InputField, Loader, Modal, Row } from 'Components/UI'

import { USER_ROLE } from 'Constants/ids'

import { useAdminMutation } from 'Services/Apollo'
import _ from 'Services/I18n'
import toast from 'Services/Toast'

import { Content, Subtitle } from './styles'

const FIELD = {
  EMAIL: 'email',
  ROLE: 'role',
  FIRST_NAME: 'firstName',
  LAST_NAME: 'lastName',
}

function InviteUserModal({ isOpen, onClose }) {
  const [loading, setLoading] = useState(false)

  const close = useRef(null)

  const [inviteUser] = useAdminMutation(inviteUserByEmailMutation)

  const formConstraints = useMemo(
    () => ({
      [FIELD.EMAIL]: {
        presence: {
          presence: true,
          message: `^email is required`,
        },
        email: {
          email: true,
          message: `^invalid email`,
        },
      },
    }),
    [],
  )

  const handleMount = useCallback(instance => {
    close.current = get(instance, 'handleClose')
  }, [])

  const submit = useCallback(
    async values => {
      setLoading(true)

      try {
        await inviteUser({
          variables: {
            email: values.email,
            role: USER_ROLE.SUPER_ADMIN,
            firstName: values.firstName,
            lastName: values.lastName,
          },
        })

        toast.success({
          title: 'Invite user',
          text: `User ${values.email} successfully invited`,
        })

        onClose()
      } catch (error) {
        toast.error({
          title: 'Invite user',
          text: _(`error.${error?.message || 'generic'}`),
        })
      } finally {
        setLoading(false)
      }
    },
    [onClose, inviteUser],
  )

  return (
    <Modal
      isOpen={isOpen}
      shouldCloseOnOverlayClick={false}
      title="Invite SuperAdmin"
      width="400px"
      onClose={onClose}
      onMount={handleMount}
    >
      <Form
        render={({ handleSubmit }) => (
          <>
            <Content>
              <Subtitle my={3}>You can invite SuperAdmin</Subtitle>

              <InputField
                label="Email"
                mb={3}
                name={FIELD.EMAIL}
                required
                type="email"
                width={1}
              />

              <InputField
                label="First Name (optional)"
                mb={3}
                name={FIELD.FIRST_NAME}
                width={1}
              />

              <InputField
                label="Last Name (optional)"
                mb={4}
                name={FIELD.LAST_NAME}
                width={1}
              />
            </Content>

            <Row center mt={2} spaceBetween>
              {loading ? (
                <Loader />
              ) : (
                <>
                  <Button secondary small onClick={onClose}>
                    Cancel
                  </Button>
                  <Button small onClick={handleSubmit}>
                    Invite
                  </Button>
                </>
              )}
            </Row>
          </>
        )}
        validate={values => validate(values, formConstraints)}
        onSubmit={submit}
      />
    </Modal>
  )
}

InviteUserModal.defaultProps = {
  isOpen: false,
  onClose: noop,
}

InviteUserModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
}

export default InviteUserModal
