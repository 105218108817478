import { css } from 'styled-components'

const flexCenter = css`
  display: flex;
  justify-content: center;
  align-items: center;
`

const resetButton = () => css`
  margin: 0;
  padding: 0;
  background: transparent;
  border: unset;
`

export { flexCenter, resetButton }
