import React, { useMemo } from 'react'

import {
  IconAlertTriangle,
  IconCheck,
  IconInfoCircle,
  IconX,
} from '@tabler/icons-react'

import { Column } from 'Components/UI/Flex'
import Text from 'Components/UI/Text'

import { TOAST_KIND } from 'Constants/toasts'

import { Container } from './styles'

export interface IToast {
  kind?: string
  text: React.ReactNode
  title: React.ReactNode
}

function Toast({ title, text, kind = TOAST_KIND.SUCCESS }: IToast) {
  const icon = useMemo(() => {
    switch (kind) {
      case TOAST_KIND.SUCCESS: {
        return <IconCheck />
      }
      case TOAST_KIND.INFO: {
        return <IconInfoCircle />
      }
      case TOAST_KIND.WARNING: {
        return <IconAlertTriangle />
      }
      default: {
        return <IconX />
      }
    }
  }, [kind])

  return (
    <Container
      error={kind === TOAST_KIND.ERROR}
      info={kind === TOAST_KIND.INFO}
      warning={kind === TOAST_KIND.WARNING}
    >
      {icon}
      <Column gap="2px">
        <Text header header4>
          {title || 'Please set title!'}
        </Text>
        <Text body bodySmall>
          {text || ''}
        </Text>
      </Column>
    </Container>
  )
}

export default Toast
