import React from 'react'

import { Badge, Column, Row, Text } from 'Components/UI'

function Badges() {
  return (
    <Column gap={6}>
      <Text header1>Badges</Text>
      <Row center gap={5}>
        <Badge counter={9229} large />
        <Badge counter={99} large />
        <Badge counter={12} />
        <Badge counter={12} small />
      </Row>
    </Column>
  )
}

export default Badges
