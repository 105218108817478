import React, { useCallback, useMemo, useRef } from 'react'
import PropTypes from 'prop-types'

import { IconButton, Row, Tooltip } from 'Components/UI'

import { DeleteIcon, EditIcon, UpdateIcon } from './styles'

import { useTableContext } from '../context'

function ActionsCell({ row }) {
  const editRef = useRef(null)
  const deleteRef = useRef(null)
  const updateRoleRef = useRef(null)

  const user = useMemo(() => row?.original, [row])

  const { onDeleteRow, onEditUser, onUpdateCommunityUsersRole } =
    useTableContext()

  const handleDelete = useCallback(() => {
    deleteRef.current.blur()
    onDeleteRow(user)
  }, [onDeleteRow, user])

  const handleEdit = useCallback(() => {
    editRef.current.blur()
    onEditUser(user)
  }, [onEditUser, user])

  const handleUpdateRole = useCallback(() => {
    updateRoleRef.current.blur()
    onUpdateCommunityUsersRole(user)
  }, [onUpdateCommunityUsersRole, user])

  return (
    <Row>
      <Tooltip content="Edit user">
        <IconButton mr={2} ref={editRef} small onClick={handleEdit}>
          <EditIcon />
        </IconButton>
      </Tooltip>

      <Tooltip content="Update user role">
        <IconButton mr={2} ref={updateRoleRef} small onClick={handleUpdateRole}>
          <UpdateIcon />
        </IconButton>
      </Tooltip>

      <Tooltip content="Delete user">
        <IconButton mr={2} ref={deleteRef} small onClick={handleDelete}>
          <DeleteIcon />
        </IconButton>
      </Tooltip>
    </Row>
  )
}

ActionsCell.defaultProps = {
  row: {},
}

ActionsCell.propTypes = {
  row: PropTypes.object,
}

export default ActionsCell
