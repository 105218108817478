import React from 'react'

import { Column, Row, Text } from 'Components/UI'

import { Box } from './styles'

function Backgrounds() {
  return (
    <Column gap={6}>
      <Text header1>Backgrounds</Text>
      <Row fullWidth gap={5}>
        <Box body>body</Box>
        <Box disabled>disabled</Box>
        <Box overlay>overlay</Box>
        <Box overlayPopUp>overlayPopUp</Box>
        <Box tooltip>tooltip</Box>
      </Row>

      <Row fullWidth gap={5}>
        <Box info>info</Box>
        <Box success>success</Box>
        <Box warning>warning</Box>
        <Box error>error</Box>
      </Row>

      <Row fullWidth gap={5}>
        <Box winterLakes>winterLakes</Box>
        <Box skylar>skylar</Box>
        <Box hokkaidoLavender>hokkaidoLavender</Box>
        <Box pasadenaRose>pasadenaRose</Box>
        <Box goldenSpice>goldenSpice</Box>
        <Box shutterCopper>shutterCopper</Box>
        <Box clematisGreen>clematisGreen</Box>
      </Row>
    </Column>
  )
}

export default Backgrounds
