import { useCallback, useMemo, useState } from 'react'

export interface IModal<TEntity> {
  isOpen: boolean
  entity: TEntity | undefined
}

export interface IEntityModalActions<TEntity> {
  closeModal: (success?: boolean) => Promise<void>
  openModal: (entityOrEvent?: TEntity | undefined) => Promise<void>
}

export interface IEntityModal<TEntity> {
  onClose?: (props: {
    modal: IModal<TEntity>
    success: boolean
  }) => Promise<Partial<IModal<TEntity>>>
  onOpen?: (props: {
    modal: IModal<TEntity>
    entityOrEvent: unknown
  }) => Promise<Partial<IModal<TEntity>>>
}

const placeholderResolve = async () => {
  return Promise.resolve({})
}

export default function useEntityModal<TEntity>({
  onClose = placeholderResolve,
  onOpen = placeholderResolve,
}: IEntityModal<TEntity> = {}): [
  IModal<TEntity>,
  IEntityModalActions<TEntity>,
] {
  const [modal, setModal] = useState<IModal<TEntity>>({
    isOpen: false,
    entity: undefined,
  })

  const handleClose = useCallback(
    async (success: boolean = true) => {
      const overrides = await onClose({ modal, success })

      setModal({
        isOpen: false,
        entity: undefined,
        ...overrides,
      })
    },
    [onClose, modal],
  )

  const handleOpen = useCallback(
    async (entityOrEvent: TEntity | undefined) => {
      const overrides = await onOpen({ modal, entityOrEvent })

      setModal({
        isOpen: true,
        entity:
          entityOrEvent &&
          typeof entityOrEvent === 'object' &&
          'target' in entityOrEvent
            ? undefined
            : entityOrEvent,
        ...overrides,
      })
    },
    [onOpen, modal],
  )

  const entityModal = useMemo<
    [IModal<TEntity>, IEntityModalActions<TEntity>]
  >(() => {
    return [
      modal,
      {
        closeModal: handleClose,
        openModal: handleOpen,
      },
    ]
  }, [modal, handleClose, handleOpen])

  return entityModal
}
