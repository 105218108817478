import styled from 'styled-components'
import { themeGet } from '@styled-system/theme-get'

export const ChartContainer = styled.div`
  display: flex;
  flex-grow: 1;
  width: 100%;
  position: relative;
  padding-inline: ${themeGet('space.5')}px;
`

export const TooltipWrapper = styled.div`
  display: flex;
  top: 300px;
  justify-content: center;
  align-items: center;
  background-color: ${themeGet('colors.white')};
  width: 48px;
  height: 20px;
  border-radius: 10px;
  box-shadow: 0 2px 15px 0 rgba(52, 52, 52, 0.14);
`
