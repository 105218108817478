import React, { forwardRef, useCallback } from 'react'

import { useApolloClient, useMutation, useQuery } from '@apollo/client'
import updateGraphSnapshotMutation from 'GraphQL/Mutations/GraphSnapshot/updateGraphSnapshot.graphql'
import graphSnapshotQuery from 'GraphQL/Queries/GraphSnapshot/graphSnapshot.graphql'
import graphSnapshotsQuery from 'GraphQL/Queries/GraphSnapshot/graphSnapshots.graphql'

import {
  ContextMenu,
  ContextMenuItem,
  ContextMenuItemHeader,
  Text,
  Tooltip,
} from 'Components/UI'

import { useAppContext, useGraphContext } from 'Hooks'

import EventBus from 'Services/EventBus'
import _, { useScopedI18n } from 'Services/I18n'
import toast from 'Services/Toast'

import colors from 'Theme/Main/colors'

import * as Styled from './styles'
import utils from './utils'

const CONTEXT_GRAPH_SNAPSHOTS_FIELD = {
  RECENTLY_ADDED: 'recentlyAdded',
  SAVE_GRAPH_SNAPSHOT: 'saveGraphSnapshot',
  SAVE_AS_GRAPH_SNAPSHOT: 'saveAsGraphSnapshot',
  VIEW_GRAPH_SNAPSHOTS: 'viewGraphSnapshots',
  VIEW_GRAPH_SNAPSHOT: (graphSnapshotId: string) =>
    `viewGraphSnapshots-${graphSnapshotId}`,
  MANAGE_GRAPH_SNAPSHOTS: 'manageGraphSnapshots',
  CREATE_NEW_GRAPH_SNAPSHOT: 'createNewGraphSnapshot',
}

interface GraphSnapshotContextMenuProps {
  isOpen: boolean
  left: number | string
  top: number | string
  right?: number | string
  setShowGraphSnapshotsMenu: React.Dispatch<React.SetStateAction<boolean>>
}

const GraphSnapshotContextMenu = forwardRef<
  HTMLDivElement,
  GraphSnapshotContextMenuProps
>(({ isOpen, left, top, right, setShowGraphSnapshotsMenu }, ref) => {
  const { communityUser } = useAppContext()
  const {
    setIsLoading,
    currentGraphSnapshotId,
    setCurrentGraphSnapshotId,
    loadGraphState,
    setSavedLoadGraphState,
    graphState,
    graphMapper,
  } = useGraphContext()
  const t = useScopedI18n('features.graphSnapshot')
  const client = useApolloClient()

  const { data: graphSnapshotsData } = useQuery<
    Pick<MainSchema.Query, 'graphSnapshots'>,
    MainSchema.QueryGraphSnapshotsArgs
  >(graphSnapshotsQuery, {
    variables: communityUser
      ? {
          communityId: communityUser.communityId,
        }
      : undefined,
    skip: !communityUser,
    fetchPolicy: 'network-only',
  })
  const graphSnapshots = graphSnapshotsData?.graphSnapshots ?? []
  const currentGraphSnapshot = graphSnapshots.find(
    graphSnapshot => graphSnapshot.id === currentGraphSnapshotId,
  )
  const currentGraphSnapshotName = currentGraphSnapshot?.name

  const [updateGraphSnapshot] = useMutation<
    Pick<MainSchema.Mutation, 'updateGraphSnapshot'>,
    MainSchema.MutationUpdateGraphSnapshotArgs
  >(updateGraphSnapshotMutation)

  const handleSaveGraphSnapshot = useCallback(
    async (id: MainSchema.GraphSnapshot['id']) => {
      setShowGraphSnapshotsMenu(false)
      setIsLoading(true)

      try {
        const state = utils.generateGraphSnapshotStateFromGraphState(graphState)

        await updateGraphSnapshot({
          variables: {
            id,
            state: state.json,
            stateVersion: state.version,
          },
        })

        setSavedLoadGraphState(loadGraphState)

        toast.success({
          title: t('toast.title'),
          text: t('toast.saved'),
        })
      } catch (error) {
        let message = _('error.generic')

        if (error instanceof Error) {
          message = _(`error.${error.message || 'generic'}`)
        }

        toast.error({
          title: t('toast.title'),
          text: message,
        })
      } finally {
        setIsLoading(false)
      }
    },
    [
      graphState,
      t,
      updateGraphSnapshot,
      setIsLoading,
      setShowGraphSnapshotsMenu,
      setSavedLoadGraphState,
      loadGraphState,
    ],
  )

  const handleViewGraphSnapshot = useCallback(
    async (id: MainSchema.GraphSnapshot['id']) => {
      if (!communityUser) {
        throw new Error('Community user not found')
      }

      setShowGraphSnapshotsMenu(false)
      setCurrentGraphSnapshotId(id)
      setIsLoading(true)

      try {
        const graphSnapshotResult = await client.query({
          query: graphSnapshotQuery,
          variables: {
            id,
          },
        })

        const graphSnapshot = graphSnapshotResult.data?.graphSnapshot

        if (!graphSnapshot) {
          throw new Error('Graph snapshot not found')
        }

        const loadGraphState =
          await utils.generateLoadGraphStateFromGraphSnapshotState(
            communityUser.communityId,
            {
              json: graphSnapshot.state,
              version: graphSnapshot.stateVersion,
            },
            communityUser.userId,
          )
        setSavedLoadGraphState(loadGraphState)

        graphMapper.handleLoadGraphState(loadGraphState)

        toast.success({
          title: t('toast.title'),
          text: t('toast.loaded'),
        })
      } catch (error) {
        let message = _('error.generic')

        if (error instanceof Error) {
          message = _(`error.${error.message || 'generic'}`)
        }

        toast.error({
          title: t('toast.title'),
          text: message,
        })
      } finally {
        setIsLoading(false)
      }
    },
    [
      setCurrentGraphSnapshotId,
      setSavedLoadGraphState,
      t,
      client,
      graphMapper,
      setIsLoading,
      setShowGraphSnapshotsMenu,
      communityUser,
    ],
  )

  const handleManageGraphSnapshots = useCallback(() => {
    setShowGraphSnapshotsMenu(false)
    EventBus.trigger(EventBus.actions.dashboard.manageGraphSnapshots)
  }, [setShowGraphSnapshotsMenu])

  const handleCreateNewGraphSnapshot = useCallback(() => {
    setShowGraphSnapshotsMenu(false)
    EventBus.trigger(EventBus.actions.dashboard.createGraphSnapshot)
  }, [setShowGraphSnapshotsMenu])

  return (
    <div ref={ref}>
      <Styled.Dropdown
        aria-controls={isOpen ? 'graph-menu' : undefined}
        aria-expanded={isOpen}
        aria-haspopup="true"
        isOpen={isOpen}
        onClick={() => setShowGraphSnapshotsMenu(prevState => !prevState)}
      >
        <Tooltip content={_('tips.graphSnapshots')}>
          <Styled.DropdownText>
            <Text
              captionSmall
              color={colors.text.placeholder}
              fontWeight="normal"
            >
              {t('graphSnapshotContextMenu.graphTitle')}
            </Text>
            <Text header4>
              {currentGraphSnapshotName ||
                t('graphSnapshotContextMenu.recentlyAdded')}
            </Text>
          </Styled.DropdownText>
        </Tooltip>
        <Styled.ChevronIcon open={isOpen} />
      </Styled.Dropdown>

      <ContextMenu isOpen={isOpen} left={left} right={right} top={top}>
        <ContextMenuItem
          canBeFavorited
          id={CONTEXT_GRAPH_SNAPSHOTS_FIELD.RECENTLY_ADDED}
          isDisabled
          label={t('graphSnapshotContextMenu.recentlyAdded')}
          showDivider
        />
        {graphSnapshots.length > 0 && (
          <>
            <ContextMenuItemHeader>
              {t('graphSnapshotContextMenu.savedGraphs')}
            </ContextMenuItemHeader>

            {graphSnapshots.map((graphSnapshot, index) => (
              <ContextMenuItem
                canBeFavorited
                id={CONTEXT_GRAPH_SNAPSHOTS_FIELD.VIEW_GRAPH_SNAPSHOT(
                  graphSnapshot.id,
                )}
                isDefault={graphSnapshot.id === currentGraphSnapshotId}
                key={graphSnapshot.id}
                label={graphSnapshot.name}
                showDivider={index === graphSnapshots.length - 1}
                onClick={() => handleViewGraphSnapshot(graphSnapshot.id)}
              />
            ))}

            {currentGraphSnapshotId && (
              <ContextMenuItem
                iconPrefix="save"
                id={CONTEXT_GRAPH_SNAPSHOTS_FIELD.SAVE_GRAPH_SNAPSHOT}
                label={t('graphSnapshotContextMenu.saveGraphSnapshot', {
                  name: currentGraphSnapshot?.name,
                })}
                onClick={() => handleSaveGraphSnapshot(currentGraphSnapshotId)}
              />
            )}

            <ContextMenuItem
              iconPrefix="saveAs"
              id={CONTEXT_GRAPH_SNAPSHOTS_FIELD.SAVE_AS_GRAPH_SNAPSHOT}
              label={t('graphSnapshotContextMenu.saveAsGraphSnapshot')}
              showDivider
              onClick={handleCreateNewGraphSnapshot}
            />

            <ContextMenuItem
              iconPrefix="gear"
              id={CONTEXT_GRAPH_SNAPSHOTS_FIELD.MANAGE_GRAPH_SNAPSHOTS}
              label={t('graphSnapshotContextMenu.manageGraphSnapshots')}
              showDivider
              onClick={handleManageGraphSnapshots}
            />
          </>
        )}

        <Styled.DropdownDivider />
        <ContextMenuItem
          iconPrefix="plusSimple"
          id={CONTEXT_GRAPH_SNAPSHOTS_FIELD.CREATE_NEW_GRAPH_SNAPSHOT}
          label={t('graphSnapshotContextMenu.createNewGraphSnapshot')}
          onClick={handleCreateNewGraphSnapshot}
        />
      </ContextMenu>
    </div>
  )
})

export default GraphSnapshotContextMenu
