import React, { useRef, useState } from 'react'

import CreateCommunityUserModal from 'Components/Blocks/Modals/CreateCommunityUser'
import {
  ContextMenu,
  ContextMenuItem,
  ContextMenuItemHeader,
} from 'Components/UI'

import { useEntityModal, useOnClickOutside } from 'Hooks'

import { useScopedI18n } from 'Services/I18n'

import * as Styled from './styles'

import { IconButtonStyled } from '../styles'

const CreateNewButton = () => {
  const t = useScopedI18n(
    'components.blocks.communityDashboard.createNewButton',
  )
  const ref = useRef<HTMLDivElement>(null)
  const [isOpen, setIsOpen] = useState(false)

  const [createModal, createActions] = useEntityModal()
  const handleCreateModalOpen = createActions.openModal
  const handleCreateModalClose = createActions.closeModal

  useOnClickOutside(ref, () => setIsOpen(false))

  return (
    <>
      <Styled.Wrapper ref={ref}>
        <IconButtonStyled
          tertiary
          onClick={() => setIsOpen(prevState => !prevState)}
        >
          <Styled.PlusIcon />
        </IconButtonStyled>

        <ContextMenu isOpen={isOpen} left="auto" right={0} top="auto">
          <ContextMenuItemHeader>{t('createNew')}</ContextMenuItemHeader>
          <ContextMenuItem
            iconPrefix="contacts"
            id="CREATE_USER"
            label={t('contact')}
            onClick={handleCreateModalOpen}
          />
        </ContextMenu>
      </Styled.Wrapper>
      <CreateCommunityUserModal
        isOpen={createModal.isOpen}
        onClose={handleCreateModalClose}
      />
    </>
  )
}

export default CreateNewButton
