export interface IBadgeSizes {
  large?: number
  default?: number
  small?: number
}

export interface IBadges {
  size: IBadgeSizes
  borderRadius: IBadgeSizes
  padding: IBadgeSizes
}

const badges: IBadges = {
  size: { large: 20, default: 12, small: 9 },

  borderRadius: { large: 10, default: 6, small: 5 },

  padding: { large: 2, default: 0, small: 0 },
}

export default badges
