const inputs = {
  height: { default: 40, textAreaSmall: 118, textAreaLarge: 200 },

  minWidth: { default: 100 },

  borderRadius: { default: 4 },

  fontSize: { default: 14 },

  iconSize: { default: 16 },
}

export default inputs
