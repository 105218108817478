import React from 'react'

import { FiExternalLink } from 'react-icons/fi'

import { RenderElementProps } from 'slate-react'
import { ElementType } from 'Types/slate'

import Tooltip from 'Components/UI/Tooltip'

import { ExternalLink } from './styles'

export interface IElementProps extends RenderElementProps {}

function Element({ attributes, children, element }: IElementProps) {
  switch (element.type) {
    case ElementType.Link: {
      const url = element.url as string

      return (
        <Tooltip
          appendTo={document.body}
          content={
            <ExternalLink href={url} rel="noopener noreferrer" target="_blank">
              <FiExternalLink />
              {url}
            </ExternalLink>
          }
          interactive
        >
          <a
            {...attributes}
            href={url}
            rel="noopener noreferrer"
            target="_blank"
          >
            {children}
          </a>
        </Tooltip>
      )
    }
    case ElementType.Code:
      return <pre {...attributes}>{children}</pre>
    case ElementType.Quote:
      return <blockquote {...attributes}>{children}</blockquote>
    case ElementType.OrderedList:
      return <ol {...attributes}>{children}</ol>
    case ElementType.UnorderedList:
      return <ul {...attributes}>{children}</ul>
    case ElementType.ListItem:
      return <li {...attributes}>{children}</li>
    default:
      return <p {...attributes}>{children}</p>
  }
}

export default Element
