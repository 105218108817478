import { ApolloClient } from '@apollo/client'
import expandKnowledgeQuery from 'Features/KnowledgeGraphing/Queries/ExpandKnowledge.graphql'

export const onKnowledgeNodeDoubleClick = async (
  client: ApolloClient<object>,
  node: MainSchema.KnowledgeGraphNode,
  communityId?: string,
): Promise<MainSchema.KnowledgeGraphNode[] | undefined> => {
  const result = await client.query<
    Pick<MainSchema.Query, 'expandKnowledge'>,
    MainSchema.QueryExpandKnowledgeArgs
  >({
    query: expandKnowledgeQuery,
    fetchPolicy: 'network-only',
    variables: {
      originNode: {
        id: node.id,
        kind: node.kind,
        text: node.valueString,
      },
    },
    context: {
      headers: {
        'x-community-id': communityId,
      },
    },
  })

  return result?.data?.expandKnowledge.nodes
}
