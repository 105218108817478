import { CgClose } from 'react-icons/cg'
import { IoReload } from 'react-icons/io5'

import styled from 'styled-components'
import { margin, MarginProps } from 'styled-system'
import themeGet from '@styled-system/theme-get'

export interface IDeleteIconProps extends MarginProps {}

export const DeleteIcon = styled(CgClose)<IDeleteIconProps>`
  color: ${themeGet('colors.grayBoulder')};
  ${margin}
`

export interface IDeletedUserIconProps extends MarginProps {}

export const DeletedUserIcon = styled(IoReload)<IDeletedUserIconProps>`
  color: ${themeGet('colors.primaryCardinal')};
  transform: rotate(180deg);
  ${margin}
`
