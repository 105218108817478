import styled from 'styled-components'
import { mapToTheme } from 'styled-map'
import {
  FlexShrinkProps,
  layout,
  LayoutProps,
  margin,
  MarginProps,
} from 'styled-system'
import { themeGet } from '@styled-system/theme-get'

export interface IWrapper extends LayoutProps, MarginProps, FlexShrinkProps {}

export const Wrapper = styled.div<IWrapper>`
  width: 100%;

  input {
    font-size: ${themeGet('fontSize.2')}px;
    font-weight: ${themeGet('fontWeight.0')};
  }

  & .react-select-container {
    background-color: ${themeGet('colors.bg.primary')};

    & .react-select__control {
      border: 1px solid ${themeGet('colors.divider.default')};
      border-radius: ${mapToTheme('selects.borderRadius')}px;
      min-height: ${mapToTheme('selects.height')}px;

      &:hover {
        border-color: ${themeGet('colors.divider.hover')} !important;

        & .react-select__dropdown-indicator {
          svg {
            color: ${themeGet('colors.text.header')};
          }
        }
      }

      &--has-error {
        border-color: ${themeGet('colors.error')} !important;
      }
    }

    &:active,
    :focus-within {
      & .react-select__control {
        border-color: ${themeGet('colors.divider.hover')} !important;
        box-shadow: ${themeGet('shadows.secondaryFocus')} !important;

        & .react-select__dropdown-indicator {
          svg {
            color: ${themeGet('colors.text.header')};
          }
        }
      }
    }
  }

  & .react-select__control--is-disabled {
    cursor: default;
    color: ${themeGet('colors.text.disabled')};
    border-color: ${themeGet('colors.divider.disabled')} !important;
    background-color: ${themeGet('colors.bg.primary')} !important;

    &:hover {
      border-color: ${themeGet('colors.divider.disabled')} !important;
    }

    div {
      color: ${themeGet('colors.text.disabled')};
    }
  }

  & .react-select__dropdown-indicator {
    svg {
      height: 16px;
      width: 16px;
      color: ${themeGet('colors.text.disabled')};
      transition: all ${themeGet('transitionTime.default')};
    }
  }

  & .react-select__control--menu-is-open {
    .react-select__dropdown-indicator {
      svg {
        transform: rotateZ(180deg);
      }
    }
  }

  & .react-select__value-container--is-multi {
    gap: ${themeGet('space.1')}px;
  }

  & .react-select__multi-value {
    background: none;
    margin: 0;

    & .react-select__multi-value__remove {
      padding: 0;
      color: ${themeGet('colors.text.header')};
      cursor: pointer;

      svg {
        height: 16px;
        width: 16px;
      }

      &:hover {
        background: none;
      }
    }

    & .react-select__multi-value__label {
      font-size: ${themeGet('fontSize.2')}px;
      font-weight: ${themeGet('fontWeight.0')};
      color: ${themeGet('colors.text.header')};
      padding: 0;
    }
  }

  ${layout};
  ${margin};
`
