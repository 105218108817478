import React, { useCallback, useRef, useState } from 'react'
import { useDropzone } from 'react-dropzone'

import { IconCamera, IconPencil, IconTrash } from '@tabler/icons-react'
import { fileToDataURL } from 'Utils/Files'

import noop from 'lodash/noop'

import { Text } from 'Components/UI/_v2'
import Avatar from 'Components/UI/Avatar'
import { Row } from 'Components/UI/Flex'
import Loader from 'Components/UI/Loader'
import Modal from 'Components/UI/Modal'

import { UPLOAD_TYPES, UploadType } from 'Constants/ids'

import useOnClickOutside from 'Hooks/useOnClickOutside'

import { uploadFile } from 'Services/Api/Queries/upload'

import colors from 'Theme/_v2/colors'

import * as Styled from './styles'

export interface IAvatarInput {
  disabled?: boolean
  entityId?: string
  entityText?: string
  uploadType?: UploadType
  value?: string
  onChange?: (url: string | null) => void
}

function AvatarInput({
  disabled = false,
  value,
  entityId,
  entityText,
  uploadType = UPLOAD_TYPES.profilePhoto,
  onChange,
}: IAvatarInput) {
  const menuContainerRef = useRef<HTMLDivElement>(null)
  const [uploadingFile, setUploadingFile] = useState<string | null>(null)
  const [isLoading, setIsLoading] = useState(false)
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)

  const handleUploadFile = useCallback(
    async (file: File) =>
      uploadFile({
        file,
        type: uploadType,
        entityId,
        onProgress: noop,
      }),
    [entityId, uploadType],
  )

  const handleDrop = useCallback(
    async ([acceptedFile]: File[]) => {
      if (!acceptedFile) return

      setIsLoading(true)

      fileToDataURL(acceptedFile, setUploadingFile)

      const response = await handleUploadFile(acceptedFile)

      if (response.ok) {
        onChange?.(response.url)
      }

      setIsLoading(false)
      setUploadingFile(null)
    },
    [setUploadingFile, handleUploadFile, onChange],
  )

  const handleCloseDeleteModal = useCallback(() => {
    setIsDeleteModalOpen(false)
  }, [])

  const handleDeleteImage = useCallback(() => {
    onChange?.(null)
    handleCloseDeleteModal()
  }, [handleCloseDeleteModal, onChange])

  const handlePressMenuToggle = useCallback(() => {
    setIsMenuOpen(!isMenuOpen)
  }, [isMenuOpen])

  const { getRootProps, getInputProps, isDragActive, open } = useDropzone({
    onDrop: handleDrop,
    accept: {
      'image/*': ['.jpeg', '.jpg', '.png'],
    },
    disabled: isLoading || disabled,
  })

  useOnClickOutside(menuContainerRef, () => setIsMenuOpen(false))

  const handlePressEdit = useCallback(() => {
    open()
    setIsMenuOpen(false)
  }, [open])

  const handlePressDelete = useCallback(() => {
    setIsDeleteModalOpen(true)
    setIsMenuOpen(false)
  }, [])

  const src = uploadingFile || value
  const isMenuDisabled = isDragActive || disabled

  return (
    <Row>
      <Styled.AvatarContainer>
        <Styled.Dropzone {...getRootProps()} isDragActive={isDragActive}>
          <input {...getInputProps()} />

          <Avatar profileInput src={src} text={entityText} />
        </Styled.Dropzone>

        {!isMenuDisabled && (
          <Styled.MenuContainer ref={menuContainerRef}>
            <Styled.MenuToggleButton
              disabled={isLoading}
              isOpen={isMenuOpen}
              onClick={handlePressMenuToggle}
            >
              {isLoading && <Loader />}
              {!isLoading && <IconCamera size={16} />}
            </Styled.MenuToggleButton>

            {isMenuOpen && (
              <Styled.Menu>
                <Styled.MenuItem>
                  <Styled.MenuItemButton onClick={handlePressEdit}>
                    <IconPencil size={16} />
                    <Text variant="body-s">Edit</Text>
                  </Styled.MenuItemButton>
                </Styled.MenuItem>
                {!!src && (
                  <Styled.MenuItem>
                    <Styled.MenuItemButton onClick={handlePressDelete}>
                      <IconTrash size={16} />
                      <Text variant="body-s">Delete</Text>
                    </Styled.MenuItemButton>
                  </Styled.MenuItem>
                )}
              </Styled.Menu>
            )}
          </Styled.MenuContainer>
        )}
      </Styled.AvatarContainer>

      <Modal
        cancelText="Cancel"
        confirmText="Remove"
        isOpen={isDeleteModalOpen}
        title="Remove image?"
        onClose={handleCloseDeleteModal}
        onConfirm={handleDeleteImage}
      >
        <Text color={colors.text.primary} variant="body-s">
          Are you sure you want to delete the image?
        </Text>
      </Modal>
    </Row>
  )
}

export default AvatarInput
