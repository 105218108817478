import React, { useCallback, useMemo, useState } from 'react'
import { Field, Form, FormRenderProps } from 'react-final-form'

import { useMutation, useQuery } from '@apollo/client'
import listOAuthProvidersQuery from 'GraphQL/Queries/User/listOAuthProviders.graphql'
import validate from 'validate.js'

import loadingImage from 'Assets/Images/loading.gif'

import {
  Checkbox,
  CheckboxField,
  Column,
  Modal,
  SearchInput,
} from 'Components/UI'
import { Text } from 'Components/UI/_v2'

import _, { useScopedI18n } from 'Services/I18n'
import toast from 'Services/Toast'

import colors from 'Theme/_v2/colors'

import setOAuthMonitoredFoldersMutation from '../Mutations/setOAuthMonitoredFolders.graphql'
import listOAuthFoldersQuery from '../Queries/listOAuthFolders.graphql'

enum SelectLabelsFormField {
  Search = 'search',
  LabelIds = 'labelIds',
}

interface SelectLabelsFormValues {
  [SelectLabelsFormField.Search]: string
  [SelectLabelsFormField.LabelIds]: string[]
}

export interface SelectLabelsModalProps {
  userOAuth: MainSchema.UserOAuth
  isOpen: boolean
  onClose: (success: boolean) => void
}

const SelectLabelsModal: React.FC<SelectLabelsModalProps> = ({
  userOAuth,
  isOpen,
  onClose,
}) => {
  const t = useScopedI18n('features.oauth.view.selectLabelsModal')

  const { data: listOAuthFoldersData, loading: isListOAuthFoldersLoading } =
    useQuery<
      Pick<MainSchema.Query, 'listOAuthFolders'>,
      MainSchema.QueryListOAuthFoldersArgs
    >(listOAuthFoldersQuery, {
      variables: {
        userOAuthId: userOAuth.id,
      },
      skip: !isOpen,
    })
  const [setOAuthMonitoredFolders] = useMutation<
    Pick<MainSchema.Mutation, 'setOAuthMonitoredFolders'>,
    MainSchema.MutationSetOAuthMonitoredFoldersArgs
  >(setOAuthMonitoredFoldersMutation)

  const [isSubmitting, setIsSubmitting] = useState(false)

  const SelectLabelsFormConstraints = {}

  const initialValues = useMemo(() => {
    return {
      [SelectLabelsFormField.LabelIds]:
        userOAuth.monitorFolders?.map(folder => folder.id) ?? [],
    }
  }, [userOAuth])

  const submit = useCallback(
    async (values: SelectLabelsFormValues) => {
      setIsSubmitting(true)

      const monitorFolders =
        listOAuthFoldersData?.listOAuthFolders
          .filter(folder =>
            values[SelectLabelsFormField.LabelIds].includes(folder.id),
          )
          .map(folder => ({
            id: folder.id,
            name: folder.name,
          })) ?? []

      try {
        await setOAuthMonitoredFolders({
          variables: {
            userOAuthId: userOAuth.id,
            monitorFolders,
          },
          refetchQueries: [
            {
              query: listOAuthProvidersQuery,
            },
          ],
        })

        toast.success({
          title: t('toast.title', {
            defaultValue: 'Select Labels',
          }),
          text: t('toast.success', {
            defaultValue: 'Your labels have been updated',
          }),
        })

        onClose?.(true)
      } catch (error) {
        let message = _('error.generic')

        if (error instanceof Error) {
          message = _(`error.${error.message || 'generic'}`)
        }

        toast.error({
          title: t('toast.title', {
            defaultValue: 'Select Labels',
          }),
          text: message,
        })
      } finally {
        setIsSubmitting(false)
      }
    },
    [listOAuthFoldersData, userOAuth, setOAuthMonitoredFolders, onClose, t],
  )

  const renderForm = useCallback(
    ({
      form,
      handleSubmit,
      valid,
    }: FormRenderProps<SelectLabelsFormValues>) => {
      const search =
        form.getFieldState(SelectLabelsFormField.Search)?.value ?? ''
      const folders = listOAuthFoldersData?.listOAuthFolders ?? []
      const filteredFolders = search
        ? folders.filter(folder =>
            folder.name.toLowerCase().includes(search.toLowerCase()),
          )
        : folders

      const labelIds = folders.map(folder => folder.id)
      const selectedLabelIds =
        form.getFieldState(SelectLabelsFormField.LabelIds)?.value || []
      const allLabelsChecked = labelIds.length === selectedLabelIds.length

      const handleAllLabelsChange = (checked: boolean) => {
        form.batch(() => {
          form.change(
            SelectLabelsFormField.LabelIds,
            checked ? [...labelIds] : [],
          )
        })
      }

      return (
        <Modal
          cancelText={t('form.cancel', {
            defaultValue: 'Cancel',
          })}
          confirmDisabled={isSubmitting || !valid}
          confirmText={t('form.submit', {
            defaultValue: 'Save',
          })}
          isOpen={isOpen}
          title={t('title', {
            defaultValue: 'Select Labels',
          })}
          width="456px"
          onClose={() => onClose?.(true)}
          onConfirm={handleSubmit}
        >
          <Column alignItems="center">
            <Column alignItems="center" maxWidth={'368px'}>
              <Column gap={4}>
                <Text
                  color={colors.text.primary}
                  fontSize={'16px'}
                  fontWeight={500}
                  lineHeight={'20px'}
                >
                  {t('description', {
                    defaultValue:
                      'Your emails will be summarized as notes in the system based on the labels you choose.',
                  })}
                </Text>

                <Column gap={3}>
                  {folders.length > 0 && (
                    <Field name={SelectLabelsFormField.Search}>
                      {({ input: { value, onChange, onBlur, onFocus } }) => {
                        const handleClear = () => {
                          onChange('')
                        }

                        return (
                          <SearchInput
                            placeholder={t('search.placeholder', {
                              defaultValue: 'search email labels',
                            })}
                            value={value}
                            onBlur={onBlur}
                            onChange={onChange}
                            onClear={handleClear}
                            onFocus={onFocus}
                          />
                        )
                      }}
                    </Field>
                  )}

                  <Column gap={2}>
                    {!search && filteredFolders.length > 0 && (
                      <Checkbox
                        checked={allLabelsChecked}
                        indeterminate={
                          !allLabelsChecked && selectedLabelIds.length > 0
                        }
                        label={
                          <Text color={colors.text.primary} fontWeight={400}>
                            {t('label.all', {
                              defaultValue: 'All Labels',
                            })}
                          </Text>
                        }
                        onChange={() =>
                          handleAllLabelsChange(!allLabelsChecked)
                        }
                      />
                    )}

                    {filteredFolders.map(folder => {
                      return (
                        <CheckboxField
                          key={folder.id}
                          label={
                            <Text color={colors.text.primary} fontWeight={400}>
                              {folder.name}
                            </Text>
                          }
                          name={SelectLabelsFormField.LabelIds}
                          value={folder.id}
                        />
                      )
                    })}

                    {!isListOAuthFoldersLoading && folders.length === 0 && (
                      <Text color={colors.text.secondary} variant="body-s">
                        {t('search.noLabels', {
                          defaultValue: 'No email labels found.',
                        })}
                      </Text>
                    )}

                    {!isListOAuthFoldersLoading &&
                      folders.length > 0 &&
                      filteredFolders.length === 0 && (
                        <Text color={colors.text.secondary} variant="body-s">
                          {t('search.noLabelsForSearch', {
                            defaultValue:
                              'No email labels found for "%{search}".',
                            search,
                          })}
                        </Text>
                      )}
                  </Column>
                </Column>

                {isListOAuthFoldersLoading && (
                  <Column alignItems="center" gap={2}>
                    <img
                      alt={t('loading.alt', {
                        defaultValue: 'Loader image',
                      })}
                      src={loadingImage}
                      width={74}
                    />
                    <Text
                      color={colors.text.secondary}
                      fontSize={'12px'}
                      fontWeight={400}
                      lineHeight={'30px'}
                    >
                      {t('loading.description', {
                        defaultValue: 'Syncing labels from your email account',
                      })}
                    </Text>
                  </Column>
                )}
              </Column>
            </Column>
          </Column>
        </Modal>
      )
    },
    [
      isSubmitting,
      isOpen,
      onClose,
      t,
      listOAuthFoldersData,
      isListOAuthFoldersLoading,
    ],
  )

  if (!isOpen) return null

  return (
    <Form<SelectLabelsFormValues>
      initialValues={initialValues}
      render={renderForm}
      validate={values => validate(values, SelectLabelsFormConstraints)}
      onSubmit={submit}
    />
  )
}

export default SelectLabelsModal
