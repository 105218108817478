import { useMemo } from 'react'
import {
  TableInstance,
  TableOptions,
  useFlexLayout,
  usePagination,
  useSortBy,
  useTable,
} from 'react-table'

export interface IUseEntityTableProps<D extends object>
  extends Omit<TableOptions<D>, 'data'> {
  data?: {
    rows: TableOptions<D>['data']
    pages?: number
    count?: number
  }
}

export default function useEntityTable<D extends object>({
  columns,
  data,
  ...rest
}: IUseEntityTableProps<D>): [TableInstance<D>, number] {
  const dataRows = data?.rows
  const pageCount = data?.pages ?? 0
  const rowsCount = data?.count ?? 0

  const tableData = useMemo(() => dataRows ?? [], [dataRows])

  const tableProps = useTable<D>(
    {
      autoResetPage: false,
      autoResetSortBy: false,
      columns,
      data: tableData,
      manualPagination: true,
      manualSortBy: true,
      pageCount,
      ...rest,
    },
    useFlexLayout,
    useSortBy,
    usePagination,
  )

  return [tableProps, rowsCount]
}
