import { useMemo } from 'react'

import Utils from 'Utils'

import { useScopedI18n } from 'Services/I18n'

import { AcceptCell, CreatorCell, ProposalCell, RejectCell } from './Cells'
import { AcceptAllHeader, RejectAllHeader } from './Headers'

export function useColumns() {
  const s = useScopedI18n('blocks.tables.proposalsTable')

  return useMemo(
    () => [
      {
        Header: s('proposal'),
        id: 'proposal',
        Cell: ProposalCell,
        width: 2,
        disableSortBy: true,
      },
      {
        Header: s('proposedBy'),
        accessor: 'creator',
        Cell: CreatorCell,
        width: 1,
        disableSortBy: true,
      },
      {
        Header: s('date'),
        id: 'createdAt',
        accessor: 'createdAt',
        Cell: Utils.Table.renderDateTimeCell,
        width: 1,
      },
      {
        Header: AcceptAllHeader,
        id: 'accept',
        width: 0.4,
        Cell: AcceptCell,
        disableSortBy: true,
      },
      {
        Header: RejectAllHeader,
        id: 'reject',
        width: 0.4,
        Cell: RejectCell,
        disableSortBy: true,
      },
    ],
    [s],
  )
}
