import React from 'react'

import { Column, Row, SearchInput, Text } from 'Components/UI'

function SearchInputs() {
  return (
    <Column gap={6}>
      <Text header1>Search</Text>
      <Row fullWidth gap={5}>
        <SearchInput label="Label" placeholder="Search" />
        <SearchInput isLoading label="Loading" placeholder="Search" />
        <SearchInput disabled label="Disabled" placeholder="Search" />
      </Row>
    </Column>
  )
}

export default SearchInputs
