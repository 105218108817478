import React, { useCallback, useEffect, useMemo, useState } from 'react'

import deleteSkillMutation from 'GraphQL/Mutations/Skill/deleteSkill.graphql'
import listSkillsQuery from 'GraphQL/Queries/listSkills.graphql'
import { entityCacheRemover } from 'GraphQL/Updaters/Common'
import Utils from 'Utils'

import { ModifySkillModal } from 'Components/Blocks/Admin/Modals'
import { Dialog } from 'Components/Blocks/Modals'
import { Button, Input, Pagination, Row, Text } from 'Components/UI'
import { Table } from 'Components/UI/Admin'

import {
  // useCommunity,
  useEntityModal,
  useEntityTable,
  useTableSearch,
} from 'Hooks'

import { useMutation, useQuery } from 'Services/Apollo'
import _, { useScopedI18n } from 'Services/I18n'
import toast from 'Services/Toast'

import { useColumns } from './columns'
import TableContext from './context'
import { Container, Content } from './styles'

const SORT_BY = [
  {
    column: 'name',
    order: 'asc',
  },
]

const DEFAULT_PAGE_SIZE = 10

function SkillsTable() {
  const s = useScopedI18n('skill')

  const [mostRecentPage, setMostRecentPage] = useState(0)
  const [mostRecentLimit, setMostRecentLimit] = useState(DEFAULT_PAGE_SIZE)
  const [innerSearch, setInnerSearch] = useState(null)

  // const { community } = useCommunity()

  const [search, changeSearch, clearSearch] = useTableSearch({
    onSearch: value => {
      Utils.Search.tableSearch({
        search: value,
        onSetSearchValue: setInnerSearch,
        onGoToPage: tableProps.gotoPage,
      })
    },
  })

  const { data, loading, refetch } = useQuery(listSkillsQuery, {
    variables: {
      page: mostRecentPage,
      limit: mostRecentLimit,
      sort: SORT_BY,
      search: innerSearch,
    },
    fetchPolicy: 'network-only',
  })

  const columns = useColumns()
  const entities = data?.listSkills ?? {}
  const [tableProps, rowsCount] = useEntityTable({
    data: entities,
    columns,
    disableSortBy: true,
  })
  const { pageSize, pageIndex } = tableProps.state

  useEffect(() => {
    setMostRecentPage(tableProps.state.pageIndex)
  }, [tableProps.state.pageIndex])

  useEffect(() => {
    setMostRecentPage(0)
    setMostRecentLimit(tableProps.state.pageSize)
  }, [tableProps.state.pageSize])

  const refetchOnClose = useCallback(
    ({ context }) =>
      async success => {
        if (!success) {
          return
        }

        try {
          await refetch()
        } catch (error) {
          toast.error({
            title: context,
            text: error?.message,
          })
        }
      },
    [refetch],
  )

  const [editModal, editActions] = useEntityModal({
    refetch: refetchOnClose({ context: s('actions.edit') }),
  })

  const [createModal, createActions] = useEntityModal({
    refetch: refetchOnClose({ context: s('actions.create') }),
  })

  const [deleteModal, deleteActions] = useEntityModal()

  const [deleteSkill] = useMutation(deleteSkillMutation, {
    update: entityCacheRemover({
      entity: deleteModal.entity,
      pageSize,
      queryKey: 'skills',
    }),
  })

  const handleDeleteSkill = useCallback(
    async success => {
      if (!success) {
        return
      }

      try {
        await deleteSkill({
          variables: {
            skillId: deleteModal.entity?.id,
          },
        })

        toast.success({
          title: s('actions.delete'),
          text: s('deleteSuccess'),
        })
      } catch (error) {
        toast.error({
          title: s('actions.delete'),
          text: error.message,
        })
      }
    },
    [deleteModal.entity?.id, deleteSkill, s],
  )

  const handleEditModalClose = editActions.closeModal
  const handleCreateModalOpen = createActions.openModal
  const handleCreateModalClose = createActions.closeModal
  const handleDeleteModalClose = deleteActions.closeModal

  const deleteSkillMessage = (
    <Text
      dangerouslySetInnerHTML={{
        __html: s('messages.delete', {
          skillName: deleteModal.entity?.name,
        }),
      }}
    />
  )

  const memoizedValue = useMemo(
    () => ({
      onEditRow: editActions.openModal,
      onDeleteRow: deleteActions.openModal,
    }),
    [editActions, deleteActions],
  )

  return (
    <TableContext.Provider value={memoizedValue}>
      <Container>
        <Text header header2 mb={3} mt={4}>
          Skills management
        </Text>

        <Content>
          <Row borderBottom center mb={2} pb={3} spaceBetween>
            <Row>
              <Input
                clearable
                name="search"
                placeholder={_('search.byName')}
                small
                value={search}
                width={300}
                onChange={changeSearch}
                onClear={clearSearch}
              />
            </Row>

            <Row>
              <Button small onClick={handleCreateModalOpen}>
                {s('actions.create')}
              </Button>
            </Row>
          </Row>

          <Table {...tableProps} loading={loading} />

          <Row borderTop mt={2} pt={3}>
            <Pagination
              loading={loading}
              state={{ pageSize, pageIndex }}
              total={rowsCount}
              onPageIndexChange={tableProps?.gotoPage}
              onPageSizeChange={tableProps?.setPageSize}
            />
          </Row>
        </Content>
      </Container>

      <ModifySkillModal
        isOpen={editModal.isOpen}
        skill={editModal.entity}
        onClose={handleEditModalClose}
      />

      <ModifySkillModal
        isOpen={createModal.isOpen}
        onClose={handleCreateModalClose}
      />

      <Dialog
        content={deleteSkillMessage}
        isOpen={deleteModal.isOpen}
        title={s('actions.delete')}
        onClose={handleDeleteModalClose}
        onFinish={handleDeleteSkill}
      />
    </TableContext.Provider>
  )
}

export default SkillsTable
