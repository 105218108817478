export const firstNameOrFallback = (
  communityUser?: MainSchema.CommunityUser,
): string => {
  if (!communityUser) {
    return 'N/A'
  }

  if (communityUser.firstName) {
    return communityUser.firstName
  }
  if (communityUser.lastName) {
    return communityUser.lastName
  }
  return communityUser.email || 'N/A'
}

export const fullNameOrFallback = (
  communityUser?: MainSchema.CommunityUser,
): string => {
  if (!communityUser) {
    return 'N/A'
  }

  const { firstName, lastName } = communityUser

  if (firstName && lastName) {
    return `${firstName} ${lastName}`
  }

  if (firstName) {
    return firstName
  }

  if (lastName) {
    return lastName
  }

  return communityUser.email || 'N/A'
}

export const firstInitialOrFallback = (
  communityUser?: MainSchema.CommunityUser,
): string | undefined => {
  if (!communityUser) {
    return undefined
  }

  if (communityUser.firstName) {
    return communityUser.firstName.charAt(0)
  }

  if (communityUser.lastName) {
    return communityUser.lastName.charAt(0)
  }

  if (communityUser.email) {
    return communityUser.email.charAt(0)
  }

  return undefined
}
