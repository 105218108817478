import { useEffect } from 'react'

import EventBus from 'Services/EventBus'

export default function useEventBusSubscribe(
  actionName: string,
  actionHandler:
    | ((...args: any[]) => void)
    | ((...args: any[]) => Promise<void>),
) {
  return useEffect(() => {
    const subscription = EventBus.on(actionName, actionHandler)

    return subscription.clear
  }, [actionName, actionHandler])
}
