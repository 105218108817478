import React, { useRef, useState } from 'react'
import PropTypes from 'prop-types'

import { IconAdjustments, IconX } from '@tabler/icons-react'

import noop from 'lodash/noop'

import { Button, LinkButton, Row, Text } from 'Components/UI'

import { useOnClickOutside } from 'Hooks'

import { useScopedI18n } from 'Services/I18n'

import { Container, Dropdown } from './styles'

function AdvancedFilter({ children, applyFilters, clearFilter }) {
  const s = useScopedI18n('general')

  const containerRef = useRef(null)

  const [isOpen, setIsOpen] = useState(false)

  useOnClickOutside(containerRef, () => setIsOpen(false))

  return (
    <Container ref={containerRef}>
      <Button secondary onClick={() => setIsOpen(prevState => !prevState)}>
        <IconAdjustments />
        {s('advancedFilters')}
      </Button>

      {isOpen && (
        <Dropdown>
          <Row gap={7} px={6} py={4} spaceBetween>
            <Text header header3>
              Filters
            </Text>

            <Row gap={7}>
              <LinkButton onClick={applyFilters}>Apply</LinkButton>
              <LinkButton secondary onClick={clearFilter}>
                Clear
              </LinkButton>
              <LinkButton secondary>
                <IconX size={16} onClick={() => setIsOpen(false)} />
              </LinkButton>
            </Row>
          </Row>

          {children}
        </Dropdown>
      )}
    </Container>
  )
}

AdvancedFilter.defaultProps = {
  applyFilters: noop,
  children: null,
  clearFilter: noop,
}

AdvancedFilter.propTypes = {
  applyFilters: PropTypes.func,
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.string,
  ]),
  clearFilter: PropTypes.func,
}

export default AdvancedFilter
