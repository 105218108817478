import React, { useState } from 'react'

import SelectLabelsModal from 'Features/OAuth/Views/SelectLabelsModal'

import { Button } from 'Components/UI'
import { Text } from 'Components/UI/_v2'

import { useScopedI18n } from 'Services/I18n'

import colors from 'Theme/_v2/colors'

import { Wrapper } from './Labels.styles'

interface LabelsProps {
  userOAuth: MainSchema.UserOAuth
}

const Labels = (props: LabelsProps) => {
  const t = useScopedI18n(
    'components.blocks.ownProfile.connectedAccounts.labels',
  )
  const [isSelectLabelsModalOpen, setIsSelectLabelsModalOpen] = useState(false)

  return (
    <>
      <Wrapper>
        <Text color={colors.text.primary} fontWeight={600} variant="heading5">
          {t('labelsSynced', {
            count: props.userOAuth.monitorFolders?.length ?? 0,
            defaultValue: {
              one: '%{count} Label Synced',
              other: '%{count} Labels Synced',
            },
          })}
        </Text>

        <Button
          secondary
          small
          type="button"
          onClick={() => setIsSelectLabelsModalOpen(true)}
        >
          {t('modifyLabels', { defaultValue: 'Modify Labels' })}
        </Button>
      </Wrapper>

      <SelectLabelsModal
        isOpen={isSelectLabelsModalOpen}
        userOAuth={props.userOAuth}
        onClose={() => setIsSelectLabelsModalOpen(false)}
      />
    </>
  )
}

export default Labels
