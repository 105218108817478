import React from 'react'

import styled from 'styled-components'

import { websiteGlyph } from 'Assets/Svg'

import colors from 'Theme/_v2/colors'

const Icon = styled(websiteGlyph)``

export interface IWebsite {
  color?: string
  size?: number
}

const Website = ({ color = colors.main.primary, size }: IWebsite) => {
  return <Icon color={color} display="block" height={size} width={size} />
}

export default Website
