import React, { useCallback, useState } from 'react'

import { Column, Pagination, Text } from 'Components/UI'

function PaginationSection() {
  const [state, setState] = useState({ pageSize: 10, pageIndex: 0 })

  const handlePageSize = useCallback(
    (pageSize: number) => {
      setState({ ...state, pageSize })
    },
    [state],
  )

  const handlePageIndex = useCallback(
    (pageIndex: number) => {
      setState({ ...state, pageIndex })
    },
    [state],
  )

  return (
    <Column gap={6}>
      <Text header1>Pagination</Text>
      <Column gap={5}>
        <Column center fullWidth gap={4}>
          <Text header2 primary80>
            Desktop
          </Text>
          <Pagination
            state={state}
            total={300}
            onPageIndexChange={handlePageIndex}
            onPageSizeChange={handlePageSize}
          />
        </Column>
      </Column>
    </Column>
  )
}

export default PaginationSection
