import { MdOutlineArrowForwardIos } from 'react-icons/md'

import styled, { css } from 'styled-components'
import { margin, MarginProps, position, PositionProps } from 'styled-system'
import { themeGet } from '@styled-system/theme-get'

import * as icons from 'Assets/Svg/icons'

const iconSize = css`
  width: 14px;
  height: 14px;
`

export type IconType = keyof typeof icons

export const StyledIcon = styled.div`
  ${iconSize}
  display: flex;
  align-items: center;
  justify-content: center;
`

interface MenuHoveredCssProps {
  hovered?: boolean
  menuLeft?: boolean
}

const menuHoveredCss = ({ hovered, menuLeft }: MenuHoveredCssProps) =>
  hovered &&
  css`
    border: none;
    top: 0;
    left: ${menuLeft ? 0 : 'unset'} !important;
    right: ${menuLeft ? 'unset' : 0} !important;
    transform: ${menuLeft ? 'translateX(-100%)' : 'translateX(100%)'};
    color: ${themeGet('colors.text.header')} !important;
  `

interface MenuScrollableCssProps {
  scrollable?: boolean
}

const menuScrollableCss = ({ scrollable }: MenuScrollableCssProps) =>
  scrollable &&
  css`
    max-height: 194px;
    overflow-y: auto;
  `

export interface IMenu
  extends PositionProps,
    MarginProps,
    MenuHoveredCssProps,
    MenuScrollableCssProps {}

export const Menu = styled.div<IMenu>`
  position: absolute;
  box-sizing: content-box;
  transform: translateY(10px);
  display: flex;
  flex-direction: column;
  z-index: ${themeGet('zIndices.popover')};
  background-color: ${themeGet('colors.bg.primary')};
  border-radius: 4px;
  border: 1px solid #f0f0f0;
  box-shadow:
    0px 0px 0px 1px rgba(0, 0, 0, 0.05),
    0px 10px 15px -3px rgba(0, 0, 0, 0.1),
    0px 4px 6px -2px rgba(0, 0, 0, 0.05);

  > div {
    color: ${themeGet('colors.text.header')};

    &:hover {
      background-color: ${themeGet('colors.bg.hover')};
      color: ${themeGet('colors.main')};
    }

    &[aria-disabled='true'] {
      cursor: default;
      background-color: unset;
      color: unset;
    }
  }

  ${position}
  ${margin}

  ${menuHoveredCss}
  ${menuScrollableCss}
`

interface ItemCssProps {
  showDivider?: boolean
}
export const dividerCssProps = ({ showDivider }: ItemCssProps) =>
  showDivider &&
  css`
    border-bottom: 1px solid ${themeGet('colors.border.primary')};
  `
export const Item = styled.div<ItemCssProps>`
  position: relative;
  display: grid;
  align-items: center;
  grid-template-columns: auto 1fr auto;
  gap: ${themeGet('space.2')}px;
  padding: ${themeGet('space.2')}px ${themeGet('space.2')}px;
  font-weight: ${themeGet('fontWeight.0')};
  font-size: ${themeGet('fontSize.2')}px;
  cursor: pointer;
  white-space: nowrap;

  ${dividerCssProps}
`

export const ItemLabel = styled.div`
  display: flex;
  justify-content: left;
  width: calc(100%);
`

export const ArrowForwardIcon = styled(MdOutlineArrowForwardIos)`
  ${iconSize}
  margin-left: auto;
`

export const ItemHeader = styled.div`
  display: grid;
  grid-template-columns: auto 1fr auto;
  align-items: center;
  gap: ${themeGet('space.2')}px;
  padding: ${themeGet('space.2')}px ${themeGet('space.2')}px 0;
  font-weight: ${themeGet('fontWeight.0')};
  font-size: ${themeGet('fontSize.0')}px;
  color: ${themeGet('colors.text.placeholder')} !important;
  cursor: default;
  white-space: nowrap;
  background-color: ${themeGet('colors.bg.primary')} !important;
  text-transform: uppercase;
`
