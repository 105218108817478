import React, { useCallback, useState } from 'react'

import { useMutation } from '@apollo/client'
import { IconX } from '@tabler/icons-react'
import rejectAllProposalsMutation from 'GraphQL/Mutations/Proposal/acceptAllProposals.graphql'

import { LinkButton, Modal } from 'Components/UI'

import { useCommunityContext } from 'Hooks'

import _, { useScopedI18n } from 'Services/I18n'
import toast from 'Services/Toast'

import { useTableContext } from '../context'

function RejectAll() {
  const s = useScopedI18n('blocks.tables.proposalsTable')
  const { community } = useCommunityContext()
  const { onRefetch, entitiesCount } = useTableContext()

  const [isShowDialog, setShowDialog] = useState(false)

  const [rejectAllProposals] = useMutation(rejectAllProposalsMutation)

  const handleRejectAllProposals = useCallback(
    async success => {
      if (!success) {
        return
      }

      try {
        await rejectAllProposals({
          variables: {
            communityId: community?.id,
          },
        })

        await onRefetch()

        toast.success({
          title: s('messages.title'),
          text: s('messages.rejected'),
        })
      } catch (error) {
        toast.error({
          title: s('messages.title'),
          text: _(`error.${error?.message || 'generic'}`),
        })
      }
    },
    [rejectAllProposals, community, onRefetch, s],
  )

  if (entitiesCount === 0) return null

  return (
    <>
      <LinkButton danger onClick={() => setShowDialog(true)}>
        <IconX />
        {s('rejectAll')}
      </LinkButton>

      <Modal
        cancelText={_('general.cancel')}
        confirmText={s('rejectAll')}
        isOpen={isShowDialog}
        title={s('dialog.reject.title')}
        onClose={() => setShowDialog(false)}
        onConfirm={handleRejectAllProposals}
      >
        {s('dialog.reject.content')}
      </Modal>
    </>
  )
}

export default RejectAll
