import { createContext, useContext } from 'react'

export type SelectDropdownVisibleHandler = React.Dispatch<
  React.SetStateAction<boolean>
>
export type ResetHandler = () => void

export interface IMentionTextAreaContext {
  dropdownVisible: boolean
  onSelectDropdownVisible: SelectDropdownVisibleHandler
  onReset: ResetHandler
}

const MentionTextAreaContext = createContext<IMentionTextAreaContext>({
  dropdownVisible: false,
  onSelectDropdownVisible: () => {},
  onReset: () => {},
})

export function useMentionTextareaContext() {
  return useContext(MentionTextAreaContext)
}

export default MentionTextAreaContext
