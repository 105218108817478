export interface ITagSizes {
  default?: number
  small?: number
  medium?: number
}

export interface ITags {
  height: ITagSizes
  borderRadius: ITagSizes
  iconSize: ITagSizes
}

const tags: ITags = {
  height: { default: 32, small: 24, medium: 28 },

  borderRadius: { default: 4 },

  iconSize: { default: 16, small: 12, medium: 12 },
}

export default tags
