import React, { forwardRef, HTMLAttributes } from 'react'

import { pick } from '@styled-system/props'

import { Column } from 'Components/UI/Flex'
import Text from 'Components/UI/Text'

import {
  CheckboxBorder,
  CheckIcon,
  Container,
  IContainerProps,
  InvisibleCheckbox,
  MinusIcon,
} from './styles'

export interface ICheckboxProps
  extends HTMLAttributes<HTMLInputElement>,
    IContainerProps {
  checked?: boolean
  defaultChecked?: boolean
  disabled?: boolean
  error?: React.ReactNode
  hasError?: boolean
  indeterminate?: boolean
  label?: React.ReactNode
  onBlur?: React.FocusEventHandler<HTMLInputElement>
  onChange?: React.ChangeEventHandler<HTMLInputElement>
  onFocus?: React.FocusEventHandler<HTMLInputElement>
}

const Checkbox = forwardRef<HTMLInputElement, ICheckboxProps>(
  (
    {
      checked,
      defaultChecked,
      disabled,
      error,
      indeterminate,
      hasError,
      label,
      onChange,
      onFocus,
      onBlur,
      ...rest
    },
    ref,
  ) => {
    const innerChecked = checked === true || indeterminate === true

    return (
      <Column>
        <Container {...pick(rest)}>
          <InvisibleCheckbox
            checked={innerChecked}
            defaultChecked={defaultChecked}
            disabled={disabled}
            ref={ref}
            onBlur={onBlur}
            onChange={onChange}
            onFocus={onFocus}
          />
          <CheckboxBorder>
            {innerChecked && (checked ? <CheckIcon /> : <MinusIcon />)}
          </CheckboxBorder>

          {label && (
            <Text as="span" disabled={disabled} header={!disabled} header4>
              {label}
            </Text>
          )}
        </Container>

        {hasError && (
          <Text bodySmall error>
            {error}
          </Text>
        )}
      </Column>
    )
  },
)

export default Checkbox
