import React from 'react'
import { components } from 'react-select'

import { Item } from './styles'

const Option: typeof components.Option = props => {
  // TODO: find a better way to satisfy the type
  const StyledItem = Item as any
  return <StyledItem {...props} as={components.Option} />
}

export default Option
