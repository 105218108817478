import React from 'react'
import { components } from 'react-select'

import { Separator } from './styles'

const IndicatorSeparator: typeof components.IndicatorSeparator = props => {
  // TODO: find a better way to satisfy the type
  const StyledSeparator = Separator as any
  return <StyledSeparator {...props} as={components.IndicatorSeparator} />
}

export default IndicatorSeparator
