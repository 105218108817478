import { useEffect } from 'react'
import { useMatch } from 'react-router-dom'

import communityBySlugQuery from 'GraphQL/Queries/Community/communityBySlug.graphql'

import { COMMUNITY_SLUG } from 'Router/routes'

import { useQuery } from 'Services/Apollo'
import { getAuth } from 'Services/Store/auth'
import {
  getCommunity,
  setCommunity as cacheCommunity,
} from 'Services/Store/community'

export default function useCommunity() {
  const auth = getAuth()

  const cachedCommunity: MainSchema.Community | undefined = auth.accessToken
    ? getCommunity()
    : undefined

  const match = useMatch(COMMUNITY_SLUG)
  const urlSlug = match?.params?.slug !== 'all' ? match?.params?.slug : null
  const slug = urlSlug || cachedCommunity?.slug || undefined
  const skip = !slug || !auth.accessToken

  // TODO: find a better way to add graphql types
  const {
    data,
    loading,
    error,
    refetch: refetchCommunityBySlug,
  } = useQuery<
    Pick<MainSchema.Query, 'communityBySlug'>,
    MainSchema.QueryCommunityBySlugArgs
  >(communityBySlugQuery, {
    variables: { slug },
    skip,
  })

  useEffect(() => {
    if (data?.communityBySlug) {
      cacheCommunity(data.communityBySlug)
    }
  }, [data?.communityBySlug])

  return {
    community: cachedCommunity,
    loading,
    error,
    refetchCommunityBySlug,
  }
}
