import { NavLink } from 'react-router-dom'

import styled, { css } from 'styled-components'
import { mapToTheme } from 'styled-map'
import {
  BorderProps,
  flexbox,
  FlexboxProps,
  layout,
  LayoutProps,
  space,
  SpaceProps,
} from 'styled-system'
import shouldForwardProp from '@styled-system/should-forward-prop'
import { themeGet } from '@styled-system/theme-get'

export interface ISecondaryCssProps {
  secondary?: boolean
}

const secondaryCss = ({ secondary }: ISecondaryCssProps) =>
  secondary &&
  css`
    color: ${themeGet('colors.link.secondary.color')};

    &:hover {
      color: ${themeGet('colors.link.secondary.colorHover')};
    }

    &:focus,
    :active {
      color: ${themeGet('colors.link.secondary.colorFocus')};
    }
  `

export interface ISuccessCssProps {
  success?: boolean
}

const successCss = ({ success }: ISuccessCssProps) =>
  success &&
  css`
    color: ${themeGet('colors.link.success.color')};

    &:hover {
      color: ${themeGet('colors.link.success.colorHover')};
    }

    &:focus,
    :active {
      color: ${themeGet('colors.link.success.colorFocus')};
    }
  `

export interface IInfoCssProps {
  info?: boolean
}

const infoCss = ({ info }: IInfoCssProps) =>
  info &&
  css`
    color: ${themeGet('colors.link.info.color')};

    &:hover {
      color: ${themeGet('colors.link.info.colorHover')};
    }

    &:focus,
    :active {
      color: ${themeGet('colors.link.info.colorFocus')};
    }
  `

export interface IDangerCssProps {
  danger?: boolean
}

const dangerCss = ({ danger }: IDangerCssProps) =>
  danger &&
  css`
    color: ${themeGet('colors.link.danger.color')};

    &:hover {
      color: ${themeGet('colors.link.danger.colorHover')};
    }

    &:focus,
    :active {
      color: ${themeGet('colors.link.danger.colorFocus')};
    }
  `

export interface IDisabledCssProps {
  disabled?: boolean
}

const disabledCss = ({ disabled }: IDisabledCssProps) =>
  disabled &&
  css`
    pointer-events: none;
    color: ${themeGet('colors.link.colorDisabled')};
    cursor: default;

    &:hover {
      color: ${themeGet('colors.link.colorDisabled')};
    }
  `

export interface ILinkCss
  extends ISecondaryCssProps,
    ISuccessCssProps,
    IInfoCssProps,
    IDangerCssProps,
    IDisabledCssProps,
    FlexboxProps,
    LayoutProps,
    SpaceProps,
    BorderProps {
  large?: boolean
  small?: boolean
  button?: boolean
  icon?: boolean
  outline?: boolean
}

const linkCss = css<ILinkCss>`
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  cursor: pointer;

  font-weight: ${themeGet('fontWeight.1')};
  font-size: ${mapToTheme('links.fontSize')}px;
  line-height: ${mapToTheme('links.lineHeight')};
  gap: ${themeGet('space.2')}px;

  color: ${themeGet('colors.link.primary.color')};

  &:visited {
    color: ${themeGet('colors.link.primary.color')};
  }

  &:hover {
    color: ${themeGet('colors.link.primary.colorHover')};
  }

  &:focus,
  :active {
    color: ${themeGet('colors.link.primary.colorFocus')};
  }

  & > svg {
    flex-shrink: 0;
    height: ${mapToTheme('links.iconSize')}px;
    width: ${mapToTheme('links.iconSize')}px;
  }

  ${flexbox}
  ${layout}
  ${space}

  ${dangerCss}
  ${infoCss}
  ${secondaryCss}
  ${successCss}
  ${disabledCss}
`

export const StyledNavLink = styled(NavLink).withConfig<ILinkCss>({
  shouldForwardProp,
})`
  ${linkCss}
`

export const StyledExternalLink = styled.a`
  ${linkCss}
`

export const StyledButton = styled.button`
  outline: none;
  border: none;
  background: none;
  padding: 0;

  ${linkCss}
`
