import React from 'react'
import PropTypes from 'prop-types'

import { Text } from 'Components/UI'

const HUBSPOT_STATE = {
  pending: 'Pending',
  active: 'Active',
  inactive: 'Inactive',
  syncing: 'Syncing',
}

function StateCell({ value }) {
  return (
    <Text bodyMedium header>
      {HUBSPOT_STATE[value]}
    </Text>
  )
}

StateCell.defaultProps = {
  value: null,
}

StateCell.propTypes = {
  value: PropTypes.string,
}

export default StateCell
