import React, { useCallback, useMemo, useRef, useState } from 'react'
import { Form } from 'react-final-form'
import PropTypes from 'prop-types'

import createSkillsMutation from 'GraphQL/Mutations/Skill/createSkills.graphql'
import updateSkillMutation from 'GraphQL/Mutations/Skill/updateSkill.graphql'
import validate from 'validate.js'

import fromPairs from 'lodash/fromPairs'
import get from 'lodash/get'
import noop from 'lodash/noop'

import { Button, InputField, Loader, Modal, Row } from 'Components/UI'

import { useMutation } from 'Services/Apollo'
import { useScopedI18n } from 'Services/I18n'
import toast from 'Services/Toast'

import { Content } from './styles'

const FIELD = {
  NAME: 'name',
}

function ModifySkillModal({ skill, isOpen, onClose }) {
  const s = useScopedI18n('skill')
  const [loading, setLoading] = useState(false)

  const update = !!skill

  const close = useRef(null)

  const [updateSkill] = useMutation(updateSkillMutation)
  const [createSkills] = useMutation(createSkillsMutation)

  const formConstraints = useMemo(
    () => ({
      [FIELD.NAME]: {
        presence: true,
      },
    }),
    [],
  )

  const handleMount = useCallback(instance => {
    close.current = get(instance, 'handleClose')
  }, [])

  const submit = useCallback(
    async values => {
      setLoading(true)

      try {
        if (update) {
          const changedValues = fromPairs(
            Object.entries(values)?.filter(
              ([key, value]) => skill?.[key] !== value,
            ),
          )

          await updateSkill({
            variables: {
              skillId: skill?.id,
              communityId: skill?.communityId,
              name: values[FIELD.NAME],
              ...changedValues,
            },
          })
        } else {
          await createSkills({
            variables: {
              skills: [
                {
                  name: values[FIELD.NAME],
                },
              ],
            },
          })
        }

        toast.success({
          title: 'Modify skill',
          text: `${values[FIELD.NAME]} skill ${update ? 'updated' : 'created'}`,
        })

        setLoading(false)

        onClose()
      } catch (error) {
        toast.error({
          title: 'Oops...',
          text: error?.message,
        })

        setLoading(false)
      }
    },
    [update, onClose, updateSkill, skill, createSkills],
  )

  return (
    <Modal
      isOpen={isOpen}
      shouldCloseOnOverlayClick={false}
      title={update ? s('actions.edit') : s('actions.create')}
      width="420px"
      onClose={onClose}
      onMount={handleMount}
    >
      <Form
        initialValues={skill}
        render={({ handleSubmit }) => (
          <>
            <Content>
              <InputField label="Name" mb={3} name={FIELD.NAME} width={1} />
            </Content>

            <Row center mt={3} spaceBetween>
              {loading ? (
                <Loader />
              ) : (
                <>
                  <Button secondary small onClick={onClose}>
                    Cancel
                  </Button>
                  <Button small onClick={handleSubmit}>
                    Save
                  </Button>
                </>
              )}
            </Row>
          </>
        )}
        validate={values => validate(values, formConstraints)}
        onSubmit={submit}
      />
    </Modal>
  )
}

ModifySkillModal.defaultProps = {
  isOpen: false,
  skill: null,
  onClose: noop,
}

ModifySkillModal.propTypes = {
  isOpen: PropTypes.bool,
  skill: PropTypes.object,
  onClose: PropTypes.func,
}

export default ModifySkillModal
