import React, { useCallback, useRef, useState } from 'react'

import { useMutation } from '@apollo/client'
import removeConnectionsMutation from 'GraphQL/Mutations/Community/removeConnections.graphql'
import { removeConnectionsUpdater } from 'GraphQL/Updaters/Connections'

import { Popover, Text } from 'Components/UI'

import { useAppContext, useCommunity, useOnClickOutside } from 'Hooks'

import EventBus from 'Services/EventBus'

import {
  Container,
  DisconnectIcon,
  DotsIcon,
  DropdownContainer,
  Option,
} from './styles'

export interface IConnectDropdownProps {
  userId: string
}

function ConnectDropdown({ userId }: IConnectDropdownProps) {
  const containerRef = useRef<HTMLDivElement | null>(null)
  const { community } = useCommunity()
  const { me, refetchMe } = useAppContext()

  const [isOpen, setOpen] = useState(false)
  const [removeConnection] = useMutation<
    Pick<MainSchema.Mutation, 'removeConnections'>,
    MainSchema.MutationRemoveConnectionsArgs
  >(removeConnectionsMutation)

  useOnClickOutside(containerRef, () => setOpen(false))

  const handleDisconnect = useCallback(async () => {
    if (!community || !me) {
      return
    }

    await removeConnection({
      variables: {
        userIds: [userId],
      },
      update: removeConnectionsUpdater({
        communityId: community.id,
        me,
        removedUserId: userId,
      }),
    })

    // TODO: Graph is not updating by default updater, edges is not recalculating when targetUser connections updated. Look to setEdges
    await refetchMe?.()
    setTimeout(() => {
      EventBus.trigger(EventBus.actions.graph.disconnectUser, {
        fromId: me?.userId,
        toId: userId,
      })
    }, 0)

    setOpen(false)
  }, [removeConnection, userId, community, me, refetchMe])

  return (
    <Container>
      <Popover
        appendTo={document.body}
        content={
          <DropdownContainer ref={containerRef}>
            <Option gap={2} onClick={handleDisconnect}>
              <DisconnectIcon />
              <Text bodyMedium>Disconnect user</Text>
            </Option>
          </DropdownContainer>
        }
        interactive
        offset={[0, 5]}
        placement="bottom-end"
        visible={isOpen}
      >
        <DotsIcon onClick={() => setOpen(true)} />
      </Popover>
    </Container>
  )
}

export default ConnectDropdown
