import styled, { css } from 'styled-components'
import { layout, LayoutProps, margin, MarginProps } from 'styled-system'
import { themeGet } from '@styled-system/theme-get'

const containerVerticalCss = ({ vertical }: { vertical?: boolean }) =>
  vertical &&
  css`
    width: 1px;
    height: 100%;
  `

export interface IContainer extends LayoutProps, MarginProps {
  vertical?: boolean
}

export const Container = styled.div<IContainer>`
  width: 100%;
  height: 1px;
  background-color: ${themeGet('colors.divider.default')};

  ${margin}

  ${containerVerticalCss}

  ${layout}
`
