import { css } from 'styled-components'
import { mapToTheme } from 'styled-map'
import { themeGet } from '@styled-system/theme-get'

export default css`
  .toast-container {
    margin-top: 65px;
    min-width: ${mapToTheme('toasts.minWidth')}px;

    .Toastify__toast {
      border-radius: ${mapToTheme('toasts.borderRadius')}px;
      min-height: unset;
      font-family: ${themeGet('font.fontFamily')};
      box-shadow: ${themeGet('colors.toast.shadow')};
      padding: ${themeGet('space.3')}px;

      > svg {
        width: ${mapToTheme('toasts.xSize')}px;
        height: ${mapToTheme('toasts.xSize')}px;
      }

      .Toastify__toast-icon {
        display: none;
      }

      .Toastify__toast-body {
        padding: 0;
      }

      .Toastify__progress-bar {
        height: ${mapToTheme('toasts.progressBarHeight')}px;
        background: ${themeGet('colors.success')};
      }

      &.Toastify__toast--error {
        border-color: unset;
        background-color: ${themeGet('colors.toast.bg')};

        .Toastify__progress-bar {
          background: ${themeGet('colors.error')};
        }
      }

      &.Toastify__toast--success {
        border-color: unset;
        background-color: ${themeGet('colors.toast.bg')};

        .Toastify__progress-bar {
          background: ${themeGet('colors.success')};
        }
      }

      &.Toastify__toast--warning {
        border-color: unset;
        background-color: ${themeGet('colors.toast.bg')};

        .Toastify__progress-bar {
          background: ${themeGet('colors.warning')};
        }
      }

      &.Toastify__toast--info {
        border-color: unset;
        background-color: ${themeGet('colors.toast.bg')};

        .Toastify__progress-bar {
          background: ${themeGet('colors.info')};
        }
      }

      &.Toastify__toast--default {
        border-color: unset;
        background-color: ${themeGet('colors.toast.bg')};

        .Toastify__progress-bar {
          background: ${themeGet('colors.success')};
        }
      }
    }
  }
`
