import React, { useCallback, useState } from 'react'
import PropTypes from 'prop-types'

import noop from 'lodash/noop'

import { Column, Input, Modal, Text } from 'Components/UI'

function DisconnectHubSpotModal({
  content,
  isOpen,
  title,
  onClose,
  onConfirm,
}) {
  const [confirmDisabled, setConfirmDisabled] = useState(true)

  const inputRef = React.useRef(null)

  const handleInputChange = useCallback(() => {
    setConfirmDisabled(
      !inputRef.current?.value || inputRef.current?.value !== 'DISCONNECT',
    )
  }, [])

  if (!isOpen) return null

  return (
    <Modal
      cancelText="Cancel"
      confirmDisabled={confirmDisabled}
      confirmText="Delete"
      isOpen
      title={title}
      onClose={onClose}
      onConfirm={onConfirm}
    >
      <Column gap={4} width={['100%', '100%', '600px']}>
        <Text header header4>
          {content}
        </Text>

        <Input
          placeholder="Type DISCONNECT"
          ref={inputRef}
          onChange={handleInputChange}
        />
      </Column>
    </Modal>
  )
}

DisconnectHubSpotModal.defaultProps = {
  content: null,
  isOpen: false,
  title: null,
  onClose: noop,
  onConfirm: noop,
}

DisconnectHubSpotModal.propTypes = {
  content: PropTypes.node,
  isOpen: PropTypes.bool,
  title: PropTypes.node,
  onClose: PropTypes.func,
  onConfirm: PropTypes.func,
}

export default DisconnectHubSpotModal
