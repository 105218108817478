import React, { useCallback, useMemo } from 'react'

import { SelectField } from 'Components/UI'
import { Text } from 'Components/UI/_v2'

import { SkillKind, SkillTagKind } from 'Constants/ids'
import { TagKind } from 'Constants/mainGraphQL'

import { useScopedI18n } from 'Services/I18n'

export const useSkillTagKindOptions = () => {
  const t = useScopedI18n(
    'components.blocks.forms.fields.skillTagKindSelectField',
  )

  const options = useMemo<SkillTagKindOption[]>(() => {
    return [
      {
        label: t('options.skill.label'),
        value: SkillKind.Skill,
      },
      {
        label: t('options.needsSkill.label'),
        value: SkillKind.NeedsSkill,
      },
      {
        label: t('options.event.label'),
        value: TagKind.Event,
      },
      {
        label: t('options.role.label'),
        value: TagKind.Role,
      },
      {
        label: t('options.project.label'),
        value: TagKind.Project,
      },
      {
        label: t('options.group.label'),
        value: TagKind.Group,
      },
      {
        label: t('options.industry.label'),
        value: TagKind.Industry,
      },
      {
        label: t('options.custom.label'),
        value: TagKind.Custom,
      },
    ]
  }, [t])

  return options
}

interface OptionLabelProps {
  label: string
}

// TODO: refactor to a shared component
const OptionLabel: React.FC<OptionLabelProps> = props => {
  return (
    <Text fontWeight={500} variant="body-s">
      {props.label}
    </Text>
  )
}

export interface SkillTagKindOption {
  label: string
  value: SkillTagKind
}

export interface SkillTagKindSelectFieldProps {
  name: string
  label: string
  placeholder?: string
  isRequired?: boolean
  isClearable?: boolean
}

const SkillTagKindSelectField: React.FC<
  SkillTagKindSelectFieldProps
> = props => {
  const options = useSkillTagKindOptions()

  const handleFormatOptionLabel = useCallback((option: SkillTagKindOption) => {
    return <OptionLabel label={option.label} />
  }, [])

  return (
    <SelectField<SkillTagKindOption, false>
      checkErrorIfDirty
      clearable={props.isClearable}
      formatOptionLabel={handleFormatOptionLabel}
      label={props.label}
      name={props.name}
      options={options}
      placeholder={props.placeholder}
      required={props.isRequired}
      withPortal
    />
  )
}

export default SkillTagKindSelectField
