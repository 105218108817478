import React, { useCallback } from 'react'

import { Button, OldModal, Row } from 'Components/UI'

import { useModal } from 'Hooks'
import { IUseModalProps } from 'Hooks/useModal'

import _ from 'Services/I18n'

import { Content } from './styles'

export interface IDialogModalProps extends IUseModalProps {
  cancelText?: string
  confirmText?: string
  content?: React.ReactNode
  onFinish: (success: boolean) => Promise<void>
}

function DialogModal(props: IDialogModalProps) {
  const { cancelText, confirmText, content, onFinish } = props

  const [modalProps] = useModal(props)

  const handleClose = modalProps.onClose

  const handleFinish = useCallback(
    async (success: boolean) => {
      await onFinish(success)
      handleClose(success)
    },
    [handleClose, onFinish],
  )

  const handleConfirm = useCallback(() => handleFinish(true), [handleFinish])

  const handleCancel = useCallback(() => handleFinish(false), [handleFinish])

  return (
    <OldModal {...modalProps}>
      <Content>{content || _('modal.dialog.content')}</Content>
      <Row mx={5} my={5} spaceBetween>
        <Button big mr={100} secondary width={180} onClick={handleCancel}>
          {cancelText || _('modal.dialog.cancel')}
        </Button>

        <Button big width={180} onClick={handleConfirm}>
          {confirmText || _('modal.dialog.confirm')}
        </Button>
      </Row>
    </OldModal>
  )
}

export default DialogModal
