import React, { useCallback, useMemo } from 'react'
import PropTypes from 'prop-types'

import deleteCommunitySkillMutation from 'GraphQL/Mutations/Skill/deleteCommunitySkill.graphql'
import deleteTagMutation from 'GraphQL/Mutations/Tag/deleteTag.graphql'
import { entityCacheRemover } from 'GraphQL/Updaters/Common'
import { Interweave } from 'interweave'

import noop from 'lodash/noop'
import upperFirst from 'lodash/upperFirst'

import { Column, Divider, Modal, Text } from 'Components/UI'

import { SEARCH_TYPES } from 'Constants/ids'

import { useCommunityContext } from 'Hooks'

import { useMutation } from 'Services/Apollo'
import { useScopedI18n } from 'Services/I18n'
import toast from 'Services/Toast'

import TagUsersTable from './TagUsersTable'

function DeleteTagModal({ tag, type, mostRecentLimit, isOpen, onClose }) {
  const s = useScopedI18n('tag')
  const { community } = useCommunityContext()

  const removeMutation = useMemo(
    () =>
      type === SEARCH_TYPES.skill
        ? deleteCommunitySkillMutation
        : deleteTagMutation,
    [type],
  )

  const [deleteTag] = useMutation(removeMutation, {
    update: entityCacheRemover({
      entity: tag,
      pageSize: mostRecentLimit,
      queryKey: type === SEARCH_TYPES.skill ? 'skills' : 'tags',
    }),
  })

  const handleDelete = useCallback(async () => {
    const variables =
      type === SEARCH_TYPES.skill
        ? {
            skillId: tag?.id,
            communityId: community?.id,
          }
        : {
            tagId: tag?.id,
            communityId: community?.id,
          }

    try {
      await deleteTag({
        variables,
      })

      toast.success({
        title: s('actions.delete', {
          tagName: tag?.name,
          tagType: upperFirst(tag?.kind || SEARCH_TYPES.skill),
        }),
        text: s('deleteSuccess', {
          tagName: tag?.name,
          tagType: upperFirst(tag?.kind || SEARCH_TYPES.skill),
        }),
      })

      onClose()
    } catch (error) {
      toast.error({
        title: s('actions.delete', {
          tagName: tag?.name,
          tagType: upperFirst(tag?.kind || SEARCH_TYPES.skill),
        }),
        text: error.message,
      })
    }
  }, [type, tag, community, deleteTag, s, onClose])

  const confirmMessageTagUsers = useMemo(() => {
    if (tag?.userCount === 1) {
      return s('messages.deleteTagUser', {
        userCount: tag?.userCount,
      })
    }
    if (tag?.userCount > 1) {
      return s('messages.deleteTagUsers', {
        userCount: tag?.userCount,
      })
    }
    return null
  }, [tag?.userCount, s])

  return (
    <Modal
      cancelText="Cancel"
      confirmText="Ok"
      isOpen={isOpen}
      noScroll
      p={0}
      title={s('actions.delete', {
        tagName: tag?.name,
        tagType: upperFirst(tag?.kind || SEARCH_TYPES.skill),
      })}
      onClose={onClose}
      onConfirm={handleDelete}
    >
      <Column gap={4} py={4} width={['100%', '100%', '600px']}>
        <Column px={5}>
          {confirmMessageTagUsers && (
            <Text as="div" bodyMedium header>
              <Interweave content={confirmMessageTagUsers} />
            </Text>
          )}

          <Text as="div" bodyMedium header>
            <Interweave
              content={s('messages.deleteTag', {
                tagName: tag?.name,
                tagType: upperFirst(tag?.kind || SEARCH_TYPES.skill),
              })}
            />
          </Text>
        </Column>

        <Divider />

        <Column px={5}>
          <TagUsersTable tagId={tag?.id} type={type} />
        </Column>
      </Column>
    </Modal>
  )
}

DeleteTagModal.defaultProps = {
  isOpen: false,
  tag: null,
  mostRecentLimit: 0,
  onClose: noop,
}

DeleteTagModal.propTypes = {
  isOpen: PropTypes.bool,
  mostRecentLimit: PropTypes.number,
  tag: PropTypes.object,
  type: PropTypes.string.isRequired,
  onClose: PropTypes.func,
}

export default DeleteTagModal
