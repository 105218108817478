import React from 'react'
import { CellProps, Renderer } from 'react-table'

import { DateTime } from 'luxon'
import { getFullName } from 'Utils/User'

import startCase from 'lodash/startCase'

import { LONG_DASH } from 'Constants/strings'

export const renderDateTimeCell: Renderer<CellProps<any, string>> = ({
  value,
}) => {
  if (value) {
    return (
      <>{DateTime.fromISO(value).toLocaleString(DateTime.DATETIME_SHORT)}</>
    )
  }
  return <>{LONG_DASH}</>
}

export function renderOptionalString({ value }: { value?: string }) {
  if (!value) {
    return LONG_DASH
  }
  return value
}

export interface IComponentToRender {
  checked?: boolean
  readOnly?: boolean
}

export function createBooleanCellRenderer({
  render: ComponentToRender,
}: {
  render: React.ComponentType<IComponentToRender>
}) {
  return ({ value }: { value?: boolean }) => (
    <ComponentToRender checked={value} readOnly />
  )
}

export function renderStartCaseCell({ value }: { value?: string }) {
  return renderOptionalString({ value: startCase(value) })
}

export function renderDoubleCell({ value }: { value?: number }) {
  return renderOptionalString({ value: value?.toFixed(2) })
}

export const renderProfileFullName: Renderer<
  CellProps<any, MainSchema.User | undefined>
> = ({ value }) => {
  return <>{getFullName(value?.profile)}</>
}

export function renderCommunityName({
  value,
}: {
  value?: MainSchema.Community
}) {
  return value ? `${value?.name || ''}` : LONG_DASH
}
