import React, { MouseEventHandler } from 'react'

import { pick } from '@styled-system/props'

import Text from 'Components/UI/Text'

import { IBase, StyledActiveBox, StyledButton, StyledNavLink } from './styles'

export interface IMenuItem extends IBase {
  caption: string
  isActive?: boolean
  isButton?: boolean
  to?: string
  onClick?: MouseEventHandler<HTMLButtonElement>
}

function MenuItem({
  isActive = false,
  isButton = false,
  caption,
  to = '/',
  onClick,
  ...rest
}: IMenuItem) {
  if (isActive) {
    return (
      <StyledActiveBox {...pick(rest)}>
        <Text header header4>
          {caption}
        </Text>
      </StyledActiveBox>
    )
  }

  return isButton ? (
    <StyledButton {...pick(rest)} onClick={onClick}>
      <Text body header4>
        {caption}
      </Text>
    </StyledButton>
  ) : (
    <StyledNavLink {...pick(rest)} to={to}>
      <Text body header4>
        {caption}
      </Text>
    </StyledNavLink>
  )
}

export default MenuItem
