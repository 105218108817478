import React, { useMemo } from 'react'
import PropTypes from 'prop-types'

import {
  IconAt,
  IconBrandFacebook,
  IconBrandLinkedin,
  IconBrandTwitter,
  IconBulb,
  IconCalendarEvent,
  IconId,
  IconMail,
  IconPhone,
  IconPuzzle,
  IconSearch,
  IconTag,
  IconUsers,
} from '@tabler/icons-react'
import { getFullName } from 'Utils/User'

import upperFirst from 'lodash/upperFirst'

import { Text } from 'Components/UI'

import { PROPOSAL_KIND } from 'Constants/ids'

import { Content } from './styles'

const ICONS = {
  [PROPOSAL_KIND.CONNECTION]: <IconAt />,
  [PROPOSAL_KIND.CUSTOM]: <IconTag />,
  [PROPOSAL_KIND.EMAIL]: <IconMail />,
  [PROPOSAL_KIND.EVENT]: <IconCalendarEvent />,
  [PROPOSAL_KIND.GROUP]: <IconUsers />,
  [PROPOSAL_KIND.NEED_SKILL]: <IconSearch />,
  [PROPOSAL_KIND.PHONE]: <IconPhone />,
  [PROPOSAL_KIND.PROJECT]: <IconBulb />,
  [PROPOSAL_KIND.ROLE]: <IconId />,
  [PROPOSAL_KIND.SKILL]: <IconPuzzle />,
  [PROPOSAL_KIND.TWITTER_URL]: <IconBrandTwitter />,
  [PROPOSAL_KIND.FACEBOOK_URL]: <IconBrandFacebook />,
  [PROPOSAL_KIND.LINKEDIN_URL]: <IconBrandLinkedin />,
}

function Proposal({ row }) {
  const proposal = useMemo(() => row?.original, [row])

  const name = useMemo(() => {
    switch (proposal?.kind) {
      case PROPOSAL_KIND.SKILL:
      case PROPOSAL_KIND.NEED_SKILL:
        return proposal?.skill?.name
      case PROPOSAL_KIND.CONNECTION:
        return getFullName(proposal?.proposedUser?.profile)
      default:
        return proposal?.tag?.name ?? proposal?.value
    }
  }, [proposal])

  const icon = ICONS[proposal?.kind] ?? null

  const kind = useMemo(() => {
    if (proposal?.kind === PROPOSAL_KIND.FACEBOOK_URL) return 'Facebook'
    if (proposal?.kind === PROPOSAL_KIND.TWITTER_URL) return 'Twitter'
    if (proposal?.kind === PROPOSAL_KIND.LINKEDIN_URL) return 'LinkedIn'

    return upperFirst(proposal?.kind)
  }, [proposal])

  const isUrl = proposal?.kind.endsWith('Url')

  return (
    <Content title={name}>
      {icon}

      <Text bodyMedium header ml={2}>
        {kind}:{' '}
      </Text>

      <Text
        header
        header4
        ml={1}
        style={
          isUrl
            ? {
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
                overflow: 'hidden',
              }
            : {}
        }
      >
        {name}
      </Text>
    </Content>
  )
}

Proposal.defaultProps = {
  row: {},
}

Proposal.propTypes = {
  row: PropTypes.object,
}

export default Proposal
