import reduce from 'lodash/reduce'

import { TAG_KIND } from 'Constants/ids'

export interface ITagsByKind {
  roles: MainSchema.Tag[]
  projects: MainSchema.Tag[]
  custom: MainSchema.Tag[]
  groups: MainSchema.Tag[]
  events: MainSchema.Tag[]
  industries: MainSchema.Tag[]
}

export function tagsByKind(tags: MainSchema.Tag[]): ITagsByKind {
  return reduce(
    tags,
    (acc: ITagsByKind, tag) => {
      switch (tag.kind) {
        case TAG_KIND.ROLE:
          acc.roles.push(tag)
          break
        case TAG_KIND.PROJECT:
          acc.projects.push(tag)
          break
        case TAG_KIND.CUSTOM:
          acc.custom.push(tag)
          break
        case TAG_KIND.GROUP:
          acc.groups.push(tag)
          break
        case TAG_KIND.EVENT:
          acc.events.push(tag)
          break
        case TAG_KIND.INDUSTRY:
          acc.industries.push(tag)
          break
        default:
          break
      }

      return acc
    },
    {
      roles: [],
      projects: [],
      custom: [],
      groups: [],
      events: [],
      industries: [],
    },
  )
}
