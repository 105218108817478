export interface Address {
  address1?: string
  address2?: string
  locality?: string
  region?: string
  postalCode?: string
  country?: string
}

export function formatAddress(address: Address, lineBreak: string = '\n') {
  const { address1, address2, locality, region, postalCode, country } = address
  let formattedAddress = ''

  if (address1) {
    formattedAddress += `${address1}${lineBreak}`
  }

  if (address2) {
    formattedAddress += `${address2}${lineBreak}`
  }

  if (locality) {
    formattedAddress += locality

    if (region || postalCode) {
      formattedAddress += ', '
    }
  }

  if (region) {
    formattedAddress += region

    if (postalCode) {
      formattedAddress += ' '
    }
  }

  if (postalCode) {
    formattedAddress += postalCode
  }

  formattedAddress += lineBreak

  if (country) {
    formattedAddress += country
  }

  return formattedAddress.trim()
}
