import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'

import { FiBold, FiItalic, FiLink, FiLink2, FiUnderline } from 'react-icons/fi'

import { pick } from '@styled-system/props'

import { createEditor, Descendant } from 'slate'
import { withHistory } from 'slate-history'
import {
  Editable,
  RenderElementProps,
  RenderLeafProps,
  Slate,
  withReact,
} from 'slate-react'
import { CustomTextMark, ElementType } from 'Types/slate'

import { Column, IColumn } from 'Components/UI/Flex'

import withLinks from './plugins/withLinks'
import Element from './Element'
import ElementButton from './ElementButton'
import Leaf from './Leaf'
import LeafButton from './LeafButton'
import { Container, EditableWrapper, ErrorWrapper, Toolbar } from './styles'
import { deserialize, htmlToDOM, serialize } from './utils'

const EMPTY_VALUE: Descendant[] = [
  { type: ElementType.Paragraph, children: [{ text: '' }] },
]

const MAX_HEIGHT = 240

export interface ITextAreaEditorProps extends IColumn {
  content: string
  disabled?: boolean
  error?: string | null
  placeholder?: string
  onChange?: (value: string | null) => void
}

function TextAreaEditor({
  content,
  disabled = false,
  placeholder,
  error,
  onChange,
  ...rest
}: ITextAreaEditorProps) {
  const [value, setValue] = useState(EMPTY_VALUE)
  const [focused, setFocused] = useState(false)
  const editableRef = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    try {
      let DOMElement = htmlToDOM(content || '<p></p>')

      const isText =
        DOMElement.body.childNodes.length === 1 &&
        DOMElement.body.childNodes[0].nodeType === Node.TEXT_NODE
      if (isText) {
        DOMElement = htmlToDOM(`<p>${content}</p>`)
      }

      setValue(deserialize(DOMElement.body) as Descendant[])
    } catch (error) {
      let message

      if (error instanceof Error) {
        message = error.message
      }

      // eslint-disable-next-line no-console
      console.error(
        `Error occurred while parsing html: "${message}". Please contact developers`,
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const renderElement = useCallback(
    (props: RenderElementProps) => <Element {...props} />,
    [],
  )
  const renderLeaf = useCallback(
    (props: RenderLeafProps) => <Leaf {...props} />,
    [],
  )

  const editor = useMemo(
    () => withLinks(withHistory(withReact(createEditor()))),
    [],
  )

  const handleChange = useCallback(
    (changedValue: Descendant[]) => {
      if (changedValue !== value) {
        const serializedValue = serialize(editor)
        setValue(changedValue)

        onChange?.(serializedValue === '<p></p>' ? null : serializedValue)
      }
    },
    [value, editor, onChange],
  )

  const handleFocus = useCallback(() => {
    setFocused(true)
  }, [])

  const handleBlur = useCallback(() => {
    setFocused(false)
  }, [])

  return (
    <Column {...pick(rest)}>
      <Container withError={!!error}>
        <Slate editor={editor} initialValue={value} onChange={handleChange}>
          <EditableWrapper maxHeight={MAX_HEIGHT} ref={editableRef}>
            <Editable
              placeholder={placeholder}
              readOnly={disabled}
              renderElement={renderElement}
              renderLeaf={renderLeaf}
              spellCheck
              style={{
                minHeight: 100,
                padding: '12px 16px',
              }}
              onBlur={handleBlur}
              onFocus={handleFocus}
            />
          </EditableWrapper>
          {focused && (
            <Toolbar>
              <LeafButton
                icon={<FiBold size={14} />}
                mark={CustomTextMark.Bold}
              />
              <LeafButton
                icon={<FiItalic size={14} />}
                mark={CustomTextMark.Italic}
              />
              <LeafButton
                icon={<FiUnderline size={14} />}
                mark={CustomTextMark.Underline}
              />
              <ElementButton
                icon={<FiLink2 size={14} />}
                type={ElementType.Link}
              />
              <ElementButton
                className="link-button"
                icon={<FiLink size={14} />}
                type={ElementType.Link}
              />
            </Toolbar>
          )}
        </Slate>
      </Container>
      {error && <ErrorWrapper>{error}</ErrorWrapper>}
    </Column>
  )
}

export default TextAreaEditor
