import { down } from 'styled-breakpoints'
import styled from 'styled-components'
import { position, PositionProps } from 'styled-system'
import { themeGet } from '@styled-system/theme-get'

import { customScrollbar } from 'Theme/styles'

export interface IContainerProps extends PositionProps {}

export const Container = styled.div<IContainerProps>`
  display: flex;
  width: 350px;
  border-left: 1px solid ${themeGet('colors.divider.default')};
  flex-direction: column;
  padding: 0;
  background-color: ${themeGet('colors.bg.primary')};
  flex-shrink: 0;
  height: 100%;
  overflow-y: hidden;

  ${down('md')} {
    width: 100%;
    border: 0;
  }

  ${position}
`

export const Divider = styled.div`
  display: flex;
  flex-shrink: 0;
  height: 1px;
  width: 100%;
  background-color: ${themeGet('colors.divider.default')};
`

export const NoteContainer = styled.div`
  display: flex;
  height: 100%;
  padding: ${themeGet('space.2')}px ${themeGet('space.5')}px;
  gap: ${themeGet('space.2')}px;
  flex-grow: 1;
  flex-direction: column;
  justify-content: space-between;
  overflow-y: hidden;
`

export const ScrollContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow-y: auto;

  ${customScrollbar}
`
