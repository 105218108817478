import styled, { css } from 'styled-components'
import { themeGet } from '@styled-system/theme-get'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  cursor: pointer;
`

export interface ITitleIsOpenCssProps {
  isOpened?: boolean
}

const titleIsOpenCss = ({ isOpened }: ITitleIsOpenCssProps) =>
  isOpened &&
  css`
    background-color: ${themeGet('colors.bg.overlay')};
    > svg {
      transform: rotateZ(180deg);
    }
  `

export interface ITitleProps extends ITitleIsOpenCssProps {}

export const Title = styled.div<ITitleProps>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 52px;
  border-bottom: 1px solid ${themeGet('colors.divider.default')};
  padding: 0 ${themeGet('space.6')}px;

  ${titleIsOpenCss}
`

export const Items = styled.div`
  display: flex;
  align-items: center;
  padding: ${themeGet('space.4')}px ${themeGet('space.6')}px;
  border-bottom: 1px solid ${themeGet('colors.divider.default')};
`
