export interface IRadioButtons {
  size: {
    default: number
  }
  borderRadius: {
    default: number
  }
  borderWidth: {
    unselected: number
    selected: number
  }
}

const radioButtons: IRadioButtons = {
  size: { default: 16 },

  borderRadius: { default: 8 },

  borderWidth: { unselected: 1, selected: 5 },
}

export default radioButtons
