import React from 'react'
import { toast as toastify, ToastOptions } from 'react-toastify'

import Toast from 'Components/UI/Toast'

import { TOAST_KIND } from 'Constants/toasts'

export interface IToast {
  title: React.ReactNode
  text: React.ReactNode
}

const toast = {
  info: ({ title, text }: IToast, options?: ToastOptions) =>
    toastify.info(
      <Toast kind={TOAST_KIND.INFO} text={text} title={title} />,
      options,
    ),

  warning: ({ title, text }: IToast, options?: ToastOptions) =>
    toastify.warning(
      <Toast kind={TOAST_KIND.WARNING} text={text} title={title} />,
      options,
    ),

  error: ({ title, text }: IToast, options?: ToastOptions) =>
    toastify.error(
      <Toast kind={TOAST_KIND.ERROR} text={text} title={title} />,
      options,
    ),

  success: ({ title, text }: IToast, options?: ToastOptions) =>
    toastify.success(
      <Toast kind={TOAST_KIND.SUCCESS} text={text} title={title} />,
      options,
    ),
}

export default toast
