import { Node } from 'regraph'

import { IItemData, IKnowledgeNode } from 'Components/Blocks/Graph/utils'

import { ItemType } from 'Constants/graph'
import { KnowledgeGraphNodeKind } from 'Constants/mainGraphQL'

const nodeColorMap: Record<KnowledgeGraphNodeKind, string> = {
  [KnowledgeGraphNodeKind.Technology]: '#A3E4D7', // Light teal for technology
  [KnowledgeGraphNodeKind.Topic]: '#D6C0F3', // Light lavender for topic
  [KnowledgeGraphNodeKind.Event]: '#F5CBA7', // Light peach for event
  [KnowledgeGraphNodeKind.Location]: '#AED6F1', // Light blue for location
  [KnowledgeGraphNodeKind.Organization]: '#F9E79F', // Light yellow for organization
  [KnowledgeGraphNodeKind.Person]: '#D7BDE2', // Light purple for person
  [KnowledgeGraphNodeKind.Skill]: '#D5DBDB', // Light gray for skill
}

const nodeHaloColorMap: Record<KnowledgeGraphNodeKind, string> = {
  [KnowledgeGraphNodeKind.Technology]: '#C1F0E3', // Lighter teal for technology halo
  [KnowledgeGraphNodeKind.Topic]: '#E6E0F3', // Lighter lavender for topic halo
  [KnowledgeGraphNodeKind.Event]: '#FAD7BD', // Lighter peach for event halo
  [KnowledgeGraphNodeKind.Location]: '#D6E9F8', // Lighter blue for location halo
  [KnowledgeGraphNodeKind.Organization]: '#FCF3CF', // Lighter yellow for organization halo
  [KnowledgeGraphNodeKind.Person]: '#E8DAEF', // Lighter purple for person halo
  [KnowledgeGraphNodeKind.Skill]: '#E5E8E8', // Lighter gray for skill halo
}

export function createKnowledgeNode({
  knowledgeNode,
  isSelected,
}: {
  knowledgeNode: MainSchema.KnowledgeGraphNode
  isSelected: boolean
}): Node<IItemData<IKnowledgeNode>> {
  const borderWidth = 1.5
  const borderToTextGap = 8
  const nodeColor = nodeColorMap[knowledgeNode.kind]
  const haloColor = nodeHaloColorMap[knowledgeNode.kind]

  return {
    data: {
      id: knowledgeNode.id,
      type: ItemType.Knowledge,
      data: knowledgeNode,
      entity: knowledgeNode,
      cluster: `Knowledge`,
    },
    color: nodeColor,
    shape: 'circle',
    border: {
      color: isSelected ? haloColor : 'transparent',
      width: borderWidth,
    },
    halos: [
      {
        color: haloColor,
        radius: 28,
        width: isSelected ? 8 : 0,
      },
    ],
    label: [
      {
        backgroundColor: 'transparent',
        color: isSelected ? '#2D2D2D' : '#2D2D2D',
        fontFamily: 'Inter',
        fontSize: 14,
        bold: true,
        text: knowledgeNode.valueString,
        textWrap: 'normal',
        minWidth: 200,
        position: {
          horizontal: 'center',
          vertical: 'middle',
        },
      },
      {
        backgroundColor: 'transparent',
        margin: {
          top: 50 + borderWidth * 2 + borderToTextGap,
          bottom: 0,
          left: 0,
          right: 0,
        },
        color: '#757575',
        fontFamily: 'Inter',
        fontSize: 12,
        text: `${knowledgeNode.kind.charAt(0).toUpperCase() + knowledgeNode.kind.slice(1)}`,
        textWrap: 'normal',
        minWidth: 200,
        position: {
          horizontal: 'center',
          vertical: 'top',
        },
      },
    ],
  }
}
