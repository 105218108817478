import React from 'react'
import PropTypes from 'prop-types'

import capitalize from 'lodash/capitalize'

import { Row, Text } from 'Components/UI'

import { StatusMarker } from '../styles'

function StatusCell({ value }) {
  return (
    <Row center>
      <StatusMarker status={value} />

      <Text actionMedium body>
        {capitalize(value)}
      </Text>
    </Row>
  )
}

StatusCell.defaultProps = {
  value: null,
}

StatusCell.propTypes = {
  value: PropTypes.string,
}

export default StatusCell
