import React from 'react'
import { components } from 'react-select'

import { Value } from './styles'

const SingleValue: typeof components.SingleValue = props => {
  // TODO: find a better way to satisfy the type
  const StyledValue = Value as any
  return <StyledValue {...props} as={components.SingleValue} />
}

export default SingleValue
