import React, { useCallback, useMemo, useState } from 'react'

import { omit, pick } from '@styled-system/props'

import map from 'lodash/map'

import {
  ErrorWrapper,
  InnerContainer,
  Input,
  Item,
  ItemsContainer,
  RemoveIcon,
} from './styles'

import { Button } from '../../Button'
import { Column, IColumn } from '../../Flex'
import { IInput } from '../Input/Input'

export interface IInputListProps
  extends IColumn,
    Omit<IInput, 'error' | 'value' | 'onChange'> {
  buttonText: string
  displayError?: boolean
  error?: string | null
  value?: string[]
  onChange?: (value: string[] | null) => void
}

function InputList({
  placeholder,
  buttonText,
  value = [],
  displayError = true,
  error,
  onChange,
  ...rest
}: IInputListProps) {
  const styledProps = pick(rest)
  const inputProps = omit(rest)

  const [inputValue, setInputValue] = useState('')

  const handleAddItem = useCallback(() => {
    if (!inputValue || value?.indexOf(inputValue) !== -1) return

    onChange?.([...value, inputValue])
    setInputValue('')
  }, [value, inputValue, onChange])

  const handleRemoveItem = useCallback(
    (removedItem: string) => () => {
      const nextItems = value?.filter(item => item !== removedItem)
      onChange?.(nextItems.length === 0 ? null : nextItems)
    },
    [value, onChange],
  )

  const items = useMemo(
    () =>
      map(value, item => (
        <Item key={item}>
          {item} <RemoveIcon ml="6px" onClick={handleRemoveItem(item)} />
        </Item>
      )),
    [value, handleRemoveItem],
  )

  return (
    <Column {...styledProps}>
      <InnerContainer gap={4}>
        <Input
          {...inputProps}
          placeholder={placeholder}
          value={inputValue}
          onChange={event => setInputValue(event.target.value)}
        />
        <Button big disabled={!inputValue} onClick={handleAddItem}>
          {buttonText}
        </Button>
      </InnerContainer>

      {items.length > 0 && (
        <ItemsContainer gap={2} mt={4}>
          {items}
        </ItemsContainer>
      )}

      {displayError && error && <ErrorWrapper>{error}</ErrorWrapper>}
    </Column>
  )
}

export default InputList
