import React from 'react'
import PropTypes from 'prop-types'

import { Text } from 'Components/UI'

import { LONG_DASH } from 'Constants/strings'

const SOURCE_TYPES = {
  networkOS: 'NetworkOS',
  nylas: 'Email',
  nylasEmail: 'Google Email',
  nylasCalendar: 'Google Calendar',
  nylasContacts: 'Google Contact',
  hubSpot: 'HubSpot',
}

function Source({ value }) {
  return (
    <Text primary>{value ? `${SOURCE_TYPES[value] ?? ''}` : LONG_DASH}</Text>
  )
}

Source.defaultProps = {
  value: {},
}

Source.propTypes = {
  value: PropTypes.object,
}

export default Source
