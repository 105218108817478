import { useMemo } from 'react'

import Utils from 'Utils'

import { LONG_DASH, ROLE_NAMES } from 'Constants/strings'

import { ActionsCell } from './Cells'

export function useColumns() {
  return useMemo(
    () => [
      {
        Header: 'Name',
        accessor: 'profile',
        Cell: renderFullName,
        width: 2,
      },
      {
        Header: 'Email',
        Cell: renderUserEmail,
        accessor: 'primaryEmailCredential',
        width: 2,
      },
      {
        Header: 'Role',
        accessor: 'role',
        Cell: renderUserRole,
        width: 1,
      },
      {
        Header: 'Last logged in',
        Cell: Utils.Table.renderDateTimeCell,
        accessor: 'lastLoginAt',
        width: 1,
      },
      {
        Header: 'Actions',
        Cell: ActionsCell,
        width: 0.5,
        cellRight: true,
        headerRight: true,
      },
    ],
    [],
  )
}

function renderFullName({ value }) {
  return `${value?.firstName || '—'} ${value?.lastName || '—'}`
}

function renderUserRole({ value }) {
  return ROLE_NAMES[value] || null
}

function renderUserEmail({ value }) {
  return value?.email || LONG_DASH
}
