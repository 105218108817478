import React, { forwardRef, PropsWithChildren } from 'react'

import { Menu } from './styles'

export interface IContextMenu extends PropsWithChildren {
  isOpen: boolean
  left?: string | number
  right?: string | number
  top?: string | number
}

const ContextMenu = forwardRef<HTMLDivElement, IContextMenu>(
  ({ children, left = 0, top = 0, right = 'unset', isOpen }, ref) => {
    if (!isOpen) {
      return null
    }

    return (
      <Menu left={left} ref={ref} right={right} top={top}>
        {children}
      </Menu>
    )
  },
)

export default ContextMenu
