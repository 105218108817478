import merge from 'lodash/merge'

import avatars from './avatars'
import badges from './badges'
import buttons from './buttons'
import checkboxes from './checkboxes'
import colors from './colors'
import datePickers from './datePickers'
import graph from './graph'
import inputs from './inputs'
import links from './links'
import menuItems from './menuItems'
import modals from './modals'
import oldColors from './oldColors'
import pagination from './pagination'
import radioButtons from './radioButtons'
import selects from './selects'
import shadows from './shadows'
import switches from './switches'
import tabs from './tabs'
import tags from './tags'
import toasts from './toasts'

import defaultTheme from '../defaultTheme'
import palette from '../palette'

const mainTheme = {
  ...defaultTheme,

  name: 'Main theme',
  font: 'Inter, Arial, Helvetica, sans-serif;',
  webfonts: ['Inter:400,500,600,700,900'],

  transitionTime: {
    default: '250ms',
    modal: '150ms',
  },

  colors: merge(
    {
      default: palette.black,
    },
    palette,
    oldColors,
    colors,
  ),
  avatars,
  badges,
  buttons,
  checkboxes,
  datePickers,
  inputs,
  links,
  menuItems,
  modals,
  pagination,
  radioButtons,
  selects,
  shadows,
  switches,
  tabs,
  tags,
  toasts,
  graph,
}

export default mainTheme
