import React, {
  forwardRef,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'
import ContentEditable from 'react-contenteditable'
import { createPortal } from 'react-dom'

import { useOnClickOutside } from 'Hooks'

import {
  CommandDropdownContainer,
  Container,
  ContentEditableTextArea,
  IAppContentEditableProps,
} from './styles'

import { useMentionTextareaContext } from '../../context'
import { getCursorCoords } from '../../utils'

const DISABLED_KEYBOARD_KEYS = new Set([
  'Left',
  'Right',
  'Down',
  'Up',
  'ArrowDown',
  'ArrowUp',
  'ArrowLeft',
  'ArrowRight',
  'Enter',
])
export type TextareaChangeHandler = IAppContentEditableProps['onChange']
export type TextareaRemoveHandler = (option: {
  id?: string | null
  label?: string | null
  kind?: string | null
}) => void

export interface ITextareaProps extends Omit<IAppContentEditableProps, 'html'> {
  commands: React.ReactNode
  search?: React.ReactNode
  value?: string
  onRemove: TextareaRemoveHandler
}

export interface IDropdownPosition {
  top?: string | null
  left?: string | null
  bottom?: string | null
}

const Textarea = forwardRef<ContentEditable, ITextareaProps>(
  (
    {
      commands,
      disabled = false,
      placeholder,
      search,
      value = '',
      onChange,
      onRemove,
    },
    textareaRef,
  ) => {
    const dropdownRef = useRef<HTMLDivElement | null>(null)
    const dropdownVisibleInnerRef = useRef(false)

    const { dropdownVisible, onSelectDropdownVisible, onReset } =
      useMentionTextareaContext()

    const [dropdownPosition, setDropdownPosition] = useState<IDropdownPosition>(
      {
        top: null,
        left: '0px',
        bottom: null,
      },
    )

    useOnClickOutside(dropdownRef, () => onReset())

    useEffect(() => {
      dropdownVisibleInnerRef.current = dropdownVisible
    }, [dropdownVisible])

    // Observe removing mention
    useEffect(() => {
      const contentEditableElem =
        textareaRef &&
        'current' in textareaRef &&
        textareaRef?.current?.el?.current

      if (!contentEditableElem) return () => {}

      const observer = new MutationObserver(mutationsList => {
        mutationsList.forEach(mutation => {
          if (mutation.type === 'childList') {
            mutation.removedNodes.forEach(removedNode => {
              const removedElement = removedNode as HTMLElement
              if (
                removedElement.tagName === 'SPAN' &&
                removedElement.classList.contains('mention')
              ) {
                const text = removedElement.textContent
                onRemove({
                  id: removedElement.dataset?.id,
                  label: text,
                  kind: removedElement.dataset?.kind,
                })
              }
            })
          }
        })
      })

      observer.observe(contentEditableElem, {
        childList: true,
        subtree: true,
      })

      return () => {
        observer.disconnect()
      }
    }, [textareaRef, onRemove])

    // TODO: need to have fixed width for textarea
    // useEffect(() => {
    // const notesSidebar = document.getElementById(blockScrollId)

    // if (dropdownVisible) {
    //   notesSidebar.style.overflow = 'hidden'
    // } else {
    //   notesSidebar.style.overflow = 'auto'
    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [dropdownVisible])

    const handleKeyDown = useCallback<
      React.KeyboardEventHandler<HTMLDivElement>
    >(
      event => {
        if (
          // handleKeyDown is not reacting to state or props values, only refs
          dropdownVisibleInnerRef.current &&
          DISABLED_KEYBOARD_KEYS.has(event.key)
        ) {
          event.preventDefault()
          return
        }

        if (event.key === '/') {
          setTimeout(() => {
            if (!dropdownRef.current) {
              return
            }

            const { x, y } = getCursorCoords()

            const caretHeight = 12
            const dropdownHeight = dropdownRef.current.offsetHeight

            if (y + (dropdownHeight + 60) > window.innerHeight) {
              const bottom = window.innerHeight - y
              setDropdownPosition({ left: `${x}px`, bottom: `${bottom}px` })
            } else {
              const top = y + caretHeight * 2
              setDropdownPosition({ left: `${x}px`, top: `${top}px` })
            }

            onSelectDropdownVisible(true)
          }, 0)
        } else {
          onSelectDropdownVisible(false)
        }
      },
      [onSelectDropdownVisible],
    )

    const handleChange: TextareaChangeHandler = event => {
      onChange(event)
    }

    return (
      <Container>
        <ContentEditableTextArea
          disabled={disabled}
          html={value}
          placeholder={placeholder}
          // onBlur={handleBlur}
          ref={textareaRef}
          onChange={handleChange}
          onKeyDown={handleKeyDown}
        />

        <>
          {createPortal(
            <CommandDropdownContainer
              bottom={dropdownPosition.bottom}
              left={dropdownPosition.left}
              ref={dropdownRef}
              top={dropdownPosition.top}
              visible={dropdownVisible}
            >
              {search && search}
              {!search && commands}
            </CommandDropdownContainer>,
            document.body,
          )}
        </>
      </Container>
    )
  },
)

export default Textarea
