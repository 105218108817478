import React from 'react'

import styled from 'styled-components'

import { userProfileGlyph } from 'Assets/Svg/icons'

const UserProfileIcon = styled(userProfileGlyph)``

export interface IUserProfileProps {
  color?: string
  size?: number
}

const UserProfile = ({ color = 'currentColor', size }: IUserProfileProps) => {
  return (
    <UserProfileIcon color={color} display="block" height={size} width={size} />
  )
}

export default UserProfile
