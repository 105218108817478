import React, { useCallback, useState } from 'react'

import { useMutation } from '@apollo/client'
import removeNotesMutation from 'GraphQL/Mutations/Notes/removeNotes.graphql'
import { entitiesCacheRemover } from 'GraphQL/Updaters/Common'

import map from 'lodash/map'
import truncate from 'lodash/truncate'

import { Column, Input, Modal, Text } from 'Components/UI'

import { useCommunity } from 'Hooks'

import _, { useScopedI18n } from 'Services/I18n'
import toast from 'Services/Toast'

export interface INote {
  id: string
  content: string
}

export interface IDeleteNotesModalProps {
  isOpen?: boolean
  notes: INote[]
  updater?: {
    queryKey: string
    pageSize: number
  }
  onClose?: (success: boolean, noteIds: string[]) => void
}

function DeleteNotesModal({
  notes = [],
  updater = {
    queryKey: 'notes',
    pageSize: 10,
  },
  isOpen = false,
  onClose,
}: IDeleteNotesModalProps) {
  const s = useScopedI18n('modals.deleteNotes')
  const { community } = useCommunity()

  const inputRef = React.useRef<HTMLInputElement>(null)

  const [confirmDisabled, setConfirmDisabled] = useState(true)

  const [removeNotes, { loading }] = useMutation(removeNotesMutation)

  const handleFinish = useCallback(
    async (success: boolean, noteIds: string[]) => {
      onClose?.(success, noteIds)
    },
    [onClose],
  )

  const handleCancel = useCallback(
    () => handleFinish(false, []),
    [handleFinish],
  )

  const handleSuccess = useCallback(async () => {
    try {
      const noteIds = map(notes, 'id')
      await removeNotes({
        variables: {
          noteIds,
          communityId: community?.id,
        },
        update: entitiesCacheRemover({
          entities: notes,
          queryKey: updater.queryKey,
          pageSize: updater.pageSize,
        }),
      })

      toast.success({
        title: s('title'),
        text: s('successMessage'),
      })

      await handleFinish(true, noteIds)
    } catch (error) {
      let message = _(`error.generic`)

      if (error instanceof Error) {
        message = error.message
      }

      toast.error({
        title: s('title'),
        text: message,
      })
    }
  }, [handleFinish, removeNotes, notes, s, updater, community])

  const handleInputChange = useCallback(() => {
    setConfirmDisabled(
      !inputRef.current?.value || inputRef.current?.value !== 'DELETE',
    )
  }, [])

  if (!isOpen) return null

  return (
    <Modal
      cancelText="Cancel"
      confirmDisabled={confirmDisabled || loading}
      confirmText="Delete"
      isOpen
      title={s('title')}
      onClose={handleCancel}
      onConfirm={handleSuccess}
    >
      <Column gap={4} width={['100%', '100%', '600px']}>
        <Text header header4>
          {s('deleteConfirmation.0')}{' '}
          <span>
            {map(notes, note => truncate(note?.content, { length: 52 })).join(
              ', ',
            )}
          </span>
          {s('deleteConfirmation.1')}
        </Text>

        <Input
          placeholder="Type DELETE"
          ref={inputRef}
          onChange={handleInputChange}
        />
      </Column>
    </Modal>
  )
}

export default DeleteNotesModal
