import React from 'react'
import PropTypes from 'prop-types'

import Utils from 'Utils'

import { Avatar, Row, Text } from 'Components/UI'

function InviteCell({ value }) {
  const profile = value?.profile
  const email = value?.email

  return (
    <Row center gap={2}>
      <Avatar small src={profile?.photoUrl} />

      <Text breakWord header header4>
        {profile?.lastName ? Utils.User.getFullName(profile) : `${email}`}
      </Text>
    </Row>
  )
}

InviteCell.defaultProps = {
  value: {},
}

InviteCell.propTypes = {
  value: PropTypes.object,
}

export default InviteCell
