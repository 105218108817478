import React from 'react'

import { FcGoogle } from 'react-icons/fc'

export interface IGoogle {
  color?: string
  size?: number
}

const Google = ({ color, size }: IGoogle) => {
  return <FcGoogle color={color} display="block" size={size} />
}

export default Google
