import React from 'react'
import { components } from 'react-select'

import { GroupHeadingWrapper } from './styles'

const GroupHeading: typeof components.GroupHeading = props => {
  // TODO: find a better way to satisfy the type
  const StyledGroupHeadingWrapper = GroupHeadingWrapper as any
  return <StyledGroupHeadingWrapper {...props} as={components.GroupHeading} />
}

export default GroupHeading
