import { RefObject, useEffect } from 'react'

function useScrollToSelected(
  ref: RefObject<HTMLElement>,
  index: number | null,
) {
  useEffect(() => {
    const parentElement = ref.current

    if (!parentElement || index === null || index < 0) {
      return () => {}
    }

    const selectedElement = parentElement?.children[index] as HTMLElement

    if (!selectedElement) {
      return () => {}
    }

    const observer = new IntersectionObserver(
      ([entry]) => {
        observer.unobserve(entry.target)

        if (entry.intersectionRatio > 0.9) {
          return
        }

        const topOffset = selectedElement.offsetTop - parentElement.offsetTop

        parentElement.scrollTo({
          top: topOffset,
          behavior: 'smooth',
        })
      },
      {
        root: selectedElement,
        threshold: 1,
      },
    )

    observer.observe(selectedElement)

    return () => {
      observer.disconnect()
    }
  }, [index, ref])
}

export default useScrollToSelected
