import { BaseEditor } from 'slate'
import { HistoryEditor } from 'slate-history'
import { ReactEditor } from 'slate-react'

export type CustomEditor = BaseEditor & ReactEditor & HistoryEditor

export enum CustomTextMark {
  Bold = 'bold',
  Code = 'code',
  Italic = 'italic',
  Underline = 'underline',
  Strikethrough = 'strikethrough',
}

export type CustomText = {
  text: string
  [CustomTextMark.Bold]?: boolean
  [CustomTextMark.Code]?: boolean
  [CustomTextMark.Italic]?: boolean
  [CustomTextMark.Underline]?: boolean
  [CustomTextMark.Strikethrough]?: boolean
}

export enum ElementType {
  Paragraph = 'paragraph',
  OrderedList = 'ordered-list',
  UnorderedList = 'unordered-list',
  ListItem = 'list-item',
  Quote = 'quote',
  Code = 'code',
  Link = 'link',
}

export type ParagraphElement = {
  type: ElementType.Paragraph
  children: (CustomElement | CustomText)[]
}

export type OrderedListElement = {
  type: ElementType.OrderedList
  children: (CustomElement | CustomText)[]
}

export type UnorderedListElement = {
  type: ElementType.UnorderedList
  children: (CustomElement | CustomText)[]
}

export type ListItemElement = {
  type: ElementType.ListItem
  children: (CustomElement | CustomText)[]
}

export type QuoteElement = {
  type: ElementType.Quote
  children: (CustomElement | CustomText)[]
}

export type CodeElement = {
  type: ElementType.Code
  children: (CustomElement | CustomText)[]
}

export type LinkElement = {
  type: ElementType.Link
  url?: string
  className?: string
  children: (CustomElement | CustomText)[]
}

export type CustomElement =
  | ParagraphElement
  | OrderedListElement
  | UnorderedListElement
  | ListItemElement
  | QuoteElement
  | CodeElement
  | LinkElement

declare module 'slate' {
  interface CustomTypes {
    Editor: CustomEditor
    Element: CustomElement
    Text: CustomText
  }
}
