import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import AuthService from 'Services/Auth'

function SignOut() {
  const navigate = useNavigate()

  useEffect(() => {
    async function logOut() {
      await AuthService.logOut(navigate)
    }
    logOut()
  })

  return null
}

export default SignOut
