import React, { PropsWithChildren, useCallback } from 'react'

import { I18n, Scope, TranslateOptions } from 'i18n-js'

import { Locale } from 'Constants/ids'

import { getLocale, setLocale } from 'Services/Store/i18n'

import mainEn from './translations/en.json'

export const validLocales: string[] = [Locale.English]

const translationsSet: Record<Locale, object> = {
  [Locale.English]: mainEn,
}

const i18n = new I18n(translationsSet)

const defaultLocale = Locale.English

export function detectLocale() {
  const { language } = window.navigator

  return language ? language.substring(0, 2) : undefined
}

export function initTranslations() {
  let locale: string | undefined = getLocale()

  if (!locale) {
    locale = detectLocale()
  }

  if (!locale || !validLocales.includes(locale)) {
    locale = defaultLocale
  }

  setLocale(locale as Locale)
  i18n.locale = locale
}

function _(key: Scope, options?: TranslateOptions) {
  return i18n.t(key, options)
}

export function useScopedI18n(key: Scope) {
  return useCallback(
    (subKey: Scope, options?: TranslateOptions) =>
      _(`${key}.${subKey}`, options),
    [key],
  )
}

export function LocaleRenderer({ children }: PropsWithChildren) {
  return <>{children}</>
}

export default _
