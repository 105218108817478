import React, { ChangeEventHandler, useCallback, useState } from 'react'

import { IconPlus } from '@tabler/icons-react'
import addUserEmailMutation from 'GraphQL/Mutations/User/addUserEmail.graphql'
import { meAddUserEmailUpdater } from 'GraphQL/Updaters/Me'

import { Column, Input, Loader, Row } from 'Components/UI'
import { Link, Text } from 'Components/UI/_v2'

import { EMAIL_STRING_REGEX } from 'Constants/regex'

import { useCommunity } from 'Hooks'

import { useMutation } from 'Services/Apollo'
import _ from 'Services/I18n'
import toast from 'Services/Toast'

import List from './List'

import Container from '../Container'

function AdditionalEmails() {
  const { community } = useCommunity()

  const [isAddingEmail, setIsAddingEmail] = useState(false)
  const [value, setValue] = useState('')
  const [hasError, setHasError] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const [addUserEmail] = useMutation(addUserEmailMutation)

  const handleClear = useCallback(() => {
    setHasError(false)
    setValue('')
  }, [])

  const handleChange: ChangeEventHandler<HTMLInputElement> = useCallback(
    event => {
      setValue(event.target.value)
    },
    [],
  )

  const handleCancel = useCallback(() => {
    setIsAddingEmail(false)
    setHasError(false)
    setValue('')
  }, [])

  const handleSave = useCallback(async () => {
    const isValid = new RegExp(EMAIL_STRING_REGEX).test(value)

    if (!isValid) {
      setHasError(true)
      return
    }

    try {
      setIsLoading(true)

      await addUserEmail({
        variables: {
          email: value,
        },
        update: community
          ? meAddUserEmailUpdater({ communityId: community.id })
          : undefined,
      })

      toast.success({
        title: 'Add additional email',
        text: 'Record has been added, finish verification at your email.',
      })

      setValue('')
      setHasError(false)
      setIsAddingEmail(false)
    } catch (error) {
      let message = _('error.generic')

      if (error instanceof Error && error.message) {
        message = _(`error.${error.message}`)
      }

      toast.error({
        title: 'Add additional email',
        text: message,
      })
    } finally {
      setIsLoading(false)
    }
  }, [addUserEmail, value, community])

  return (
    <Column gap={6}>
      <List />
      <Container>
        <Column alignItems="start">
          {!isAddingEmail && (
            <Link onClick={() => setIsAddingEmail(true)}>
              <Row center gap={1}>
                <IconPlus size={16} />
                <Text fontWeight={600} variant="body-xs">
                  Add Email
                </Text>
              </Row>
            </Link>
          )}

          {isAddingEmail && (
            <>
              <Input
                clearable
                error={hasError}
                isLoading={isLoading}
                label="New Email Address"
                placeholder="Email address"
                value={value}
                width={1}
                onChange={handleChange}
                onClear={handleClear}
              />

              <Row gap={3} mt={2}>
                <Link
                  isDisabled={isLoading}
                  variant="secondary"
                  onClick={handleCancel}
                >
                  <Text fontWeight={600} variant="body-xs">
                    Cancel
                  </Text>
                </Link>
                <Link isDisabled={isLoading} onClick={handleSave}>
                  <Text fontWeight={600} variant="body-xs">
                    {isLoading ? <Loader /> : 'Save'}
                  </Text>
                </Link>
              </Row>
            </>
          )}
        </Column>
      </Container>
    </Column>
  )
}

export default AdditionalEmails
