import { captureException } from '@sentry/browser'
import { File as BufferFile } from 'buffer'
import qs from 'qs'
import superagent from 'superagent'

import forOwn from 'lodash/forOwn'
import noop from 'lodash/noop'

import { API, IS_PRODUCTION } from 'Config'

import { getAccessToken } from 'Services/Store/auth'

export enum HttpMethod {
  Get = 'Get',
  Post = 'Post',
  Put = 'Put',
  Patch = 'Patch',
  Delete = 'Delete',
}

const requestMethod = (
  httpMethod: HttpMethod,
): 'get' | 'post' | 'put' | 'patch' | 'delete' => {
  switch (httpMethod) {
    case HttpMethod.Post:
      return 'post'
    case HttpMethod.Put:
      return 'put'
    case HttpMethod.Patch:
      return 'patch'
    case HttpMethod.Delete:
      return 'delete'
    case HttpMethod.Get:
    default:
      return 'get'
  }
}

const sendMethod = (httpMethod: HttpMethod) =>
  httpMethod === HttpMethod.Post ||
  httpMethod === HttpMethod.Put ||
  httpMethod === HttpMethod.Patch ||
  httpMethod === HttpMethod.Delete
    ? 'send'
    : 'query'

const sendArguments = (
  httpMethod: HttpMethod,
  query: Record<string, unknown>,
) =>
  httpMethod === HttpMethod.Post ||
  httpMethod === HttpMethod.Put ||
  httpMethod === HttpMethod.Patch ||
  httpMethod === HttpMethod.Delete
    ? query
    : qs.stringify(query, { arrayFormat: 'brackets' })

export interface IOptions {
  url?: string
  endpoint?: string
  method?: HttpMethod
  query?: Record<string, superagent.MultipartValueSingle | undefined>
  headers?: Record<string, string>
  file?: File
  payload?: string
  withoutAuthorization?: boolean
  onProgress?: (event: superagent.ProgressEvent) => void
}

// eslint-disable-next-line prefer-regex-literals
const absoluteUrl = new RegExp('^(?:[a-z]+:)?//', 'i')

export default async function apiCall<TResponse>({
  url = `${API.URL}/v1`,
  endpoint = '',
  method = HttpMethod.Get,
  query = {},
  headers = {},
  file,
  withoutAuthorization = false,
  onProgress = noop,
  payload,
}: IOptions) {
  const allHeaders: Record<string, string> = {
    ...headers,
  }

  if (!withoutAuthorization) {
    const accessToken = getAccessToken()

    if (accessToken) {
      allHeaders.Authorization = `Bearer ${accessToken}`
    }
  }

  const HTTPMethod = requestMethod(method)

  const fullUrl = absoluteUrl.test(endpoint) ? endpoint : url + endpoint

  const request = superagent(HTTPMethod, fullUrl)

  if (file) {
    // TODO: find a better way besides casting
    request.attach('file', file as unknown as BufferFile)

    forOwn(query, (value, key) => {
      if (value) {
        request.field(key, value)
      }
    })
  } else {
    request[sendMethod(method)](sendArguments(method, query))
  }

  return new Promise<TResponse>((resolve, reject) => {
    request
      .set(allHeaders)
      .on('progress', onProgress)
      .end((error: Error, response) => {
        if (IS_PRODUCTION) {
          captureException(error) // Sentry exception capture
        }

        let body = response?.body

        if (payload) {
          body = { ...body, payload }
        }

        if (error) {
          reject(error)
        } else {
          resolve(body)
        }
      })
  })
}
