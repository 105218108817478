import { DateTime } from 'luxon'
import Cookies, { CookieSetOptions } from 'universal-cookie'

import { APP_ENV, COOKIE } from 'Config'

const defaultOptions: CookieSetOptions = {
  path: '/',
  expires: DateTime.local().plus({ years: 1 }).toJSDate(),
  domain: COOKIE.SHARED_DOMAIN,
  secure: APP_ENV !== 'development',
}

const keyWithPrefix = (key: string) => `${COOKIE.PREFIX}${key}`

let cookie = new Cookies()

function reinitialize(cookies?: string | object | null) {
  cookie = new Cookies(cookies)
}

function setItem(key: string, value: any) {
  cookie.set(keyWithPrefix(key), value, defaultOptions)
}

function getItem(key: string) {
  return cookie.get(keyWithPrefix(key), { doNotParse: true })
}

export const CookieStorage: Storage = {
  setItem,
  getItem,
  reinitialize,
  length: 0,
  clear: () => {
    throw new Error('Not implemented')
  },
  key: () => {
    throw new Error('Not implemented')
  },
  removeItem: () => {
    throw new Error('Not implemented')
  },
}
