import React, { useCallback, useState } from 'react'
import { useForm } from 'react-final-form'
import { GroupBase, MultiValueRemoveProps } from 'react-select'
import { LoadOptions } from 'react-select-async-paginate'

import { useApolloClient } from '@apollo/client'
import userCommunitiesQuery from 'GraphQL/Queries/Community/userCommunities.graphql'
import { ITagOption, tagsToOptions, tagToOption } from 'Utils/Options'

import CreateCommunityModal, {
  CreateCommunityFormField,
  CreateCommunityFormValues,
} from 'Components/Blocks/Modals/CreateCommunity'
import { SelectField, TagMultiValueRemove } from 'Components/UI'

import { SortInputOrder } from 'Constants/mainGraphQL'
import { TAG_COLOR_KIND } from 'Constants/tags'

import { useEntityModal } from 'Hooks'

import { useScopedI18n } from 'Services/I18n'
import toast from 'Services/Toast'

function renderClearIndicator(props: MultiValueRemoveProps) {
  return (
    <TagMultiValueRemove {...props} tagColorKind={TAG_COLOR_KIND.COMMUNITY} />
  )
}

export interface CommunitiesFieldProps {
  name: string
  label?: string
  placeholder?: string
  isRequired?: boolean
}

type Additional = { page: number }

function CommunitiesField({
  name,
  label,
  placeholder,
  isRequired,
}: CommunitiesFieldProps) {
  const form = useForm()
  const field = form.getFieldState(name)
  const client = useApolloClient()
  const t = useScopedI18n('components.blocks.forms.fields.communityField')
  const errorT = useScopedI18n('error')
  const [newOptionIds, setNewOptionIds] = useState<string[]>([])
  const [isLoading, setIsLoading] = useState(false)
  const [createCommunityModal, createCommunityModalActions] =
    useEntityModal<Partial<CreateCommunityFormValues>>()

  const searchCommunities = useCallback(
    async (inputValue: string, page: number) => {
      try {
        const result = await client.query<
          Pick<MainSchema.Query, 'userCommunities'>,
          MainSchema.QueryUserCommunitiesArgs
        >({
          query: userCommunitiesQuery,
          fetchPolicy: 'network-only',
          variables: {
            search: inputValue,
            limit: 25,
            page,
            // TODO: sorting on the BE is case sensitive
            sort: [
              {
                column: 'name',
                order: SortInputOrder.Asc,
              },
            ],
          },
        })
        const suggestions = result?.data

        return suggestions.userCommunities
      } catch (error) {
        let message = errorT('generic')

        if (error instanceof Error) {
          message = error.message
        }

        toast.error({
          title: 'Oops...',
          text: `The server returned an error: "${message}"`,
        })
      }

      return null
    },
    [client, errorT],
  )

  const loadOptions = useCallback<
    LoadOptions<ITagOption, GroupBase<ITagOption>, Additional>
  >(
    async (inputValue, _, additional) => {
      setIsLoading(true)
      // The pages are 0-based
      const page = additional?.page ?? 0

      try {
        const userCommunities = await searchCommunities(inputValue, page)
        const newOptions = tagsToOptions(
          userCommunities?.rows.map(
            community => ({
              id: community.id,
              kind: TAG_COLOR_KIND.COMMUNITY,
              name: community.name,
            }),
            TAG_COLOR_KIND.COMMUNITY,
          ) ?? [],
        )
        // the pages that come back are 1-based
        const hasMore = !!userCommunities && page < userCommunities.pages - 1

        return {
          options: newOptions,
          hasMore,
          additional: {
            page: page + 1,
          },
        }
      } catch (error) {
        let message = errorT('generic')
        if (error instanceof Error) {
          message = error.message
        }
        toast.error({
          title: 'Oops...',
          text: `The server returned an error: "${message}"`,
        })
      } finally {
        setIsLoading(false)
      }

      return {
        options: [],
        hasMore: false,
      }
    },
    [searchCommunities, errorT],
  )

  const handleFormatCreateLabel = useCallback(
    (inputValue: string) => {
      if (!inputValue) {
        return t('createNew.label.empty')
      }

      return t('createNew.label.notEmpty', {
        communityName: inputValue,
      })
    },
    [t],
  )

  const handleCreateOptionSelected = useCallback(
    async (inputValue: string) => {
      await createCommunityModalActions.openModal({
        [CreateCommunityFormField.Name]: inputValue,
      })
    },
    [createCommunityModalActions],
  )

  const handleCommunityCreated = useCallback(
    async (community?: MainSchema.Community) => {
      await createCommunityModalActions.closeModal()

      if (community) {
        const newOption = tagToOption(
          {
            id: community.id,
            kind: TAG_COLOR_KIND.COMMUNITY,
            name: community.name,
          },
          TAG_COLOR_KIND.COMMUNITY,
        )
        setNewOptionIds(optionIds => [...optionIds, newOption.id])
        field?.change([...(field.value || []), newOption])
      }
    },
    [createCommunityModalActions, field],
  )

  return (
    <>
      <SelectField<ITagOption, true, GroupBase<ITagOption>, Additional>
        async
        // TODO: auto refetch all pages that were previously fetched instead of just the first page
        cacheUniqs={[newOptionIds]}
        clearable
        components={{
          MultiValueRemove: renderClearIndicator,
        }}
        creatable
        createOptionPosition="first"
        debounceTimeout={300}
        defaultOptions
        formatCreateLabel={handleFormatCreateLabel}
        isLoading={isLoading}
        isMulti
        isValidNewOption={() => true}
        label={label}
        loadOptions={loadOptions}
        name={name}
        paginated
        placeholder={placeholder}
        required={isRequired}
        withPortal
        onCreateOption={handleCreateOptionSelected}
      />
      <CreateCommunityModal
        initialValues={createCommunityModal.entity}
        isOpen={createCommunityModal.isOpen}
        onClose={handleCommunityCreated}
      />
    </>
  )
}

export default CommunitiesField
