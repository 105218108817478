import React from 'react'
import { Field } from 'react-final-form'

import RelationshipStrengthSelect, {
  IOption,
} from './RelationshipStrengthSelect'

export interface RelationshipStrengthSelectFieldProps {
  name: string
}

export default function RelationshipStrengthSelectField(
  props: RelationshipStrengthSelectFieldProps,
) {
  return (
    <Field name={props.name}>
      {({ input: { value, onChange } }) => {
        const handleSelectRelationship = (option: IOption | undefined) => {
          onChange(option?.value)
        }

        return (
          <RelationshipStrengthSelect
            value={value}
            onSelect={handleSelectRelationship}
          />
        )
      }}
    </Field>
  )
}
