import React, { PropsWithChildren } from 'react'

import * as Styled from './QuickActionMenuGroup.styles'

export interface IQuickActionMenuGroupProps extends PropsWithChildren {
  heading?: string
}

const QuickActionMenuGroup = ({
  heading,
  children,
}: IQuickActionMenuGroupProps) => {
  return (
    <Styled.Container>
      {heading && <Styled.Heading>{heading}</Styled.Heading>}

      {children}
    </Styled.Container>
  )
}

export default QuickActionMenuGroup
