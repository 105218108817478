import React from 'react'

import { FiTruck } from 'react-icons/fi'

import styled, { keyframes } from 'styled-components'

import { Column, Row } from 'Components/UI'

const bounce = keyframes`
  0%   { transform: scale(1,1)      translateY(0) skewX(-10deg); }
  10%  { transform: scale(1.1,.9)   translateY(0) skewX(-10deg); }
  30%  { transform: scale(.9,1.1)   translateY(-6px) skewX(-10deg); }
  50%  { transform: scale(1.05,.95) translateY(0) skewX(-10deg); }
  57%  { transform: scale(1,1)      translateY(-2px) skewX(-10deg); }
  64%  { transform: scale(1,1)      translateY(0) skewX(-10deg); }
  100% { transform: scale(1,1)      translateY(0) skewX(-10deg); }
`

const Icon = styled(FiTruck)`
  animation: ${bounce} 1s ease infinite;
`

export default function ComingSoon() {
  return (
    <Row fullHeight>
      <Column center flex={1} fullWidth justifyCenter>
        <Icon size={40} />
        Coming soon!
      </Column>
    </Row>
  )
}
