import React, { useCallback, useMemo, useState } from 'react'
import { Form, FormRenderProps } from 'react-final-form'

import { useMutation } from '@apollo/client'
import { fullNameOrFallback } from 'Features/CommunityUsers/communityUserNameUtil'
import addUsersToCommunitiesMutation from 'GraphQL/Mutations/Community/addUsersToCommunities.graphql'
import { ITagOption } from 'Utils/Options'
import { validate } from 'validate.js'

import CommunityField from 'Components/Blocks/Forms/Fields/CommunityField'
import { Divider, Modal, Row, Tag } from 'Components/UI'

import { TAG_COLOR_KIND } from 'Constants/tags'

import { useCommunity } from 'Hooks'

import _, { useScopedI18n } from 'Services/I18n'
import toast from 'Services/Toast'

enum AddToCommunityFormField {
  Communities = 'communities',
}

interface AddToCommunityFormValues {
  [AddToCommunityFormField.Communities]: ITagOption[] | undefined
}

export interface AddToCommunityModalProps {
  isOpen?: boolean
  users?: MainSchema.CommunityUser[]
  onClose?: () => void
}

function AddToCommunityModal({
  users,
  isOpen,
  onClose,
}: AddToCommunityModalProps) {
  const s = useScopedI18n('modals.addToCommunity')
  const { community } = useCommunity()

  const [addUsersToCommunities] = useMutation<
    Pick<MainSchema.Mutation, 'addUsersToCommunities'>,
    MainSchema.MutationAddUsersToCommunitiesArgs
  >(addUsersToCommunitiesMutation)

  const [isLoading, setIsLoading] = useState(false)

  const userNames = useMemo(
    () => users?.map(user => fullNameOrFallback(user)) ?? [],
    [users],
  )

  const formConstraints = useMemo(() => {
    return {
      [AddToCommunityFormField.Communities]: {
        type: 'array',
        presence: {
          allowEmpty: false,
          message: `^${s('communityName.validation.required')}`,
        },
      },
    }
  }, [s])

  const onSubmit = useCallback(
    async (values: AddToCommunityFormValues) => {
      if (!community) {
        throw new Error('Community not found')
      }

      try {
        setIsLoading(true)

        const communityIds =
          values.communities?.map(community => community.id) ?? []
        const userIds = users?.map(user => user.userId!) || []

        await addUsersToCommunities({
          variables: {
            userIds,
            communityIds,
            sourceCommunityId: community.id,
          },
        })

        onClose?.()

        toast.success({
          title: s('title'),
          text: s(`success`),
        })
      } catch (error) {
        let message = _(`error.generic`)

        if (error instanceof Error) {
          message = error.message
        }

        toast.error({
          title: s('title'),
          text: `The server returned an error: "${message}"`,
        })
      } finally {
        setIsLoading(false)
      }
    },
    [users, addUsersToCommunities, community, onClose, s],
  )

  const renderForm = useCallback(
    ({ form, handleSubmit }: FormRenderProps<AddToCommunityFormValues>) => {
      const formState = form.getState()

      return (
        <Modal
          cancelText={s('actions.cancel')}
          confirmDisabled={isLoading || !formState.valid}
          confirmText={s('actions.ok')}
          isOpen={isOpen}
          px={0}
          title={s('title')}
          width={['100%', '100%', '600px']}
          onClose={onClose}
          onConfirm={handleSubmit}
        >
          <Row gap={2} wrapped>
            {userNames?.map(name => (
              <Tag
                colorKind={TAG_COLOR_KIND.USER}
                key={name}
                small
                text={name}
              />
            ))}
          </Row>

          <Divider my={4} />

          <Row fullWidth>
            <CommunityField
              isRequired
              label={s('communityName.label')}
              name={AddToCommunityFormField.Communities}
              placeholder={s('communityName.placeholder')}
            />
          </Row>
        </Modal>
      )
    },
    [isOpen, isLoading, onClose, s, userNames],
  )

  if (!isOpen) return null

  return (
    <Form<AddToCommunityFormValues>
      render={renderForm}
      validate={values => validate(values, formConstraints)}
      onSubmit={onSubmit}
    />
  )
}

export default AddToCommunityModal
