import React, { HTMLAttributes } from 'react'

import { pick } from '@styled-system/props'

import { ILinkCss, StyledButton } from './styles'

export interface ILinkButton
  extends ILinkCss,
    HTMLAttributes<HTMLButtonElement> {}

function LinkButton({
  children,
  danger,
  disabled,
  info,
  large,
  secondary,
  small,
  success,
  onClick,
  ...rest
}: ILinkButton) {
  return (
    <StyledButton
      {...pick(rest)}
      danger={danger}
      disabled={disabled}
      info={info}
      large={large}
      secondary={secondary}
      small={small}
      success={success}
      onClick={onClick}
    >
      {children}
    </StyledButton>
  )
}

export default LinkButton
