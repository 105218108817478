import apiCall, { HttpMethod } from 'Services/Api'

export interface IUploadFileResponse<TData> {
  ok: boolean
  url: string
  data: TData | null
  message?: string
}

export async function uploadFile<TData = unknown>({
  file,
  type,
  entityId,
  onProgress,
  ...rest
}: {
  file: File
  type: string
  entityId: string | undefined
  onProgress: () => void
}) {
  return apiCall<IUploadFileResponse<TData>>({
    endpoint: '/private/uploadFile',
    method: HttpMethod.Post,
    query: { type, entityId, ...rest },
    file,
    onProgress,
  })
}

export function uploadFileCSV<TData = unknown>({
  file,
  type,
  entityId,
}: {
  file: File
  type: string
  entityId: string | undefined
}) {
  return apiCall<IUploadFileResponse<TData>>({
    endpoint: '/private/uploadFile',
    method: HttpMethod.Post,
    query: { type, entityId },
    file,
  })
}
