import React, { useMemo } from 'react'
import PropTypes from 'prop-types'

import { Text } from 'Components/UI'

function AgreementCell({ row }) {
  const user = useMemo(() => row?.original, [row])
  return <Text primary>{user?.agreedToTermsAndConditions ? 'Yes' : 'No'}</Text>
}

AgreementCell.defaultProps = {
  row: {},
}

AgreementCell.propTypes = {
  row: PropTypes.object,
}

export default AgreementCell
