import styled from 'styled-components'
import { themeGet } from '@styled-system/theme-get'

import { IconDotsVertical, IconPlugConnectedX } from '@tabler/icons-react'

import { resetButton } from 'Components/Styles'

import { gap, GapProps } from 'Theme/system'

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
`

export const DotsIcon = styled(IconDotsVertical)`
  width: 16px;
  height: 16px;
  color: ${themeGet('colors.text.header')};
  stroke-width: 1.5px;
`

export const DropdownContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${themeGet('colors.bg.primary')};
  border-radius: 4px;
  overflow: hidden;

  svg {
    width: 16px;
    height: 16px;
    stroke-width: 1.5px;
  }
`

export interface IOptionProps extends GapProps {}

export const Option = styled.button<IOptionProps>`
  ${resetButton};

  display: flex;
  width: 100%;
  border-bottom: 1px solid ${themeGet('colors.divider.default')};
  padding: ${themeGet('space.3')}px;
  align-items: center;
  cursor: pointer;
  color: ${themeGet('colors.text.header')};

  p {
    color: ${themeGet('colors.text.header')};
  }

  :last-of-type {
    border: 0;
  }

  :hover {
    color: ${themeGet('colors.hover')};
    background-color: ${themeGet('colors.bg.hover')};

    p {
      color: ${themeGet('colors.hover')};
    }
  }

  ${gap}
`

export const DisconnectIcon = styled(IconPlugConnectedX)``
