import React, { useCallback, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import PropTypes from 'prop-types'

import {
  FiEdit3,
  FiPlusCircle,
  FiTrash,
  FiUnlock,
  FiUsers,
} from 'react-icons/fi'

import Utils from 'Utils'

import { IconButton, Row, Tooltip } from 'Components/UI'

import { USER_ROLE } from 'Constants/ids'

import { useAppContext } from 'Hooks'

import { ADMIN_COMMUNITY_RBA, ADMIN_COMMUNITY_USERS } from 'Router/routes'

import { useScopedI18n } from 'Services/I18n'

import { useTableContext } from '../context'

function ActionsCell({ row }) {
  const navigate = useNavigate()
  const s = useScopedI18n('community.actions')
  const importRef = useRef(null)
  const editRef = useRef(null)
  const deleteRef = useRef(null)

  const { me } = useAppContext()

  const { onEditRow, onDeleteRow, onImportOpen } = useTableContext()

  const community = row?.original

  const handleGoToCommunityUsers = useCallback(() => {
    navigate(
      Utils.URL.generatePath(ADMIN_COMMUNITY_USERS, {
        communityId: community.id,
      }),
    )
  }, [community, navigate])

  const handleGoToCommunityRBA = useCallback(() => {
    navigate(
      Utils.URL.generatePath(ADMIN_COMMUNITY_RBA, {
        communityId: community.id,
      }),
    )
  }, [community, navigate])

  const handleEdit = useCallback(() => {
    editRef.current.blur()
    onEditRow(community)
  }, [onEditRow, community])

  const handleDelete = useCallback(() => {
    deleteRef.current.blur()
    onDeleteRow(community)
  }, [onDeleteRow, community])

  const handleImport = useCallback(() => {
    importRef.current.blur()
    onImportOpen(community)
  }, [community, onImportOpen])

  const canInteract = me.role === USER_ROLE.SUPER_ADMIN

  if (!canInteract) {
    return null
  }

  return (
    <Row>
      <Tooltip content={s('showUsers')}>
        <IconButton mr={1} small onClick={handleGoToCommunityUsers}>
          <FiUsers />
        </IconButton>
      </Tooltip>

      <Tooltip content={s('roleBasedAccess')}>
        <IconButton mr={1} small onClick={handleGoToCommunityRBA}>
          <FiUnlock />
        </IconButton>
      </Tooltip>

      <Tooltip content={s('edit')}>
        <IconButton mr={1} ref={editRef} small onClick={handleEdit}>
          <FiEdit3 />
        </IconButton>
      </Tooltip>

      <Tooltip content={s('delete')}>
        <IconButton mr={1} ref={deleteRef} small onClick={handleDelete}>
          <FiTrash />
        </IconButton>
      </Tooltip>

      <Tooltip content={s('import')}>
        <IconButton ref={importRef} small onClick={handleImport}>
          <FiPlusCircle />
        </IconButton>
      </Tooltip>
    </Row>
  )
}

ActionsCell.defaultProps = {
  row: {},
}

ActionsCell.propTypes = {
  row: PropTypes.object,
}

export default ActionsCell
