import React, { useCallback } from 'react'
import { Form, FormRenderProps } from 'react-final-form'

import { useMutation, useQuery } from '@apollo/client'
import slugify from '@sindresorhus/slugify'
import { FormApi } from 'final-form'
import createCommunityMutation from 'GraphQL/Mutations/Community/createCommunity.graphql'
import userCommunitiesQuery from 'GraphQL/Queries/Community/userCommunities.graphql'
import { getDefaultAvatar } from 'Utils/Strings'
import validate from 'validate.js'

import { AvatarInputField, Box, Column, InputField, Modal } from 'Components/UI'

import { UPLOAD_TYPES } from 'Constants/ids'

import { useAppContext } from 'Hooks'

import _ from 'Services/I18n'
import toast from 'Services/Toast'

export enum CreateCommunityFormField {
  Name = 'name',
  Description = 'description',
  PhotoUrl = 'photoUrl',
}

export interface CreateCommunityFormValues {
  [CreateCommunityFormField.Name]: string
  [CreateCommunityFormField.Description]: string
  [CreateCommunityFormField.PhotoUrl]: string
}

function validateForm(values: CreateCommunityFormValues) {
  return validate(values, {
    [CreateCommunityFormField.Name]: {
      presence: {
        allowEmpty: false,
      },
    },
  })
}

interface CreateCommunityModalProps {
  initialValues?: Partial<CreateCommunityFormValues>
  isOpen?: boolean
  onClose?: (community?: MainSchema.Community) => Promise<void>
}

// TODO: translations
function CreateCommunityModal({
  initialValues,
  isOpen,
  onClose,
}: CreateCommunityModalProps) {
  const { me, refetchMe } = useAppContext()
  const { refetch } = useQuery<
    Pick<MainSchema.Query, 'userCommunities'>,
    MainSchema.QueryUserCommunitiesArgs
  >(userCommunitiesQuery)
  const [createCommunity] = useMutation<
    Pick<MainSchema.Mutation, 'createCommunity'>,
    MainSchema.MutationCreateCommunityArgs
  >(createCommunityMutation)

  const handleCreate = useCallback(
    async (
      values: CreateCommunityFormValues,
      form: FormApi<
        CreateCommunityFormValues,
        Partial<CreateCommunityFormValues>
      >,
    ) => {
      if (!me?.primaryEmailCredential?.email) {
        throw new Error('Email not found')
      }

      try {
        const result = await createCommunity({
          variables: {
            name: values[CreateCommunityFormField.Name],
            open: false,
            description: values[CreateCommunityFormField.Description],
            slug: slugify(`${values[CreateCommunityFormField.Name]}`),
            owner: {
              firstName: me?.profile?.firstName,
              lastName: me?.profile?.lastName,
              email: me.primaryEmailCredential.email,
            },
            isPersonalCommunity: false,
            photoUrl: values[CreateCommunityFormField.PhotoUrl],
          },
        })
        const community = result.data?.createCommunity

        toast.success({
          title: 'Community',
          text: 'Successfully created',
        })

        await refetchMe?.()
        await refetch()
        await onClose?.(community)

        form.reset()
      } catch (error) {
        let message = _('error.generic')

        if (error instanceof Error) {
          message = error.message
        }

        toast.error({
          title: 'Community',
          text: message,
        })
      }
    },
    [
      createCommunity,
      me?.primaryEmailCredential?.email,
      me?.profile?.firstName,
      me?.profile?.lastName,
      onClose,
      refetch,
      refetchMe,
    ],
  )

  const renderForm = useCallback(
    ({
      handleSubmit,
      invalid,
      form,
    }: FormRenderProps<CreateCommunityFormValues>) => (
      <Modal
        cancelText="Cancel"
        confirmDisabled={invalid}
        confirmText="Create"
        isOpen={isOpen}
        title="Create New Community"
        width="480px"
        onClose={async () => {
          form.reset()
          await onClose?.()
        }}
        onConfirm={handleSubmit}
      >
        <Column>
          <Box style={{ marginBottom: 14 }}>
            <AvatarInputField
              defaultValue={getDefaultAvatar()}
              entityId=""
              entityText=""
              name={CreateCommunityFormField.PhotoUrl}
              uploadType={UPLOAD_TYPES.profilePhoto}
            />
          </Box>
          <InputField
            label="Community Name"
            mb={3}
            name={CreateCommunityFormField.Name}
            placeholder="Community name"
            required
            width={1}
          />

          <InputField
            label="About This Community"
            name={CreateCommunityFormField.Description}
            placeholder="Describe your community’s mission, goals, and purpose"
            small
            textArea
          />
        </Column>
      </Modal>
    ),
    [isOpen, onClose],
  )

  return (
    <Form<CreateCommunityFormValues>
      initialValues={initialValues}
      render={renderForm}
      validate={validateForm}
      onSubmit={handleCreate}
    />
  )
}

export default CreateCommunityModal
