import React from 'react'
import { Outlet } from 'react-router-dom'

import Header from 'Components/Blocks/Community/Header'
import { Row } from 'Components/UI'

import { useAppContext } from 'Hooks'

import PermissionProvider from 'Services/Permission/Provider'

import PrivacyAgreement from './PrivacyAgreement'
import { Container, Content, PageContent, PrivacyContainer } from './styles'

function OnboardingLayout() {
  const { me } = useAppContext()

  if (!me?.agreedToTermsAndConditions) {
    return (
      <PermissionProvider>
        <Container>
          <Header menuHidden restricted />
          <Row fullHeight fullWidth justifyCenter p={5}>
            <PrivacyContainer>
              <PrivacyAgreement />
            </PrivacyContainer>
          </Row>
        </Container>
      </PermissionProvider>
    )
  }

  return (
    <PermissionProvider>
      <Container>
        <Header menuHidden restricted />
        <Content>
          <PageContent>
            <Outlet />
          </PageContent>
        </Content>
      </Container>
    </PermissionProvider>
  )
}

export default OnboardingLayout
