import React, { forwardRef, useMemo } from 'react'
import ReactSelect, {
  ClassNamesConfig,
  GroupBase,
  Options,
  OptionsOrGroups,
  Props,
  SelectComponentsConfig,
  SelectInstance,
} from 'react-select'
import ReactSelectAsync from 'react-select/async'
import ReactSelectAsyncCreatable from 'react-select/async-creatable'
import ReactSelectCreatable from 'react-select/creatable'
import {
  AsyncPaginate,
  LoadOptions as ReactSelectAsyncPaginateLoadOptions,
  ReduceOptions,
  withAsyncPaginate,
  wrapMenuList,
} from 'react-select-async-paginate'

import { omit, pick } from '@styled-system/props'

import { IconInfoCircle } from '@tabler/icons-react'

import { Row } from 'Components/UI/Flex'
import Text from 'Components/UI/Text'
import Tooltip from 'Components/UI/Tooltip'

import * as Components from './Components'
import { IWrapper, Wrapper } from './styles'

const ReactSelectPaginateCreatable = withAsyncPaginate(ReactSelectCreatable)

function getSelectType({
  async,
  creatable,
  paginated,
}: {
  async?: boolean
  creatable?: boolean
  paginated?: boolean
}): React.ComponentType<any> {
  if (creatable) {
    if (paginated) {
      return ReactSelectPaginateCreatable
    }

    if (async) {
      return ReactSelectAsyncCreatable
    }

    return ReactSelectCreatable
  }

  if (paginated) {
    return AsyncPaginate
  }

  if (async) {
    return ReactSelectAsync
  }

  return ReactSelect
}

export type ReactSelectAsyncLoadOptions<
  Option = unknown,
  Group extends GroupBase<Option> = GroupBase<Option>,
> =
  | ((inputValue: string) => Promise<OptionsOrGroups<Option, Group>>)
  | ((
      inputValue: string,
      callback: (options: OptionsOrGroups<Option, Group>) => void,
    ) => void)

export interface ISelect<
  Option = unknown,
  IsMulti extends boolean = boolean,
  Group extends GroupBase<Option> = GroupBase<Option>,
  Additional = unknown,
> extends Props<Option, IsMulti, Group>,
    IWrapper {
  async?: boolean
  caption?: string
  clearable?: boolean
  creatable?: boolean
  disabled?: boolean
  error?: boolean
  infoText?: React.ReactNode
  label?: React.ReactNode
  maxWidth?: number | string
  paginated?: boolean
  placement?: 'auto' | 'bottom' | 'top'
  suggestions?: React.ReactNode
  width?: number | string
  withPortal?: boolean
  withLabel?: boolean
  debounceTimeout?: number
  loadOptions?:
    | ReactSelectAsyncPaginateLoadOptions<Option, Group, Additional>
    | ReactSelectAsyncLoadOptions<Option, Group>
  defaultOptions?: OptionsOrGroups<Option, Group> | boolean
  renderBeforeElement?: () => React.ReactNode
  getNewOptionData?: (inputValue: string, optionLabel: string) => Option
  createOptionPosition?: 'first' | 'last'
  formatCreateLabel?: (inputValue: string) => React.ReactNode
  onCreateOption?: (inputValue: string) => void
  isValidNewOption?: (
    inputValue: string,
    value: Options<Option>,
    options: OptionsOrGroups<Option, Group>,
  ) => boolean
  reduceOptions?: ReduceOptions<Option, Group, Additional>
  cacheUniqs?: ReadonlyArray<any>
  selectRef?: React.Ref<SelectInstance>
}

export const PaginatedMenuList = wrapMenuList(Components.MenuList)

const Select = forwardRef<SelectInstance, ISelect>(
  (
    {
      caption,
      infoText,
      label,
      disabled,
      error,
      required,
      suggestions,
      value,
      placeholder = 'Select...',
      placement = 'auto',
      creatable = false,
      isSearchable = true,
      width = 1,
      selectRef,
      ...rest
    },
    ref,
  ) => {
    const { async, paginated, withPortal, clearable } = rest

    const SelectComponent = useMemo(
      () => getSelectType({ async, creatable, paginated }),
      [async, creatable, paginated],
    )

    const classNames: ClassNamesConfig = {
      control: () => (error ? 'react-select__control--has-error' : ''),
    }

    return (
      <Wrapper width={width} {...pick(rest)}>
        {label && (
          <Row center gap={1} mb={1}>
            <Text disabled={disabled} header={!disabled} header4>
              {label}
              {required && (
                <Text as="span" error header4>
                  &nbsp;*
                </Text>
              )}
            </Text>
            {infoText && (
              <Tooltip content={infoText} offset={[0, 8]}>
                <IconInfoCircle size={16} stroke={1.5} />
              </Tooltip>
            )}
          </Row>
        )}

        {suggestions}

        <SelectComponent
          {...omit(rest)}
          className="react-select-container"
          classNamePrefix="react-select"
          classNames={classNames}
          closeMenuOnScroll={withPortal}
          closeMenuOnSelect={rest.closeMenuOnSelect || !rest.isMulti}
          components={{
            ...(Components as Partial<
              SelectComponentsConfig<unknown, boolean, GroupBase<unknown>>
            >),
            ...rest.components,
            MenuList: paginated ? PaginatedMenuList : Components.MenuList,
          }}
          creatable={creatable}
          getNewOptionData={rest.getNewOptionData}
          isClearable={clearable}
          isSearchable={isSearchable}
          menuPlacement={placement}
          menuPortalTarget={withPortal ? document.body : undefined}
          placeholder={placeholder}
          ref={ref}
          selectRef={selectRef}
          value={value}
          width={width}
        />

        {caption && (
          <Text
            body={!disabled}
            bodySmall
            disabled={disabled}
            error={error}
            mt={1}
          >
            {caption}
          </Text>
        )}
      </Wrapper>
    )
  },
  // TODO: find a better way to handle generics
) as <
  Option,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>,
  Additional = unknown,
>(
  props: ISelect<Option, IsMulti, Group, Additional>,
  ref: React.RefObject<SelectInstance>,
) => React.ReactElement

export default Select
