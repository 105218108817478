import React from 'react'
import { components } from 'react-select'

import { SelectContainerWrapper } from './styles'

const SelectContainer: typeof components.SelectContainer = props => {
  // TODO: find a better way to satisfy the type
  const StyledSelectContainerWrapper = SelectContainerWrapper as any
  return (
    <StyledSelectContainerWrapper {...props} as={components.SelectContainer} />
  )
}

export default SelectContainer
