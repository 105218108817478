import escapeHtml from 'escape-html'
import {
  Descendant,
  Editor,
  Element as SlateElement,
  Text,
  Transforms,
} from 'slate'
import { jsx } from 'slate-hyperscript'
import {
  CustomEditor,
  CustomElement,
  CustomText,
  CustomTextMark,
  ElementType,
} from 'Types/slate'

import first from 'lodash/first'
import map from 'lodash/map'

const isList = (type: ElementType) =>
  type === ElementType.OrderedList || type === ElementType.UnorderedList

const isCode = (type: ElementType) => type === ElementType.Code

export function isLeafActive(editor: CustomEditor, key: CustomTextMark) {
  const marks = Editor.marks(editor)
  return marks ? marks[key] === true : false
}

export function isElementActive(editor: CustomEditor, type: ElementType) {
  const [match] = Editor.nodes(editor, {
    match: n => SlateElement.isElementType(n, type),
  })

  return !!match
}

export function toggleLeaf(editor: CustomEditor, key: CustomTextMark) {
  const isActive = isLeafActive(editor, key)

  if (isActive) {
    Editor.removeMark(editor, key)
  } else {
    Editor.addMark(editor, key, true)
  }
}

export function toggleElement(editor: CustomEditor, type: ElementType) {
  const isActive = isElementActive(editor, type)

  Transforms.unwrapNodes(editor, {
    match: n => {
      return SlateElement.isElement(n) && (isList(n.type) || isCode(n.type))
    },
    split: true,
  })

  if (isList(type) && !isActive) {
    Transforms.setNodes(editor, {
      type: ElementType.ListItem,
    })
  } else if (isActive || isCode(type)) {
    Transforms.setNodes(editor, {
      type: ElementType.Paragraph,
    })
  } else {
    Transforms.setNodes(editor, { type })
  }

  if (!isActive && (isList(type) || isCode(type))) {
    Transforms.wrapNodes(editor, {
      type,
      children: [],
    })
  }
}

export function htmlToDOM(html: string) {
  return new DOMParser().parseFromString(html, 'text/html')
}

export function deserialize(
  el: HTMLElement,
): string | Descendant | Descendant[] | null {
  if (el.nodeType === Node.TEXT_NODE) {
    return el.textContent
  }
  if (el.nodeType !== Node.ELEMENT_NODE) {
    return null
  }

  const children: (string | Descendant | Descendant[] | null)[] = Array.from(
    (el.childNodes as NodeListOf<HTMLElement>) || [],
  ).map(deserialize)

  if (children.length === 0) {
    return jsx('element', { type: ElementType.Paragraph }, [''])
  }

  switch (el.nodeName) {
    case 'BODY':
      return jsx('fragment', {}, children)
    case 'BR':
      return '\n'
    case 'PRE':
      return jsx('element', { type: ElementType.Code }, children)
    case 'LI':
      return jsx('element', { type: ElementType.ListItem }, children)
    case 'OL':
      return jsx('element', { type: ElementType.OrderedList }, children)
    case 'UL':
      return jsx('element', { type: ElementType.UnorderedList }, children)
    case 'BLOCKQUOTE':
      return jsx('element', { type: ElementType.Quote }, children)
    case 'A':
      return jsx(
        'element',
        { type: ElementType.Link, url: el.getAttribute('href') },
        children,
      )
    case 'P':
      return jsx('element', { type: ElementType.Paragraph }, children)

    case 'STRONG':
      return jsx('text', { bold: true }, [first(children)])
    case 'EM':
      return jsx('text', { italic: true }, [first(children)])
    case 'U':
      return jsx('text', { underline: true }, [first(children)])
    case 'STRIKE':
      return jsx('text', { strikethrough: true }, [first(children)])

    default:
      return el.textContent || ''
  }
}

export function serialize(
  node: CustomEditor | CustomElement | CustomText,
): string {
  if (Text.isText(node)) {
    const children = escapeHtml(node.text)

    if (node.bold) {
      return `<strong>${children}</strong>`
    }

    if (node.italic) {
      return `<em>${children}</em>`
    }

    if (node.underline) {
      return `<u>${children}</u>`
    }

    return children
  }

  if (SlateElement.isElement(node)) {
    const children = map(node.children, n => serialize(n)).join('')

    switch (node.type) {
      case ElementType.Link:
        return `<a class="${node.className}" href="${node.url}" rel="noopener noreferrer" target="_blank">${children}</a>`
      case ElementType.Quote:
        return `<blockquote><p>${children}</p></blockquote>`
      case ElementType.Code:
        return `<pre>${children}</pre>`

      case ElementType.OrderedList:
        return `<ol>${children}</ol>`
      case ElementType.UnorderedList:
        return `<ul>${children}</ul>`
      case ElementType.ListItem:
        return `<li>${children}</li>`

      case ElementType.Paragraph:
        // TODO: check if all goes ok
        if (children === '<p></p>') return children
        return `<p>${children}</p>`

      default:
        return children
    }
  }

  return ''
}
