import camelCase from 'lodash/camelCase'

import { getEnv } from 'Services/Envs'

export const APP_ENV = getEnv('ENV')

export const IS_PRODUCTION = APP_ENV === 'production'
export const IS_DEVELOPMENT = APP_ENV === 'development'

export const appearance = {
  theme: 'main',
}

const apiProtocol = getEnv('API_SSL') === 'true' ? 'https' : 'http'
const socketProtocol = getEnv('API_SSL') === 'true' ? 'wss' : 'ws'

export const APP_NAME = getEnv('NAME')

const API_URL = `${apiProtocol}://${getEnv('API_URL')}`

export const API = {
  URL: API_URL,
  WS: `${socketProtocol}://${getEnv('API_URL')}`,
  PROXY: `${API_URL}/proxy/`,
}

export const FRONTEND = {
  URL: getEnv('FRONTEND_URL'),
  PROTOCOL: getEnv('FRONTEND_PROTOCOL') || 'https',
}

export const COOKIE = {
  SHARED_DOMAIN: getEnv('COOKIE_DOMAIN'),
  PREFIX: `${camelCase(APP_NAME)}-${!IS_PRODUCTION ? 'dev-' : ''}`,
}

export const SENTRY = {
  DSN: getEnv('SENTRY_DSN'),
}

export const S3_URLS = {
  'https://networkos-dev-files.s3.us-west-1.amazonaws.com':
    'https://dev-api.networkos.c8.ai/s3',
  'https://networkos-staging-files.s3.us-west-1.amazonaws.com':
    'https://api.networkos.c8.ai/s3',
  'https://networkos-production-files.s3.us-west-1.amazonaws.com':
    'https://api.network.collaboration.ai/s3',
}

export const TELEMETRY = {
  SMARTLOOK_KEY: getEnv('SMARTLOOK_KEY'),
  GOOGLE_ANALYTICS_KEY: getEnv('GOOGLE_ANALYTICS_KEY'),
}
