import styled from 'styled-components'
import { themeGet } from '@styled-system/theme-get'

export const Container = styled.div`
  width: 100%;
`

export const Content = styled.div`
  padding: ${themeGet('space.3')}px 0;
`
