export const FIELD = {
  PHOTO_URL: 'photoUrl',
  FIRST_NAME: 'firstName',
  LAST_NAME: 'lastName',
  JOB: 'jobTitle',
  LINKED_IN: 'linkedIn',
  TWITTER_URL: 'twitterUrl',
  FACEBOOK_URL: 'facebookUrl',
  EMAIL: 'email',
  PHONE_NUMBER: 'phoneNumber',
  ORGANIZATION: 'organization',
  INTERESTS_HOBBIES: 'interestsHobbies',
  ABOUT: 'about',
  RELATIONSHIP: 'relationshipStrength',
  SKILLS: 'skills',
  EVENT: 'event',
  PROJECT: 'project',
  ROLE: 'role',
  CUSTOM: 'custom',
  GROUP: 'group',
  COMMUNITIES: 'communities',
}
