import React from 'react'
import { Field } from 'react-final-form'

import { pick } from '@styled-system/props'

import Utils from 'Utils'

import InputList, { IInputListProps } from './InputList'

export interface IInputListFieldProps extends IInputListProps {
  checkErrorIfDirty?: boolean
  displayError?: boolean
  name: string
}

function InputListField({
  checkErrorIfDirty = false,
  displayError = true,
  name,
  ...rest
}: IInputListFieldProps) {
  return (
    <Field name={name}>
      {({ input: { value, onChange: handleChange }, meta }) => {
        const { error } = Utils.Form.hasError(meta, checkErrorIfDirty)

        return (
          <InputList
            displayError={displayError}
            error={error}
            value={value}
            {...pick(rest)}
            {...rest}
            onChange={handleChange}
          />
        )
      }}
    </Field>
  )
}

export default InputListField
