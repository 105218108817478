import React from 'react'
import { components } from 'react-select'

import { MenuWrapper } from './styles'

const Menu: typeof components.Menu = props => {
  // TODO: find a better way to satisfy the type
  const StyledMenuWrapper = MenuWrapper as any
  return <StyledMenuWrapper {...props} as={components.Menu} />
}

export default Menu
