import { useCallback } from 'react'

import {
  PERMISSION_ACTION,
  PERMISSION_SCOPES,
  PERMISSION_SUBJECT,
} from 'Constants/permissions'

import { useAppContext, usePermission } from 'Hooks'

function useAbility() {
  const { can } = usePermission()
  const { me } = useAppContext()

  const isCreator = useCallback(user => user?.creator?.id === me?.id, [me])

  const canCreateUser = can(
    PERMISSION_ACTION.CREATE,
    PERMISSION_SUBJECT.ACCOUNT,
    PERMISSION_SCOPES.ACCOUNT_OWN_CREATOR,
  )

  const canEditCreator = can(
    PERMISSION_ACTION.EDIT,
    PERMISSION_SUBJECT.ACCOUNT,
    PERMISSION_SCOPES.ACCOUNT_OWN_CREATOR,
  )

  const canEditUserIfCreator = useCallback(
    user => isCreator(user) && canEditCreator,
    [isCreator, canEditCreator],
  )

  const canEditNotCreator = can(
    PERMISSION_ACTION.EDIT,
    PERMISSION_SUBJECT.ACCOUNT,
    PERMISSION_SCOPES.ACCOUNT_OTHER_CREATOR,
  )

  const canEditUserIfNotCreator = useCallback(
    user => !isCreator(user) && canEditNotCreator,
    [isCreator, canEditNotCreator],
  )

  const canEditAnyAccount = can(
    PERMISSION_ACTION.EDIT,
    PERMISSION_SUBJECT.ACCOUNT,
    PERMISSION_SCOPES.ACCOUNT_PRIVATE,
  )

  const canDeleteIfCreator = useCallback(
    user =>
      isCreator(user) &&
      can(
        PERMISSION_ACTION.DELETE,
        PERMISSION_SUBJECT.ACCOUNT,
        PERMISSION_SCOPES.ACCOUNT_OWN_CREATOR,
      ),
    [isCreator, can],
  )

  const canDeleteIfNotCreator = useCallback(
    user =>
      !isCreator(user) &&
      can(
        PERMISSION_ACTION.DELETE,
        PERMISSION_SUBJECT.ACCOUNT,
        PERMISSION_SCOPES.ACCOUNT_OTHER_CREATOR,
      ),
    [isCreator, can],
  )

  return {
    canCreateUser,
    canEditUserIfCreator,
    canEditUserIfNotCreator,
    canDeleteIfCreator,
    canDeleteIfNotCreator,
    canEditCreator,
    canEditNotCreator,
    canEditAnyAccount,
  }
}

export default useAbility
