import React from 'react'

import colors from 'Theme/_v2/colors'

import Text from './Text'

import Box from '../Box'
import Flex, { Row } from '../Flex'

export interface IChipProps {
  label: string
}

const Chip: React.FC<IChipProps> = props => {
  return (
    <Box
      backgroundColor={colors.chip.primary.background}
      borderRadius={4}
      px={2}
      py={1}
    >
      <Row center gap={1}>
        <Flex flex={1}>
          <Text
            color={colors.chip.primary.text}
            fontWeight={500}
            variant="body-xs"
          >
            {props.label}
          </Text>
        </Flex>
      </Row>
    </Box>
  )
}

export default Chip
