import React from 'react'
import { Field } from 'react-final-form'

import { pick } from '@styled-system/props'

import { FieldValidator } from 'final-form'
import Utils from 'Utils'

import Checkbox, { ICheckboxProps } from './Checkbox'

export interface ICheckboxFieldProps extends ICheckboxProps {
  name: string
  caption?: String
  validate?: FieldValidator<string | number | boolean>
  value?: string | number | boolean
}

function CheckboxField({
  name,
  validate,
  label,
  disabled,
  value,
  ...rest
}: ICheckboxFieldProps) {
  return (
    <Field<string | number | boolean>
      name={name}
      type="checkbox"
      validate={validate}
      value={value}
    >
      {({ input, meta }) => {
        const { hasError, error } = Utils.Form.hasError(meta)

        return (
          <Checkbox
            {...pick(rest)}
            {...input}
            danger={hasError}
            disabled={disabled}
            error={error}
            label={label}
          />
        )
      }}
    </Field>
  )
}

export default CheckboxField
