import styled, { css } from 'styled-components'
import { mapToTheme } from 'styled-map'
import {
  flexbox,
  FlexboxProps,
  layout,
  LayoutProps,
  margin,
  MarginProps,
  padding,
  PaddingProps,
  position,
  PositionProps,
  space,
  SpaceProps,
} from 'styled-system'

import { gap, GapProps } from 'Theme/system'

const alignEndCss = ({ alignEnd }: { alignEnd?: boolean }) =>
  alignEnd &&
  css`
    align-items: flex-end;
  `

const borderBottomCss = ({ borderBottom }: { borderBottom?: boolean }) =>
  borderBottom &&
  css`
    border-bottom: solid 1px ${mapToTheme('colors.divider')};
  `

const borderTopCss = ({ borderTop }: { borderTop?: boolean }) =>
  borderTop &&
  css`
    border-top: solid 1px ${mapToTheme('colors.divider')};
  `

const centerCss = ({ center }: { center?: boolean }) =>
  center &&
  css`
    align-items: center;
  `

const fullHeightCss = ({ fullHeight }: { fullHeight?: boolean }) =>
  fullHeight &&
  css`
    height: 100%;
  `

const fullScreenCss = ({ fullScreen }: { fullScreen?: boolean }) =>
  fullScreen &&
  css`
    width: 100vw;
    height: 100vh;
  `

const fullWidthCss = ({ fullWidth }: { fullWidth?: boolean }) =>
  fullWidth &&
  css`
    width: 100%;
  `

const justifyCenterCss = ({ justifyCenter }: { justifyCenter?: boolean }) =>
  justifyCenter &&
  css`
    justify-content: center;
  `

const justifyEndCss = ({ justifyEnd }: { justifyEnd?: boolean }) =>
  justifyEnd &&
  css`
    justify-content: flex-end;
  `

const noShrinkCss = ({ noShrink }: { noShrink?: boolean }) =>
  noShrink &&
  css`
    flex-shrink: 0;
  `

const spaceBetweenCss = ({ spaceBetween }: { spaceBetween?: boolean }) =>
  spaceBetween &&
  css`
    justify-content: space-between;
  `

const squeezeCss = ({ squeeze }: { squeeze?: boolean }) =>
  squeeze &&
  css`
    width: fit-content;
  `

const growCss = ({ grow }: { grow?: boolean }) =>
  grow &&
  css`
    flex-grow: 1;
  `

const wrapCss = ({ wrapped }: { wrapped?: boolean }) =>
  wrapped &&
  css`
    flex-wrap: wrap;
  `

const overflowHiddenCss = ({ overflowHidden }: { overflowHidden?: boolean }) =>
  overflowHidden &&
  css`
    overflow: hidden;
  `

const relativeCss = ({ relative }: { relative?: boolean }) =>
  relative &&
  css`
    position: relative;
  `

export interface IElement
  extends LayoutProps,
    MarginProps,
    PaddingProps,
    FlexboxProps,
    GapProps,
    SpaceProps,
    PositionProps {
  alignEnd?: boolean
  borderBottom?: boolean
  borderTop?: boolean
  center?: boolean
  grow?: boolean
  fullHeight?: boolean
  fullScreen?: boolean
  fullWidth?: boolean
  justifyCenter?: boolean
  justifyEnd?: boolean
  noShrink?: boolean
  spaceBetween?: boolean
  squeeze?: boolean
  wrapped?: boolean
  overflowHidden?: boolean
  relative?: boolean
}

export const Element = styled.div<IElement>`
  ${layout}
  ${margin}
  ${padding}
  ${flexbox}
  ${gap}
  ${space}
  ${position}

  ${alignEndCss}
  ${borderBottomCss}
  ${borderTopCss}
  ${centerCss}
  ${fullHeightCss}
  ${fullWidthCss}
  ${justifyCenterCss}
  ${justifyEndCss}
  ${noShrinkCss}
  ${spaceBetweenCss}
  ${squeezeCss}
  ${fullScreenCss}
  ${growCss}
  ${wrapCss}
  ${overflowHiddenCss}
  ${relativeCss}
`

export interface IColumn extends IElement {}

export const Column = styled(Element)<IColumn>`
  display: flex;
  flex-direction: column;
`

export interface IRow extends IElement {}

export const Row = styled(Element)<IRow>`
  display: flex;
`

export interface IFlex extends IElement {}

const Flex = styled(Element)<IFlex>`
  display: flex;
`

export default Flex
