import { useCallback, useEffect, useMemo, useState } from 'react'

import { IGraphPersonNode } from 'Features/GraphNodes/NodeTypes'

import forEach from 'lodash/forEach'

import useActionHandlers from 'Components/Blocks/QuickActions//useActionHandlers'

import { QuickActionKind } from 'Constants/graph'

import { useEventBusSubscribe } from 'Hooks'
import { IAvailableQuickAction, IGraphState } from 'Hooks/useGraphContext'

import EventBus from 'Services/EventBus'

import utils from './utils'

export interface IIntroduceTo {
  isOpen: boolean
  userWhom: MainSchema.UserSuggestion | null
  userTo: MainSchema.UserSuggestion | null
}

export interface IUseAction {
  selectedIds: string[]
  quickActions: QuickActionKind[]
  graphState: IGraphState
  onOpenAddToCommunityModal: (users: MainSchema.CommunityUser[]) => void
  onUpdateContactModal: (user: MainSchema.CommunityUser) => void
  onCreateGraphSnapshotModal: () => void
  onUpdateGraphSnapshotModal: (graphSnapshot: MainSchema.GraphSnapshot) => void
  onDeleteGraphSnapshotModal: (graphSnapshot: MainSchema.GraphSnapshot) => void
  onManageGraphSnapshotsModal: () => void
}

// TODO: Calculate actions without having to use selected items in graph
// Need a better name, but basically the Quick Action Handlers / Processors
export default function useAction({
  selectedIds,
  quickActions,
  graphState,
  onOpenAddToCommunityModal,
  onUpdateContactModal,
  onCreateGraphSnapshotModal,
  onUpdateGraphSnapshotModal,
  onDeleteGraphSnapshotModal,
  onManageGraphSnapshotsModal,
}: IUseAction) {
  const selectedUsers = useMemo(() => {
    return selectedIds
      .filter(
        selectedId =>
          graphState.items[selectedId] &&
          utils.isUserNode(graphState.items[selectedId]),
      )
      .map(
        selectedId =>
          utils.getAsUserNode(graphState.items[selectedId]).data!.data,
      )
      .filter(Boolean) as IGraphPersonNode[]
  }, [graphState.items, selectedIds])

  // Action States
  const [introduceTo, setIntroduceTo] = useState<IIntroduceTo>({
    isOpen: false,
    userWhom: null,
    userTo: null,
  })

  const [availableQuickActions, setAvailableQuickActions] = useState<
    Record<string, Record<string, IAvailableQuickAction>>
  >({})

  // Action Handlers
  const actionHandlers = useActionHandlers({
    quickActions,
  })

  const handleIntroduceToCancel = () => {
    setIntroduceTo({
      isOpen: false,
      userTo: null,
      userWhom: null,
    })
  }

  const handleIntroduceTo = useCallback(
    (introduceUsers: MainSchema.UserSuggestion[]) => {
      /*
        const email = user?.email
        if (!email || email === 'Private') return
        */

      if (introduceUsers.length > 0 && !introduceTo?.userWhom) {
        setIntroduceTo({
          isOpen: true,
          userWhom: introduceUsers[0],
          userTo: introduceUsers.length === 2 ? introduceUsers[1] : null,
        })
      }

      if (
        introduceUsers.length > 0 &&
        introduceTo?.userWhom &&
        introduceUsers[0].id !== introduceTo?.userWhom.id
      ) {
        setIntroduceTo(prevState => ({
          ...prevState,
          userTo: introduceUsers[0],
        }))
      }
    },
    [introduceTo?.userWhom],
  )

  const updateUserSelectionAndActions = () => {
    const users: IGraphPersonNode[] = []

    forEach(selectedIds, selectedId => {
      const item = graphState.items[selectedId]

      if (item && utils.isUserNode(item)) {
        const userNode = utils.getAsUserNode(item)
        const user = userNode.data!.data

        if (user) {
          users.push(user)
        }
      }
    })

    const updatedAvailableQuickActions = utils.getAvailableQuickActionsForUsers(
      quickActions,
      actionHandlers,
      users,
      selectedUsers,
    )

    setAvailableQuickActions(updatedAvailableQuickActions)
  }

  useEventBusSubscribe(
    EventBus.actions.dashboard.introduceTo,
    handleIntroduceTo,
  )

  useEventBusSubscribe(
    EventBus.actions.dashboard.addToCommunity,
    onOpenAddToCommunityModal,
  )

  useEventBusSubscribe(
    EventBus.actions.dashboard.updateContact,
    onUpdateContactModal,
  )

  useEventBusSubscribe(
    EventBus.actions.dashboard.createGraphSnapshot,
    onCreateGraphSnapshotModal,
  )

  useEventBusSubscribe(
    EventBus.actions.dashboard.updateGraphSnapshot,
    onUpdateGraphSnapshotModal,
  )

  useEventBusSubscribe(
    EventBus.actions.dashboard.deleteGraphSnapshot,
    onDeleteGraphSnapshotModal,
  )

  useEventBusSubscribe(
    EventBus.actions.dashboard.manageGraphSnapshots,
    onManageGraphSnapshotsModal,
  )

  useEffect(() => {
    updateUserSelectionAndActions()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [graphState.items, selectedIds])

  return {
    introduceTo,
    availableQuickActions,
    handleIntroduceToCancel,
    handleIntroduceTo,
  }
}
