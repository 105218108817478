import { useCallback } from 'react'

import { useLazyQuery } from '@apollo/client'

import CheckIfFeatureFlagEnabledQuery from './Queries/CheckIfFeatureFlagEnabled.graphql'

export const useFeatureFlag = () => {
  const [checkFeatureFlag] = useLazyQuery<{
    checkIfFeatureFlagEnabled: boolean
  }>(CheckIfFeatureFlagEnabledQuery, { fetchPolicy: 'network-only' })

  const featureEnabled = useCallback(
    async (key: string): Promise<boolean> => {
      try {
        const result = await checkFeatureFlag({ variables: { key } })
        return result.data?.checkIfFeatureFlagEnabled ?? false
      } catch {
        return false
      }
    },
    [checkFeatureFlag],
  )

  return { featureEnabled }
}
