import React from 'react'

import { Row } from 'Components/UI'

import { Item } from './styles'

export enum CommandType {
  Button = 'button',
  List = 'list',
}
export interface ICommand {
  label: string
  id: string
  type: CommandType
  icon: React.ReactNode
  subIcon?: React.ReactNode
  parentId?: string
  children?: ICommand[]
}

export interface ICommandItemProps {
  command: ICommand
  highlight?: boolean
  onClick: (command: ICommand) => void
  onMouseEnter: React.MouseEventHandler<HTMLButtonElement>
}

const CommandItem = React.memo(
  ({
    command,
    highlight = false,
    onClick,
    onMouseEnter,
  }: ICommandItemProps) => (
    <Item
      highlight={highlight}
      tabIndex={0}
      onClick={() => onClick(command)}
      onMouseEnter={onMouseEnter}
    >
      {command.icon}
      {command.label}

      {command.subIcon && <Row ml="auto">{command.subIcon}</Row>}
    </Item>
  ),
)

export default CommandItem
