/* eslint-disable react/prop-types */

import React, { useMemo } from 'react'

import { NotificationCell } from './Cells'

export function useColumns() {
  return useMemo(
    () => [
      {
        id: 'notification',
        // eslint-disable-next-line react/prop-types
        Cell: ({ row }) => <NotificationCell notification={row?.original} />,
        width: 12,
      },
    ],
    [],
  )
}
