import styled, { css } from 'styled-components'
import { flexbox, FlexboxProps, space, SpaceProps } from 'styled-system'
import { themeGet } from '@styled-system/theme-get'

const fullWidthCss = ({ fullWidth }: { fullWidth?: boolean }) =>
  fullWidth &&
  css`
    width: 100%;
  `

export interface IBox extends SpaceProps, FlexboxProps {
  fullWidth?: boolean
}

export const Box = styled.div<IBox>`
  position: relative;
  border-radius: 8px;
  border: 1px solid ${themeGet('colors.divider.default')};

  ${space}
  ${flexbox}

  ${fullWidthCss}
`
