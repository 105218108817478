import React, { PropsWithChildren } from 'react'
import { To } from 'react-router-dom'

import { pick } from '@styled-system/props'

import { ILinkCss, StyledExternalLink, StyledNavLink } from './styles'

export interface ILink extends ILinkCss, PropsWithChildren {
  isExternal?: boolean
  to?: To
  href?: string
  onClick?: React.MouseEventHandler<HTMLButtonElement | HTMLAnchorElement>
  large?: boolean
}

function Link({
  children,
  danger,
  disabled,
  info,
  href,
  isExternal,
  large,
  secondary,
  small,
  success,
  to = '/',
  onClick,
  ...rest
}: ILink) {
  return href ? (
    <StyledExternalLink
      {...pick(rest)}
      danger={danger}
      disabled={disabled}
      href={href}
      info={info}
      large={large}
      secondary={secondary}
      small={small}
      success={success}
      target={isExternal ? '_blank' : '_self'}
      onClick={onClick}
    >
      {children}
    </StyledExternalLink>
  ) : (
    <StyledNavLink
      {...pick(rest)}
      danger={danger}
      disabled={disabled}
      info={info}
      large={large}
      secondary={secondary}
      small={small}
      success={success}
      target={isExternal ? '_blank' : '_self'}
      to={to}
      onClick={onClick}
    >
      {children}
    </StyledNavLink>
  )
}

export default Link
