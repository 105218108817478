import React, { useCallback } from 'react'

import AvatarImage from 'Assets/Images/bg.jpg'

import { Avatar, Column, Row, Text } from 'Components/UI'

function Avatars() {
  const handleClick = useCallback(() => {
    // eslint-disable-next-line no-console
    console.log('Clicked')
  }, [])

  return (
    <Column gap={6}>
      <Text header1>Avatars</Text>
      <Row gap={5}>
        <Column center fullWidth gap={5}>
          <Text header2 primary80>
            Empty - Circle
          </Text>
          <Avatar large />
          <Avatar />
          <Avatar small />
          <Avatar small />
        </Column>

        <Column center fullWidth gap={5}>
          <Text header2 primary80>
            Empty - Square
          </Text>
          <Avatar large square />
          <Avatar square />
          <Avatar small square />
          <Avatar small square />
        </Column>

        <Column center fullWidth gap={5}>
          <Text header2 primary80>
            Image - Circle
          </Text>
          <Avatar large src={AvatarImage} />
          <Avatar src={AvatarImage} />
          <Avatar small src={AvatarImage} />
          <Avatar small src={AvatarImage} />
        </Column>

        <Column center fullWidth gap={5}>
          <Text header2 primary80>
            Image - Square
          </Text>
          <Avatar large square src={AvatarImage} />
          <Avatar square src={AvatarImage} />
          <Avatar small square src={AvatarImage} />
          <Avatar small square src={AvatarImage} />
        </Column>

        <Column center fullWidth gap={5}>
          <Text header2 primary80>
            Clickable
          </Text>
          <Avatar large onClick={handleClick} />
          <Avatar onClick={handleClick} />
          <Avatar small onClick={handleClick} />
          <Avatar small onClick={handleClick} />
        </Column>
      </Row>
    </Column>
  )
}

export default Avatars
