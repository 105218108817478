import React, { useMemo } from 'react'
import PropTypes from 'prop-types'

import { useTheme } from 'styled-components'

import { getFullName } from 'Utils/User'

import { Text } from 'Components/UI'

import { useScopedI18n } from 'Services/I18n'

function FullNameCell({ row, deleteCommunityUserIds }) {
  const { colors } = useTheme()
  const s = useScopedI18n('tag')

  const user = row?.original

  const userFullName = useMemo(() => getFullName(user), [user])

  const isDeletedCommunityUser = useMemo(
    () => deleteCommunityUserIds.includes(user?.communityUserId),
    [deleteCommunityUserIds, user],
  )

  return (
    <Text
      color={isDeletedCommunityUser ? colors.primaryCardinal : colors.black}
    >
      {isDeletedCommunityUser
        ? s('actions.userHasBeenDeleted', { fullName: userFullName })
        : userFullName}
    </Text>
  )
}

FullNameCell.defaultProps = {
  deleteCommunityUserIds: [],
  row: {},
}

FullNameCell.propTypes = {
  deleteCommunityUserIds: PropTypes.array,
  row: PropTypes.object,
}

export default FullNameCell
