import React from 'react'
import { Navigate, RouteObject } from 'react-router-dom'

import { AuthLayout } from 'Components/Layout'

import Auth from 'Pages/Auth'

import * as ROUTES from './routes'

export const AUTH_ROUTES: RouteObject = {
  element: <AuthLayout />,
  path: ROUTES.AUTH_ROOT,
  children: [
    {
      element: <Navigate to={ROUTES.AUTH_SIGN_IN} />,
      path: ROUTES.AUTH_ROOT,
    },
    {
      element: <Auth.SignIn />,
      path: ROUTES.AUTH_SIGN_IN,
    },
    {
      element: <Auth.SignUp />,
      path: ROUTES.AUTH_SIGN_UP,
    },
    {
      element: <Auth.CheckMagicLink />,
      path: ROUTES.AUTH_CHECK_MAGIC_LINK,
    },
  ],
}
