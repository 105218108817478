import React from 'react'
import PropTypes from 'prop-types'

import { IconChevronDown } from '@tabler/icons-react'

import { Text } from 'Components/UI'

import { Container, Items, Title } from './styles'

function FilterDropdown({ title, isOpened, setIsOpened, children }) {
  return (
    <Container>
      <Title
        isOpened={isOpened}
        onClick={() => {
          setIsOpened(prevState => !prevState)
        }}
      >
        <Text header header4>
          {title}
        </Text>
        <IconChevronDown size={16} />
      </Title>

      {isOpened && <Items>{children}</Items>}
    </Container>
  )
}

FilterDropdown.defaultProps = {
  title: '',
  children: null,
}

FilterDropdown.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.string,
  ]),
  isOpened: PropTypes.bool.isRequired,
  setIsOpened: PropTypes.func.isRequired,
  title: PropTypes.string,
}

export default FilterDropdown
