import React, { PropsWithChildren } from 'react'
import ReactModal from 'react-modal'

import styled from 'styled-components'

import pick from 'lodash/pick'

import { logoPatternGlyph } from 'Assets/Svg/icons'

import { Button } from 'Components/UI/Button'
import { Row } from 'Components/UI/Flex'
import Text from 'Components/UI/Text'

import {
  Content,
  ContentWrapper,
  Divider,
  IContentWrapper,
  StyledModal,
} from './styles'

ReactModal.setAppElement('body')

interface IUnskippableModal extends IContentWrapper, PropsWithChildren {
  confirmDisabled?: boolean
  confirmText?: string
  isOpen?: boolean
  noScroll?: boolean
  shouldReturnFocusAfterClose?: boolean
  title?: string
  onConfirm?: () => void
  onClose?: () => void
}

const LogoPattern = styled(logoPatternGlyph)`
  width: 62px;
  height: 57px;
`

function UnskippableModal({
  children,
  confirmDisabled,
  confirmText,
  isOpen = false,
  noScroll = false,
  title,
  onConfirm,
  ...rest
}: IUnskippableModal) {
  if (!isOpen) {
    return null
  }

  return (
    <StyledModal
      isOpen={isOpen}
      shouldCloseOnEsc={false}
      shouldCloseOnOverlayClick={false}
      shouldReturnFocusAfterClose={false}
    >
      <Row center justifyCenter px={5} py={4}>
        {title ? (
          <Text header header2>
            {title}
          </Text>
        ) : (
          <div />
        )}

        <LogoPattern />
      </Row>

      <ContentWrapper {...pick(rest)}>
        <Content noScroll={noScroll}>{children}</Content>
      </ContentWrapper>

      {confirmText && (
        <>
          <Divider />
          <Row fullWidth justifyEnd px={5} py={4}>
            {confirmText && (
              <Button disabled={confirmDisabled} onClick={onConfirm}>
                {confirmText}
              </Button>
            )}
          </Row>
        </>
      )}
    </StyledModal>
  )
}

const PROPS = ['isOpen', 'title']

UnskippableModal.pickProps = (props: any) => pick(props, PROPS)

export default UnskippableModal
