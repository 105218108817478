import { mentionElementRegex, mentionRegex } from 'Constants/regex'

import { TOOLTIPS_BY_KIND } from './constants'

export function getCursorCoords(parentElementRef) {
  const doc = window.document
  let x = 0
  let y = 0

  if (doc.selection) {
    const sel = doc.selection
    if (sel.type !== 'Control') {
      const range = sel.createRange()
      range.collapse(true)
      x = range.boundingLeft
      y = range.boundingTop
    }
  } else if (window.getSelection) {
    const sel = window.getSelection()
    if (sel.rangeCount) {
      const range = sel.getRangeAt(0).cloneRange()
      if (range.getClientRects) {
        range.collapse(true)
        const rects = range.getClientRects()
        if (rects.length > 0) {
          const rect = rects[0]
          x = rect.left || 0
          y = rect.top || 0
        }
      }
      // Fall back to inserting a temporary element (Webkit bug)
      if (x === 0 && y === 0) {
        const span = doc.createElement('span')
        if (span.getClientRects) {
          // Ensure span has dimensions and position by
          // adding a zero-width space character
          span.appendChild(doc.createTextNode('\u200b'))
          range.insertNode(span)
          const rect = span.getClientRects()[0]
          x = rect.left
          y = rect.top
          const spanParent = span.parentNode
          spanParent.removeChild(span)

          // Glue any broken text nodes back together
          spanParent.normalize()
        }
      }
    }
  }

  if (parentElementRef) {
    const parentRect = parentElementRef.current.getBoundingClientRect()

    x -= parentRect.left
    y -= parentRect.top
  }

  return { x, y }
}

export function getCursorPosition(el) {
  const range = window.getSelection().getRangeAt(0)
  const preSelectionRange = range.cloneRange()

  preSelectionRange.selectNodeContents(el)
  preSelectionRange.setEnd(range.startContainer, range.startOffset)

  return preSelectionRange.toString().length
}

export function setCaretToEnd(target) {
  const range = document.createRange()
  const sel = window.getSelection()

  range.selectNodeContents(target)
  range.collapse(false)
  sel.removeAllRanges()
  sel.addRange(range)
  target.focus()
  range.detach()

  // set scroll to the end if multiline
  // eslint-disable-next-line no-param-reassign
  target.scrollTop = target.scrollHeight
}

export function addElementAtPosition(contentEditableElement, index, value) {
  const range = document.createRange()
  let currentIndex = 0
  let found = false

  function traverseNodes(node) {
    if (found || !node) {
      return
    }
    if (node.nodeType === Node.TEXT_NODE) {
      const nextIndex = currentIndex + node.length
      if (index >= currentIndex && index <= nextIndex) {
        range.setStart(node, index - currentIndex)
        range.setEnd(node, index - currentIndex)
        found = true
      }
      currentIndex = nextIndex
    } else {
      for (let i = 0; i < node.childNodes.length; i += 1) {
        traverseNodes(node.childNodes[i])
      }
    }
  }

  traverseNodes(contentEditableElement)

  if (!found) {
    range.setStart(contentEditableElement.childNodes[0], 0)
    range.setEnd(contentEditableElement.childNodes[0], 0)
  }

  let nodeToAdd

  if (typeof value === 'string') {
    nodeToAdd = document.createTextNode(value.trim())
  } else {
    const span = document.createElement('span')
    span.innerHTML = value.label.trim()
    span.classList.add('mention', `${value.kind}`)
    span.dataset.id = value.id
    span.dataset.kind = value.kind
    span.dataset.tooltip = TOOLTIPS_BY_KIND[value.kind]
    span.setAttribute('contentEditable', 'false')
    nodeToAdd = span
  }

  const selection = window.getSelection()
  selection.removeAllRanges()

  range.insertNode(nodeToAdd)

  // Remove a character before nodeToAdd if it has a previous text node sibling
  if (
    nodeToAdd.previousSibling &&
    nodeToAdd.previousSibling.nodeType === Node.TEXT_NODE &&
    nodeToAdd.previousSibling.textContent.endsWith('/')
  ) {
    const prevNode = nodeToAdd.previousSibling
    prevNode.deleteData(prevNode.length - 1, 1)
  }

  const space = document.createTextNode('\u00A0')
  nodeToAdd.parentNode.insertBefore(space, nodeToAdd.nextSibling)

  range.setStartAfter(space)
  range.setEndAfter(space)

  selection.addRange(range)
}

export function serializeContent(content) {
  //                                  [__kind__][__label__][__id__]
  return content
    .replaceAll(mentionElementRegex, '[__$3__][__$5__](__$2__)')
    .replaceAll('&nbsp;', ' ')
}

export function deserializeContent(content, existingMentions) {
  if (!content) return ''

  return content.replaceAll(
    mentionRegex,
    (match, kind, label, id) =>
      `<span class="mention ${kind}" data-id="${id}" data-kind="${kind}" data-tooltip="${
        TOOLTIPS_BY_KIND[kind]
      }" contentEditable="false">${
        existingMentions[id] ? label : 'deleted'
      }</span>`,
  )
}
