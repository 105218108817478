import styled, { css } from 'styled-components'
import { layout, margin, MarginProps, WidthProps } from 'styled-system'
import { themeGet } from '@styled-system/theme-get'

import { resetButton } from 'Components/Styles'

import colors from 'Theme/_v2/colors'

export interface IDropzone extends MarginProps, WidthProps {
  isDragActive?: boolean
}

export const AvatarContainer = styled.div`
  position: relative;
`

export const Dropzone = styled.div<IDropzone>`
  outline: none;
  cursor: pointer;

  ${props =>
    props.isDragActive &&
    css`
      border-color: ${themeGet('colors.positive')};
      cursor: grabbing;
    `};

  ${layout}
  ${margin}
`

export const MenuContainer = styled.div`
  position: absolute;
  bottom: -4px;
  right: -12px;
`

const menuToggleButtonOpenedCss = ({ isOpen }: { isOpen?: boolean }) =>
  isOpen &&
  css`
    background: ${colors.main.primary};
    color: #ffffff;
  `

export interface IMenuToggleButton {
  isOpen?: boolean
}

export const MenuToggleButton = styled.button<IMenuToggleButton>`
  ${resetButton}

  border-radius: 24px;
  border: 1px solid #ffffff;
  background: #ffffff;
  width: 24px;
  height: 24px;
  color: ${colors.main.primary};
  display: flex;
  align-items: center;
  justify-content: center;

  :enabled {
    cursor: pointer;

    :hover {
      border-color: ${colors.main.primary};
    }
  }

  ${menuToggleButtonOpenedCss}
`

export const Menu = styled.ul`
  min-width: 130px;
  position: absolute;
  z-index: 99;
  top: 28px;
  left: 0;
  margin: 0;
  padding: 0;
  border-radius: 4px;
  background: #ffffff;
  list-style: none;
  box-shadow:
    0px 4px 6px -2px rgba(0, 0, 0, 0.05),
    0px 10px 15px -3px rgba(0, 0, 0, 0.1),
    0px 0px 0px 1px rgba(0, 0, 0, 0.05);
`

export const MenuItem = styled.li``

export const MenuItemButton = styled.button`
  ${resetButton}

  padding: 8px 12px;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;

  :enabled {
    cursor: pointer;
  }

  :hover {
    background-color: ${themeGet('colors.bg.hover')};
    color: ${themeGet('colors.main')};

    > svg {
      color: ${themeGet('colors.main')};
    }
  }

  > svg {
    color: #757575;
  }
`
