import styled from 'styled-components'
import {
  border,
  BorderProps,
  margin,
  MarginProps,
  padding,
  PaddingProps,
} from 'styled-system'
import { themeGet } from '@styled-system/theme-get'

export interface IContainer extends MarginProps, PaddingProps {}

export const Container = styled.div.attrs<IContainer>({
  padding: [0, 0, 6],
})`
  display: flex;
  height: 100%;
  width: 100%;
  overflow: hidden;

  ${margin}
  ${padding}
`

export interface IContent extends MarginProps, BorderProps {}

export const Content = styled.div.attrs<IContent>({
  borderRadius: [0, 0, 4],
  borderStyle: ['none', 'none', 'solid'],
})`
  display: flex;
  flex-direction: column;
  background-color: ${themeGet('colors.bg.primary')};
  border-color: ${themeGet('colors.divider.default')};
  border-width: 1px;
  height: 100%;
  width: 100%;
  overflow: hidden;

  ${margin}
  ${border}
`
