import React from 'react'
import PropTypes from 'prop-types'

import { Text } from 'Components/UI'

import { LONG_DASH } from 'Constants/strings'

function Creator({ value }) {
  return <Text primary>{value ? `${value || ''}` : LONG_DASH}</Text>
}

Creator.defaultProps = {
  value: {},
}

Creator.propTypes = {
  value: PropTypes.object,
}

export default Creator
