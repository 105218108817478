import React from 'react'

import loadable from '@loadable/component'

import { Loader } from 'Components/UI'

const params = {
  fallback: <Loader absolute />,
}

export default {
  Profile: loadable(
    () => import('./Profile' /* webpackChunkName: "profile-profile" */),
    params,
  ),
}
