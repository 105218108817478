import { split } from '@apollo/client'
// eslint-disable-next-line import/no-extraneous-dependencies
import { getMainDefinition } from '@apollo/client/utilities'

import httpLink from './Links/httpLink'
import WebSocketLink from './Links/WebSocketLink'

function isSubscription({ query }) {
  const { kind, operation } = getMainDefinition(query)

  return kind === 'OperationDefinition' && operation === 'subscription'
}

export function getRequestLink(path) {
  return split(isSubscription, new WebSocketLink(path), httpLink(path))
}
