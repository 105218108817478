import React from 'react'

import * as Styled from './QuickActionMenuOption.styles'

export interface IQuickActionMenuOptionProps {
  isActive?: boolean
  hasSubMenu?: boolean
  label: string
  icon?: React.ReactNode
  onClick?: React.MouseEventHandler<HTMLButtonElement>
}

const QuickActionMenuOption = ({
  isActive,
  hasSubMenu,
  label,
  icon,
  onClick,
}: IQuickActionMenuOptionProps) => {
  return (
    <Styled.Container gap={2} isActive={isActive} onClick={onClick}>
      {icon && <Styled.Icon>{icon}</Styled.Icon>}

      <Styled.Label>{label}</Styled.Label>

      {hasSubMenu && <Styled.Arrow />}
    </Styled.Container>
  )
}

export default QuickActionMenuOption
