import styled from 'styled-components'
import { space, SpaceProps } from 'styled-system'
import { themeGet } from '@styled-system/theme-get'

import { Text } from 'Components/UI'

export interface ITopResultListProps extends SpaceProps {}

export const TopResultList = styled.div<ITopResultListProps>`
  ${space}
`

export const TopResultItem = styled(Text)`
  font-size: 14px;
  cursor: pointer;

  &:hover {
    color: ${themeGet('colors.darkenBlue')};
  }
`

export const Content = styled.div`
  padding: 16px 18px;
  border-bottom: 1px solid ${themeGet('colors.alto')};
`
