import React, { useCallback, useState } from 'react'
import PropTypes from 'prop-types'

import noop from 'lodash/noop'

import { Column, Input, Modal, Text } from 'Components/UI'

function DeleteAccountModal({ content, isOpen, title, onClose }) {
  const inputRef = React.useRef(null)

  const [confirmDisabled, setConfirmDisabled] = useState(true)

  const handleFinish = useCallback(
    async success => {
      onClose?.(success)
    },
    [onClose],
  )

  const handleCancel = useCallback(() => handleFinish(false), [handleFinish])

  const handleSuccess = useCallback(() => {
    handleFinish(true).then()
  }, [handleFinish])

  const handleInputChange = useCallback(() => {
    setConfirmDisabled(
      !inputRef.current?.value || inputRef.current?.value !== 'DELETE',
    )
  }, [])

  if (!isOpen) return null

  return (
    <Modal
      cancelText="Cancel"
      confirmDisabled={confirmDisabled}
      confirmText="Delete"
      isOpen
      title={title}
      onClose={handleCancel}
      onConfirm={handleSuccess}
    >
      <Column gap={4} width={['100%', '100%', '600px']}>
        <Text header header4>
          {content}
        </Text>

        <Input
          placeholder="Type DELETE"
          ref={inputRef}
          onChange={handleInputChange}
        />
      </Column>
    </Modal>
  )
}

DeleteAccountModal.defaultProps = {
  content: null,
  isOpen: false,
  title: null,
  onClose: noop,
}

DeleteAccountModal.propTypes = {
  content: PropTypes.node,
  isOpen: PropTypes.bool,
  title: PropTypes.string,
  onClose: PropTypes.func,
}

export default DeleteAccountModal
