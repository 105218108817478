/**
 * @see {@link https://www.apollographql.com/docs/react/caching/garbage-collection/#cacheevict}
 */

import { ApolloCache } from '@apollo/client'

import forEach from 'lodash/forEach'

export function entityCacheRemover({
  entity,
  queryKey,
  pageSize,
}: {
  entity: any
  queryKey?: string
  pageSize?: number
}) {
  return (cache: ApolloCache<any>) => {
    cache.evict({
      id: cache.identify(entity),
    })

    cache.gc()

    if (!queryKey || !pageSize) {
      return
    }

    cache.modify<any>({
      fields: {
        [queryKey]: (data: { count: number }) => {
          const nextCount = data.count - 1
          const nextPages = pageSize / data.count - 1

          return {
            count: nextCount,
            pages: nextPages,
          }
        },
      },
    })
  }
}

export function entitiesCacheRemover({
  entities,
  queryKey,
  pageSize,
}: {
  entities: any[]
  queryKey?: string
  pageSize?: number
}) {
  return (cache: ApolloCache<any>) => {
    forEach(entities, entity => cache.evict({ id: cache.identify(entity) }))

    cache.gc()

    if (!queryKey || !pageSize) {
      return
    }

    cache.modify<any>({
      fields: {
        [queryKey]: (data: { count: number }) => {
          const nextCount = data.count - 1
          const nextPages = pageSize / data.count - 1

          return {
            count: nextCount,
            pages: nextPages,
          }
        },
      },
    })
  }
}
