import { useMemo } from 'react'

import { ActionsCell, AddedAtCell, FullNameCell } from './Cells'

export function useColumns() {
  return useMemo(
    () => [
      {
        Header: `Full name`,
        Cell: FullNameCell,
        width: 1.5,
      },
      {
        Header: 'Added at',
        Cell: AddedAtCell,
        width: 1,
      },
      {
        Header: 'Actions',
        Cell: ActionsCell,
        width: 0.5,
        cellRight: true,
        headerRight: true,
      },
    ],
    [],
  )
}
