import React from 'react'
import PropTypes from 'prop-types'

import { pick } from '@styled-system/props'

import TagsField from 'Components/Blocks/Forms/Fields/TagsField'
import { Column, InputField } from 'Components/UI'

import { SEARCH_TYPES } from 'Constants/ids'

import _ from 'Services/I18n'

import { FIELD } from '../fields'
import { TabContainer } from '../styles'

function TagsTab({ visible, ...rest }) {
  return (
    <TabContainer {...pick(rest)} visible={visible}>
      <Column gap={4} px={5}>
        <TagsField
          label="Skills"
          name={FIELD.SKILLS}
          type={SEARCH_TYPES.skill}
        />

        <InputField
          label={_('auth.shared.hobbies&Interests')}
          name={FIELD.INTERESTS_HOBBIES}
        />

        <TagsField label="Event" name={FIELD.EVENT} type={SEARCH_TYPES.event} />

        <TagsField
          label="Project"
          name={FIELD.PROJECT}
          type={SEARCH_TYPES.project}
        />

        <TagsField label="Role" name={FIELD.ROLE} type={SEARCH_TYPES.role} />

        <TagsField
          label="Custom"
          name={FIELD.CUSTOM}
          type={SEARCH_TYPES.custom}
        />

        <TagsField label="Group" name={FIELD.GROUP} type={SEARCH_TYPES.group} />

        <InputField
          label="About"
          name={FIELD.ABOUT}
          placeholder="Write about user"
          small
          textArea
        />
      </Column>
    </TabContainer>
  )
}

TagsTab.defaultProps = {
  visible: true,
}

TagsTab.propTypes = {
  visible: PropTypes.bool,
}
export default TagsTab
