import React, { useEffect, useMemo, useRef, useState } from 'react'
import PropTypes from 'prop-types'

import { IconUserPlus } from '@tabler/icons-react'
import communityInvitesQuery from 'GraphQL/Queries/Community/communityInvites.graphql'

import map from 'lodash/map'
import noop from 'lodash/noop'

import { Button, Divider, Pagination, Table, Text } from 'Components/UI'

import { PERMISSION_ACTION, PERMISSION_SUBJECT } from 'Constants/permissions'

import { useCommunityContext, useEntityTable, usePermission } from 'Hooks'

import { useQuery } from 'Services/Apollo'

import { useColumns } from './columns'
import Filter from './Filter'
import { Container, Content, HeaderRow } from './styles'

function InvitesTable({ onOpenInviteModal }) {
  const [mostRecentPage, setMostRecentPage] = useState(0)
  const [mostRecentLimit, setMostRecentLimit] = useState(10)
  const mostRecentSortBy = useRef(null)

  const { community } = useCommunityContext()
  const { can } = usePermission()

  const [filters, setFilters] = useState({})

  const { data, loading } = useQuery(communityInvitesQuery, {
    variables: community
      ? {
          communityId: community.id,
          page: mostRecentPage,
          limit: mostRecentLimit,
          sort: mostRecentSortBy.current,
          ...filters,
        }
      : undefined,
    skip: !community,
  })

  const columns = useColumns()

  const entities = data?.communityInvites || {}

  const [tableProps, rowsCount] = useEntityTable({
    data: entities,
    columns,
  })

  const { pageSize, pageIndex, sortBy } = tableProps.state

  const sort = useMemo(
    () =>
      map(sortBy, value => ({
        column: value.id,
        order: value.desc ? 'desc' : 'asc',
      })),
    [sortBy],
  )

  mostRecentSortBy.current = sort.length !== 0 ? sort : null

  useEffect(() => {
    setMostRecentPage(pageIndex)
  }, [pageIndex])

  useEffect(() => {
    setMostRecentPage(0)
    setMostRecentLimit(pageSize)
  }, [pageSize])

  const handleFilters = filtersBy => setFilters(filtersBy)

  const canCreateInvites = can(
    PERMISSION_ACTION.CREATE,
    PERMISSION_SUBJECT.INVITE,
    [],
  )

  return (
    <Container>
      <HeaderRow>
        <Text header header3>
          Invites
        </Text>

        {canCreateInvites && (
          <Button mr={3} small onClick={onOpenInviteModal}>
            <IconUserPlus />
            Invite
          </Button>
        )}

        <Filter onChange={handleFilters} />
      </HeaderRow>

      <Content>
        <Table {...tableProps} loading={loading} sortable />
      </Content>

      <Divider />

      <Pagination
        loading={loading}
        px={5}
        py={3}
        showTotal={false}
        state={{ pageSize, pageIndex }}
        total={rowsCount}
        onPageIndexChange={tableProps?.gotoPage}
        onPageSizeChange={tableProps?.setPageSize}
      />
    </Container>
  )
}

InvitesTable.defaultProps = {
  onOpenInviteModal: noop,
}

InvitesTable.propTypes = {
  onOpenInviteModal: PropTypes.func,
}

export default InvitesTable
