import {
  ApolloClient,
  ApolloLink,
  InMemoryCache,
  useLazyQuery,
  useMutation,
  useQuery,
  useSubscription,
} from '@apollo/client'

import { IS_DEVELOPMENT } from 'Config'

import { setApolloClient } from 'Services/Shared'

import { authLink, errorLink, httpLink, requestLink } from './Links'

function createApolloClient({ history }) {
  const cache = new InMemoryCache({
    typePolicies: {
      UserProfile: {
        keyFields: ['userId'],
      },
    },
  })

  const links = [errorLink(history), authLink(), requestLink(), httpLink()]

  const clientOptions = {
    link: ApolloLink.from(links),
    connectDevTools: IS_DEVELOPMENT,
    cache,
  }

  const client = new ApolloClient(clientOptions)

  setApolloClient(client)

  return client
}

function appendAdminContext(options) {
  return {
    ...options,
    context: { ...options?.context, admin: true },
  }
}

function useAdminQuery(query, options) {
  return useQuery(query, appendAdminContext(options))
}

function useAdminLazyQuery(query, options) {
  return useLazyQuery(query, appendAdminContext(options))
}

function useAdminMutation(query, options) {
  return useMutation(query, appendAdminContext(options))
}

function useAdminSubscription(query, options) {
  return useSubscription(query, appendAdminContext(options))
}

export {
  appendAdminContext,
  createApolloClient,
  useAdminLazyQuery,
  useAdminMutation,
  useAdminQuery,
  useAdminSubscription,
  useLazyQuery,
  useMutation,
  useQuery,
  useSubscription,
}
