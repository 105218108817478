import React from 'react'
import { components } from 'react-select'

import Text from 'Components/UI/Text'

import { Item } from './styles'

const LoadingMessage: typeof components.LoadingMessage = props => {
  return (
    <Item>
      <Text bodyMedium center isPlaceholder my={2}>
        {props.children}
      </Text>
    </Item>
  )
}

export default LoadingMessage
