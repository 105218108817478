import styled from 'styled-components'
import { space, SpaceProps } from 'styled-system'

export interface ITopSkillsColorProps extends SpaceProps {}

export const TopSkillsColor = styled.div<ITopSkillsColorProps>`
  width: 18px;
  height: 8px;
  border-radius: 4px;
  background-color: ${({ color }) => color};
  flex-shrink: 0;

  ${space}
`
