import styled, { keyframes } from 'styled-components'

export const Container = styled.div`
  position: relative;
  border: 0 solid lightgray;
  width: 100%;
  height: 100%;
`

const spin = keyframes`
  0%,
  100% {
    transform: translateY(40%);
  }
  33% {
    transform: rotate(0deg);
  }
  66% {
    transform: rotate(360deg);
  }
`

const elongate = keyframes`
  0%,
  29%,
  95%,
  100% {
    height: 10px;
    border-radius: 50%;
  }
  23%,
  83% {
    height: 17px;
    border-radius: 17px;
    line-height: 17px;
  }
  33%,
  66% {
    height: 10px;
    border-radius: 50%;
  }
`

export const GraphLoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  position: absolute;
  z-index: 100;
  width: 100vw;
  top: 0;
  background-color: rgb(222, 222, 222);
  background: linear-gradient(
    180deg,
    rgba(222, 222, 222, 0.3) 0%,
    rgba(154, 161, 167, 0.3) 100%
  );

  & .container {
    height: 70px;
    width: 70px;
    background-color: rgba(245, 245, 246, 255);
    position: relative;
    border-radius: 50%;
  }

  & .column {
    position: absolute;
    top: 10px;
    left: 30px;
    width: 10px;
    height: 50px;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
  }

  & .column1 {
    animation: ${spin} 3s 300ms infinite;
  }

  & .column2 {
    animation: ${spin} 3s 150ms infinite;
  }

  & .column3 {
    animation: ${spin} 3s infinite;
  }

  & .ball {
    height: 10px;
    width: 10px;
    border-radius: 50%;
    animation: ${elongate} 3s infinite;
  }

  & .ball3 {
    background-color: rgba(182, 50, 72, 0.9);
  }

  & .ball2 {
    background-color: rgba(245, 231, 236, 0.9);
  }

  & .ball1 {
    background-color: rgba(228, 125, 146, 0.9);
  }
`
