import React, { useCallback, useMemo, useState } from 'react'
import { Form, FormRenderProps } from 'react-final-form'
import PropTypes from 'prop-types'

import { useMutation } from '@apollo/client'
import connectUsersToSkillsMutation from 'GraphQL/Mutations/Community/connectUsersToSkills.graphql'
import connectUsersToTagsMutation from 'GraphQL/Mutations/Community/connectUsersToTags.graphql'
import createCommunityUserMutation from 'GraphQL/Mutations/CommunityUser/createCommunityUser.graphql'
import { getCommunityUserSkillsUpdater } from 'GraphQL/Updaters/GetCommunityUserSkills'
import { getCommunityUserTagsUpdater } from 'GraphQL/Updaters/GetCommunityUserTags'
import { updateCommunityUserDirectConnectionsUpdater } from 'GraphQL/Updaters/User'
import Utils from 'Utils'
import validate from 'validate.js'

import find from 'lodash/find'
import fromPairs from 'lodash/fromPairs'
import map from 'lodash/map'
import noop from 'lodash/noop'

import { OPTIONS as RELATIONSHIP_OPTIONS } from 'Components/Blocks/Forms/Fields/RelationshipStrengthField'
import { Divider, MenuItem, Modal, Row } from 'Components/UI'

import {
  PERMISSION_ACTION,
  PERMISSION_SCOPES,
  PERMISSION_SUBJECT,
} from 'Constants/permissions'
import { LINKEDIN_REGEX } from 'Constants/regex'

import { useAppContext, useCommunityContext, usePermission } from 'Hooks'

import EventBus from 'Services/EventBus'
import _, { useScopedI18n } from 'Services/I18n'
import toast from 'Services/Toast'

import { FIELD } from './fields'
import { ErrorWrapper } from './styles'
import { GeneralTab, TagsTab } from './Tabs'

const TABS = {
  GENERAL_INFO: 'General Info',
  TAGS: 'Tags',
}

const DEFAULT_TABS = [TABS.GENERAL_INFO, TABS.TAGS]

function entitiesToValues(entities: any): MainSchema.Tag[] {
  return map(entities, entity => ({
    ...entity,
    value: entity.id,
    label: entity.name,
  }))
}

type FieldValues = (typeof FIELD)[keyof typeof FIELD]
type FormValues = {
  [K in FieldValues]: K extends 'firstName' | 'lastName' | 'email'
    ? string
    : K extends 'skills' | 'communities'
      ? Array<{ value: string; label: string }>
      : K extends 'relationship'
        ? { value: string; label: string } | null
        : any
}

interface CreateCommunityUserProps {
  // This is never passed to this modal. But the modal is too hard to change right now.
  // Documenting this so that it can be removed in the future.
  userDontUseThis?: any
  isOpen?: boolean
  onClose?: () => void
}

function CreateCommunityUserModal({
  userDontUseThis: user,
  isOpen,
  onClose,
}: CreateCommunityUserProps) {
  const { can } = usePermission()

  const { community } = useCommunityContext()
  const { me } = useAppContext()
  const t = useScopedI18n('components.blocks.modals.createCommunityUser')

  const [createCommunityUser] = useMutation(createCommunityUserMutation)
  const [connectUsersToTags] = useMutation<
    Pick<MainSchema.Mutation, 'connectUsersToTags'>,
    MainSchema.MutationConnectUsersToTagsArgs
  >(connectUsersToTagsMutation)
  const [connectUsersToSkills] = useMutation<
    Pick<MainSchema.Mutation, 'connectUsersToSkills'>,
    MainSchema.MutationConnectUsersToSkillsArgs
  >(connectUsersToSkillsMutation)

  const [loading, setLoading] = useState(false)
  const [tab, setTab] = useState(TABS.GENERAL_INFO)

  const update = !!user

  const canEditAnyAccount = can(
    PERMISSION_ACTION.EDIT,
    PERMISSION_SUBJECT.ACCOUNT,
    PERMISSION_SCOPES.ACCOUNT_PRIVATE,
  )

  const isCreator = useMemo(
    () => user?.creator?.id === me?.id,
    [me?.id, user?.creator?.id],
  )

  const isStubUser = user?.isStub

  const canEditEmail = useMemo(() => {
    if (!update) return true
    return !!(
      (canEditAnyAccount || isCreator || isStubUser) &&
      user?.email !== 'Private'
    )
  }, [isCreator, update, user?.email, isStubUser, canEditAnyAccount])

  const canEditPhone = useMemo(() => {
    if (!update) return true
    return !!(
      (canEditAnyAccount || isCreator || isStubUser) &&
      user?.phoneNumber !== 'Private'
    )
  }, [isCreator, canEditAnyAccount, update, user?.phoneNumber, isStubUser])

  const initialSkills = useMemo(() => entitiesToValues(user?.skills), [user])

  const initialTags = useMemo(() => {
    const tags = Utils.Tag.tagsByKind(entitiesToValues(user?.tags))

    return {
      ...tags,
      industries: undefined,
    }
  }, [user])

  const initialValues = useMemo(() => {
    const relationshipOption = find(
      RELATIONSHIP_OPTIONS,
      item => item.value === user?.relationshipStrength,
    )

    const values = {
      [FIELD.FIRST_NAME]: user?.firstName,
      [FIELD.LAST_NAME]: user?.lastName,
      [FIELD.PHOTO_URL]: user?.photoUrl,
      [FIELD.JOB]: user?.jobTitle?.name,
      [FIELD.LINKED_IN]: user?.linkedInUrl || null,
      [FIELD.PHONE_NUMBER]: user?.phoneNumber,
      [FIELD.RELATIONSHIP]: relationshipOption,
      [FIELD.ORGANIZATION]: user?.organization?.name,
      [FIELD.INTERESTS_HOBBIES]: user?.interestsHobbies || null,
      [FIELD.ABOUT]: user?.about,
      [FIELD.SKILLS]: initialSkills,
      [FIELD.CUSTOM]: entitiesToValues(initialTags.custom),
      [FIELD.PROJECT]: entitiesToValues(initialTags.projects),
      [FIELD.ROLE]: entitiesToValues(initialTags.roles),
      [FIELD.EVENT]: entitiesToValues(initialTags.events),
      [FIELD.GROUP]: entitiesToValues(initialTags.groups),
      [FIELD.COMMUNITIES]: user
        ? [{ value: community?.id, label: community?.name }]
        : [],
    }

    if (user?.email !== 'Private') values[FIELD.EMAIL] = user?.email
    if (user?.phoneNumber !== 'Private')
      values[FIELD.PHONE_NUMBER] = user?.phoneNumber

    return values
  }, [user, community, initialTags, initialSkills])

  const formConstraints = useMemo(() => {
    const constraints = {
      [FIELD.PHOTO_URL]: {
        type: 'string',
      },
      [FIELD.FIRST_NAME]: {
        type: 'string',
        presence: {
          allowEmpty: false,
          message: `^${_('auth.shared.firsNameRequired')}`,
        },
        length: {
          maximum: 255,
          tooLong: `^${_('auth.shared.firsNameTooLong')}`,
        },
      },
      [FIELD.LAST_NAME]: {
        type: 'string',
        presence: {
          allowEmpty: false,
          message: `^${_('auth.shared.lastNameRequired')}`,
        },
        length: {
          maximum: 255,
          tooLong: `^${_('auth.shared.lastNameTooLong')}`,
        },
      },
      [FIELD.JOB]: {
        type: 'string',
        length: {
          maximum: 255,
          tooLong: `^${_('auth.shared.jobTooLong')}`,
        },
      },
      [FIELD.LINKED_IN]: {
        type: 'string',
        format: {
          pattern: LINKEDIN_REGEX,
          message: `^${_('auth.shared.linkedinProfileUrl')}`,
        },
      },
      [FIELD.ORGANIZATION]: {
        type: 'string',
        length: {
          maximum: 255,
          tooLong: `^${_('auth.shared.organizationTooLong')}`,
        },
      },
      [FIELD.INTERESTS_HOBBIES]: {
        type: 'string',
      },
      [FIELD.ABOUT]: {
        type: 'string',
      },
      [FIELD.COMMUNITIES]: {
        presence: {
          allowEmpty: false,
          message: `^${_('auth.shared.communityRequired')}`,
        },
      },
    }

    return constraints
  }, [])

  const submit = useCallback(
    async (values: FormValues) => {
      setLoading(true)

      const tags = [
        ...values[FIELD.EVENT],
        ...values[FIELD.GROUP],
        ...values[FIELD.PROJECT],
        ...values[FIELD.ROLE],
        ...values[FIELD.CUSTOM],
      ]

      const skills: MainSchema.Skill[] = [...values[FIELD.SKILLS]]

      const changedValues = fromPairs(
        Object.entries(values)?.filter(
          ([key, value]) =>
            initialValues?.[key] !== value && value !== 'Private',
        ),
      )

      if (initialValues.email === changedValues.email) {
        changedValues.email = undefined
        delete changedValues.email
      }

      const skillIds = map(values[FIELD.SKILLS], 'value')
      const tagIds = map(tags, 'value')

      try {
        const selectedCommunities = values[FIELD.COMMUNITIES]

        for (const selectedCommunity of selectedCommunities) {
          let userId = user?.userId
          let communityUserId = user?.communityUserId

          const response = await createCommunityUser({
            variables: {
              communityId: selectedCommunity.value,
              ...values,
              [FIELD.RELATIONSHIP]: values[FIELD.RELATIONSHIP]?.value,
            },
            update: (store, { data: communityAppendUserData }) =>
              values[FIELD.RELATIONSHIP]?.value
                ? updateCommunityUserDirectConnectionsUpdater(
                    selectedCommunity.value,
                    me?.id!,
                    store,
                    communityAppendUserData?.communityAppendUser,
                  )
                : null,
          })

          EventBus.trigger(EventBus.actions.dashboard.refetch)

          userId = response?.data?.createCommunityUser?.userId
          communityUserId = response?.data?.createCommunityUser?.id

          if (skillIds?.length) {
            const communityUsers = [
              {
                communityUserId: communityUserId!,
                userId: userId!,
                communityId: selectedCommunity.value,
              },
            ]

            const usersToSkills: MainSchema.ConnectUsersToSkillsInputType[] = []
            skillIds.forEach(skillId => {
              usersToSkills.push({
                skillId,
                communityUserId: communityUserId!,
              })
            })

            await connectUsersToSkills({
              variables: {
                communityId: selectedCommunity.value,
                usersToSkills,
              },
              update: getCommunityUserSkillsUpdater({
                communityIds: [selectedCommunity.value],
                communityUsers,
                skills,
              }),
            })
          }

          if (tagIds?.length) {
            const usersToTags: MainSchema.ConnectUsersToTagsInputType[] = []
            tagIds.forEach(tagId => {
              usersToTags.push({
                tagId,
                communityUserId: communityUserId!,
              })
            })

            const communityUsers = [
              {
                communityUserId: communityUserId!,
                userId: userId!,
                communityId: selectedCommunity.value,
              },
            ]

            await connectUsersToTags({
              variables: {
                communityId: selectedCommunity.value,
                usersToTags,
              },
              update: getCommunityUserTagsUpdater({
                communityIds: [selectedCommunity.value],
                communityUsers,
                communityUserTags: tags.map(
                  tag =>
                    ({
                      tagId: tag.value,
                      tag: {
                        id: tag.value,
                        name: tag.label,
                        kind: tag.kind,
                      } as MainSchema.Tag,
                    }) as MainSchema.CommunityUserTag,
                ),
              }),
            })
          }

          EventBus.trigger(EventBus.actions.graph.addUserById, {
            userId,
            isSelected: true,
            fromUserId: me?.id,
          })

          EventBus.trigger(EventBus.actions.graph.focusNode, userId)
        }

        toast.success({
          title: t('toast.title'),
          text:
            t(`toast.success.updated.${Boolean(update)}`, {
              count: selectedCommunities.length,
            }) +
            (selectedCommunities.length > 1
              ? t('toast.success.multipleCommunities')
              : t('toast.success.oneCommunity')),
        })

        setTab(TABS.GENERAL_INFO)
        setLoading(false)

        onClose?.()
      } catch (error: any) {
        toast.error({
          title: 'Server error',
          text: _(`error.${error?.message || 'generic'}`),
        })
        setLoading(false)
      }
    },
    [
      initialValues,
      user,
      update,
      onClose,
      createCommunityUser,
      me,
      connectUsersToSkills,
      connectUsersToTags,
      t,
    ],
  )

  const renderForm = useCallback(
    ({ handleSubmit, form }: FormRenderProps<FormValues>) => {
      const formErrors = Utils.Form.errors(form, FIELD, {
        checkDirty: true,
      })

      return (
        <Modal
          cancelText={_('general.cancel')}
          confirmDisabled={loading}
          confirmText={_('general.save')}
          isOpen={isOpen}
          p={0}
          title={t('title')}
          width={['0', '0', '600px']}
          onClose={onClose}
          onConfirm={handleSubmit}
        >
          <Row fullWidth px={5}>
            {DEFAULT_TABS.map(item => (
              <MenuItem
                caption={item}
                isActive={item === tab}
                isButton
                key={item}
                onClick={() => setTab(item)}
              />
            ))}
          </Row>

          <Divider my={2} />

          <GeneralTab
            canEditEmail={canEditEmail}
            canEditPhone={canEditPhone}
            mb={5}
            visible={tab === TABS.GENERAL_INFO}
          />

          <TagsTab mb={5} visible={tab === TABS.TAGS} />

          {Object.values(formErrors).length > 0 && (
            <Row center justifyEnd pb={4}>
              <ErrorWrapper>
                {_('auth.shared.fieldValidationError')}
              </ErrorWrapper>
            </Row>
          )}
        </Modal>
      )
    },
    [loading, isOpen, onClose, canEditEmail, canEditPhone, tab, t],
  )

  if (!isOpen) return null

  return (
    <Form<FormValues>
      initialValues={initialValues}
      render={renderForm}
      validate={values => validate(values, formConstraints)}
      onSubmit={submit}
    />
  )
}

CreateCommunityUserModal.defaultProps = {
  isOpen: false,
  onClose: noop,
}

CreateCommunityUserModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
}

export default CreateCommunityUserModal
