import React, { useCallback, useMemo, useRef } from 'react'
import PropTypes from 'prop-types'

import { SEARCH_TYPES, TAG_KIND } from 'Constants/ids'
import { PERMISSION_ACTION, PERMISSION_SUBJECT } from 'Constants/permissions'

import { usePermission } from 'Hooks'

import { useScopedI18n } from 'Services/I18n'

import { TableActions } from '../../Blocks'
import { useTableContext } from '../context'

function ActionsCell({ row }) {
  const editRef = useRef(null)
  const deleteRef = useRef(null)
  const { can } = usePermission()

  const s = useScopedI18n('tag.actions')

  const { onEditRow, onDeleteRow, type } = useTableContext()

  const tag = useMemo(() => row?.original, [row])

  const isIndustryTag = tag.kind === TAG_KIND.INDUSTRY

  const canDeleteTag = isIndustryTag
    ? can(
        PERMISSION_ACTION.DELETE,
        PERMISSION_SUBJECT.MANAGEMENT_INDUSTRIES_TAG,
        [],
      )
    : can(PERMISSION_ACTION.DELETE, PERMISSION_SUBJECT.MANAGEMENT_TAG, [])

  const canEditTag = isIndustryTag
    ? can(
        PERMISSION_ACTION.EDIT,
        PERMISSION_SUBJECT.MANAGEMENT_INDUSTRIES_TAG,
        [],
      )
    : can(PERMISSION_ACTION.EDIT, PERMISSION_SUBJECT.MANAGEMENT_TAG, [])

  const canEdit =
    type === 'tag'
      ? canEditTag
      : can(PERMISSION_ACTION.EDIT, PERMISSION_SUBJECT.MANAGEMENT_SKILL, [])

  const canDelete =
    type === 'tag'
      ? canDeleteTag
      : can(PERMISSION_ACTION.DELETE, PERMISSION_SUBJECT.MANAGEMENT_SKILL, [])

  const handleEdit = useCallback(() => {
    editRef.current.blur()
    onEditRow(tag)
  }, [onEditRow, tag])

  const handleDelete = useCallback(() => {
    deleteRef.current.blur()
    onDeleteRow(tag)
  }, [onDeleteRow, tag])

  return (
    <TableActions
      canDelete={canDelete}
      canEdit={canEdit}
      deleteRef={deleteRef}
      deleteTooltip={s('delete', {
        tagName: tag?.name,
        tagType: `${tag.kind || SEARCH_TYPES.skill}`,
      })}
      editRef={editRef}
      editTooltip={s('edit', {
        tagType: `${tag?.kind || SEARCH_TYPES.skill}`,
        tagName: `${tag?.name}`,
      })}
      onDelete={handleDelete}
      onEdit={handleEdit}
    />
  )
}

ActionsCell.defaultProps = {
  row: {},
}

ActionsCell.propTypes = {
  row: PropTypes.object,
}

export default ActionsCell
