import React from 'react'
import { useNavigate } from 'react-router-dom'

import agreeToTermsAndConditionsMutation from 'GraphQL/Mutations/User/agreeToTermsAndConditions.graphql'

import { Button, Column, Divider, Link, Row, Text } from 'Components/UI'

import { EXTERNAL_LINKS } from 'Constants/ids'

import { useMutation } from 'Services/Apollo'
import AuthService from 'Services/Auth'

function PrivacyAgreement() {
  const navigate = useNavigate()

  const [agreeToTermsAndConditions] = useMutation(
    agreeToTermsAndConditionsMutation,
  )

  const handleAgree = async () => {
    const agreed = await agreeToTermsAndConditions()
    if (agreed) {
      window.location.reload()
    }
  }

  const handleDecline = async () => {
    await AuthService.logOut(navigate)
  }

  return (
    <Column fullHeight fullWidth>
      <Column center fullWidth gap={6} grow justifyCenter p={4}>
        <Text center header header1>
          Before you can use NetworkOS, you must agree to{' '}
        </Text>
        <Row gap={2}>
          <Link href={EXTERNAL_LINKS.termsOfUse} info isExternal large>
            Terms of Use
          </Link>

          <Text bodyLarge header>
            and
          </Text>

          <Link href={EXTERNAL_LINKS.privacyPolicy} info isExternal large>
            Privacy Policy
          </Link>
        </Row>
      </Column>

      <Divider />

      <Row gap={6} justifyEnd p={4}>
        <Button secondary onClick={handleDecline}>
          Decline
        </Button>
        <Button onClick={handleAgree}>Agree</Button>
      </Row>
    </Column>
  )
}

export default PrivacyAgreement
