import React, { useEffect, useMemo, useState } from 'react'
import { useLocation } from 'react-router-dom'

import qs from 'qs'

import { Row, Text } from 'Components/UI'

function Nylas() {
  const [error, setError] = useState(false)
  const location = useLocation()

  useEffect(() => {
    const query = qs.parse(location.search.replace('?', ''))

    if (!query.success) {
      setError(true)
      return
    }

    if (window.opener) {
      window.opener.postMessage({ success: true, grantId: query.grant_id }, '*')
      window.close()
    }
  }, [location])

  const renderState = useMemo(() => {
    if (error) return <Text>Something goes wrong, please try again later</Text>

    return <Text>Connecting Nylas...</Text>
  }, [error])

  return (
    <Row center fullScreen justifyCenter>
      {renderState}
    </Row>
  )
}

export default Nylas
