const oldColors = {
  alto: '#E0E0E0',
  athensGray: '#F9FAFB',
  alabaster: '#FAFAFA',
  blue: '#449aff',
  chartYellow: '#ffc744',
  catskillWhite: '#F9FAFC',
  darkenRed: '#d31e4e',
  darkenBlue: '#0474f7',
  gallery: '#EEEEEE',
  gray: '#808080',
  grayAthens: '#E5E7EB',
  grayBoulder: '#757575',
  grayLight: '#b5b5b5',
  graySilver: '#BBBBBB',
  grayWithWhite: '#f2f2f2',
  grayDove: '#737373',
  greenLight: '#6EC147',
  mineShaft: '#2D2D2D',
  primary: '#2b2b2b',
  primaryCardinal: '#CD1C45',
  primaryLight: '#e95f5f',
  primaryWePeep: '#FAE8EC',
  silver: '#BDBDBD',
  tamarillo: '#A41637',
  wildSand: '#F5F5F5',
  grayLightest: '#f4f4f4',
  chartRed: '#ff4b77',
  chartBlue: '#1C64F2',
  chartLightBlue: '#A8CBFF',
  chartLightRed: '#FEB2C3',
  chartDark: '#424242',
  chartOrange: '#ff8a09',
  positiveDark: '#3ea74b',
  lochmara: '#0B79D0',
  white: '#ffffff',
  darkCyan: '#226EA0',
  chartreuse: '#BBED61',
  purple: '#8A2BE2',
  teal: '#34cfc2',
  lightcoral: '#ED616D',
}

export default oldColors
