import apiCall, { HttpMethod } from 'Services/Api'

export interface IRefreshParams {
  refreshToken: string
}

export function refresh({ refreshToken }: IRefreshParams) {
  return apiCall({
    endpoint: '/auth/refresh-token',
    method: HttpMethod.Get,
    query: { refreshToken },
  })
}
