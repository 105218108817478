import styled, { css } from 'styled-components'
import { themeGet } from '@styled-system/theme-get'

import { resetButton } from 'Components/Styles'

export const Container = styled.div`
  display: flex;
  position: relative;
  max-height: 200px;
  overflow: auto;
`

export interface IHighlightItemCssProps {
  highlight?: boolean
}

const highlightItemCss = ({ highlight }: IHighlightItemCssProps) =>
  highlight &&
  css`
    background: ${themeGet('colors.primary10')};
    color: ${themeGet('colors.primary80')};
  `

export interface IItemProps extends IHighlightItemCssProps {}

export const Item = styled.button<IItemProps>`
  ${resetButton};

  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  cursor: pointer;
  padding: ${themeGet('space.3')}px ${themeGet('space.4')}px;
  gap: ${themeGet('space.3')}px;
  color: ${themeGet('colors.primaryNeutral100')};

  ${highlightItemCss}
`
