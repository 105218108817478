import styled from 'styled-components'
import { themeGet } from '@styled-system/theme-get'

export const Content = styled.div`
  display: flex;
  align-items: center;
  width: 100%;

  > svg {
    width: 16px;
    height: 16px;
    color: ${themeGet('colors.text.header')};
    stroke-width: 1.5px;
  }
`
