import React, { useMemo } from 'react'
import PropTypes from 'prop-types'

import {
  PERMISSION_ACTION,
  PERMISSION_SCOPE,
  PERMISSION_SUBJECT,
} from 'Constants/permissions'

import { useAppContext, usePermission } from 'Hooks'

import { useScopedI18n } from 'Services/I18n'

import { TableActions } from '../../Blocks'
import { useTableContext } from '../context'

function ActionsCell({ row }) {
  const { me } = useAppContext()
  const { can } = usePermission()
  const { onDeleteRow } = useTableContext()
  const s = useScopedI18n('hubSpotsManagement.actions')

  const hub = useMemo(() => row?.original, [row])

  const canDeleteOwn =
    can(PERMISSION_ACTION.DELETE, PERMISSION_SUBJECT.HUBSPOT, [
      PERMISSION_SCOPE.OWN,
    ]) && hub.creatorId === me.id

  const canDeleteOther =
    can(PERMISSION_ACTION.DELETE, PERMISSION_SUBJECT.HUBSPOT, [
      PERMISSION_SCOPE.OTHER,
    ]) && hub.creatorId !== me.id

  return (
    <TableActions
      canDelete={canDeleteOwn || canDeleteOther}
      deleteTooltip={s('disconnect')}
      onDelete={() => onDeleteRow(hub)}
    />
  )
}

ActionsCell.defaultProps = {
  row: {},
}

ActionsCell.propTypes = {
  row: PropTypes.object,
}

export default ActionsCell
