import styled from 'styled-components'
import { margin, MarginProps } from 'styled-system'

import { customScrollbar } from 'Theme/styles'

export interface IContainerProps extends MarginProps {}

export const Container = styled.div<IContainerProps>`
  width: 100%;
  height: 267px;
  overflow: auto;

  ${margin}

  ${customScrollbar}
`
