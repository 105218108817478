import React, { useCallback, useMemo, useRef, useState } from 'react'
import { Form } from 'react-final-form'
import PropTypes from 'prop-types'

import updateUserMutation from 'GraphQL/Mutations/Admin/Community/updateUser.graphql'
import validate from 'validate.js'

import get from 'lodash/get'
import noop from 'lodash/noop'

import GenerateMagicLink from 'Components/Blocks/Admin/GenerateMagicLink'
import { Button, InputField, Loader, Modal, Row } from 'Components/UI'

import { useMutation } from 'Services/Apollo'
import toast from 'Services/Toast'

import { Content, Subtitle } from './styles'

const FIELD = {
  FIRST_NAME: 'firstName',
  LAST_NAME: 'lastName',
}

function EditProfileModal({ user, onClose, isOpen }) {
  const [loading, setLoading] = useState(false)

  const close = useRef(null)

  const [updateUser] = useMutation(updateUserMutation)

  const formConstraints = useMemo(
    () => ({
      [FIELD.FIRST_NAME]: {
        presence: true,
      },
      [FIELD.LAST_NAME]: {
        presence: true,
      },
    }),
    [],
  )

  const handleMount = useCallback(instance => {
    close.current = get(instance, 'handleClose')
  }, [])

  const submit = useCallback(
    async values => {
      setLoading(true)

      try {
        await updateUser({
          variables: {
            userId: user?.id,
            firstName: values[FIELD.FIRST_NAME],
            lastName: values[FIELD.LAST_NAME],
          },
        })

        toast.success({
          title: 'User profile',
          text: `${values[FIELD.FIRST_NAME]} profile updated`,
        })

        setLoading(false)

        onClose()
      } catch (error) {
        toast.error({
          title: 'Oops...',
          text: error?.message,
        })

        setLoading(false)
      }
    },
    [onClose, updateUser, user],
  )

  return (
    <Modal
      isOpen={isOpen}
      shouldCloseOnOverlayClick={false}
      title="Edit Profile"
      width="400px"
      onClose={onClose}
      onMount={handleMount}
    >
      <Form
        initialValues={user?.profile}
        render={({ handleSubmit }) => (
          <>
            <Content>
              <Subtitle mb={4}>
                Email: <strong>{user?.primaryEmailCredential?.email}</strong>
              </Subtitle>

              <Subtitle mb={4}>
                ID: <strong>{user?.id}</strong>
              </Subtitle>

              <InputField
                disabled
                label="First Name"
                mb={3}
                name={FIELD.FIRST_NAME}
                width={1}
              />

              <InputField
                disabled
                label="Last Name"
                mb={4}
                name={FIELD.LAST_NAME}
                width={1}
              />

              <GenerateMagicLink user={user} />
            </Content>

            <Row center mt={4} spaceBetween>
              {loading ? (
                <Loader />
              ) : (
                <>
                  <Button secondary small onClick={onClose}>
                    Cancel
                  </Button>
                  <Button small onClick={handleSubmit}>
                    Save
                  </Button>
                </>
              )}
            </Row>
          </>
        )}
        validate={values => validate(values, formConstraints)}
        onSubmit={submit}
      />
    </Modal>
  )
}

EditProfileModal.defaultProps = {
  isOpen: false,
  user: null,
  onClose: noop,
}

EditProfileModal.propTypes = {
  isOpen: PropTypes.bool,
  user: PropTypes.object,
  onClose: PropTypes.func,
}

export default EditProfileModal
