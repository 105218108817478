import { FiChevronDown } from 'react-icons/fi'

import styled, { css } from 'styled-components'
import { margin, MarginProps } from 'styled-system'
import { themeGet } from '@styled-system/theme-get'

export const ArrowIcon = styled(FiChevronDown)`
  width: 20px;
  height: 20px;
  color: ${themeGet('colors.mineShaft')};
  transition: all 0.3s ease;
`

const openedContainerCss = ({ $opened }: { $opened?: boolean }) =>
  $opened &&
  css`
    border-radius: 6px 6px 0 0;

    ${ArrowIcon} {
      transform: rotate(180deg);
    }
  `

export interface IContainer extends MarginProps {
  $opened?: boolean
}

export const Container = styled.div<IContainer>`
  display: flex;
  border-radius: 6px;
  border: 1px solid ${themeGet('colors.alto')};
  padding: 9px 13px;
  position: relative;
  background-color: ${themeGet('colors.alabaster')};

  ${openedContainerCss}

  ${margin}
`

export const ValueContainer = styled.div`
  display: flex;
  cursor: pointer;
  justify-content: space-between;
  width: 100%;
`

export const Value = styled.span`
  color: ${themeGet('colors.mineShaft')};
  line-height: 21px;
`

export const Placeholder = styled.span`
  color: ${themeGet('colors.grayBoulder')};
`

export const DropdownContainer = styled.div`
  width: calc(100% + 2px);
  transition: all 0.3s ease;
  position: absolute;
  z-index: ${themeGet('zIndices.popover')};
  top: 100%;
  left: -1px;
  background-color: ${themeGet('colors.alabaster')};
  border: 1px solid #e0e0e0;
  border-radius: 0 0 6px 6px;
`
