import { useEffect } from 'react'
import useEvent from 'react-use-event-hook'

export default function useKeys(
  callback: (event: KeyboardEvent) => void,
  keysSet: Set<string>,
) {
  const cb = useEvent(callback)

  useEffect(() => {
    const handle = (event: KeyboardEvent) => {
      if (keysSet.has(event.key)) {
        cb(event)
      }
    }

    window.addEventListener('keydown', handle)
    return () => {
      window.removeEventListener('keydown', handle)
    }
  }, [cb, keysSet])
}
