import mapValues from 'lodash/mapValues'

import { FRONTEND } from '../Config'

export interface IBuildFrontendPath {
  path?: string
  queryParams?: Record<string, unknown>
}

export function buildFrontendPath({
  path = '',
  queryParams,
}: IBuildFrontendPath) {
  const searchParams = new URLSearchParams(
    mapValues(queryParams, (value: unknown) => value as string),
  ).toString()

  return `${path}${searchParams ? `?${searchParams}` : ''}`
}

export interface IBuildFrontendUrl extends IBuildFrontendPath {
  slug?: string
}

export function buildFrontendUrl({
  slug,
  path = '',
  queryParams,
}: IBuildFrontendUrl) {
  return `${FRONTEND.PROTOCOL}://${slug ? `${slug}.` : ''}${
    FRONTEND.URL
  }${buildFrontendPath({ path, queryParams })}`
}

// generatePath('/community/:slug', { slug: 'test' })
// generatePath('/community/:slug/:id', { slug: 'test', id: '123' })
export function generatePath(
  template: string,
  params: Record<string, unknown>,
) {
  return Object.entries(params).reduce(
    (path, [key, value]) => path.replace(`:${key}`, value as string),
    template,
  )
}
