import React from 'react'

import { Column, Row, Text } from 'Components/UI'

import { Box } from './styles'

function Shadows() {
  return (
    <Column gap={6}>
      <Text header1>Shadows</Text>
      <Row fullWidth gap={5}>
        <Box primaryFocus>Primary – Focus</Box>
        <Box secondaryFocus>Secondary – Focus</Box>
        <Box dangerFocus>Danger – Focus</Box>
        <Box>Box</Box>
      </Row>
    </Column>
  )
}

export default Shadows
