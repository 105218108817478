import React, { useCallback, useMemo, useState } from 'react'
import { Form } from 'react-final-form'
import PropTypes from 'prop-types'

import { useMutation } from '@apollo/client'
// Obsolete
import createCommunityUserMutation from 'GraphQL/Mutations/CommunityUser/createCommunityUser.graphql'
import validate from 'validate.js'

import noop from 'lodash/noop'

import { Button, InputField, Loader, Modal, Row } from 'Components/UI'

import _ from 'Services/I18n'
import toast from 'Services/Toast'

import { Content, Subtitle } from './styles'

const FIELD = {
  EMAIL: 'email',
  FIRST_NAME: 'firstName',
  LAST_NAME: 'lastName',
}

function AddUserToCommunity({ communityId, isOpen, onClose }) {
  const [loading, setLoading] = useState(false)

  const [createCommunityUser] = useMutation(createCommunityUserMutation)

  const formConstraints = useMemo(
    () => ({
      [FIELD.EMAIL]: {
        presence: {
          presence: true,
          message: `^email is required`,
        },
        email: {
          email: true,
          message: `^invalid email`,
        },
      },
      [FIELD.FIRST_NAME]: {
        presence: {
          presence: true,
          message: `^First name is required`,
        },
      },
      [FIELD.LAST_NAME]: {
        presence: {
          presence: true,
          message: `^Last name is required`,
        },
      },
    }),
    [],
  )

  const handleFinish = useCallback(
    success => {
      onClose?.(success)
    },
    [onClose],
  )
  const handleCancel = useCallback(() => handleFinish(false), [handleFinish])

  const submit = useCallback(
    async values => {
      setLoading(true)

      try {
        await createCommunityUser({
          variables: {
            communityId,
            ...values,
          },
        })

        toast.success({
          title: 'Add user to community',
          text: `User ${values.email} successfully added`,
        })

        handleFinish(true)
      } catch (error) {
        toast.error({
          title: 'Add user to community',
          text: _(`error.${error?.message || 'generic'}`),
        })
      } finally {
        setLoading(false)
      }
    },
    [communityId, createCommunityUser, handleFinish],
  )

  return (
    <Modal
      isOpen={isOpen}
      title="Add a user to the community"
      width="400px"
      onClose={onClose}
    >
      <Form
        render={({ handleSubmit }) => (
          <>
            <Content>
              <Subtitle my={3}>You can add a user to the community</Subtitle>

              <InputField
                label="Email"
                mb={3}
                name={FIELD.EMAIL}
                required
                type="email"
                width={1}
              />

              <InputField
                label="First Name (optional)"
                mb={3}
                name={FIELD.FIRST_NAME}
                width={1}
              />

              <InputField
                label="Last Name (optional)"
                mb={4}
                name={FIELD.LAST_NAME}
                width={1}
              />
            </Content>

            <Row center mt={2} spaceBetween>
              {loading ? (
                <Loader />
              ) : (
                <>
                  <Button secondary small onClick={handleCancel}>
                    Cancel
                  </Button>
                  <Button small onClick={handleSubmit}>
                    Add
                  </Button>
                </>
              )}
            </Row>
          </>
        )}
        validate={values => validate(values, formConstraints)}
        onSubmit={submit}
      />
    </Modal>
  )
}

AddUserToCommunity.defaultProps = {
  communityId: null,
  isOpen: false,
  onClose: noop,
}

AddUserToCommunity.propTypes = {
  communityId: PropTypes.string,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
}
export default AddUserToCommunity
