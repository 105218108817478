import { useCallback, useEffect, useState } from 'react'

const useKeysDown = () => {
  const [keysDown, setKeysDown] = useState<string[]>([])

  const handleKeyDown = useCallback(
    (event: KeyboardEvent) => {
      if (keysDown.includes(event.key)) {
        return
      }

      setKeysDown([...keysDown, event.key])
    },
    [keysDown],
  )

  const handleKeyUp = useCallback(
    (event: KeyboardEvent) => {
      setKeysDown(keysDown.filter(key => key !== event.key))
    },
    [keysDown],
  )

  const handleBlur = useCallback(() => {
    setKeysDown([])
  }, [])

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown)
    window.addEventListener('keyup', handleKeyUp)
    window.addEventListener('blur', handleBlur)

    return () => {
      window.removeEventListener('keydown', handleKeyDown)
      window.removeEventListener('keyup', handleKeyUp)
      window.removeEventListener('blur', handleBlur)
    }
  }, [handleKeyDown, handleKeyUp, handleBlur])

  return keysDown
}

export default useKeysDown
