import React from 'react'
import { RouteObject } from 'react-router-dom'

import { CommunityLayout } from 'Components/Layout'

import Profile from 'Pages/Profile'

import * as ROUTES from './routes'

export const PROFILE_ROUTES: RouteObject = {
  element: <CommunityLayout />,
  path: ROUTES.PROFILE_ROOT,
  children: [
    {
      element: <Profile.Profile />,
      path: ROUTES.PROFILE_ROOT,
    },
  ],
}
