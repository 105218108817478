import React from 'react'

import { Column, Row, Text } from 'Components/UI'

const TEXT_EXAMPLE = 'If you never try'

function Typography() {
  return (
    <Column gap={6}>
      <Text header1>Typography</Text>
      <Row fullWidth gap={5}>
        <Column gap={4}>
          <Text header2 primary80>
            Headers
          </Text>
          <Column gap={3}>
            <Text header1>header1 - {TEXT_EXAMPLE}</Text>
            <Text header2>header2 - {TEXT_EXAMPLE}</Text>
            <Text header3>header3 - {TEXT_EXAMPLE}</Text>
            <Text header4>header4 - {TEXT_EXAMPLE}</Text>
            <Text header5>header5 - {TEXT_EXAMPLE}</Text>
          </Column>
        </Column>

        <Column gap={4}>
          <Text header2 primary80>
            Body
          </Text>
          <Column gap={3}>
            <Text bodyLarge>bodyLarge - {TEXT_EXAMPLE}</Text>
            <Text bodyMedium>bodyMedium - {TEXT_EXAMPLE}</Text>
            <Text bodySmall>bodySmall - {TEXT_EXAMPLE}</Text>
          </Column>
        </Column>

        <Column gap={4}>
          <Text header2 primary80>
            Action
          </Text>
          <Column gap={3}>
            <Text actionLarge>actionLarge - {TEXT_EXAMPLE}</Text>
            <Text actionMedium>actionMedium - {TEXT_EXAMPLE}</Text>
            <Text actionSmall>actionSmall - {TEXT_EXAMPLE}</Text>
          </Column>
        </Column>

        <Column gap={4}>
          <Text header2 primary80>
            Caption
          </Text>
          <Column gap={3}>
            <Text captionLarge>caption_Large - {TEXT_EXAMPLE}</Text>
            <Text captionMedium>caption_Medium - {TEXT_EXAMPLE}</Text>
            <Text captionSmall>caption_Small - {TEXT_EXAMPLE}</Text>
          </Column>
        </Column>
      </Row>
    </Column>
  )
}

export default Typography
