import styled, { css } from 'styled-components'
import { position, PositionProps } from 'styled-system'

import { IconDotsVertical } from '@tabler/icons-react'

import { resetButton } from 'Components/Styles'

import colors from 'Theme/_v2/colors'

export interface ContainerProps extends PositionProps {}

export const Container = styled.div<ContainerProps>`
  position: absolute;
  z-index: 1;

  ${position}
`

export const Content = styled.div`
  border-radius: 4px;
  background: #fff;
  box-shadow:
    0px 4px 6px -2px rgba(0, 0, 0, 0.05),
    0px 10px 15px -3px rgba(0, 0, 0, 0.1),
    0px 0px 0px 1px rgba(0, 0, 0, 0.05);

  display: flex;
  flex-direction: row;

  transform: translate3d(-50%, -50%, 0);
`

export const ButtonContainer = styled.div`
  :not(:last-child) {
    border-right: 1px solid #dde1e4;
  }
`

export interface ButtonIsActiveCss {
  isActive?: boolean
}

const buttonIsActiveCss = ({ isActive }: ButtonIsActiveCss) =>
  isActive &&
  css`
    background-color: #f0f0f0;
  `

export interface ButtonProps extends ButtonIsActiveCss {}

export const Button = styled.button<ButtonProps>`
  ${resetButton}

  width: 36px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;

  :enabled {
    cursor: pointer;

    :hover {
      svg {
        color: #cd1c45;
      }
    }
  }

  svg {
    width: 16px;
    height: 16px;
    color: #757575;
  }

  ${buttonIsActiveCss}
`

// TODO: remove
export const DotsIcon = styled(IconDotsVertical)`
  width: 24px;
  height: 24px;
  color: ${colors.icon.profile};
`
