import React, { useCallback, useMemo } from 'react'
import { DateRange } from 'react-day-picker'

import { pick } from '@styled-system/props'

import { IconCalendarEvent } from '@tabler/icons-react'
import { DateTime } from 'luxon'

import DateRangePicker from 'Components/UI/DatePicker/DateRangePicker'
import Dropdown from 'Components/UI/Dropdown'
import { Input } from 'Components/UI/Form/Input'

import _ from 'Services/I18n'

const DATE_RANGE_PICKER_PLACEHOLDER = 'YYYY / M / D'
const DATE_DISPLAY_FORMAT = 'yyyy/L/d'

export interface IDateRangePickerInputProps {
  dateRange?: DateRange
  disabled?: boolean
  isLoading?: boolean
  label?: React.ReactNode
  width?: number
  onBlur?: React.FocusEventHandler<HTMLInputElement>
  onChange?: (value?: DateRange) => void
  onFocus?: React.FocusEventHandler<HTMLInputElement>
  onKeyDown?: React.KeyboardEventHandler<HTMLInputElement>
}

function DateRangePickerInput({
  disabled,
  isLoading,
  label,
  dateRange,
  onChange,
  onBlur,
  onFocus,
  onKeyDown,
  ...rest
}: IDateRangePickerInputProps) {
  const inputValue = useMemo(() => {
    const fromDate = dateRange?.from
      ? DateTime.fromJSDate(dateRange.from)
      : undefined
    const toDate = dateRange?.to ? DateTime.fromJSDate(dateRange.to) : undefined

    const fromCaption = fromDate?.isValid
      ? fromDate.toFormat(DATE_DISPLAY_FORMAT)
      : _('general.noDate')

    const toCaption = toDate?.isValid
      ? toDate.toFormat(DATE_DISPLAY_FORMAT)
      : _('general.noDate')

    return `${fromCaption} - ${toCaption}`
  }, [dateRange])

  const handleChange = useCallback(
    (innerValue?: DateRange) => {
      onChange?.(innerValue)
    },
    [onChange],
  )

  const handleClear = useCallback<
    React.MouseEventHandler<HTMLButtonElement>
  >(() => {
    onChange?.({ from: undefined, to: undefined })
  }, [onChange])

  return (
    <Dropdown
      content={
        <DateRangePicker
          value={{ from: dateRange?.from, to: dateRange?.to }}
          onChange={handleChange}
        />
      }
    >
      <Input
        {...pick(rest)}
        clearable
        disabled={disabled}
        isLoading={isLoading}
        label={label}
        placeholder={DATE_RANGE_PICKER_PLACEHOLDER}
        renderBeforeElement={() => <IconCalendarEvent />}
        value={inputValue}
        onBlur={onBlur}
        onClear={handleClear}
        onFocus={onFocus}
        onKeyDown={onKeyDown}
      />
    </Dropdown>
  )
}

export default DateRangePickerInput
