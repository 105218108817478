import React, { useEffect, useMemo, useState } from 'react'

import proposalsQuery from 'GraphQL/Queries/Proposals/proposals.graphql'

import map from 'lodash/map'

import ContentCard from 'Components/Blocks/ContentCard'
import { Table } from 'Components/UI'

import { useCommunityContext, useEntityTable } from 'Hooks'

import { useQuery } from 'Services/Apollo'

import { useColumns } from './columns'
import TableContext from './context'
import Filter from './Filter'

import { TableControlContainer, TablePagination } from '../Blocks'

const DEFAULT_PAGE_SIZE = 10

const SORT_BY = [
  {
    column: 'createdAt',
    order: 'desc',
  },
]

function ProposalsTable() {
  const { community } = useCommunityContext()

  const [mostRecentPage, setMostRecentPage] = useState(0)
  const [mostRecentLimit, setMostRecentLimit] = useState(DEFAULT_PAGE_SIZE)
  const [mostRecentSortBy, setMostRecentSortBy] = useState(SORT_BY)
  const [filter, setFilter] = useState({})

  const { data, loading, refetch } = useQuery(proposalsQuery, {
    variables: {
      communityId: community?.id,
      page: mostRecentPage,
      limit: mostRecentLimit,
      sort: mostRecentSortBy,
      creatorIds: filter?.creatorIds,
      createdFrom: filter?.createdFrom,
      createdTo: filter?.createdTo,
      kind: filter?.kind,
    },
    fetchPolicy: 'network-only',
  })

  const columns = useColumns()

  const entities = data?.proposals || {}

  const [tableProps, rowsCount] = useEntityTable({
    data: entities,
    columns,
    initialState: {
      sortBy: [
        {
          id: 'createdAt',
          desc: true,
        },
      ],
    },
  })

  const { pageSize, pageIndex, sortBy } = tableProps.state

  const sort = useMemo(
    () =>
      map(sortBy, value => ({
        column: value.id,
        order: value.desc ? 'desc' : 'asc',
      })),
    [sortBy],
  )

  useEffect(() => {
    setMostRecentPage(pageIndex)
    setMostRecentSortBy(sort.length !== 0 ? sort : null)
  }, [pageIndex, sort])

  useEffect(() => {
    setMostRecentPage(0)
    setMostRecentLimit(pageSize)
  }, [pageSize])

  const memoizedContext = useMemo(
    () => ({
      pageSize: mostRecentLimit,
      onRefetch: refetch,
      entitiesCount: entities?.count ?? 0,
    }),
    [entities?.count, mostRecentLimit, refetch],
  )

  return (
    <ContentCard>
      <TableContext.Provider value={memoizedContext}>
        <TableControlContainer>
          <Filter onChange={setFilter} />
        </TableControlContainer>

        <Table {...tableProps} loading={loading} />

        <TablePagination
          state={{ pageIndex, pageSize }}
          total={rowsCount}
          onPageIndexChange={tableProps?.gotoPage}
          onPageSizeChange={tableProps?.setPageSize}
        />
      </TableContext.Provider>
    </ContentCard>
  )
}

export default ProposalsTable
