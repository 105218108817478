import React, { MouseEventHandler, useCallback } from 'react'

import ConnectDropdown from 'Components/Blocks/ConnectDropdown'
import SimpleUserCard, {
  ISimpleUserCardUser,
} from 'Components/Blocks/UserCard/SimpleUserCard'

import { useGraphContext } from 'Hooks'

import { StyledButton } from './styles'

export type HandleClick = (user: ISimpleUserCardUser, shiftKey: boolean) => void

export interface IExtendedUserCard {
  id?: string
  showConnectionActions?: boolean
  onClick?: HandleClick
  user: ISimpleUserCardUser
  onMenuClicked?: (user: ISimpleUserCardUser) => void
}

function ExtendedUserCard({
  id,
  showConnectionActions,
  onClick,
  onMenuClicked,
  user,
}: IExtendedUserCard) {
  const { graphState } = useGraphContext()

  const handleClick: MouseEventHandler<HTMLDivElement> = useCallback(
    event => {
      onClick?.(user, event.shiftKey)
    },
    [onClick, user],
  )

  const handleMenuClicked: MouseEventHandler<HTMLButtonElement> = useCallback(
    event => {
      event.stopPropagation()
      onMenuClicked?.(user)
    },
    [onMenuClicked, user],
  )

  return (
    <SimpleUserCard
      id={id}
      selected={!!graphState.selection?.[user.userId!]}
      user={user}
      onClick={handleClick}
    >
      {/* TODO: pass one dropdown by prop or quickActions or connectActions */}
      {showConnectionActions && (
        <StyledButton onClick={handleMenuClicked}>
          <ConnectDropdown userId={user.userId} />
        </StyledButton>
      )}
    </SimpleUserCard>
  )
}

export default ExtendedUserCard
