import styled from 'styled-components'
import { themeGet } from '@styled-system/theme-get'

import { secureGlyph } from 'Assets/Svg/icons'

export const OuterWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
`
export const Wrapper = styled.div`
  display: flex;
  height: 100%;

  > * {
    display: flex;
    flex-basis: 50%;
  }

  > *:first-child {
    justify-content: center;
    padding: 24px;
  }
`

export const Title = styled.h2`
  font-weight: 700;
  font-size: 40px;
  line-height: 48px;
  display: flex;
  align-items: center;
  margin-bottom: 0;
`

export const Description = styled.p`
  font-weight: 400;
  font-size: 16px;
  line-height: 25px;
`

export const Pane = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  box-shadow: -9px 0px 16px rgba(0, 0, 0, 0.06);
`
interface BlockProps {
  grow?: boolean
}

export const Profile = styled.div`
  display: flex;
  flex-direction: column;
  width: 62%;
  margin-top: auto;
  margin-bottom: auto;
  gap: 12px;
`

export const ConnectWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  margin-top: auto;
  margin-bottom: auto;
  justify-content: center;
  gap: 12px;
  min-width: 450px;
`

export const Block = styled.div<BlockProps>`
  display: flex;
  justify-content: center;
  flex-direction: column;
  max-width: 548px;

  ${({ grow }) =>
    grow &&
    `
      flex-grow: 1;
      max-width: none;
      width: 100%;
      align-items: center;
    `}
`
interface StepProps {
  step: number
}

export const Step = styled.div<StepProps>`
  display: flex;
  align-self: flex-start;

  > * {
    background-color: #cfd0d1;
    width: 38px;
    height: 4px;
    border-radius: 100px;
  }

  & > * + * {
    margin-left: 4px;
  }

  > *:nth-child(-n + ${({ step }) => step}) {
    background-color: ${themeGet('colors.main')};
  }
`

interface ActionProps {
  step: number
}
export const Action = styled.div<ActionProps>`
  width: 100%;
  padding: 24px;
  display: flex;
  border-top: 1px solid #cfd0d1;
  justify-content: ${({ step }) => (step > 1 ? 'space-between' : 'flex-end')};

  > * {
    margin-top: 14px;
    margin-bottom: 2px;
  }
`

export const Disclaimer = styled.p`
  display: flex;
  align-items: center;

  > * + * {
    margin-left: 4px;
  }
`
export const SecureGlyph = styled(secureGlyph)`
  width: 16px;
  height: 17px;
`
