import { down } from 'styled-breakpoints'
import styled from 'styled-components'

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  ${down('md')} {
    width: calc(100vw - 20px);
    min-height: auto;
  }
`
