import styled from 'styled-components'
import { themeGet } from '@styled-system/theme-get'

export const Container = styled.div`
  display: flex;
  position: relative;
`

export const Dropdown = styled.div`
  display: flex;
  flex-direction: column;
  width: 430px;
  max-width: 90vw;
  background-color: ${themeGet('colors.bg.primary')};
  border-radius: 4px;
  position: absolute;
  top: 110%;
  left: 0;
  z-index: 9999;
  box-shadow: ${themeGet('shadows.box')};
`
