import React, { useCallback, useMemo, useRef, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import userCommunitiesQuery from 'GraphQL/Queries/Community/userCommunities.graphql'
import Utils from 'Utils'

import CreateCommunityModal from 'Components/Blocks/Modals/CreateCommunity'
import {
  Avatar,
  Box,
  Column,
  IconButton,
  Loader,
  Row,
  Text,
  Tooltip,
} from 'Components/UI'

import {
  useAppContext,
  useCommunityContext,
  useEntityModal,
  useOnClickOutside,
} from 'Hooks'

import {
  COMMUNITY_DASHBOARD,
  COMMUNITY_MANAGEMENT,
  COMMUNITY_SLUG,
} from 'Router/routes'

import { useQuery } from 'Services/Apollo'
import { useScopedI18n } from 'Services/I18n'
import { setCommunity } from 'Services/Store/community'

import colors from 'Theme/Main/colors'

import * as Styled from './styles'

function CommunitySelector() {
  const { community } = useCommunityContext()
  const { isRestricted, me } = useAppContext()
  const location = useLocation()
  const navigate = useNavigate()
  const t = useScopedI18n('components.blocks.navigation.communitySelector')

  const [opened, setOpened] = useState(false)

  const [createModal, createModalActions] = useEntityModal()

  const ref = useRef<HTMLDivElement | null>(null)

  useOnClickOutside(ref, () => setOpened(false))

  const { data, loading, refetch } = useQuery<
    Pick<MainSchema.Query, 'userCommunities'>,
    MainSchema.QueryUserCommunitiesArgs
  >(userCommunitiesQuery, { variables: { page: 0, limit: 100 } })

  const communities = useMemo(
    () =>
      data?.userCommunities?.rows
        .filter(
          communityItem =>
            communityItem?.creatorId === me?.id || !communityItem.open,
        )
        .sort((a, b) => {
          if (a.creatorId === me?.id && b.creatorId !== me?.id) return -1
          if (b.creatorId === me?.id && a.creatorId !== me?.id) return 1

          if (a.id === me?.personalCommunityId) return -1
          if (b.id === me?.personalCommunityId) return 1
          return a.name.localeCompare(b.name)
        }) || [],
    [me, data],
  )

  const isActive = (slug: MainSchema.Community['slug']) => {
    const communityPath = Utils.URL.generatePath(COMMUNITY_SLUG, { slug })

    return location.pathname.startsWith(communityPath)
  }

  const handleCommunitySelect = useCallback(
    (selectedCommunity: MainSchema.Community) => {
      setCommunity(selectedCommunity)
      navigate(
        Utils.URL.generatePath(COMMUNITY_DASHBOARD, {
          slug: selectedCommunity.slug,
        }),
      )
      setOpened(false)
    },
    [navigate],
  )

  const handleCommunityManagementSelect = useCallback(
    (selectedCommunity: MainSchema.Community) => {
      setCommunity(selectedCommunity)
      navigate(
        Utils.URL.generatePath(COMMUNITY_MANAGEMENT, {
          slug: selectedCommunity.slug,
        }),
      )
      setOpened(false)
    },
    [navigate],
  )

  const handleCreateCommunity = createModalActions.openModal

  const handleCloseModal = async () => {
    if (refetch) {
      await refetch()
      createModalActions.closeModal(true)
    }
  }

  return (
    <>
      <Styled.Holder ref={ref}>
        <Styled.Dropdown
          aria-controls={opened ? 'community-menu' : undefined}
          aria-expanded={opened}
          aria-haspopup="true"
          isOpen={opened}
          onClick={() => setOpened(prevState => !prevState)}
        >
          <Tooltip
            content={<Box width={'250px'}>{community?.description}</Box>}
            offset={[115, 10]}
          >
            <Text header4>{community?.name || t('selectCommunity')}</Text>
          </Tooltip>
          <Styled.ChevronIcon open={opened} />
        </Styled.Dropdown>

        {opened &&
          (loading ? (
            <Loader />
          ) : (
            <Styled.DropdownContainer id="community-menu">
              <Row fullWidth gap={1} marginLeft={'34px'} paddingY={'14px'}>
                <Text captionMedium color={colors.text.body}>
                  {t('myCommunities').toLocaleUpperCase()}
                </Text>
                {communities.length > 1 && (
                  <Styled.CommunityCount captionMedium color={colors.text.body}>
                    {communities.length}
                  </Styled.CommunityCount>
                )}
              </Row>

              <Styled.DropdownDivider />

              <Styled.ScrollContainer>
                {communities.map(comm => (
                  <Styled.CommunityItem
                    isActive={isActive(comm.slug)}
                    key={comm.id}
                    onClick={() => handleCommunitySelect(comm)}
                  >
                    <Styled.CommunityLink>
                      {isActive(comm.slug) ? (
                        <Styled.CircleCheckFilledIcon />
                      ) : (
                        <div />
                      )}
                      <Avatar small src={comm.photoUrl} />
                      <Column gap={1}>
                        <Text header4>{comm.name}</Text>

                        <Text color={colors.text.body}>
                          <Styled.PersonOutlineIcon />
                          {`${comm.members.toLocaleString()} Members`}
                        </Text>
                      </Column>
                    </Styled.CommunityLink>
                    <IconButton
                      disabled={isRestricted}
                      secondary
                      onClick={e => {
                        e.stopPropagation()
                        handleCommunityManagementSelect(comm)
                      }}
                    >
                      <Styled.GearIcon />
                    </IconButton>
                  </Styled.CommunityItem>
                ))}
              </Styled.ScrollContainer>

              <Styled.DropdownDivider />

              <Styled.CreateCommunityButton onClick={handleCreateCommunity}>
                <div />
                <Styled.CirclePlusDashedOutlineIcon />
                <Text color={colors.text.body} header4>
                  {t('createNewCommunity')}
                </Text>
              </Styled.CreateCommunityButton>
            </Styled.DropdownContainer>
          ))}
      </Styled.Holder>

      <CreateCommunityModal
        isOpen={createModal.isOpen}
        onClose={handleCloseModal}
      />
    </>
  )
}

export default CommunitySelector
