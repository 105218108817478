import { useMemo } from 'react'
import { useLocation } from 'react-router-dom'

import qs from 'qs'

import trimStart from 'lodash/trimStart'

export default function useLocationQueryParams() {
  const location = useLocation()

  return useMemo(
    () =>
      qs.parse(trimStart(location.search, '?'), {
        plainObjects: true,
      }),
    [location],
  )
}
