import styled, { css } from 'styled-components'
import { margin, MarginProps } from 'styled-system'
import { themeGet } from '@styled-system/theme-get'

import { customScrollbar } from 'Theme/styles'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
`

export interface IActiveOptionCssProps {
  active?: boolean
}

const activeOptionCss = ({ active }: IActiveOptionCssProps) =>
  active &&
  css`
    background: ${themeGet('colors.primary10')};
    color: ${themeGet('colors.primary80')};
  `

export interface IDDisabledOptionCssProps {
  disabled?: boolean
}

const disabledOptionCss = ({ disabled }: IDDisabledOptionCssProps) =>
  disabled &&
  css`
    color: ${themeGet('colors.text.disabled')};
    :hover {
      color: ${themeGet('colors.text.disabled')};
      background: transparent;
    }
  `

export interface IOptionProps
  extends IActiveOptionCssProps,
    IDDisabledOptionCssProps {}

export const Option = styled.div<IOptionProps>`
  display: flex;
  width: 100%;
  padding: ${themeGet('space.3')}px ${themeGet('space.4')}px;
  cursor: pointer;

  span {
    color: ${themeGet('colors.primary80')};
  }

  :hover {
    background: ${themeGet('colors.primary10')};
    color: ${themeGet('colors.primary80')};
  }

  ${activeOptionCss}
  ${disabledOptionCss}
`

export interface IDropdownProps extends MarginProps {}

export const Dropdown = styled.div<IDropdownProps>`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-height: 180px;
  overflow: auto;

  ${margin};

  ${customScrollbar};
`
