import { HTMLAttributes } from 'react'
import {
  Link as ReactRouterLink,
  LinkProps as ReactRouterLinkProps,
} from 'react-router-dom'

import styled, { css } from 'styled-components'
import {
  flexbox,
  FlexboxProps,
  layout,
  LayoutProps,
  space,
  SpaceProps,
  typography,
  TypographyProps,
} from 'styled-system'
import shouldForwardProp from '@styled-system/should-forward-prop'

import { resetButton } from 'Components/Styles'

import colors from 'Theme/_v2/colors'
import { gap, GapProps } from 'Theme/system'

export enum LinkVariant {
  Primary = 'primary',
  Secondary = 'secondary',
}

export interface IVariantCss {
  variant?: `${LinkVariant}`
}

const primaryCss = ({ variant = LinkVariant.Primary }: IVariantCss) =>
  variant === LinkVariant.Primary &&
  css`
    color: ${colors.link.primary.main};

    :visited {
      color: ${colors.link.primary.main};
    }

    :hover {
      color: ${colors.link.primary.hover};
    }

    :focus,
    :active {
      color: ${colors.link.primary.focus};
    }
  `

const secondaryCss = ({ variant }: IVariantCss) =>
  variant === LinkVariant.Secondary &&
  css`
    color: ${colors.link.secondary.main};

    :visited {
      color: ${colors.link.secondary.main};
    }

    :hover {
      color: ${colors.link.secondary.hover};
    }

    :focus,
    :active {
      color: ${colors.link.secondary.focus};
    }
  `

export interface IUnderlineCss {
  underline?: boolean
}

const underlineCss = ({ underline }: IUnderlineCss) =>
  underline &&
  css`
    text-decoration: underline;
  `

export interface ILinkCss
  extends IVariantCss,
    IUnderlineCss,
    LayoutProps,
    SpaceProps,
    TypographyProps,
    FlexboxProps,
    GapProps {
  underline?: boolean
}

const linkCss = css<ILinkCss>`
  text-decoration: none;
  text-overflow: ellipsis;
  overflow: hidden;

  :disabled {
    pointer-events: none;
    cursor: default;
  }

  :enabled {
    cursor: pointer;
  }

  ${primaryCss}
  ${secondaryCss}

  ${underlineCss}

  ${layout}
  ${space}
  ${typography}
  ${flexbox}
  ${gap}
`

export interface ILink extends ILinkCss, ReactRouterLinkProps {}

export const Link = styled(ReactRouterLink).withConfig<ILink>({
  shouldForwardProp,
})`
  ${linkCss}
`

export interface IEternalLink
  extends ILinkCss,
    HTMLAttributes<HTMLAnchorElement> {}

export const ExternalLink = styled.a.withConfig<IEternalLink>({
  shouldForwardProp,
})`
  ${linkCss}
`

export interface ILinkButton
  extends ILinkCss,
    HTMLAttributes<HTMLButtonElement> {}

export const LinkButton = styled.button.withConfig<ILinkButton>({
  shouldForwardProp,
})`
  ${resetButton}
  ${linkCss}
`
