import React from 'react'

import Popover, { IPopover } from 'Components/UI/Popover'

export interface IDropdown extends IPopover {
  children?: React.ReactElement
  content?: React.ReactNode
  offsetY?: number
  placement?: 'bottom' | 'bottom-start' | 'bottom-end'
}

function Dropdown({
  content,
  children,
  placement = 'bottom',
  offsetY = 20,
  ...rest
}: IDropdown) {
  return (
    <Popover
      arrow={false}
      content={content}
      delay={[0, 0]}
      duration={0}
      interactive
      maxWidth="100%"
      offset={[0, offsetY]}
      placement={placement}
      trigger="click"
      {...rest}
    >
      {children}
    </Popover>
  )
}

export default Dropdown
