import styled, { css } from 'styled-components'
import { margin, MarginProps, size, SizeProps } from 'styled-system'
import { themeGet } from '@styled-system/theme-get'

import { flexCenter } from 'Components/Styles'

const borderRadiusCss = ({ borderRadius }: { borderRadius: number }) => css`
  border-radius: ${borderRadius}px;
`

export interface IWrapper extends MarginProps, SizeProps {
  borderRadius: number
}

export const Wrapper = styled.div<IWrapper>`
  ${flexCenter};
  background-color: ${themeGet('colors.primaryLight')};
  color: ${themeGet('colors.white')};
  transition: border-radius 0.3s ease;
  flex-shrink: 0;

  ${size}
  ${margin}
  ${borderRadiusCss}
`

export const Image = styled.img`
  width: 100%;
  height: 100%;
  border-radius: inherit;
  object-fit: cover;
  transition: border-radius 0.3s ease;
`

export const Empty = styled.div`
  ${flexCenter};
  width: 100%;
  height: 100%;
  background: ${themeGet('colors.alto')};
  border-radius: inherit;
  border: 1px solid ${themeGet('colors.white')};
`
