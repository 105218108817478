import { useCallback, useRef } from 'react'

import get from 'lodash/get'

import { HandleClose, IModalProps, OnMount } from 'Components/UI/OldModal'

export interface IUseModalProps extends Omit<IModalProps, 'onClose'> {
  onClose: (success: boolean) => void
}

export default function useModal({ onClose, ...rest }: IUseModalProps) {
  const close = useRef<HandleClose | undefined>(undefined)

  const handleMount = useCallback<OnMount>(instance => {
    close.current = get(instance, 'handleClose')
  }, [])

  const handleClose = useCallback(
    (success = false) => {
      close.current?.()
      onClose?.(success)
    },
    [onClose],
  )

  return [
    {
      ...rest,
      onClose: handleClose,
      onMount: handleMount,
    },
  ]
}
