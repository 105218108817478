import styled, { css } from 'styled-components'
import { mapToTheme } from 'styled-map'
import { layout, LayoutProps, space, SpaceProps } from 'styled-system'
import { themeGet } from '@styled-system/theme-get'

import { customScrollbar } from 'Theme/styles'

export interface ICapitalizeCssProps {
  capitalize?: boolean
}

const capitalizeCss = ({ capitalize }: ICapitalizeCssProps) =>
  capitalize &&
  css`
    text-transform: capitalize;
  `

export interface ILabelProps extends ICapitalizeCssProps {}

export const Label = styled.div<ILabelProps>`
  color: ${themeGet('colors.main')};
  transition: all ease-in-out ${themeGet('transitionTime.default')};
  font-weight: bold;
  user-select: none;
  cursor: text;
  margin-bottom: 8px;

  ${capitalizeCss}
`

export const Close = styled.div`
  position: absolute;
  cursor: pointer;
  top: 8px;
  right: 8px;
  line-height: 0;
`

export const StyledTextArea = styled.textarea`
  position: relative;
  width: 100%;
  height: 100%;
  margin: 0;
  border: none;
  outline: none;
  appearance: none;
  background: none;
  color: ${themeGet('colors.mineShaft')};
  resize: none;
  padding: 12px 16px;

  ${customScrollbar}

  ::placeholder {
    color: ${themeGet('colors.grayBoulder')};
  }

  :disabled {
    color: ${themeGet('colors.mineShaft')};
  }
`

const labelTopCss = css`
  ${Label} {
    font-size: 12px;
    transform: translateY(-10px);
    text-transform: initial;

    > span {
      color: ${themeGet('colors.error')};
    }
  }
`

export interface IWithLabelCssProps {
  withLabel?: boolean
}

const withLabelCss = ({ withLabel }: IWithLabelCssProps) =>
  withLabel &&
  css`
    :focus-within {
      ${labelTopCss}
    }
  `

export interface IHasValueCssProps {
  hasValue?: boolean
}

const hasValueCss = ({ hasValue }: IHasValueCssProps) =>
  hasValue &&
  css`
    ${StyledTextArea} {
      margin-right: 32px;
    }
  `

export interface IHasValueAndLabelCssProps {
  hasValue?: boolean
  withLabel?: boolean
}

const hasValueAndLabelCss = ({
  hasValue,
  withLabel,
}: IHasValueAndLabelCssProps) =>
  hasValue &&
  withLabel &&
  css`
    ${labelTopCss}
  `

export interface IWithIconCssProps {
  withIcon?: boolean
}

const withIconCss = ({ withIcon }: IWithIconCssProps) =>
  withIcon &&
  css`
    > svg:first-of-type {
      margin-left: 16px;
      margin-right: 0;
      color: ${themeGet('colors.alto')};
      width: 20px;
      height: 20px;
    }
  `

export interface IWithErrorCssProps {
  withError?: boolean
}

const withErrorCss = ({ withError }: IWithErrorCssProps) =>
  withError &&
  css`
    > svg:first-of-type {
      color: ${themeGet('colors.error')};
    }
  `

export interface IFullWidthCssProps {
  fullWidth?: boolean
}

const fullWidthCss = ({ fullWidth }: IFullWidthCssProps) =>
  fullWidth &&
  css`
    width: 100%;
  `

export interface IRoundedCssProps {
  rounded?: boolean
}

const roundedCss = ({ rounded }: IRoundedCssProps) =>
  rounded &&
  css`
    border-radius: 100px;
  `

export interface IContainerProps
  extends IWithLabelCssProps,
    IWithIconCssProps,
    IWithErrorCssProps,
    IHasValueCssProps,
    IHasValueAndLabelCssProps,
    IFullWidthCssProps,
    IRoundedCssProps,
    SpaceProps,
    LayoutProps {}

export const Container = styled.div<IContainerProps>`
  display: flex;
  position: relative;
  align-items: center;
  height: 100%;
  background-color: ${mapToTheme('inputs.background')};
  border: solid 1px ${mapToTheme('inputs.border')};
  border-radius: 6px;
  outline: none;
  appearance: none;
  transition: all ease-in-out ${themeGet('transitionTime.default')};
  overflow: hidden;

  :focus-within {
    border-color: ${mapToTheme('inputs.focus.border')};
  }

  ${withLabelCss}
  ${withIconCss}
  ${withErrorCss}
  ${hasValueCss}
  ${hasValueAndLabelCss}
  ${fullWidthCss}
  ${roundedCss}

  ${space}
  ${layout}
`
