import { down } from 'styled-breakpoints'
import styled from 'styled-components'
import { themeGet } from '@styled-system/theme-get'

import { resetButton } from 'Components/Styles'

import { customScrollbar } from 'Theme/styles'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow: hidden;
`

export const ScrollContainer = styled.div`
  display: flex;
  flex-grow: 1;
  overflow-y: auto;
  padding: 0;

  .table {
    width: 100%;
    ${down('md')} {
      min-width: unset !important;
    }
  }

  .header {
    display: none;
  }

  .table-row {
    padding: 0;
  }

  ${customScrollbar};
`

export const Email = styled.div`
  cursor: pointer;
  display: flex;
  flex-shrink: 0;
  align-items: center;

  svg {
    display: none;
    margin-left: 2px;
  }

  :hover {
    color: ${themeGet('colors.hover')};
    text-decoration: underline;

    svg {
      display: block;
    }
  }
`

export const HeaderRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${themeGet('space.4')}px ${themeGet('space.5')}px;
`

export const MarkAllReadButton = styled.button`
  ${resetButton};

  display: flex;
  align-items: center;
  cursor: pointer;
  color: ${themeGet('colors.text.header')};
`
