import styled, { css } from 'styled-components'
import { height, HeightProps } from 'styled-system'
import { themeGet } from '@styled-system/theme-get'

export const StyledTable = styled.div`
  width: 100%;
  border-radius: 8px;
  padding: 0 18px;
`

export interface ICellInfoCssProps {
  info?: boolean
}

const cellInfoCss = ({ info }: ICellInfoCssProps) =>
  info &&
  css`
    width: 100%;
    padding: 8px 0;
  `

export interface ICellRightCssProps {
  right?: boolean
}

const cellRightCss = ({ right }: ICellRightCssProps) =>
  right &&
  css`
    justify-content: flex-end;
  `

export interface ICellCenterCssProps {
  center?: boolean
}

const cellCenterCss = ({ center }: ICellCenterCssProps) =>
  center &&
  css`
    justify-content: center;
  `

export interface IHeaderContentOffsetCssProps {
  contentOffset?: number
}

const headerContentOffsetCss = ({
  contentOffset,
}: IHeaderContentOffsetCssProps) =>
  contentOffset &&
  css`
    margin-bottom: ${contentOffset}px;
  `

export interface IHeaderProps extends IHeaderContentOffsetCssProps {}

export const Header = styled.div<IHeaderProps>`
  display: flex;
  align-items: center;

  ${headerContentOffsetCss}
`

export interface IHeaderCellProps extends ICellRightCssProps {}

export const HeaderCell = styled.div<IHeaderCellProps>`
  display: flex;
  align-items: center;
  font-weight: 700;
  user-select: none;

  ${cellRightCss}
`

export interface ITableRowProps extends HeightProps {}

export const TableRow = styled.div<ITableRowProps>`
  display: flex;
  align-items: center;
  padding: 12px 0;

  border-bottom: 1px solid ${themeGet('colors.alto')};

  ${height}
`

export interface IRowCellProps
  extends ICellInfoCssProps,
    ICellRightCssProps,
    ICellCenterCssProps {}

export const RowCell = styled.div<IRowCellProps>`
  display: flex;
  align-items: center;

  ${cellInfoCss}
  ${cellRightCss}
  ${cellCenterCss}
`

export const Body = styled.div``
