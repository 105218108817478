import React from 'react'

import Link, { ILink } from './Link'

export interface IConditionalLink extends ILink {
  condition: boolean
}

const ConditionalLink = ({
  condition,
  children,
  ...props
}: IConditionalLink) => {
  return condition ? <Link {...props}>{children}</Link> : <>{children}</>
}

export default ConditionalLink
