import { Index, Items, Link } from 'regraph'

import graphUtils, {
  AnalyzerFunction,
  analyzerFunctions,
  ICommunityNode,
  IItemData,
} from 'Components/Blocks/Graph/utils'

import { ItemType } from 'Constants/graph'

import { IGraphState, LoadGraphState } from 'Hooks/useGraphContext'

import {
  CURRENT_SAVED_GRAPH_STATE_VERSION,
  GraphSnapshotStateVersion,
} from './constants'

interface GraphSnapshotState {
  version: GraphSnapshotStateVersion
  json: string
}

interface GraphSnapshotV1CombineOptions {
  properties: (string | number)[]
  level?: number
}

export enum GraphSnapshotV1Layout {
  Organic = 'organic',
  Sequential = 'sequential',
  Structural = 'structural',
  Radial = 'radial',
  Lens = 'lens',
}

interface GraphSnapshotV1LayoutOptions {
  name: GraphSnapshotV1Layout
  top?: string | string[]
  curvedLinks?: boolean
  tightness?: number
}

interface GraphSnapshotV1Item {
  id: string
  type: string
  data: any
  cluster?: string
  clusterType?: string
  clusterContainer?: string
}

interface GraphSnapshotV1Items {
  [id: string]: GraphSnapshotV1Item
}

interface GraphSnapshotV1Position {
  x: number
  y: number
}

interface GraphSnapshotV1Positions {
  [id: string]: GraphSnapshotV1Position
}

interface GraphSnapshotV1State {
  clusteringEnabled: boolean
  showRelationshipStrength: Record<string, boolean>
  analyzerFunction: string | null
  combine?: GraphSnapshotV1CombineOptions
  items?: GraphSnapshotV1Items
  layout?: GraphSnapshotV1LayoutOptions
  positions?: GraphSnapshotV1Positions
  selection?: string[]
  openCombos?: Record<string, boolean>
}

const generateGraphSnapshotStateFromGraphState = (
  graphState: IGraphState,
): GraphSnapshotState => {
  let state: GraphSnapshotV1State | null = null

  if (CURRENT_SAVED_GRAPH_STATE_VERSION === GraphSnapshotStateVersion.V1) {
    const v1State: GraphSnapshotV1State = {
      clusteringEnabled: graphState.clusteringEnabled,
      showRelationshipStrength: graphState.showRelationshipStrength,
      analyzerFunction: graphState.analyzerFunction,
      combine: graphState.combine
        ? {
            properties: graphState.combine.properties,
            level: graphState.combine.level,
          }
        : undefined,
      items: Object.keys(graphState.items).reduce((items, id) => {
        const item = graphState.items[id]

        return {
          ...items,
          [id]: {
            id: item.data?.id,
            type: item.data?.type,
            data: item.data?.data,
            cluster: item.data?.cluster,
            clusterType: item.data?.clusterType,
            clusterContainer: item.data?.clusterContainer,
          },
        }
      }, {}),
      layout: graphState.layout
        ? ({
            name: graphState.layout.name
              ? (graphState.layout.name as GraphSnapshotV1Layout)
              : null,
            top: graphState.layout.top,
            curvedLinks: graphState.layout.curvedLinks,
            tightness: graphState.layout.tightness,
          } as GraphSnapshotV1LayoutOptions)
        : undefined,
      positions: graphState.positions
        ? Object.keys(graphState.positions).reduce((positions, id) => {
            const position = graphState.positions![id]

            return {
              ...positions,
              [id]: {
                x: position.x,
                y: position.y,
              },
            }
          }, {})
        : undefined,
      selection: graphState.selection
        ? Object.keys(graphState.selection)
        : undefined,
      openCombos: graphState.openCombos,
    }

    state = v1State
  }

  if (!state) {
    throw new Error(
      `The current saved graph state ${CURRENT_SAVED_GRAPH_STATE_VERSION} is not supported`,
    )
  }

  return {
    version: CURRENT_SAVED_GRAPH_STATE_VERSION,
    json: JSON.stringify(state),
  }
}

const generateLoadGraphStateFromGraphSnapshotState = async (
  communityId: MainSchema.Community['id'],
  graphSnapshotState: GraphSnapshotState,
  currentUserId: string,
): Promise<LoadGraphState> => {
  try {
    const parsedJson: object = JSON.parse(graphSnapshotState.json)

    if (graphSnapshotState.version === GraphSnapshotStateVersion.V1) {
      const graphState = parsedJson as GraphSnapshotV1State

      const items = graphState.items ?? {}
      const nodes: Items<IItemData<any, any>> = {}
      const edges: Index<Link<IItemData>> = {}
      const selection = graphState.selection ?? []

      const userIds: string[] = []
      const communities: ICommunityNode[] = []
      Object.values(items).forEach(item => {
        if (item.type === ItemType.User) {
          userIds.push(item.data.id)
        }
        if (item.type === ItemType.Community) {
          communities.push(item.data)
        }
      })

      for (const [id, item] of Object.entries(items)) {
        if (item.type === ItemType.User) {
          nodes[id] = graphUtils.createUserNode({
            user: item.data,
            isMe: item.data.id === currentUserId,
            isSelected: selection.includes(item.id),
          })
        }

        if (item.type === ItemType.SkillTag) {
          nodes[id] = graphUtils.createSkillTagNode({
            skillTagData: item.data,
            // TODO: populate the skill tag entity
            // skillTag: ,
            isSelected: selection.includes(item.id),
          })
        }

        if (item.type === ItemType.Organization) {
          nodes[id] = graphUtils.createOrganizationNode({
            organizationData: item.data,
            // TODO: populate the organization entity
            // organization: ,
            isSelected: selection.includes(item.id),
          })
        }

        if (item.type === ItemType.Community) {
          nodes[id] = graphUtils.createCommunityNode({
            community: item.data,
            isSelected: selection.includes(item.id),
          })
        }

        if (item.type === ItemType.Edge) {
          edges[id] = graphUtils.createEdge({
            edgeData: item.data,
          })
        }

        if (item.type === ItemType.RelationshipStrengthEdge) {
          edges[id] = graphUtils.createRelationshipStrengthEdge({
            relationshipStrengthEdgeData: item.data,
          })
        }
      }

      const loadGraphState: LoadGraphState = {
        clusteringEnabled: graphState.clusteringEnabled ?? false,
        showRelationshipStrength: graphState.showRelationshipStrength ?? {},
        analyzerFunction:
          graphState.analyzerFunction &&
          analyzerFunctions[graphState.analyzerFunction]
            ? (graphState.analyzerFunction as AnalyzerFunction)
            : null,
        nodes,
        edges,
        layout: graphState.layout
          ? {
              name: graphState.layout.name,
              top: graphState.layout.top,
              curvedLinks: graphState.layout.curvedLinks,
              tightness: graphState.layout.tightness,
            }
          : undefined,
        positions: graphState.positions,
        selection: graphState.selection?.reduce(
          (selection, id) => ({
            ...selection,
            [id]: true,
          }),
          {},
        ),
        combine: graphState.combine,
        openCombos: graphState.openCombos,
      }

      return loadGraphState
    }
  } catch {
    throw new Error('The saved graph state contains invalid json')
  }

  throw new Error(
    `The saved graph state ${graphSnapshotState.version} is not supported`,
  )
}

const utils = {
  generateGraphSnapshotStateFromGraphState,
  generateLoadGraphStateFromGraphSnapshotState,
}

export default utils
