import styled from 'styled-components'

import { resetButton } from 'Components/Styles'

import colors from 'Theme/_v2/colors'

export const ActionButton = styled.button`
  ${resetButton}

  line-height: 0;
  color: ${colors.button.action.enabled};

  :enabled {
    cursor: pointer;
  }

  :enabled:hover {
    color: ${colors.button.action.hover};
  }

  :enabled:focus,
  :enabled:active {
    color: ${colors.button.action.focus};
  }

  :disabled {
    color: ${colors.button.action.disabled}d;
  }
`
