import React from 'react'

import { QUICK_ACTION_KINDS_MAP } from 'Components/Blocks/QuickActions/constants'
import { ContextMenu, ContextMenuItem } from 'Components/UI'

import { QUICK_ACTION_KIND } from 'Constants/graph'

import _ from 'Services/I18n'

import { CONTEXT_GRAPH_FIELD } from '../constants'

export interface IGraphContext {
  isOpen?: boolean
  left?: number
  top?: number
  onRemoveSelected: () => void
  onSelectedItems: () => void
}

function GraphContext({
  isOpen = false,
  left = 0,
  top = 0,
  onSelectedItems,
  onRemoveSelected,
}: IGraphContext) {
  return (
    <ContextMenu isOpen={isOpen} left={left} top={top}>
      <ContextMenuItem
        id={CONTEXT_GRAPH_FIELD.SELECTED_ITEMS}
        label={_(
          QUICK_ACTION_KINDS_MAP[QUICK_ACTION_KIND.VIEW_SELECTED_ITEMS].label,
        )}
        onClick={onSelectedItems}
      />
      <ContextMenuItem
        id={CONTEXT_GRAPH_FIELD.REMOVE_SELECTED}
        label={_(QUICK_ACTION_KINDS_MAP[QUICK_ACTION_KIND.HIDE].label)}
        onClick={onRemoveSelected}
      />
    </ContextMenu>
  )
}

export default GraphContext
