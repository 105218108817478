import { Draggable, DraggableProps } from 'react-drag-and-drop'

import styled, { css } from 'styled-components'
import { themeGet } from '@styled-system/theme-get'

const wrapperSelectedCss = ({ selected }: { selected?: boolean }) =>
  selected &&
  css`
    border: 1px solid ${themeGet('colors.divider.hover')};
  `

export interface IWrapper extends DraggableProps {
  selected?: boolean
}

export const Wrapper = styled(Draggable)<IWrapper>`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 4px;
  padding: ${themeGet('space.3')}px;
  border: 1px solid ${themeGet('colors.divider.default')};
  cursor: pointer;
  width: 100%;

  ${wrapperSelectedCss}
`

export const StyledButton = styled.button`
  display: flex;
  align-items: center;
  background: none;
  border: none;
  outline: none;
  padding: 0;
  cursor: pointer;
`
