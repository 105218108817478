import { useMemo } from 'react'

import { AddedAtCell } from './Cells'

export function useColumns() {
  return useMemo(
    () => [
      {
        Header: `Full name`,
        Cell: renderFullName,
        width: 1.5,
      },
      {
        Header: 'Added at',
        Cell: AddedAtCell,
        width: 1,
      },
    ],
    [],
  )
}

const renderFullName = ({ row }) =>
  `${row?.original?.firstName || '—'} ${row?.original?.lastName || '—'}`
