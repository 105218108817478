import React, { useCallback, useMemo } from 'react'
import { Form } from 'react-final-form'
import PropTypes from 'prop-types'

import { useMutation, useQuery } from '@apollo/client'
import updateCommunityUsersRoleMutation from 'GraphQL/Mutations/Community/updateCommunityUsersRole.graphql'
import communityRolesListQuery from 'GraphQL/Queries/Community/communityRolesList.graphql'
import { getFullName } from 'Utils/User'
import validate from 'validate.js'

import map from 'lodash/map'

import { Button, Column, Modal, Row, SelectField, Text } from 'Components/UI'
import DropdownIndicator from 'Components/UI/Form/Select/Components/DropdownIndicator'

import { useCommunityContext } from 'Hooks'

import { useScopedI18n } from 'Services/I18n'
import toast from 'Services/Toast'

import { Content, FormContent } from './styles'

const FIELD = {
  ROLE: 'role',
}

function entityToOption(entity) {
  return {
    label: entity.name,
    value: entity.id,
  }
}

function validateForm(values) {
  return validate(values, {
    [FIELD.ROLE]: {
      presence: true,
    },
  })
}

function UpdateCommunityUsersRole({
  content,
  users,
  communityId,
  onClose,
  ...rest
}) {
  const s = useScopedI18n('modals.updateCommunityUsersRole')
  const { community } = useCommunityContext()

  const { data } = useQuery(communityRolesListQuery, {
    variables: {
      communityId: community?.id,
      limit: 99,
    },
    skip: !community?.id,
  })

  const [updateCommunityUsersRole] = useMutation(
    updateCommunityUsersRoleMutation,
  )

  const handleFinish = useCallback(
    async success => {
      onClose(success)
    },
    [onClose],
  )

  const handleCancel = useCallback(() => handleFinish(false), [handleFinish])

  const handleUpdate = useCallback(
    async values => {
      try {
        await updateCommunityUsersRole({
          variables: {
            communityId,
            userIds: map(users, 'userId'),
            communityRoleId: values[FIELD.ROLE].value,
          },
        })
        toast.success({
          title: s('title'),
          text: s('updateSuccess'),
        })
        await handleFinish(true)
      } catch (error) {
        toast.error({
          title: s('title'),
          text: error.message,
        })
        await handleFinish(false)
      }
    },
    [users, communityId, handleFinish, updateCommunityUsersRole, s],
  )

  const roles = data?.communityRolesList?.rows
  const options = useMemo(() => map(roles, entityToOption), [roles])

  return (
    <Modal title={'Update users role'} onClose={onClose} {...rest}>
      <Content>
        <Text fontSize="16px" fontWeight={500}>
          {s('content')}{' '}
          <span>{users.map(item => `${getFullName(item)}`).join(', ')}</span>?
        </Text>

        <Form
          render={({ handleSubmit, invalid }) => (
            <FormContent mt={5} onSubmit={handleSubmit}>
              <Column fullWidth mr={24}>
                <Text bold mb={2}>
                  {s('form.label')}
                </Text>
                <SelectField
                  blurInputOnSelect
                  components={{
                    DropdownIndicator,
                  }}
                  fullWidth
                  name={FIELD.ROLE}
                  options={options}
                  placeholder={s('form.placeholder')}
                  withPortal
                />
              </Column>

              <Row fullWidth mt={5} spaceBetween>
                <Button big bold secondary width="100px" onClick={handleCancel}>
                  {s('actions.cancel')}
                </Button>

                <Button
                  big
                  bold
                  disabled={invalid}
                  width="100px"
                  onClick={handleSubmit}
                >
                  {s('actions.ok')}
                </Button>
              </Row>
            </FormContent>
          )}
          validate={validateForm}
          onSubmit={handleUpdate}
        />
      </Content>
    </Modal>
  )
}

UpdateCommunityUsersRole.defaultProps = {
  communityId: null,
  users: [],
}

UpdateCommunityUsersRole.propTypes = {
  ...Modal.propTypes,
  communityId: PropTypes.string,
  users: PropTypes.arrayOf(PropTypes.object),
}

export default UpdateCommunityUsersRole
