import React from 'react'
import PropTypes from 'prop-types'

import { pick } from '@styled-system/props'

import { RelationshipStrengthField } from 'Components/Blocks/Forms/Fields'
import { Column, ImageInputField, InputField, Row } from 'Components/UI'

import _ from 'Services/I18n'

import { FIELD } from '../fields'
import { TabContainer } from '../styles'

function GeneralTab({ visible, canEditEmail, canEditPhone, ...rest }) {
  return (
    <TabContainer {...pick(rest)} visible={visible}>
      <Row fullWidth gap={5} mb={5}>
        <Column fullWidth gap={4} minWidth={'300px'}>
          <ImageInputField name={FIELD.PHOTO_URL} />
          <Column fullWidth gap={4}>
            <InputField
              label={_('auth.shared.firstName')}
              name={FIELD.FIRST_NAME}
              placeholder="First name"
              required
            />

            <InputField
              label={_('auth.shared.lastName')}
              name={FIELD.LAST_NAME}
              placeholder="Last name"
              required
            />

            <InputField
              disabled={!canEditEmail}
              label={_('auth.shared.email')}
              name={FIELD.EMAIL}
              placeholder="Email"
            />

            {canEditPhone && (
              <InputField
                disabled={!canEditPhone}
                label={_('auth.shared.phone')}
                name={FIELD.PHONE_NUMBER}
                phoneNumberFormat
                placeholder="Phone number"
              />
            )}
          </Column>
        </Column>

        <Column fullWidth gap={4} minWidth={'300px'}>
          <InputField
            label={_('auth.shared.linkedIn')}
            name={FIELD.LINKED_IN}
            placeholder="LinkedIn"
          />

          <InputField
            label={_('auth.shared.twitterUrl')}
            name={FIELD.TWITTER_URL}
            placeholder="Twitter"
          />

          <InputField
            label={_('auth.shared.facebookUrl')}
            name={FIELD.FACEBOOK_URL}
            placeholder="Facebook"
          />

          <InputField
            label={_('auth.shared.job')}
            name={FIELD.JOB}
            placeholder="Job"
          />

          <InputField
            label={_('auth.shared.organization')}
            name={FIELD.ORGANIZATION}
            placeholder="Company"
          />
        </Column>
      </Row>
      <Row fullWidth gap={5} mb={5}>
        <Column fullWidth gap={4} minWidth={'300px'}>
          <RelationshipStrengthField name={FIELD.RELATIONSHIP} />
        </Column>
      </Row>
    </TabContainer>
  )
}

GeneralTab.defaultProps = {
  canEditEmail: false,
  canEditPhone: false,
  visible: true,
}

GeneralTab.propTypes = {
  canEditEmail: PropTypes.bool,
  canEditPhone: PropTypes.bool,
  visible: PropTypes.bool,
}

export default GeneralTab
