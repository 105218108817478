import React, { ReactNode, useState } from 'react'

import { PiWarningCircleFill } from 'react-icons/pi'

import { IconX } from '@tabler/icons-react'

import { Box, Column, Loader, Row, Tooltip } from 'Components/UI'
import { Link, Text } from 'Components/UI/_v2'
import { ActionButton } from 'Components/UI/Button'

import colors from 'Theme/_v2/colors'

import Container from '../Container'

export interface IItemAction {
  label: string
  onPress: () => Promise<void> | void
}

export interface IItem {
  label: ReactNode
  remove: IItemAction
  action?: IItemAction
  description?: ReactNode
  error?: ReactNode
}

const Item = (props: IItem) => {
  const [isRemoveLoading, setIsRemoveLoading] = useState(false)
  const [isActionLoading, setIsActionLoading] = useState(false)

  const borderColor = props.error
    ? colors.input.border.error
    : colors.input.border.enabled

  const handlePressRemove = async () => {
    setIsRemoveLoading(true)
    await props.remove.onPress()
    setIsRemoveLoading(false)
  }

  const handlePressAction = async () => {
    setIsActionLoading(true)
    await props.action?.onPress()
    setIsActionLoading(false)
  }

  return (
    <Column fullWidth gap={2}>
      <Row gap={3}>
        <Container>
          <Box
            backgroundColor={colors.input.background}
            borderColor={borderColor}
            borderRadius={6}
            borderStyle="solid"
            borderWidth={1}
            px={3}
            py={2}
          >
            <Row center gap={3} spaceBetween>
              <Text color={colors.input.value} variant="body-s">
                {props.label}
              </Text>

              {isRemoveLoading && <Loader />}
              {!isRemoveLoading && (
                <Tooltip content={props.remove.label} placement="bottom">
                  <ActionButton
                    title={props.remove.label}
                    type="submit"
                    onClick={handlePressRemove}
                  >
                    <IconX size={16} />
                  </ActionButton>
                </Tooltip>
              )}
            </Row>
          </Box>
        </Container>

        {!!props.action && (
          <>
            {isActionLoading && <Loader />}
            {!isActionLoading && (
              <Link onClick={handlePressAction}>
                <Text fontWeight={600} variant="body-xs">
                  {props.action.label}
                </Text>
              </Link>
            )}
          </>
        )}
      </Row>

      {(!!props.description || props.error) && (
        <Row>
          <Container>
            {!!props.description && !props.error && (
              <Row>
                <Text color={colors.input.message} variant="body-xs">
                  {props.description}
                </Text>
              </Row>
            )}

            {!!props.error && (
              <Row center gap={1}>
                <PiWarningCircleFill color={colors.input.error} size={16} />
                <Text color={colors.input.message} variant="body-xs">
                  {props.error}
                </Text>
              </Row>
            )}
          </Container>
        </Row>
      )}

      {!!props.action && (
        <Row>
          {isActionLoading && <Loader />}
          {!isActionLoading && (
            <Link onClick={handlePressAction}>
              <Text fontWeight={600} variant="body-xs">
                {props.action.label}
              </Text>
            </Link>
          )}
        </Row>
      )}
    </Column>
  )
}

export default Item
