import styled from 'styled-components'
import { mapToTheme } from 'styled-map'
import { layout, LayoutProps, space, SpaceProps } from 'styled-system'
import { themeGet } from '@styled-system/theme-get'

export const StyledRadioButton = styled.div`
  border-style: solid;
  border-radius: ${mapToTheme('radioButtons.borderRadius')}px;
  width: ${mapToTheme('radioButtons.size')}px;
  height: ${mapToTheme('radioButtons.size')}px;
  flex-shrink: 0;
`

export const InvisibleRadio = styled.input.attrs({ type: 'radio' })`
  position: absolute;
  background: none;
  border: none;
  opacity: 0;
  pointer-events: none;
`

export interface IContainerProps extends SpaceProps, LayoutProps {}

export const Container = styled.label<IContainerProps>`
  display: flex;
  align-items: center;
  position: relative;
  user-select: none;
  gap: ${themeGet('space.2')}px;
  width: max-content;

  & > input {
    :not(:checked) ~ ${StyledRadioButton} {
      border-width: ${themeGet('radioButtons.borderWidth.unselected')}px;
    }

    :enabled ~ ${StyledRadioButton} {
      cursor: pointer;
      border-color: ${themeGet('colors.radioButton.border')};
      background-color: ${themeGet('colors.radioButton.bg')};
    }

    :checked ~ ${StyledRadioButton} {
      border-width: ${themeGet('radioButtons.borderWidth.selected')}px;
    }

    :enabled:checked ~ ${StyledRadioButton} {
      border-color: ${themeGet('colors.radioButton.selected.border')};
    }

    :enabled:hover ~ ${StyledRadioButton} {
      border-color: ${themeGet('colors.radioButton.hover.border')};
    }

    :enabled:focus ~ ${StyledRadioButton} {
      border-color: ${themeGet('colors.radioButton.focus.border')};
      box-shadow: ${themeGet('colors.radioButton.focus.shadow')};
    }

    :disabled ~ ${StyledRadioButton} {
      cursor: default;
      background-color: ${themeGet('colors.radioButton.disabled.bg')};
      border-color: ${themeGet('colors.radioButton.disabled.border')};
    }

    :disabled:checked ~ ${StyledRadioButton} {
      background-color: ${themeGet('colors.radioButton.selected.disabled.bg')};
    }
  }

  ${space}
  ${layout}
`
