import React, { useCallback } from 'react'

import capitalize from 'lodash/capitalize'

import { Button, Column, Row, Text } from 'Components/UI'

import { TOAST_KIND } from 'Constants/toasts'

import toast from 'Services/Toast'

function Toasts() {
  const handleClick = useCallback((kind: string) => {
    switch (kind) {
      case TOAST_KIND.SUCCESS: {
        toast.success({
          title: 'Success',
          text: 'Message Content',
        })
        break
      }
      case TOAST_KIND.INFO: {
        toast.info({
          title: 'Info',
          text: 'Message Content',
        })
        break
      }
      case TOAST_KIND.WARNING: {
        toast.warning({
          title: 'Warning',
          text: 'Message Content',
        })
        break
      }
      default: {
        toast.error({
          title: 'Error',
          text: 'Message Content',
        })
        break
      }
    }
  }, [])

  return (
    <Column gap={6}>
      <Text header1>Toasts</Text>
      <Row fullWidth gap={5}>
        <Button onClick={() => handleClick(TOAST_KIND.SUCCESS)}>
          {capitalize(TOAST_KIND.SUCCESS)}
        </Button>
        <Button onClick={() => handleClick(TOAST_KIND.INFO)}>
          {capitalize(TOAST_KIND.INFO)}
        </Button>
        <Button onClick={() => handleClick(TOAST_KIND.WARNING)}>
          {capitalize(TOAST_KIND.WARNING)}
        </Button>
        <Button onClick={() => handleClick(TOAST_KIND.ERROR)}>
          {capitalize(TOAST_KIND.ERROR)}
        </Button>
      </Row>
    </Column>
  )
}

export default Toasts
