import { IGraphPersonNode } from 'Features/GraphNodes/NodeTypes'
import { createKnowledgeNode } from 'Features/KnowledgeGraphing/Graph/createKnowledgeNode'
import {
  Chart,
  Glyph,
  GraphItem,
  Index,
  ItemOrCombo,
  Items,
  Link,
  Node,
} from 'regraph'
import {
  betweenness,
  closeness,
  degrees,
  eigenCentrality,
  pageRank,
} from 'regraph/analysis'
import { getOrganizationLogoUrl } from 'Utils/Organization'
import { resizeImageUrl } from 'Utils/Strings'
import { getUserLabel } from 'Utils/User'

import cloneDeep from 'lodash/cloneDeep'
import concat from 'lodash/concat'
import forEach from 'lodash/forEach'
import omit from 'lodash/omit'

import { S3_URLS } from 'Config'

import {
  COMBO_NAME,
  ItemType,
  NODE_KIND,
  NODE_NAME,
  NodeKind,
  QuickActionKind,
  SkillTagKind,
} from 'Constants/graph'
import {
  RELATIONSHIP_STRENGTH_ENUM,
  USER_RELATIONSHIP_STRENGTH,
  UserRelationshipStrength,
} from 'Constants/ids'
import { KnowledgeGraphNodeKind } from 'Constants/mainGraphQL'

import { IAvailableQuickAction } from 'Hooks/useGraphContext'

import _ from 'Services/I18n'

import { theme } from 'Theme'

import { IQuickActionKind } from '../QuickActions/constants'
import { IActionHandler } from '../QuickActions/useActionHandlers'

const DEFAULT_NODE_SIZE = 50

export interface ISkillNode {
  id: string
  name?: string | null
}

export interface ITagNode {
  id: string
  name: string
  kind: SkillTagKind
}

export interface ICommunityNode {
  id: string
  name: string
  photoUrl?: string
}

export interface IOrganizationNode {
  id: string
  name?: string
  logos?: {
    url: string
    width: number
    height: number
  }[]
  linkedInUrl?: string
  twitterUrl?: string
  facebookUrl?: string
  websiteUrl?: string
  description?: string
  location?: {
    address1?: string
    address2?: string
    locality?: string
    region?: string
    postalCode?: string
    country?: string
  }
}

export interface IKnowledgeNode {
  id: string
  valueString?: string
  kind: string
}

export interface IAreaOfExperienceNode {
  id: string
  name: string
  skills: ISkillNode[]
}

export interface IQuickAction {
  kind: QuickActionKind
  label: string
  icon: React.ReactNode
  handleClick: () => void
}

export interface IRelationshipStrengthEdgeData {
  fromId: string
  toId: string
  strength: number
  fade?: boolean
}

export interface IItemData<TData = any, TEntity = any> {
  id: string
  type: ItemType
  // TODO: better name?
  data: TData
  entity?: TEntity
  // TODO: keep in sync with COMBO_PROPERTIES
  cluster?: string
  clusterType?: string
  clusterContainer?: string
  glyphs?: Glyph[]
}

export interface ISkillTagNodeData {
  id: string
  name: string
  kind: SkillTagKind
}

export interface IEdgeData {
  fromId: string
  toId: string
  kind?: NodeKind | string
  fade?: boolean
}

export type AnalyzerFunction = keyof typeof analyzerFunctions

// TODO: keep in sync with IItemData properties
export const COMBO_PROPERTIES = ['cluster', 'clusterType', 'clusterContainer']

export const analyzerFunctions: Record<string, CallableFunction> = {
  betweenness,
  closeness,
  degrees,
  eigenCentrality,
  pageRank,
}

const RELATIONSHIP_STRENGTH_EDGE_COLORS: Record<string, string> = {
  [RELATIONSHIP_STRENGTH_ENUM.dontKnow]: '#CECECE',
  [RELATIONSHIP_STRENGTH_ENUM.weak]: '#A9A9A9',
  [RELATIONSHIP_STRENGTH_ENUM.moderate]: '#787878',
  [RELATIONSHIP_STRENGTH_ENUM.strong]: '#2D2D2D',
}

const RELATIONSHIP_STRENGTH_EDGE_GLYPH_COLORS: Record<string, string> = {
  [RELATIONSHIP_STRENGTH_ENUM.dontKnow]: '#D2D2D3',
  [RELATIONSHIP_STRENGTH_ENUM.weak]: '#B1B1B1',
  [RELATIONSHIP_STRENGTH_ENUM.moderate]: '#858585',
  [RELATIONSHIP_STRENGTH_ENUM.strong]: '#2B2B2B',
}

/* *** PRIVATE HELPERS *** */
function createEdgeId(id1: string, id2: string) {
  return `edge:${id1}:${id2}`
}

function replaceImage(photoUrl: string) {
  let nextUrl = photoUrl

  forEach(S3_URLS, (value, key) => {
    nextUrl = nextUrl.replace(key, value)
  })

  return resizeImageUrl(nextUrl, '512')
}

/* *** CORE *** */

const getNodeSize = (node: Node) => {
  return node.size ?? 1
}

const isItemDataNode = (node: ItemOrCombo<IItemData>) => {
  return 'data' in node
}

const isUserNode = (node: Node<IItemData>) => {
  return isItemDataNode(node) && node.data!.type === ItemType.User
}

const getAsUserNode = (node: Node<IItemData>) => {
  return node as Node<IItemData<IGraphPersonNode, IGraphPersonNode>>
}

const isGlyphOfType = (
  node: ItemOrCombo<IItemData> | null,
  subItem: Chart.SubItem | null,
  glyphImage: string,
) => {
  if (!node || !subItem || subItem.type !== 'glyph') return false
  if (!('data' in node) || !isUserNode(node) || !node.data.data) return false

  const glyphIndex = node.glyphs?.findIndex(glyph => glyph.image === glyphImage)
  return glyphIndex !== -1 && subItem.index === glyphIndex
}

const isUserQuickConnectGlyph = (
  node: ItemOrCombo<IItemData> | null,
  subItem: Chart.SubItem | null,
) => isGlyphOfType(node, subItem, '/img/quick-connector.svg')

const isAskOfferGlyph = (
  node: ItemOrCombo<IItemData> | null,
  subItem: Chart.SubItem | null,
) => isGlyphOfType(node, subItem, '/img/ask-glyph.svg')

function createUserNode({
  user,
  isMe,
  isSelected = false,
  fade = false,
}: {
  user: IGraphPersonNode
  isMe?: boolean
  isSelected?: boolean
  fade?: boolean
}): Node<IItemData<IGraphPersonNode>> {
  const text = getUserLabel(user)
  const glyphs: Glyph[] = []
  const { askOfferStatements } = user
  const hasAsk =
    askOfferStatements &&
    askOfferStatements.filter(ask => ask.kind === 'Ask').length > 0

  // TODO: use quick actions to determine
  if (isSelected && user) {
    glyphs.push({
      image: '/img/quick-connector.svg',
      radius: 29,
      angle: 180,
      size: 1,
    })
  }

  if (hasAsk && user) {
    glyphs.push({
      image: '/img/ask-glyph.svg',
      radius: 28,
      angle: 45,
      size: 1,
      color: '#f8f9fc',
    })
  }

  // If the user entity is not present we assume the user no longer exists.
  // This adds a visual indicator that the user is no longer valid
  if (!user) {
    glyphs.push({
      image: '/img/invalid.svg',
      radius: 44,
      angle: 0,
      size: 1,
    })
  }

  const userImagePath = isMe ? '/img/nodes/user.svg' : '/img/nodes/contact.svg'
  const nodeSize = isMe ? 1.5 : 1

  return {
    color: isMe ? '#CD1C45' : '#BBBFC2',
    shape: 'circle',
    cutout: true,
    size: isSelected ? 1.75 : nodeSize,
    data: {
      id: user.userId!,
      type: ItemType.User,
      data: {
        userId: user.userId,
        communityUserId: user.communityUserId,
        communityId: user.communityId,
        firstName: user.firstName,
        lastName: user.lastName,
        photoUrl: user.photoUrl,
        email: user.email,
        askOfferStatements: user.askOfferStatements,
        communityUsers: user.communityUsers,
      },
    },
    glyphs,
    halos: [
      {
        color: '#27af8b',
        radius: 28,
        width: isSelected ? 3 : 0,
      },
      {
        color: '#1ccda4',
        radius: 32,
        width: isSelected ? 6 : 0,
      },
      {
        color: '#5ebbe5',
        radius: 28,
        width: hasAsk ? 3 : 0,
      },
      {
        color: '#8ecfed',
        radius: 32,
        width: hasAsk && isSelected ? 6 : 0,
      },
    ],
    label: [
      {
        backgroundColor: 'transparent',
        minWidth: 52,
        minHeight: 52,
        margin: 0,
        padding: 0,
      },
      {
        backgroundColor: 'transparent',
        padding: 2,
        margin: {
          top: isSelected ? 66 : 58,
          bottom: 0,
          left: 0,
          right: 0,
        },
        color: '#2d2d2d',
        fontFamily: 'Inter',
        fontSize: 10,
        bold: isSelected,
        text,
        position: {
          horizontal: 'center',
          vertical: 'top',
        },
      },
    ],
    image: user.photoUrl ? replaceImage(user.photoUrl) : userImagePath,
    fade,
  }
}

const isSkillTagNode = (node: Node<IItemData>) => {
  return isItemDataNode(node) && node.data!.type === ItemType.SkillTag
}

const getAsSkillTagNode = (node: Node<IItemData>) => {
  return node as Node<IItemData<ISkillTagNodeData>>
}

function createSkillTagNode({
  skillTagData,
  skillTag,
  isSelected,
}: {
  skillTagData: ISkillTagNodeData
  skillTag?: ISkillNode | ITagNode
  isSelected: boolean
}): Node<IItemData<ISkillTagNodeData>> {
  const borderWidth = 1.5
  const iconSize = 30
  const borderToTextGap = 8

  return {
    data: {
      id: skillTagData.id,
      type: ItemType.SkillTag,
      data: skillTagData,
      entity: skillTag,
      cluster: `${skillTagData.kind}_${COMBO_NAME[skillTagData.kind]}`,
    },
    color: isSelected ? '#ffffff' : '#F9FAFB',
    shape: 'circle',
    size: isSelected ? 1.75 : 1,
    border: {
      color: isSelected ? nodeKindColor(skillTagData.kind) : 'transparent',
      width: borderWidth,
    },
    halos: [
      {
        color: nodeKindLightColor(skillTagData.kind),
        radius: 28,
        width: isSelected ? 8 : 0,
      },
    ],
    image: nodeImage(skillTagData.kind),
    label: [
      {
        backgroundColor: 'transparent',
        color: isSelected ? '#ffffff' : nodeKindColor(skillTagData.kind),
        fontFamily: 'GraphIcons',
        fontSize: iconSize,
        position: {
          horizontal: 'center',
          vertical: 'middle',
        },
        padding: {
          top: iconSize / 2,
        },
      },
      {
        backgroundColor: 'transparent',
        margin: {
          top: DEFAULT_NODE_SIZE + borderWidth * 2 + borderToTextGap,
          bottom: 0,
          left: 0,
          right: 0,
        },
        color: '#2D2D2D',
        fontFamily: 'Inter',
        fontSize: 14,
        bold: true,
        text: skillTagData.name,
        textWrap: 'normal',
        minWidth: 200,
        position: {
          horizontal: 'center',
          vertical: 'top',
        },
      },
      {
        backgroundColor: 'transparent',
        color: '#757575',
        fontFamily: 'Inter',
        fontSize: 12,
        text: NODE_NAME[skillTagData.kind],
        textWrap: 'normal',
        minWidth: 200,
      },
    ],
  }
}

function createAreaOfExperienceNode({
  areaOfExperienceNode,
  isSelected,
}: {
  areaOfExperienceNode: IAreaOfExperienceNode
  isSelected: boolean
}): Node<IItemData<IAreaOfExperienceNode>> {
  const borderWidth = 1.5
  const borderToTextGap = 8
  const kind = NODE_KIND.areaOfExperience

  return {
    data: {
      id: areaOfExperienceNode.id,
      type: ItemType.AreaOfExperience,
      data: areaOfExperienceNode,
      entity: areaOfExperienceNode,
      cluster: `AreasOfExperience`,
    },
    color: isSelected ? '#ffffff' : '#F9FAFB',
    shape: 'circle',
    size: isSelected ? 1 : 0.5,
    border: {
      color: isSelected ? nodeKindColor(kind) : 'transparent',
      width: borderWidth,
    },
    halos: [
      {
        color: nodeKindLightColor(kind),
        radius: 28,
        width: isSelected ? 8 : 0,
      },
    ],
    image: nodeImage(kind),
    label: [
      {
        backgroundColor: 'transparent',
        margin: {
          top: DEFAULT_NODE_SIZE + borderWidth * 2 + borderToTextGap,
          bottom: 0,
          left: 0,
          right: 0,
        },
        color: '#5D5D5E',
        fontFamily: 'Inter',
        fontSize: 14,
        bold: true,
        text: areaOfExperienceNode.name,
        textWrap: 'normal',
        minWidth: 200,
        position: {
          horizontal: 'center',
          vertical: 'top',
        },
      },
    ],
  }
}

function createCommunityNode({
  community,
  isSelected,
}: {
  community: ICommunityNode
  isSelected: boolean
}): Node<IItemData<ICommunityNode>> {
  const borderWidth = 1.5
  const iconSize = 30
  const borderToTextGap = 8

  return {
    data: {
      id: community.id,
      type: ItemType.Community,
      data: community,
    },
    color: isSelected ? '#ffffff' : '#F9FAFB',
    shape: 'circle',
    cutout: true,
    size: isSelected ? 1.75 : 1,
    border: {
      color: isSelected ? nodeKindColor(NODE_KIND.community) : 'transparent',
      width: borderWidth,
    },
    halos: [
      {
        color: nodeKindLightColor(NODE_KIND.community),
        radius: 28,
        width: isSelected ? 8 : 0,
      },
    ],
    label: [
      {
        backgroundColor: 'transparent',
        color: isSelected ? '#ffffff' : nodeKindColor(NODE_KIND.community),
        fontFamily: 'GraphIcons',
        fontSize: iconSize,
        position: {
          horizontal: 'center',
          vertical: 'middle',
        },
        padding: {
          top: iconSize / 2,
        },
      },
      {
        backgroundColor: 'transparent',
        margin: {
          top: DEFAULT_NODE_SIZE + borderWidth * 2 + borderToTextGap,
          bottom: 0,
          left: 0,
          right: 0,
        },
        color: '#2D2D2D',
        fontFamily: 'Inter',
        fontSize: 14,
        bold: true,
        text: community.name,
        textWrap: 'normal',
        minWidth: 200,
        position: {
          horizontal: 'center',
          vertical: 'top',
        },
      },
      {
        backgroundColor: 'transparent',
        color: '#757575',
        fontFamily: 'Inter',
        fontSize: 12,
        text: NODE_NAME[NODE_KIND.community],
        textWrap: 'normal',
        minWidth: 200,
      },
    ],
    image: community?.photoUrl || nodeImage(NODE_KIND.community),
  }
}

const isOrganizationNode = (node: Node<IItemData>) => {
  return isItemDataNode(node) && node.data!.type === ItemType.Organization
}

const isKnowledgeNode = (node: Node<IItemData>) => {
  return isItemDataNode(node) && node.data!.type === ItemType.Knowledge
}

const isAreaOfExperienceNode = (node: Node<IItemData>) => {
  return isItemDataNode(node) && node.data!.type === ItemType.AreaOfExperience
}

const isCommunityNode = (node: Node<IItemData>) => {
  return isItemDataNode(node) && node.data!.type === ItemType.Community
}

const getAsKnowledgeNode = (node: Node<IItemData>) => {
  return node as Node<IItemData<IKnowledgeNode>>
}

const getAsAreaOfExperienceNode = (node: Node<IItemData>) => {
  return node as Node<IItemData<IAreaOfExperienceNode>>
}

const getAsOrganizationNode = (node: Node<IItemData>) => {
  return node as Node<IItemData<IOrganizationNode>>
}

// TODO: Can this be merged with createSkillTag?
function createOrganizationNode({
  organizationData,
  organization,
  isSelected,
}: {
  organizationData: IOrganizationNode
  organization?: MainSchema.Organization
  isSelected: boolean
}): Node<IItemData<IOrganizationNode>> {
  const borderWidth = 1.5
  const iconSize = 30
  const borderToTextGap = 8
  const kind = NODE_KIND.organization

  const logoUrl = getOrganizationLogoUrl(
    organization?.logos || organizationData?.logos,
  )

  return {
    data: {
      id: organizationData.id,
      type: ItemType.Organization,
      data: organizationData,
      entity: organization,
      cluster: `${kind}_${COMBO_NAME[kind]}`,
    },
    color: isSelected ? '#ffffff' : '#F9FAFB',
    shape: 'circle',
    cutout: true,
    border: {
      color: isSelected ? nodeKindColor(kind) : 'transparent',
      width: borderWidth,
    },
    halos: [
      {
        color: nodeKindLightColor(kind),
        radius: 28,
        width: isSelected ? 8 : 0,
      },
    ],
    image: logoUrl ?? nodeImage(kind),
    label: [
      {
        backgroundColor: 'transparent',
        color: isSelected ? '#ffffff' : nodeKindColor(kind),
        fontFamily: 'GraphIcons',
        fontSize: iconSize,
        position: {
          horizontal: 'center',
          vertical: 'middle',
        },
        padding: {
          top: iconSize / 2,
        },
      },
      {
        backgroundColor: 'transparent',
        margin: {
          top: DEFAULT_NODE_SIZE + borderWidth * 2 + borderToTextGap,
          bottom: 0,
          left: 0,
          right: 0,
        },
        color: '#2D2D2D',
        fontFamily: 'Inter',
        fontSize: 14,
        bold: true,
        text: organizationData.name,
        textWrap: 'normal',
        minWidth: 200,
        position: {
          horizontal: 'center',
          vertical: 'top',
        },
      },
      {
        backgroundColor: 'transparent',
        color: '#757575',
        fontFamily: 'Inter',
        fontSize: 12,
        text: NODE_NAME[kind],
        textWrap: 'normal',
        minWidth: 200,
      },
    ],
  }
}

function createEdge({
  edgeData,
  label,
  directed,
  color = '#2D2D2D',
  fade = true,
}: {
  edgeData: IEdgeData
  label?: string
  directed?: boolean
  color?: string
  fade?: boolean
}): Link<IItemData<IEdgeData>> {
  const id = createEdgeId(edgeData.fromId, edgeData.toId)

  return {
    id1: edgeData.fromId,
    id2: edgeData.toId,
    lineStyle: 'solid',
    end2: {
      arrow: directed,
    },
    width: 0.6,
    color,
    fade,
    data: {
      id,
      type: ItemType.Edge,
      data: edgeData,
    },
    ...(label
      ? {
          label: {
            fontFamily: 'Inter',
            color: '#2D2D2D',
            text: label,
          },
        }
      : {}),
  }
}

/* *** PRIVATE *** */

function createRelationshipStrengthEdge({
  relationshipStrengthEdgeData,
}: {
  relationshipStrengthEdgeData: IRelationshipStrengthEdgeData
}): Link<IItemData<IRelationshipStrengthEdgeData>> {
  const color =
    RELATIONSHIP_STRENGTH_EDGE_COLORS[relationshipStrengthEdgeData.strength]
  const glyphColor =
    RELATIONSHIP_STRENGTH_EDGE_GLYPH_COLORS[
      relationshipStrengthEdgeData.strength
    ]

  return {
    id1: relationshipStrengthEdgeData.fromId,
    id2: relationshipStrengthEdgeData.toId,
    color,
    lineStyle: 'solid',
    width: 0.75,
    fade: relationshipStrengthEdgeData.fade ?? true,
    glyphs: [
      {
        border: {
          color,
        },
        color: 'rgba(255,255,255,1)',
        size: 0,
        label: {
          fontFamily: 'Inter',
          color: glyphColor,
          text: `${relationshipStrengthEdgeData.strength}`,
        },
      },
    ],
    data: {
      id: createEdgeId(
        relationshipStrengthEdgeData.fromId,
        relationshipStrengthEdgeData.toId,
      ),
      type: ItemType.RelationshipStrengthEdge,
      data: relationshipStrengthEdgeData,
    },
  }
}

/* *** PUBLIC *** */

// TODO: Temporarily making public to fix a bug on prod
function nodeKindColor(kind: NodeKind, defaultColor?: string) {
  return theme.graph.nodeKind[kind] || defaultColor
}

function nodeKindLightColor(kind: NodeKind) {
  return theme.graph.nodeKindLight[kind] || '#dfdfdf'
}

function nodeImage(kind: NodeKind) {
  return `/img/nodes/${kind}.svg`
}

function isCombo(id?: string | null) {
  return id?.startsWith('_combonode_')
}

function isEdge(id?: string) {
  return id?.startsWith('edge')
}

function appendItems({
  myUserId,
  users = [],
  skills = [],
  needSkills = [],
  tags = [],
  organizations = [],
  communities = [],
  showTargetTags = false,
  showTargetSkills = false,
  showTargetOrganizations = false,
  existingItems = {},
  selectedIds = [],
  knowledge = [],
  areasOfExperience = [],
}: {
  myUserId?: string
  users?: IGraphPersonNode[]
  skills?: ISkillNode[]
  needSkills?: ISkillNode[]
  tags?: ITagNode[]
  organizations?: MainSchema.Organization[]
  knowledge?: MainSchema.KnowledgeGraphNode[]
  areasOfExperience?: MainSchema.AreaOfExperience[]
  communities?: ICommunityNode[]
  showTargetTags?: boolean
  showTargetSkills?: boolean
  showTargetOrganizations?: boolean
  existingItems?: Items<IItemData>
  selectedIds: string[]
}): [Index<GraphItem<IItemData>>, Index<Link<IItemData>>] {
  const graphNodes: Index<GraphItem<IItemData>> = {}
  const graphEdges: Index<Link<IItemData>> = {}

  const appendedTags = appendSkillsTagsOrganizations({
    skills,
    needSkills,
    tags,
    organizations,
    selectedIds,
  })

  Object.assign(graphNodes, appendedTags)

  const appendedCommunities = appendCommunities({
    communities,
    selectedIds,
  })

  Object.assign(graphNodes, appendedCommunities)

  const knowledgeUsers = knowledge
    .filter(node => node.kind === KnowledgeGraphNodeKind.Person && node.value)
    .map(node => node.value as MainSchema.CommunityUser)
    .map(
      communityUser =>
        ({
          ...communityUser,
          id: communityUser.userId,
          connectedUsers: [],
        }) as unknown as IGraphPersonNode,
    )

  forEach([...users, ...knowledgeUsers], user => {
    // temporary FE solution to ignore archived users
    if (user && user.userId) {
      const userNode = createUserNode({
        user,
        isMe: user.userId === myUserId,
        isSelected: selectedIds.includes(user.userId),
      })

      if (userNode.data!.id) {
        graphNodes[userNode.data!.id] = userNode
      }

      if (existingItems[user.userId]) {
        return
      }

      const userSkills = concat(
        user.communityUserSkills?.map(
          e => e.skill as ISkillNode,
        ) as ISkillNode[],
      )
      const userTags = concat(
        user.communityUserTags?.map(e => e.tag as ITagNode) as ITagNode[],
      )

      let skillsTagsOrganizations = {}

      if (showTargetSkills) {
        const appendSkills = appendSkillsTagsOrganizations({
          skills: userSkills,
          selectedIds,
        })

        skillsTagsOrganizations = {
          ...skillsTagsOrganizations,
          ...appendSkills,
        }
      }

      if (showTargetTags) {
        const appendTags = appendSkillsTagsOrganizations({
          tags: userTags as ITagNode[],
          selectedIds,
        })

        skillsTagsOrganizations = {
          ...skillsTagsOrganizations,
          ...appendTags,
        }
      }

      if (showTargetOrganizations) {
        const organizations = user.communityUserWorkHistory
          ?.filter(workHistory => workHistory?.isCurrent)
          .map(
            workHistory => workHistory.organization,
          ) as MainSchema.Organization[]

        skillsTagsOrganizations = {
          ...skillsTagsOrganizations,
          ...appendSkillsTagsOrganizations({
            organizations,
            selectedIds,
          }),
        }
      }

      forEach(skillsTagsOrganizations, (item, id) => {
        graphNodes[id] = item
      })

      if (showTargetOrganizations) {
        const organizations = user.communityUserWorkHistory
          ?.filter(workHistory => workHistory?.isCurrent)
          .map(
            workHistory => workHistory.organization,
          ) as MainSchema.Organization[]

        skillsTagsOrganizations = {
          ...skillsTagsOrganizations,
          ...appendSkillsTagsOrganizations({
            organizations,
            selectedIds,
          }),
        }
      }

      forEach(skillsTagsOrganizations, (item, id) => {
        graphNodes[id] = item
      })

      forEach(user.connections, connection => {
        if (user.userId && connection.toUserId) {
          const [fromId, toId] = [user.userId, connection.toUserId].sort()
          const edge = createEdge({
            edgeData: {
              fromId,
              toId,
            },
          })
          graphEdges[edge.data!.id] = edge
        }
      })

      forEach(user.communityUserSkills, skill => {
        if (user.communityUserId && skill.skillId) {
          const [fromId, toId] = [user.userId, skill.skillId].sort()
          const edge = createEdge({
            edgeData: {
              fromId,
              toId,
              kind: NODE_KIND.skill,
            },
          })
          graphEdges[edge.data!.id] = edge
        }
      })

      forEach(user.communityUserTags, tag => {
        if (user.userId && tag.tagId) {
          const [fromId, toId] = [user.userId, tag.tagId].sort()
          // TODO: Legacy is using NODE_KIND.skill for tags??
          const edge = createEdge({
            edgeData: {
              fromId,
              toId,
              kind: NODE_KIND.skill,
            },
          })
          graphEdges[edge.data!.id] = edge
        }
      })

      forEach(user.communityUserEducationHistory, history => {
        if (user.userId && history.organizationId) {
          const [fromId, toId] = [user.userId, history.organizationId].sort()
          const edge = createEdge({
            edgeData: {
              fromId,
              toId,
              kind: NODE_KIND.organization,
            },
          })
          graphEdges[edge.data!.id] = edge
        }
      })

      forEach(user.communityUserWorkHistory, history => {
        if (user.userId && history.organizationId) {
          if (history.organizationId) {
            const [fromId, toId] = [user.userId, history.organizationId].sort()
            const edge = createEdge({
              edgeData: {
                fromId,
                toId,
                kind: NODE_KIND.organization,
              },
            })

            graphEdges[edge.data!.id] = edge
          }
          if (history.jobTitleId) {
            const [fromId, toId] = [user.userId, history.jobTitleId].sort()
            const edge = createEdge({
              edgeData: {
                fromId,
                toId,
                kind: NODE_KIND.organization,
              },
            })

            graphEdges[edge.data!.id] = edge
          }
        }
      })

      forEach(
        user?.communityUsers?.map(e => e.communityId),
        communityId => {
          if (user.userId) {
            const [fromId, toId] = [user.userId, communityId].sort()
            const edge = createEdge({
              edgeData: {
                fromId,
                toId,
              },
            })
            graphEdges[edge.data!.id] = edge
          }
        },
      )
    }
  })

  forEach(areasOfExperience, areaOfExperience => {
    let areaOfExperienceSkills: ISkillNode[] = areaOfExperience.skills.map(
      skill => ({
        id: skill.id,
        name: skill.name,
      }),
    )

    if (existingItems[areaOfExperience.id]) {
      const existingAreaOfExperience = getAsAreaOfExperienceNode(
        existingItems[areaOfExperience.id],
      )

      areaOfExperienceSkills = [
        ...existingAreaOfExperience.data!.data.skills,
        ...areaOfExperienceSkills,
      ]
    }

    const newAreaOfExperienceNode = createAreaOfExperienceNode({
      areaOfExperienceNode: {
        id: areaOfExperience.id,
        name: areaOfExperience.name,
        skills: areaOfExperienceSkills,
      },
      isSelected: selectedIds.includes(areaOfExperience.id),
    })
    graphNodes[newAreaOfExperienceNode.data!.id] = newAreaOfExperienceNode

    const edge = createEdge({
      edgeData: {
        fromId: areaOfExperience.id,
        toId: areaOfExperience.userId,
        kind: NODE_KIND.areaOfExperience,
      },
    })
    graphEdges[edge.data!.id] = edge

    areaOfExperienceSkills.forEach(skill => {
      const edge = createEdge({
        edgeData: {
          fromId: areaOfExperience.id,
          toId: skill.id,
          kind: NODE_KIND.skill,
        },
      })
      graphEdges[edge.data!.id] = edge
    })
  })

  forEach(knowledge, node => {
    if (!node) return

    if (node.valueString) {
      const newKnowledgeNode = createKnowledgeNode({
        knowledgeNode: node,
        isSelected: selectedIds.includes(node.id),
      })
      graphNodes[newKnowledgeNode.data!.id] = newKnowledgeNode
    }
  })

  forEach(knowledge, node => {
    if (!node?.connections) return

    node.connections.forEach(connection => {
      const edge = createEdge({
        edgeData: {
          fromId: node.id,
          toId: connection.toId,
          kind: 'Knowledge',
        },
        label: connection.relationship,
        directed: true,
      })
      graphEdges[edge.data!.id] = edge
    })
  })

  return [graphNodes, graphEdges]
}

function appendSkillsTagsOrganizations({
  skills,
  tags,
  organizations,
  selectedIds,
}: {
  skills?: ISkillNode[]
  needSkills?: ISkillNode[]
  tags?: ITagNode[]
  organizations?: MainSchema.Organization[] | null
  selectedIds: string[]
}) {
  const graphNodes: Items<IItemData> = {}
  forEach(skills, skill => {
    if (skill?.id && skill.name) {
      const newSkill = createSkillTagNode({
        skillTagData: {
          id: skill.id,
          name: skill?.name,
          kind: NODE_KIND.skill,
        },
        skillTag: {
          id: skill.id,
          name: skill?.name || skill?.name || 'N/A',
          kind: NODE_KIND.skill,
        },
        isSelected: selectedIds.includes(skill.id),
      })
      graphNodes[newSkill.data!.id] = newSkill
    }
  })

  forEach(tags, tag => {
    if (tag && tag.id && tag?.name) {
      const newTag = createSkillTagNode({
        skillTagData: {
          id: tag.id,
          name: tag.name || tag?.name || 'N/A',
          kind: (tag.kind || tag?.kind) as SkillTagKind,
        },
        skillTag: {
          id: tag.id,
          name: tag.name || tag?.name || 'N/A',
          kind: (tag.kind || tag?.kind) as SkillTagKind,
        },
        isSelected: selectedIds.includes(tag.id),
      })
      graphNodes[newTag.data!.id] = newTag
    }
  })

  forEach(organizations, organization => {
    if (!organization) return
    const newOrganization = createOrganizationNode({
      organizationData: organization,
      organization,
      isSelected: selectedIds.includes(organization.id),
    })
    graphNodes[newOrganization.data!.id] = newOrganization
  })

  return graphNodes
}

function appendCommunities({
  communities,
  selectedIds,
}: {
  communities?: ICommunityNode[]
  selectedIds: string[]
}) {
  const graphNodes: Items<IItemData> = {}
  forEach(communities, community => {
    if (community?.id && community?.name) {
      const newCommunity = createCommunityNode({
        community,
        isSelected: selectedIds.includes(community.id),
      })
      graphNodes[newCommunity.data!.id] = newCommunity
    }
  })
  return graphNodes
}

// TODO: Optimize
function appendRelationshipStrengthEdges({
  me,
  nodes,
  relationships,
  edges,
  showRelationshipStrength,
}: {
  me: MainSchema.User
  nodes: Items<IItemData>
  relationships: Record<string, Record<string, UserRelationshipStrength>>
  edges: Index<Link<IItemData>>
  showRelationshipStrength?: Record<string, boolean>
}) {
  const result: Items = {}
  const omitEdgeIds: string[] = []

  forEach(nodes, (item, fromId) => {
    if (isUserNode(item)) {
      if (item.data!.id === me?.id) {
        // Ensure logged in user is always on the graph
        result[item.data!.id] = item
      }
      forEach(relationships[fromId], (strength, toId) => {
        if (showRelationshipStrength?.[strength]) {
          const id1 = edges[createEdgeId(fromId, toId)]
          const id2 = edges[createEdgeId(toId, fromId)]

          if (id1) omitEdgeIds.push(id1.data!.id)
          if (id2) omitEdgeIds.push(id2.data!.id)

          const edge = createRelationshipStrengthEdge({
            relationshipStrengthEdgeData: {
              fromId,
              toId,
              strength: RELATIONSHIP_STRENGTH_ENUM[strength],
            },
          })

          // TODO: Check why nodes[fromId], or  nodes[toId] can be undefined - existing relationship strength in the DB, but a user didn't exist anymore?
          if (nodes[fromId]) result[fromId] = nodes[fromId]
          if (nodes[toId]) result[toId] = nodes[toId]

          result[edge.data!.id] = edge
        }
      })
    } else {
      result[fromId] = item
    }
  })

  return { ...omit(edges, omitEdgeIds), ...result }
}

function buildPaths(
  paths: IGraphPersonNode[][],
  items: Index<GraphItem<IItemData>>,
  me: MainSchema.User,
  selectedIds: string[],
) {
  const clonedItems = cloneDeep(items)
  let updatedItems: Index<GraphItem<IItemData>> = {}
  const updatedEdges: Index<Link<IItemData>> = {}
  const nodeIds: string[] = []

  forEach(paths, path => {
    forEach(path, (currentNode, index) => {
      nodeIds.push(currentNode.userId!)

      const userNode = createUserNode({
        user: currentNode,
        isMe: currentNode.userId === me?.userId,
        isSelected: selectedIds.includes(currentNode.userId!),
        fade: false,
      })
      updatedItems = {
        ...updatedItems,
        [userNode.data!.id]: userNode,
      }

      const nextNode = path[index + 1]

      if (!nextNode) {
        return
      }

      const toEdgeId = createEdgeId(nextNode.userId!, currentNode.userId!)
      const fromEdgeId = createEdgeId(currentNode.userId!, nextNode.userId!)

      const hasToEdge = !!items[toEdgeId]

      const userEdge = createEdge({
        edgeData: {
          fromId: hasToEdge ? nextNode.userId! : currentNode.userId!,
          toId: hasToEdge ? currentNode.userId! : nextNode.userId!,
        },
        fade: false,
        color: '#262dff',
      })

      updatedEdges[hasToEdge ? toEdgeId : fromEdgeId] = userEdge
    })
  })

  forEach(clonedItems, item => {
    if (item.data!.id && !nodeIds.includes(item.data!.id)) {
      const fadedItem = cloneDeep(item)
      fadedItem.fade = true
      updatedItems[item.data!.id] = fadedItem
    }
  })

  return { ...updatedItems, ...updatedEdges }
}

function getClusteredName(item: IItemData, id: string) {
  return (
    item.cluster?.split('_')?.pop() ||
    item.clusterType?.split('_')?.pop() ||
    item.clusterContainer?.split('_')?.pop() ||
    id
  )
}

function getClusteredColor(item: IItemData) {
  const actionKind =
    item.cluster?.split('_')?.[0] ||
    item.clusterType?.split('_')?.[0] ||
    item.clusterContainer?.split('_')?.[0]

  return nodeKindColor(actionKind as NodeKind, '#cecece')
}

async function analyzeNodes(analyzer: AnalyzerFunction, nodes: Index<Node>) {
  const res = await analyzerFunctions[analyzer](nodes, {})

  const nodesCopy = cloneDeep(nodes)

  forEach(res, (value, key) => {
    nodesCopy[key].size = Math.max(Math.min(Number(value), 8), 0.5)
  })

  return nodesCopy
}

function deserializeUserRelationStrength(
  usersRelationStrength: string[],
): Record<string, Record<string, UserRelationshipStrength>> {
  return usersRelationStrength.reduce<
    Record<string, Record<string, UserRelationshipStrength>>
  >((acc, item) => {
    const [fromUserId, strength, toUserId] = item.split(':')
    const strengthNumber = parseInt(strength, 10)
    let id: UserRelationshipStrength = USER_RELATIONSHIP_STRENGTH.DONT_KNOW

    if (strengthNumber === 1) id = USER_RELATIONSHIP_STRENGTH.WEAK
    if (strengthNumber === 2) id = USER_RELATIONSHIP_STRENGTH.MODERATE
    if (strengthNumber === 3) id = USER_RELATIONSHIP_STRENGTH.STRONG

    if (!acc[fromUserId]) {
      acc[fromUserId] = { [toUserId]: id }
    } else {
      acc[fromUserId] = {
        ...acc[fromUserId],
        [toUserId]: id,
      }
    }

    return acc
  }, {})
}

function appendSkillTag({
  targetUserId,
  id,
  name,
  kind,
  isSelected,
}: {
  targetUserId: string
  id: string
  name: string
  kind: SkillTagKind
  isSelected: boolean
}): [Index<GraphItem<IItemData>>, Index<Link<IItemData>>] {
  const node = createSkillTagNode({
    skillTagData: { id, name, kind },
    skillTag: { id, name, kind },
    isSelected,
  })
  const edge = createEdge({
    edgeData: {
      fromId: targetUserId,
      toId: id,
      kind,
    },
  })
  return [{ [node.data!.id]: node }, { [edge.data!.id]: edge }]
}

function createUserConnection({
  user,
  connectTo,
}: {
  user: IGraphPersonNode
  connectTo: IGraphPersonNode
}) {
  const [fromId, toId] = [user.userId!, connectTo.userId!].sort()
  const edge = createEdge({
    edgeData: {
      fromId,
      toId,
    },
  })
  return { [edge.data!.id]: edge }
}

function createTemporaryUserConnection({
  fromUserId,
  toUserId,
}: {
  fromUserId: string
  toUserId: string
}) {
  const [fromId, toId] = [fromUserId, toUserId].sort()
  const edge = createEdge({
    edgeData: {
      fromId,
      toId,
    },
  })
  return { [edge.data!.id]: edge }
}

function appendUserSkillTagEdge({
  fromId,
  toId,
  kind,
}: {
  fromId: string
  toId: string
  kind: NodeKind
}) {
  const edge = createEdge({
    edgeData: {
      fromId,
      toId,
      kind,
    },
  })
  return { [edge.data!.id]: edge }
}

function appendCommunityEdge({
  fromId,
  toId,
  kind,
}: {
  fromId: string
  toId: string
  kind: NodeKind
}) {
  const edge = createEdge({
    edgeData: {
      fromId,
      toId,
      kind,
    },
  })
  return { [edge.data!.id]: edge }
}

function findExistingEdge(
  existing: Items<IItemData>,
  id1: string,
  id2: string,
) {
  return (
    existing[`edge:${id1}:${id2}`] || existing[`edge:${id2}:${id1}`] || null
  )
}

const getAvailableQuickActionsForUsers = (
  quickActions: QuickActionKind[],
  quickActionHandlers: Record<QuickActionKind, IActionHandler>,
  users: IGraphPersonNode[] | MainSchema.CommunityUser[],
  selectedUsers: IGraphPersonNode[] | MainSchema.CommunityUser[],
): Record<string, Record<string, IAvailableQuickAction>> => {
  const availableQuickActions: Record<
    string,
    Record<string, IAvailableQuickAction>
  > = {}
  forEach(users, (user: IGraphPersonNode | MainSchema.CommunityUser) => {
    availableQuickActions[user.userId!] = {}

    quickActions.forEach(kind => {
      const actionHandler = quickActionHandlers[kind]

      availableQuickActions[user.userId!][kind] = {
        isEnabled: actionHandler.isEnabled({
          user: user as MainSchema.CommunityUser,
          users: selectedUsers as MainSchema.CommunityUser[],
        }),
        handleClick: () =>
          actionHandler.onClick({
            user: user as MainSchema.CommunityUser,
            users: selectedUsers as MainSchema.CommunityUser[],
          }),
      }
    })
  })

  return availableQuickActions
}

const getEnabledQuickActions = (
  quickActionKinds: IQuickActionKind[],
  availableQuickActions: Record<string, IAvailableQuickAction>,
): IQuickAction[] => {
  const quickActions: IQuickAction[] = []

  quickActionKinds.forEach(quickActionKind => {
    const availableQuickAction = availableQuickActions[quickActionKind.kind]

    if (!availableQuickAction?.isEnabled) {
      return
    }

    quickActions.push({
      kind: quickActionKind.kind,
      label: _(quickActionKind.label),
      icon: quickActionKind.icon,
      handleClick: availableQuickAction.handleClick,
    })
  })

  return quickActions
}

const utils = {
  getNodeSize,
  isItemDataNode,
  isUserNode,
  getAsUserNode,
  isUserQuickConnectGlyph,
  isAskOfferGlyph,
  createUserNode,
  isSkillTagNode,
  isCommunityNode,
  getAsSkillTagNode,
  createSkillTagNode,
  isOrganizationNode,
  isKnowledgeNode,
  isAreaOfExperienceNode,
  getAsOrganizationNode,
  getAsKnowledgeNode,
  getAsAreaOfExperienceNode,
  createAreaOfExperienceNode,
  createOrganizationNode,
  createKnowledgeNode,
  createCommunityNode,
  nodeKindColor,
  isCombo,
  isEdge,
  createEdge,
  createRelationshipStrengthEdge,
  appendItems,
  appendSkillTag,
  appendRelationshipStrengthEdges,
  buildPaths,
  createUserConnection,
  createTemporaryUserConnection,
  appendUserSkillTagEdge,
  appendCommunityEdge,
  getClusteredName,
  getClusteredColor,
  analyzeNodes,
  deserializeUserRelationStrength,
  findExistingEdge,
  getAvailableQuickActionsForUsers,
  getEnabledQuickActions,
}

export default utils
