import React, { useCallback, useEffect, useMemo, useState } from 'react'

import { FiDownload } from 'react-icons/fi'

import { useMutation } from '@apollo/client'
import { IconSearch } from '@tabler/icons-react'
import deleteCommunityUserMutation from 'GraphQL/Mutations/CommunityUser/deleteCommunityUser.graphql'
import listCommunityUsersQuery from 'GraphQL/Queries/CommunityUser/listCommunityUsers.full.graphql'
import { entityCacheRemover } from 'GraphQL/Updaters/Common'
import Utils from 'Utils'

import filter from 'lodash/filter'
import map from 'lodash/map'

import ContentCard from 'Components/Blocks/ContentCard'
import CreateCommunityUserModal from 'Components/Blocks/Modals/CreateCommunityUser'
import DeleteAccountModal from 'Components/Blocks/Modals/DeleteAccount'
import ExportCommunityUsersModal from 'Components/Blocks/Modals/ExportCommunityUsers/ExportCommunityUsers'
import UpdateCommunityUserModal from 'Components/Blocks/Modals/UpdateCommunityUser'
import UpdateCommunityUsersRoleModal from 'Components/Blocks/Modals/UpdateCommunityUsersRole'
import UpdateCommunityUserStatusModal from 'Components/Blocks/Modals/UpdateCommunityUserStatus'
import { Button, Column, Input, Loader, Row, Table, Text } from 'Components/UI'

import {
  useCommunityContext,
  useEntityModal,
  useEntityTable,
  useTableSearch,
} from 'Hooks'

import { useQuery } from 'Services/Apollo'
import { useScopedI18n } from 'Services/I18n'
import toast from 'Services/Toast'

import { useColumns } from './columns'
import TableContext from './context'
import Filter from './Filter'
import useAbility from './useAbility'
import { useExportUsers } from './useExportUsers'

import {
  TableControlContainer,
  TableGroupOperatingPanel,
  TablePagination,
} from '../Blocks'

const DEFAULT_PAGE_SIZE = 10

const SORT_BY = [
  {
    column: 'createdAt',
    order: 'desc',
  },
]

function AccountsTable() {
  const s = useScopedI18n('accountManagement')
  const { community } = useCommunityContext()
  const {
    canCreateUser,
    canEditUserIfCreator,
    canEditUserIfNotCreator,
    canEditCreator,
    canEditNotCreator,
  } = useAbility()

  const [filters, setFilters] = useState({})
  const [isSelectedAll, setSelectedAll] = useState(false)
  const [selectedUsers, setSelectedUsers] = useState([])

  const [mostRecentPage, setMostRecentPage] = useState(0)
  const [mostRecentLimit, setMostRecentLimit] = useState(DEFAULT_PAGE_SIZE)
  const [mostRecentSortBy, setMostRecentSortBy] = useState(SORT_BY)
  const [innerSearch, setInnerSearch] = useState(null)
  const [isExportModalOpen, setIsExportModalOpen] = useState(false)

  const canEdit = canEditCreator || canEditNotCreator

  const [search, changeSearch, clearSearch] = useTableSearch({
    onSearch: value => {
      Utils.Search.tableSearch({
        search: value,
        onSetSearchValue: setInnerSearch,
        onGoToPage: tableProps.gotoPage,
      })
    },
  })

  // Removing creatorIds and stub from query, RBAC handles this now.
  const { data, loading, refetch } = useQuery(listCommunityUsersQuery, {
    variables: {
      communityIds: [community?.id],
      page: mostRecentPage,
      limit: mostRecentLimit,
      sortBy: mostRecentSortBy,
      search: innerSearch,
      showArchived: true,
      ...filters,
    },
    fetchPolicy: 'network-only',
  })

  const columns = useColumns({ showHeader: canEdit })
  const entities = useMemo(
    () =>
      ({
        rows: data?.listCommunityUsers?.communityUsers,
        pages: data?.listCommunityUsers?.pages,
        count: data?.listCommunityUsers?.count,
      }) || {},
    [data],
  )

  const [tableProps, rowsCount] = useEntityTable({
    data: entities,
    columns,
    initialState: {
      sortBy: [
        {
          id: 'createdAt',
          desc: true,
        },
      ],
    },
  })

  const { pageSize, pageIndex, sortBy } = tableProps.state

  const sort = useMemo(
    () =>
      map(sortBy, value => ({
        column: value.id,
        order: value.desc ? 'desc' : 'asc',
      })),
    [sortBy],
  )

  useEffect(() => {
    setMostRecentPage(pageIndex)
    setMostRecentSortBy(sort.length !== 0 ? sort : null)
    setSelectedAll(false)
  }, [pageIndex, sort])

  useEffect(() => {
    setMostRecentPage(0)
    setMostRecentLimit(pageSize)
  }, [pageSize])

  const refetchOnClose = useCallback(
    ({ context }) =>
      async ({ success }) => {
        if (!success) {
          return
        }

        try {
          await refetch()
        } catch (error) {
          toast.error({
            title: context,
            text: error?.message,
          })
        }
      },
    [refetch],
  )

  const [editModal, editActions] = useEntityModal({
    onClose: refetchOnClose({ context: s('actions.edit') }),
  })

  const [createModal, createActions] = useEntityModal({
    onClose: refetchOnClose({ context: s('actions.add') }),
  })

  const [deleteModal, deleteActions] = useEntityModal()

  const [updateStatusModal, updateStatusModalActions] = useEntityModal({
    onClose: refetchOnClose({ context: s('actions.update') }),
  })
  const [updateRoleModal, updateRoleModalActions] = useEntityModal({
    onClose: refetchOnClose({ context: s('actions.update') }),
  })

  const userToDeleteFirstName = Utils.Table.renderOptionalString({
    value: deleteModal.entity?.firstName,
  })
  const userToDeleteLastName = Utils.Table.renderOptionalString({
    value: deleteModal.entity?.lastName,
  })

  const [deleteCommunityUser] = useMutation(deleteCommunityUserMutation, {
    update: entityCacheRemover({
      entity: deleteModal.entity,
      pageSize: mostRecentLimit,
      queryKey: 'communityUsers',
    }),
  })

  const handleEditModalClose = editActions.closeModal
  const handleCreateModalOpen = createActions.openModal
  const handleCreateModalClose = createActions.closeModal
  const handleDeleteModalClose = deleteActions.closeModal
  const handleUpdateStatusModalOpen = updateStatusModalActions.openModal
  const handleCloseUpdateStatusModal = updateStatusModalActions.closeModal
  const handleUpdateRoleModalOpen = updateRoleModalActions.openModal
  const handleCloseUpdateRoleModal = updateRoleModalActions.closeModal

  const { isExporting, exportUsers, saveFile } = useExportUsers({
    communityIds: [community?.id],
    selectedUsers,
  })

  const handleDeleteUser = useCallback(
    async success => {
      if (!success) {
        handleDeleteModalClose().then()
        return
      }

      try {
        await deleteCommunityUser({
          variables: {
            communityId: community?.id,
            id: deleteModal.entity?.id,
          },
        })

        toast.success({
          title: s('actions.delete'),
          text: s('deleteSuccess', {
            userName: `${userToDeleteFirstName} ${userToDeleteLastName}`,
          }),
        })

        handleDeleteModalClose().then()
      } catch (error) {
        toast.error({
          title: s('actions.delete'),
          text: error.message,
        })
      }
    },
    [
      community?.id,
      deleteModal.entity?.id,
      deleteCommunityUser,
      s,
      userToDeleteFirstName,
      userToDeleteLastName,
      handleDeleteModalClose,
    ],
  )

  const handleFilters = filtersBy => setFilters(filtersBy)

  const handleSelectAll = useCallback(
    event => {
      const checked = event?.target?.checked

      if (checked) {
        const filteredUsersByPermission = filter(
          data?.communityUsers,
          user => canEditUserIfCreator(user) || canEditUserIfNotCreator(user),
        )

        setSelectedAll(true)
        setSelectedUsers(
          map(filteredUsersByPermission, user => ({
            id: user.id,
            userId: user.userId,
            firstName: user.firstName,
            lastName: user.lastName,
          })),
        )
      } else {
        setSelectedAll(false)
        setSelectedUsers([])
      }
    },
    [data, canEditUserIfNotCreator, canEditUserIfCreator],
  )

  const handleSelectUser = useCallback(
    communityUser => {
      const selectedUsersIds = map(selectedUsers, 'userId')

      if (selectedUsersIds.includes(communityUser?.userId)) {
        setSelectedUsers(prevState =>
          prevState.filter(user => user?.id !== communityUser?.id),
        )
      } else {
        setSelectedUsers(prevState => [...prevState, communityUser])
      }
    },
    [selectedUsers],
  )

  const memoizedContext = useMemo(
    () => ({
      onEditRow: editActions.openModal,
      onDeleteRow: deleteActions.openModal,
      onSelectAll: handleSelectAll,
      onSelectUser: handleSelectUser,
      selectedUsersIds: map(selectedUsers, 'userId'),
      isSelectedAll,
    }),
    [
      deleteActions.openModal,
      editActions.openModal,
      handleSelectAll,
      handleSelectUser,
      isSelectedAll,
      selectedUsers,
    ],
  )

  const prepareContacts = async () => {
    await exportUsers()
    setIsExportModalOpen(true)
  }

  const handleConfirm = filename => saveFile(filename)

  return (
    <ContentCard>
      <TableContext.Provider value={memoizedContext}>
        <TableControlContainer>
          <Row center gap={3}>
            <Input
              clearable
              name="search"
              placeholder={s('search.byAccount')}
              renderBeforeElement={() => <IconSearch />}
              value={search}
              width={[1, 1, '300px']}
              onChange={changeSearch}
              onClear={clearSearch}
            />
            <Filter onChange={handleFilters} />
          </Row>

          <TableGroupOperatingPanel
            canUpdateRole={canEdit}
            canUpdateStatus={canEdit}
            itemCount={selectedUsers.length}
            suffix="users"
            onUpdateRole={handleUpdateRoleModalOpen}
            onUpdateStatus={handleUpdateStatusModalOpen}
          />

          <Row center gap={3}>
            {canCreateUser && (
              <Button onClick={prepareContacts}>
                {isExporting ? <Loader /> : <FiDownload />}
                {selectedUsers.length > 0
                  ? `${s('actions.exportSelected')} (${selectedUsers.length})`
                  : `${s('actions.exportAll')} (${rowsCount})`}
              </Button>
            )}
            {canCreateUser && (
              <Button onClick={handleCreateModalOpen}>
                {s('actions.add')}
              </Button>
            )}
          </Row>
        </TableControlContainer>

        <Table {...tableProps} loading={loading} />

        <TablePagination
          state={{ pageIndex, pageSize }}
          total={rowsCount}
          onPageIndexChange={tableProps?.gotoPage}
          onPageSizeChange={tableProps?.setPageSize}
        />

        <UpdateCommunityUserModal
          isOpen={editModal.isOpen}
          user={editModal.entity}
          onClose={handleEditModalClose}
        />

        <CreateCommunityUserModal
          isOpen={createModal.isOpen}
          onClose={handleCreateModalClose}
        />

        <DeleteAccountModal
          content={
            <>
              {s('deleteConfirmation.0')}{' '}
              <span>
                {userToDeleteFirstName} {userToDeleteLastName}
              </span>
              {s('deleteConfirmation.1')}
            </>
          }
          isOpen={deleteModal.isOpen}
          title={s('actions.delete')}
          onClose={handleDeleteUser}
        />

        <UpdateCommunityUserStatusModal
          communityId={community?.id}
          isOpen={updateStatusModal.isOpen}
          users={selectedUsers}
          onClose={handleCloseUpdateStatusModal}
        />

        <UpdateCommunityUsersRoleModal
          communityId={community?.id}
          isOpen={updateRoleModal.isOpen}
          users={selectedUsers}
          onClose={handleCloseUpdateRoleModal}
        />

        <ExportCommunityUsersModal
          cancelText="Cancel"
          confirmText="Confirm"
          handleSaveFile={handleConfirm}
          isOpen={isExportModalOpen}
          title="Export Contacts"
          onClose={() => setIsExportModalOpen(false)}
        >
          <Column gap={5} noShrink spaceBetween>
            <Text body header2>
              ExportModal
            </Text>
          </Column>
        </ExportCommunityUsersModal>
      </TableContext.Provider>
    </ContentCard>
  )
}

export default AccountsTable
