import styled, { css } from 'styled-components'

export interface IGraphTooltipCss {
  x: number
  y: number
}

const GraphTooltipCss = ({ x, y }: IGraphTooltipCss) => css`
  left: ${x}px;
  top: ${y}px;
`

export interface IGraphTooltipEnabledCss {
  enabled?: boolean
}

const graphTooltipEnabledCss = ({ enabled }: IGraphTooltipEnabledCss) => {
  const color = enabled ? 'black' : 'gray'
  const fontStyle = enabled ? 'normal' : 'italic'
  const strike = enabled ? 'none' : 'line-through'
  return css`
    font-style: ${fontStyle};
    color: ${color};
    text-decoration: ${strike};
  `
}

export interface IGraphTooltipContainer extends IGraphTooltipCss {}

export const GraphTooltipContainer = styled.div<IGraphTooltipContainer>`
  position: absolute;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-top: -18px;
  margin-left: 22px;

  width: auto;
  padding: 6px 8px;
  background-color: #fff;

  font-family: sans-serif;
  font-size: 14px;
  text-align: center;

  border-radius: 1.5px 3px 3px 1.5px;
  z-index: 1;
  -webkit-box-shadow: -2px 2px 6px 0px rgba(94, 94, 94, 1);
  box-shadow: -2px 2px 6px 0px rgba(94, 94, 94, 1);

  pointer-events: none;
  ${GraphTooltipCss}
`

export const GraphTooltipArrow = styled.div`
  position: absolute;
  left: -11px;
  width: 22px;
  height: 22px;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  background-color: #fff;
  z-index: 0;
  -webkit-box-shadow: -1px 4px 6px -2px rgba(94, 94, 94, 1);
  box-shadow: -1px 4px 6px -2px rgba(94, 94, 94, 1);
`

export interface IGraphTooltipContent extends IGraphTooltipEnabledCss {}

export const GraphTooltipContent = styled.div<IGraphTooltipContent>`
  position: relative;
  z-index: 2;
  height: 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  ${graphTooltipEnabledCss}
`
