import React from 'react'
import { Outlet } from 'react-router-dom'

import { Container } from './styles'

function Root() {
  return (
    <Container>
      <Outlet />
    </Container>
  )
}

export default Root
