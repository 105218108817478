import React, { ComponentType } from 'react'
import ReactModal, { Classes, Props } from 'react-modal'

import { down } from 'styled-breakpoints'
import styled, { css } from 'styled-components'
import { mapToTheme } from 'styled-map'
import { layout, LayoutProps, padding, PaddingProps } from 'styled-system'
import { themeGet } from '@styled-system/theme-get'

import { customScrollbar } from 'Theme/styles'

const MODAL_SCALE = '0.5, 0.5, 0.5'

const ModalSafeForReact18 = ReactModal as ComponentType<ReactModal['props']>

export interface IReactModalAdapter
  extends Omit<Props, 'className' | 'modalClassName' | 'overlayClassName'> {
  className?: string
  modalClassName: Classes
  overlayClassName: Classes
  width?: string | string[] | number
}

function ReactModalAdapter({
  className,
  modalClassName,
  overlayClassName,
  ...props
}: IReactModalAdapter) {
  return (
    <ModalSafeForReact18
      className={modalClassName}
      overlayClassName={overlayClassName}
      portalClassName={className}
      {...props}
    />
  )
}

export const StyledModal = styled(ReactModalAdapter).attrs({
  modalClassName: {
    base: 'Modal',
    afterOpen: 'Modal--after-open',
    beforeClose: 'Modal--before-close',
  },
  overlayClassName: {
    base: 'Overlay',
    afterOpen: 'Overlay--after-open',
    beforeClose: 'Overlay--before-close',
  },
})`
  .Overlay {
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: ${themeGet('colors.modal.overlay')};
    transition: all ${themeGet('transitionTime.modal')} ease-in;
    z-index: ${themeGet('zIndices.modal')};

    &--after-open {
      background-color: rgba(0, 0, 0, 0.31);
    }

    &--before-close {
      background-color: ${themeGet('colors.modal.overlay')};
      backdrop-filter: none;
    }
  }

  .Modal {
    display: flex;
    flex-direction: column;
    width: ${({ width }) => (width ? `${width}` : 'fit-content')};
    background-color: ${themeGet('colors.modal.bg')};
    border-radius: ${mapToTheme('modals.borderRadius')}px;
    outline: none;
    transform: scale3d(${MODAL_SCALE});
    transition: all ${themeGet('transitionTime.modal')} ease-in;

    &--after-open {
      transform: scale3d(1, 1, 1);

      overflow-y: auto !important;
      max-height: 95%;

      ${down('md')} {
        width: calc(100vw - 20px);
      }
    }

    &--before-close {
      transform: scale3d(${MODAL_SCALE});
      opacity: 0;
    }
  }
`

export const CloseButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  padding: 0;
  border: none;
  background: none;
  outline: none;
  width: 24px;
  height: 24px;
  cursor: pointer;
  color: ${themeGet('colors.modal.closeButton')};

  > svg {
    width: 16px;
    height: 16px;
  }
`

export const Divider = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${themeGet('colors.modal.divider')};
  flex-shrink: 0;
`

export interface IContentWrapper extends PaddingProps, LayoutProps {}

export const ContentWrapper = styled.div<IContentWrapper>`
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  padding-block: ${themeGet('space.4')}px;
  padding-left: ${themeGet('space.5')}px;
  padding-right: ${themeGet('space.2')}px;

  ${padding}
  ${layout}
`

const contentNoScrollCss = ({ noScroll }: { noScroll?: boolean }) =>
  noScroll &&
  css`
    overflow-y: hidden;
  `

export interface IContent extends PaddingProps {
  noScroll?: boolean
}

export const Content = styled.div<IContent>`
  width: 100%;
  padding-right: ${themeGet('space.3')}px;
  overflow-y: auto;
  overflow-x: hidden;

  ${padding}

  ${contentNoScrollCss}
  ${customScrollbar}
`
