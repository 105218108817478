import { useCallback } from 'react'

import { subject } from '@casl/ability'
import { useAbility } from '@casl/react'

import reduce from 'lodash/reduce'

import PermissionContext from 'Services/Permission/PermissionContext'

export default function usePermission() {
  const permission = useAbility(PermissionContext)

  const handleCan = useCallback(
    (action: string, subjectName: string, scope: string[]) => {
      const conditions = reduce(
        scope,
        (acc, index) => ({ ...acc, [index]: true }),
        {},
      )

      return permission?.can(action, subject(subjectName, conditions))
    },
    [permission],
  )

  return {
    permission,
    can: handleCan,
  }
}
