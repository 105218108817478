import React from 'react'
import { Field } from 'react-final-form'

import styled from 'styled-components'
import { omit, pick } from '@styled-system/props'
import { themeGet } from '@styled-system/theme-get'

import { FieldValidator } from 'final-form'
import Utils from 'Utils'

import TextAreaEditor, { ITextAreaEditorProps } from './TextAreaEditor'

import { RequiredMark } from '../styles'

const Label = styled.div`
  color: ${themeGet('colors.mineShaft')};
  transition: all ease-in-out ${themeGet('transitionTime.default')};
  font-weight: bold;
  user-select: none;
  cursor: text;
  margin-bottom: 8px;
`

export interface ITextAreaFieldProps
  extends Omit<ITextAreaEditorProps, 'content' | 'error' | 'onChange'> {
  checkErrorIfDirty?: boolean
  displayError?: boolean
  label?: string
  name: string
  validate?: FieldValidator<string>
}

function TextAreaField({
  checkErrorIfDirty = false,
  displayError = true,
  name,
  validate,
  disabled = false,
  ...rest
}: ITextAreaFieldProps) {
  const inputProps = omit(rest)

  return (
    <Field name={name} validate={validate}>
      {({ input: { value, onChange: handleChange }, meta }) => {
        const { hasError, error } = Utils.Form.hasError(meta, checkErrorIfDirty)

        return (
          <div>
            {inputProps.label && (
              <Label>
                {inputProps.label}
                {inputProps.required && <RequiredMark>*</RequiredMark>}
              </Label>
            )}
            <TextAreaEditor
              {...pick(rest)}
              content={value}
              disabled={disabled}
              error={displayError && hasError ? error : null}
              onChange={handleChange}
            />
          </div>
        )
      }}
    </Field>
  )
}

export default TextAreaField
