export const defaultOAuthScopes = [
  'https://www.googleapis.com/auth/userinfo.profile',
  'https://www.googleapis.com/auth/userinfo.email',
]

export const oAuthScopes = {
  'https://www.googleapis.com/auth/gmail.readonly': 'Email',
  'https://www.googleapis.com/auth/calendar': 'Calendar',
  'https://www.googleapis.com/auth/contacts': 'Contacts',
}
