import React from 'react'
import { components } from 'react-select'

import { IconX } from '@tabler/icons-react'

import { Clear } from './styles'

const ClearIndicator: typeof components.ClearIndicator = props => {
  // TODO: find a better way to satisfy the type
  const StyledClear = Clear as any
  return (
    <StyledClear {...props} as={components.ClearIndicator}>
      <IconX size={16} />
    </StyledClear>
  )
}

export default ClearIndicator
