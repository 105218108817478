import styled from 'styled-components'

export const Content = styled.div`
  display: flex;
  flex-direction: column;
`

export const DownloadLink = styled.a`
  text-decoration: none;
`
