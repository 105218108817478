import React from 'react'

import { FRONTEND } from 'Config'

import { Code, Container } from './styles'

export default function CommunityNotFound() {
  return (
    <Container>
      <Code>404</Code>
      Community not found
      <a href={`${FRONTEND.PROTOCOL}://${FRONTEND.URL}`}>Go to the NetworkOS</a>
    </Container>
  )
}
