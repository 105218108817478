import React from 'react'

import * as Styled from './Divider.styles'

export interface IDivider extends Styled.IContainer {}

function Divider(props: IDivider) {
  return <Styled.Container {...props} />
}

export default Divider
