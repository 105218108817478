import { Observable } from '@apollo/client'
// eslint-disable-next-line import/no-extraneous-dependencies
import { onError } from '@apollo/client/link/error'

import forEach from 'lodash/forEach'
import get from 'lodash/get'

import { refresh } from 'Services/Api/Queries/auth'
import AuthService from 'Services/Auth'
import { getAccessToken, getRefreshToken } from 'Services/Store/auth'

let isFetchingToken = false
let subscribers = []

const subscribeTokenRefresh = callback => {
  subscribers.push(callback)
}
const onTokenRefreshed = err => {
  subscribers.map(callback => callback(err))
}

/* eslint-disable no-console, consistent-return */
export default function errorLink(history) {
  return onError(
    ({ response, graphQLErrors, networkError, operation, forward }) => {
      if (networkError) console.error(`[Network error]: ${networkError}`)

      if (graphQLErrors) {
        forEach(graphQLErrors, ({ message, location, path }) => {
          if (message === 'PersistedQueryNotFound') return

          console.log(
            `[GraphQL error]: Message: ${message}, Location: ${location}, Path: ${path}`,
          )
        })
      }

      if (operation.operationName === 'Me' && response) {
        response.errors = null
      }

      const isUnauthorizedError =
        get(networkError, 'statusCode') === 401 ||
        get(graphQLErrors, [0, 'extensions', 'name']) ===
          'AuthenticationError' ||
        get(graphQLErrors, [0, 'message']) === 'generic.notAuthorized'

      if (isUnauthorizedError) {
        const refreshToken = getRefreshToken()

        if (refreshToken) {
          return new Observable(async observer => {
            try {
              const retryRequest = () => {
                operation.setContext(({ headers = {} }) => {
                  const accessToken = getAccessToken()

                  return {
                    headers: {
                      ...headers,
                      Authorization: accessToken
                        ? `Bearer ${accessToken}`
                        : null,
                    },
                  }
                })

                const subscriber = {
                  next: observer.next.bind(observer),
                  error: observer.error.bind(observer),
                  complete: observer.complete.bind(observer),
                }

                return forward(operation).subscribe(subscriber)
              }

              if (!isFetchingToken) {
                isFetchingToken = true

                try {
                  const result = await refresh({ refreshToken })

                  if (result.ok) {
                    await AuthService.handleAuth({
                      accessToken: result.refreshed,
                    })
                  } else {
                    throw new Error('Refresh token expired')
                  }

                  isFetchingToken = false
                  onTokenRefreshed(null)
                  subscribers = []

                  return retryRequest()
                } catch (e) {
                  onTokenRefreshed(new Error('Unable to refresh access token'))

                  subscribers = []
                  isFetchingToken = false

                  await AuthService.logOut(history)
                }
              }

              return new Promise(resolve => {
                subscribeTokenRefresh(errRefreshing => {
                  if (!errRefreshing) return resolve(retryRequest())
                })
              })
            } catch (e) {
              observer.error(e)
            }
          })
        }

        AuthService.logOut(history)

        return forward(operation)
      }
    },
  )
}
