import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import Utils from 'Utils'

import BasicHeader from 'Components/Blocks/BasicHeader'
import { Loader, Text } from 'Components/UI'

import { useAppContext, useLocationQueryParams } from 'Hooks'

import { ROOT } from 'Router/routes'

import { Container } from './styles'

function Redirector() {
  const { me } = useAppContext()
  const navigate = useNavigate()
  const queryParams = useLocationQueryParams()

  useEffect(() => {
    const isAuthorized = !!me

    const route = Utils.Me.getRootRoute(me)

    const timeout = setTimeout(() => {
      const path = isAuthorized ? route : ROOT

      if (queryParams?.community) {
        navigate(Utils.URL.generatePath(path, { slug: queryParams.community }))
        return
      }

      navigate(path, { replace: true })
    }, 1000)

    return () => clearTimeout(timeout)
  }, [queryParams, navigate, me])

  return (
    <Container>
      <BasicHeader />
      <Loader />
      <Text bodyMedium header>
        Redirect...
      </Text>
    </Container>
  )
}

export default Redirector
