const toasts = {
  minWidth: { default: 300 },

  borderRadius: { default: 10 },

  progressBarHeight: { default: 2 },

  iconSize: { default: 20 },

  xSize: { default: 16 },
}

export default toasts
