import React from 'react'
import { TableInstance } from 'react-table'

import { HiSortAscending, HiSortDescending } from 'react-icons/hi'

import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import map from 'lodash/map'

import { Loader, Row } from 'Components/UI'

import {
  Body,
  Header,
  HeaderCell,
  RowCell,
  StyledTable,
  TableRow,
} from './styles'

export interface ITableProps<D extends object> extends TableInstance<D> {
  contentOffset?: number
  loading?: boolean
}

function Table<D extends object>({
  loading = false,
  getTableProps,
  getTableBodyProps,
  headerGroups = [],
  page = [],
  prepareRow,
  contentOffset,
}: ITableProps<D>) {
  return (
    <StyledTable {...getTableProps()}>
      <Header contentOffset={contentOffset}>
        {map(headerGroups, headerGroup => (
          <TableRow {...headerGroup.getHeaderGroupProps()}>
            {map(headerGroup.headers, column => (
              <HeaderCell
                {...column.getHeaderProps(column.getSortByToggleProps?.())}
                right={get(column, 'headerRight')}
              >
                {column.render('Header')}
                <Row ml={1}>
                  {column.canSort &&
                    column.isSorted &&
                    (column.isSortedDesc ? (
                      <HiSortDescending size={14} />
                    ) : (
                      <HiSortAscending size={14} />
                    ))}
                </Row>
              </HeaderCell>
            ))}
          </TableRow>
        ))}
      </Header>
      <Body {...getTableBodyProps()}>
        {
          // TODO: refactor
          // eslint-disable-next-line no-nested-ternary
          loading ? (
            <TableRow>
              <RowCell center info>
                <Loader />
              </RowCell>
            </TableRow>
          ) : isEmpty(page) ? (
            <TableRow>
              <RowCell center info>
                No entities found
              </RowCell>
            </TableRow>
          ) : (
            map(page, row => {
              prepareRow(row)

              return (
                <TableRow {...row.getRowProps()}>
                  {map(row.cells, cell => (
                    <RowCell
                      {...cell.getCellProps()}
                      right={get(cell, ['column', 'cellRight'])}
                    >
                      {cell.render('Cell')}
                    </RowCell>
                  ))}
                </TableRow>
              )
            })
          )
        }
      </Body>
    </StyledTable>
  )
}

export default Table
