import { ACTION_KIND, ActionKind } from 'Constants/graph'
import { TagKind } from 'Constants/mainGraphQL'

const actionKindToTagKind = (actionKind: ActionKind): TagKind | null => {
  if (actionKind === ACTION_KIND.custom) {
    return TagKind.Custom
  }

  if (actionKind === ACTION_KIND.event) {
    return TagKind.Event
  }

  if (actionKind === ACTION_KIND.group) {
    return TagKind.Group
  }

  if (actionKind === ACTION_KIND.project) {
    return TagKind.Project
  }

  if (actionKind === ACTION_KIND.role) {
    return TagKind.Role
  }

  return null
}

const utils = {
  actionKindToTagKind,
}

export default utils
