import React, { useCallback, useMemo, useRef, useState } from 'react'
import { Form } from 'react-final-form'
import PropTypes from 'prop-types'

import { useApolloClient } from '@apollo/client'
import createCommunityMutation from 'GraphQL/Mutations/Community/createCommunity.graphql'
import updateCommunityMutation from 'GraphQL/Mutations/Community/updateCommunity.graphql'
import slugExistsQuery from 'GraphQL/Queries/Community/slugExists.graphql'
import validate from 'validate.js'

import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import noop from 'lodash/noop'

import {
  Button,
  CheckboxField,
  Column,
  ImageInputField,
  InputField,
  Loader,
  Modal,
  Row,
} from 'Components/UI'

import { UPLOAD_TYPES } from 'Constants/ids'

import { useAdminMutation } from 'Services/Apollo'
import _, { useScopedI18n } from 'Services/I18n'
import toast from 'Services/Toast'

import { Content } from './styles'

const FIELD = {
  NAME: 'name',
  DESCRIPTION: 'description',
  PHOTO_URL: 'photoUrl',
  OPEN: 'open',
  SLUG: 'slug',
  FIRST_NAME: 'firstName',
  LAST_NAME: 'lastName',
  EMAIL: 'email',
  EVENT_NAME: 'eventName',
}

validate.validators.inputSlugValidator = value => {
  if (/\s/.test(value)) {
    return `^Slug must be without whitespaces`
  }
  if (!/^[a-z0-9]+(?:-[a-z0-9]+)*$/.test(value)) {
    return `^Invalid Slug. Slug should only contain lowercase characters and numbers`
  }
  return null
}

validate.validators.inputEventNameValidator = value => {
  if (isEmpty(value)) {
    return null
  }
  if (/\s/.test(value)) {
    return `^Event Name must be without whitespaces`
  }
  if (!/^[a-zA-Z0-9-_.]+$/.test(value)) {
    return `^Invalid Event Name. Event Name should only contain URL safe characters (lowercase letters, uppercase letters, numbers, hyphens, underscores, and periods)`
  }
  return null
}

function ModifyCommunityModal({ community, isOpen, onClose }) {
  const s = useScopedI18n('community')
  const [loading, setLoading] = useState(false)

  const update = !!community

  const close = useRef(null)

  const [updateCommunity] = useAdminMutation(updateCommunityMutation)
  const [createCommunity] = useAdminMutation(createCommunityMutation)

  const client = useApolloClient()

  const formConstraints = useMemo(
    () => ({
      [FIELD.NAME]: {
        presence: true,
      },
      [FIELD.EVENT_NAME]: {
        presence: false,
        inputEventNameValidator: true,
        length: { maximum: 6, minimum: 3 },
      },
      [FIELD.SLUG]: {
        presence: true,
        length: { maximum: 75, minimum: 3 },
        inputSlugValidator: true,
      },
      [FIELD.EMAIL]: {
        email: {
          email: true,
          message: `^${_('auth.shared.emailInvalid')}`,
        },
      },
    }),
    [],
  )

  const handleMount = useCallback(instance => {
    close.current = get(instance, 'handleClose')
  }, [])

  const submit = useCallback(
    async values => {
      setLoading(true)
      // Only check if slug exists if it's changed
      if (community?.slug !== values[FIELD.SLUG]) {
        const { data } = await client.query({
          query: slugExistsQuery,
          variables: { slug: values[FIELD.SLUG] },
        })
        if (data?.slugExists) {
          setLoading(false)
          toast.error({
            title: 'Community',
            text: `${values[FIELD.SLUG]} slug already exists`,
          })
          return
        }
      }

      try {
        if (update) {
          await updateCommunity({
            variables: {
              communityId: community?.id,
              name: values[FIELD.NAME],
              photoUrl: values[FIELD.PHOTO_URL],
              description: values[FIELD.DESCRIPTION],
              open: values[FIELD.OPEN],
              slug: values[FIELD.SLUG],
              eventName: values[FIELD.EVENT_NAME] || null,
            },
          })
        } else {
          // TODO: Speak to Sergey regarding multi-field validator. Owner is optional, but all fields in owner are required especially email. We should only
          // send owner if all fields are filled out, show validation message if only one field is filled out and no validation message if no fields are filled out.
          const owner =
            values[FIELD.EMAIL] &&
            values[FIELD.FIRST_NAME] &&
            values[FIELD.LAST_NAME]
              ? {
                  firstName: values[FIELD.FIRST_NAME],
                  lastName: values[FIELD.LAST_NAME],
                  email: values[FIELD.EMAIL],
                }
              : null

          await createCommunity({
            variables: {
              name: values[FIELD.NAME],
              description: values[FIELD.DESCRIPTION],
              category: values[FIELD.CATEGORY],
              subCategory: values[FIELD.SUB_CATEGORY],
              open: values[FIELD.OPEN],
              slug: values[FIELD.SLUG],
              eventName: values[FIELD.EVENT_NAME] || null,
              owner,
            },
          })
        }

        toast.success({
          title: 'Community',
          text: `${values[FIELD.NAME]} community ${
            update ? 'updated' : 'created'
          }`,
        })

        setLoading(false)

        onClose()
      } catch (error) {
        toast.error({
          title: 'Oops...',
          text: error?.message,
        })

        setLoading(false)
      }
    },
    [community, client, update, onClose, updateCommunity, createCommunity],
  )

  return (
    <Modal
      isOpen={isOpen}
      shouldCloseOnOverlayClick={false}
      title={update ? s('actions.edit') : s('actions.create')}
      onClose={onClose}
      onMount={handleMount}
    >
      <Form
        initialValues={community}
        render={({ handleSubmit }) => (
          <>
            <Content>
              <Row gap={4}>
                <Column>
                  <InputField
                    label="Community Name"
                    mb={3}
                    name={FIELD.NAME}
                    width={1}
                  />
                  <InputField label="Slug" mb={3} name={FIELD.SLUG} width={1} />
                  <ImageInputField
                    mb={3}
                    name={FIELD.PHOTO_URL}
                    uploadType={UPLOAD_TYPES.profilePhoto}
                  />

                  <InputField
                    label="Description"
                    mb={3}
                    name={FIELD.DESCRIPTION}
                    width={1}
                  />

                  {update && (
                    <InputField
                      label="Change Report Event Name"
                      mb={3}
                      name={FIELD.EVENT_NAME}
                      width={1}
                    />
                  )}
                  <CheckboxField label="Open" mb={3} name={FIELD.OPEN} />
                </Column>
                {!update && (
                  <Column>
                    <InputField
                      label="Owner First Name"
                      mb={3}
                      name={FIELD.FIRST_NAME}
                      width={1}
                    />
                    <InputField
                      label="Owner Last Name"
                      mb={3}
                      name={FIELD.LAST_NAME}
                      width={1}
                    />
                    <InputField
                      label="Owner Email"
                      mb={3}
                      name={FIELD.EMAIL}
                      width={1}
                    />
                  </Column>
                )}
              </Row>
            </Content>

            <Row center mt={3} spaceBetween>
              {loading ? (
                <Loader />
              ) : (
                <>
                  <Button secondary small onClick={onClose}>
                    Cancel
                  </Button>
                  <Button small onClick={handleSubmit}>
                    Save
                  </Button>
                </>
              )}
            </Row>
          </>
        )}
        validate={values => validate(values, formConstraints)}
        onSubmit={submit}
      />
    </Modal>
  )
}

ModifyCommunityModal.defaultProps = {
  community: null,
  isOpen: false,
  onClose: noop,
}

ModifyCommunityModal.propTypes = {
  community: PropTypes.object,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
}

export default ModifyCommunityModal
