import React from 'react'

import { Column, RadioButton, Row, Text } from 'Components/UI'

function RadioButtons() {
  return (
    <Column gap={6}>
      <Text header1>Radio Buttons</Text>
      <Row gap={7}>
        <Column gap={2}>
          <RadioButton checked label="Option 1" name="name" value={1} />
          <RadioButton label="Option 2" name="name" value={2} />
          <RadioButton label="Option 3" name="name" value={3} />
          <RadioButton label="Option 4" name="name" value={4} />
        </Column>

        <Column gap={2}>
          <RadioButton checked disabled label="Option 1" name="disabled" />
          <RadioButton disabled label="Option 2" name="disabled" />
          <RadioButton disabled label="Option 3" name="disabled" />
          <RadioButton disabled label="Option 4" name="disabled" />
        </Column>
      </Row>
    </Column>
  )
}

export default RadioButtons
