import styled, { css } from 'styled-components'
import { margin, MarginProps, padding, PaddingProps } from 'styled-system'
import { themeGet } from '@styled-system/theme-get'

export interface ITabContainerVisibilityCssProps {
  visible?: boolean
}

const tabContainerVisibilityCss = ({
  visible,
}: ITabContainerVisibilityCssProps) =>
  visible &&
  css`
    display: flex;
  `

export interface ITabContainerProps
  extends ITabContainerVisibilityCssProps,
    PaddingProps,
    MarginProps {}

export const TabContainer = styled.div<ITabContainerProps>`
  flex-direction: column;
  display: none;

  ${tabContainerVisibilityCss}
  ${padding}
  ${margin}
`

export const ErrorWrapper = styled.div`
  color: ${themeGet('colors.negative')};
  font-size: 12px;
  padding: 0 12px;
  margin-top: 8px;
  line-height: 1.2;
`
