import palette from '../palette'

const shadows = {
  box: '0px 12px 16px -4px rgba(154, 161, 167, 0.2)',
  dangerFocus: `0px 0px 0px 2px ${palette.error20}80`,
  secondaryFocus: `0px 0px 0px 2px ${palette.primaryNeutral20}80`,
  primaryFocus: `0px 0px 0px 3px ${palette.primary10}`,
}

export default shadows
