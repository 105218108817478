import React from 'react'
import { components } from 'react-select'

import { MultiValueContainerWrapper } from './styles'

const MultiValueContainer: typeof components.MultiValueContainer = props => {
  // TODO: find a better way to satisfy the type
  const StyledMultiValueContainerWrapper = MultiValueContainerWrapper as any
  return (
    <StyledMultiValueContainerWrapper
      {...props}
      as={components.MultiValueContainer}
    />
  )
}

export default MultiValueContainer
