import { down } from 'styled-breakpoints'
import styled from 'styled-components'
import { themeGet } from '@styled-system/theme-get'

export const Container = styled.section`
  background-color: ${themeGet('colors.catskillWhite')};
  display: flex;
  min-height: 100vh;
  /* Using % instead of vw to prevent horizontal scroll */
  width: 100%;

  ${down('md')} {
    min-height: auto;
    height: 100%;
  }
`
