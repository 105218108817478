import React, { useCallback } from 'react'

import { useMutation, useQuery } from '@apollo/client'
import removeUserOAuthMutation from 'GraphQL/Mutations/User/removeUserOAuth.graphql'
import listOAuthProvidersQuery from 'GraphQL/Queries/User/listOAuthProviders.graphql'

import { Column, Divider } from 'Components/UI'

import _ from 'Services/I18n'
import toast from 'Services/Toast'

import Labels from './Labels'
import Scopes from './Scopes'

import Container from '../Container'
import Item from '../Item/Item'

export enum UserOAuthState {
  Unknown = 'unknown',
  Pending = 'pending',
  Active = 'active',
  Inactive = 'inactive',
  Expired = 'expired',
  Revoked = 'revoked',
  Errored = 'errored',
}

export interface IList {
  // onClickItemReconnect: () => void
  hideScopes?: boolean
  hideLabels?: boolean
}

// TODO: move text to translations
const List = (props: IList) => {
  // TODO: need to find a better way to get graphql types
  const { data } = useQuery<Pick<MainSchema.Query, 'listOAuthProviders'>>(
    listOAuthProvidersQuery,
  )
  const [removeUserOAuth] = useMutation(removeUserOAuthMutation)

  const userOAuths = data?.listOAuthProviders

  const handleRemove = useCallback(
    async (userOAuth: MainSchema.UserOAuth) => {
      try {
        await removeUserOAuth({
          variables: {
            id: userOAuth.id,
          },
          refetchQueries: [listOAuthProvidersQuery],
        })
        toast.success({
          title: 'Remove connected account',
          text: 'Your connected account has been removed',
        })
      } catch (error) {
        let message = _('error.generic')

        if (error instanceof Error) {
          message = _(`error.${error.message}`)
        }

        toast.error({
          title: 'Remove connected account',
          text: message,
        })
      }
    },
    [removeUserOAuth],
  )

  if (!userOAuths?.length) {
    return null
  }
  /*
  function getHasError(status: UserOAuthState) {
    return (
      status === UserOAuthState.Inactive ||
      status === UserOAuthState.Expired ||
      status === UserOAuthState.Revoked ||
      status === UserOAuthState.Errored
    )
  }
  */

  /* Removed state in oauth table
  function getStateAction(userOAuth: MainSchema.UserOAuth) {
    const hasError =  false // getHasError(userOAuth.state as UserOAuthState)

    if (hasError) {
      return {
        label: 'Reconnect',
        onPress: () => props.onClickItemReconnect(),
      }
    }

    switch (userOAuth.state) {
      case UserOAuthState.Pending:
        return {
          label: 'Pending',
          onPress: () => props.onClickItemReconnect(),
        }
      default:
        return undefined
    }
  }
  */

  return (
    <Column gap={6}>
      {userOAuths?.map((userOAuth, index) => {
        // const isSyncing = userOAuth.state === UserOAuthState.Pending
        // const isActive = userOAuth.state === UserOAuthState.Active
        // const hasError = getHasError(userOAuth.state as UserOAuthState)

        return (
          <React.Fragment key={userOAuth.id}>
            {index > 0 && <Divider />}
            <Column gap={4}>
              <Item
                // action={getStateAction(userOAuth)}
                /*
              description={
                isSyncing ? (
                  <Row gap={1}>
                    <Loader />
                    <Text>This account is currently syncing...</Text>
                  </Row>
                ) : undefined
              }
              error={
                hasError
                  ? 'Unable to connect to this account, please reconnect.'
                  : undefined
              }
              */
                key={userOAuth.id}
                label={userOAuth.email}
                remove={{
                  label: 'Remove connected account',
                  onPress: () => handleRemove(userOAuth),
                }}
              />
              {!props.hideScopes && (
                <Container>
                  <Scopes userOAuth={userOAuth} />
                </Container>
              )}
              {!props.hideLabels && (
                <Container>
                  <Labels userOAuth={userOAuth} />
                </Container>
              )}
            </Column>
          </React.Fragment>
        )
      })}
    </Column>
  )
}

export default List
