import styled from 'styled-components'

export const Clear = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`

export const Dropdown = styled.div`
  cursor: pointer;
`

export const ControlWrapper = styled.div``

export const GroupWrapper = styled.div``

export const GroupHeadingWrapper = styled.div``

export const Indicators = styled.div``

export const Separator = styled.div`
  display: none;
`

export const InputWrapper = styled.div``

export const MenuWrapper = styled.div``

export const MenuListWrapper = styled.div``

export const Item = styled.div``

export const MultiValueWrapper = styled.div``

export const MultiValueContainerWrapper = styled.div``

export const MultiValueLabelWrapper = styled.div``

export const PlaceholderWrapper = styled.div``

export const SelectContainerWrapper = styled.div``

export const Value = styled.div``

export const ValueContainerWrapper = styled.div``
