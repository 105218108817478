import { DateTime } from 'luxon'
import validatejs from 'validate.js'

import isEmpty from 'lodash/isEmpty'

validatejs.extend(validatejs.validators.datetime, {
  parse(value: Date) {
    return DateTime.fromJSDate(value).toMillis()
  },
  format(value: number) {
    return DateTime.fromMillis(value).toJSDate()
  },
})

function validateArray(arrayItems: unknown[][], itemConstraints: unknown[]) {
  if (!arrayItems) return null

  const arrayItemErrors = arrayItems.reduce((acc, item) => {
    const error = validatejs.single(item, itemConstraints)

    if (error) acc.push(error)

    return acc
  }, [])

  return isEmpty(arrayItemErrors) ? null : arrayItemErrors
}

const DEFAULT_VALIDATORS = [{ key: 'array', value: validateArray }]
export default DEFAULT_VALIDATORS
