import { DayPicker } from 'react-day-picker'

import styled, { css } from 'styled-components'
import { mapToTheme } from 'styled-map'
import { themeGet } from '@styled-system/theme-get'

export const CLASSNAME = {
  DROPDOWN_MONTH: 'rdp-dropdown_month',
  DROPDOWN_YEAR: 'rdp-dropdown_year',
}

const pickerCss = css`
  --rdp-cell-size: ${mapToTheme('datePickers.cellSize')}px;
  --rdp-accent-color: ${themeGet('colors.main')};
  --rdp-background-color: ${themeGet('colors.main')};
  --rdp-accent-color-dark: #000;
  --rdp-background-color-dark: #000;
  --rdp-outline: none;
  --rdp-outline-selected: none;
  --rdp-hover-bg-color: ${themeGet('colors.bg.hover')};
  --rdp-hover-color: ${themeGet('colors.text.header')};
  --caption-color: ${themeGet('colors.text.header')};
  --caption-font-size: ${themeGet('fontSize.2')}px;
  --caption-weight: ${themeGet('fontWeight.2')};
  --day-color: ${themeGet('colors.text.header')};
  --day-inverse-color: ${themeGet('colors.text.contrast')};
  --day-disabled-color: ${themeGet('colors.text.disabled')};
  --day_range_middle-bg-color: ${themeGet('colors.bg.hover')};
  --day-font-size: ${themeGet('fontSize.2')}px;
  --day-range-radius: ${mapToTheme('datePickers.cellBorderRadius')}px;
  --day-today-marker-color: ${themeGet('colors.main')};
  --day-today-marker-inverse-color: ${themeGet('colors.text.contrast')};
  --day-hover-bg-color: ${themeGet('colors.bg.hover')};
  --day-today-weight: ${themeGet('fontWeight.2')};
  --head-cell-color: ${themeGet('colors.text.disabled')};
  --head-cell-font-size: ${themeGet('fontSize.2')}px;
  --head-cell-weight: ${themeGet('fontWeight.0')};
  --nav-color: ${themeGet('colors.text.header')};
  --nav-hover-color: ${themeGet('colors.main')};
  --nav-icon-size: ${mapToTheme('datePickers.navIconSize')}px;
  background: ${themeGet('colors.bg.body')};
  border: none;
  padding: ${themeGet('space.3')}px;

  .rdp {
    margin: 0;
  }

  /* Hide elements for devices that are not screen readers */
  .rdp-vhidden {
    box-sizing: border-box;
    margin: 0;
    background: transparent;
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    position: absolute !important;
    top: 0;
    width: 1px !important;
    height: 1px !important;
    padding: 0 !important;
    overflow: hidden !important;
    clip: rect(1px, 1px, 1px, 1px) !important;
    border: 0 !important;
  }

  /* Buttons */
  .rdp-button_reset {
    appearance: none;
    position: relative;
    margin: 0;
    padding: 0;
    cursor: default;
    color: inherit;
    outline: none;
    background: none;
    font: inherit;

    -moz-appearance: none;
    -webkit-appearance: none;
  }

  .rdp-button {
    position: relative;
    border: 2px solid transparent;
    border-radius: var(--day-range-radius);
    justify-content: center;
    display: flex;
  }

  .rdp-button[aria-disabled='true'] {
    color: var(--day-disabled-color);
    pointer-events: none;
  }

  .rdp-button:not([aria-disabled='true']) {
    cursor: pointer;
  }

  .rdp-button:hover:not([aria-disabled='true']) {
    background-color: var(--rdp-hover-bg-color);
    color: var(--rdp-hover-color);
  }

  .rdp-button:focus,
  .rdp-button:active {
    color: ${themeGet('colors.white')};
    border: var(--rdp-outline);
    background-color: var(--rdp-background-color);
  }

  .rdp-months {
    display: flex;
  }

  .rdp-month {
    margin: 0 1em;
  }

  .rdp-month:first-child {
    margin-left: 0;
  }

  .rdp-month:last-child {
    margin-right: 0;
  }

  .rdp-table {
    margin: 0;
    max-width: calc(var(--rdp-cell-size) * 7);
    border-collapse: collapse;
  }

  .rdp-with_weeknumber .rdp-table {
    max-width: calc(var(--rdp-cell-size) * 8);
    border-collapse: collapse;
  }

  .rdp-caption {
    display: flex;
    height: 38px;
    align-items: center;
    justify-content: space-between;
    padding: 0;
    text-align: left;
    position: relative;
  }

  .rdp-multiple_months .rdp-caption {
    position: relative;
    display: block;
    text-align: center;
  }

  .rdp-caption_dropdowns {
    position: relative;
    display: inline-flex;
    width: 100%;
  }

  .rdp-caption_label {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translateY(-50%) translateX(-50%);
    z-index: 1;
    align-items: center;
    white-space: nowrap;
    margin: 0;
    border: 0;
    font-size: var(--caption-font-size);
    color: var(--caption-color);
    font-weight: var(--caption-weight);
  }

  .rdp-nav {
    white-space: nowrap;
    display: flex;
    justify-content: space-between;
    width: 100%;

    > button {
      width: 20px;
      padding: 0;
      margin: 0;

      > svg {
        width: var(--nav-icon-size);
        height: var(--nav-icon-size);
        > path {
          fill: var(--nav-color);
        }
      }

      :hover,
      :active,
      :focus {
        background: none !important;
        > svg {
          > path {
            fill: var(--nav-hover-color);
          }
        }
      }
    }
  }

  &.rdp-multiple_months .rdp-caption_start .rdp-nav {
    justify-content: flex-start;
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
  }

  &.rdp-multiple_months .rdp-caption_end .rdp-nav {
    justify-content: flex-end;
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
  }

  .rdp-nav_button {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: var(--rdp-cell-size);
    height: var(--rdp-cell-size);
    padding: 0.25em;
    border-radius: 100%;
  }

  /* ---------- */
  /* Dropdowns  */
  /* ---------- */
  .${CLASSNAME.DROPDOWN_MONTH}, .${CLASSNAME.DROPDOWN_YEAR} {
    position: relative;
    display: inline-flex;
    align-items: center;
  }

  .rdp-dropdown {
    appearance: none;
    position: absolute;
    z-index: 2;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    margin: 0;
    padding: 0;
    cursor: inherit;
    opacity: 0;
    border: none;
    background-color: transparent;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
  }

  .rdp-dropdown[disabled] {
    opacity: unset;
    color: unset;
  }

  .rdp-dropdown:focus:not([disabled]) + .rdp-caption_label,
  .rdp-dropdown:active:not([disabled]) + .rdp-caption_label {
    border: var(--rdp-outline);
    border-radius: var(--day-range-radius);
    background-color: var(--rdp-background-color);
  }

  .rdp-dropdown_icon {
    margin: 0 0 0 5px;
  }

  .rdp-head {
    border: 0;
  }

  .rdp-head_row,
  .rdp-row {
    height: 100%;
  }

  .rdp-head_cell {
    vertical-align: middle;
    text-align: center;
    height: var(--rdp-cell-size);
    padding: 0;
    color: var(--head-cell-color);
    font-weight: var(--head-cell-weight);
    font-size: var(--head-cell-font-size);
  }

  .rdp-tbody {
    border: 0;
  }

  .rdp-tfoot {
    margin: 0.5em;
  }

  .rdp-cell {
    width: var(--rdp-cell-size);
    height: var(--rdp-cell-size);
    padding: 0;
    text-align: center;
  }

  .rdp-weeknumber {
    font-size: 0.75em;
  }

  .rdp-weeknumber,
  .rdp-day {
    display: flex;
    overflow: hidden;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    font-weight: ${themeGet('fontWeights.2')};
    width: var(--rdp-cell-size);
    max-width: var(--rdp-cell-size);
    height: var(--rdp-cell-size);
    margin: 0;
    border: 2px solid transparent;
    border-radius: var(--day-range-radius);
    font-size: var(--day-font-size);
    color: var(--day-color);
  }

  .rdp-day_outside {
    color: var(--day-disabled-color);
  }

  .rdp-day_today {
    font-weight: var(--day-today-weight);

    &:after {
      content: '•';
      height: 1px;
      color: var(--day-today-marker-contrast-color);
      position: absolute;
      bottom: 40%;
    }
  }

  .rdp-day_today:not(.rdp-day_single_selected) {
    &:after {
      color: var(--day-today-marker-color);
    }
  }

  .rdp-day_range_end:not(.rdp-day_range_start) {
    color: var(--day-inverse-color) !important;
    background-color: var(--rdp-background-color) !important;
    border-bottom-left-radius: 0 !important;
    border-top-left-radius: 0 !important;

    &:after {
      color: var(--day-today-marker-contrast-color) !important;
    }
  }

  .rdp-day_range_start:not(.rdp-day_range_end) {
    color: var(--day-inverse-color) !important;
    background-color: var(--rdp-background-color) !important;
    border-bottom-right-radius: 0 !important;
    border-top-right-radius: 0 !important;

    &:after {
      color: var(--day-today-marker-contrast-color) !important;
    }
  }

  .rdp-day_range_middle {
    color: var(--day-color) !important;
    background-color: var(--day_range_middle-bg-color) !important;
    border-radius: 0 !important;

    &:hover {
      border-radius: var(--day-range-radius) !important;
    }

    &:after {
      color: var(--day-today-marker-color) !important;
    }
  }

  .rdp-day_single_selected {
    color: var(--day-inverse-color);
    background-color: var(--rdp-background-color);
    border-radius: var(--day-range-radius);

    &:hover {
      color: var(--day-inverse-color) !important;
      border: var(--rdp-outline) !important;
      background-color: var(--rdp-background-color) !important;
    }
  }

  .rdp-day_selected,
  .rdp-day_selected:active,
  .rdp-day_selected:hover {
    background-color: var(--rdp-accent-color);
    color: var(--day-color);

    &:after {
      color: var(--day-today-marker-contrast-color);
    }
  }
`

export const StyledPicker = styled(DayPicker)`
  ${pickerCss};

  border-radius: ${mapToTheme('datePickers.containerBorderRadius')}px;
`
