import React, {
  ChangeEventHandler,
  FocusEventHandler,
  forwardRef,
  KeyboardEventHandler,
  MouseEventHandler,
  useCallback,
  useState,
} from 'react'

import { omit, pick } from '@styled-system/props'

import { IconInfoCircle, IconX } from '@tabler/icons-react'

import { Row } from 'Components/UI/Flex'
import Loader from 'Components/UI/Loader'
import Text from 'Components/UI/Text'
import Tooltip from 'Components/UI/Tooltip'

import {
  ClearButton,
  Container,
  Counter,
  IWrapper,
  StyledInput,
  StyledTextArea,
  Wrapper,
} from './styles'

export interface IInput extends IWrapper {
  caption?: string
  clearable?: boolean
  counter?: string | number
  defaultValue?: string | number
  disabled?: boolean
  error?: boolean
  infoText?: string
  maxLength?: number
  isLoading?: boolean
  label?: React.ReactNode
  clearTitle?: string
  large?: boolean
  max?: number
  min?: number
  name?: string
  optional?: boolean
  placeholder?: string
  renderBeforeElement?: () => React.ReactNode
  renderAfterElement?: () => React.ReactNode
  required?: boolean
  small?: boolean
  success?: boolean
  textArea?: boolean
  type?: 'text' | 'number' | 'tel' | 'email' | 'password' | 'search'
  value?: string | number
  onBlur?: FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>
  onChange?: ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>
  onClear?: MouseEventHandler<HTMLButtonElement>
  onFocus?: FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>
  onKeyDown?: KeyboardEventHandler<HTMLInputElement | HTMLTextAreaElement>
}

const Input = forwardRef<HTMLInputElement, IInput>(
  (
    {
      type = 'text',
      caption,
      clearable,
      counter,
      disabled,
      error,
      infoText,
      isLoading,
      label,
      clearTitle,
      maxLength,
      renderBeforeElement,
      renderAfterElement,
      required,
      success,
      textArea,
      value,
      onBlur,
      onFocus,
      onClear,
      ...rest
    },
    ref,
  ) => {
    const [isActive, setIsActive] = useState<boolean>(false)

    const handleBlur: FocusEventHandler<
      HTMLInputElement | HTMLTextAreaElement
    > = useCallback(
      event => {
        setIsActive(false)
        onBlur?.(event)
      },
      [setIsActive, onBlur],
    )

    const handleFocus: FocusEventHandler<
      HTMLInputElement | HTMLTextAreaElement
    > = useCallback(
      event => {
        setIsActive(true)
        onFocus?.(event)
      },
      [setIsActive, onFocus],
    )

    return (
      <Wrapper {...pick(rest)} disabled={disabled}>
        {label && (
          <Row center gap={1} mb={1}>
            <Text disabled={disabled} header={!disabled} header4>
              {label}
              {required && !disabled && (
                <Text as="span" error ml={1}>
                  *
                </Text>
              )}
            </Text>
            {infoText && (
              <Tooltip content={infoText} offset={[0, 8]}>
                <IconInfoCircle size={16} />
              </Tooltip>
            )}
          </Row>
        )}

        <Container
          active={isActive}
          disabled={disabled}
          error={error}
          success={success}
          textArea={textArea}
        >
          {textArea ? (
            <StyledTextArea
              {...omit(rest)}
              disabled={disabled || isLoading}
              maxLength={maxLength}
              ref={ref}
              type={type}
              value={value}
              onBlur={handleBlur}
              onFocus={handleFocus}
            />
          ) : (
            <>
              {renderBeforeElement && <>{renderBeforeElement()}</>}

              {counter && (
                <Counter disabled={disabled}>
                  <Text contrast header5>
                    {counter}
                  </Text>
                </Counter>
              )}

              <StyledInput
                {...omit(rest)}
                disabled={disabled || isLoading}
                maxLength={maxLength}
                ref={ref}
                type={type}
                value={value}
                onBlur={handleBlur}
                onFocus={handleFocus}
              />

              {isLoading && <Loader />}

              {clearable && !disabled && value && (
                <ClearButton
                  disabled={isLoading || !value}
                  title={clearTitle}
                  onClick={onClear}
                >
                  <IconX />
                </ClearButton>
              )}

              {renderAfterElement && <>{renderAfterElement()}</>}
            </>
          )}
        </Container>

        {caption && (
          <Text
            bodySmall
            disabled={disabled}
            error={error}
            mt={1}
            success={success}
          >
            {caption}
          </Text>
        )}
      </Wrapper>
    )
  },
)

export default Input
