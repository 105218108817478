import React from 'react'

import { Link } from 'Components/UI'

import { ROOT } from 'Router/routes'

import { Container, Logo, LogoWrapper } from './styles'

function BasicHeader() {
  return (
    <Container>
      <Link to={ROOT}>
        <LogoWrapper>
          <Logo />
        </LogoWrapper>
      </Link>
    </Container>
  )
}

export default BasicHeader
