import React, { ComponentType } from 'react'
import ReactModal, { Classes, Props } from 'react-modal'

import { down } from 'styled-breakpoints'
import styled from 'styled-components'
import { margin, MarginProps } from 'styled-system'
import { themeGet } from '@styled-system/theme-get'

import { closeGlyph } from 'Assets/Svg'

import { flexCenter } from 'Components/Styles'
import { Column } from 'Components/UI/Flex'

import { customScrollbar } from 'Theme/styles'

const OVERLAY_BACKGROUND_COLOR = 'transparent'
const MODAL_SCALE = '0.5, 0.5, 0.5'

const ModalSafeForReact18 = ReactModal as ComponentType<ReactModal['props']>

export interface IReactModalAdapter
  extends Omit<Props, 'className' | 'modalClassName' | 'overlayClassName'> {
  className?: string
  modalClassName: Classes
  overlayClassName: Classes
}

function ReactModalAdapter({
  className,
  modalClassName,
  overlayClassName,
  ...props
}: IReactModalAdapter) {
  return (
    <ModalSafeForReact18
      className={modalClassName}
      overlayClassName={overlayClassName}
      portalClassName={className}
      {...props}
    />
  )
}

export const StyledModal = styled(ReactModalAdapter).attrs({
  modalClassName: {
    base: 'Modal',
    afterOpen: 'Modal--after-open',
    beforeClose: 'Modal--before-close',
  },
  overlayClassName: {
    base: 'Overlay',
    afterOpen: 'Overlay--after-open',
    beforeClose: 'Overlay--before-close',
  },
})`
  .Overlay {
    position: fixed;
    ${flexCenter};
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: ${OVERLAY_BACKGROUND_COLOR};
    transition: all ${themeGet('transitionTime.modal')} ease-in;
    z-index: 1000;

    &--after-open {
      background-color: rgba(0, 0, 0, 0.31);
    }

    &--before-close {
      background-color: ${OVERLAY_BACKGROUND_COLOR};
      backdrop-filter: none;
    }
  }

  .Modal {
    display: flex;
    flex-direction: column;
    width: fit-content;
    background-color: ${themeGet('colors.white')};
    border-radius: 8px;
    box-shadow: ${themeGet('shadow')};
    outline: none;
    transform: scale3d(${MODAL_SCALE});
    transition: all ${themeGet('transitionTime.modal')} ease-in;
    max-height: 98vh;

    ${customScrollbar}

    &--after-open {
      transform: scale3d(1, 1, 1);

      overflow-y: auto !important;
      max-height: 95%;

      ${down('md')} {
        width: calc(100vw - 20px);
      }
    }

    &--before-close {
      transform: scale3d(${MODAL_SCALE});
      opacity: 0;
    }
  }
`

export const Title = styled.div`
  font-size: 16px;
  font-weight: 600;
  white-space: nowrap;
`

export interface ICloseButtonProps extends MarginProps {}

export const CloseButton = styled.div<ICloseButtonProps>`
  ${flexCenter};
  flex-shrink: 0;
  width: 12px;
  height: 12px;
  cursor: pointer;
  position: absolute;
  right: 31px;
  top: 50%;
  margin-top: -6px;

  ${margin}
`

export const CloseIcon = styled(closeGlyph)`
  width: 12px;
  height: 12px;
  fill: ${themeGet('colors.primary')};
`

export const ModalHeader = styled(Column)`
  position: relative;
  text-align: center;
  border-bottom: 1px solid ${themeGet('colors.grayLightest')};

  p {
    max-width: 95%;
  }
`
