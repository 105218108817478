import { ApolloCache } from '@apollo/client'
import { AsStoreObject } from '@apollo/client/utilities'
import NoteFragment from 'GraphQL/Fragments/Note.graphql'

export const readNoteUpdater =
  (note: MainSchema.Note) =>
  (store: ApolloCache<Pick<MainSchema.Mutation, 'readNote'>>) => {
    store.modify<MainSchema.Note>({
      id: store.identify(note as AsStoreObject<MainSchema.Note>),
      fields: {
        read() {
          return true
        },
      },
    })
  }

export const isSharedUpdater =
  ({ noteId }: { noteId: string }) =>
  (store: ApolloCache<Pick<MainSchema.Mutation, 'readNote'>>) => {
    const fragment = {
      id: `Note:${noteId}`,
      fragmentName: 'NoteFields',
      fragment: NoteFragment,
    }

    // TODO: add type
    const fragmentData = store.readFragment(fragment)

    if (fragmentData) {
      // TODO: add type
      store.writeFragment({
        ...fragment,
        data: { ...fragmentData, isShared: true },
      })
    }
  }
