import { MutationUpdaterFn, QueryOptions } from '@apollo/client'
import meQuery from 'GraphQL/Queries/User/me.graphql'

import concat from 'lodash/concat'

export const meAddUserEmailUpdater =
  ({
    communityId,
  }: {
    communityId: string
  }): MutationUpdaterFn<Pick<MainSchema.Mutation, 'addUserEmail'>> =>
  (store, { data }) => {
    if (!data?.addUserEmail) return

    const query: QueryOptions = {
      query: meQuery,
      variables: {
        communityId,
      },
    }

    const queryData = store.readQuery<
      Pick<MainSchema.Query, 'me'>,
      MainSchema.QueryMeArgs
    >(query)
    const updatedEmails = concat(
      queryData?.me.emailCredentials ?? [],
      data.addUserEmail,
    )

    if (queryData) {
      store.writeQuery<Pick<MainSchema.Query, 'me'>, MainSchema.QueryMeArgs>({
        ...query,
        data: {
          ...queryData,
          me: {
            ...queryData.me,
            emailCredentials: updatedEmails,
          },
        },
      })
    }
  }
