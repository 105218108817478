import styled from 'styled-components'
import { themeGet } from '@styled-system/theme-get'

import { sidebarLogoGlyph } from 'Assets/Svg'

export const Container = styled.div`
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  align-items: center;
  justify-content: space-between;
  height: 68px;
  width: 100%;
  background-color: transparent;
  color: ${themeGet('colors.white')};
  padding: 8px 16px;

  @media screen and (max-width: ${themeGet('breakpoints.1')}) {
    position: relative;
    border-bottom: 1px solid ${themeGet('colors.divider.default')};
  }
`

export const LogoWrapper = styled.div`
  display: flex;
  height: 100%;
`

export const Logo = styled(sidebarLogoGlyph)`
  width: 233px;
`

export const PoweredWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`
