import React from 'react'

import { IconSquareRounded } from '@tabler/icons-react'

import { Column, Input, Row, Text } from 'Components/UI'

function Inputs() {
  return (
    <Column gap={6}>
      <Text header1>Inputs</Text>
      <Row fullWidth gap={5} wrapped>
        <Input label="Email" placeholder="Type your email" type="email" />
        <Input label="Phone" placeholder="Type your phone" type="tel" />
        <Input
          caption="Caption"
          clearable
          counter={3}
          infoText="Additional information"
          label="Label"
          placeholder="Placeholder"
          renderBeforeElement={() => <IconSquareRounded />}
        />
        <Input
          caption="Caption"
          clearable
          counter={333}
          isLoading
          label="Loading"
          placeholder="Placeholder"
        />
        <Input
          caption="Caption"
          clearable
          counter={3}
          disabled
          infoText="Additional information"
          label="Disabled"
          placeholder="Placeholder"
        />
        <Input
          caption="Success"
          clearable
          counter={3}
          label="Success"
          placeholder="Placeholder"
          success
        />
        <Input
          caption="Error"
          clearable
          counter={3}
          error
          label="Error"
          placeholder="Placeholder"
        />
      </Row>
    </Column>
  )
}

export default Inputs
