import React, { MouseEventHandler, useCallback, useState } from 'react'
import { useDropzone } from 'react-dropzone'

import { pick } from '@styled-system/props'

import { IconRefresh, IconTrash } from '@tabler/icons-react'
import { fileToDataURL } from 'Utils/Files'

import noop from 'lodash/noop'

import Avatar from 'Components/UI/Avatar'
import { IRow, Row } from 'Components/UI/Flex'
import Modal from 'Components/UI/Modal'
import Text from 'Components/UI/Text'

import { UPLOAD_TYPES, UploadType } from 'Constants/ids'

import { uploadFile } from 'Services/Api/Queries/upload'

import { StyledButton, Wrapper } from './styles'

export interface IImageInput extends IRow {
  disabled?: boolean
  entityId?: string
  required?: boolean
  uploadType?: UploadType
  value?: string
  onChange?: (url: string | null) => void
}

function ImageInput({
  disabled = false,
  value,
  entityId,
  required = false,
  uploadType = UPLOAD_TYPES.profilePhoto,
  onChange,
  ...rest
}: IImageInput) {
  const [uploadingFile, setUploadingFile] = useState<string | null>(null)
  const [loading, setLoading] = useState<boolean>(false)
  const [deleteImageModal, setDeleteImageModal] = useState<boolean>(false)

  const handleUploadFile = useCallback(
    async (file: File) =>
      uploadFile({
        file,
        type: uploadType,
        entityId,
        onProgress: noop,
      }),
    [entityId, uploadType],
  )

  const handleDrop = useCallback(
    async ([acceptedFile]: File[]) => {
      if (!acceptedFile) return

      setLoading(true)

      fileToDataURL(acceptedFile, setUploadingFile)

      const response = await handleUploadFile(acceptedFile)

      if (response.ok) {
        onChange?.(response.url)
      }

      setLoading(false)
      setUploadingFile(null)
    },
    [setUploadingFile, handleUploadFile, onChange],
  )

  const handleCloseDeleteModal: () => void = useCallback(() => {
    setDeleteImageModal(false)
  }, [])

  const handleShowDeleteModal: MouseEventHandler<HTMLButtonElement> =
    useCallback(() => {
      if (disabled) return
      setDeleteImageModal(true)
    }, [disabled])

  const handleDeleteImage: () => void = useCallback(() => {
    onChange?.(null)
    handleCloseDeleteModal()
  }, [handleCloseDeleteModal, onChange])

  const { getRootProps, getInputProps, isDragActive, open } = useDropzone({
    onDrop: handleDrop,
    accept: {
      'image/*': ['.jpeg', '.jpg', '.png'],
    },
    disabled: loading || disabled,
  })

  const src = uploadingFile || value

  return (
    <Row {...pick(rest)} center gap={4}>
      <Wrapper {...getRootProps()} isDragActive={isDragActive}>
        <input {...getInputProps()} />

        <Avatar large square src={src} />
      </Wrapper>

      <StyledButton
        disabled={isDragActive || loading || disabled}
        onClick={open}
      >
        <IconRefresh />
        Change photo
        {required && !disabled && (
          <Text as="span" error>
            *
          </Text>
        )}
      </StyledButton>

      {src && (
        <StyledButton
          danger
          disabled={isDragActive || loading || disabled}
          onClick={handleShowDeleteModal}
        >
          <IconTrash />
          Delete photo
        </StyledButton>
      )}

      <Modal
        cancelText="Cancel"
        confirmText="Remove"
        isOpen={deleteImageModal}
        title="Remove image?"
        onClose={handleCloseDeleteModal}
        onConfirm={handleDeleteImage}
      >
        <Text header header4>
          Are you sure you want to delete the image?
        </Text>
      </Modal>
    </Row>
  )
}

export default ImageInput
