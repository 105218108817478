import React, { useCallback } from 'react'
import { Field, useForm } from 'react-final-form'

import { omit, pick as styledPick } from '@styled-system/props'

import { FieldValidator } from 'final-form'
import Utils from 'Utils'

import pick from 'lodash/pick'

import { Column } from 'Components/UI/Flex'

import { Label } from './styles'
import TextArea, { TextAreaProps } from './Textarea'

import { RequiredMark } from '../styles'

interface TextAreaFieldProps {
  checkErrorIfDirty?: boolean
  displayError?: boolean
  input?: TextAreaProps
  name: string
  validate?: FieldValidator<string | number>
}

function TextAreaField({
  checkErrorIfDirty = false,
  displayError = true,
  input,
  name,
  validate,
  ...rest
}: TextAreaFieldProps) {
  const formApi = useForm()

  const handleClear = useCallback(() => {
    formApi.change(name, '')
  }, [formApi, name])

  const inputProps = omit(rest)

  return (
    <Field {...pick(rest, ['initialValue'])} name={name} validate={validate}>
      {({ input: { value, onChange: handleChange }, meta }) => {
        const { hasError, error } = Utils.Form.hasError(meta, checkErrorIfDirty)

        return (
          <Column {...styledPick(rest)}>
            {inputProps.label && (
              <Label>
                {inputProps.label}
                {inputProps.required && <RequiredMark>*</RequiredMark>}
              </Label>
            )}
            <TextArea
              error={displayError && hasError ? error : undefined}
              value={value}
              {...input}
              {...rest}
              onChange={({ target }) => handleChange(target.value)}
              onClear={handleClear}
            />
          </Column>
        )
      }}
    </Field>
  )
}

export default TextAreaField
