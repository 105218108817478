import React from 'react'
import { components } from 'react-select'

import { ValueContainerWrapper } from './styles'

const ValueContainer: typeof components.ValueContainer = props => {
  // TODO: find a better way to satisfy the type
  const StyledValueContainerWrapper = ValueContainerWrapper as any
  return (
    <StyledValueContainerWrapper {...props} as={components.ValueContainer} />
  )
}

export default ValueContainer
