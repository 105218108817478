import React from 'react'
import { Outlet, useLocation } from 'react-router-dom'

import { Row } from 'Components/UI'

import { COMMUNITY } from 'Router/routes'

import { PageContent } from './styles'

function NotMember() {
  const location = useLocation()

  const isCommunityPanelPage = location.pathname === COMMUNITY

  return isCommunityPanelPage ? (
    <PageContent>
      <Outlet />
    </PageContent>
  ) : (
    <Row center grow justifyCenter>
      Not a community member
    </Row>
  )
}

export default NotMember
