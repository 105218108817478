import styled, { css } from 'styled-components'
import { layout, LayoutProps, margin, MarginProps } from 'styled-system'
import { themeGet } from '@styled-system/theme-get'

import { customScrollbar } from 'Theme/styles'

export interface IStyledTableProps extends MarginProps {}

export const StyledTable = styled.div<IStyledTableProps>`
  display: flex;
  flex-direction: column;
  overflow-x: auto;
  max-height: 100vh;

  ${margin}

  ${customScrollbar}
`

export interface IScrollWrapperProps extends LayoutProps {}

export const ScrollWrapper = styled.div<IScrollWrapperProps>`
  display: flex;
  flex-direction: column;
  overflow-y: hidden;
  height: 100%;

  ${layout}
`

export interface ICellRightCssProps {
  right?: boolean
}

const cellRightCss = ({ right }: ICellRightCssProps) =>
  right &&
  css`
    justify-content: flex-end;
  `

export interface ICellCenterCssProps {
  center?: boolean
}

const cellCenterCss = ({ center }: ICellCenterCssProps) =>
  center &&
  css`
    justify-content: center;
  `

export interface IHeaderContentOffsetCssProps {
  contentOffset?: number
}

const headerContentOffsetCss = ({
  contentOffset,
}: IHeaderContentOffsetCssProps) =>
  contentOffset &&
  css`
    margin-bottom: ${contentOffset}px;
  `

export interface IHeaderProps extends IHeaderContentOffsetCssProps {}

export const Header = styled.div<IHeaderProps>`
  background-color: ${themeGet('colors.bg.overlay')};
  border-top: 1px solid ${themeGet('colors.divider.default')};
  border-bottom: 1px solid ${themeGet('colors.divider.default')};

  ${headerContentOffsetCss}
`

export interface IHeaderCellProps extends ICellRightCssProps {}

export const HeaderCell = styled.div<IHeaderCellProps>`
  display: flex;
  align-items: center;
  gap: ${themeGet('space.2')}px;
  user-select: none;

  ${cellRightCss}
`

export const TableRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 44px;
  padding: ${themeGet('space.2')}px ${themeGet('space.4')}px;
  color: ${themeGet('colors.text.heading')};
  gap: ${themeGet('space.3')}px;
  width: 100%;

  :not(:last-of-type) {
    border-bottom: 1px solid ${themeGet('colors.divider.default')};
  }
`

export interface IRowCellProps extends ICellRightCssProps, ICellCenterCssProps {
  info?: boolean
}

export const RowCell = styled.div<IRowCellProps>`
  align-items: center;
  font-size: ${themeGet('fontSize.2')}px;
  font-weight: ${themeGet('fontWeight.0')};
  line-height: 20px;
  overflow: hidden;
  text-overflow: ellipsis;

  ${cellRightCss}
  ${cellCenterCss}
`

export const Body = styled.div`
  overflow-y: auto;
  height: 100%;

  ${customScrollbar}
`
