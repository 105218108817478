import { CgClose } from 'react-icons/cg'
import { FiUserCheck } from 'react-icons/fi'
import { HiOutlinePencil } from 'react-icons/hi'

import styled from 'styled-components'
import { margin, MarginProps } from 'styled-system'

export interface IDeleteIconProps extends MarginProps {}

export const DeleteIcon = styled(CgClose)<IDeleteIconProps>`
  width: 20px;
  height: 20px;
  transition: transform 0.3s ease;
  ${margin}
`

export interface IEditIconProps extends MarginProps {}

export const EditIcon = styled(HiOutlinePencil)<IEditIconProps>`
  width: 20px;
  height: 20px;
  transition: transform 0.3s ease;
  ${margin}
`

export interface IUpdateIconProps extends MarginProps {}

export const UpdateIcon = styled(FiUserCheck)<IUpdateIconProps>`
  width: 20px;
  height: 20px;
  transition: transform 0.3s ease;
  ${margin}
`
