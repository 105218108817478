import styled from 'styled-components'
import { themeGet } from '@styled-system/theme-get'

export const Content = styled.div`
  display: flex;
  align-items: center;
  width: 100%;

  > svg {
    width: 20px;
    height: 20px;
    color: ${themeGet('colors.text.header')};
    stroke-width: 1px;
  }
`
