import styled from 'styled-components'
import { themeGet } from '@styled-system/theme-get'

export const Container = styled.div`
  padding: 2px 0;

  :not(:last-child) {
    border-bottom: 0.5px solid #dde1e4;
  }
`

export const Heading = styled.label`
  text-transform: uppercase;
  font-size: 10px;
  line-height: 1.6;
  font-weight: 400;
  color: #757575;
  padding: ${themeGet('space.1')}px ${themeGet('space.2')}px;
  display: block;
`
