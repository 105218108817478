import React from 'react'
import { Field } from 'react-final-form'

import { pick } from '@styled-system/props'

import { FieldValidator } from 'final-form'

import RadioButton, { IRadioButtonProps } from './RadioButton'

export interface IRadioButtonFieldProps extends IRadioButtonProps {
  caption?: String
  validate?: FieldValidator<string | number>
}

function RadioButtonField({ name, validate, ...rest }: IRadioButtonFieldProps) {
  return (
    <Field name={name} type="radio" validate={validate}>
      {({ input }) => <RadioButton {...pick(rest)} {...input} name={name} />}
    </Field>
  )
}

export default RadioButtonField
