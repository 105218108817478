import styled, { css } from 'styled-components'
import { mapToTheme } from 'styled-map'
import { layout, LayoutProps, space, SpaceProps } from 'styled-system'
import { themeGet } from '@styled-system/theme-get'

const activeCss = ({ active }: { active?: boolean }) =>
  active &&
  css`
    color: ${themeGet('colors.button.basic.primary.color')};
    background-color: ${themeGet('colors.button.icon.primary.bg')};

    :enabled:hover {
      background-color: ${themeGet('colors.button.icon.primary.hover.bg')};
    }

    :enabled:focus,
    :enabled:active {
      background-color: ${themeGet('colors.button.icon.primary.focus.bg')};
    }
  `

const outlineCss = ({ outline }: { outline?: boolean }) =>
  outline &&
  css`
    color: ${themeGet('colors.button.icon.outline.color')};
    background-color: ${themeGet('colors.button.icon.outline.bg')};
    border: 1px solid ${themeGet('colors.button.icon.outline.border')};

    :enabled:hover {
      background-color: ${themeGet('colors.button.icon.outline.hover.bg')};
      border: 1px solid ${themeGet('colors.button.icon.outline.hover.border')};
    }

    :enabled:focus,
    :enabled:active {
      background-color: ${themeGet('colors.button.icon.outline.focus.bg')};
      border: 1px solid ${themeGet('colors.button.icon.outline.focus.border')};
      box-shadow: ${themeGet('colors.button.icon.outline.focus.shadow')};
    }

    :disabled {
      background-color: ${themeGet('colors.button.icon.outline.disabled.bg')};
      border: 1px solid
        ${themeGet('colors.button.icon.outline.disabled.border')};
      color: ${themeGet('colors.button.icon.outline.disabled.color')};
    }
  `

const secondaryCss = ({ secondary }: { secondary?: boolean }) =>
  secondary &&
  css`
    color: ${themeGet('colors.button.icon.secondary.color')};
    background-color: ${themeGet('colors.button.icon.secondary.bg')};
    border: 1px solid ${themeGet('colors.button.icon.secondary.border')};

    :enabled:hover {
      background-color: ${themeGet('colors.button.icon.secondary.hover.bg')};
      border: 1px solid ${themeGet('colors.button.icon.secondary.hover.border')};
    }

    :enabled:focus,
    :enabled:active {
      background-color: ${themeGet('colors.button.icon.secondary.focus.bg')};
      border: 1px solid ${themeGet('colors.button.icon.secondary.focus.border')};
      box-shadow: ${themeGet('colors.button.icon.secondary.focus.shadow')};
    }

    :disabled {
      background-color: ${themeGet('colors.button.icon.secondary.disabled.bg')};
      border: 1px solid
        ${themeGet('colors.button.icon.secondary.disabled.border')};
      color: ${themeGet('colors.button.icon.secondary.disabled.color')};
    }
  `

const tertiaryCss = ({ tertiary }: { tertiary?: boolean }) =>
  tertiary &&
  css`
    color: ${themeGet('colors.button.icon.tertiary.color')};
    background-color: ${themeGet('colors.button.icon.tertiary.bg')};
    border: 1px solid ${themeGet('colors.button.icon.tertiary.border')};

    :enabled:hover {
      background-color: ${themeGet('colors.button.icon.tertiary.hover.bg')};
      border: 1px solid ${themeGet('colors.button.icon.tertiary.hover.border')};
    }

    :enabled:focus,
    :enabled:active {
      background-color: ${themeGet('colors.button.icon.tertiary.focus.bg')};
      border: 1px solid ${themeGet('colors.button.icon.tertiary.focus.border')};
      box-shadow: ${themeGet('colors.button.icon.tertiary.focus.shadow')};
    }

    :disabled {
      background-color: ${themeGet('colors.button.icon.tertiary.disabled.bg')};
      border: 1px solid
        ${themeGet('colors.button.icon.tertiary.disabled.border')};
      color: ${themeGet('colors.button.icon.tertiary.disabled.color')};
    }
  `

const dangerCss = ({ danger }: { danger?: boolean }) =>
  danger &&
  css`
    color: ${themeGet('colors.button.icon.danger.color')};
    background-color: ${themeGet('colors.button.icon.danger.bg')};
    border: 1px solid ${themeGet('colors.button.icon.danger.border')};

    :enabled:hover {
      background-color: ${themeGet('colors.button.icon.danger.hover.bg')};
      border: 1px solid ${themeGet('colors.button.icon.danger.hover.border')};
    }

    :enabled:focus,
    :enabled:active {
      background-color: ${themeGet('colors.button.icon.danger.focus.bg')};
      border: 1px solid ${themeGet('colors.button.icon.danger.focus.border')};
      box-shadow: ${themeGet('colors.button.icon.danger.focus.shadow')};
    }

    :disabled {
      background-color: ${themeGet('colors.button.icon.danger.disabled.bg')};
      border: 1px solid ${themeGet('colors.button.icon.danger.disabled.border')};
      color: ${themeGet('colors.button.icon.danger.disabled.color')};
    }
  `

const pointerEventAllCss = ({
  pointerEventAll,
}: {
  pointerEventAll?: boolean
}) =>
  pointerEventAll &&
  css`
    pointer-events: all;
  `

export interface IIconButton extends LayoutProps, SpaceProps {
  danger?: boolean
  disabled?: boolean
  large?: boolean
  outline?: boolean
  pointerEventAll?: boolean
  primary?: boolean
  secondary?: boolean
  small?: boolean
  smallSquare?: boolean
  mediumSquare?: boolean
  extraSmall?: boolean
  tertiary?: boolean
  active?: boolean
}

const IconButton = styled.button.attrs(props => ({
  type: props.type || 'button',
}))<IIconButton>`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  cursor: pointer;
  margin: 0;
  user-select: none;
  white-space: nowrap;
  outline: none;
  position: relative;

  height: ${mapToTheme('buttons.height')}px;
  width: ${mapToTheme('buttons.height')}px;
  border-radius: ${mapToTheme('buttons.borderRadius')}px;

  color: ${themeGet('colors.button.basic.primary.color')};
  background-color: ${themeGet('colors.button.basic.primary.bg')};
  border: 1px solid ${themeGet('colors.button.basic.primary.border')};

  :enabled:hover {
    background-color: ${themeGet('colors.button.icon.primary.hover.bg')};
    border: 1px solid ${themeGet('colors.button.icon.primary.hover.border')};
  }

  :enabled:focus,
  :enabled:active {
    background-color: ${themeGet('colors.button.icon.primary.focus.bg')};
    border: 1px solid ${themeGet('colors.button.icon.primary.focus.border')};
    box-shadow: ${themeGet('colors.button.basic.primary.focus.shadow')};
  }

  :disabled {
    background-color: ${themeGet('colors.button.icon.primary.disabled.bg')};
    border: 1px solid ${themeGet('colors.button.icon.primary.disabled.border')};
    cursor: default;
  }

  & > svg {
    flex-shrink: 0;
    stroke-width: 1.5px;
    height: ${mapToTheme('buttons.iconSize')}px;
    width: ${mapToTheme('buttons.iconSize')}px;
  }

  ${layout}
  ${space}

  ${dangerCss}
  ${outlineCss}
  ${secondaryCss}
  ${tertiaryCss}
  ${pointerEventAllCss}

  ${activeCss}
`

export default IconButton
