import { useMemo } from 'react'

import { ActionsCell } from './Cells'

export function useColumns() {
  return useMemo(
    () => [
      {
        Header: 'Name',
        accessor: 'name',
        Cell: renderName,
        width: 1,
      },
      {
        Header: 'Creator',
        accessor: 'creator.profile',
        Cell: renderFullName,
        width: 1,
      },
      {
        Header: 'Description',
        Cell: renderDescription,
        accessor: 'description',
        width: 1,
      },
      {
        Header: 'Active',
        accessor: 'active',
        Cell: renderActive,
        width: 0.5,
      },
      {
        Header: 'Open',
        accessor: 'open',
        Cell: renderOpen,
        width: 0.5,
      },
      {
        Header: 'Actions',
        Cell: ActionsCell,
        width: 0.5,
        cellRight: true,
        headerRight: true,
      },
    ],
    [],
  )
}

function renderName({ value }) {
  return `${value || '—'}`
}

function renderFullName({ value }) {
  return `${value?.firstName || '—'} ${value?.lastName || '—'}`
}

function renderDescription({ value }) {
  return `${value || '—'}`
}

function renderActive({ value }) {
  return `${value || '—'}`
}

function renderOpen({ value }) {
  return `${value || '—'}`
}
