import React, { PropsWithChildren } from 'react'

import { Box } from 'Components/UI'

export interface IContainer extends PropsWithChildren {}

const Container = (props: IContainer) => {
  return (
    <Box maxWidth="420px" width="100%">
      {props.children}
    </Box>
  )
}

export default Container
