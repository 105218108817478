import styled from 'styled-components'
import {
  border,
  BorderProps,
  color,
  ColorProps,
  compose,
  flexbox,
  FlexboxProps,
  layout,
  LayoutProps,
  position,
  PositionProps,
  space,
  SpaceProps,
} from 'styled-system'
import shouldForwardProp from '@styled-system/should-forward-prop'

export interface IBox
  extends SpaceProps,
    ColorProps,
    LayoutProps,
    BorderProps,
    PositionProps,
    FlexboxProps {}

const Box = styled('div').withConfig<IBox>({
  shouldForwardProp,
})`
  ${compose(space, color, layout, border, position, flexbox)}
`

export default Box
