import { ChangeEventHandler, useCallback, useState } from 'react'

import { DEFAULT_MIN_SEARCH_SIZE } from 'Constants/ids'

import { useDebouncedEffect } from './useDebouncedEffect'

export interface ITableSearch {
  minLength?: number
  onSearch: (query: string) => void
}

export default function useTableSearch({
  minLength = DEFAULT_MIN_SEARCH_SIZE,
  onSearch,
}: ITableSearch): [string, ChangeEventHandler<HTMLInputElement>, () => void] {
  const [query, setQuery] = useState('')

  useDebouncedEffect(
    () => {
      if (!query || query?.length >= minLength) onSearch(query)
    },
    [query],
    500,
  )

  const handleChangeSearch = useCallback<ChangeEventHandler<HTMLInputElement>>(
    event => {
      setQuery(event?.target?.value)
    },
    [],
  )

  const handleClearSearch = useCallback(() => {
    setQuery('')
  }, [])

  return [query, handleChangeSearch, handleClearSearch]
}
