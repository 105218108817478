import { FiX } from 'react-icons/fi'

import { down } from 'styled-breakpoints'
import styled from 'styled-components'
import { margin, MarginProps } from 'styled-system'
import { themeGet } from '@styled-system/theme-get'

import { gap, GapProps } from 'Theme/system'

export interface IInnerContainerProps extends GapProps {}

export const InnerContainer = styled.div<IInnerContainerProps>`
  display: flex;
  width: 100%;

  ${down('md')} {
    flex-wrap: wrap;
  }

  ${gap}
`

export const Input = styled.input`
  width: 100%;
  background: ${themeGet('colors.alabaster')};
  border: 1px solid ${themeGet('colors.alto')};
  border-radius: 6px;
  padding: 10px 12px;

  :focus {
    outline: 0;
  }
`

export const Button = styled.button`
  flex-shrink: 0;
  margin: 0;
  background: ${themeGet('colors.primaryCardinal')};
  border-radius: 100px;
  border: 0;
  padding: 10px 20px;
  color: ${themeGet('colors.white')};
  font-weight: ${themeGet('fontWeights.2')};
  font-size: ${themeGet('fontSizes.1')}px;
  cursor: pointer;
`

export interface IItemsContainerProps extends GapProps, MarginProps {}

export const ItemsContainer = styled.div<IItemsContainerProps>`
  width: 100%;
  display: flex;
  flex-wrap: wrap;

  ${gap}
  ${margin}
`

export const Item = styled.div`
  display: flex;
  align-content: center;
  align-items: center;
  background: ${themeGet('colors.primaryWePeep')};
  color: ${themeGet('colors.primaryCardinal')};
  border-radius: 100px;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  padding: 2px 8px;
`

export interface IRemoveIconProps extends MarginProps {}

export const RemoveIcon = styled(FiX)<IRemoveIconProps>`
  width: 14px;
  height: 14px;
  color: ${themeGet('colors.primaryCardinal')};
  stroke-width: 3px;
  cursor: pointer;

  ${margin}
`

export const ErrorWrapper = styled.div`
  color: ${themeGet('colors.error')};
  font-size: 12px;
  padding: 0 12px;
  margin-top: 8px;
  line-height: 1.2;
`
