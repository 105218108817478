import React, { useCallback } from 'react'
import PropTypes from 'prop-types'

import { useMutation } from '@apollo/client'
import { IconCheck } from '@tabler/icons-react'
import rejectProposalMutation from 'GraphQL/Mutations/Proposal/rejectProposal.graphql'
import { entityCacheRemover } from 'GraphQL/Updaters/Common'
import { rejectProposalUpdater } from 'GraphQL/Updaters/Proposal'

import { LinkButton } from 'Components/UI'

import _ from 'Services/I18n'
import toast from 'Services/Toast'

import { useTableContext } from '../context'

function Reject({ row }) {
  const { pageSize } = useTableContext()

  const [rejectProposal] = useMutation(rejectProposalMutation)

  const proposal = row?.original

  const handleRejectProposal = useCallback(async () => {
    try {
      await rejectProposal({
        variables: {
          id: proposal.id,
        },
        update: store => {
          entityCacheRemover({
            entity: proposal,
            pageSize,
            queryKey: 'proposals',
          })(store)
          rejectProposalUpdater(proposal)(store)
        },
      })

      toast.success({
        title: 'Proposal',
        text: 'Proposal successfully rejected',
      })
    } catch (error) {
      toast.error({
        title: 'Proposal',
        text: _(`error.${error?.message || 'generic'}`),
      })
    }
  }, [rejectProposal, proposal, pageSize])

  return (
    <LinkButton danger onClick={handleRejectProposal}>
      <IconCheck />
      Reject
    </LinkButton>
  )
}

Reject.defaultProps = {
  row: {},
}

Reject.propTypes = {
  row: PropTypes.object,
}

export default Reject
