import React from 'react'
import { components } from 'react-select'

import { MenuListWrapper } from './styles'

const MenuList: typeof components.MenuList = props => {
  // TODO: find a better way to satisfy the type
  const StyledMenuListWrapper = MenuListWrapper as any
  return <StyledMenuListWrapper {...props} as={components.MenuList} />
}

export default MenuList
