import React from 'react'

import { useSlate } from 'slate-react'
import { ElementType } from 'Types/slate'

import { insertLink, unwrapLink } from './plugins/withLinks'
import { Button } from './styles'
import { isElementActive, toggleElement } from './utils'

export interface IElementButtonProps {
  className?: string
  type: ElementType
  icon: React.ReactNode
}

function ElementButton({ type, icon, className }: IElementButtonProps) {
  const editor = useSlate()
  const active = isElementActive(editor, type)

  return (
    <Button
      active={active}
      onMouseDown={event => {
        event.preventDefault()
        if (type === ElementType.Link) {
          if (!active) {
            // eslint-disable-next-line no-alert
            const url = window.prompt('Enter the URL of the link:')
            if (!url) return
            insertLink(editor, url, className)
          } else {
            unwrapLink(editor)
          }
        } else {
          toggleElement(editor, type)
        }
      }}
    >
      {icon}
    </Button>
  )
}

export default ElementButton
