import React, { useCallback, useEffect, useRef, useState } from 'react'
import { CopyToClipboard } from 'react-copy-to-clipboard'

import generateMagicLinkMutation from 'GraphQL/Mutations/Admin/generateMagicLink.graphql'

import { Button } from 'Components/UI'

import { FRONTEND } from 'Config'

import * as ROUTES from 'Router/routes'

import { useAdminMutation } from 'Services/Apollo'
import _ from 'Services/I18n'
import toast from 'Services/Toast'

import { Container } from './styles'

export interface IGenerateMagicLink {
  user: MainSchema.User
}

function GenerateMagicLink({ user }: IGenerateMagicLink) {
  const timeout = useRef<NodeJS.Timeout | null>(null)

  const [token, setToken] = useState<string | null>(null)
  const [copied, setCopied] = useState(false)

  const [generateMagicLink] = useAdminMutation(generateMagicLinkMutation)

  const handleGenerate = useCallback(async () => {
    try {
      const response = await generateMagicLink({
        variables: { userId: user.id },
      })
      setToken(response.data?.generateMagicLink?.token)
    } catch (error) {
      let message = _(`error.generic`)

      if (error instanceof Error) {
        message = error.message
      }

      toast.error({
        title: 'Oops...',
        text: message,
      })
    }
  }, [user, generateMagicLink])

  const handleCopy = useCallback(() => {
    setCopied(true)

    timeout.current = setTimeout(() => {
      setCopied(false)
    }, 1000)
  }, [])

  useEffect(
    () => () => {
      if (!timeout.current) {
        return
      }

      clearTimeout(timeout.current)
    },
    [],
  )

  const link = `${FRONTEND.URL}${ROUTES.AUTH_CHECK_MAGIC_LINK}?token=${token}`

  return (
    <Container>
      {token ? (
        <CopyToClipboard text={link} onCopy={handleCopy}>
          <span>
            {link}
            <br />
            <small>(click to copy)</small>
          </span>
        </CopyToClipboard>
      ) : (
        <Button small onClick={handleGenerate}>
          Generate Magic Link
        </Button>
      )}

      {copied && <div>Copied!</div>}
    </Container>
  )
}

export default GenerateMagicLink
