import React from 'react'

import { IconSquareRounded } from '@tabler/icons-react'

import { Column, Row, Select, Text } from 'Components/UI'

const OPTIONS = [
  { value: 1, label: 'Option 1' },
  { value: 2, label: 'Option 2' },
  { value: 3, label: 'Option 3' },
  { value: 4, label: 'Option 4' },
]

function Selects() {
  return (
    <Column gap={6}>
      <Text header1>Selects</Text>
      <Row fullWidth gap={5}>
        <Select
          caption="Caption"
          label="Default"
          options={OPTIONS}
          placeholder="Placeholder"
          renderBeforeElement={() => <IconSquareRounded />}
          width={250}
        />
        <Select
          caption="Caption"
          clearable
          infoText="Additional information"
          isSearchable={false}
          label="Not searchable, clearable"
          options={OPTIONS}
          placeholder="Placeholder"
          renderBeforeElement={() => <IconSquareRounded />}
          width={250}
        />
        <Select
          caption="Caption"
          infoText="Additional information"
          isMulti
          label="Multiselect"
          options={OPTIONS}
          placeholder="Placeholder"
          renderBeforeElement={() => <IconSquareRounded />}
          width={250}
        />
        <Select
          caption="Caption"
          creatable
          infoText="Additional information"
          label="Creatable"
          options={OPTIONS}
          placeholder="Placeholder"
          renderBeforeElement={() => <IconSquareRounded />}
          width={250}
        />
      </Row>
      <Row fullWidth gap={5}>
        <Select
          async
          caption="Caption"
          isLoading
          label="Loading"
          placeholder="Placeholder"
          width={250}
        />
        <Select
          caption="Caption"
          disabled
          infoText="Additional information"
          label="Disabled"
          placeholder="Placeholder"
          width={250}
        />
      </Row>
    </Column>
  )
}

export default Selects
