import React from 'react'

import loadable from '@loadable/component'

import { Loader } from 'Components/UI'

const params = {
  fallback: <Loader absolute />,
}

const Auth = {
  CheckMagicLink: loadable(
    () =>
      import(
        './CheckMagicLink' /* webpackChunkName: "auth-check-magic-link" */
      ),
    params,
  ),
  SignIn: loadable(
    () => import('./SignIn' /* webpackChunkName: "auth-sign-in" */),
  ),
  SignUp: loadable(
    () => import('./SignUp' /* webpackChunkName: "auth-sign-up" */),
  ),
}

export default Auth
