import React, { useCallback, useMemo } from 'react'
import PropTypes from 'prop-types'

import { useMutation } from '@apollo/client'
import {
  IconAt,
  IconBrandFacebook,
  IconBrandLinkedin,
  IconBrandTwitter,
  IconBulb,
  IconCalendarEvent,
  IconCheck,
  IconId,
  IconMail,
  IconPhone,
  IconPuzzle,
  IconSearch,
  IconTag,
  IconUsers,
  IconX,
} from '@tabler/icons-react'
import acceptProposalMutation from 'GraphQL/Mutations/Proposal/acceptProposal.graphql'
import rejectProposalMutation from 'GraphQL/Mutations/Proposal/rejectProposal.graphql'
import proposalQuery from 'GraphQL/Queries/Proposals/proposal.graphql'
import {
  acceptProposalUpdater,
  rejectProposalUpdater,
} from 'GraphQL/Updaters/Proposal'
import { getFullName } from 'Utils/User'

import upperFirst from 'lodash/upperFirst'

import { Divider, LinkButton, Loader, Row, Text } from 'Components/UI'

import { PROPOSAL_KIND, PROPOSAL_STATUS } from 'Constants/ids'

import { useAppContext } from 'Hooks'

import { useQuery } from 'Services/Apollo'
import _, { useScopedI18n } from 'Services/I18n'
import toast from 'Services/Toast'

import { Content } from './styles'

const ICONS = {
  [PROPOSAL_KIND.CONNECTION]: <IconAt />,
  [PROPOSAL_KIND.CUSTOM]: <IconTag />,
  [PROPOSAL_KIND.EMAIL]: <IconMail />,
  [PROPOSAL_KIND.EVENT]: <IconCalendarEvent />,
  [PROPOSAL_KIND.GROUP]: <IconUsers />,
  [PROPOSAL_KIND.NEED_SKILL]: <IconSearch />,
  [PROPOSAL_KIND.PHONE]: <IconPhone />,
  [PROPOSAL_KIND.PROJECT]: <IconBulb />,
  [PROPOSAL_KIND.ROLE]: <IconId />,
  [PROPOSAL_KIND.SKILL]: <IconPuzzle />,
  [PROPOSAL_KIND.TWITTER_URL]: <IconBrandTwitter />,
  [PROPOSAL_KIND.FACEBOOK_URL]: <IconBrandFacebook />,
  [PROPOSAL_KIND.LINKEDIN_URL]: <IconBrandLinkedin />,
}

function ProposalDetails({ proposalId }) {
  const s = useScopedI18n('blocks.tables.notificationsTable')

  const { refetchMe } = useAppContext()

  const { data, loading } = useQuery(proposalQuery, {
    skip: !proposalId,
    fetchPolicy: 'network-only',
    variables: {
      id: proposalId,
    },
  })

  const [acceptProposal] = useMutation(acceptProposalMutation)
  const [rejectProposal] = useMutation(rejectProposalMutation)

  const proposal = data?.proposal

  const handleAcceptProposal = useCallback(async () => {
    try {
      await acceptProposal({
        variables: {
          id: proposal.id,
        },
        update: acceptProposalUpdater(proposal),
      })

      refetchMe()

      toast.success({
        title: s('messages.proposal.title'),
        text: s('messages.proposal.accept'),
      })
    } catch (error) {
      toast.error({
        title: 'Proposal',
        text: _(`error.${error?.message || 'generic'}`),
      })
    }
  }, [acceptProposal, proposal, s, refetchMe])

  const handleRejectProposal = useCallback(async () => {
    try {
      await rejectProposal({
        variables: {
          id: proposal.id,
        },
        update: rejectProposalUpdater(proposal),
      })

      toast.success({
        title: s('messages.proposal.title'),
        text: s('messages.proposal.reject'),
      })
    } catch (error) {
      toast.error({
        title: s('messages.proposal.title'),
        text: _(`error.${error?.message || 'generic'}`),
      })
    }
  }, [rejectProposal, proposal, s])

  const name = useMemo(() => {
    switch (proposal?.kind) {
      case PROPOSAL_KIND.SKILL:
      case PROPOSAL_KIND.NEED_SKILL:
        return proposal?.skill?.name
      case PROPOSAL_KIND.CONNECTION:
        return getFullName(proposal?.proposedUser?.profile)
      default:
        return proposal?.tag?.name ?? proposal?.value
    }
  }, [proposal])

  const icon = ICONS[proposal?.kind] ?? null

  const kind = useMemo(() => {
    if (proposal?.kind === PROPOSAL_KIND.FACEBOOK_URL) return 'Facebook'
    if (proposal?.kind === PROPOSAL_KIND.TWITTER_URL) return 'Twitter'
    if (proposal?.kind === PROPOSAL_KIND.LINKEDIN_URL) return 'LinkedIn'

    return upperFirst(proposal?.kind)
  }, [proposal])

  if (!proposal) return null

  if (loading)
    return (
      <Row fullWidth justifyCenter wrapped>
        <Loader />
      </Row>
    )

  return (
    <Row fullWidth pb={3} px={5} wrapped>
      <Divider mb={3} />

      <Content>
        {icon}

        <Text bodyMedium header ml={1}>
          {kind}:{' '}
        </Text>

        <Text header header4 ml="2px">
          {name}
        </Text>

        {proposal?.status === PROPOSAL_STATUS.PENDING && (
          <Row gap={6} ml="auto">
            <LinkButton success onClick={handleAcceptProposal}>
              <IconCheck size={16} />
              {s('approve')}
            </LinkButton>

            <LinkButton danger onClick={handleRejectProposal}>
              <IconX size={16} />
              {s('reject')}
            </LinkButton>
          </Row>
        )}
      </Content>
    </Row>
  )
}

ProposalDetails.defaultProps = {
  proposalId: null,
}

ProposalDetails.propTypes = {
  proposalId: PropTypes.string,
}
export default ProposalDetails
