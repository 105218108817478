import React from 'react'
import { components } from 'react-select'

import { ControlWrapper } from './styles'

const Control: typeof components.Control = props => {
  // TODO: find a better way to satisfy the type
  const StyledControlWrapper = ControlWrapper as any
  return <StyledControlWrapper {...props} as={components.Control} />
}

export default Control
