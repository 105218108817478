import React, { ReactNode } from 'react'

import { IconCircleCheck, IconInfoCircle } from '@tabler/icons-react'

import colors from 'Theme/_v2/colors'

import { Text } from '../_v2'
import Box from '../Box'
import { Column, Row } from '../Flex'

enum MessageType {
  Success = 'success',
  Error = 'error',
}

export interface IMessage {
  type: `${MessageType}`
  heading: ReactNode
  description?: ReactNode
}

const Message = ({ type, heading, description }: IMessage) => {
  const backgroundColor =
    type === MessageType.Success ? colors.main.success05 : colors.main.error05
  const IconComponent =
    type === MessageType.Success ? IconCircleCheck : IconInfoCircle
  const iconColor =
    type === MessageType.Success ? colors.main.success : colors.main.error

  return (
    <Box
      alignItems="center"
      backgroundColor={backgroundColor}
      borderRadius={4}
      display="flex"
      flexDirection="column"
      mb={7}
      p={3}
      width="100%"
    >
      <Column gap={1} maxWidth="300px">
        <Row center gap={1} justifyCenter>
          <IconComponent color={iconColor} size={16} />
          <Text
            color={colors.text.primary}
            fontWeight={600}
            textAlign="center"
            variant="body-s"
          >
            {heading}
          </Text>
        </Row>
        {description && (
          <Text color={colors.text.primary} textAlign="center" variant="body-s">
            {description}
          </Text>
        )}
      </Column>
    </Box>
  )
}

export default Message
