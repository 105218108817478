import styled from 'styled-components'

export const Heading = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
`

export const HeadingLabel = styled.div`
  font-size: 12px;
  font-weight: 400;
  text-transform: uppercase;
  line-height: 20px;
  color: #757575;
`

export const HeadingBadge = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 10px;
  font-weight: 500;
  line-height: 100%;
  text-transform: uppercase;
  color: #757575;
  border-radius: 100px;
  background: #f0f0f0;
  padding-inline: 4px;
  height: 12px;
  min-width: 16px;
`
