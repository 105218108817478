import styled, { css } from 'styled-components'
import { themeGet } from '@styled-system/theme-get'

import { IconCornerRightUp, IconEye, IconLock } from '@tabler/icons-react'

export const Container = styled.div`
  display: flex;
  height: 100%;
  overflow: hidden;
`

export interface IGreenIconSharedCssProps {
  green?: boolean
}

const greenIconSharedCss = ({ green }: IGreenIconSharedCssProps) =>
  green &&
  css`
    color: ${themeGet('colors.greenLight')};
  `

export const IconPrivate = styled(IconLock)``
export const IconPublic = styled(IconEye)``
export const IconShared = styled(IconCornerRightUp)`
  ${greenIconSharedCss}
`
