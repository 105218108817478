import { createContext, useContext } from 'react'

export interface ITableContext {
  onDeleteNote: (noteId: string) => Promise<void>
  onSelectAll: React.ChangeEventHandler<HTMLInputElement>
  onSelectNote: (note: MainSchema.Note) => void
  isSelectedAll: boolean
  selectedNotesIds: string[]
  onUpdateFavorite: (noteId: string, isFavorite: boolean) => Promise<void>
  onReadNote: React.Dispatch<React.SetStateAction<MainSchema.Note | null>>
}

const TableContext = createContext<ITableContext>({
  onDeleteNote: async () => {
    return Promise.resolve()
  },
  onSelectAll: () => {},
  onSelectNote: () => {},
  isSelectedAll: false,
  selectedNotesIds: [],
  onUpdateFavorite: async () => {
    return Promise.resolve()
  },
  onReadNote: () => {},
})

export function useTableContext() {
  return useContext(TableContext)
}

export default TableContext
