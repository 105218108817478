import { useCallback, useState } from 'react'

import { useApolloClient } from '@apollo/client'
import geCommunityUserQuery from 'GraphQL/Queries/CommunityUser/getCommunityUser.graphql'
import listCommunityUsersQuery from 'GraphQL/Queries/CommunityUser/listCommunityUsers.full.graphql'
import GetAskOfferQuery from 'GraphQL/Queries/GetAskOffer.graphql'
import { unparse } from 'papaparse'

import { AskOfferStatementKind } from 'Constants/mainGraphQL'

import toast from 'Services/Toast'

interface Props {
  communityIds: string[]
  selectedUsers: MainSchema.CommunityUser[]
}

export function useExportUsers({ communityIds, selectedUsers }: Props) {
  const client = useApolloClient()
  const [isExporting, setIsExporting] = useState(false)
  const [tsvBlob, setTsvBlob] = useState<Blob | null>(null)

  const exportUsers = useCallback(async () => {
    if (communityIds.length === 0) {
      toast.error({
        title: 'Export Failed',
        text: 'Invalid community ID(s).',
      })
      return
    }

    setIsExporting(true)
    try {
      let communityUsers: MainSchema.CommunityUser[] = []

      if (selectedUsers.length > 0) {
        communityUsers = selectedUsers
      } else {
        const limit = 100
        let currentPage = 0
        let totalPages = 1

        const initialResponse = await client.query<
          Pick<MainSchema.Query, 'listCommunityUsers'>,
          MainSchema.QueryListCommunityUsersArgs
        >({
          query: listCommunityUsersQuery,
          variables: {
            communityIds,
            page: currentPage,
            limit,
            showArchived: true,
          },
          fetchPolicy: 'no-cache',
        })

        const initialData = initialResponse.data?.listCommunityUsers
        const count = initialData?.count || 0
        totalPages = Math.ceil(count / limit)

        communityUsers = [...(initialData?.communityUsers || [])]

        currentPage += 1

        while (currentPage <= totalPages) {
          const { data: fetchedData } = await client.query({
            query: listCommunityUsersQuery,
            variables: {
              communityIds,
              page: currentPage,
              limit,
              showArchived: true,
            },
            fetchPolicy: 'no-cache',
          })

          const fetchedCommunityUsers =
            fetchedData?.listCommunityUsers?.communityUsers || []
          communityUsers.push(...fetchedCommunityUsers)
          currentPage += 1
        }
      }

      const userProfiles = await Promise.all(
        communityUsers.map(async communityUser => {
          const { data: askOffersQuery } = await client.query<
            Pick<MainSchema.Query, 'getAskOffers'>
          >({
            query: GetAskOfferQuery,
            variables: {
              communityUserId: communityUser.id,
            },
            context: {
              headers: {
                'x-community-id': communityIds[0],
              },
            },
          })

          const { data } = await client.query<
            Pick<MainSchema.Query, 'getCommunityUser'>,
            MainSchema.QueryGetCommunityUserArgs
          >({
            query: geCommunityUserQuery,
            variables: {
              communityIds,
              communityUserId: communityUser.id,
            },
            fetchPolicy: 'no-cache',
          })

          const asks = askOffersQuery.getAskOffers.filter(
            askOffers => askOffers.kind === AskOfferStatementKind.Ask,
          )
          const offers = askOffersQuery.getAskOffers.filter(
            askOffers => askOffers.kind === AskOfferStatementKind.Offer,
          )

          return {
            communityUser: data?.getCommunityUser,
            asks,
            offers,
          }
        }),
      )

      const rows = userProfiles.map(userProfile => ({
        userId: userProfile.communityUser?.userId,
        firstName: userProfile.communityUser?.firstName,
        lastName: userProfile.communityUser?.lastName,
        email: userProfile.communityUser?.email,
        linkedInUrl: userProfile.communityUser?.linkedInUrl,
        phoneNumber: userProfile.communityUser?.phoneNumber,
        workHistory: userProfile.communityUser?.communityUserWorkHistory?.map(
          userWorkHistory =>
            `${userWorkHistory.jobTitle?.name}, ${userWorkHistory.organization?.name}`,
        ),
        educationHistory:
          userProfile.communityUser?.communityUserEducationHistory?.map(
            eduHistory => eduHistory.organization?.name,
          ),
        tags: userProfile.communityUser?.communityUserTags?.map(
          userTag => userTag.tag?.name,
        ),
        asks: userProfile.asks.map(ask => ask.statement),
        offers: userProfile.offers.map(offer => offer.statement),
      }))

      const tsv = unparse(rows, { delimiter: '\t' })
      const blob = new Blob([tsv], {
        type: 'text/tab-separated-values;charset=utf-8;',
      })
      setTsvBlob(blob)
    } catch (error: any) {
      toast.error({
        title: 'Export Failed',
        text: error.message,
      })
    } finally {
      setIsExporting(false)
    }
  }, [client, communityIds, selectedUsers])

  const saveFile = (filename: string) => {
    if (!tsvBlob) return

    const url = URL.createObjectURL(tsvBlob)
    const link = document.createElement('a')

    link.href = url
    link.setAttribute('download', `${filename}.tsv`)
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
    URL.revokeObjectURL(url)

    setTsvBlob(null)
  }

  return { isExporting, exportUsers, saveFile }
}
