import React, { useState } from 'react'

import { useEventBusSubscribe } from 'Hooks'

import EventBus from 'Services/EventBus'

import {
  GraphTooltipArrow,
  GraphTooltipContainer,
  GraphTooltipContent,
} from './styles'

export interface IToolTip {
  message: string | null
  x?: number
  y?: number
  enabled?: boolean
}

function GraphTooltip() {
  const [tooltip, setTooltip] = useState<IToolTip>({
    message: null,
    x: 0,
    y: 0,
  })

  useEventBusSubscribe(EventBus.actions.graph.setTooltip, (tip: IToolTip) => {
    setTooltip(tip)
  })

  if (!tooltip.message) {
    return <div />
  }

  return (
    <GraphTooltipContainer x={tooltip.x!} y={tooltip.y!}>
      <GraphTooltipArrow />
      <GraphTooltipContent enabled={tooltip.enabled}>
        {tooltip.message}
      </GraphTooltipContent>
    </GraphTooltipContainer>
  )
}

export default GraphTooltip
