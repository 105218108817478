import React, { useCallback, useRef, useState } from 'react'

import { FiX } from 'react-icons/fi'

import { omit, pick } from '@styled-system/props'

import { Close, Container, StyledTextArea } from './styles'

import { ErrorWrapper, IWrapperProps, Wrapper } from '../styles'

export interface TextAreaProps extends IWrapperProps {
  autoComplete?: 'on' | 'off'
  autoFocus?: boolean
  defaultValue?: string
  disabled?: boolean
  error?: string | null
  fullWidth?: boolean
  hasClear?: boolean
  icon?: React.ReactNode
  label?: string
  rounded?: boolean
  onChange?: React.ChangeEventHandler<HTMLTextAreaElement>
  onClear?: () => void
  placeholder?: string
  value?: string | number
}

function TextArea({
  autoComplete = 'on',
  autoFocus = false,
  defaultValue = '',
  disabled = false,
  fullWidth = false,
  error,
  icon,
  label,
  value,
  hasClear = true,
  onChange,
  onClear,
  rounded = false,
  ...rest
}: TextAreaProps) {
  const textareaRef = useRef<HTMLTextAreaElement | null>(null)
  const [innerValue, setInnerValue] = useState(defaultValue)
  const styledProps = pick(rest)
  const inputProps = omit(rest)

  const hasValue = !!value || !!innerValue

  const handleChange = onChange || (({ target }) => setInnerValue(target.value))

  const handleClear = useCallback(() => {
    if (textareaRef.current) textareaRef.current.value = ''
    setInnerValue('')

    onClear?.()
  }, [onClear])

  return (
    <Wrapper fullWidth={fullWidth} {...styledProps}>
      <Container
        fullWidth={fullWidth}
        hasValue={hasValue}
        rounded={rounded}
        withError={!!error}
        withIcon={!!icon}
        withLabel={!!label}
      >
        {icon}

        <StyledTextArea
          ref={textareaRef}
          {...inputProps}
          autoComplete={autoComplete}
          autoFocus={autoFocus}
          disabled={disabled}
          placeholder={inputProps.placeholder}
          value={value || innerValue}
          onChange={handleChange}
        />

        {hasValue && !disabled && hasClear && (
          <Close onClick={handleClear}>
            <FiX size={14} />
          </Close>
        )}
      </Container>

      {error && <ErrorWrapper>{error}</ErrorWrapper>}
    </Wrapper>
  )
}

export default TextArea
