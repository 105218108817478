export function topSkillsToValue(topSkills: MainSchema.TopSkill[] | undefined) {
  return topSkills?.map(item => ({
    name: item.skill,
    value: item.users,
  }))
}

export function topSearchedSkillsToValue(
  topSkills: MainSchema.TopSearchedSkill[] | undefined,
) {
  return topSkills?.map(item => ({
    name: item.skill,
    value: item.count,
  }))
}
