import first from 'lodash/first'
import sortBy from 'lodash/sortBy'

export function getOrganizationLogoUrl(
  organizationLogos?: MainSchema.OrganizationLogo[],
): string | null {
  if (!organizationLogos || organizationLogos.length === 0) {
    return null
  }

  const sortedLogosByWidth = sortBy(
    organizationLogos ?? [],
    logo => logo.width,
    'asc',
  )

  return first(sortedLogosByWidth)?.url ?? null
}
