import { down } from 'styled-breakpoints'
import styled from 'styled-components'
import { margin, MarginProps } from 'styled-system'
import { themeGet } from '@styled-system/theme-get'

export const Container = styled.div`
  display: flex;
  position: absolute;
  bottom: 110px;
  width: 100%;

  ${down('xl')} {
    bottom: 60px;
    padding: 0 ${themeGet('space.4')}px;
  }
`

export const Inner = styled.div`
  display: flex;
  background: ${themeGet('colors.white')};
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.07);
  border-radius: 6px;
  padding: ${themeGet('space.4')}px ${themeGet('space.5')}px;
  margin: 0 auto;

  ${down('xl')} {
    flex-wrap: wrap;
    padding: ${themeGet('space.4')}px;
  }
`

export interface IUserWrapperProps extends MarginProps {}

export const UserWrapper = styled.div<IUserWrapperProps>`
  display: flex;
  align-items: center;

  ${down('xl')} {
    width: 50%;
    flex-shrink: 0;
    align-items: start;
    flex-direction: column;
  }

  ${margin}
`

export interface IDashedCircleProps extends MarginProps {}

export const DashedCircle = styled.div<IDashedCircleProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  background: ${themeGet('colors.athensGray')};
  border: 1px dashed ${themeGet('colors.alto')};
  border-radius: 50%;
  color: ${themeGet('colors.silver')};
  cursor: pointer;

  :hover {
    background: ${themeGet('colors.primaryWePeep')};
    border: 1px dashed ${themeGet('colors.primaryCardinal')};

    > svg {
      color: ${themeGet('colors.primaryCardinal')};
    }
  }

  ${margin}
`

export interface IUserContainerProps extends MarginProps {}

export const UserContainer = styled.div<IUserContainerProps>`
  display: flex;
  align-items: center;

  ${margin}
`

export const SearchContainer = styled.div`
  width: 280px;
  padding: ${themeGet('space.3')}px;
`
