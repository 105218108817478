import React from 'react'
import { components } from 'react-select'

import { GroupWrapper } from './styles'

const Group: typeof components.Group = props => {
  // TODO: find a better way to satisfy the type
  const StyledGroupWrapper = GroupWrapper as any
  return <StyledGroupWrapper {...props} as={components.Group} />
}

export default Group
