import { htmlRegex, mentionRegex } from 'Constants/regex'

export const notesReplacers = [
  // NOTE: higher priority value means replacer will be applied first
  {
    regex: mentionRegex,
    priority: 0,
    render: (replace: RegExpMatchArray) => `${replace[2]}`,
  },
  {
    regex: htmlRegex,
    priority: 0,
    render: () => ``,
  },
]
