import styled, { css } from 'styled-components'
import { mapToTheme } from 'styled-map'
import {
  flexbox,
  FlexboxProps,
  layout,
  LayoutProps,
  space,
  SpaceProps,
} from 'styled-system'
import { themeGet } from '@styled-system/theme-get'

const secondaryCss = ({ secondary }: { secondary?: boolean }) =>
  secondary &&
  css`
    color: ${themeGet('colors.button.text.secondary.color')};
    background-color: ${themeGet('colors.button.text.secondary.bg')};
    border: 1px solid ${themeGet('colors.button.text.secondary.border')};

    :enabled:hover {
      background-color: ${themeGet('colors.button.text.secondary.hover.bg')};
      border: 1px solid ${themeGet('colors.button.text.secondary.hover.border')};
    }

    :enabled:focus,
    :enabled:active {
      background-color: ${themeGet('colors.button.text.secondary.focus.bg')};
      border: 1px solid ${themeGet('colors.button.text.secondary.focus.border')};
      box-shadow: ${themeGet('colors.button.text.secondary.focus.shadow')};
    }

    :disabled {
      background-color: ${themeGet('colors.button.text.secondary.disabled.bg')};
      border: 1px solid
        ${themeGet('colors.button.text.secondary.disabled.border')};
      color: ${themeGet('colors.button.text.secondary.disabled.color')};
    }
  `

const dangerCss = ({ danger }: { danger?: boolean }) =>
  danger &&
  css`
    color: ${themeGet('colors.button.text.danger.color')};
    background-color: ${themeGet('colors.button.text.danger.bg')};
    border: 1px solid ${themeGet('colors.button.text.danger.border')};

    :enabled:hover {
      background-color: ${themeGet('colors.button.text.danger.hover.bg')};
      border: 1px solid ${themeGet('colors.button.text.danger.hover.border')};
    }

    :enabled:focus,
    :enabled:active {
      background-color: ${themeGet('colors.button.text.danger.focus.bg')};
      border: 1px solid ${themeGet('colors.button.text.danger.focus.border')};
      box-shadow: ${themeGet('colors.button.text.danger.focus.shadow')};
    }

    :disabled {
      background-color: ${themeGet('colors.button.text.danger.disabled.bg')};
      border: 1px solid ${themeGet('colors.button.text.danger.disabled.border')};
      color: ${themeGet('colors.button.text.danger.disabled.color')};
    }
  `

export interface ITextButton extends FlexboxProps, LayoutProps, SpaceProps {
  danger?: boolean
  disabled?: boolean
  large?: boolean
  primary?: boolean
  secondary?: boolean
  small?: boolean
}

const TextButton = styled.button.attrs(props => ({
  type: props.type || 'button',
}))<ITextButton>`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin: 0;
  user-select: none;
  white-space: nowrap;
  outline: none;

  height: ${mapToTheme('buttons.height')}px;
  min-width: ${mapToTheme('buttons.minWidth')}px;
  border-radius: ${mapToTheme('buttons.borderRadius')}px;
  font-weight: ${themeGet('fontWeights.2')};
  font-size: ${mapToTheme('buttons.fontSize')}px;
  padding-inline: ${themeGet('space.5')}px;
  gap: ${themeGet('space.2')}px;

  color: ${themeGet('colors.button.text.primary.color')};
  background-color: ${themeGet('colors.button.text.primary.bg')};
  border: 1px solid ${themeGet('colors.button.text.primary.border')};

  :enabled:hover {
    background-color: ${themeGet('colors.button.text.primary.hover.bg')};
    border: 1px solid ${themeGet('colors.button.text.primary.hover.border')};
  }

  :enabled:focus,
  :enabled:active {
    background-color: ${themeGet('colors.button.text.primary.focus.bg')};
    border: 1px solid ${themeGet('colors.button.text.primary.focus.border')};
    box-shadow: ${themeGet('colors.button.text.primary.focus.shadow')};
  }

  :disabled {
    background-color: ${themeGet('colors.button.text.primary.disabled.bg')};
    border: 1px solid ${themeGet('colors.button.text.primary.disabled.border')};
    color: ${themeGet('colors.button.text.primary.disabled.color')};
    cursor: default;
  }

  & > svg {
    flex-shrink: 0;
    height: ${mapToTheme('buttons.iconSize')}px;
    width: ${mapToTheme('buttons.iconSize')}px;
  }

  ${flexbox}
  ${layout}
  ${space}

  ${secondaryCss}
  ${dangerCss}
`

export default TextButton
