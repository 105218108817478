import React from 'react'

import { IconX } from '@tabler/icons-react'
import { formatAddress } from 'Utils/Location'
import { getOrganizationLogoUrl } from 'Utils/Organization'

import { Box, Column, Divider, Icon, Row } from 'Components/UI'
import { ConditionalLink, Text } from 'Components/UI/_v2'

import { useScopedI18n } from 'Services/I18n'

import colors from 'Theme/_v2/colors'

import * as Styled from './OrganizationPanel.styles'
import { IOrganizationNode } from './utils'

export interface OrganizationPanelProps {
  organization: IOrganizationNode
  onClose: React.MouseEventHandler<HTMLButtonElement>
}

export default function OrganizationPanel({
  organization,
  onClose,
}: OrganizationPanelProps) {
  const t = useScopedI18n('components.blocks.graph.organizationPanel')

  const logoUrl = getOrganizationLogoUrl(organization.logos)

  return (
    <Styled.Container>
      <Box pb={3} pt={4} px={4}>
        <Styled.CloseButton title={t('close.title')} onClick={onClose}>
          <IconX display="block" size={12} />
        </Styled.CloseButton>

        <Row alignItems={'center'} gap={2}>
          <Styled.Image>
            <img
              alt={t('logoUrl.alt', {
                organizationName: organization.name,
              })}
              src={logoUrl || '/img/nodes/organization.svg'}
            />
          </Styled.Image>

          <Column flex={1} gap={2}>
            <Text
              color={colors.text.primary}
              fontSize={'16px'}
              fontWeight={600}
              lineHeight={1}
            >
              {organization.name}
            </Text>

            <Row alignItems="center" gap={3}>
              <ConditionalLink
                condition={!!organization.linkedInUrl}
                href={organization.linkedInUrl}
                isExternal
                target="_blank"
                title={t('linkedInUrl.title', {
                  organizationName: organization.name,
                })}
              >
                <Icon.LinkedIn
                  color={
                    !organization.linkedInUrl
                      ? colors.social.disabled
                      : undefined
                  }
                  size={14}
                />
              </ConditionalLink>

              <ConditionalLink
                condition={!!organization.twitterUrl}
                href={organization.twitterUrl}
                isExternal
                target="_blank"
                title={t('twitterUrl.title', {
                  organizationName: organization.name,
                })}
              >
                <Icon.Twitter
                  color={
                    !organization.twitterUrl
                      ? colors.social.disabled
                      : undefined
                  }
                  size={14}
                />
              </ConditionalLink>

              <ConditionalLink
                condition={!!organization.facebookUrl}
                href={organization.facebookUrl}
                isExternal
                target="_blank"
                title={t('facebookUrl.title', {
                  organizationName: organization.name,
                })}
              >
                <Icon.Facebook
                  color={
                    !organization.facebookUrl
                      ? colors.social.disabled
                      : undefined
                  }
                  size={14}
                />
              </ConditionalLink>

              <ConditionalLink
                condition={!!organization.websiteUrl}
                href={organization.websiteUrl}
                isExternal
                target="_blank"
                title={t('websiteUrl.title', {
                  organizationName: organization.name,
                })}
              >
                <Icon.Website
                  color={
                    !organization.websiteUrl
                      ? colors.social.disabled
                      : undefined
                  }
                  size={14}
                />
              </ConditionalLink>
            </Row>
          </Column>
        </Row>
      </Box>

      {(!!organization.description || !!organization.location) && (
        <>
          <Divider />

          <Box pb={4} pt={3} px={4}>
            <Column gap={3}>
              {!!organization.description && (
                <Text
                  color={'#000000'}
                  variant="body-xs"
                  whiteSpace={'pre-line'}
                >
                  {organization.description}
                </Text>
              )}

              {!!organization.location && (
                <Column gap={1}>
                  <Row alignItems={'center'} gap={1}>
                    <Styled.LocationIcon
                      color={'#757575'}
                      height={12}
                      width={8}
                    />

                    <Text
                      color={colors.text.primary}
                      fontSize={'11px'}
                      fontWeight={600}
                      lineHeight={'16px'}
                    >
                      {t('location.label')}
                    </Text>
                  </Row>

                  <Text
                    color={colors.text.primary}
                    fontSize={'12px'}
                    fontWeight={400}
                    lineHeight={'16px'}
                    pl={3}
                    whiteSpace={'pre-line'}
                  >
                    {formatAddress(organization.location)}
                  </Text>
                </Column>
              )}
            </Column>
          </Box>
        </>
      )}
    </Styled.Container>
  )
}
