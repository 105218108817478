import React, { useCallback, useState } from 'react'
import { MultiValueRemoveProps } from 'react-select'
import { wrapMenuList } from 'react-select-async-paginate'

import { pick } from '@styled-system/props'

import { useApolloClient } from '@apollo/client'
import communitySkillsQuery from 'GraphQL/Queries/Community/communitySkills.graphql'
import listTagsQuery from 'GraphQL/Queries/listTags.graphql'
import { ITagOption, tagsToOptions } from 'Utils/Options'

import { SelectField, TagMultiValueRemove } from 'Components/UI'
import MenuList from 'Components/UI/Form/Select/Components/MenuList'
import { ISelectField } from 'Components/UI/Form/Select/SelectField'

import {
  DEFAULT_MIN_SEARCH_SIZE,
  DEFAULT_SEARCH_DEBOUNCE,
  SEARCH_TYPES,
  SearchType,
} from 'Constants/ids'

import { useCommunityContext } from 'Hooks'

import toast from 'Services/Toast'

const WrappedMenuList = wrapMenuList(MenuList)

export interface IPaginatedTagsField extends ISelectField<ITagOption, true> {
  type: SearchType
}

function PaginatedTagsField({
  label,
  name,
  placeholder,
  required = false,
  type,
  isOptionDisabled,
  ...rest
}: IPaginatedTagsField) {
  const { community } = useCommunityContext()
  const client = useApolloClient()

  const [page, setPage] = useState<number>(-1)
  const [searchValue, setSearchValue] = useState<string | null>(null)

  const loadTags = useCallback(
    async (inputValue: string, nextPage: number) => {
      if (inputValue && inputValue.length < DEFAULT_MIN_SEARCH_SIZE)
        return {
          data: [],
          pages: 0,
        }

      const result = await client.query({
        query:
          type === SEARCH_TYPES.skill ? communitySkillsQuery : listTagsQuery,
        variables: {
          communityIds: [community?.id],
          kind: type === SEARCH_TYPES.skill ? undefined : type,
          search: inputValue || null,
          sort: [{ column: 'createdAt', order: 'desc' }],
          limit: 25,
          page: nextPage,
        },
      })
      const suggestions = result?.data
      const totalPages =
        type === SEARCH_TYPES.skill
          ? suggestions?.communitySkills?.pages
          : suggestions?.listTags?.pages

      return {
        data:
          type === SEARCH_TYPES.skill
            ? suggestions?.communitySkills?.rows
            : suggestions?.listTags?.rows,
        pages: totalPages,
      }
    },
    [client, type, community?.id],
  )

  const loadTagOptions = useCallback(
    async (inputValue: string) => {
      try {
        setSearchValue(inputValue)

        const nextPage = inputValue !== searchValue ? 0 : page + 1
        const response = await loadTags(inputValue, nextPage)

        setSearchValue(inputValue)
        setPage(nextPage)

        return {
          options: tagsToOptions(response.data, type),
          hasMore: nextPage < response.pages,
        }
      } catch (error) {
        if (error instanceof Error) {
          toast.error({
            title: `The server returned an error: "${error.message}"`,
            text: error.message,
          })
        }
        return {
          options: [],
          hasMore: false,
        }
      }
    },

    [loadTags, page, searchValue, type],
  )

  const renderClearIndicator = useCallback(
    (props: MultiValueRemoveProps) => (
      <TagMultiValueRemove {...props} tagColorKind={type} />
    ),
    [type],
  )

  return (
    <SelectField<ITagOption, true>
      {...pick(rest)}
      components={{
        MultiValueRemove: renderClearIndicator,
        MenuList: WrappedMenuList,
      }}
      debounceTimeout={DEFAULT_SEARCH_DEBOUNCE}
      isMulti
      isOptionDisabled={isOptionDisabled}
      label={label}
      loadOptions={loadTagOptions}
      name={name}
      paginated
      placeholder={placeholder}
      required={required}
      withPortal
    />
  )
}

export default PaginatedTagsField
