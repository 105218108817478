import React, { useCallback, useEffect, useState } from 'react'

import { format } from 'date-fns'

import { Column, Input, Modal, Text } from 'Components/UI'

interface Props {
  content: string
  isOpen: boolean
  title: string
  onClose: () => void
  handleSaveFile: (filename: string) => void
}

const defaultFilename = 'NetworkOS-Contacts'

export default function ExportCommunityUsers({
  content,
  isOpen,
  title,
  onClose,
  handleSaveFile,
}: Props) {
  const [filename, setFilename] = useState(
    `${defaultFilename}-${format(new Date(), 'MM-dd-yyyy-HH:mm:ss')}`,
  )

  const handleFinish = useCallback(() => {
    onClose?.()
  }, [onClose])

  const handleCancel = useCallback(() => handleFinish(), [handleFinish])

  const handleSuccess = useCallback(
    (filename: string) => {
      handleFinish()
      handleSaveFile(filename)
      setFilename(defaultFilename)
    },
    [handleFinish, handleSaveFile],
  )

  useEffect(() => {
    if (isOpen) {
      setFilename(
        `${defaultFilename}-${format(new Date(), 'MM-dd-yyyy-HH:mm:ss')}`,
      )
    }
  }, [isOpen])

  if (!isOpen) return null

  return (
    <Modal
      cancelText="Cancel"
      confirmText="Save"
      isOpen
      title={title}
      onClose={handleCancel}
      onConfirm={() => handleSuccess(filename)}
    >
      <Column gap={4} width={['100%', '100%', '600px']}>
        <Text header header4>
          {content}
        </Text>

        <Input
          placeholder={defaultFilename}
          value={filename}
          onChange={event => setFilename(event.target.value)}
        />
      </Column>
    </Modal>
  )
}
