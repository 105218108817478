import { StoreonModule } from 'storeon'

import get from 'lodash/get'
import isEqual from 'lodash/isEqual'
import pickBy from 'lodash/pickBy'

import { Locale } from 'Constants/ids'

import { getStore } from 'Services/Shared'

export const I18N_STATE_NAMESPACE = 'i18n'

enum I18nStateField {
  Locale = 'locale',
}

enum I18nEvent {
  Set = 'i18n/set',
}

export interface I18nState {
  [I18N_STATE_NAMESPACE]: {
    [I18nStateField.Locale]?: Locale
  }
}

export interface I18nStateEvents {
  [I18nEvent.Set]: {
    [I18nStateField.Locale]?: Locale
  }
}

export function getI18n() {
  return get(getStore().get(), I18N_STATE_NAMESPACE)
}

export function getLocale() {
  return get(getI18n(), I18nStateField.Locale)
}

export function setLocale(locale: Locale) {
  return getStore().dispatch(I18nEvent.Set, {
    [I18nStateField.Locale]: locale,
  })
}

const i18nStateModule: StoreonModule<I18nState, I18nStateEvents> = store => {
  store.on('@init', () => ({
    [I18N_STATE_NAMESPACE]: {
      [I18nStateField.Locale]: undefined,
    },
  }))

  store.on(I18nEvent.Set, (state, variables) => {
    const values = pickBy(variables, (value, name) => {
      switch (name) {
        case I18nStateField.Locale:
          return true
        default:
          return false
      }
    })

    const nextI18n = { ...state[I18N_STATE_NAMESPACE], ...values }

    if (isEqual(nextI18n, state[I18N_STATE_NAMESPACE])) return null

    return { [I18N_STATE_NAMESPACE]: nextI18n }
  })
}

export default i18nStateModule
