import React from 'react'
import PropTypes from 'prop-types'

import { Container } from './styles'

function TableControlContainer({ children }) {
  return <Container>{children}</Container>
}

TableControlContainer.defaultProps = {
  children: null,
}

TableControlContainer.propTypes = {
  children: PropTypes.node,
}

export default TableControlContainer
