import styled, { css } from 'styled-components'
import { themeGet } from '@styled-system/theme-get'

import { IconStar } from '@tabler/icons-react'

export const Mark = styled.div`
  position: absolute;
  width: 6px;
  height: 6px;
  border-radius: 3px;
  background-color: ${themeGet('colors.main')};
  right: 2px;
  top: 2px;
`

export interface IStyledIconFavoriteCssProps {
  favorite?: boolean
}

const styledIconFavoriteCss = ({ favorite }: IStyledIconFavoriteCssProps) =>
  favorite &&
  css`
    stroke: ${themeGet('colors.warning40')};
    fill: ${themeGet('colors.warning40')};
  `

export interface IStyledIconStarProps extends IStyledIconFavoriteCssProps {}

export const StyledIconStar = styled(IconStar)<IStyledIconStarProps>`
  stroke: ${themeGet('colors.text.header')};
  fill: none;

  ${styledIconFavoriteCss}
`
