import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'

import { useMutation } from '@apollo/client'
import deleteCommunityUserMutation from 'GraphQL/Mutations/CommunityUser/deleteCommunityUser.graphql'
import listCommunityUsersQuery from 'GraphQL/Queries/CommunityUser/listCommunityUsers.full.graphql'
import { entityCacheRemover } from 'GraphQL/Updaters/Common'
import Utils from 'Utils'
import { getFullName } from 'Utils/User'

import {
  AddUserToCommunityModal,
  EditCommunityUserModal,
  UpdateCommunityUsersRoleModal,
} from 'Components/Blocks/Admin/Modals'
import DeleteAccountModal from 'Components/Blocks/Modals/DeleteAccount'
import { Button, Input, Pagination, Row, Text } from 'Components/UI'
import { Table } from 'Components/UI/Admin'

import { useEntityModal, useEntityTable, useTableSearch } from 'Hooks'

import { useQuery } from 'Services/Apollo'
import _ from 'Services/I18n'
import toast from 'Services/Toast'

import { useColumns } from './columns'
import TableContext from './context'
import { Container, Content } from './styles'

const DEFAULT_PAGE_SIZE = 10

function CommunityUsersTable() {
  const { communityId } = useParams()

  const [addUserModal, addUserModalActions] = useEntityModal()
  const [deleteModal, deleteActions] = useEntityModal()
  const [editUserModal, editUserModalAction] = useEntityModal()
  const [updateCommunityUsersRoleModal, updateCommunityUsersRoleModalAction] =
    useEntityModal()

  const [mostRecentPage, setMostRecentPage] = useState(0)
  const [mostRecentLimit, setMostRecentLimit] = useState(DEFAULT_PAGE_SIZE)
  const [innerSearch, setInnerSearch] = useState(null)

  const [search, changeSearch, clearSearch] = useTableSearch({
    onSearch: value => {
      Utils.Search.tableSearch({
        search: value,
        onSetSearchValue: setInnerSearch,
        onGoToPage: tableProps.gotoPage,
      })
    },
  })

  const { data, loading, refetch } = useQuery(listCommunityUsersQuery, {
    variables: {
      communityIds: [communityId],
      page: mostRecentPage,
      limit: mostRecentLimit,
      search: innerSearch,
    },
    fetchPolicy: 'network-only',
  })

  const [deleteCommunityUser] = useMutation(deleteCommunityUserMutation, {
    update: entityCacheRemover({
      entity: deleteModal.entity,
      pageSize: mostRecentLimit,
      queryKey: 'communityUsers',
    }),
  })

  const columns = useColumns()
  const entities = useMemo(
    () =>
      ({
        rows: data?.listCommunityUsers?.communityUsers,
        pages: data?.listCommunityUsers?.pages,
        count: data?.listCommunityUsers?.count,
      }) || {},
    [data],
  )

  const [tableProps, rowsCount] = useEntityTable({
    data: entities,
    columns,
    disableSortBy: true,
  })
  const { pageSize, pageIndex } = tableProps.state

  useEffect(() => {
    setMostRecentPage(tableProps.state.pageIndex)
  }, [tableProps.state.pageIndex])

  useEffect(() => {
    setMostRecentPage(0)
    setMostRecentLimit(tableProps.state.pageSize)
  }, [tableProps.state.pageSize])

  const handleOpenAddModal = addUserModalActions.openModal
  const handleDeleteModalClose = deleteActions.closeModal
  const handleUpdateCommunityUsersRoleModalClose =
    updateCommunityUsersRoleModalAction.closeModal

  const handleCloseEditUserModal = useCallback(
    async success => {
      if (success) await refetch()
      await editUserModalAction.closeModal()
    },
    [editUserModalAction, refetch],
  )

  const handleCloseAddUserToCommunity = useCallback(
    async success => {
      if (success) await refetch()
      await addUserModalActions.closeModal()
    },
    [addUserModalActions, refetch],
  )

  const handleDeleteUser = useCallback(
    async success => {
      if (!success) {
        handleDeleteModalClose().then()
        return
      }

      try {
        await deleteCommunityUser({
          variables: {
            communityId,
            id: deleteModal.entity?.id,
          },
        })

        toast.success({
          title: 'Delete user from community',
          text: `${getFullName(deleteModal?.entity)} successfully deleted`,
        })
        handleDeleteModalClose().then()
      } catch (error) {
        toast.error({
          title: 'Delete user from community',
          text: error.message,
        })
      }
    },
    [communityId, deleteModal, handleDeleteModalClose, deleteCommunityUser],
  )

  const memoizedValue = useMemo(
    () => ({
      onDeleteRow: deleteActions.openModal,
      onEditUser: editUserModalAction.openModal,
      onUpdateCommunityUsersRole: updateCommunityUsersRoleModalAction.openModal,
    }),
    [deleteActions, editUserModalAction, updateCommunityUsersRoleModalAction],
  )

  return (
    <TableContext.Provider value={memoizedValue}>
      <Container>
        <Text header header2 mb={3} mt={4}>
          Community users management
        </Text>
        <Content>
          <Row borderBottom center mb={2} pb={3} spaceBetween>
            <Row>
              <Input
                clearable
                name="search"
                placeholder={_('search.byName')}
                small
                value={search}
                width={300}
                onChange={changeSearch}
                onClear={clearSearch}
              />
            </Row>

            <Button small onClick={handleOpenAddModal}>
              Add user
            </Button>
          </Row>

          <Table {...tableProps} loading={loading} />

          <Row borderTop mt={2} pt={3}>
            <Pagination
              state={{ pageSize, pageIndex }}
              total={rowsCount}
              onPageIndexChange={tableProps?.gotoPage}
              onPageSizeChange={tableProps?.setPageSize}
            />
          </Row>
        </Content>
      </Container>

      <DeleteAccountModal
        content={
          <>
            Are you sure you want to delete
            <span>{getFullName(deleteModal.entity)}</span>? To delete write the
            word DELETE in the field below
          </>
        }
        isOpen={deleteModal.isOpen}
        title="Delete user from community"
        onClose={handleDeleteUser}
      />

      <AddUserToCommunityModal
        communityId={communityId}
        isOpen={addUserModal.isOpen}
        onClose={handleCloseAddUserToCommunity}
      />

      <EditCommunityUserModal
        communityId={communityId}
        isOpen={editUserModal.isOpen}
        user={editUserModal.entity}
        onClose={handleCloseEditUserModal}
      />

      <UpdateCommunityUsersRoleModal
        communityId={communityId}
        isOpen={updateCommunityUsersRoleModal.isOpen}
        user={updateCommunityUsersRoleModal.entity}
        onClose={handleUpdateCommunityUsersRoleModalClose}
        onRefetch={refetch}
      />
    </TableContext.Provider>
  )
}

export default CommunityUsersTable
