import { MutationUpdaterFn } from '@apollo/client'
import { AsStoreObject } from '@apollo/client/utilities'
import communityUserRelationshipsQuery from 'GraphQL/Queries/Community/communityUsersRelationships.graphql'

import { RELATIONSHIP_STRENGTH_ENUM } from 'Constants/ids'
import { UserRelationship } from 'Constants/mainGraphQL'

export const relationshipStrengthUpdater: (props: {
  fromUserId?: string
  user: MainSchema.CommunityUser
  relationshipStrength: UserRelationship
  communityId?: string
}) => MutationUpdaterFn<Pick<MainSchema.Mutation, 'setUserRelationship'>> =
  ({ fromUserId, user, relationshipStrength, communityId }) =>
  store => {
    store.modify<MainSchema.CommunityUser>({
      id: store.identify(user as AsStoreObject<MainSchema.CommunityUser>),
      fields: {
        relationshipStrength() {
          return relationshipStrength
        },
      },
    })

    if (fromUserId && communityId) {
      const query = {
        query: communityUserRelationshipsQuery,
        variables: {
          communityId,
        },
      }
      const queryData = store.readQuery<
        Pick<MainSchema.Query, 'communityUsersRelationships'>,
        MainSchema.QueryCommunityUsersRelationshipsArgs
      >(query)

      if (queryData) {
        const data = [...(queryData?.communityUsersRelationships ?? [])]

        const previousRelationshipStrength = `${fromUserId}:${
          RELATIONSHIP_STRENGTH_ENUM[user.relationshipStrength!]
        }:${user.userId}`
        const nextRelationshipStrength = `${fromUserId}:${RELATIONSHIP_STRENGTH_ENUM[relationshipStrength]}:${user.userId}`

        const itemIndex = data.indexOf(previousRelationshipStrength)

        if (itemIndex !== -1) {
          data[itemIndex] =
            `${fromUserId}:${RELATIONSHIP_STRENGTH_ENUM[relationshipStrength]}:${user.userId}`
        } else {
          data.push(nextRelationshipStrength)
        }

        store.writeQuery<
          Pick<MainSchema.Query, 'communityUsersRelationships'>,
          MainSchema.QueryCommunityUsersRelationshipsArgs
        >({
          ...query,
          data: {
            communityUsersRelationships: [...data],
          },
        })
      }
    }
  }
