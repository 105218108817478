import { ApolloClient } from '@apollo/client'

import WebSocketLink from './Apollo/Links/WebSocketLink'
import { Store } from './Store/index'

enum Service {
  ApolloClient = 'apolloClient',
  WebSocketLink = 'webSocketLink',
  Store = 'store',
}

interface Services {
  [Service.ApolloClient]?: ApolloClient<any>
  [Service.WebSocketLink]?: WebSocketLink
  [Service.Store]?: Store
}

const services: Services = {}

function checkHasValue<T extends Service>(serviceName: T) {
  const service = services[serviceName]

  if (!service) throw new Error(`${serviceName} not found in Shared`)

  return service
}

function setApolloClient(apolloClient: ApolloClient<any>) {
  services[Service.ApolloClient] = apolloClient
}

function setStore(store: Store) {
  services[Service.Store] = store
}

function setWebSocketLink(webSocketLink: WebSocketLink) {
  services[Service.WebSocketLink] = webSocketLink
}

function getApolloClient() {
  return checkHasValue(Service.ApolloClient)
}

function getStore() {
  return checkHasValue(Service.Store)
}

function getWebSocketLink() {
  return checkHasValue(Service.WebSocketLink)
}

export {
  getApolloClient,
  getStore,
  getWebSocketLink,
  setApolloClient,
  setStore,
  setWebSocketLink,
}
