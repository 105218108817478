import React from 'react'

import { Tooltip } from 'Components/UI'

import { FillStarIcon, OutlineStarIcon } from 'Pages/Community/Notes/styles'

import { useScopedI18n } from 'Services/I18n'

import colors from 'Theme/_v2/colors'

import * as Styled from './IsFavoriteField.styles'

export interface IIsFavoriteFieldProps {
  value?: boolean
  onChange: React.MouseEventHandler<HTMLButtonElement>
}

const IsFavoriteField = (props: IIsFavoriteFieldProps) => {
  const t = useScopedI18n('features.notes.components.noteForm.isFavoriteField')

  return (
    <Tooltip
      content={props.value ? t('label.unfavorite') : t('label.favorite')}
    >
      <Styled.FavoriteButton
        title={props.value ? t('label.unfavorite') : t('label.favorite')}
        onClick={props.onChange}
      >
        {props.value ? (
          <FillStarIcon size={20} />
        ) : (
          <OutlineStarIcon color={colors.text.secondary} size={20} />
        )}
      </Styled.FavoriteButton>
    </Tooltip>
  )
}

export default IsFavoriteField
