import styled, { css } from 'styled-components'
import { mapToTheme } from 'styled-map'
import { layout, LayoutProps, margin, MarginProps } from 'styled-system'
import { themeGet } from '@styled-system/theme-get'

const wrapperDisabledCss = ({ disabled }: { disabled?: boolean }) =>
  disabled &&
  css`
    color: ${themeGet('colors.text.disabled')};
  `

export interface IWrapper extends MarginProps, LayoutProps {
  disabled?: boolean
}

export const Wrapper = styled.div<IWrapper>`
  display: flex;
  flex-direction: column;
  color: ${themeGet('colors.text.body')};
  min-width: ${mapToTheme('inputs.minWidth')}px;

  ${margin};
  ${layout};

  ${wrapperDisabledCss}
`

const containerDisabledCss = ({ disabled }: { disabled?: boolean }) =>
  disabled &&
  css`
    cursor: default;
    color: ${themeGet('colors.text.disabled')};
    border-color: ${themeGet('colors.divider.disabled')};

    &:hover {
      border-color: ${themeGet('colors.divider.disabled')} !important;
    }

    input {
      color: ${themeGet('colors.text.disabled')};
    }
  `

const containerActiveCss = ({ active }: { active?: boolean }) =>
  active &&
  css`
    border-color: ${themeGet('colors.divider.hover')};
    box-shadow: ${themeGet('shadows.secondaryFocus')};
  `

const containerSuccessCss = ({ success }: { success?: boolean }) =>
  success &&
  css`
    border-color: ${themeGet('colors.success')};
  `

const containerErrorCss = ({ error }: { error?: boolean }) =>
  error &&
  css`
    border-color: ${themeGet('colors.error')};
  `

const containerTextAreaCss = ({ textArea }: { textArea?: boolean }) =>
  textArea &&
  css`
    height: auto;
    padding: ${themeGet('space.3')}px;

    input {
      width: 100%;
    }
  `

export interface IContainer {
  textArea?: boolean
  active?: boolean
  error?: boolean
  success?: boolean
  disabled?: boolean
}

export const Container = styled.div<IContainer>`
  display: flex;
  border: 1px solid ${themeGet('colors.divider.default')};
  border-radius: ${mapToTheme('inputs.borderRadius')}px;
  align-items: center;
  padding-inline: ${themeGet('space.3')}px;
  position: relative;
  height: ${mapToTheme('inputs.height')}px;
  gap: ${themeGet('space.2')}px;
  background-color: ${themeGet('colors.bg.body')};

  svg {
    flex-shrink: 0;
    height: 16px;
    width: 16px;
  }

  &:hover {
    border-color: ${themeGet('colors.divider.hover')} !important;
  }

  ${containerTextAreaCss}
  ${containerActiveCss};
  ${containerErrorCss};
  ${containerSuccessCss};
  ${containerDisabledCss};
`

export const StyledInput = styled.input`
  position: relative;
  width: 100%;
  margin: 0;
  border: none;
  outline: none;
  appearance: none;
  background: transparent;
  color: ${themeGet('colors.text.heading')};
  font-weight: ${themeGet('fontWeights.1')};
  font-size: ${mapToTheme('inputs.fontSize')}px;

  ::placeholder {
    color: ${themeGet('colors.text.placeholder')};
  }
`

const textAreaSmallCss = ({ small }: { small?: boolean }) =>
  small &&
  css`
    height: ${themeGet('inputs.height.textAreaSmall')}px;
  `
export interface IStyledTextArea {
  small?: boolean
}

export const StyledTextArea = styled(StyledInput).attrs({
  as: 'textarea',
})<IStyledTextArea>`
  height: ${themeGet('inputs.height.textAreaLarge')}px;
  resize: none;

  ${textAreaSmallCss}
`

const counterDisabledCss = ({ disabled }: { disabled?: boolean }) =>
  disabled &&
  css`
    background-color: ${themeGet('colors.text.disabled')};
  `
export interface ICounter {
  disabled?: boolean
}

export const Counter = styled.div<ICounter>`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  min-width: 20px;
  height: 20px;
  border-radius: 10px;
  padding: 0 ${themeGet('space.1')}px;
  background-color: ${themeGet('colors.bg.secondary')};

  ${counterDisabledCss}
`

export const ClearButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  outline: none;
  border: none;
  background: none;
  padding: 0;
  &:enabled {
    cursor: pointer;
  }
`
