import React from 'react'
import { CopyToClipboard } from 'react-copy-to-clipboard'

import { IconLink } from '@tabler/icons-react'

import Divider from 'Components/UI/Divider'
import { Row } from 'Components/UI/Flex'
import Text from 'Components/UI/Text'

import _ from 'Services/I18n'
import toast from 'Services/Toast'

import { Container, HiddenInput } from './styles'

export interface ICopyInput {
  inputValue: string
  title: string
}

function CopyInput({ inputValue, title }: ICopyInput) {
  const handleCopy = () => {
    toast.success({
      title: _('general.copyInviteLink'),
      text: _('general.copied'),
    })
  }

  return (
    <Container>
      <HiddenInput readOnly value={inputValue} />

      <CopyToClipboard text={inputValue} onCopy={handleCopy}>
        <Row center>
          <Divider height="20px" mr={3} vertical />

          <IconLink size="20px" />

          <Text header4 main ml={2}>
            {title}
          </Text>
        </Row>
      </CopyToClipboard>
    </Container>
  )
}

export default CopyInput
