import React from 'react'
import { components } from 'react-select'

import { PlaceholderWrapper } from './styles'

const Placeholder: typeof components.Placeholder = props => {
  // TODO: find a better way to satisfy the type
  const StyledPlaceholderWrapper = PlaceholderWrapper as any
  return <StyledPlaceholderWrapper {...props} as={components.Placeholder} />
}

export default Placeholder
