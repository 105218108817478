import React from 'react'

import { Checkbox } from 'Components/UI'

import { useTableContext } from '../context'

function CheckAllHeader() {
  const { onSelectAll, isSelectedAll } = useTableContext()

  return <Checkbox checked={isSelectedAll} onChange={onSelectAll} />
}

export default CheckAllHeader
