import React from 'react'

import { Text } from 'Components/UI'

import { useScopedI18n } from 'Services/I18n'

export interface IStatusProps {
  value?: boolean
}

function Status({ value }: IStatusProps) {
  const s = useScopedI18n('notesManagement.status')
  return <Text header>{value ? s('public') : s('private')}</Text>
}

export default Status
