import styled, { css } from 'styled-components'
import { mapToTheme } from 'styled-map'
import {
  color,
  ColorProps,
  layout,
  LayoutProps,
  space,
  SpaceProps,
  typography,
  TypographyProps,
} from 'styled-system'
import shouldForwardProp from '@styled-system/should-forward-prop'
import { themeGet } from '@styled-system/theme-get'

const header1Css = ({ header1 }: { header1?: boolean }) =>
  header1 &&
  css`
    font-size: ${themeGet('fontSize.5')}px;
    font-weight: ${themeGet('fontWeight.2')};
    line-height: 112%;
  `

const header2Css = ({ header2 }: { header2?: boolean }) =>
  header2 &&
  css`
    font-size: ${themeGet('fontSize.4')}px;
    font-weight: ${themeGet('fontWeight.2')};
    line-height: 120%;
  `

const header3Css = ({ header3 }: { header3?: boolean }) =>
  header3 &&
  css`
    font-size: ${themeGet('fontSize.3')}px;
    font-weight: ${themeGet('fontWeight.2')};
    line-height: 150%;
  `

const header4Css = ({ header4 }: { header4?: boolean }) =>
  header4 &&
  css`
    font-size: ${themeGet('fontSize.2')}px;
    font-weight: ${themeGet('fontWeight.2')};
    line-height: 143%;
  `

const header5Css = ({ header5 }: { header5?: boolean }) =>
  header5 &&
  css`
    font-size: ${themeGet('fontSize.1')}px;
    font-weight: ${themeGet('fontWeight.2')};
    line-height: 133%;
  `

const bodyLargeCss = ({ bodyLarge }: { bodyLarge?: boolean }) =>
  bodyLarge &&
  css`
    font-size: ${themeGet('fontSize.3')}px;
    font-weight: ${themeGet('fontWeight.0')};
    line-height: 150%;
  `

const bodyMediumCss = ({ bodyMedium }: { bodyMedium?: boolean }) =>
  bodyMedium &&
  css`
    font-size: ${themeGet('fontSize.2')}px;
    font-weight: ${themeGet('fontWeight.0')};
    line-height: 143%;
  `

const bodySmallCss = ({ bodySmall }: { bodySmall?: boolean }) =>
  bodySmall &&
  css`
    font-size: ${themeGet('fontSize.1')}px;
    font-weight: ${themeGet('fontWeight.0')};
    line-height: 133%;
  `

const actionLargeCss = ({ actionLarge }: { actionLarge?: boolean }) =>
  actionLarge &&
  css`
    font-size: ${themeGet('fontSize.3')}px;
    font-weight: ${themeGet('fontWeight.1')};
    line-height: 150%;
  `

const actionMediumCss = ({ actionMedium }: { actionMedium?: boolean }) =>
  actionMedium &&
  css`
    font-size: ${themeGet('fontSize.2')}px;
    font-weight: ${themeGet('fontWeight.1')};
    line-height: 143%;
  `

const actionSmallCss = ({ actionSmall }: { actionSmall?: boolean }) =>
  actionSmall &&
  css`
    font-size: ${themeGet('fontSize.1')}px;
    font-weight: ${themeGet('fontWeight.1')};
    line-height: 133%;
  `

const captionLargeCss = ({ captionLarge }: { captionLarge?: boolean }) =>
  captionLarge &&
  css`
    font-size: ${themeGet('fontSize.2')}px;
    font-weight: ${themeGet('fontWeight.1')};
    line-height: 150%;
    text-transform: uppercase;
  `

const captionMediumCss = ({ captionMedium }: { captionMedium?: boolean }) =>
  captionMedium &&
  css`
    font-size: ${themeGet('fontSize.1')}px;
    font-weight: ${themeGet('fontWeight.1')};
    line-height: 143%;
    text-transform: uppercase;
  `

const captionSmallCss = ({ captionSmall }: { captionSmall?: boolean }) =>
  captionSmall &&
  css`
    font-size: ${themeGet('fontSize.0')}px;
    font-weight: ${themeGet('fontWeight.1')};
    line-height: 133%;
    text-transform: uppercase;
  `

const tagLargeCss = ({ tagLarge }: { tagLarge?: boolean }) =>
  tagLarge &&
  css`
    font-size: ${themeGet('fontSize.3')}px;
    font-weight: ${themeGet('fontWeight.1')};
    line-height: 150%;
  `

const tagSmallCss = ({ tagSmall }: { tagSmall?: boolean }) =>
  tagSmall &&
  css`
    font-size: ${themeGet('fontSize.1')}px;
    font-weight: ${themeGet('fontWeight.1')};
    line-height: 133%;
  `

const mainCss = ({ main }: { main?: boolean }) =>
  main &&
  css`
    color: ${themeGet('colors.text.main')};
  `

const headerCss = ({ header }: { header?: boolean }) =>
  header &&
  css`
    color: ${themeGet('colors.text.header')};
  `

const bodyCss = ({ body }: { body?: boolean }) =>
  body &&
  css`
    color: ${themeGet('colors.text.body')};
  `

const contrastCss = ({ contrast }: { contrast?: boolean }) =>
  contrast &&
  css`
    color: ${themeGet('colors.text.contrast')};
  `

const disabledCss = ({ disabled }: { disabled?: boolean }) =>
  disabled &&
  css`
    color: ${themeGet('colors.text.disabled')};
  `

const errorCss = ({ error }: { error?: boolean }) =>
  error &&
  css`
    color: ${themeGet('colors.text.error')};
  `

const infoCss = ({ info }: { info?: boolean }) =>
  info &&
  css`
    color: ${themeGet('colors.text.info')};
  `

const successCss = ({ success }: { success?: boolean }) =>
  success &&
  css`
    color: ${themeGet('colors.text.success')};
  `

const warningCss = ({ warning }: { warning?: boolean }) =>
  warning &&
  css`
    color: ${themeGet('colors.text.warning')};
  `

const isPlaceholderCss = ({ isPlaceholder }: { isPlaceholder?: boolean }) =>
  isPlaceholder &&
  css`
    color: ${themeGet('colors.text.placeholder')};
  `

const centerCss = ({ center }: { center?: boolean }) =>
  center &&
  css`
    text-align: center;
  `

const nowrapCss = ({ nowrap }: { nowrap?: boolean }) =>
  nowrap &&
  css`
    white-space: nowrap;
  `

const ellipsisCss = ({ ellipsis }: { ellipsis?: boolean }) =>
  ellipsis &&
  css`
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  `

const breakWordCss = ({ breakWord }: { breakWord?: boolean }) =>
  breakWord &&
  css`
    word-break: break-word;
  `

export interface IText
  extends LayoutProps,
    SpaceProps,
    ColorProps,
    TypographyProps {
  actionLarge?: boolean
  actionMedium?: boolean
  actionSmall?: boolean
  body?: boolean
  bodyLarge?: boolean
  bodyMedium?: boolean
  bodySmall?: boolean
  breakWord?: boolean
  captionLarge?: boolean
  captionMedium?: boolean
  captionSmall?: boolean
  center?: boolean
  contrast?: boolean
  disabled?: boolean
  ellipsis?: boolean
  error?: boolean
  fontSize?: number | number[] | string
  fontWeight?: number | number[] | string
  header?: boolean
  header1?: boolean
  header2?: boolean
  header3?: boolean
  header4?: boolean
  header5?: boolean
  info?: boolean
  isPlaceholder?: boolean
  main?: boolean
  nowrap?: boolean
  success?: boolean
  tagLarge?: boolean
  tagSmall?: boolean
  warning?: boolean
  bold?: boolean
  grayBoulder?: boolean
  big?: boolean
  inline?: boolean
  secondary?: boolean
  mineShaft?: boolean
  small?: boolean
  ebony?: boolean
  paleSky?: boolean
  primary80?: boolean
  colored?: boolean
  action?: boolean
  bolder?: boolean
  light?: boolean
  primary?: boolean
  maxLines?: number
  multiline?: boolean
}

const Text = styled('p').withConfig<IText>({
  shouldForwardProp,
})`
  margin: 0;
  padding: 0;
  position: relative;
  font-family: ${themeGet('font.fontFamily')};
  color: ${mapToTheme('colors')};

  ${layout}
  ${space}
  ${color}

  ${actionLargeCss}
  ${actionMediumCss}
  ${actionSmallCss}
  ${bodyLargeCss}
  ${bodyMediumCss}
  ${bodySmallCss}
  ${captionLargeCss}
  ${captionMediumCss}
  ${captionSmallCss}
  ${tagLargeCss}
  ${tagSmallCss}
  ${header1Css}
  ${header2Css}
  ${header3Css}
  ${header4Css}
  ${header5Css}

  ${bodyCss}
  ${contrastCss}
  ${disabledCss}
  ${errorCss}
  ${headerCss}
  ${infoCss}
  ${isPlaceholderCss}
  ${mainCss}
  ${successCss}
  ${warningCss}

  ${centerCss}
  ${nowrapCss}
  ${ellipsisCss}
  ${breakWordCss}

  ${typography}
`

export default Text
