import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import PropTypes from 'prop-types'

import { IconSearch } from '@tabler/icons-react'
import communitySkillsQuery from 'GraphQL/Queries/Community/communitySkills.graphql'
import listTagsQuery from 'GraphQL/Queries/listTags.graphql'
import Utils from 'Utils'

import map from 'lodash/map'

import ContentCard from 'Components/Blocks/ContentCard'
import AddEditTagModal from 'Components/Blocks/Modals/AddEditTag'
import CreateSkillModal from 'Components/Blocks/Modals/CreateSkill'
import CreateTagModal from 'Components/Blocks/Modals/CreateTag'
import DeleteTagModal from 'Components/Blocks/Modals/DeleteTag'
import { Button, Input, Row, Table } from 'Components/UI'

import { SEARCH_TYPES } from 'Constants/ids'
import { PERMISSION_ACTION, PERMISSION_SUBJECT } from 'Constants/permissions'

import {
  useCommunityContext,
  useEntityModal,
  useEntityTable,
  usePermission,
  useTableSearch,
} from 'Hooks'

import { useQuery } from 'Services/Apollo'
import { useScopedI18n } from 'Services/I18n'

import { useColumns } from './columns'
import TableContext from './context'
import Filter from './Filter'

import { TableControlContainer, TablePagination } from '../Blocks'

const DEFAULT_PAGE_SIZE = 10

const SORT_BY = [
  {
    column: 'createdAt',
    order: 'desc',
  },
]

function TagsTable({ type, title }) {
  const s = useScopedI18n('tag')
  const { community } = useCommunityContext()
  const createButtonRef = useRef()
  const { can } = usePermission()

  const [createTagModal, createTagModalActions] = useEntityModal()
  const [createSkillModal, createSkillModalActions] = useEntityModal()
  const [editModal, editActions] = useEntityModal()
  const [deleteModal, deleteActions] = useEntityModal()

  const [mostRecentPage, setMostRecentPage] = useState(0)
  const [mostRecentLimit, setMostRecentLimit] = useState(DEFAULT_PAGE_SIZE)
  const [mostRecentSortBy, setMostRecentSortBy] = useState(SORT_BY)
  const [filter, setFilter] = useState({})
  const [innerSearch, setInnerSearch] = useState(null)

  const query = useMemo(
    () => (type === SEARCH_TYPES.skill ? communitySkillsQuery : listTagsQuery),
    [type],
  )

  const [search, changeSearch, clearSearch] = useTableSearch({
    onSearch: value => {
      Utils.Search.tableSearch({
        search: value,
        onSetSearchValue: setInnerSearch,
        onGoToPage: tableProps.gotoPage,
      })
    },
  })

  const { data, loading, refetch } = useQuery(query, {
    skip: !community?.id,
    variables: {
      communityIds: [community?.id],
      kind: type === SEARCH_TYPES.skill ? undefined : filter?.kind,
      page: mostRecentPage,
      limit: mostRecentLimit,
      sort: mostRecentSortBy,
      creatorIds: filter?.creatorIds,
      createdFrom: filter?.createdFrom,
      createdTo: filter?.createdTo,
      search: innerSearch,
    },
    fetchPolicy: 'network-only',
  })

  const columns = useColumns(title, type)
  const entities = useMemo(
    () =>
      type === SEARCH_TYPES.skill
        ? data?.communitySkills
        : (data?.listTags ?? {}),
    [type, data],
  )

  const [tableProps, rowsCount] = useEntityTable({
    data: entities,
    columns,
    initialState: {
      sortBy: [
        {
          id: 'createdAt',
          desc: true,
        },
      ],
    },
  })

  const { pageSize, pageIndex, sortBy } = tableProps.state

  const sort = useMemo(
    () =>
      map(sortBy, value => ({
        column: value.id,
        order: value.desc ? 'desc' : 'asc',
      })),
    [sortBy],
  )

  const canCreate =
    type === 'tag'
      ? can(PERMISSION_ACTION.CREATE, PERMISSION_SUBJECT.MANAGEMENT_TAG, [])
      : can(PERMISSION_ACTION.CREATE, PERMISSION_SUBJECT.MANAGEMENT_SKILL, [])

  useEffect(() => {
    setMostRecentPage(pageIndex)
    setMostRecentSortBy(sort.length !== 0 ? sort : null)
  }, [pageIndex, sort])

  useEffect(() => {
    setMostRecentPage(0)
    setMostRecentLimit(pageSize)
  }, [pageSize])

  const handleOpenCreateModal = useCallback(() => {
    if (type === SEARCH_TYPES.skill)
      createSkillModalActions.openModal(null).then()
    if (type === SEARCH_TYPES.tag) createTagModalActions.openModal(null).then()

    createButtonRef.current.blur()
  }, [createSkillModalActions, createTagModalActions, type])

  const handleEditModalClose = editActions.closeModal
  const handleDeleteModalClose = deleteActions.closeModal
  const handleCreateTagModalClose = createTagModalActions.closeModal
  const handleCreateSkillModalClose = createSkillModalActions.closeModal

  const memoizedContext = useMemo(
    () => ({
      type,
      onEditRow: editActions.openModal,
      onDeleteRow: deleteActions.openModal,
    }),
    [deleteActions.openModal, editActions.openModal, type],
  )

  return (
    <ContentCard>
      <TableControlContainer>
        <Row center gap={3}>
          <Input
            clearable
            name="search"
            placeholder={`Search by ${title || type}`}
            renderBeforeElement={() => <IconSearch />}
            value={search}
            width={[1, 1, 300]}
            onChange={changeSearch}
            onClear={clearSearch}
          />
          <Filter
            showDate={type !== 'skill'}
            showKind={type === 'tag'}
            onChange={setFilter}
          />
        </Row>

        {canCreate && (
          <Button ref={createButtonRef} onClick={handleOpenCreateModal}>
            {s('actions.create', {
              tagType: type === 'skill' ? 'skill' : 'tag',
            })}
          </Button>
        )}
      </TableControlContainer>

      <TableContext.Provider value={memoizedContext}>
        <Table {...tableProps} loading={loading} />

        <TablePagination
          state={{ pageIndex, pageSize }}
          total={rowsCount}
          onPageIndexChange={tableProps?.gotoPage}
          onPageSizeChange={tableProps?.setPageSize}
        />

        {type === SEARCH_TYPES.tag && (
          <CreateTagModal
            isOpen={createTagModal.isOpen}
            onClose={handleCreateTagModalClose}
            onRefetch={refetch}
          />
        )}

        {type === SEARCH_TYPES.skill && (
          <CreateSkillModal
            isOpen={createSkillModal.isOpen}
            onClose={handleCreateSkillModalClose}
            onRefetch={refetch}
          />
        )}

        <AddEditTagModal
          isOpen={editModal.isOpen}
          refetch={refetch}
          tag={editModal.entity}
          title={title}
          type={type}
          onClose={handleEditModalClose}
        />

        <DeleteTagModal
          isOpen={deleteModal.isOpen}
          mostRecentLimit={mostRecentLimit}
          tag={deleteModal.entity}
          title={title}
          type={type}
          onClose={handleDeleteModalClose}
        />
      </TableContext.Provider>
    </ContentCard>
  )
}

TagsTable.propTypes = {
  title: PropTypes.string,
  type: PropTypes.oneOf(['tag', 'skill']).isRequired,
}

TagsTable.defaultProps = {
  title: '',
}

export default TagsTable
