import { NavLink } from 'react-router-dom'

import styled, { css } from 'styled-components'
import { padding, PaddingProps, space, SpaceProps } from 'styled-system'
import { themeGet } from '@styled-system/theme-get'

import { sidebarLogoGlyph } from 'Assets/Svg'

import { IconButton } from 'Components/UI'

export interface IMenu extends PaddingProps {}

export const Menu = styled.header.attrs<IMenu>({
  px: [1, 1, 3],
})<{ isDashboard?: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100vw;
  height: 70px;
  z-index: 1000;
  background-color: ${({ isDashboard }) =>
    isDashboard ? 'transparent' : themeGet('colors.bg.primary')};
  ${({ isDashboard }) =>
    !isDashboard &&
    css`
      border-bottom: 1px solid ${themeGet('colors.divider.default')};
    `}
  transition-duration: ${themeGet('transitionTime.default')};

  ${padding}
`

export const MenuLink = styled(NavLink)`
  display: flex;
  justify-content: center;
  text-decoration: none;
  cursor: pointer;
  transition: all 0.3s ease;

  &:hover,
  &:focus {
    > p {
      color: ${themeGet('colors.hover')};
    }
  }
`

export interface ILogo extends SpaceProps {}

export const Logo = styled(sidebarLogoGlyph).attrs<ILogo>({
  width: '130px',
  height: '32px',
})`
  ${space};
`

export const HamburgerMenuContainer = styled.div`
  position: absolute;
  top: 100%;
  left: 0;
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: ${themeGet('space.5')}px;
  gap: ${themeGet('space.4')}px;
  background-color: ${themeGet('colors.bg.primary')};
  border-top: 1px solid ${themeGet('colors.divider.default')};
  color: #007aff;
`

export const LogoWrapper = styled.div`
  display: flex;
  padding: ${themeGet('space.1')}px ${themeGet('space.2')}px;
  border-radius: 2px;

  &:hover,
  &:focus {
    background-color: ${themeGet('colors.bg.tertiary')};
  }
`

export const LogoNavWrapper = styled.div`
  position: relative;
  display: flex;
  height: 100%;
  align-items: center;
  padding: ${themeGet('space.1')}px;
  border-radius: 4px;
  background-color: ${themeGet('colors.bg.primary')};
  box-shadow:
    0px 0px 0px 1px rgba(0, 0, 0, 0.02),
    0px 0px 13px -15px rgba(0, 0, 0, 0.1),
    0px 4px 6px -2px rgba(0, 0, 0, 0.05);
`

export const NavDivider = styled.div`
  width: 1px;
  height: 20px;
  margin: 0 ${themeGet('space.1')}px;
  background-color: ${themeGet('colors.border.secondary')};
`

export const IconButtonStyled = styled(IconButton)`
  box-shadow:
    0px 0px 0px 1px rgba(0, 0, 0, 0.02),
    0px 0px 13px -15px rgba(0, 0, 0, 0.1),
    0px 4px 6px -2px rgba(0, 0, 0, 0.05);
`
