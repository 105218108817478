import React, { useCallback, useState } from 'react'
import PropTypes from 'prop-types'

import { useApolloClient } from '@apollo/client'
import debounce from 'awesome-debounce-promise'
import listCommunityUsersQuery from 'GraphQL/Queries/CommunityUser/listCommunityUsers.full.graphql'
import { setMinSearchLength } from 'Utils/Form'

import map from 'lodash/map'
import upperFirst from 'lodash/upperFirst'

import { Column, DateRangePickerInput, Select } from 'Components/UI'

import {
  DEFAULT_MIN_SEARCH_SIZE,
  DEFAULT_SEARCH_DEBOUNCE,
  PROPOSAL_KIND,
} from 'Constants/ids'

import { useCommunityContext } from 'Hooks'

import { useScopedI18n } from 'Services/I18n'

import { AdvancedFilter, FilterDropdown } from '../../Blocks'

const KIND_OPTIONS = [
  {
    label: upperFirst(PROPOSAL_KIND.ROLE),
    value: PROPOSAL_KIND.ROLE,
  },
  {
    label: upperFirst(PROPOSAL_KIND.EVENT),
    value: PROPOSAL_KIND.EVENT,
  },
  {
    label: upperFirst(PROPOSAL_KIND.GROUP),
    value: PROPOSAL_KIND.GROUP,
  },
  {
    label: upperFirst(PROPOSAL_KIND.PROJECT),
    value: PROPOSAL_KIND.PROJECT,
  },
  {
    label: upperFirst(PROPOSAL_KIND.SKILL),
    value: PROPOSAL_KIND.SKILL,
  },
  {
    label: upperFirst(PROPOSAL_KIND.NEED_SKILL),
    value: PROPOSAL_KIND.NEED_SKILL,
  },
  {
    label: upperFirst(PROPOSAL_KIND.CUSTOM),
    value: PROPOSAL_KIND.CUSTOM,
  },
  {
    label: upperFirst(PROPOSAL_KIND.PHONE),
    value: PROPOSAL_KIND.PHONE,
  },
]

const FILTER = {
  CREATORS: 'creators',
  DATE: 'date',
  KIND: 'kind',
}

function Filter({ onChange }) {
  const s = useScopedI18n('general')
  const { community } = useCommunityContext()
  const client = useApolloClient()

  const [creators, setCreators] = useState([])
  const [date, setDate] = useState({
    from: null,
    to: null,
  })
  const [kind, setKind] = useState()
  const [openFilter, setOpenFilter] = useState(false)

  const handleChangeDate = useCallback(({ to, from }) => {
    setDate({ to, from })
  }, [])

  const handleClearAll = useCallback(() => {
    setCreators([])
    setKind(null)
    setDate({
      from: null,
      to: null,
    })
    onChange({
      createdFrom: null,
      createdTo: null,
    })
  }, [onChange])

  const handleApplyFilters = () => {
    onChange({
      createdTo: date.to,
      createdFrom: date.from,
      creatorIds: creators.length
        ? map(creators, creator => creator.value)
        : undefined,
      kind: kind?.value,
    })
  }

  const loadUsersOptions = useCallback(
    () => async (inputValue, callback) => {
      const result = await client.query({
        query: listCommunityUsersQuery,
        variables: {
          communityIds: [community?.id],
          search: inputValue,
          limit: 25,
        },
      })

      const usersSuggestions =
        result.data?.listCommunityUsers?.communityUsers || []

      callback(usersSuggestions)
    },
    [community?.id, client],
  )

  const debouncedLoadOptions = useCallback(
    () =>
      setMinSearchLength(
        debounce(loadUsersOptions(), DEFAULT_SEARCH_DEBOUNCE),
        DEFAULT_MIN_SEARCH_SIZE,
      ),
    [loadUsersOptions],
  )

  return (
    <AdvancedFilter
      applyFilters={handleApplyFilters}
      clearFilter={handleClearAll}
    >
      <Column>
        <FilterDropdown
          isOpened={openFilter === FILTER.CREATORS}
          setIsOpened={() =>
            setOpenFilter(
              openFilter === FILTER.CREATORS ? null : FILTER.CREATORS,
            )
          }
          title={`${s('creators')} (${creators.length})`}
        >
          <Select
            async
            flexShrink={0}
            isMulti
            loadOptions={debouncedLoadOptions()}
            placeholder=""
            value={creators}
            width={1}
            withLabel={false}
            onChange={setCreators}
          />
        </FilterDropdown>

        <FilterDropdown
          isOpened={openFilter === FILTER.KIND}
          setIsOpened={() =>
            setOpenFilter(openFilter === FILTER.KIND ? null : FILTER.KIND)
          }
          title={s('kind')}
        >
          <Select
            blurInputOnSelect
            flexShrink={0}
            isSearchable={false}
            options={KIND_OPTIONS}
            placeholder=""
            value={kind}
            width={1}
            withLabel={false}
            onChange={setKind}
          />
        </FilterDropdown>

        <FilterDropdown
          isOpened={openFilter === FILTER.DATE}
          setIsOpened={() =>
            setOpenFilter(openFilter === FILTER.DATE ? null : FILTER.DATE)
          }
          title={s('date')}
        >
          <DateRangePickerInput
            dateRange={date}
            width={1}
            onChange={handleChangeDate}
          />
        </FilterDropdown>
      </Column>
    </AdvancedFilter>
  )
}

Filter.propTypes = {
  onChange: PropTypes.func.isRequired,
}

export default Filter
