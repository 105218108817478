import React from 'react'

import { FiUser } from 'react-icons/fi'

import { pick } from '@styled-system/props'

import { resizeImageUrl } from 'Utils/Strings'

import { Empty, Image, IWrapper, Wrapper } from './styles'

export interface IAvatar extends Omit<IWrapper, 'borderRadius'> {
  borderRadius?: number
  size?: number
  src?: string
  renderSize?: 'Full' | '128' | '512'
}

function Avatar({
  size = 32,
  src,
  borderRadius = 4,
  renderSize = '128',
  ...rest
}: IAvatar) {
  const adjustedSrc = resizeImageUrl(src, renderSize)

  return (
    <Wrapper borderRadius={borderRadius} size={size} {...pick(rest)}>
      {adjustedSrc ? (
        <Image src={adjustedSrc} />
      ) : (
        <Empty>
          <FiUser size={size * 0.55} strokeWidth={1} />
        </Empty>
      )}
    </Wrapper>
  )
}

export default Avatar
