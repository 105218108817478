import { AbilityBuilder, PureAbility } from '@casl/ability'

import forEach from 'lodash/forEach'
import isEqual from 'lodash/isEqual'
import reduce from 'lodash/reduce'

interface IRule {
  action: string
  subject: string
  conditions?: Record<string, any>
}

interface IParsedRules {
  [subject: string]: {
    [conditions: string]: {
      [action: string]: boolean
    }
  }
}

function parseBySubjectAndScope(rules: IRule[]): IParsedRules {
  return reduce(
    rules,
    (acc, item) => {
      const subject = item?.subject
      const conditions = item?.conditions
        ? Object.keys(item.conditions).join('-')
        : ''
      const action = item?.action

      if (!acc[subject]) {
        acc[subject] = {}
      }

      acc[subject] = {
        ...acc[subject],
        [conditions]: {
          ...acc[subject][conditions],
          [action]: true,
        },
      }

      return acc
    },
    {} as IParsedRules,
  )
}

function createAbility(rules: IRule[]) {
  const { can, build } = new AbilityBuilder(PureAbility<any, any>)

  forEach(rules, ({ action, subject, conditions }: any) => {
    // By default, CASL works as OR for rules matching, we need strong equality for our checks.
    can(action, subject, (scope: any) => isEqual(conditions, scope))
  })

  return build({ conditionsMatcher: (matchConditions: any) => matchConditions })
}

const PermissionService = {
  parseBySubjectAndScope,
  createAbility,
}

export default PermissionService
