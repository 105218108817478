import React, { useCallback, useMemo, useState } from 'react'
import { Form } from 'react-final-form'
import PropTypes from 'prop-types'

import updateUserMutation from 'GraphQL/Mutations/Admin/Community/updateUser.graphql'
import removeUserSkillsMutation from 'GraphQL/Mutations/Admin/User/removeUserSkills.graphql'
import connectUsersToSkillsMutation from 'GraphQL/Mutations/Community/connectUsersToSkills.graphql'
import connectUsersToTagsMutation from 'GraphQL/Mutations/Community/connectUsersToTags.graphql'
import disconnectUsersFromTagsMutation from 'GraphQL/Mutations/Community/disconnectUsersFromTags.graphql'
import { getCommunityUserSkillsUpdater } from 'GraphQL/Updaters/GetCommunityUserSkills'
import { getCommunityUserTagsUpdater } from 'GraphQL/Updaters/GetCommunityUserTags'
import Utils from 'Utils'
import validate from 'validate.js'

import differenceBy from 'lodash/differenceBy'
import fromPairs from 'lodash/fromPairs'
import map from 'lodash/map'
import noop from 'lodash/noop'

import {
  Button,
  Divider,
  IconButton,
  Loader,
  Modal,
  Row,
  Text,
} from 'Components/UI'

import { LINKEDIN_REGEX } from 'Constants/regex'

import { useAdminMutation, useMutation } from 'Services/Apollo'
import _ from 'Services/I18n'
import toast from 'Services/Toast'

import { FIELD } from './fields'
import { GeneralTab, TagsTab } from './Tabs'
import { entitiesToValues, tagsByKind } from './utils'

const TABS = {
  GENERAL_INFO: 'General Info',
  TAGS: 'Tags',
}

const DEFAULT_TABS = [TABS.GENERAL_INFO, TABS.TAGS]

function EditCommunityUser({ user, communityId, isOpen, onClose }) {
  const [loading, setLoading] = useState(false)
  const [tab, setTab] = useState(TABS.GENERAL_INFO)

  const [updateUser] = useAdminMutation(updateUserMutation)
  const [connectUsersToTags] = useMutation(connectUsersToTagsMutation)
  const [connectUsersToSkills] = useAdminMutation(connectUsersToSkillsMutation)
  const [removeUserSkills] = useAdminMutation(removeUserSkillsMutation)
  const [disconnectUsersFromTags] = useMutation(disconnectUsersFromTagsMutation)

  const initialSkills = useMemo(() => entitiesToValues(user?.skills), [user])
  const initialTags = useMemo(
    () => tagsByKind(entitiesToValues(user?.tags)),
    [user],
  )

  const initialValues = useMemo(
    () => ({
      [FIELD.EMAIL]: user?.email,
      [FIELD.FIRST_NAME]: user?.firstName,
      [FIELD.LAST_NAME]: user?.lastName,
      [FIELD.PHOTO_URL]: user?.photoUrl,
      [FIELD.JOB]: user?.jobTitle?.name,
      [FIELD.LINKED_IN]: user?.linkedInUrl || null,
      [FIELD.PHONE_NUMBER]: user?.phoneNumber,
      [FIELD.ORGANIZATION]: user?.organization?.name,
      [FIELD.INTERESTS_HOBBIES]: user?.interestsHobbies,
      [FIELD.ABOUT]: user?.about,
      [FIELD.SKILLS]: initialSkills,
      [FIELD.CUSTOM]: entitiesToValues(initialTags.custom),
      [FIELD.PROJECT]: entitiesToValues(initialTags.projects),
      [FIELD.ROLE]: entitiesToValues(initialTags.roles),
      [FIELD.EVENT]: entitiesToValues(initialTags.events),
      [FIELD.GROUP]: entitiesToValues(initialTags.groups),
    }),
    [user, initialTags, initialSkills],
  )

  const formConstraints = useMemo(
    () => ({
      [FIELD.PHOTO_URL]: {
        type: 'string',
      },
      [FIELD.FIRST_NAME]: {
        type: 'string',
        presence: {
          allowEmpty: false,
          message: `^${_('auth.shared.firsNameRequired')}`,
        },
        length: {
          maximum: 255,
          tooLong: `^${_('auth.shared.firsNameTooLong')}`,
        },
      },
      [FIELD.LAST_NAME]: {
        type: 'string',
        presence: {
          allowEmpty: false,
          message: `^${_('auth.shared.lastNameRequired')}`,
        },
        length: {
          maximum: 255,
          tooLong: `^${_('auth.shared.lastNameTooLong')}`,
        },
      },
      [FIELD.JOB]: {
        type: 'string',
        length: {
          maximum: 255,
          tooLong: `^${_('auth.shared.jobTooLong')}`,
        },
      },
      [FIELD.LINKED_IN]: {
        type: 'string',
        format: {
          pattern: LINKEDIN_REGEX,
          message: `^${_('auth.shared.linkedinProfileUrl')}`,
        },
      },
      [FIELD.EMAIL]: {
        presence: {
          allowEmpty: false,
          message: `^${_('auth.shared.emailRequired')}`,
        },
        email: {
          email: true,
          message: `^${_('auth.shared.emailInvalid')}`,
        },
      },
      [FIELD.ORGANIZATION]: {
        type: 'string',
        length: {
          maximum: 255,
          tooLong: `^${_('auth.shared.organizationTooLong')}`,
        },
      },
      [FIELD.INTERESTS_HOBBIES]: {
        type: 'string',
      },
      [FIELD.ABOUT]: {
        type: 'string',
      },
    }),
    [],
  )

  const handleFinish = useCallback(
    async success => {
      onClose?.(success)
    },
    [onClose],
  )

  const handleCancel = useCallback(() => {
    handleFinish(false).then()
  }, [handleFinish])

  const submit = useCallback(
    async values => {
      setLoading(true)

      const tags = [
        ...values[FIELD.EVENT],
        ...values[FIELD.GROUP],
        ...values[FIELD.PROJECT],
        ...values[FIELD.ROLE],
        ...values[FIELD.CUSTOM],
      ].map(tag => ({
        id: tag.value,
        name: tag.label,
        kind: tag.kind,
      }))

      const skills = [...values[FIELD.SKILLS]].map(skill => ({
        id: skill.value,
        name: skill.label,
      }))

      const changedValues = fromPairs(
        Object.entries(values)?.filter(
          ([key, value]) => initialValues?.[key] !== value,
        ),
      )

      const skillIds = map(values[FIELD.SKILLS], 'value')
      const tagIds = map(tags, 'value')

      const removedSkills = differenceBy(
        initialSkills,
        values[FIELD.SKILLS],
        'value',
      )

      const removedTags = differenceBy(
        Object.values(initialTags).flat(),
        tags,
        'value',
      )

      try {
        const userId = user?.userId
        const communityUserId = user?.communityUserId

        await updateUser({
          variables: {
            communityId,
            userId,
            ...changedValues,
          },
        })

        if (removedSkills?.length) {
          await removeUserSkills({
            variables: {
              userId: user.id,
              communityId,
              skillIds: map(removedSkills, 'value'),
            },
          })
        }

        if (removedTags?.length) {
          const usersFromTags = removedTags.map(tag => ({
            communityUserId: user?.communityUserId,
            tagId: tag.value,
          }))

          await disconnectUsersFromTags({
            variables: {
              communityId,
              usersFromTags,
            },
          })
        }

        if (skillIds?.length) {
          const communityUsers = [
            {
              communityUserId,
              userId,
              communityId,
            },
          ]

          const usersToSkills = []
          skillIds.forEach(skillId => {
            usersToSkills.push({
              skillId,
              communityUserId,
            })
          })

          await connectUsersToSkills({
            variables: {
              communityId,
              usersToSkills,
            },
            update: getCommunityUserSkillsUpdater({
              communityUsers,
              skills,
            }),
          })
        }

        if (tagIds?.length) {
          const usersToTags = []
          tagIds.forEach(tagId => {
            usersToTags.push({
              tagId,
              communityUserId,
            })
          })

          const communityUsers = [
            {
              communityUserId,
              userId,
              communityId,
            },
          ]

          await connectUsersToTags({
            variables: {
              communityId,
              usersToTags,
            },
            update: getCommunityUserTagsUpdater({
              communityIds: [communityId],
              communityUsers,
              tags,
            }),
          })
        }

        toast.success({
          title: 'Community User',
          text: `Community user updated`,
        })

        setLoading(false)

        handleFinish(true).then()
      } catch (error) {
        toast.error({
          title: 'Server error',
          text: _(`error.${error?.message || 'generic'}`),
        })
        setLoading(false)
      }
    },
    [
      communityId,
      connectUsersToSkills,
      connectUsersToTags,
      disconnectUsersFromTags,
      handleFinish,
      initialSkills,
      initialTags,
      initialValues,
      removeUserSkills,
      updateUser,
      user?.communityUserId,
      user?.id,
      user?.userId,
    ],
  )

  return (
    <Modal
      isOpen={isOpen}
      minWidth="640px"
      shouldCloseOnOverlayClick={false}
      title="Edit User Profile"
      onClose={handleCancel}
    >
      <Form
        initialValues={initialValues}
        render={({ handleSubmit, form }) => {
          const formErrors = Utils.Form.errors(form, FIELD, {
            checkDirty: true,
          })

          return (
            <>
              <Row fullWidth gap={5}>
                {DEFAULT_TABS.map((item, index) => (
                  <Row center gap={3} key={item}>
                    <IconButton
                      gap={3}
                      index={item}
                      outline={item !== tab}
                      onClick={() => setTab(item)}
                    >
                      {index + 1}
                    </IconButton>
                    <Text header header3>
                      {item}
                    </Text>
                  </Row>
                ))}
              </Row>

              <Divider my={5} />

              {tab === TABS.GENERAL_INFO && (
                <GeneralTab canEditEmail={user?.isStub} />
              )}

              {tab === TABS.TAGS && <TagsTab communityId={communityId} />}

              {Object.values(formErrors).length > 0 && (
                <Row center justifyEnd mt={4}>
                  <Text actionMedium error>
                    * Please fill all required fields!
                  </Text>
                </Row>
              )}

              <Divider my={5} />

              <Row center mt={5} spaceBetween>
                {loading ? (
                  <Row fullWidth justifyCenter>
                    <Loader />
                  </Row>
                ) : (
                  <>
                    <Button
                      secondary
                      small
                      onClick={e => {
                        e.preventDefault()
                        handleCancel()
                      }}
                    >
                      {_('general.cancel')}
                    </Button>

                    <Button small onClick={handleSubmit}>
                      {_('general.save')}
                    </Button>
                  </>
                )}
              </Row>
            </>
          )
        }}
        validate={values => validate(values, formConstraints)}
        onSubmit={submit}
      />
    </Modal>
  )
}

EditCommunityUser.defaultProps = {
  communityId: null,
  isOpen: false,
  user: null,
  onClose: noop,
}

EditCommunityUser.propTypes = {
  communityId: PropTypes.string,
  isOpen: PropTypes.bool,
  user: PropTypes.object,
  onClose: PropTypes.func,
}

export default EditCommunityUser
