import React from 'react'

import { Checkbox } from 'Components/UI'

import { useTableContext } from '../context'

function SelectAllHeader() {
  const { onSelectAll, isSelectedAll } = useTableContext()

  return (
    <Checkbox
      checked={isSelectedAll}
      rounded
      secondary
      small
      onChange={onSelectAll}
    />
  )
}

export default SelectAllHeader
