import React from 'react'

import { Column, Row, Switch, Text } from 'Components/UI'

function Switches() {
  return (
    <Column gap={6}>
      <Text header1>Switches</Text>
      <Row gap={5}>
        <Switch label="Enabled" />
        <Switch error="Error Text" hasError label="With error" />
        <Switch disabled label="Disabled off" />
        <Switch checked disabled label="Disabled on" />
      </Row>
    </Column>
  )
}

export default Switches
