import React from 'react'

import { pick } from '@styled-system/props'

import { Column } from 'Components/UI/Flex'
import Text from 'Components/UI/Text'

import {
  Container,
  IContainerProps,
  InvisibleCheckbox,
  StyledSwitch,
} from './styles'

export interface ISwitch extends IContainerProps {
  checked?: boolean
  disabled?: boolean
  error?: React.ReactNode
  hasError?: boolean
  label?: React.ReactNode
  labelPosition?: 'left' | 'right'
  name?: string
  onBlur?: React.FocusEventHandler
  onChange?: React.ChangeEventHandler
  onFocus?: React.FocusEventHandler
}

function Switch({
  checked,
  disabled,
  error,
  hasError,
  label,
  labelPosition = 'right',
  name,
  onBlur,
  onChange,
  onFocus,
  ...rest
}: ISwitch) {
  return (
    <Column>
      <Container {...pick(rest)}>
        <InvisibleCheckbox
          checked={checked}
          disabled={disabled}
          name={name}
          onBlur={onBlur}
          onChange={onChange}
          onFocus={onFocus}
        />

        {label && labelPosition === 'left' && (
          <Text as="div" disabled={disabled} header={!disabled} header4>
            {label}
          </Text>
        )}

        <StyledSwitch />

        {label && labelPosition === 'right' && (
          <Text as="div" disabled={disabled} header={!disabled} header4>
            {label}
          </Text>
        )}
      </Container>

      {hasError && (
        <Text bodySmall error>
          {error}
        </Text>
      )}
    </Column>
  )
}

export default Switch
