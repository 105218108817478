import React from 'react'
import { TableInstance } from 'react-table'

import { pick } from '@styled-system/props'

import {
  IconArrowsSort,
  IconSortAscending,
  IconSortDescending,
} from '@tabler/icons-react'

import isEmpty from 'lodash/isEmpty'
import map from 'lodash/map'

import Loader from 'Components/UI/Loader'
import Text from 'Components/UI/Text'

import {
  Body,
  Header,
  HeaderCell,
  RowCell,
  ScrollWrapper,
  StyledTable,
  TableRow,
} from './styles'

export interface ITableProps<D extends object> extends TableInstance<D> {
  contentOffset?: number
  loading?: boolean
  modal?: boolean
}

function Table<D extends object>({
  loading = false,
  getTableProps,
  getTableBodyProps,
  headerGroups = [],
  modal,
  page = [],
  prepareRow,
  contentOffset,
  ...rest
}: ITableProps<D>) {
  return (
    <StyledTable {...pick(rest)} {...getTableProps?.()} className="table">
      <ScrollWrapper width={modal ? '100%' : ['900px', '900px', '100%']}>
        <Header className="header" contentOffset={contentOffset}>
          {map(headerGroups, headerGroup => (
            <TableRow {...headerGroup.getHeaderGroupProps()}>
              {map(headerGroup.headers, column => (
                <HeaderCell
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                  right={column?.headerRight}
                >
                  <Text as="div" header4>
                    {column.render('Header')}
                  </Text>

                  {column.canSort && (
                    <>
                      {!column.isSorted && <IconArrowsSort size={16} />}

                      {column.isSorted &&
                        (column.isSortedDesc ? (
                          <IconSortDescending size={16} />
                        ) : (
                          <IconSortAscending size={16} />
                        ))}
                    </>
                  )}
                </HeaderCell>
              ))}
            </TableRow>
          ))}
        </Header>

        <Body {...getTableBodyProps?.()}>
          {
            // TODO: refactor
            // eslint-disable-next-line no-nested-ternary
            loading ? (
              <TableRow>
                <RowCell center info>
                  <Loader />
                </RowCell>
              </TableRow>
            ) : isEmpty(page) ? (
              <TableRow className="table-row">
                <RowCell center info>
                  No entities found
                </RowCell>
              </TableRow>
            ) : (
              map(page, row => {
                prepareRow?.(row)

                return (
                  <TableRow {...row.getRowProps()} className="table-row">
                    {map(row.cells, cell => (
                      <RowCell
                        {...cell.getCellProps()}
                        center={cell?.column?.cellCenter}
                        right={cell?.column?.cellRight}
                        title={cell?.value ?? ''}
                      >
                        {cell.render('Cell')}
                      </RowCell>
                    ))}
                  </TableRow>
                )
              })
            )
          }
        </Body>
      </ScrollWrapper>
    </StyledTable>
  )
}

export default Table
