import { useMemo } from 'react'

import Utils from 'Utils'

import { InviteCell, InvitedByCell, StatusCell } from './Cells'

export function useColumns() {
  return useMemo(
    () => [
      {
        Header: 'Invite',
        accessor: 'toUser',
        Cell: InviteCell,
        width: 1,
      },
      {
        Header: 'Invited By',
        accessor: 'fromUser',
        Cell: InvitedByCell,
        width: 1,
      },
      {
        Header: 'Date',
        accessor: 'createdAt',
        Cell: Utils.Table.renderDateTimeCell,
        width: 0.7,
      },
      {
        Header: 'Status',
        accessor: 'state',
        Cell: StatusCell,
        width: 0.5,
      },
    ],
    [],
  )
}
