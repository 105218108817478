import React from 'react'
import PropTypes from 'prop-types'

import { getFullName } from 'Utils/User'

import Avatar from 'Components/Blocks/Avatar'
import { Row, Text } from 'Components/UI'

function Creator({ value }) {
  const profile = value?.profile

  return (
    <Row center>
      <Avatar borderRadius={100} mr={3} size={32} src={profile?.photoUrl} />
      <Text primary>{getFullName(profile)}</Text>
    </Row>
  )
}

Creator.defaultProps = {
  value: {},
}

Creator.propTypes = {
  value: PropTypes.object,
}

export default Creator
