import React, { MouseEventHandler } from 'react'

import { pick } from '@styled-system/props'

import { IconX } from '@tabler/icons-react'

import Text from 'Components/UI/Text'

import { Container, IContainer, RemoveButton } from './styles'

export interface ITag extends IContainer {
  removable?: boolean
  text?: string
  renderAfter?: React.ReactNode
  renderBefore?: React.ReactNode
  onRemove?: MouseEventHandler<HTMLButtonElement>
}

function Tag({
  colorKind,
  maxWidth,
  removable = false,
  renderAfter,
  renderBefore,
  small = false,
  text,
  onClick,
  onRemove,
  ...rest
}: ITag) {
  if (!text) return null

  return (
    <Container
      {...pick(rest)}
      colorKind={colorKind}
      maxWidth={maxWidth}
      small={small}
      onClick={onClick}
    >
      {renderBefore}
      <Text ellipsis tagLarge={!small} tagSmall={small}>
        {text}
      </Text>
      {renderAfter}

      {removable && (
        <RemoveButton onClick={onRemove}>
          <IconX />
        </RemoveButton>
      )}
    </Container>
  )
}

export default Tag
