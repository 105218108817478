import React from 'react'

import { Row, Switch } from 'Components/UI'
import { Text } from 'Components/UI/_v2'

import { useScopedI18n } from 'Services/I18n'

import colors from 'Theme/_v2/colors'

export interface IIsPublicFieldProps {
  value?: boolean
  isDisabled?: boolean
  onChange?: React.ChangeEventHandler<HTMLInputElement>
}

// TODO: update translation references
const IsPublicField = (props: IIsPublicFieldProps) => {
  const t = useScopedI18n('features.notes.components.noteForm.isPublicField')

  return (
    <Row center gap={2}>
      <Switch
        checked={props.value}
        disabled={props.isDisabled}
        onChange={props.onChange}
      />
      <Text color={colors.text.primary} fontWeight={600} variant="body-xs">
        {t('label')}
      </Text>
    </Row>
  )
}

export default IsPublicField
