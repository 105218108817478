import React from 'react'
import { Outlet } from 'react-router-dom'

import { Container } from './styles'

// TODO can this be deleted?
function Public() {
  return (
    <Container>
      Public layout <Outlet />
    </Container>
  )
}

export default Public
