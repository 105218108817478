import React, { useCallback } from 'react'
import { toast } from 'react-toastify'

import hubSpotOAuthUrlMutation from 'GraphQL/Mutations/CommunityHubSpots/hubSpotOAuthUrl.graphql'

import { Button } from 'Components/UI'

import { useCommunityContext } from 'Hooks'

import { useMutation } from 'Services/Apollo'
import _ from 'Services/I18n'
import { setCommunity } from 'Services/Store/community'

export interface IConnectHubSpot {
  enabled?: boolean
  text?: string
}

function ConnectHubSpot({ text = 'Connect', enabled }: IConnectHubSpot) {
  const { community } = useCommunityContext()

  const [hubSpotOAuthUrl] = useMutation<
    Pick<MainSchema.Mutation, 'hubSpotOAuthUrl'>,
    MainSchema.MutationHubSpotOAuthUrlArgs
  >(hubSpotOAuthUrlMutation)

  const handleConnect = useCallback(async () => {
    if (!community) {
      return
    }

    try {
      const response = await hubSpotOAuthUrl({
        variables: { communityId: community.id },
      })

      if (response.data?.hubSpotOAuthUrl) {
        setCommunity(community)
        window.location.href = response.data.hubSpotOAuthUrl
      } else {
        toast.error(`Something went wrong`)
      }
    } catch (error) {
      let message = _(`error.generic`)

      if (error instanceof Error) {
        message = error.message
      }

      toast.error(`Something went wrong: "${message}"`)
    }
  }, [community, hubSpotOAuthUrl])

  return (
    <Button disabled={!enabled} onClick={handleConnect}>
      {text}
    </Button>
  )
}

export default ConnectHubSpot
