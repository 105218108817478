import { StoreonModule } from 'storeon'

import get from 'lodash/get'
import orderBy from 'lodash/orderBy'

import { getStore } from 'Services/Shared'

export const SEARCH_HISTORY_STATE_NAMESPACE = 'searchHistory'
const MAX_SEARCH_HISTORY_ITEMS = 5

type SearchHistoryItemId = string

enum SearchHistoryItemStateField {
  CreatedAt = 'createdAt',
}

export interface ISearchHistoryItem {
  id: SearchHistoryItemId
  query: string
  [SearchHistoryItemStateField.CreatedAt]: Date
}

export interface SearchHistoryState {
  [SEARCH_HISTORY_STATE_NAMESPACE]: ISearchHistoryItem[]
}

enum SearchHistoryEvent {
  Add = `${SEARCH_HISTORY_STATE_NAMESPACE}/add`,
  Remove = `${SEARCH_HISTORY_STATE_NAMESPACE}/remove`,
}

export interface SearchHistoryStateEvents {
  [SearchHistoryEvent.Add]: ISearchHistoryItem
  [SearchHistoryEvent.Remove]: SearchHistoryItemId
}

export function getSearchHistory(): ISearchHistoryItem[] {
  return orderBy(
    get(getStore().get(), SEARCH_HISTORY_STATE_NAMESPACE),
    SearchHistoryItemStateField.CreatedAt,
    'desc',
  )
}

export function addSearchHistoryItem(searchHistoryItem: ISearchHistoryItem) {
  return getStore().dispatch(SearchHistoryEvent.Add, searchHistoryItem)
}

export function removeSearchHistoryItem(id: SearchHistoryItemId) {
  return getStore().dispatch(SearchHistoryEvent.Remove, id)
}

const searchHistoryStateModule: StoreonModule<
  SearchHistoryState,
  SearchHistoryStateEvents
> = store => {
  store.on('@init', () => ({
    searchHistory: [],
  }))

  store.on(
    SearchHistoryEvent.Add,
    (state, searchHistoryItem: ISearchHistoryItem) => {
      let currentSearchHistory = orderBy(
        state[SEARCH_HISTORY_STATE_NAMESPACE],
        SearchHistoryItemStateField.CreatedAt,
        'desc',
      )
      // remove any exact matching queries
      currentSearchHistory = currentSearchHistory.filter(
        currentSearchHistoryItem =>
          currentSearchHistoryItem.query !== searchHistoryItem.query,
      )

      currentSearchHistory.unshift(searchHistoryItem)
      currentSearchHistory.length =
        currentSearchHistory.length > MAX_SEARCH_HISTORY_ITEMS
          ? MAX_SEARCH_HISTORY_ITEMS
          : currentSearchHistory.length

      return { [SEARCH_HISTORY_STATE_NAMESPACE]: currentSearchHistory }
    },
  )

  store.on(SearchHistoryEvent.Remove, (state, id: SearchHistoryItemId) => {
    const currentSearchHistory = state[SEARCH_HISTORY_STATE_NAMESPACE]

    const searchHistory = currentSearchHistory.filter(
      searchHistoryItem => searchHistoryItem.id !== id,
    )

    return { [SEARCH_HISTORY_STATE_NAMESPACE]: searchHistory }
  })
}

export default searchHistoryStateModule
