import React, { useCallback, useState } from 'react'

import { Checkbox, Column, Row, Text } from 'Components/UI'

function Checkboxes() {
  const [value, setValue] = useState<boolean | undefined>(false)

  const handleClick = useCallback(() => {
    if (value === false) {
      setValue(true)
      return
    }
    if (value === true) {
      setValue(undefined)
      return
    }
    setValue(false)
  }, [value])

  return (
    <Column gap={6}>
      <Text header1>Checkboxes</Text>
      <Row gap={5}>
        <Checkbox
          checked={value}
          indeterminate={value === undefined}
          label="Enabled checkbox"
          onChange={handleClick}
        />
        <Checkbox
          checked={value}
          error="Error text"
          hasError
          indeterminate={value === undefined}
          label="Enabled checkbox with error"
          onChange={handleClick}
        />
        <Checkbox checked disabled label="Disabled checked" />
        <Checkbox disabled label="Disabled unchecked" />
      </Row>
    </Column>
  )
}

export default Checkboxes
