import React, { useCallback } from 'react'
import { Form } from 'react-final-form'
import PropTypes from 'prop-types'

import { useMutation } from '@apollo/client'
import updateCommunityUsersStatusMutation from 'GraphQL/Mutations/Community/updateCommunityUsersStatus.graphql'
import { getFullName } from 'Utils/User'
import validate from 'validate.js'

import map from 'lodash/map'

import { Button, Column, Modal, Row, SelectField, Text } from 'Components/UI'
import DropdownIndicator from 'Components/UI/Form/Select/Components/DropdownIndicator'

import { COMMUNITY_USER_STATUS } from 'Constants/ids'

import { useScopedI18n } from 'Services/I18n'
import toast from 'Services/Toast'

import { Content, FormContent } from './styles'

const FIELD = {
  STATUS: 'status',
}

const STATUS_OPTIONS = [
  { label: 'Full', value: COMMUNITY_USER_STATUS.FULL },
  { label: 'Pending', value: COMMUNITY_USER_STATUS.PENDING },
  { label: 'Archived', value: COMMUNITY_USER_STATUS.ARCHIVED },
]

function validateForm(values) {
  return validate(values, {
    [FIELD.STATUS]: {
      presence: true,
    },
  })
}

function UpdateCommunityUserStatus({
  content,
  users,
  communityId,
  onClose,
  ...rest
}) {
  const s = useScopedI18n('modals.updateCommunityUserStatus')

  const [updateCommunityUsersStatus] = useMutation(
    updateCommunityUsersStatusMutation,
  )

  const handleFinish = useCallback(
    async success => {
      onClose(success)
    },
    [onClose],
  )

  const handleCancel = useCallback(() => handleFinish(false), [handleFinish])

  const handleUpdate = useCallback(
    async values => {
      try {
        await updateCommunityUsersStatus({
          variables: {
            communityId,
            userIds: map(users, 'userId'),
            status: values[FIELD.STATUS].value,
          },
        })
        toast.success({
          title: s('title'),
          text: s('updateSuccess'),
        })
        await handleFinish(true)
      } catch (error) {
        toast.error({
          title: s('title'),
          text: error.message,
        })
        await handleFinish(false)
      }
    },
    [users, communityId, handleFinish, updateCommunityUsersStatus, s],
  )

  return (
    <Modal title={'Update users status'} onClose={onClose} {...rest}>
      <Content>
        <Text fontSize="16px" fontWeight={500}>
          {s('content')}{' '}
          <span>{users.map(item => `${getFullName(item)}`).join(', ')}</span>?
        </Text>

        <Form
          render={({ handleSubmit, invalid }) => (
            <FormContent mt={5} onSubmit={handleSubmit}>
              <Column fullWidth mr={24}>
                <Text bold mb={2}>
                  {s('form.label')}
                </Text>
                <SelectField
                  blurInputOnSelect
                  components={{
                    DropdownIndicator,
                  }}
                  fullWidth
                  label=""
                  name={FIELD.STATUS}
                  options={STATUS_OPTIONS}
                  placeholder={s('form.placeholder')}
                />
              </Column>

              <Row fullWidth mt={10} spaceBetween>
                <Button big bold secondary width="100px" onClick={handleCancel}>
                  {s('actions.cancel')}
                </Button>

                <Button
                  big
                  bold
                  disabled={invalid}
                  width="100px"
                  onClick={handleSubmit}
                >
                  {s('actions.ok')}
                </Button>
              </Row>
            </FormContent>
          )}
          validate={validateForm}
          onSubmit={handleUpdate}
        />
      </Content>
    </Modal>
  )
}

UpdateCommunityUserStatus.defaultProps = {
  communityId: null,
  users: [],
}

UpdateCommunityUserStatus.propTypes = {
  ...Modal.propTypes,
  communityId: PropTypes.string,
  users: PropTypes.arrayOf(PropTypes.object),
}

export default UpdateCommunityUserStatus
