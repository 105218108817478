import { useMemo } from 'react'

import Utils from 'Utils'
import { getFullName } from 'Utils/User'

import {
  ActionsCell,
  AgreementCell,
  CheckboxCell,
  CreatorCell,
  SourceCell,
} from './Cells'
import { CheckAllHeader } from './Headers'

function renderFullName({ row }) {
  return getFullName(row?.original)
}

export function useColumns({ showHeader = true }) {
  return useMemo(() => {
    const columns = [
      {
        id: 'user',
        Header: CheckAllHeader,
        Cell: CheckboxCell,
        width: '16px',
        disableSortBy: true,
      },
      {
        Header: 'Name',
        id: 'firstName',
        Cell: renderFullName,
        width: 1.5,
        canSort: true,
      },
      {
        Header: 'Email',
        accessor: 'email',
        width: 1.5,
        disableSortBy: true,
      },
      {
        Header: 'Status',
        accessor: 'status',
        width: 0.5,
        disableSortBy: true,
      },
      {
        Header: 'Source',
        accessor: 'source',
        Cell: SourceCell,
        width: 0.65,
        disableSortBy: true,
      },
      {
        Header: 'Terms & Privacy',
        Cell: AgreementCell,
        width: 0.5,
        disableSortBy: true,
      },
      {
        Header: 'Role',
        accessor: 'role',
        width: 0.5,
        disableSortBy: true,
      },
      {
        Header: 'Сreator',
        accessor: 'creator',
        Cell: CreatorCell,
        width: 1,
        disableSortBy: true,
      },
      {
        Header: 'Created at',
        accessor: 'createdAt',
        Cell: Utils.Table.renderDateTimeCell,
        width: 1,
      },
      {
        Header: 'Actions',
        Cell: ActionsCell,
        width: 0.9,
        cellRight: true,
        headerRight: true,
        disableSortBy: true,
      },
    ]

    if (!showHeader) columns.shift()

    return columns
  }, [showHeader])
}
