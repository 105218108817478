import { ApolloCache, MutationUpdaterFn } from '@apollo/client'
import { AsStoreObject } from '@apollo/client/utilities'
import notificationsQuery from 'GraphQL/Queries/Community/notifications.graphql'

export const readNotificationUpdater: MutationUpdaterFn<
  Pick<MainSchema.Mutation, 'readNotification'>
> = (store, notification) => {
  store.modify({
    id: store.identify(notification as AsStoreObject<MainSchema.Notification>),
    fields: {
      read() {
        return true
      },
    },
  })
}

export const readAllNotificationsUpdater = (
  store: ApolloCache<Pick<MainSchema.Mutation, 'acceptProposal'>>,
  communityId: string,
  limit?: number,
) => {
  const query = {
    query: notificationsQuery,
    variables: {
      limit,
      communityId,
    },
  }

  const queryData = store.readQuery<
    Pick<MainSchema.Query, 'notifications'>,
    MainSchema.QueryNotificationsArgs
  >(query)

  if (queryData) {
    store.writeQuery<
      Pick<MainSchema.Query, 'notifications'>,
      MainSchema.QueryNotificationsArgs
    >({
      ...query,
      data: {
        notifications: {
          ...queryData.notifications,
          rows: [
            ...queryData.notifications.rows.map(item => ({
              ...item,
              read: true,
            })),
          ],
        },
      },
    })
  }
}
