import styled, { css } from 'styled-components'
import { margin, MarginProps } from 'styled-system'
import { themeGet } from '@styled-system/theme-get'

const absoluteCss = ({ absolute }: { absolute?: boolean }) =>
  absolute &&
  css`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  `

export interface IContainer extends MarginProps {
  absolute?: boolean
}

export const Container = styled.div<IContainer>`
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${themeGet('colors.text.heading')};

  ${margin};

  ${absoluteCss};

  svg {
    animation: spin 2s infinite linear;

    @keyframes spin {
      100% {
        transform: rotate(360deg);
      }
    }
  }
`
export interface IText extends MarginProps {}

export const Text = styled.div<IText>`
  font-family: ${themeGet('font.fontFamily')};
  font-size: ${themeGet('fontSize.2')}px;
  font-weight: ${themeGet('fontWeight.1')};
  line-height: 143%;
  color: ${themeGet('colors.text.header')};

  ${margin};
`
