import React from 'react'

import { useSlate } from 'slate-react'
import { CustomTextMark } from 'Types/slate'

import { Button } from './styles'
import { isLeafActive, toggleLeaf } from './utils'

export interface ILeafButtonProps {
  mark: CustomTextMark
  icon: React.ReactNode
}

function LeafButton({ mark, icon }: ILeafButtonProps) {
  const editor = useSlate()

  return (
    <Button
      active={isLeafActive(editor, mark)}
      onMouseDown={event => {
        event.preventDefault()
        toggleLeaf(editor, mark)
      }}
    >
      {icon}
    </Button>
  )
}

export default LeafButton
