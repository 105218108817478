import { css } from 'styled-components'
import { themeGet } from '@styled-system/theme-get'

import customScrollbar from 'Theme/styles/customScrollbar'

export default css`
  & .react-select__menu-portal {
    z-index: 9999 !important;
  }

  & .react-select__menu {
    padding: 0 !important;
    margin: ${themeGet('space.1')}px 0 !important;
    overflow: hidden;
    border-radius: 4px !important;
    box-shadow: ${themeGet('shadows.box')} !important;

    & .react-select__menu-list {
      padding: 0;
      color: ${themeGet('colors.text.header')} !important;
      font-size: ${themeGet('fontSize.1')}px !important;
      font-weight: ${themeGet('fontWeight.0')} !important;
      overflow-x: hidden;

      ${customScrollbar}

      & .react-select__option--is-focused {
        background-color: ${themeGet('colors.bg.hover')} !important;
        color: ${themeGet('colors.main')} !important;
        cursor: pointer;
      }

      & .react-select__option--is-selected {
        background-color: ${themeGet('colors.main')} !important;
        color: ${themeGet('colors.text.contrast')} !important;
      }
    }
  }
`
