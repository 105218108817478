import React from 'react'
import { Field } from 'react-final-form'

import { pick } from '@styled-system/props'

import Utils from 'Utils'

import Text from 'Components/UI/Text'

import Switch, { ISwitch } from './Switch'

export interface ISwitchField extends ISwitch {
  checkErrorIfDirty?: boolean
  displayError?: boolean
  name: string
}

function SwitchField({
  checkErrorIfDirty,
  displayError = true,
  label,
  name,
  disabled,
  ...rest
}: ISwitchField) {
  return (
    <Field name={name} type="checkbox">
      {({ input: { checked, onChange: handleChange }, meta }) => {
        const { hasError, error } = Utils.Form.hasError(meta, checkErrorIfDirty)

        return (
          <>
            <Switch
              {...pick(rest)}
              checked={checked}
              disabled={disabled}
              error={error}
              hasError={hasError}
              label={label}
              onChange={handleChange}
            />
            {displayError && hasError && (
              <Text error mt={1}>
                {error}
              </Text>
            )}
          </>
        )
      }}
    </Field>
  )
}

export default SwitchField
