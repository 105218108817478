import React, { useCallback, useMemo } from 'react'
import { GroupBase } from 'react-select'

import debounce from 'awesome-debounce-promise'
import useCommunitySearch, {
  COMMUNITY_SEARCH_DEBOUNCE,
  SearchCommunityType,
} from 'Features/CommunitySearch/useCommunitySearch'

import { SelectField } from 'Components/UI'
import { Text } from 'Components/UI/_v2'

import { SkillKind, SkillTagKind } from 'Constants/ids'
import { TagKind } from 'Constants/mainGraphQL'

import { useCommunityContext } from 'Hooks'

import { useScopedI18n } from 'Services/I18n'
import toast from 'Services/Toast'

import * as Styled from './SkillTagSelectField.styles'

export interface SkillTagOption {
  label: string
  value: string | null
  kind: SkillTagKind
}

export interface SkillTagSelectFieldProps {
  name: string
  label: string
  placeholder?: string
  isRequired?: boolean
}

const skillToOption = (
  skill: MainSchema.Skill,
  kind: SkillKind,
): SkillTagOption => ({
  label: skill.name || 'N/A',
  value: skill.id,
  kind,
})

const tagToOption = (tag: MainSchema.Tag): SkillTagOption => ({
  label: tag.name,
  value: tag.id,
  kind: tag.kind,
})

interface SkillTagGroupLabelProps {
  group: GroupBase<SkillTagOption>
}

// TODO: refactor to a shared component
const SkillTagGroupLabel: React.FC<SkillTagGroupLabelProps> = props => {
  return (
    <Styled.Heading>
      <Styled.HeadingLabel>{props.group.label}</Styled.HeadingLabel>
      <Styled.HeadingBadge>{props.group.options.length}</Styled.HeadingBadge>
    </Styled.Heading>
  )
}

interface SkillTagOptionLabelProps {
  option: SkillTagOption
}

// TODO: refactor to a shared component
const SkillTagOptionLabel: React.FC<SkillTagOptionLabelProps> = props => {
  return (
    <Text fontWeight={500} variant="body-s">
      {props.option.label}
    </Text>
  )
}

// TODO: support multiple being selected
// TODO: support only a specific kind of tag
const SkillTagSelectField: React.FC<SkillTagSelectFieldProps> = props => {
  const t = useScopedI18n('components.blocks.forms.fields.skillTagSelectField')
  const errorT = useScopedI18n('error')
  const { community } = useCommunityContext()
  const communitySearch = useCommunitySearch()

  const loadOptions = useCallback(
    async (inputValue: string) => {
      if (!community) {
        throw new Error('Community is not defined')
      }

      try {
        const limit = 10
        const groups: GroupBase<SkillTagOption>[] = []
        const results = await communitySearch.searchCommunity({
          communityIds: [community.id],
          searchText: inputValue,
          types: [
            SearchCommunityType.SemanticCustomTags,
            SearchCommunityType.DirectCustomTags,
            SearchCommunityType.SemanticEventTags,
            SearchCommunityType.DirectEventTags,
            SearchCommunityType.SemanticGroupTags,
            SearchCommunityType.DirectGroupTags,
            SearchCommunityType.SemanticIndustryTags,
            SearchCommunityType.DirectIndustryTags,
            SearchCommunityType.SemanticProjectTags,
            SearchCommunityType.DirectProjectTags,
            SearchCommunityType.SemanticRoleTags,
            SearchCommunityType.DirectRoleTags,
            SearchCommunityType.SemanticSkills,
            SearchCommunityType.DirectSkills,
          ],
          limit,
        })

        const skillResults = communitySearch.processSkills(
          results[SearchCommunityType.DirectSkills].data
            .directSearchCommunitySkills.edges,
          results[SearchCommunityType.SemanticSkills].data
            .semanticSearchCommunitySkills.results,
          limit,
        )
        const projectTagResults = communitySearch.processTags(
          results[SearchCommunityType.DirectProjectTags].data
            .directSearchCommunityTags.edges,
          results[SearchCommunityType.SemanticProjectTags].data
            .semanticSearchCommunityTags.results,
          limit,
        )
        const eventTagResults = communitySearch.processTags(
          results[SearchCommunityType.DirectEventTags].data
            .directSearchCommunityTags.edges,
          results[SearchCommunityType.SemanticEventTags].data
            .semanticSearchCommunityTags.results,
          limit,
        )
        const customTagResults = communitySearch.processTags(
          results[SearchCommunityType.DirectCustomTags].data
            .directSearchCommunityTags.edges,
          results[SearchCommunityType.SemanticCustomTags].data
            .semanticSearchCommunityTags.results,
          limit,
        )
        const groupTagResults = communitySearch.processTags(
          results[SearchCommunityType.DirectGroupTags].data
            .directSearchCommunityTags.edges,
          results[SearchCommunityType.SemanticGroupTags].data
            .semanticSearchCommunityTags.results,
          limit,
        )

        groups.push({
          label: t('groups.custom.label'),
          options: customTagResults.combinedTagResults.map(combinedTagResult =>
            tagToOption(combinedTagResult.node),
          ),
        })

        groups.push({
          label: t('groups.events.label'),
          options: eventTagResults.combinedTagResults.map(combinedTagResult =>
            tagToOption(combinedTagResult.node),
          ),
        })

        groups.push({
          label: t('groups.groups.label'),
          options: groupTagResults.combinedTagResults.map(combinedTagResult =>
            tagToOption(combinedTagResult.node),
          ),
        })

        groups.push({
          label: t('groups.projects.label'),
          options: projectTagResults.combinedTagResults.map(combinedTagResult =>
            tagToOption(combinedTagResult.node),
          ),
        })

        groups.push({
          label: t('groups.skills.label'),
          options: skillResults.combinedSkillResults.map(combinedSkillResult =>
            skillToOption(combinedSkillResult.node, SkillKind.Skill),
          ),
        })

        return groups
      } catch (error) {
        let message = errorT('generic')

        if (error instanceof Error) {
          message = errorT('server.message', {
            message: error.message,
          })
        }

        toast.error({
          title: errorT('server.title'),
          text: message,
        })
      }

      return []
    },
    [community, communitySearch, t, errorT],
  )

  const debouncedLoadOptions = useMemo(
    () => debounce(loadOptions, COMMUNITY_SEARCH_DEBOUNCE),
    [loadOptions],
  )

  const handleLoadOptions = useCallback(
    async (inputValue: string) => {
      if (communitySearch.isSearchTextValid(inputValue)) {
        return debouncedLoadOptions(inputValue)
      }

      return []
    },
    [communitySearch, debouncedLoadOptions],
  )

  const handleFormatCreateLabel = useCallback(
    (inputValue: string) => {
      return t('createNew.label.notEmpty', {
        name: inputValue,
      })
    },
    [t],
  )

  const handleGetNewOptionData = useCallback(
    (_: string, optionLabel: string) => ({
      label: optionLabel,
      value: null,
      kind: TagKind.Custom,
    }),
    [],
  )

  const handleIsValidNewOption = useCallback(
    (inputValue: string) => {
      return communitySearch.isSearchTextValid(inputValue)
    },
    [communitySearch],
  )

  const handleFormatGroupLabel = useCallback(
    (group: GroupBase<SkillTagOption>) => {
      return <SkillTagGroupLabel group={group} />
    },
    [],
  )

  const handleFormatOptionLabel = useCallback((option: SkillTagOption) => {
    return <SkillTagOptionLabel option={option} />
  }, [])

  return (
    <SelectField<SkillTagOption>
      async
      checkErrorIfDirty
      clearable
      creatable
      createOptionPosition="first"
      formatCreateLabel={handleFormatCreateLabel}
      formatGroupLabel={handleFormatGroupLabel}
      formatOptionLabel={handleFormatOptionLabel}
      getNewOptionData={handleGetNewOptionData}
      isValidNewOption={handleIsValidNewOption}
      label={props.label}
      loadOptions={handleLoadOptions}
      name={props.name}
      placeholder={props.placeholder}
      // TODO: Forcing top placement until https://github.com/JedWatson/react-select/issues/5642 is resolved
      placement="top"
      required={props.isRequired}
      withPortal
    />
  )
}

export default SkillTagSelectField
