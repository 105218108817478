import { IoIosArrowDown } from 'react-icons/io'

import { down } from 'styled-breakpoints'
import styled, { css } from 'styled-components'
import {
  margin,
  MarginProps,
  padding,
  PaddingProps,
  position,
  PositionProps,
  width,
  WidthProps,
} from 'styled-system'
import { themeGet } from '@styled-system/theme-get'

import { closeGlyph } from 'Assets/Svg'

import { Flex, Text } from 'Components/UI'
import { ValueContainerWrapper } from 'Components/UI/Form/Select/Components/styles'

import { gap, GapProps } from 'Theme/system'

export interface IDropDownOpenedBackgroundCssProps {
  $opened?: boolean
}

const dropDownOpenedBackgroundCss = ({
  $opened,
}: IDropDownOpenedBackgroundCssProps) =>
  $opened &&
  css`
    background: ${themeGet('colors.athensGray')};
  `

export interface IDropDownOpenedCssProps {
  $opened?: boolean
}

const dropDownOpenedCss = ({ $opened }: IDropDownOpenedCssProps) =>
  $opened &&
  css`
    transform: rotate(180deg);
  `

export interface IHiddenCssProps {
  hidden?: boolean
}

const hiddenCss = ({ hidden }: IHiddenCssProps) =>
  hidden &&
  css`
    transform: translateX(-100%);
    left: 12px;
    border-radius: 0;
  `

export interface IBorderBottomCssProps {
  borderBottom?: boolean
}

const borderBottomCss = ({ borderBottom }: IBorderBottomCssProps) =>
  borderBottom &&
  css`
    border-bottom: solid 1px ${themeGet('colors.grayLight')};
  `

export interface IContainerProps
  extends IBorderBottomCssProps,
    IHiddenCssProps,
    PaddingProps {}

export const Container = styled.div<IContainerProps>`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  position: relative;

  ${borderBottomCss}
  ${hiddenCss}
  ${padding}
`

export interface ITogglerProps extends MarginProps, GapProps {}

export const Toggler = styled.div<ITogglerProps>`
  display: flex;
  align-self: flex-start;
  justify-content: center;
  align-items: center;
  border-radius: 54px;
  cursor: pointer;
  height: 40px;

  > svg {
    margin-right: 8px;
  }

  ${margin}
  ${gap}
`

export interface IFormOpenedCssProps {
  $opened?: boolean
}

const formOpenedCss = ({ $opened }: IFormOpenedCssProps) =>
  $opened &&
  css`
    display: block;
  `

export interface IFormProps
  extends PositionProps,
    WidthProps,
    MarginProps,
    IFormOpenedCssProps {}

export const Form = styled.div<IFormProps>`
  display: none;
  width: 333px;
  border-radius: 4px;
  flex-direction: column;
  padding: 0;
  background-color: ${themeGet('colors.white')};
  flex-shrink: 0;
  box-shadow: 0 1px 4px ${themeGet('colors.grayAthens')};

  ${down('md')} {
    width: 95vw;
  }

  ${position}
  ${width}
  ${margin}

  ${formOpenedCss}
`

export interface ITotalWrapperProps extends MarginProps {}

export const TotalWrapper = styled.div<ITotalWrapperProps>`
  border: 1px solid ${themeGet('colors.silver')};
  border-radius: 100px;
  padding: 4px 14px;
  flex-shrink: 0;

  ${margin};
`

export const CloseIcon = styled(closeGlyph)`
  width: 8px;
  height: 8px;
  margin-left: 10px;
`

export const CloseWrapper = styled.div`
  width: 26px;
  height: 26px;
  margin-left: 28px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${themeGet('colors.athensGray')};
  border-radius: 50%;
`

export const TextButton = styled(Text)`
  cursor: pointer;
`

export const DropDownItems = styled.div`
  padding: 16px 32px;
  border-bottom: 1px solid ${themeGet('colors.alto')};
`

export const DropDownContainer = styled(Flex)`
  cursor: pointer;

  ${ValueContainerWrapper} {
    margin-top: 0;
  }
`

export interface IArrowIconProps extends IDropDownOpenedCssProps {}

export const ArrowIcon = styled(IoIosArrowDown)<IArrowIconProps>`
  ${dropDownOpenedCss}
`

export interface IDropDownTitleProps
  extends IDropDownOpenedBackgroundCssProps {}

export const DropDownTitle = styled(Flex)<IDropDownTitleProps>`
  border-bottom: 1px solid ${themeGet('colors.alto')};
  padding: 0 24px;

  ${dropDownOpenedBackgroundCss}
`
