import React from 'react'
import { components } from 'react-select'

import Loader from 'Components/UI/Loader'

const LoadingIndicator: typeof components.LoadingIndicator = () => {
  return <Loader />
}

export default LoadingIndicator
