import styled, { css } from 'styled-components'
import {
  color,
  ColorProps,
  layout,
  LayoutProps,
  space,
  SpaceProps,
  typography,
  TypographyProps,
} from 'styled-system'
import shouldForwardProp from '@styled-system/should-forward-prop'

import { whiteSpace, WhiteSpaceProps } from 'Theme/system'

export enum TextVariant {
  Heading1 = 'heading1',
  Heading2 = 'heading2',
  Heading3 = 'heading3',
  Heading4 = 'heading4',
  Heading5 = 'heading5',
  BodyL = 'body-l',
  BodyM = 'body-m',
  BodyS = 'body-s',
  BodyXs = 'body-xs',
}

// TODO: look into converting to rem units in the future

export interface IText
  extends LayoutProps,
    SpaceProps,
    ColorProps,
    TypographyProps,
    WhiteSpaceProps {
  variant?: `${TextVariant}`
}

const heading1VariantCss = ({ variant }: IText) =>
  variant === TextVariant.Heading1 &&
  css`
    font-size: 30px;
    line-height: 38px;
    font-weight: 700;
  `

const heading2VariantCss = ({ variant }: IText) =>
  variant === TextVariant.Heading2 &&
  css`
    font-size: 20px;
    line-height: 30px;
    font-weight: 700;
  `

const heading3VariantCss = ({ variant }: IText) =>
  variant === TextVariant.Heading3 &&
  css`
    font-size: 18px;
    line-height: 26px;
    font-weight: 700;
  `

const heading4VariantCss = ({ variant }: IText) =>
  variant === TextVariant.Heading4 &&
  css`
    font-size: 16px;
    line-height: 24px;
    font-weight: 700;
  `

const heading5VariantCss = ({ variant }: IText) =>
  variant === TextVariant.Heading5 &&
  css`
    font-size: 14px;
    line-height: 22px;
    font-weight: 700;
  `

const bodyLVariantCss = ({ variant }: IText) =>
  variant === TextVariant.BodyL &&
  css`
    font-size: 18px;
    line-height: 24px;
    font-weight: 400;
  `

const bodyMVariantCss = ({ variant }: IText) =>
  variant === TextVariant.BodyM &&
  css`
    font-size: 16px;
    line-height: 22px;
    font-weight: 400;
  `

const bodySVariantCss = ({ variant }: IText) =>
  variant === TextVariant.BodyS &&
  css`
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
  `

const bodyXsVariantCss = ({ variant }: IText) =>
  variant === TextVariant.BodyXs &&
  css`
    font-size: 12px;
    line-height: 16px;
    font-weight: 400;
  `

const ellipsisCss = ({ ellipsis }: { ellipsis?: boolean }) =>
  ellipsis &&
  css`
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  `

const Text = styled.span.withConfig<IText>({
  shouldForwardProp,
})`
  margin: 0;
  padding: 0;
  color: inherit;

  ${heading1VariantCss}
  ${heading2VariantCss}
  ${heading3VariantCss}
  ${heading4VariantCss}
  ${heading5VariantCss}
  ${bodyLVariantCss}
  ${bodyMVariantCss}
  ${bodySVariantCss}
  ${bodyXsVariantCss}
  ${ellipsisCss}

  ${layout}
  ${space}
  ${color}
  ${typography}
  ${whiteSpace}
`

export default Text
