import React, { useMemo } from 'react'
import PropTypes from 'prop-types'

import { useTheme } from 'styled-components'

import Utils from 'Utils'

import { Text } from 'Components/UI'

function AddedAtCell({ row, deleteCommunityUserIds }) {
  const { colors } = useTheme()

  const addedAtDate = useMemo(
    () => Utils.Table.renderDateTimeCell({ value: row?.original?.addedAt }),
    [row?.original?.addedAt],
  )

  const isDeletedUser = useMemo(
    () => deleteCommunityUserIds.includes(row?.original?.communityUserId),
    [deleteCommunityUserIds, row?.original?.communityUserId],
  )

  return (
    <Text color={isDeletedUser ? colors.primaryCardinal : colors.grayBoulder}>
      {addedAtDate}
    </Text>
  )
}

AddedAtCell.defaultProps = {
  deleteCommunityUserIds: [],
  row: {},
}

AddedAtCell.propTypes = {
  deleteCommunityUserIds: PropTypes.array,
  row: PropTypes.object,
}

export default AddedAtCell
