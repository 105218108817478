import React from 'react'

export interface IHighlightProps {
  children: string
  word?: string | null
}

function Highlight({ word, children }: IHighlightProps) {
  const regex = new RegExp(`${word}(?![^<]*>)`, 'gi')
  const highlighted = children.replace(regex, `<span>$&</span>`)

  // eslint-disable-next-line react/no-danger
  return <div dangerouslySetInnerHTML={{ __html: highlighted }} />
}

export default Highlight
