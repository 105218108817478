import React from 'react'
import { components } from 'react-select'

import { MultiValueWrapper } from './styles'

const MultiValue: typeof components.MultiValue = props => {
  // TODO: find a better way to satisfy the type
  const StyledMultiValueWrapper = MultiValueWrapper as any
  return <StyledMultiValueWrapper {...props} as={components.MultiValue} />
}

export default MultiValue
