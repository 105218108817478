import React, { PropsWithChildren } from 'react'

import { Box, Text } from 'Components/UI'

export interface ISection extends PropsWithChildren {
  title?: string
}

const Section = (props: ISection) => {
  return (
    <Box
      borderColor="#DDE1E4"
      borderRadius={4}
      borderStyle="solid"
      borderWidth={1}
    >
      {!!props.title && (
        <Box
          borderBottomColor="#DDE1E4"
          borderBottomStyle="solid"
          borderBottomWidth={1}
          px={4}
          py={3}
        >
          <Text
            as="h3"
            color="#757575"
            fontSize={12}
            fontWeight={600}
            lineHeight={1.33}
          >
            {props.title}
          </Text>
        </Box>
      )}
      <Box px={4} py={5}>
        {props.children}
      </Box>
    </Box>
  )
}

export default Section
