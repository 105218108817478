import React from 'react'

import { Link } from 'Components/UI'

import { EXTERNAL_LINKS } from 'Constants/ids'

import Step2 from './Step2'
import {
  Block,
  Description,
  Disclaimer,
  OuterWrapper,
  Pane,
  SecureGlyph,
  Step,
  Title,
  Wrapper,
} from './styles'

export default function Onboarding() {
  return (
    <OuterWrapper>
      <Wrapper>
        <div>
          <Block>
            <Step step={2}>
              <div />
              <div />
            </Step>
            <Title>Grow your network by connecting your accounts.</Title>
            <Description>
              Connecting your accounts allows NetworkOS to work its magic! By
              linking your accounts, you grant us secure access to important
              data like contacts, calendars, email. With your connected account
              data, we can auto-populate your network, suggest contacts you
              interact with frequently, surface relevant information, and more.
            </Description>
            <Disclaimer>
              <SecureGlyph />
              <span>We are committed to your privacy</span>
              <Link info isExternal to={EXTERNAL_LINKS.privacyPolicy}>
                Learn More
              </Link>
            </Disclaimer>
          </Block>
        </div>
        <Pane>
          <Block grow>
            <Step2 />
          </Block>
        </Pane>
      </Wrapper>
    </OuterWrapper>
  )
}
