import React from 'react'

import { PiWarningCircleFill } from 'react-icons/pi'

import { IconCircleCheck, IconRefresh } from '@tabler/icons-react'

import { Row } from 'Components/UI'
import { Text } from 'Components/UI/_v2'

import { useScopedI18n } from 'Services/I18n'

import colors from 'Theme/_v2/colors'

import { NoteFormStatus } from './useNoteForm'

export interface IStatusMessageProps {
  status: NoteFormStatus
}

const StatusMessage = (props: IStatusMessageProps) => {
  const t = useScopedI18n('features.notes.components.noteForm.statusMessage')

  return (
    <Row center gap={1}>
      {props.status === NoteFormStatus.Loading && (
        <>
          <IconRefresh color={colors.text.secondary} size={16} />
          <Text
            color={colors.text.secondary}
            fontWeight={500}
            variant="body-xs"
          >
            {t('loading')}
          </Text>
        </>
      )}

      {props.status === NoteFormStatus.Success && (
        <>
          <IconCircleCheck color={colors.text.secondary} size={16} />
          <Text
            color={colors.text.secondary}
            fontWeight={500}
            variant="body-xs"
          >
            {t('success')}
          </Text>
        </>
      )}

      {props.status === NoteFormStatus.Error && (
        <>
          <PiWarningCircleFill color={colors.text.error} size={16} />
          <Text color={colors.text.error} fontWeight={500} variant="body-xs">
            {t('error')}
          </Text>
        </>
      )}
    </Row>
  )
}

export default StatusMessage
