import React from 'react'
import { components, GroupBase, MultiValueRemoveProps } from 'react-select'

import { IconX } from '@tabler/icons-react'

import { TAG_COLOR_KIND, TagColorKind } from 'Constants/tags'

import { Container } from './styles'

export interface TagMultiValueRemoveProps<
  Option = unknown,
  IsMulti extends boolean = boolean,
  Group extends GroupBase<Option> = GroupBase<Option>,
> extends MultiValueRemoveProps<Option, IsMulti, Group> {
  tagColorKind?: TagColorKind
}

const TagMultiValueRemove = <
  Option = unknown,
  IsMulti extends boolean = boolean,
  Group extends GroupBase<Option> = GroupBase<Option>,
>({
  tagColorKind = TAG_COLOR_KIND.CUSTOM,
  ...rest
}: TagMultiValueRemoveProps<Option, IsMulti, Group>) => {
  return (
    <components.MultiValueRemove {...rest}>
      <Container colorKind={tagColorKind}>
        <IconX />
      </Container>
    </components.MultiValueRemove>
  )
}

export default TagMultiValueRemove
