import React from 'react'
import { Field } from 'react-final-form'

import { getFullName } from 'Utils/User'

import sortBy from 'lodash/sortBy'

import { Row } from 'Components/UI'
import { Chip } from 'Components/UI/_v2'

interface UsersFieldProps {
  name: string
}

// TODO: adding users
// TODO: removing users
// TODO: minimum required users that can't be removed
// TODO: show a max number of users, show more for the rest?
const UsersField: React.FC<UsersFieldProps> = props => {
  return (
    <Field<MainSchema.CommunityUser[] | undefined> name={props.name}>
      {fieldProps => {
        const users = fieldProps.input.value || []
        const usersSortedByName = sortBy(users, user => getFullName(user))

        return (
          <Row flexWrap="wrap" gap={2}>
            {usersSortedByName.map(user => (
              <Chip key={user.id} label={getFullName(user)} />
            ))}
          </Row>
        )
      }}
    </Field>
  )
}

export default UsersField
