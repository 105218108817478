import React, { useCallback, useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'

import { IconSearch } from '@tabler/icons-react'
import communityUsersBySkillIdQuery from 'GraphQL/Queries/Community/communityUsersBySkillId.graphql'
import communityUsersByTagIdQuery from 'GraphQL/Queries/Community/communityUsersByTagId.graphql'
import { Interweave } from 'interweave'
import Utils from 'Utils'

import noop from 'lodash/noop'

import { Divider, Input, Modal, Pagination, Table, Text } from 'Components/UI'

import { SEARCH_TYPES } from 'Constants/ids'

import {
  useCommunityContext,
  useEntityModal,
  useEntityTable,
  useTableSearch,
} from 'Hooks'

import { useQuery } from 'Services/Apollo'
import _, { useScopedI18n } from 'Services/I18n'

import { useColumns } from './columns'
import TableContext from './context'
import { Container } from './styles'

const DEFAULT_PAGE_SIZE = 10

function TagUsersTable({
  type,
  tagId,
  handleDeleteUsers,
  deleteCommunityUserIds,
}) {
  const { community } = useCommunityContext()
  const s = useScopedI18n('tag')

  const [mostRecentPage, setMostRecentPage] = useState(0)
  const [mostRecentLimit, setMostRecentLimit] = useState(DEFAULT_PAGE_SIZE)

  const query = useMemo(
    () =>
      type === SEARCH_TYPES.skill
        ? communityUsersBySkillIdQuery
        : communityUsersByTagIdQuery,
    [type],
  )

  const { data, loading, refetch } = useQuery(query, {
    variables: {
      communityId: community?.id,
      tagId: type !== SEARCH_TYPES.skill ? tagId : undefined,
      skillId: type === SEARCH_TYPES.skill ? tagId : undefined,
      page: mostRecentPage,
      limit: mostRecentLimit,
    },
    fetchPolicy: 'network-only',
  })

  const columns = useColumns()

  const entities = useMemo(
    () =>
      (type === SEARCH_TYPES.skill
        ? data?.communityUsersBySkillId
        : data?.communityUsersByTagId) ?? {},
    [type, data?.communityUsersBySkillId, data?.communityUsersByTagId],
  )

  const [tableProps, rowsCount] = useEntityTable({
    data: entities,
    columns,
    disableSortBy: true,
    deleteCommunityUserIds,
  })

  const { pageSize, pageIndex } = tableProps.state

  const [search, changeSearch, clearSearch] = useTableSearch({
    onSearch: value => {
      tableProps.gotoPage(0)

      refetch({
        limit: mostRecentLimit,
        search: value || undefined,
      }).then()
    },
  })

  const [deleteModal, deleteActions] = useEntityModal()
  const handleDeleteModalClose = deleteActions.closeModal

  const handleDelete = useCallback(
    success => {
      if (!success) {
        return
      }
      handleDeleteUsers(deleteModal.entity.communityUserId)
      handleDeleteModalClose()
    },
    [deleteModal, handleDeleteModalClose, handleDeleteUsers],
  )

  const deleteMessage = useMemo(
    () => (
      <Text as="div" bodyMedium header>
        <Interweave
          content={
            deleteCommunityUserIds?.includes(
              deleteModal?.entity?.communityUserId,
            )
              ? s('messages.notDeleteUser', {
                  userName: Utils.User.getFullName(deleteModal?.entity),
                })
              : s('messages.deleteUser', {
                  userName: Utils.User.getFullName(deleteModal?.entity),
                })
          }
        />
      </Text>
    ),
    [deleteModal, deleteCommunityUserIds, s],
  )

  useEffect(() => {
    setMostRecentPage(pageIndex)
  }, [pageIndex])

  useEffect(() => {
    setMostRecentPage(0)
    setMostRecentLimit(pageSize)
  }, [pageSize])

  const memoizedContext = useMemo(
    () => ({
      onDeleteRow: deleteActions.openModal,
    }),
    [deleteActions],
  )

  return (
    <TableContext.Provider value={memoizedContext}>
      <Input
        clearable
        fullWidth
        label={_('search.byName')}
        mb={4}
        name="search"
        placeholder="Search by users"
        renderBeforeElement={() => <IconSearch />}
        value={search}
        onChange={changeSearch}
        onClear={clearSearch}
      />

      <Container>
        <Table {...tableProps} loading={loading} modal />
      </Container>

      <Divider mb={3} />

      <Pagination
        showTotal={false}
        state={{ pageIndex, pageSize }}
        total={rowsCount}
        onPageIndexChange={tableProps?.gotoPage}
        onPageSizeChange={tableProps?.setPageSize}
      />

      <Modal
        cancelText="Cancel"
        confirmText="Delete"
        isOpen={deleteModal.isOpen}
        title={s('actions.deleteUser')}
        onClose={handleDeleteModalClose}
        onConfirm={handleDelete}
      >
        {deleteMessage}
      </Modal>
    </TableContext.Provider>
  )
}

TagUsersTable.defaultProps = {
  deleteCommunityUserIds: [],
  handleDeleteUsers: noop,
  tagId: null,
}

TagUsersTable.propTypes = {
  deleteCommunityUserIds: PropTypes.array,
  handleDeleteUsers: PropTypes.func,
  tagId: PropTypes.string,
  type: PropTypes.string.isRequired,
}

export default TagUsersTable
