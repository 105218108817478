import styled from 'styled-components'

import BgAuthLayoutImage from 'Assets/Images/bg-auth-layout.png'
import { networkosGlyph } from 'Assets/Svg'

import colors from 'Theme/Main/colors'

export const Container = styled.section`
  display: flex;
  flex-direction: row;
  width: 100%;
`

export const ContentContainer = styled.div`
  background-color: ${colors.bg.primary};
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 60px 20px;
  gap: 20px;
`

export const Logo = networkosGlyph

export const Content = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 432px;
  width: 100%;
  margin-top: 52px;
`

export const BackgroundContainer = styled.section`
  width: 100%;
  height: 100%;
  background-color: ${colors.main};
  background-image: url(${BgAuthLayoutImage});
  background-repeat: no-repeat;
  background-position: bottom right;

  @media screen and (max-width: 999px) {
    display: none;
  }
`
