import React, {
  PropsWithChildren,
  useCallback,
  useEffect,
  useState,
} from 'react'
import ReactModal from 'react-modal'

import replace from 'lodash/replace'
import toNumber from 'lodash/toNumber'

import Text from 'Components/UI/Text'

import { theme } from 'Theme'

import { CloseButton, CloseIcon, ModalHeader, StyledModal } from './styles'

ReactModal.setAppElement('body')

const TIMEOUT = toNumber(replace(theme.transitionTime.modal, 'ms', ''))

export type HandleClose = () => void
export type OnMount = (instance: IModalInstance) => void

export interface IModalInstance {
  handleClose?: HandleClose
}

export interface IModalProps extends PropsWithChildren {
  isOpen?: boolean
  shouldCloseOnEsc?: boolean
  shouldCloseOnOverlayClick?: boolean
  title?: string
  onClose?: () => void
  onMount?: OnMount
}

function Modal({
  children,
  isOpen = false,
  shouldCloseOnOverlayClick = true,
  shouldCloseOnEsc = true,
  title,
  onClose,
  onMount,
}: IModalProps) {
  const [internalOpen, setInternalOpen] = useState(false)

  useEffect(() => {
    if (isOpen) {
      setInternalOpen(true)
    }
  }, [isOpen])

  const handleClose = useCallback<HandleClose>(() => {
    setInternalOpen(false)
  }, [])

  useEffect(() => {
    onMount?.({ handleClose })
  }, [handleClose, onMount])

  if (!isOpen) {
    return null
  }

  return (
    <StyledModal
      closeTimeoutMS={TIMEOUT}
      isOpen={internalOpen}
      shouldCloseOnEsc={shouldCloseOnEsc}
      shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
      onAfterClose={onClose}
      onRequestClose={handleClose}
    >
      {title && (
        <ModalHeader px={5} py={4}>
          <Text bold fontSize={20}>
            {title}
          </Text>

          <CloseButton onClick={handleClose}>
            <CloseIcon />
          </CloseButton>
        </ModalHeader>
      )}

      {children}
    </StyledModal>
  )
}

export default Modal
