import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  transition: width 0.17s ease-in-out;
  object-fit: contain;
  position: relative;
  z-index: 1;
  word-break: break-all;
  width: 100%;

  > span {
    cursor: pointer;
    text-align: center;
  }
`
