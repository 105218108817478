import React, { useCallback, useState } from 'react'

import { Column, Row, Tabs, Text } from 'Components/UI'

const TABS = ['Tab 1', 'Tab 2', 'Tab 3', 'Tab 4']

function TabsSection() {
  const [activeTab, setActiveTab] = useState(TABS[0])

  const handleClick = useCallback((index: string) => {
    setActiveTab(index)
  }, [])

  return (
    <Column gap={6}>
      <Text header1>Tabs</Text>
      <Row gap={5} wrapped>
        <Tabs activeTab={activeTab} tabs={TABS} onChange={handleClick} />
      </Row>
    </Column>
  )
}

export default TabsSection
