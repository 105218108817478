import { appearance } from 'Config'

import GlobalStyle from './GlobalStyle'
import ThemeProvider from './ThemeProvider'
import themes from './themes'
import { Theme } from './types'

const theme = themes[appearance.theme as keyof typeof themes]

export { GlobalStyle, theme, ThemeProvider, themes }

export type { Theme }

export default themes
