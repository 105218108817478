import React from 'react'

import { FaLinkedin } from 'react-icons/fa'

export interface ILinkedIn {
  color?: string
  size?: number
}

const LinkedIn = ({ color = '#0288D1', size }: ILinkedIn) => {
  return <FaLinkedin color={color} display="block" size={size} />
}

export default LinkedIn
