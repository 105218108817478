import React from 'react'

import { useTheme } from 'styled-components'
import { themeGet } from '@styled-system/theme-get'

import { Column } from 'Components/UI'

import ImageInput from 'Pages/UIKit/ImageInputs'

import Avatars from './Avatars'
import Backgrounds from './Backgrounds'
import Badges from './Badges'
import Buttons from './Buttons'
import Checkboxes from './Checkboxes'
import DateRangePickers from './DateRangePickers'
import Inputs from './Inputs'
import Links from './Links'
import MenuItems from './MenuItems'
import Modals from './Modals'
import Pagination from './Pagination'
import RadioButtons from './RadioButtons'
import SearchInputs from './SearchInputs'
import Selects from './Selects'
import Shadows from './Shadows'
import Switches from './Switches'
import Tabs from './Tabs'
import Tags from './Tags'
import TextAreas from './TextAreas'
import Toasts from './Toasts'
import Typography from './Typography'

function UIKitV2() {
  const theme = useTheme()

  // eslint-disable-next-line no-console
  console.log(theme)

  return (
    <div
      style={{
        backgroundColor: themeGet('colors.bg.body')({ theme }),
        width: '100%',
        color: themeGet('colors.text.body')({ theme }),
      }}
    >
      <Column fullWidth gap={7} p={6}>
        UI kit V2
        <ImageInput />
        <Modals />
        <Pagination />
        <Tabs />
        <MenuItems />
        <Tags />
        <Badges />
        <Toasts />
        <Avatars />
        <Switches />
        <RadioButtons />
        <Checkboxes />
        <Selects />
        <Inputs />
        <Typography />
        <TextAreas />
        <Buttons />
        <SearchInputs />
        <DateRangePickers />
        <Links />
        <Shadows />
        <Backgrounds />
      </Column>
    </div>
  )
}

export default UIKitV2
