import { useCallback, useMemo, useState } from 'react'

const DEFAULT_PAGE_SIZE = 10

export interface IPagination {
  data?: {
    pages: number
    count: number
  }
  // TODO: add type for rest
}

export default function usePagination({ data, ...rest }: IPagination) {
  const [pageIndex, setPageIndex] = useState<number>(0)
  const [pageSize, setPageSize] = useState<number>(DEFAULT_PAGE_SIZE)

  const pagesCount = useMemo(() => data?.pages || 1, [data])
  const total = useMemo(() => data?.count || 0, [data])

  const canNextPage = pageIndex < pagesCount - 1
  const canPreviousPage = pageIndex !== 0

  const pageOptions = useMemo(() => [...Array(data?.pages).keys()], [data])

  const nextPage = useCallback(() => setPageIndex(pageIndex + 1), [pageIndex])

  const previousPage = useCallback(
    () => setPageIndex(pageIndex - 1),
    [pageIndex],
  )

  const firstPage = useCallback(() => setPageIndex(0), [])

  const lastPage = useCallback(() => setPageIndex(pagesCount - 1), [pagesCount])

  const handleSetPageSize = useCallback((size: number) => {
    setPageSize(size)
    setPageIndex(0)
  }, [])

  const paginationProps = {
    canPreviousPage,
    canNextPage,
    pageOptions,
    nextPage,
    previousPage,
    firstPage,
    lastPage,
    setPageSize: handleSetPageSize,
    state: { pageSize, pageIndex },
    total,
    ...rest,
  }

  return [paginationProps]
}
