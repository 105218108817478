import React from 'react'
import { Outlet } from 'react-router-dom'

import { Logo, LogoWrapper } from 'Components/Blocks/Community/Header/styles'
import SidebarUser from 'Components/Blocks/SidebarUser'
import { Link, MenuItem, Row } from 'Components/UI'

import {
  ADMIN_ADMINS,
  ADMIN_CHANGE_REPORT_COMMUNITIES,
  ADMIN_COMMUNITIES,
  ADMIN_SKILLS,
  ADMIN_USERS,
} from 'Router/routes'

import {
  Container,
  Content,
  ContentContainer,
  Menu,
  PageContent,
  Wrapper,
} from './styles'

function Admin() {
  return (
    <Container>
      <Menu>
        <Row center fullWidth>
          <Link to="/">
            <LogoWrapper>
              <Logo />
            </LogoWrapper>
          </Link>
          <Row gap={1}>
            <MenuItem caption="Super Admins" ml={2} to={ADMIN_ADMINS} />
            <MenuItem caption="Users" to={ADMIN_USERS} />
            <MenuItem caption="Skills" to={ADMIN_SKILLS} />
            <MenuItem caption="Communities" to={ADMIN_COMMUNITIES} />
            <MenuItem
              caption="Change Reports"
              to={ADMIN_CHANGE_REPORT_COMMUNITIES}
            />
            <MenuItem caption="Feature Flags" to="/admin/feature-flags" />
          </Row>
          <Row center gap="10px" ml="auto" noShrink>
            <SidebarUser />
          </Row>
        </Row>
      </Menu>

      <Content>
        <PageContent>
          <Wrapper>
            <ContentContainer>
              <Outlet />
            </ContentContainer>
          </Wrapper>
        </PageContent>
      </Content>
    </Container>
  )
}

export default Admin
