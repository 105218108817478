import styled from 'styled-components'
import { themeGet } from '@styled-system/theme-get'

export interface ICircleProps {
  backgroundColor?: string
}

export const Circle = styled.div<ICircleProps>`
  background-color: ${props => props.backgroundColor || '#a9a9a9'};
  border-radius: 21px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  width: 20px;
  height: 20px;
  font-weight: 700;
  font-size: 11px;
  line-height: 13px;
  text-align: center;
  color: ${themeGet('colors.text.contrast')} !important;

  &:hover {
    color: ${themeGet('colors.text.contrast')} !important;
  }
`
