import React, { useCallback } from 'react'
import PropTypes from 'prop-types'

import { useMutation } from '@apollo/client'
import { IconCheck } from '@tabler/icons-react'
import acceptProposalMutation from 'GraphQL/Mutations/Proposal/acceptProposal.graphql'
import { entityCacheRemover } from 'GraphQL/Updaters/Common'
import { acceptProposalUpdater } from 'GraphQL/Updaters/Proposal'

import { useTableContext } from 'Components/Blocks/Tables/ProposalsTable/context'
import { LinkButton } from 'Components/UI'

import { useAppContext } from 'Hooks'

import _ from 'Services/I18n'
import toast from 'Services/Toast'

function Accept({ row }) {
  const { refetchMe } = useAppContext()
  const { pageSize } = useTableContext()

  const [acceptProposal] = useMutation(acceptProposalMutation)

  const proposal = row?.original

  const handleAcceptProposal = useCallback(async () => {
    try {
      await acceptProposal({
        variables: {
          id: proposal.id,
        },
        update: store => {
          entityCacheRemover({
            entity: proposal,
            pageSize,
            queryKey: 'proposals',
          })(store)
          acceptProposalUpdater(proposal)(store)
        },
      })

      refetchMe()

      toast.success({
        title: 'Proposal',
        text: 'Proposal successfully accepted',
      })
    } catch (error) {
      toast.error({
        title: 'Proposal',
        text: _(`error.${error?.message || 'generic'}`),
      })
    }
  }, [acceptProposal, proposal, pageSize, refetchMe])

  return (
    <LinkButton success onClick={handleAcceptProposal}>
      <IconCheck />
      Approve
    </LinkButton>
  )
}

Accept.defaultProps = {
  row: {},
}

Accept.propTypes = {
  row: PropTypes.object,
}

export default Accept
