import styled, { css } from 'styled-components'
import { mapToTheme } from 'styled-map'
import { themeGet } from '@styled-system/theme-get'

const containerInfoCss = ({ info }: { info?: boolean }) =>
  info &&
  css`
    color: ${themeGet('colors.info')};
  `

const containerWarningCss = ({ warning }: { warning?: boolean }) =>
  warning &&
  css`
    color: ${themeGet('colors.warning')};
  `

const containerErrorCss = ({ error }: { error?: boolean }) =>
  error &&
  css`
    color: ${themeGet('colors.error')};
  `

export interface IContainer {
  info?: boolean
  warning?: boolean
  error?: boolean
}

export const Container = styled.div<IContainer>`
  display: flex;
  width: 100%;
  gap: ${themeGet('space.4')}px;
  color: ${themeGet('colors.success')};

  > svg {
    width: ${mapToTheme('toasts.iconSize')}px;
    height: ${mapToTheme('toasts.iconSize')}px;
  }

  ${containerErrorCss}
  ${containerInfoCss}
  ${containerWarningCss}
`
