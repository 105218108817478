import styled, { css } from 'styled-components'
import { themeGet } from '@styled-system/theme-get'

import { IconChevronRight } from '@tabler/icons-react'

import { resetButton } from 'Components/Styles'

import { gap, GapProps } from 'Theme/system'

export const Icon = styled.div`
  width: 12px;
  height: 12px;
  color: #757575;

  svg {
    width: 100%;
    height: 100%;
  }
`

export const Label = styled.div`
  font-size: 10px;
  line-height: 1.6;
  font-weight: 400;
  color: #2d2d2d;
  flex: 1;
  text-align: left;
`

export const Arrow = styled(IconChevronRight)`
  width: 12px;
  height: 12px;
  color: #545454;
  display: block;
`

export interface IIsActiveCss {
  isActive?: boolean
}

const isActiveCss = ({ isActive }: IIsActiveCss) =>
  isActive &&
  css`
    background-color: #f0f0f0;
  `

export interface IContainer extends IIsActiveCss, GapProps {}

export const Container = styled.button<IContainer>`
  ${resetButton};

  :enabled {
    cursor: pointer;
  }

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  padding: ${themeGet('space.1')}px ${themeGet('space.2')}px;
  align-items: center;

  :hover {
    background-color: ${themeGet('colors.bg.hover')};

    ${Label} {
      color: ${themeGet('colors.hover')};
    }

    ${Icon} {
      color: ${themeGet('colors.hover')};
    }

    ${Arrow} {
      color: ${themeGet('colors.hover')};
    }
  }

  ${isActiveCss};
  ${gap}
`
