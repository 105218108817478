import styled, { css } from 'styled-components'
import { margin, MarginProps } from 'styled-system'
import { themeGet } from '@styled-system/theme-get'

export interface IContainerWrappedCssProps {
  wrapped?: boolean
}

const containerWrappedCss = ({ wrapped }: IContainerWrappedCssProps) =>
  wrapped &&
  css`
    flex-wrap: wrap;
  `

export interface IContainerProps extends IContainerWrappedCssProps {}

export const Container = styled.div<IContainerProps>`
  display: flex;
  align-items: center;
  gap: ${themeGet('space.4')}px;
  width: 100%;
  ${containerWrappedCss};
`

export interface IDividerProps extends MarginProps {}

export const Divider = styled.div<IDividerProps>`
  height: 20px;
  width: 1px;
  flex-shrink: 0;
  background-color: ${themeGet('colors.divider.default')};

  ${margin}
`

export interface IStyledButtonDangerCssProps {
  danger?: boolean
}

const styledButtonDangerCss = ({ danger }: IStyledButtonDangerCssProps) =>
  danger &&
  css`
    color: ${themeGet('colors.error')};
  `

export interface IStyledButtonProps extends IStyledButtonDangerCssProps {}

export const StyledButton = styled.button<IStyledButtonProps>`
  display: flex;
  align-items: center;
  gap: ${themeGet('space.2')}px;
  background: none;
  outline: none;
  border: none;
  cursor: pointer;
  color: ${themeGet('colors.info')};
  padding: 0;
  font-size: ${themeGet('fontSize.2')}px;
  font-weight: ${themeGet('fontWeight.1')};

  > svg {
    width: 16px;
    height: 16px;
  }

  ${styledButtonDangerCss}
`
