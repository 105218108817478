import styled from 'styled-components'
import { themeGet } from '@styled-system/theme-get'

import { Row } from 'Components/UI'

export const AvatarHolder = styled(Row)`
  justify-content: center;
  align-items: center;
  background-color: ${themeGet('colors.gray')};
  height: 24px;
  width: 24px;
  border-radius: 4px;
  margin-right: 9px;
`
