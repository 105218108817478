import styled from 'styled-components'
import { padding, PaddingProps } from 'styled-system'

import { resetButton } from 'Components/Styles'

import shadows from 'Theme/Main/shadows'
import palette from 'Theme/palette'

export interface IButton extends PaddingProps {}

export const Button = styled.button<IButton>`
  ${resetButton}

  background-color: ${palette.white};
  border: 1px solid ${palette.primaryNeutral20};
  border-radius: 100px;
  color: #2d2d2d;

  :enabled {
    cursor: pointer;

    :hover,
    :focus,
    :active {
      background-color: ${palette.primaryNeutral10};
    }

    :focus,
    :active {
      box-shadow: ${shadows.secondaryFocus};
    }
  }

  :disabled {
    color: ${palette.primaryNeutral40};
  }

  ${padding}
`
