import React, {
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react'
import ContentEditable, { ContentEditableEvent } from 'react-contenteditable'

import { pick } from '@styled-system/props'

import { useEventBusSubscribe } from 'Hooks'

import EventBus from 'Services/EventBus'

import Commands, { CommandsSelectHandler } from './Components/Commands'
import { ICommand } from './Components/Commands/CommandItem'
import Search, { SearchSelectHandler } from './Components/Search'
import Textarea, {
  TextareaChangeHandler,
  TextareaRemoveHandler,
} from './Components/Textarea'
import MentionTextAreaContext, { IMentionTextAreaContext } from './context'
import { Container, IContainerProps } from './styles'
import {
  addElementAtPosition,
  deserializeContent,
  getCursorPosition,
  serializeContent,
  setCaretToEnd,
} from './utils'

export type MentionTextareaChangeHandler = (content: string) => void
export type MentionTextareaAddHandler = SearchSelectHandler
export type MentionTextareaRemoveHandler = TextareaRemoveHandler

export interface IMentionTextareaProps extends IContainerProps {
  disabled?: boolean
  existingMentions?: Record<string, MainSchema.Mention>
  initialValue?: string
  onAdd?: MentionTextareaAddHandler
  onChange?: MentionTextareaChangeHandler
  onRemove?: MentionTextareaRemoveHandler
}

export interface IMentionTextareaHandle {
  focus: () => void
}

const MentionTextarea = React.forwardRef<
  IMentionTextareaHandle,
  IMentionTextareaProps
>(
  (
    {
      initialValue = '',
      existingMentions = {},
      disabled = false,
      onChange,
      onAdd,
      onRemove,
      ...rest
    },
    ref,
  ) => {
    const textareaRef = useRef<ContentEditable | null>(null)
    const cursorPositionRef = useRef(0)

    const [dropdownVisible, setDropdownVisible] = useState(false)
    const [selectedCommand, setSelectedCommand] = useState<
      ICommand | null | undefined
    >(null)
    const [innerValue, setInnerValue] = useState('')

    useEffect(() => {
      if (!innerValue) {
        setInnerValue(deserializeContent(initialValue, existingMentions))
      }
    }, [innerValue, initialValue, existingMentions])

    useImperativeHandle(ref, () => ({
      focus: () => {
        textareaRef.current?.el?.current?.focus()
      },
    }))

    useEffect(() => {
      if (dropdownVisible)
        cursorPositionRef.current = getCursorPosition(
          textareaRef.current?.el?.current,
        )
    }, [dropdownVisible])

    const handleReset = useCallback(() => {
      setDropdownVisible(false)
      setSelectedCommand(null)
    }, [])

    const handleSelect = useCallback<CommandsSelectHandler>(value => {
      setSelectedCommand(value)
    }, [])

    const handleAdd = useCallback<SearchSelectHandler>(
      option => {
        handleReset()
        onAdd?.(option)

        // setTimeout is using to block enter click
        setTimeout(() => {
          addElementAtPosition(
            textareaRef.current?.el?.current,
            cursorPositionRef.current,
            option,
          )
          setInnerValue(textareaRef.current?.el?.current?.innerHTML ?? '')
          onChange?.(
            serializeContent(textareaRef.current?.el?.current?.innerHTML),
          )
        }, 0)
      },
      [handleReset, onAdd, onChange],
    )

    const handleAddByEventBus = useCallback((value: string | ICommand) => {
      setCaretToEnd(textareaRef.current?.el?.current)

      cursorPositionRef.current = getCursorPosition(
        textareaRef.current?.el?.current,
      )

      setTimeout(() => {
        addElementAtPosition(
          textareaRef.current?.el?.current,
          cursorPositionRef.current,
          value,
        )
        textareaRef.current?.el?.current?.blur()
      }, 0)
    }, [])

    useEventBusSubscribe(
      EventBus.actions.mentionsTextarea.addElement,
      handleAddByEventBus,
    )

    const handleRemove = useCallback<TextareaRemoveHandler>(
      option => {
        onRemove?.(option)
      },
      [onRemove],
    )

    const handleChange = useCallback<TextareaChangeHandler>(
      event => {
        const divEvent = event as unknown as ContentEditableEvent
        setInnerValue(divEvent.target.value)
        onChange?.(serializeContent(divEvent.target.value))
      },
      [onChange],
    )

    const memoizedContext = useMemo<IMentionTextAreaContext>(
      () => ({
        dropdownVisible,
        onSelectDropdownVisible: setDropdownVisible,
        onReset: handleReset,
      }),
      [dropdownVisible, handleReset],
    )

    return (
      <Container {...pick(rest)}>
        <MentionTextAreaContext.Provider value={memoizedContext}>
          <Textarea
            commands={<Commands onSelect={handleSelect} />}
            disabled={disabled}
            placeholder="Type ‘/’ for commands"
            ref={textareaRef}
            search={
              selectedCommand && (
                <Search
                  kind={selectedCommand.id}
                  onBack={handleReset}
                  onSelect={handleAdd}
                />
              )
            }
            value={innerValue}
            onChange={handleChange}
            onRemove={handleRemove}
          />
        </MentionTextAreaContext.Provider>
      </Container>
    )
  },
)

export default React.memo(MentionTextarea)
