import { useEffect, useState } from 'react'

export interface IWindowDimensions {
  width: number
  height: number
}

const getWindowDimensions = (): IWindowDimensions => {
  const { innerWidth: width, innerHeight: height } = window
  return { width, height }
}

export default function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] =
    useState<IWindowDimensions | null>(null)

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions())
    }

    setWindowDimensions(getWindowDimensions())

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  return [windowDimensions]
}
