import { MutationUpdaterFn } from '@apollo/client'
import CommunityUserFragment from 'GraphQL/Fragments/CommunityUser.graphql'
import communityUserDirectConnectionsQuery from 'GraphQL/Queries/Community/communityUserDirectConnections.graphql'

export const removeConnectionsUpdater: (props: {
  communityId: string
  me: MainSchema.User
  removedUserId: string
}) => MutationUpdaterFn<Pick<MainSchema.Mutation, 'removeConnections'>> =
  ({ communityId, me, removedUserId }) =>
  (store, { data }) => {
    if (!data?.removeConnections) return

    const query = {
      query: communityUserDirectConnectionsQuery,
      variables: {
        communityId,
        userId: me.userId!,
      },
    }

    const queryData = store.readQuery<
      Pick<MainSchema.Query, 'communityUserDirectConnections'>,
      MainSchema.QueryCommunityUserDirectConnectionsArgs
    >(query)

    if (queryData) {
      store.writeQuery<
        Pick<MainSchema.Query, 'communityUserDirectConnections'>,
        MainSchema.QueryCommunityUserDirectConnectionsArgs
      >({
        ...query,
        data: {
          ...queryData,
          communityUserDirectConnections:
            queryData.communityUserDirectConnections.filter(
              user => user.id !== removedUserId,
            ),
        },
      })
    }

    const communityUserFragment = {
      id: `CommunityUser:${me.userId}`,
      fragmentName: 'CommunityUserFields',
      fragment: CommunityUserFragment,
      variables: { communityId },
    }

    // TODO: add type
    const communityUserFragmentData = store.readFragment(
      communityUserFragment,
    ) as object

    if (communityUserFragment) {
      // TODO: add type
      store.writeFragment({
        ...communityUserFragment,
        data: {
          ...communityUserFragmentData,
          communityUser: {
            connectedUsers: {},
          },
        },
      })
    }
  }
