import inputs from './inputs'

const selects = {
  height: inputs.height,

  minWidth: inputs.minWidth,

  borderRadius: inputs.borderRadius,

  fontSize: inputs.fontSize,

  iconSize: inputs.iconSize,
}

export default selects
