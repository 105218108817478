import { USER_ROLE } from 'Constants/ids'

import { ADMIN_ROOT, COMMUNITY, ROOT } from 'Router/routes'

export function getRootRoute(user?: MainSchema.User) {
  switch (user?.role) {
    case USER_ROLE.SUPER_ADMIN:
      return ADMIN_ROOT
    case USER_ROLE.USER:
      return COMMUNITY
    default:
      return ROOT
  }
}
