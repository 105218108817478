import { DEFAULT_MIN_SEARCH_SIZE } from 'Constants/ids'

export interface ITableSearch {
  search?: string
  onSetSearchValue: (search: string | null) => void
  onGoToPage: (page: number) => void
}

export function tableSearch({
  search,
  onSetSearchValue,
  onGoToPage,
}: ITableSearch) {
  if (!search?.length) {
    onSetSearchValue(null)
    return
  }
  if (search?.length < DEFAULT_MIN_SEARCH_SIZE) return

  onGoToPage(0)
  onSetSearchValue(search)
}
