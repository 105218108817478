import React from 'react'

import { RenderLeafProps } from 'slate-react'

export interface ILeafProps extends RenderLeafProps {}

function Leaf({ attributes, children, leaf }: ILeafProps) {
  let nextChildren = children

  if (leaf.bold) {
    nextChildren = <strong>{children}</strong>
  }

  if (leaf.code) {
    nextChildren = <code>{children}</code>
  }

  if (leaf.italic) {
    nextChildren = <em>{children}</em>
  }

  if (leaf.underline) {
    nextChildren = <u>{children}</u>
  }

  if (leaf.strikethrough) {
    nextChildren = <s>{children}</s>
  }

  return <span {...attributes}>{nextChildren}</span>
}

export default Leaf
