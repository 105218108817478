import React from 'react'

import { Column, MenuItem, Row, Text } from 'Components/UI'

function MenuItems() {
  return (
    <Column gap={6}>
      <Text header1>Menu Items</Text>
      <Row gap={5} wrapped>
        <MenuItem caption="Not active" />
        <MenuItem caption="Active" isActive />
      </Row>
    </Column>
  )
}

export default MenuItems
