import { Chart } from 'regraph'

export const GRAPH_ANIMATION_TIME = 500

export function graphSettings(options?: Chart.Options): Chart.Props {
  return {
    animation: { animate: true, time: GRAPH_ANIMATION_TIME },
    options: {
      hoverDelay: 75,
      navigation: false,
      overview: false,
      selection: {
        color: 'rgba(205,27,70,0)',
      },
      backgroundColor: '#F9FAFB',
      fadeOpacity: 0.2,
      ...options,
    },
  }
}
