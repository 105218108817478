import find from 'lodash/find'
import forEach from 'lodash/forEach'
import orderBy from 'lodash/orderBy'
import reduce from 'lodash/reduce'
import sortBy from 'lodash/sortBy'
import uniqBy from 'lodash/uniqBy'

export interface IStringReplacer {
  regex: RegExp
  priority: number
  render: (replace: RegExpMatchArray) => React.ReactNode
}

export default function stringReplacer(
  content: string,
  replacers: IStringReplacer[],
): React.ReactNode[] {
  const sortedReplacers = orderBy(replacers, 'priority', 'desc')

  const allMatches = uniqBy(
    reduce(
      sortedReplacers,
      (acc: RegExpMatchArray[], replacer) => [
        ...acc,
        ...Array.from(content?.matchAll(replacer.regex) || []),
      ],
      [],
    ),
    'index',
  )

  if (allMatches.length === 0) {
    return [content]
  }

  const sortedMatches = sortBy(allMatches, 'index')
  const components: React.ReactNode[] = []
  let currentCaret = 0

  forEach(sortedMatches, (replace, i) => {
    const { index = 0 } = replace
    const [matched] = replace

    if (index > currentCaret) {
      components.push(content.substring(currentCaret, index))
      currentCaret = index
    }

    const matchedReplacer = find(
      sortedReplacers,
      replacer => !!matched.match(replacer.regex),
    )
    if (matchedReplacer) {
      components.push(matchedReplacer.render(replace))
    }

    const nextItem = sortedMatches[i + 1]
    const nextCaret =
      nextItem && nextItem?.index !== undefined
        ? nextItem.index
        : content.length

    currentCaret += matched.length

    components.push(content.substring(currentCaret, nextCaret))

    currentCaret = nextCaret
  })

  return components
}
