import styled, { css } from 'styled-components'
import { maxHeight, MaxHeightProps } from 'styled-system'
import { themeGet } from '@styled-system/theme-get'

import { customScrollbar } from 'Theme/styles'

export const Toolbar = styled.div`
  transition: all 200ms;
  bottom: 0;
  position: absolute;
  align-items: center;
  justify-content: flex-end;
  height: 26px;
  width: 100%;
`

export interface IDisabledContainerCssProps {
  disabled?: boolean
}

const disabledContainerCss = ({ disabled }: IDisabledContainerCssProps) =>
  disabled &&
  css`
    color: ${themeGet('colors.graySilver')};
  `

export interface IWithErrorCssProps {
  withError?: boolean
}

const withErrorCss = ({ withError }: IWithErrorCssProps) =>
  withError &&
  css`
    border: solid 1px ${themeGet('colors.error')};
  `

export interface IContainerProps
  extends IDisabledContainerCssProps,
    IWithErrorCssProps {}

export const Container = styled.div<IContainerProps>`
  --border-radius: 6px;

  display: flex;
  border-radius: var(--border-radius);
  position: relative;
  transition: all 200ms;
  background-color: ${themeGet('colors.alabaster')};
  border: solid 1px ${themeGet('colors.alto')};
  flex-direction: column;
  width: 100%;

  p {
    margin: 4px 0;
  }

  u {
    text-decoration: underline;
  }

  em {
    font-style: italic;
  }

  pre {
    max-width: 100%;
    padding: 16px;
    overflow: auto;
    font-size: 85%;
    line-height: 1.45;
    border-radius: 3px;
    word-wrap: normal;
    margin: 8px 0;
    word-break: normal;
    font-family: monospace;
    background-color: #f6f8fa;
    box-shadow: none;
    border: none;
  }

  blockquote {
    border-left: 4px solid ${themeGet('colors.alto')};
    margin: 8px;
    padding: 8px 16px;
  }

  ${disabledContainerCss}
  ${withErrorCss}
`

export interface IButtonProps {
  active?: boolean
}

export const Button = styled.div<IButtonProps>`
  display: inline-flex;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  width: 26px;
  height: 26px;

  &:hover {
    opacity: 0.5;
  }

  svg {
    stroke: ${themeGet('colors.gray')};
  }

  ${({ active }) =>
    active &&
    css`
      svg {
        stroke: ${themeGet('colors.primary')};
      }
    `};
`

export interface IEditableWrapperProps extends MaxHeightProps {}

export const EditableWrapper = styled.div<IEditableWrapperProps>`
  position: relative;
  overflow: auto;
  margin-bottom: 26px;
  word-break: break-word;

  ${customScrollbar}

  ${maxHeight}
`

export const ExternalLink = styled.a`
  display: inline-flex;
  align-items: center;

  > svg {
    margin-right: 3px;
  }
`

export const ErrorWrapper = styled.div`
  color: ${themeGet('colors.error')};
  font-size: 12px;
  padding: 0 12px;
  margin-top: 8px;
  line-height: 1.2;
`
