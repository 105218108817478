import { FieldMetaState } from 'react-final-form'

import { FormApi } from 'final-form'

import first from 'lodash/first'
import forEach from 'lodash/forEach'

export function hasError(
  meta: FieldMetaState<any>,
  checkDirty: boolean = false,
): { hasError: boolean; error: string | null | undefined } {
  const { dirty, touched, error, submitError, dirtySinceLastSubmit } = meta

  const isError =
    (touched || (checkDirty && dirty)) &&
    (error || (submitError && !dirtySinceLastSubmit))

  return { hasError: isError, error: isError ? first(error) : null }
}

export function errors(
  form: FormApi,
  fields: Record<string, string>,
  { checkDirty }: { checkDirty?: boolean } = {},
): Record<string, string | null | undefined> {
  const result: Record<string, string | null | undefined> = {}

  forEach(fields, field => {
    const fieldState = form.getFieldState(field)
    if (!fieldState) return

    const { hasError: fieldHasError, error } = hasError(fieldState, checkDirty)
    if (fieldHasError) {
      result[field] = error
    }
  })

  return result
}

export function setMinSearchLength(
  fn: (
    inputValue: string,
    callback: (...args: any[]) => Promise<void> | void,
  ) => Promise<void> | void,
  minLength: number,
) {
  return (
    inputValue: string,
    callback: (...args: any[]) => Promise<void> | void,
  ) => {
    if (inputValue.trim().length < minLength) {
      callback()
    } else {
      fn(inputValue, callback)
    }
  }
}
