import styled from 'styled-components'
import styledMap from 'styled-map'
import { themeGet } from '@styled-system/theme-get'

import { Row } from 'Components/UI'

import { INVITE_STATUSES } from 'Constants/ids'

import { customScrollbar } from 'Theme/styles'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  overflow: hidden;
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: 0;
  overflow-y: auto;

  ${customScrollbar}
`

export const Email = styled.div`
  cursor: pointer;
  display: flex;
  flex-shrink: 0;
  align-items: center;

  svg {
    display: none;
    margin-left: 2px;
  }

  :hover {
    color: ${themeGet('colors.primary')};
    text-decoration: underline;

    svg {
      display: block;
    }
  }
`

export const StatusMarker = styled.div`
  width: 8px;
  height: 8px;
  border-radius: 50%;
  margin-right: 8px;
  background: ${styledMap('status', {
    [INVITE_STATUSES.ONBOARDING]: themeGet('colors.error'),
    [INVITE_STATUSES.PENDING]: themeGet('colors.warning'),
    [INVITE_STATUSES.ACTIVE]: themeGet('colors.success'),
    default: themeGet('colors.text.body'),
  })};
`

export const HeaderRow = styled(Row)`
  justify-content: space-between;
  align-items: center;
  padding: 7px 18px 8px;
`
