import React, { useState } from 'react'
import { DateRange } from 'react-day-picker'

import {
  Column,
  DatePicker,
  DateRangePicker,
  DateRangePickerInput,
  Row,
  Text,
} from 'Components/UI'

function DateRangePickers() {
  const [day, setDay] = useState<Date | undefined>(undefined)
  const [dateRange, setDateRange] = useState<DateRange | undefined>({
    from: undefined,
    to: undefined,
  })

  return (
    <Column gap={6}>
      <Text header1>Date range picker</Text>
      <Row fullWidth gap={5}>
        <DateRangePickerInput
          dateRange={dateRange}
          label="Label"
          onChange={setDateRange}
        />
        <DateRangePickerInput
          dateRange={dateRange}
          disabled
          label="Disabled"
          onChange={setDateRange}
        />
      </Row>
      <Row fullWidth gap={5}>
        <DatePicker value={day} onChange={setDay} />
        <DateRangePicker value={dateRange} onChange={setDateRange} />
      </Row>
    </Column>
  )
}

export default DateRangePickers
