import { NavigateFunction } from 'react-router-dom'

import meQuery from 'GraphQL/Queries/User/me.graphql'

import { ROOT } from 'Router/routes'

import { getApolloClient, getWebSocketLink } from 'Services/Shared'
import { clearAuth, setAuth } from 'Services/Store/auth'

import { clearCommunity } from './Store/community'

function notifyLinks(token?: string | null) {
  const subscriptionLink = getWebSocketLink()
  if (subscriptionLink) subscriptionLink.onTokenChanged(token)
}

async function logOut(navigate?: NavigateFunction) {
  clearAuth()
  clearCommunity()

  await getApolloClient().clearStore()

  notifyLinks(null)

  navigate?.(ROOT)
}

async function handleAuth({
  accessToken,
  refreshToken,
}: {
  accessToken?: string
  refreshToken?: string
}) {
  setAuth({ accessToken, refreshToken })

  const client = getApolloClient()
  await client.query({ query: meQuery })

  notifyLinks(accessToken)
}

const Auth = { handleAuth, logOut }

export default Auth
