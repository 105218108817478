import React, { PropsWithChildren } from 'react'

import * as Styled from './styles'

export interface ILink extends Styled.ILinkCss, PropsWithChildren {
  href?: string
  onClick?: React.MouseEventHandler<HTMLButtonElement>
  target?: string
  title?: string
  isExternal?: boolean
  isDisabled?: boolean
}

const Link = ({
  href = '/',
  onClick,
  target,
  title,
  isExternal,
  isDisabled,
  children,
  ...props
}: ILink) => {
  if (onClick) {
    return (
      <Styled.LinkButton
        aria-disabled={isDisabled}
        disabled={isDisabled}
        title={title}
        onClick={onClick}
        {...props}
      >
        {children}
      </Styled.LinkButton>
    )
  }

  if (isExternal) {
    return (
      <Styled.ExternalLink
        aria-disabled={isDisabled}
        href={href}
        target={target}
        title={title}
        {...props}
      >
        {children}
      </Styled.ExternalLink>
    )
  }

  return (
    <Styled.Link aria-disabled={isDisabled} title={title} to={href} {...props}>
      {children}
    </Styled.Link>
  )
}

export default Link
