import styled, { css } from 'styled-components'
import { mapToTheme } from 'styled-map'
import { margin, MarginProps } from 'styled-system'
import { themeGet } from '@styled-system/theme-get'

const isActiveCss = ({ isActive }: { isActive?: boolean }) =>
  isActive &&
  css`
    cursor: default;
    border-bottom-color: ${themeGet('colors.tab.active.borderColor')};

    > p {
      color: ${themeGet('colors.tab.active.color')};
    }

    &:hover {
      border-bottom-color: ${themeGet('colors.tab.active.borderColor')};
    }
  `

export interface IStyledTab extends MarginProps {
  isActive?: boolean
}

export const StyledTab = styled.div<IStyledTab>`
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  user-select: none;
  white-space: nowrap;
  height: ${mapToTheme('tabs.height')}px;
  border-top: ${mapToTheme('tabs.borderWidth')}px solid transparent;
  border-bottom: ${mapToTheme('tabs.borderWidth')}px solid
    ${themeGet('colors.tab.borderColor')};
  cursor: pointer;

  > p {
    color: ${themeGet('colors.tab.color')};
  }

  &:hover {
    border-bottom-color: ${themeGet('colors.tab.hover.borderColor')};

    > p {
      color: ${themeGet('colors.tab.hover.color')};
    }
  }

  ${margin}

  ${isActiveCss}
`
