import React from 'react'

import Step1 from './Step1'
import {
  Block,
  Description,
  OuterWrapper,
  Pane,
  Step,
  Title,
  Wrapper,
} from './styles'

export default function Onboarding() {
  return (
    <OuterWrapper>
      <Wrapper>
        <div>
          <Block>
            <Step step={1}>
              <div />
              <div />
            </Step>
            <Title>
              Welcome to NetworkOS, we can&rsquo;t wait to learn more about you.
            </Title>
            <Description>
              Completing your profile is an important step to get the most out
              of NetworkOS. It allows us to personalize your experience,
              increase your visibility, and expand your network.
            </Description>
          </Block>
        </div>
        <Pane>
          <Block grow>
            <Step1 />
          </Block>
        </Pane>
      </Wrapper>
    </OuterWrapper>
  )
}
