import React from 'react'

import { GraphLoadingContainer } from './styles'

export interface IGraphLoadingOverlay {
  loading?: boolean
}

function GraphLoadingOverlay({ loading }: IGraphLoadingOverlay) {
  return loading ? (
    <GraphLoadingContainer>
      <div className="container">
        <div className="column column1">
          <span className="ball ball1" />
        </div>
        <div className="column column2">
          <span className="ball ball2" />
        </div>
        <div className="column column3">
          <span className="ball ball3" />
        </div>
      </div>
    </GraphLoadingContainer>
  ) : null
}

export default GraphLoadingOverlay
