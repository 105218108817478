import { ApolloCache } from '@apollo/client'
import { AsStoreObject } from '@apollo/client/utilities'

import { ProposalStatus } from '../../Constants/mainGraphQL'

export const acceptProposalUpdater =
  (proposal: MainSchema.Proposal) =>
  (store: ApolloCache<Pick<MainSchema.Mutation, 'acceptProposal'>>) => {
    store.modify<MainSchema.Proposal>({
      id: store.identify(proposal as AsStoreObject<MainSchema.Proposal>),
      fields: {
        status() {
          return ProposalStatus.Accepted
        },
      },
    })
  }

export const rejectProposalUpdater =
  (proposal: MainSchema.Proposal) =>
  (store: ApolloCache<Pick<MainSchema.Mutation, 'acceptProposal'>>) => {
    store.modify<MainSchema.Proposal>({
      id: store.identify(proposal as AsStoreObject<MainSchema.Proposal>),
      fields: {
        status() {
          return ProposalStatus.Rejected
        },
      },
    })
  }
