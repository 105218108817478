import React, { PropsWithChildren } from 'react'

import { Text } from 'Components/UI'

import {
  ArrowIcon,
  DropDownContainer,
  DropDownItems,
  DropDownTitle,
} from './styles'

export interface ISelectDropdownProps extends PropsWithChildren {
  isOpened: boolean
  setIsOpened: React.Dispatch<React.SetStateAction<boolean>>
  title?: string
}

function SelectDropdown({
  title = '',
  isOpened,
  setIsOpened,
  children,
}: ISelectDropdownProps) {
  return (
    <DropDownContainer flexDirection="column">
      <DropDownTitle
        $opened={isOpened}
        alignItems="center"
        height={53}
        justifyContent="space-between"
        onClick={() => {
          setIsOpened(!isOpened)
        }}
      >
        <Text bold fontSize={14} mineShaft>
          {title}
        </Text>
        <ArrowIcon $opened={isOpened} />
      </DropDownTitle>

      {isOpened && <DropDownItems>{children}</DropDownItems>}
    </DropDownContainer>
  )
}

export default SelectDropdown
