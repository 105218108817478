import styled from 'styled-components'

import * as icons from 'Assets/Svg/icons'

export const PlusIcon = styled(icons.plusSimple)`
  stroke-width: 1px !important;
`

export const Wrapper = styled.div`
  position: relative;
`
