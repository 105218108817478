import React, { PropsWithChildren } from 'react'

import { Popover } from 'Components/UI'

import * as Styled from './QuickActionMenu.styles'

export interface IQuickActionMenuProps extends PropsWithChildren {
  isOpen: boolean
  trigger: React.ReactNode
  placement: 'bottom-start' | 'right-start'
}

const QuickActionMenu = ({
  isOpen,
  trigger,
  placement,
  children,
}: IQuickActionMenuProps) => {
  if (React.Children.count(children) === 0) {
    return <Styled.TriggerContainer>{trigger}</Styled.TriggerContainer>
  }

  return (
    <Popover
      appendTo={document.body}
      content={<Styled.Container>{children}</Styled.Container>}
      delay={0}
      interactive
      offset={[0, 5]}
      placement={placement}
      visible={isOpen}
      zIndex={999}
    >
      <Styled.TriggerContainer>{trigger}</Styled.TriggerContainer>
    </Popover>
  )
}

export default QuickActionMenu
