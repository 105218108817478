import React, { useCallback, useMemo, useRef, useState } from 'react'
import PropTypes from 'prop-types'

import { CgClose } from 'react-icons/cg'
import { FiSliders } from 'react-icons/fi'

import { useApolloClient } from '@apollo/client'
import debounce from 'awesome-debounce-promise'
import usersQuery from 'GraphQL/Queries/User/users.graphql'
import { setMinSearchLength } from 'Utils/Form'
import { usersToValues } from 'Utils/User'

import map from 'lodash/map'
import upperFirst from 'lodash/upperFirst'

import SelectDropdown from 'Components/Blocks/Filter/SelectDropdown'
import {
  CloseIcon,
  CloseWrapper,
  Container,
  Form,
  TextButton,
  Toggler,
  TotalWrapper,
} from 'Components/Blocks/Filter/styles'
import {
  Checkbox,
  Column,
  DateRangePickerInput,
  Flex,
  Select,
  Text,
} from 'Components/UI'

import { INVITE_STATUSES } from 'Constants/ids'

import { useCommunityContext, useOnClickOutside } from 'Hooks'

const SEARCH_DEBOUNCE = 300
const SEARCH_MINIMUM_LENGTH = 3

const DEFAULT_FILTER = {
  USERS: [],
  STATUSES: {
    [INVITE_STATUSES.ACTIVE]: false,
    [INVITE_STATUSES.ONBOARDING]: false,
    [INVITE_STATUSES.PENDING]: false,
  },
  DATE: {
    from: null,
    to: null,
  },
}

function Filter({ visible, onChange }) {
  const [users, setUsers] = useState(DEFAULT_FILTER.USERS)
  const [statuses, setStatuses] = useState(DEFAULT_FILTER.STATUSES)
  const [date, setDate] = useState(DEFAULT_FILTER.DATE)

  const [opened, setOpened] = useState(false)
  const [openFilter, setOpenFilter] = useState(false)

  const ref = useRef()
  useOnClickOutside(ref, () => setOpened(false))

  const handleChangeStatuses = useCallback(name => {
    setStatuses(prevState => ({ ...prevState, [name]: !prevState[name] }))
  }, [])

  const handleChangeDate = useCallback(({ to, from }) => {
    setDate({ to, from })
  }, [])

  const checkedStatuses = useMemo(
    () => Object.keys(statuses).filter(status => statuses[status]),
    [statuses],
  )
  const total = useMemo(() => {
    const dateFilterTotal = date.from && date.to ? 2 : 0
    return users.length + checkedStatuses.length + dateFilterTotal
  }, [users, checkedStatuses, date])

  const clearAll = useCallback(() => {
    setUsers(DEFAULT_FILTER.USERS)
    setStatuses(DEFAULT_FILTER.STATUSES)
    setDate(DEFAULT_FILTER.DATE)
  }, [])

  const applyFilters = () => {
    onChange({
      createdBefore: date.to,
      createdAfter: date.from,
      states: checkedStatuses.length > 0 ? checkedStatuses : null,
      userIds: users.length > 0 ? map(users, user => user.value) : undefined,
    })
  }

  const { community } = useCommunityContext()
  const client = useApolloClient()

  const loadUsersOptions = useCallback(
    () => async (inputValue, callback) => {
      const result = await client.query({
        query: usersQuery,
        variables: {
          communityId: community?.id,
          search: inputValue,
          limit: 25,
        },
      })

      const usersSuggestions = result.data?.users?.rows || []

      callback(usersToValues(usersSuggestions))
    },
    [community?.id, client],
  )

  const debouncedLoadOptions = useCallback(
    () =>
      setMinSearchLength(
        debounce(loadUsersOptions(), SEARCH_DEBOUNCE),
        SEARCH_MINIMUM_LENGTH,
      ),
    [loadUsersOptions],
  )

  return (
    <Container hidden={!visible}>
      <Toggler active={opened} onClick={() => setOpened(value => !value)}>
        <FiSliders />
        <Text bold mr={2}>
          Advanced Filters
        </Text>
        {total !== 0 && (
          <TotalWrapper ml={2}>
            <Text light small onClick={clearAll}>
              {total}
              <CloseIcon />
            </Text>
          </TotalWrapper>
        )}
      </Toggler>
      <Form
        $opened={opened}
        position="absolute"
        ref={ref}
        right="0"
        top="57px"
        zIndex="1000"
      >
        <Flex
          alignItems="center"
          height={56}
          justifyContent="space-between"
          pl={24}
          pr={24}
        >
          <Text bold mineShaft>
            Filters
          </Text>
          <Flex alignItems="center">
            <TextButton bold colored onClick={applyFilters}>
              Apply
            </TextButton>
            <TextButton bold grayBoulder ml={29} onClick={clearAll}>
              Clear
            </TextButton>
            <CloseWrapper onClick={() => setOpened(value => !value)}>
              <CgClose />
            </CloseWrapper>
          </Flex>
        </Flex>
        <SelectDropdown
          isOpened={openFilter === 1}
          setIsOpened={() => setOpenFilter(openFilter === 1 ? null : 1)}
          title={`Names ${users.length ? `(${users.length})` : ''}`}
        >
          <Select
            async
            flexShrink={0}
            isMulti
            loadOptions={debouncedLoadOptions()}
            placeholder=""
            value={users}
            width={1}
            withLabel={false}
            onChange={setUsers}
          />
        </SelectDropdown>
        <SelectDropdown
          isOpened={openFilter === 2}
          setIsOpened={() => setOpenFilter(openFilter === 2 ? null : 2)}
          title={`Status ${statuses.length ? `(${statuses.length})` : ''}`}
        >
          <Column gap={4}>
            <Checkbox
              checked={statuses[INVITE_STATUSES.ACTIVE]}
              label={upperFirst(INVITE_STATUSES.ACTIVE)}
              rounded
              white
              onChange={() => handleChangeStatuses(INVITE_STATUSES.ACTIVE)}
            />
            <Checkbox
              checked={statuses[INVITE_STATUSES.ONBOARDING]}
              label={upperFirst(INVITE_STATUSES.ONBOARDING)}
              rounded
              white
              onChange={() => handleChangeStatuses(INVITE_STATUSES.ONBOARDING)}
            />
            <Checkbox
              checked={statuses[INVITE_STATUSES.PENDING]}
              label={upperFirst(INVITE_STATUSES.PENDING)}
              rounded
              white
              onChange={() => handleChangeStatuses(INVITE_STATUSES.PENDING)}
            />
          </Column>
        </SelectDropdown>
        <SelectDropdown
          isOpened={openFilter === 3}
          setIsOpened={() => setOpenFilter(openFilter === 3 ? null : 3)}
          title="Date"
        >
          <DateRangePickerInput
            dateRange={date}
            width={1}
            onChange={handleChangeDate}
          />
        </SelectDropdown>
      </Form>
    </Container>
  )
}

Filter.defaultProps = {
  visible: true,
}

Filter.propTypes = {
  visible: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
}

export default Filter
