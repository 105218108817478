import React, { useCallback, useMemo, useState } from 'react'
import { Form } from 'react-final-form'
import PropTypes from 'prop-types'

import { useMutation } from '@apollo/client'
import connectUsersToSkillsMutation from 'GraphQL/Mutations/Community/connectUsersToSkills.graphql'
import connectUsersToTagsMutation from 'GraphQL/Mutations/Community/connectUsersToTags.graphql'
import disconnectUsersFromTagsMutation from 'GraphQL/Mutations/Community/disconnectUsersFromTags.graphql'
import updateUserMutation from 'GraphQL/Mutations/Community/updateUser.graphql'
import createCommunityUserMutation from 'GraphQL/Mutations/CommunityUser/createCommunityUser.graphql'
import removeUserSkillsMutation from 'GraphQL/Mutations/User/removeUserSkills.graphql'
import { getCommunityUserSkillsUpdater } from 'GraphQL/Updaters/GetCommunityUserSkills'
import { getCommunityUserTagsUpdater } from 'GraphQL/Updaters/GetCommunityUserTags'
import { updateCommunityUserDirectConnectionsUpdater } from 'GraphQL/Updaters/User'
import Utils from 'Utils'
import validate from 'validate.js'

import differenceBy from 'lodash/differenceBy'
import find from 'lodash/find'
import fromPairs from 'lodash/fromPairs'
import map from 'lodash/map'
import noop from 'lodash/noop'

import { OPTIONS as RELATIONSHIP_OPTIONS } from 'Components/Blocks/Forms/Fields/RelationshipStrengthField'
import { Column, Divider, MenuItem, Modal, Row, Text } from 'Components/UI'

import {
  PERMISSION_ACTION,
  PERMISSION_SCOPES,
  PERMISSION_SUBJECT,
} from 'Constants/permissions'
import { LINKEDIN_REGEX } from 'Constants/regex'

import { useAppContext, useCommunityContext, usePermission } from 'Hooks'

import EventBus from 'Services/EventBus'
import _, { useScopedI18n } from 'Services/I18n'
import toast from 'Services/Toast'

import { FIELD } from './fields'
import { ErrorWrapper } from './styles'
import { GeneralTab, TagsTab } from './Tabs'

const TABS = {
  GENERAL_INFO: 'General Info',
  TAGS: 'Tags',
}

const DEFAULT_TABS = [TABS.GENERAL_INFO, TABS.TAGS]

function entitiesToValues(entities) {
  return map(entities, entity => ({
    ...entity,
    value: entity.id,
    label: entity.name,
  }))
}

function AddEditUserAccountModal({ user, isOpen, onClose }) {
  const s = useScopedI18n('accountManagement')
  const { can } = usePermission()

  const { community } = useCommunityContext()
  const { me } = useAppContext()

  const [updateUser] = useMutation(updateUserMutation)
  const [createCommunityUser] = useMutation(createCommunityUserMutation)
  const [connectUsersToTags] = useMutation(connectUsersToTagsMutation)
  const [connectUsersToSkills] = useMutation(connectUsersToSkillsMutation)
  const [removeUserSkills] = useMutation(removeUserSkillsMutation)
  const [disconnectUsersFromTags] = useMutation(disconnectUsersFromTagsMutation)

  const [loading, setLoading] = useState(false)
  const [tab, setTab] = useState(TABS.GENERAL_INFO)

  const update = !!user

  const canEditAnyAccount = can(
    PERMISSION_ACTION.EDIT,
    PERMISSION_SUBJECT.ACCOUNT,
    PERMISSION_SCOPES.ACCOUNT_PRIVATE,
  )

  const isCreator = useMemo(
    () => user?.creator?.id === me?.id,
    [me?.id, user?.creator?.id],
  )

  const isStubUser = user?.isStub

  const canEditEmail = useMemo(() => {
    if (!update) return true
    return !!(
      (canEditAnyAccount || isCreator || isStubUser) &&
      user?.email !== 'Private'
    )
  }, [isCreator, update, user?.email, isStubUser, canEditAnyAccount])

  const canEditPhone = useMemo(() => {
    if (!update) return true
    return !!(
      (canEditAnyAccount || isCreator || isStubUser) &&
      user?.phoneNumber !== 'Private'
    )
  }, [isCreator, canEditAnyAccount, update, user?.phoneNumber, isStubUser])

  const initialSkills = useMemo(() => entitiesToValues(user?.skills), [user])

  const initialTags = useMemo(() => {
    const tags = Utils.Tag.tagsByKind(entitiesToValues(user?.tags))
    delete tags.industries

    return tags
  }, [user])

  const initialValues = useMemo(() => {
    const relationshipOption = find(
      RELATIONSHIP_OPTIONS,
      item => item.value === user?.relationshipStrength,
    )

    const values = {
      [FIELD.FIRST_NAME]: user?.firstName,
      [FIELD.LAST_NAME]: user?.lastName,
      [FIELD.PHOTO_URL]: user?.photoUrl,
      [FIELD.JOB]: user?.jobTitle?.name,
      [FIELD.LINKED_IN]: user?.linkedIn || null,
      [FIELD.PHONE_NUMBER]: user?.phoneNumber,
      [FIELD.RELATIONSHIP]: relationshipOption,
      [FIELD.ORGANIZATION]: user?.organization?.name,
      [FIELD.INTERESTS_HOBBIES]: user?.interestsHobbies,
      [FIELD.ABOUT]: user?.about,
      [FIELD.SKILLS]: initialSkills,
      [FIELD.CUSTOM]: entitiesToValues(initialTags.custom),
      [FIELD.PROJECT]: entitiesToValues(initialTags.projects),
      [FIELD.ROLE]: entitiesToValues(initialTags.roles),
      [FIELD.EVENT]: entitiesToValues(initialTags.events),
      [FIELD.GROUP]: entitiesToValues(initialTags.groups),
    }

    if (user?.email !== 'Private') values[FIELD.EMAIL] = user?.email
    if (user?.phoneNumber !== 'Private')
      values[FIELD.PHONE_NUMBER] = user?.phoneNumber

    return values
  }, [user, initialTags, initialSkills])

  const formConstraints = useMemo(() => {
    const constraints = {
      [FIELD.PHOTO_URL]: {
        type: 'string',
      },
      [FIELD.FIRST_NAME]: {
        type: 'string',
        presence: {
          allowEmpty: false,
          message: `^${_('auth.shared.firsNameRequired')}`,
        },
        length: {
          maximum: 255,
          tooLong: `^${_('auth.shared.firsNameTooLong')}`,
        },
      },
      [FIELD.LAST_NAME]: {
        type: 'string',
        presence: {
          allowEmpty: false,
          message: `^${_('auth.shared.lastNameRequired')}`,
        },
        length: {
          maximum: 255,
          tooLong: `^${_('auth.shared.lastNameTooLong')}`,
        },
      },
      [FIELD.JOB]: {
        type: 'string',
        length: {
          maximum: 255,
          tooLong: `^${_('auth.shared.jobTooLong')}`,
        },
      },
      [FIELD.LINKED_IN]: {
        type: 'string',
        format: {
          pattern: LINKEDIN_REGEX,
          message: `^${_('auth.shared.linkedinProfileUrl')}`,
        },
      },
      [FIELD.ORGANIZATION]: {
        type: 'string',
        length: {
          maximum: 255,
          tooLong: `^${_('auth.shared.organizationTooLong')}`,
        },
      },
      [FIELD.INTERESTS_HOBBIES]: {
        type: 'string',
      },
      [FIELD.ABOUT]: {
        type: 'string',
      },
    }

    if (canEditEmail) {
      constraints[FIELD.EMAIL] = {
        presence: {
          allowEmpty: !canEditEmail,
          message: `^${_('auth.shared.emailRequired')}`,
        },
        email: {
          email: canEditEmail,
          message: `^${_('auth.shared.emailInvalid')}`,
        },
      }
    }

    return constraints
  }, [canEditEmail])

  const submit = useCallback(
    async values => {
      setLoading(true)

      const tags = [
        ...values[FIELD.EVENT],
        ...values[FIELD.GROUP],
        ...values[FIELD.PROJECT],
        ...values[FIELD.ROLE],
        ...values[FIELD.CUSTOM],
      ].map(tag => ({
        id: tag.value,
        name: tag.label,
        kind: tag.kind,
      }))

      const skills = [...values[FIELD.SKILLS]].map(skill => ({
        id: skill.value,
        name: skill.label,
      }))

      const changedValues = fromPairs(
        Object.entries(values)?.filter(
          ([key, value]) =>
            initialValues?.[key] !== value && value !== 'Private',
        ),
      )

      if (initialValues.email === changedValues.email) {
        changedValues.email = undefined
        delete changedValues.email
      }

      const removedSkills = differenceBy(
        initialSkills,
        values[FIELD.SKILLS],
        'value',
      )

      const removedTags = differenceBy(
        Object.values(initialTags).flat(),
        tags,
        'value',
      )

      const skillIds = map(values[FIELD.SKILLS], 'value')
      const tagIds = map(tags, 'value')

      try {
        let userId = user?.id
        let communityUserId = user?.communityUserId

        if (update) {
          await updateUser({
            variables: {
              communityId: community?.id,
              userId,
              ...changedValues,
              [FIELD.RELATIONSHIP]: changedValues[FIELD.RELATIONSHIP]?.value,
            },
          })

          if (removedSkills?.length) {
            await removeUserSkills({
              variables: {
                userId: user?.id,
                communityId: community.id,
                skillIds: map(removedSkills, 'value'),
              },
            })
          }

          if (removedTags?.length) {
            const usersFromTags = removedTags.map(tag => ({
              communityUserId: user?.communityUserId,
              tagId: tag.value,
            }))

            await disconnectUsersFromTags({
              variables: {
                communityId: community.id,
                usersFromTags,
              },
            })
          }
        } else {
          const response = await createCommunityUser({
            variables: {
              communityId: community?.id,
              ...values,
              [FIELD.RELATIONSHIP]: values[FIELD.RELATIONSHIP]?.value,
            },
            update: (store, { data: communityAppendUserData }) =>
              values[FIELD.RELATIONSHIP]?.value
                ? updateCommunityUserDirectConnectionsUpdater(
                    community?.id,
                    me?.id,
                    store,
                    communityAppendUserData?.communityAppendUser,
                  )
                : null,
          })

          EventBus.trigger(EventBus.actions.dashboard.refetch)

          userId = response?.data?.createCommunityUser?.userId
          communityUserId = response?.data?.createCommunityUser?.id
        }

        if (skillIds?.length) {
          const communityUsers = [
            {
              communityUserId,
              userId,
              communityId: community?.id,
            },
          ]

          const usersToSkills = []
          skillIds.forEach(skillId => {
            usersToSkills.push({
              skillId,
              communityUserId,
            })
          })

          await connectUsersToSkills({
            variables: {
              communityId: community?.id,
              usersToSkills,
            },
            update: getCommunityUserSkillsUpdater({
              communityUsers,
              skills,
            }),
          })
        }

        if (tagIds?.length) {
          const usersToTags = []
          tagIds.forEach(tagId => {
            usersToTags.push({
              tagId,
              communityUserId,
            })
          })

          const communityUsers = [
            {
              communityUserId,
              userId,
              communityId: community?.id,
            },
          ]

          await connectUsersToTags({
            variables: {
              communityId: community?.id,
              usersToTags,
            },
            update: getCommunityUserTagsUpdater({
              communityIds: [community?.id],
              communityUsers,
              tags,
            }),
          })
        }

        EventBus.trigger(EventBus.actions.graph.addUserById, { userId })

        toast.success({
          title: 'Community User',
          text: `Community user ${update ? 'updated' : 'created'}`,
        })

        setTab(TABS.GENERAL_INFO)
        setLoading(false)

        onClose()
      } catch (error) {
        toast.error({
          title: 'Server error',
          text: _(`error.${error?.message || 'generic'}`),
        })
        setLoading(false)
      }
    },
    [
      initialValues,
      initialSkills,
      initialTags,
      user?.id,
      user?.communityUserId,
      update,
      onClose,
      updateUser,
      community.id,
      removeUserSkills,
      disconnectUsersFromTags,
      createCommunityUser,
      me?.id,
      connectUsersToSkills,
      connectUsersToTags,
    ],
  )

  const modalTitle = useMemo(() => {
    let title

    if (user?.isStub) {
      title = s('actions.editStub')
    } else if (update) {
      title = s('actions.edit')
    } else {
      title = s('actions.add')
    }

    return title
  }, [s, update, user?.isStub])

  const renderForm = useCallback(
    ({ handleSubmit, form }) => {
      const formErrors = Utils.Form.errors(form, FIELD, {
        checkDirty: true,
      })

      return (
        <Modal
          cancelText={_('general.cancel')}
          confirmDisabled={loading}
          confirmText={_('general.save')}
          isOpen={isOpen}
          p={0}
          title={modalTitle}
          width={[0, 0, 600]}
          onClose={onClose}
          onConfirm={handleSubmit}
        >
          <Row fullWidth gap={6} mt={5} px={5}>
            {DEFAULT_TABS.map(item => (
              <MenuItem
                caption={item}
                isActive={item === tab}
                isButton
                key={item}
                onClick={() => setTab(item)}
              />
            ))}
          </Row>

          <Divider my={5} />

          <GeneralTab
            canEditEmail={canEditEmail}
            canEditPhone={canEditPhone}
            mb={5}
            visible={tab === TABS.GENERAL_INFO}
          />

          <TagsTab mb={5} visible={tab === TABS.TAGS} />

          {user?.isStub && (
            <Column gap={5} mb={5}>
              <Divider />
              <Text fontStyle="italic" light px={5} small>
                *user get invite letter after saving this email
              </Text>
            </Column>
          )}

          {Object.values(formErrors).length > 0 && (
            <Row center justifyEnd pb={4}>
              <ErrorWrapper>* Please fill all required fields!</ErrorWrapper>
            </Row>
          )}
        </Modal>
      )
    },
    [
      loading,
      isOpen,
      modalTitle,
      onClose,
      canEditEmail,
      canEditPhone,
      tab,
      user,
    ],
  )

  if (!isOpen) return null

  return (
    <Form
      initialValues={initialValues}
      render={renderForm}
      validate={values => validate(values, formConstraints)}
      onSubmit={submit}
    />
  )
}

AddEditUserAccountModal.defaultProps = {
  isOpen: false,
  user: null,
  onClose: noop,
}

AddEditUserAccountModal.propTypes = {
  isOpen: PropTypes.bool,
  user: PropTypes.object,
  onClose: PropTypes.func,
}

export default AddEditUserAccountModal
