import React, { useCallback, useMemo, useRef } from 'react'
import PropTypes from 'prop-types'

import { useAppContext } from 'Hooks'

import { useScopedI18n } from 'Services/I18n'

import { TableActions } from '../../Blocks'
import { useTableContext } from '../context'
import useAbility from '../useAbility'

function ActionsCell({ row }) {
  const editRef = useRef(null)
  const deleteRef = useRef(null)

  const { me } = useAppContext()
  const s = useScopedI18n('accountManagement.actions')

  const user = useMemo(() => row?.original, [row])

  const { canDeleteIfCreator, canDeleteIfNotCreator, canEditAnyAccount } =
    useAbility()

  const { onEditRow, onDeleteRow } = useTableContext()

  const isMe = user?.id === me?.id

  const isCreator = user?.creator?.id === me?.id

  const canEditUser = isMe || (isCreator && user?.isStub) || canEditAnyAccount
  const canDeleteUser = canDeleteIfCreator(user) || canDeleteIfNotCreator(user)

  const handleEdit = useCallback(() => {
    editRef.current.blur()
    onEditRow(user)
  }, [onEditRow, user])

  const handleDelete = useCallback(() => {
    deleteRef.current.blur()
    onDeleteRow(user)
  }, [onDeleteRow, user])

  return (
    <TableActions
      canDelete={canDeleteUser && !isMe}
      canEdit={canEditUser}
      deleteRef={deleteRef}
      deleteTooltip={s('delete')}
      editRef={editRef}
      editTooltip={s('edit')}
      onDelete={handleDelete}
      onEdit={handleEdit}
    />
  )
}

ActionsCell.defaultProps = {
  row: {},
}

ActionsCell.propTypes = {
  row: PropTypes.object,
}

export default ActionsCell
