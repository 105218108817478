import { useEffect, useState } from 'react'

import { useApolloClient } from '@apollo/client'
import slugExistsQuery from 'GraphQL/Queries/Community/slugExists.graphql'

import { FRONTEND } from 'Config'

function useCommunitySlug() {
  const [loading, setLoading] = useState<boolean>(false)
  const [wrongSlug, setWrongSlug] = useState<boolean>(false)
  const [slug, setSlug] = useState<string | null>(null)

  const client = useApolloClient()

  useEffect(() => {
    setLoading(true)
    async function checkSlug() {
      const url = new URL(window.location.href)

      const subdomain = url.host.replace(FRONTEND.URL, '')?.replace('.', '')

      if (!subdomain) {
        setLoading(false)
        return
      }

      const { data } = await client.query({
        query: slugExistsQuery,
        variables: { slug: subdomain },
      })

      setWrongSlug(!data?.slugExists)
      setSlug(subdomain)
      setLoading(false)
    }

    checkSlug()
  }, [client])

  return {
    slug,
    wrongSlug,
    loading,
  }
}

export default useCommunitySlug
