import styled from 'styled-components'
import { mapToTheme } from 'styled-map'
import { margin, MarginProps } from 'styled-system'
import { themeGet } from '@styled-system/theme-get'

export interface IContainer extends MarginProps {
  large?: boolean
  small?: boolean
}

export const Container = styled.div<IContainer>`
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: ${mapToTheme('badges.size')}px;
  height: ${mapToTheme('badges.size')}px;
  padding-inline: ${mapToTheme('badges.padding')}px;
  border-radius: ${mapToTheme('badges.borderRadius')}px;
  background-color: ${themeGet('colors.badge.bg')};

  ${margin}
`
