import { AiFillStar, AiOutlineStar } from 'react-icons/ai'

import { down } from 'styled-breakpoints'
import styled, { css } from 'styled-components'
import { margin, MarginProps } from 'styled-system'
import { themeGet } from '@styled-system/theme-get'

import { IconCornerRightUp } from '@tabler/icons-react'

import { customScrollbar } from 'Theme/styles'
import { gap, GapProps } from 'Theme/system'

export interface IRightOpenedCss {
  rightOpened?: boolean
}

const rightOpenedCss = ({ rightOpened }: IRightOpenedCss) =>
  rightOpened &&
  css`
    width: 400px;
    filter: blur(0px);
    opacity: 1;

    ${down('md')} {
      height: auto;
      width: 100%;
    }
  `

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
  background: ${themeGet('colors.white')};
  overflow: hidden;
`

export const HeaderHolder = styled.div`
  display: flex;
  width: 100%;
  padding: ${themeGet('space.2')}px ${themeGet('space.1')}px
    ${themeGet('space.2')}px;

  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid ${themeGet('colors.grayAthens')};
`

export const OutlineStarIcon = styled(AiOutlineStar)`
  fill: ${themeGet('colors.grayBoulder')};
`

export const FillStarIcon = styled(AiFillStar)`
  fill: ${themeGet('colors.chartYellow')};
`

export interface INoteEditorScrollContainer extends MarginProps {}

export const NoteEditorScrollContainer = styled.div<INoteEditorScrollContainer>`
  display: flex;
  width: 100%;
  flex-shrink: 0;
  max-height: 50vh;
  overflow: auto;

  ${margin};
  ${customScrollbar};
`

export interface IScrollContainer extends GapProps {}

export const ScrollContainer = styled.div<IScrollContainer>`
  display: flex;
  width: 100%;
  flex-direction: column;
  height: auto;
  min-height: 100px;
  padding: 12px;
  overflow: auto;

  :empty {
    min-height: unset;
  }

  ${down('md')} {
    max-height: 100%;
  }

  ${customScrollbar}
  ${gap}
`

export interface IIconSharedProps {
  green?: boolean
}

export const IconShared = styled(IconCornerRightUp)<IIconSharedProps>`
  color: ${themeGet('colors.greenLight')};
`

export const Left = styled.div`
  display: flex;
  flex-direction: column;
  width: 420px;
  background: ${themeGet('colors.bg.primary')};
  border-right: 1px solid ${themeGet('colors.divider.default')};
  height: auto;
  flex-shrink: 0;

  ${down('md')} {
    width: 100%;
    height: auto;
  }
`

export const Center = styled.div`
  position: relative;
  background: white;
  flex-grow: 1;
  transition: all 0.3s ease;
  overflow: hidden;
  height: 100%;
  width: 100%;
`

export interface IRight extends IRightOpenedCss {}

export const Right = styled.div<IRight>`
  width: 0;
  flex-shrink: 0;
  background: ${themeGet('colors.bg.primary')};
  border-left: 1px solid ${themeGet('colors.divider.default')};
  height: 100%;
  overflow: hidden;
  transition: all 0.3s ease;
  filter: blur(100px);
  opacity: 0;

  ${down('md')} {
    height: 0;
    border: 0;
  }

  ${rightOpenedCss};
`
