import React from 'react'
import { components } from 'react-select'

import { MultiValueLabelWrapper } from './styles'

const MultiValueLabel: typeof components.MultiValueLabel = props => {
  // TODO: find a better way to satisfy the type
  const StyledMultiValueLabelWrapper = MultiValueLabelWrapper as any
  return (
    <StyledMultiValueLabelWrapper {...props} as={components.MultiValueLabel} />
  )
}

export default MultiValueLabel
