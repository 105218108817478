import React, { useCallback, useMemo, useState } from 'react'

import {
  IconAt,
  IconBulb,
  IconCalendarEvent,
  IconChevronRight,
  IconDots,
  IconId,
  IconPuzzle,
  IconSearch,
  IconSTurnLeft,
  IconTag,
  IconUsers,
} from '@tabler/icons-react'

import omit from 'lodash/omit'

import { Column } from 'Components/UI'

import { ACTION_KIND } from 'Constants/graph'

import { useKeys } from 'Hooks'

import CommandItem, { CommandType, ICommand } from './CommandItem'
import { Container } from './styles'

import { useMentionTextareaContext } from '../../context'

const KEYBOARD_KEYS = new Set([
  'Left',
  'Right',
  'Down',
  'Up',
  'ArrowDown',
  'ArrowUp',
  'ArrowLeft',
  'ArrowRight',
  'Enter',
])

const COMMANDS_LIST: ICommand[] = [
  {
    label: 'Mention/Create User',
    id: ACTION_KIND.addUser,
    type: CommandType.Button,
    icon: <IconAt size={16} />,
  },
  {
    label: 'Should connect with',
    id: ACTION_KIND.meetUser,
    type: CommandType.Button,
    icon: <IconSTurnLeft size={16} />,
  },
  {
    label: 'Add skill',
    id: ACTION_KIND.skill,
    type: CommandType.Button,
    icon: <IconPuzzle size={16} />,
  },
  {
    label: 'Looking for skill',
    id: ACTION_KIND.needSkill,
    type: CommandType.Button,
    icon: <IconSearch size={16} />,
  },
  {
    label: 'See more',
    id: 'seeMore',
    type: CommandType.List,
    icon: <IconDots size={16} />,
    subIcon: <IconChevronRight size={16} />,
    children: [
      {
        label: 'Event',
        id: ACTION_KIND.event,
        parentId: 'seeMore',
        type: CommandType.Button,
        icon: <IconCalendarEvent size={16} />,
      },
      {
        label: 'Project',
        id: ACTION_KIND.project,
        parentId: 'seeMore',
        type: CommandType.Button,
        icon: <IconBulb size={16} />,
      },
      {
        label: 'Group',
        id: ACTION_KIND.group,
        parentId: 'seeMore',
        type: CommandType.Button,
        icon: <IconUsers size={16} />,
      },
      {
        label: 'Role',
        id: ACTION_KIND.role,
        parentId: 'seeMore',
        type: CommandType.Button,
        icon: <IconId size={16} />,
      },
      {
        label: 'Custom tag',
        id: ACTION_KIND.custom,
        parentId: 'seeMore',
        type: CommandType.Button,
        icon: <IconTag size={16} />,
      },
    ],
  },
]

export type CommandsSelectHandler = (command: ICommand) => void

export interface ICommandsProps {
  onSelect: CommandsSelectHandler
}

function Commands({ onSelect }: ICommandsProps) {
  const { dropdownVisible } = useMentionTextareaContext()

  // TODO: Rewrite to use tree
  // openedIds, activeChildIndex
  const [activeIndex, setActiveIndex] = useState(0)
  const [activeListIndex, setActiveListIndex] = useState<number | null>(null)

  const commands = useMemo(() => {
    return COMMANDS_LIST.flatMap(command => {
      if (command.children) {
        return command.children.map(child => omit(child, 'parentId'))
      }
      return command
    })
  }, [])

  useKeys(event => {
    if (!dropdownVisible) return
    const isListCommand = commands[activeIndex]?.type === CommandType.List

    const { key } = event

    if (isListCommand && activeListIndex !== null) {
      switch (key) {
        case 'Left':
        case 'ArrowLeft':
          setActiveListIndex(null)
          return
        case 'Up':
        case 'ArrowUp':
          setActiveListIndex(prevState =>
            prevState && prevState > 0
              ? prevState - 1
              : commands[activeIndex]!.children!.length - 1,
          )
          return
        case 'Down':
        case 'ArrowDown':
          setActiveListIndex(
            prevState =>
              (prevState! + 1) % commands[activeIndex]!.children!.length,
          )
          return
        case 'Enter': {
          const selectedNode = commands[activeIndex]!.children![activeListIndex]
          handleSelect(selectedNode)
          return
        }

        default:
          return
      }
    }

    switch (key) {
      case 'Up':
      case 'ArrowUp':
        setActiveIndex(prevState =>
          prevState > 0 ? prevState - 1 : commands.length - 1,
        )
        break
      case 'Right':
      case 'ArrowRight':
        if (isListCommand) setActiveListIndex(0)
        break
      case 'Down':
      case 'ArrowDown':
        setActiveIndex(prevState => (prevState + 1) % commands.length)
        break
      case 'Enter': {
        const selectedNode = commands[activeIndex]
        if (selectedNode.type === CommandType.List) {
          setActiveListIndex(0)
        } else {
          handleSelect(selectedNode)
        }
        break
      }
      default:
        break
    }
  }, KEYBOARD_KEYS)

  const handleSelect = useCallback(
    (command: ICommand) => {
      if (command.type === CommandType.List) {
        setActiveListIndex(0)
      } else {
        onSelect(command)
      }
    },
    [onSelect],
  )

  const handleHover = useCallback((command: ICommand, index: number) => {
    if (command.parentId) {
      setActiveListIndex(index)
    } else {
      setActiveIndex(index)
      setActiveListIndex(command.type === CommandType.List ? 0 : null)
    }
  }, [])

  const renderCommands = useMemo(
    () =>
      (list: ICommand[], child: boolean = false) =>
        list.map((command, index) => {
          const isHighlighted =
            (child ? activeListIndex : activeIndex) === index

          return (
            <CommandItem
              command={command}
              highlight={isHighlighted}
              key={command.id}
              onClick={handleSelect}
              onMouseEnter={() => handleHover(command, index)}
            />
          )
        }),
    [activeIndex, activeListIndex, handleHover, handleSelect],
  )

  const isListCommand = useMemo(
    () => commands[activeIndex]?.type === CommandType.List,
    [activeIndex, commands],
  )

  return (
    <Container>
      <Column fullWidth minWidth="250px">
        {renderCommands(commands)}
      </Column>

      {isListCommand && activeListIndex !== null && (
        <Column fullWidth minWidth="250px">
          {commands[activeIndex].children &&
            renderCommands(commands[activeIndex]!.children!, true)}
        </Column>
      )}
    </Container>
  )
}

export default Commands
