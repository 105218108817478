import styled from 'styled-components'
import { themeGet } from '@styled-system/theme-get'

export const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: ${themeGet('space.2')}px;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  background-color: ${themeGet('colors.bg.primary')};
`
