import React, { useCallback, useEffect, useMemo, useState } from 'react'

import { useMutation } from '@apollo/client'
import { IconSearch } from '@tabler/icons-react'
import disconnectCommunityHubSpotMutation from 'GraphQL/Mutations/CommunityHubSpots/disconnectCommunityHubSpot.graphql'
import userHubSpotQuery from 'GraphQL/Queries/Community/communityHubSpots.graphql'
import { entityCacheRemover } from 'GraphQL/Updaters/Common'
import Utils from 'Utils'

import ConnectHubSpot from 'Components/Blocks/ConnectHubSpot'
import ContentCard from 'Components/Blocks/ContentCard'
import DisconnectHubSpotModal from 'Components/Blocks/Modals/DisconnectHubSpot'
import { Input, Table } from 'Components/UI'

import {
  PERMISSION_ACTION,
  PERMISSION_SCOPE,
  PERMISSION_SUBJECT,
} from 'Constants/permissions'

import {
  useCommunity,
  useEntityModal,
  useEntityTable,
  usePermission,
  useTableSearch,
} from 'Hooks'

import { useQuery } from 'Services/Apollo'
import { useScopedI18n } from 'Services/I18n'
import toast from 'Services/Toast'

import { useColumns } from './columns'
import TableContext from './context'

import { TableControlContainer, TablePagination } from '../Blocks'

const DEFAULT_PAGE_SIZE = 10

function HubSpotsTable() {
  const s = useScopedI18n('hubSpotsManagement')
  const { community } = useCommunity()
  const { can } = usePermission()

  const [disconnectModal, disconnectActions] = useEntityModal({
    context: s('actions.delete'),
  })

  const [mostRecentPage, setMostRecentPage] = useState(0)
  const [mostRecentLimit, setMostRecentLimit] = useState(DEFAULT_PAGE_SIZE)
  const [innerSearch, setInnerSearch] = useState(null)

  const [disconnectCommunityHubSpot] = useMutation(
    disconnectCommunityHubSpotMutation,
    {
      update: entityCacheRemover({
        entity: disconnectModal.entity,
        pageSize: mostRecentLimit,
        queryKey: 'communityHubSpot',
      }),
    },
  )

  const [search, changeSearch, clearSearch] = useTableSearch({
    onSearch: value => {
      Utils.Search.tableSearch({
        search: value,
        onSetSearchValue: setInnerSearch,
        onGoToPage: tableProps.gotoPage,
      })
    },
  })

  const { data, loading } = useQuery(userHubSpotQuery, {
    variables: {
      communityId: community?.id,
      page: mostRecentPage,
      limit: mostRecentLimit,
      search: innerSearch,
    },
    skip: !community?.id,
    fetchPolicy: 'network-only',
  })

  const columns = useColumns()
  const entities = useMemo(() => data?.communityHubSpots || {}, [data])

  const [tableProps, rowsCount] = useEntityTable({
    data: entities,
    columns,
    disableSortBy: true,
  })

  const { pageSize, pageIndex } = tableProps.state

  useEffect(() => {
    setMostRecentPage(pageIndex)
  }, [pageIndex])

  useEffect(() => {
    setMostRecentPage(0)
    setMostRecentLimit(pageSize)
  }, [pageSize])

  const handleDisconnectModalClose = disconnectActions.closeModal

  const handleDisconnectHubSpot = useCallback(
    async success => {
      if (success) {
        try {
          await disconnectCommunityHubSpot({
            variables: {
              id: disconnectModal.entity?.id,
              communityId: disconnectModal.entity?.communityId,
            },
          })

          handleDisconnectModalClose().then()

          toast.success({
            title: s('actions.disconnect'),
            text: s('disconnectSuccess', {
              userName: `${disconnectModal.entity?.domain}`,
            }),
          })
        } catch (error) {
          toast.error({
            title: s('actions.disconnect'),
            text: error.message,
          })
        }
      }
    },
    [
      handleDisconnectModalClose,
      disconnectCommunityHubSpot,
      disconnectModal.entity,
      s,
    ],
  )

  const canConnect = can(PERMISSION_ACTION.CREATE, PERMISSION_SUBJECT.HUBSPOT, [
    PERMISSION_SCOPE.OWN,
  ])

  const memoizedContext = useMemo(
    () => ({
      onDeleteRow: disconnectActions.openModal,
    }),
    [disconnectActions?.openModal],
  )

  return (
    <ContentCard>
      <TableContext.Provider value={memoizedContext}>
        <TableControlContainer>
          <Input
            clearable
            name="search"
            placeholder={s('search.byHub')}
            renderBeforeElement={() => <IconSearch />}
            value={search}
            width="300px"
            onChange={changeSearch}
            onClear={clearSearch}
          />

          <ConnectHubSpot enabled={canConnect} text={s('actions.connect')} />
        </TableControlContainer>

        <Table {...tableProps} loading={loading} />

        <TablePagination
          state={{ pageIndex, pageSize }}
          total={rowsCount}
          onPageIndexChange={tableProps?.gotoPage}
          onPageSizeChange={tableProps?.setPageSize}
        />

        <DisconnectHubSpotModal
          content={
            <>
              {s('disconnectHubSpotConfirmation.0')}{' '}
              <span>{disconnectModal.entity?.domain}</span>
              {s('disconnectHubSpotConfirmation.1')}
            </>
          }
          isOpen={disconnectModal.isOpen}
          title={s('actions.disconnect')}
          onClose={handleDisconnectModalClose}
          onConfirm={handleDisconnectHubSpot}
        />
      </TableContext.Provider>
    </ContentCard>
  )
}

export default HubSpotsTable
