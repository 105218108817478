import { useCallback, useMemo } from 'react'

import {
  AsYouTypeFormatter,
  PhoneNumberFormat,
  PhoneNumberUtil,
} from 'google-libphonenumber'

const phoneUtil = PhoneNumberUtil.getInstance()

export default function usePhoneNumber() {
  const countryCode = useMemo(() => {
    const locale = new Intl.Locale(navigator.language)
    return locale.region ?? 'US'
  }, [])

  const validatePhoneNumber = useCallback(
    (value: string) => {
      try {
        return phoneUtil.isValidNumber(phoneUtil.parse(value, countryCode))
      } catch {
        return false
      }
    },
    [countryCode],
  )

  const formatPhoneNumber = useCallback(
    (value: string) => {
      const formatter = new AsYouTypeFormatter(countryCode)

      return value
        .split('')
        .reduce((_, digit) => formatter.inputDigit(digit), '')
    },
    [countryCode],
  )

  const formalizePhoneNumber = useCallback(
    (value: string) => {
      try {
        return validatePhoneNumber(value)
          ? phoneUtil.format(
              phoneUtil.parse(value, countryCode),
              PhoneNumberFormat.INTERNATIONAL,
            )
          : value
      } catch {
        return value
      }
    },
    [countryCode, validatePhoneNumber],
  )

  return {
    formalizePhoneNumber,
    formatPhoneNumber,
    validatePhoneNumber,
  }
}
