import React, { useCallback, useMemo, useState } from 'react'
import { Form, FormRenderProps } from 'react-final-form'

import { useMutation, useQuery } from '@apollo/client'
import { firstInitialOrFallback } from 'Features/CommunityUsers/communityUserNameUtil'
import communityConnectUsersMutation from 'GraphQL/Mutations/Community/communityConnectUsers.graphql'
import updateCommunityUserMutation from 'GraphQL/Mutations/CommunityUser/updateCommunityUser.graphql'
import setUserRelationshipMutation from 'GraphQL/Mutations/User/setUserRelationship.graphql'
import getCommunityUserQuery from 'GraphQL/Queries/CommunityUser/getCommunityUser.graphql'
import { relationshipStrengthUpdater } from 'GraphQL/Updaters/RelationshipStrength'
import validate from 'validate.js'

import RelationshipStrengthSelectField from 'Components/Blocks/Community/RelationshipStrengthSelectField'
import {
  AvatarInputField,
  Column,
  Icon,
  InputField,
  Modal,
  Row,
} from 'Components/UI'

import { UPLOAD_TYPES } from 'Constants/ids'
import { Source, UserRelationship } from 'Constants/mainGraphQL'
import { FACEBOOK_REGEX, LINKEDIN_REGEX, TWITTER_REGEX } from 'Constants/regex'

import { useAppContext, useCommunityContext } from 'Hooks'

import EventBus from 'Services/EventBus'
import _, { useScopedI18n } from 'Services/I18n'
import toast from 'Services/Toast'

enum UpdateContactFormField {
  PhotoUrl = 'photoUrl',
  FirstName = 'firstName',
  LastName = 'lastName',
  RelationshipStrength = 'relationshipStrength',
  JobTitle = 'jobTitle',
  Organization = 'organization',
  LinkedInUrl = 'linkedInUrl',
  TwitterUrl = 'twitterUrl',
  FacebookUrl = 'facebookUrl',
}

interface UpdateContactFormValues {
  [UpdateContactFormField.PhotoUrl]: string
  [UpdateContactFormField.FirstName]: string
  [UpdateContactFormField.LastName]: string
  [UpdateContactFormField.RelationshipStrength]: UserRelationship
  [UpdateContactFormField.JobTitle]: string
  [UpdateContactFormField.Organization]: string
  [UpdateContactFormField.LinkedInUrl]: string
  [UpdateContactFormField.TwitterUrl]: string
  [UpdateContactFormField.FacebookUrl]: string
}

export interface UpdateContactModalProps {
  isOpen?: boolean
  communityUserId?: string
  onClose?: (success: boolean) => void
}

const UpdateContactModal: React.FC<UpdateContactModalProps> = ({
  isOpen,
  communityUserId,
  onClose,
}) => {
  const t = useScopedI18n('components.blocks.modals.updateContactModal')
  const { me } = useAppContext()
  const { community } = useCommunityContext()

  const { data: communityUserData, loading: isCommunityUserLoading } = useQuery<
    Pick<MainSchema.Query, 'getCommunityUser'>,
    MainSchema.QueryGetCommunityUserArgs
  >(getCommunityUserQuery, {
    skip: !community?.id && !communityUserId,
    variables: {
      communityIds: [community?.id!],
      communityUserId,
    },
  })
  const [updateCommunityUser] = useMutation<
    Pick<MainSchema.Mutation, 'updateCommunityUser'>,
    MainSchema.MutationUpdateCommunityUserArgs
  >(updateCommunityUserMutation)
  const [connect] = useMutation<
    Pick<MainSchema.Mutation, 'communityConnectUsers'>,
    MainSchema.MutationCommunityConnectUsersArgs
  >(communityConnectUsersMutation)
  const [setUserRelationship] = useMutation<
    Pick<MainSchema.Mutation, 'setUserRelationship'>,
    MainSchema.MutationSetUserRelationshipArgs
  >(setUserRelationshipMutation)

  const [isLoading, setIsLoading] = useState(false)
  const communityUser = communityUserData?.getCommunityUser

  // TODO: allow validation constraints to be shared between similar forms
  const updateContactFormConstraints = {
    [UpdateContactFormField.PhotoUrl]: {
      type: 'string',
    },
    [UpdateContactFormField.FirstName]: {
      type: 'string',
      presence: {
        allowEmpty: false,
        message: `^${_('auth.shared.firsNameRequired')}`,
      },
      length: {
        maximum: 255,
        tooLong: `^${_('auth.shared.firsNameTooLong')}`,
      },
    },
    [UpdateContactFormField.LastName]: {
      type: 'string',
      presence: {
        allowEmpty: false,
        message: `^${_('auth.shared.lastNameRequired')}`,
      },
      length: {
        maximum: 255,
        tooLong: `^${_('auth.shared.lastNameTooLong')}`,
      },
    },
    [UpdateContactFormField.JobTitle]: {
      type: 'string',
      length: {
        maximum: 255,
        tooLong: `^${_('auth.shared.jobTooLong')}`,
      },
    },
    [UpdateContactFormField.Organization]: {
      type: 'string',
      length: {
        maximum: 255,
        tooLong: `^${_('auth.shared.organizationTooLong')}`,
      },
    },
    [UpdateContactFormField.LinkedInUrl]: {
      type: 'string',
      format: {
        pattern: LINKEDIN_REGEX,
        message: '^Use linkedin profile url',
      },
    },
    [UpdateContactFormField.TwitterUrl]: {
      type: 'string',
      format: {
        pattern: TWITTER_REGEX,
        message: '^Use twitter profile url',
      },
    },
    [UpdateContactFormField.FacebookUrl]: {
      type: 'string',
      format: {
        pattern: FACEBOOK_REGEX,
        message: '^Use facebook profile url',
      },
    },
  }

  const initialValues = useMemo(() => {
    return {
      [UpdateContactFormField.PhotoUrl]: communityUser?.photoUrl,
      [UpdateContactFormField.FirstName]: communityUser?.firstName,
      [UpdateContactFormField.LastName]: communityUser?.lastName,
      [UpdateContactFormField.RelationshipStrength]:
        communityUser?.relationshipStrength,
      [UpdateContactFormField.LinkedInUrl]: communityUser?.linkedInUrl,
      [UpdateContactFormField.TwitterUrl]: communityUser?.twitterUrl,
      [UpdateContactFormField.FacebookUrl]: communityUser?.facebookUrl,
    }
  }, [communityUser])
  const firstNameInitial = firstInitialOrFallback(communityUser)
  const isMe = communityUser?.userId === me?.id
  const canSetRelationship = !isMe && communityUser?.userId

  const submit = useCallback(
    async (values: UpdateContactFormValues) => {
      if (!communityUser || !me) {
        return
      }

      setIsLoading(true)

      const relationshipStrength =
        values[UpdateContactFormField.RelationshipStrength]

      try {
        await updateCommunityUser({
          variables: {
            id: communityUser.id,
            communityId: communityUser.communityId,
            userId: communityUser.userId,
            firstName: values[UpdateContactFormField.FirstName],
            lastName: values[UpdateContactFormField.LastName],
            photoUrl: values[UpdateContactFormField.PhotoUrl],
            // TODO: job title can't be removed currently
            jobTitle: values[UpdateContactFormField.JobTitle],
            // TODO: organization can't be removed currently
            organization: values[UpdateContactFormField.Organization],
            linkedInUrl: values[UpdateContactFormField.LinkedInUrl],
            facebookUrl: values[UpdateContactFormField.FacebookUrl],
            twitterUrl: values[UpdateContactFormField.TwitterUrl],
            relationship: canSetRelationship ? relationshipStrength : undefined,
            // TODO: Right now we need to pass all fields otherwise they get wiped. Update BE to allow only passing fields that we want to update.
            about: communityUser.about,
            email: communityUser.email,
            phoneNumber: communityUser.phoneNumber,
          },
          refetchQueries: [
            {
              query: getCommunityUserQuery,
              variables: {
                communityIds: [communityUser.communityId],
                userId: communityUser.userId,
              },
            },
          ],
        })

        if (canSetRelationship && relationshipStrength) {
          await connect({
            variables: {
              communityId: communityUser.communityId,
              fromUserId: communityUser.userId,
              toUserId: me.userId,
              source: Source.NetworkOs,
            },
          })

          await setUserRelationship({
            variables: {
              userId: communityUser.userId,
              relationship: relationshipStrength,
            },
            update: relationshipStrengthUpdater({
              fromUserId: me.id,
              user: communityUser,
              communityId: communityUser.communityId,
              relationshipStrength,
            }),
          })
        }

        // TODO: figure out a better way to update the graph
        EventBus.trigger(EventBus.actions.graph.addUsersById, {
          userIds: [communityUser.userId],
          forceLayoutReset: false,
          forceReload: true,
        })

        toast.success({
          title: t('toast.title'),
          text: t('toast.success'),
        })

        onClose?.(true)
      } catch (error) {
        let message = _('error.generic')

        if (error instanceof Error) {
          message = _(`error.${error.message || 'generic'}`)
        }

        toast.error({
          title: t('toast.title'),
          text: message,
        })
      } finally {
        setIsLoading(false)
      }
    },
    [
      updateCommunityUser,
      connect,
      setUserRelationship,
      me,
      canSetRelationship,
      communityUser,
      onClose,
      t,
    ],
  )

  const renderForm = useCallback(
    ({ handleSubmit, valid }: FormRenderProps<UpdateContactFormValues>) => {
      return (
        <Modal
          cancelText={t('form.cancel')}
          confirmDisabled={isLoading || !valid}
          confirmText={t('form.submit')}
          isOpen={isOpen}
          title={t('title')}
          width="456px"
          onClose={() => onClose?.(true)}
          onConfirm={handleSubmit}
        >
          <Column gap={3}>
            <AvatarInputField
              entityId={communityUser?.userId}
              entityText={firstNameInitial}
              name={UpdateContactFormField.PhotoUrl}
              uploadType={UPLOAD_TYPES.profilePhoto}
            />

            <InputField
              checkErrorIfDirty
              clearable
              label={t('form.firstName.label')}
              name={UpdateContactFormField.FirstName}
              required
            />

            <InputField
              checkErrorIfDirty
              clearable
              label={t('form.lastName.label')}
              name={UpdateContactFormField.LastName}
              required
            />

            {canSetRelationship && (
              <RelationshipStrengthSelectField
                name={UpdateContactFormField.RelationshipStrength}
              />
            )}

            <InputField
              checkErrorIfDirty
              clearable
              label={t('form.jobTitle.label')}
              name={UpdateContactFormField.JobTitle}
            />

            <InputField
              checkErrorIfDirty
              clearable
              label={t('form.organization.label')}
              name={UpdateContactFormField.Organization}
            />

            <InputField
              checkErrorIfDirty
              clearable
              label={
                <Row as="span" center gap={1} pb={1}>
                  <Icon.LinkedIn size={20} /> {t('form.linkedInUrl.label')}
                </Row>
              }
              name={UpdateContactFormField.LinkedInUrl}
              placeholder={t('form.linkedInUrl.placeholder')}
            />

            <InputField
              checkErrorIfDirty
              clearable
              label={
                <Row as="span" center gap={1} pb={1}>
                  <Icon.Twitter size={20} /> {t('form.twitterUrl.label')}
                </Row>
              }
              name={UpdateContactFormField.TwitterUrl}
              placeholder={t('form.twitterUrl.placeholder')}
            />

            <InputField
              checkErrorIfDirty
              clearable
              label={
                <Row as="span" center gap={1} pb={1}>
                  <Icon.Facebook size={20} /> {t('form.facebookUrl.label')}
                </Row>
              }
              name={UpdateContactFormField.FacebookUrl}
              placeholder={t('form.facebookUrl.placeholder')}
            />
          </Column>
        </Modal>
      )
    },
    [
      isLoading,
      isOpen,
      onClose,
      communityUser,
      firstNameInitial,
      canSetRelationship,
      t,
    ],
  )

  if (!isOpen || isCommunityUserLoading) return null

  return (
    <Form<UpdateContactFormValues>
      initialValues={initialValues}
      render={renderForm}
      validate={values => validate(values, updateContactFormConstraints)}
      onSubmit={submit}
    />
  )
}

export default UpdateContactModal
