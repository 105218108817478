import React from 'react'
import { components } from 'react-select'

import { IconX } from '@tabler/icons-react'

const MultiValueRemove: typeof components.MultiValueRemove = props => {
  return (
    <components.MultiValueRemove {...props}>
      <IconX />
    </components.MultiValueRemove>
  )
}

export default MultiValueRemove
