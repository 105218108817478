import { ApolloLink } from '@apollo/client'

import { getAccessToken } from 'Services/Store/auth'

export default function authLink() {
  return new ApolloLink((operation, forward) => {
    operation.setContext(({ headers = {} }) => {
      const accessToken = getAccessToken()

      return {
        headers: {
          ...headers,
          Authorization: accessToken ? `Bearer ${accessToken}` : '',
        },
      }
    })

    return forward(operation)
  })
}
