import { StoreonModule } from 'storeon'

import get from 'lodash/get'
import isEqual from 'lodash/isEqual'

import { getStore } from 'Services/Shared'

export const COMMUNITY_STATE_NAMESPACE = 'community'

export interface CommunityState {
  [COMMUNITY_STATE_NAMESPACE]: MainSchema.Community | undefined
}

const COMMUNITY_DEFAULTS: CommunityState[typeof COMMUNITY_STATE_NAMESPACE] =
  undefined

enum CommunityStateEvent {
  Set = `${COMMUNITY_STATE_NAMESPACE}/set`,
  Clear = `${COMMUNITY_STATE_NAMESPACE}/clear`,
}

export interface CommunityStateEvents {
  [CommunityStateEvent.Set]: MainSchema.Community
  [CommunityStateEvent.Clear]: undefined
}

export function getCommunity() {
  return get(getStore().get(), COMMUNITY_STATE_NAMESPACE)
}

export function setCommunity(
  community: CommunityStateEvents[CommunityStateEvent.Set],
) {
  return getStore().dispatch(CommunityStateEvent.Set, community)
}

export function clearCommunity() {
  return getStore().dispatch(CommunityStateEvent.Clear)
}

const communityStateModule: StoreonModule<
  CommunityState,
  CommunityStateEvents
> = store => {
  store.on('@init', () => ({
    [COMMUNITY_STATE_NAMESPACE]: COMMUNITY_DEFAULTS,
  }))

  store.on(CommunityStateEvent.Set, (state, nextCommunity) => {
    const currentCommunity = state[COMMUNITY_STATE_NAMESPACE]

    if (isEqual(nextCommunity, currentCommunity)) return null

    return { [COMMUNITY_STATE_NAMESPACE]: nextCommunity }
  })

  store.on(CommunityStateEvent.Clear, () => ({
    [COMMUNITY_STATE_NAMESPACE]: COMMUNITY_DEFAULTS,
  }))
}

export default communityStateModule
