const palette = {
  white: '#ffffff',
  black: '#000000',

  primary10: '#ffedf1',
  primary20: '#ffcdd7',
  primary30: '#ffadbe',
  primary40: '#ff8da5',
  primary50: '#ff6d8b',
  primary5: '#ffedf180',
  primary60: '#ff4d72',
  primary70: '#e72f55',
  primary80: '#cd1c45',
  primary90: '#95001f',
  primary100: '#6c0017',

  primaryNeutral0: '#ffffff',
  primaryNeutral10: '#f9fafb',
  primaryNeutral20: '#dde1e4',
  primaryNeutral30: '#c6cbd0',
  primaryNeutral40: '#afb6bb',
  primaryNeutral50: '#9aa1a7',
  primaryNeutral60: '#858c92',
  primaryNeutral70: '#70787e',
  primaryNeutral80: '#5c646a',
  primaryNeutral90: '#495055',
  primaryNeutral100: '#373c41',

  secondaryNeutral0: '#2d2d2d99',
  secondaryNeutral10: '#dedede',
  secondaryNeutral20: '#cacaca',
  secondaryNeutral30: '#b5b5b5',
  secondaryNeutral40: '#a1a1a1',
  secondaryNeutral50: '#8c8c8c',
  secondaryNeutral60: '#787878',
  secondaryNeutral70: '#646464',
  secondaryNeutral80: '#4f4f4f',
  secondaryNeutral90: '#3b3b3b',
  secondaryNeutral100: '#121212',

  info10: '#e9f5ff',
  info20: '#a7d8ff',
  info30: '#65baff',
  info40: '#2196f3',
  info50: '#0363af',
  info60: '#003c6b',

  success10: '#f5fff0',
  success20: '#c9ffb0',
  success30: '#97f46b',
  success40: '#6ec147',
  success50: '#4a8e2a',
  success60: '#2b5b14',

  warning10: '#fff9e6',
  warning20: '#ffe69c',
  warning30: '#ffd351',
  warning40: '#ffc107',
  warning50: '#bb8c00',
  warning60: '#775900',

  error10: '#ffeceb',
  error20: '#ff9992',
  error30: '#f44336',
  error40: '#c1271b',
  error50: '#8e1209',
  error60: '#5b0600',

  winterLakes10: '#f2f9ff',
  winterLakes20: '#bbdfff',
  winterLakes30: '#84c5ff',
  winterLakes40: '#5b98cf',
  winterLakes50: '#386d9c',
  winterLakes60: '#1d4569',

  skylar10: '#f4fffe',
  skylar20: '#c1fff8',
  skylar30: '#8ffff3',
  skylar40: '#67ccc1',
  skylar50: '#459990',
  skylar60: '#28665f',

  hokkaidoLavender10: '#f5eeff',
  hokkaidoLavender20: '#caa3ff',
  hokkaidoLavender30: '#9f58ff',
  hokkaidoLavender40: '#7e3ada',
  hokkaidoLavender50: '#591fa7',
  hokkaidoLavender60: '#380d74',

  pasadenaRose10: '#fff3f4',
  pasadenaRose20: '#ffbec1',
  pasadenaRose30: '#ff888f',
  pasadenaRose40: '#dd6a70',
  pasadenaRose50: '#aa484d',
  pasadenaRose60: '#772c30',

  goldenSpice10: '#fffaef',
  goldenSpice20: '#ffe4a8',
  goldenSpice30: '#f7c85e',
  goldenSpice40: '#c49b3e',
  goldenSpice50: '#917025',
  goldenSpice60: '#5e4712',

  shutterCopper10: '#fff4eb',
  shutterCopper20: '#ffc693',
  shutterCopper30: '#f08f37',
  shutterCopper40: '#bd691c',
  shutterCopper50: '#8a470a',
  shutterCopper60: '#572900',

  clematisGreen10: '#fbfff2',
  clematisGreen20: '#e3f9b4',
  clematisGreen30: '#c5ea75',
  clematisGreen40: '#97b752',
  clematisGreen50: '#6b8434',
  clematisGreen60: '#40511c',

  opiumMauve10: '#fbf6f7',
  opiumMauve20: '#f1c2d2',
  opiumMauve30: '#c99aaa',
  opiumMauve40: '#a07684',
  opiumMauve50: '#775460',
  opiumMauve60: '#4e353d',

  lusciousPurple10: '#ecebed',
  lusciousPurple20: '#dcd6e9',
  lusciousPurple30: '#b1aac1',
  lusciousPurple40: '#888198',
  lusciousPurple50: '#615b6f',
  lusciousPurple60: '#3c3746',
}

export default palette
