import React, { useCallback } from 'react'
import { SelectSingleEventHandler } from 'react-day-picker'

import { pick } from '@styled-system/props'

import { StyledPicker } from './styles'

export interface IDatePickerProps {
  value?: Date
  onChange?: (value: Date) => void
}

function DatePicker({ value, onChange, ...rest }: IDatePickerProps) {
  const handleSelect = useCallback<SelectSingleEventHandler>(
    (day, selectedDay) => {
      onChange?.(selectedDay)
    },
    [onChange],
  )

  return (
    <StyledPicker
      {...pick(rest)}
      mode="single"
      modifiersClassNames={{
        selected: 'rdp-day_single_selected',
      }}
      selected={value}
      showOutsideDays
      onSelect={handleSelect}
    />
  )
}

export default DatePicker
