import { Operation, split } from '@apollo/client'

import { getRequestLink } from '../utils'

export default function requestLink() {
  return split(
    ({ getContext }: Operation) => getContext().admin === true,
    getRequestLink('admin/graphql'),
    getRequestLink('graphql'),
  )
}
