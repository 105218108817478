import React from 'react'

import { pick } from '@styled-system/props'

import Text from 'Components/UI/Text'

import { Container, InvisibleRadio, StyledRadioButton } from './styles'

export interface IRadioButtonProps {
  checked?: boolean
  disabled?: boolean
  label?: React.ReactNode
  name: string
  value?: string | number
  onBlur?: React.FocusEventHandler<HTMLInputElement>
  onChange?: React.ChangeEventHandler<HTMLInputElement>
  onFocus?: React.FocusEventHandler<HTMLInputElement>
}

function RadioButton({
  checked = false,
  disabled,
  label,
  name,
  value,
  onChange,
  onFocus,
  onBlur,
  ...rest
}: IRadioButtonProps) {
  return (
    <Container {...pick(rest)}>
      <InvisibleRadio
        defaultChecked={checked}
        disabled={disabled}
        name={name}
        value={value}
        onBlur={onBlur}
        onChange={onChange}
        onFocus={onFocus}
      />
      <StyledRadioButton />

      {label && (
        <Text
          as="div"
          disabled={disabled}
          fontSize={12}
          header={!disabled}
          header4
        >
          {label}
        </Text>
      )}
    </Container>
  )
}

export default RadioButton
