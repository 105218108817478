import palette from 'Theme/palette'

const colors = {
  main: {
    primary: '#CD1C45',
    error: '#CD1C45',
    error05: '#cd1c450d',
    success: '#10B981',
    success05: '#10b9810d',
  },

  text: {
    primary: '#2D2D2D',
    secondary: '#757575',
    disabled: '#BDBDBD',
    inverted: palette.white,
    error: '#CD1C45',
  },

  input: {
    background: '#ffffff',
    value: '#2D2D2D',
    message: '#545454',
    error: '#CD1C45',
    border: {
      enabled: '#E0E0E0',
      error: '#CD1C45',
    },
  },

  link: {
    primary: {
      main: palette.primary80,
      hover: palette.primary90,
      focus: palette.primary100,
    },

    secondary: {
      main: '#757575',
      hover: palette.primary90,
      focus: palette.primary100,
    },
  },

  button: {
    action: {
      enabled: '#757575',
      hover: '#2d2d2d',
      focus: '#373c41',
      disabled: '#bdbdbd',
    },
  },

  chip: {
    primary: {
      background: 'rgba(205, 28, 69, 0.08)',
      text: '#991533',
    },
  },

  social: {
    disabled: '#DFDFDF',
  },

  icon: {
    profile: '#757575',
    note: {
      private: '#BABABA',
      favorite: '#FFA726',
    },
  },
}

export default colors
