import React, { useCallback, useRef, useState } from 'react'

import { Instance, Placement } from 'tippy.js'

import {
  ChevronIcon,
  Container,
  Label,
  MenuItem,
  StyledDropdown,
  StyledMenu,
  StyledTippy,
  ValueContainer,
} from './styles'

interface SelectableDropdownOption<TValue> {
  label: string
  value: TValue
}

interface SelectableDropdownProps<TValue> {
  disabled?: boolean
  markSelected?: boolean
  menuPlacement?: Placement
  options?: SelectableDropdownOption<TValue>[]
  placeholder?: string
  value?: TValue
  renderOption?: (option: SelectableDropdownOption<TValue>) => React.ReactNode
  onChange?: (option: SelectableDropdownOption<TValue>) => void
}

function SelectableDropdown<TValue extends React.Key>({
  disabled = false,
  markSelected = true,
  menuPlacement,
  options = [],
  placeholder,
  value,
  renderOption,
  onChange,
  ...rest
}: SelectableDropdownProps<TValue>) {
  const tippyRef = useRef<Instance | null>(null)

  const [selectedLabel, setSelectedLabel] = useState<string | undefined>()
  const [open, setOpen] = useState(false)

  const handleItemClick = useCallback(
    (option: SelectableDropdownOption<TValue>) => {
      onChange?.(option)
      tippyRef.current?.hide()
      setSelectedLabel(option.label)
    },
    [onChange],
  )

  return (
    <Container {...rest}>
      <StyledTippy
        content={
          <StyledMenu>
            {options?.map(option => (
              <MenuItem
                key={option.value}
                selected={markSelected && option.value === value}
                onClick={() => handleItemClick(option)}
              >
                {renderOption ? renderOption(option) : option.label}
              </MenuItem>
            ))}
          </StyledMenu>
        }
        disabled={disabled}
        duration={0}
        interactive
        maxWidth="100%"
        placement={menuPlacement || 'bottom'}
        trigger="click"
        onCreate={instance => {
          tippyRef.current = instance
        }}
        onHide={() => setOpen(false)}
        onShow={() => setOpen(true)}
      >
        <StyledDropdown
          active={!!selectedLabel}
          disabled={disabled}
          tabIndex={!disabled ? -1 : undefined}
        >
          <ValueContainer gap={2}>
            <Label>{selectedLabel || placeholder}</Label>
            <ChevronIcon open={open} size={20} />
          </ValueContainer>
        </StyledDropdown>
      </StyledTippy>
    </Container>
  )
}

export default SelectableDropdown
