import React from 'react'
import { Field } from 'react-final-form'

import { UploadType } from 'Constants/ids'

import AvatarInput from './AvatarInput'

export interface IAvatarInputField {
  defaultValue?: string
  entityId?: string
  entityText?: string
  name: string
  uploadType?: UploadType
}

function AvatarInputField({
  defaultValue,
  entityId,
  entityText,
  name,
  uploadType,
}: IAvatarInputField) {
  return (
    <Field defaultValue={defaultValue} name={name}>
      {({ input }) => (
        <AvatarInput
          {...input}
          entityId={entityId}
          entityText={entityText}
          uploadType={uploadType}
        />
      )}
    </Field>
  )
}

export default AvatarInputField
