import React, { useMemo } from 'react'

import { IconSquareRounded } from '@tabler/icons-react'

import {
  Button,
  Column,
  IconButton,
  Row,
  Text,
  TextButton,
} from 'Components/UI'

const TEXT_EXAMPLE = 'Button'

function Buttons() {
  const buttonContent = useMemo(
    () => (
      <>
        <IconSquareRounded />
        {TEXT_EXAMPLE}
        <IconSquareRounded />
      </>
    ),
    [],
  )

  return (
    <Column gap={6}>
      <Text header1>Buttons</Text>
      <Row gap={5}>
        <Column fullWidth gap={5}>
          <Text header2 primary80>
            Basic - Primary
          </Text>
          <Row gap={4}>
            <Column gap={3}>
              <Button large>{buttonContent}</Button>
              <Button>{buttonContent}</Button>
              <Button small>{buttonContent}</Button>
            </Column>
            <Column gap={3}>
              <Button disabled large>
                {buttonContent}
              </Button>
              <Button disabled>{buttonContent}</Button>
              <Button disabled small>
                {buttonContent}
              </Button>
            </Column>
          </Row>

          <Text header2 primary80>
            Basic - Secondary
          </Text>
          <Row gap={4}>
            <Column gap={3}>
              <Button large secondary>
                {buttonContent}
              </Button>
              <Button secondary>{buttonContent}</Button>
              <Button secondary small>
                {buttonContent}
              </Button>
            </Column>
            <Column gap={3}>
              <Button disabled large secondary>
                {buttonContent}
              </Button>
              <Button disabled secondary>
                {buttonContent}
              </Button>
              <Button disabled secondary small>
                {buttonContent}
              </Button>
            </Column>
          </Row>

          <Text header2 primary80>
            Basic - Danger
          </Text>
          <Row gap={4}>
            <Column gap={3}>
              <Button danger large>
                {buttonContent}
              </Button>
              <Button danger>{buttonContent}</Button>
              <Button danger small>
                {buttonContent}
              </Button>
            </Column>
            <Column gap={3}>
              <Button danger disabled large>
                {buttonContent}
              </Button>
              <Button danger disabled>
                {buttonContent}
              </Button>
              <Button danger disabled small>
                {buttonContent}
              </Button>
            </Column>
          </Row>
        </Column>

        <Column fullWidth gap={5}>
          <Text header2 primary80>
            TextButton - Primary
          </Text>
          <Row gap={4}>
            <Column gap={3}>
              <TextButton large>{buttonContent}</TextButton>
              <TextButton>{buttonContent}</TextButton>
              <TextButton small>{buttonContent}</TextButton>
            </Column>
            <Column gap={3}>
              <TextButton disabled large>
                {buttonContent}
              </TextButton>
              <TextButton disabled>{buttonContent}</TextButton>
              <TextButton disabled small>
                {buttonContent}
              </TextButton>
            </Column>
          </Row>

          <Text header2 primary80>
            TextButton - Secondary
          </Text>
          <Row gap={4}>
            <Column gap={3}>
              <TextButton large secondary>
                {buttonContent}
              </TextButton>
              <TextButton secondary>{buttonContent}</TextButton>
              <TextButton secondary small>
                {buttonContent}
              </TextButton>
            </Column>
            <Column gap={3}>
              <TextButton disabled large secondary>
                {buttonContent}
              </TextButton>
              <TextButton disabled secondary>
                {buttonContent}
              </TextButton>
              <TextButton disabled secondary small>
                {buttonContent}
              </TextButton>
            </Column>
          </Row>

          <Text header2 primary80>
            TextButton - Danger
          </Text>
          <Row gap={4}>
            <Column gap={3}>
              <TextButton danger large>
                {buttonContent}
              </TextButton>
              <TextButton danger>{buttonContent}</TextButton>
              <TextButton danger small>
                {buttonContent}
              </TextButton>
            </Column>
            <Column gap={3}>
              <TextButton danger disabled large>
                {buttonContent}
              </TextButton>
              <TextButton danger disabled>
                {buttonContent}
              </TextButton>
              <TextButton danger disabled small>
                {buttonContent}
              </TextButton>
            </Column>
          </Row>
        </Column>

        <Column fullWidth gap={5}>
          <Text header2 primary80>
            IconButton - Primary
          </Text>
          <Row gap={4}>
            <Column gap={3}>
              <IconButton large>
                <IconSquareRounded />
              </IconButton>
              <IconButton>
                <IconSquareRounded />
              </IconButton>
              <IconButton small>
                <IconSquareRounded />
              </IconButton>
            </Column>
            <Column gap={3}>
              <IconButton disabled large>
                <IconSquareRounded />
              </IconButton>
              <IconButton disabled>
                <IconSquareRounded />
              </IconButton>
              <IconButton disabled small>
                <IconSquareRounded />
              </IconButton>
            </Column>
          </Row>

          <Text header2 primary80>
            IconButton - Secondary
          </Text>
          <Row gap={4}>
            <Column gap={3}>
              <IconButton large secondary>
                <IconSquareRounded />
              </IconButton>
              <IconButton secondary>
                <IconSquareRounded />
              </IconButton>
              <IconButton secondary small>
                <IconSquareRounded />
              </IconButton>
            </Column>
            <Column gap={3}>
              <IconButton disabled large secondary>
                <IconSquareRounded />
              </IconButton>
              <IconButton disabled secondary>
                <IconSquareRounded />
              </IconButton>
              <IconButton disabled secondary small>
                <IconSquareRounded />
              </IconButton>
            </Column>
          </Row>

          <Text header2 primary80>
            IconButton - Outline
          </Text>
          <Row gap={4}>
            <Column gap={3}>
              <IconButton large outline>
                <IconSquareRounded />
              </IconButton>
              <IconButton outline>
                <IconSquareRounded />
              </IconButton>
              <IconButton outline small>
                <IconSquareRounded />
              </IconButton>
            </Column>
            <Column gap={3}>
              <IconButton disabled large outline>
                <IconSquareRounded />
              </IconButton>
              <IconButton disabled outline>
                <IconSquareRounded />
              </IconButton>
              <IconButton disabled outline small>
                <IconSquareRounded />
              </IconButton>
            </Column>
          </Row>

          <Text header2 primary80>
            IconButton - Danger
          </Text>
          <Row gap={4}>
            <Column gap={3}>
              <IconButton danger large>
                <IconSquareRounded />
              </IconButton>
              <IconButton danger>
                <IconSquareRounded />
              </IconButton>
              <IconButton danger small>
                <IconSquareRounded />
              </IconButton>
            </Column>
            <Column gap={3}>
              <IconButton danger disabled large>
                <IconSquareRounded />
              </IconButton>
              <IconButton danger disabled>
                <IconSquareRounded />
              </IconButton>
              <IconButton danger disabled small>
                <IconSquareRounded />
              </IconButton>
            </Column>
          </Row>
        </Column>
      </Row>
    </Column>
  )
}

export default Buttons
