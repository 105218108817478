import React from 'react'
import PropTypes from 'prop-types'

import TagsField from 'Components/Blocks/Admin/Forms/Fields/TagsField'
import { Column, InputField, Row } from 'Components/UI'

import { SEARCH_TYPES } from 'Constants/ids'

import _ from 'Services/I18n'

import { FIELD } from '../fields'

function TagsTab({ communityId }) {
  return (
    <Column fullWidth gap={4}>
      <Row fullWidth gap={4}>
        <Column fullWidth gap={4}>
          <TagsField
            communityId={communityId}
            label="Skills"
            name={FIELD.SKILLS}
            type={SEARCH_TYPES.skill}
          />

          <TagsField
            communityId={communityId}
            label="Event"
            name={FIELD.EVENT}
            type={SEARCH_TYPES.event}
          />

          <TagsField
            communityId={communityId}
            label="Role"
            name={FIELD.ROLE}
            type={SEARCH_TYPES.role}
          />

          <TagsField
            communityId={communityId}
            label="Group"
            name={FIELD.GROUP}
            type={SEARCH_TYPES.group}
          />
        </Column>

        <Column fullWidth gap={4}>
          <InputField
            label={_('auth.shared.hobbies&Interests')}
            name={FIELD.INTERESTS_HOBBIES}
            width={1}
          />

          <TagsField
            communityId={communityId}
            label="Project"
            name={FIELD.PROJECT}
            type={SEARCH_TYPES.project}
          />

          <TagsField
            communityId={communityId}
            label="Custom"
            name={FIELD.CUSTOM}
            type={SEARCH_TYPES.custom}
          />
        </Column>
      </Row>

      <InputField
        label="About"
        name={FIELD.ABOUT}
        placeholder="Write about user"
        small
        textArea
      />
    </Column>
  )
}

TagsTab.propTypes = {
  communityId: PropTypes.string.isRequired,
}
export default TagsTab
