export function fileToDataURL(
  file: Blob,
  callback: (result: string | null) => void,
) {
  const reader = new FileReader()
  reader.onloadend = function onLoadEnd(event) {
    callback(event.target?.result ? (event.target.result as string) : null)
  }
  reader.readAsDataURL(file)
}
