const datePickers = {
  containerBorderRadius: { default: 4 },

  cellSize: { default: 40 },

  cellBorderRadius: { default: 4 },

  navIconSize: { default: 10 },
}

export default datePickers
