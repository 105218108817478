import React from 'react'

import { FaFacebook } from 'react-icons/fa'

export interface IFacebook {
  color?: string
  size?: number
}

const Facebook = ({ color = '#1877F2', size }: IFacebook) => {
  return <FaFacebook color={color} display="block" size={size} />
}

export default Facebook
