import styled from 'styled-components'

import { customScrollbar } from 'Theme/styles'

export const Container = styled.div`
  width: 100%;
  height: 267px;
  overflow: auto;

  ${customScrollbar}
`
