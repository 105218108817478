import React from 'react'

import { useTheme } from 'styled-components'

import {
  Area,
  AreaChart,
  CartesianGrid,
  Curve,
  Customized,
  DotProps,
  Polygon,
  Rectangle,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  TooltipProps,
  XAxis,
  YAxis,
} from 'recharts'

import last from 'lodash/last'

import { Text } from 'Components/UI'

import { ChartContainer, TooltipWrapper } from './styles'

export interface ITimeChartProps {
  chartColors: Record<string, string>
  // TODO: add actual type
  chartData: any[]
  chartSetup: {
    xDataKey: string
    refX?: string
    domain: number[]
  } | null
}

interface ICustomizedProps {
  orderedTooltipTicks: Array<{ coordinate: number }>
  offset: { top: number; height: number; width: number; left: number }
}

function TimeChart({ chartData, chartColors, chartSetup }: ITimeChartProps) {
  let dotCoordinates: { x: number; y: number } = { x: 0, y: 0 }

  const { colors } = useTheme()

  const renderActiveDot = (data: DotProps) => {
    dotCoordinates = { x: data.cx ?? 0, y: data.cy ?? 0 }

    return (
      <>
        <circle
          cx={dotCoordinates.x}
          cy={dotCoordinates.y}
          fill={chartColors.red}
          r={7}
          strokeWidth={3}
        />
        <circle
          cx={dotCoordinates.x}
          cy={dotCoordinates.y}
          fill="white"
          r={4}
          strokeWidth={3}
        />
      </>
    )
  }

  // TODO: Refactor
  // eslint-disable-next-line react/no-unstable-nested-components
  const CustomCursor = (data?: { height?: number }) => (
    <Curve
      points={[
        { x: dotCoordinates.x, y: dotCoordinates.y + 8 },
        { x: dotCoordinates.x, y: dotCoordinates.y + (data?.height ?? 0) },
      ]}
      stroke={chartColors.red}
      strokeDasharray={5}
      type="linear"
    />
  )

  const renderCustomTooltip = (data: TooltipProps<string, string>) => {
    const { active, payload } = data
    if (active && payload && payload.length) {
      return (
        <TooltipWrapper>
          <Text bolder fontSize={1}>{`${payload[0].value}`}</Text>
        </TooltipWrapper>
      )
    }

    return null
  }

  const renderCustomized = (data: ICustomizedProps) => {
    const { offset, orderedTooltipTicks } = data
    const x = last(orderedTooltipTicks)?.coordinate ?? 0
    const cornerRadius = 8

    const tickWidth = 3
    const tickHeight = 3

    return (
      <>
        <Polygon
          fill={chartColors.blue}
          points={[
            { x: x - tickWidth, y: offset.top },
            { x: x + tickWidth, y: offset.top },
            { x, y: offset.top + tickHeight },
          ]}
          stroke={chartColors.blue}
        />
        <Polygon
          fill={chartColors.blue}
          points={[
            { x: x - tickWidth, y: offset.top + offset.height },
            { x: x + tickWidth, y: offset.top + offset.height },
            { x, y: offset.top + offset.height - tickHeight },
          ]}
          stroke={chartColors.blue}
        />
        <Rectangle
          fill="none"
          height={cornerRadius}
          stroke={colors.white}
          width={cornerRadius}
          x={offset.left}
          y={offset.top}
        />
        <Rectangle
          fill="none"
          height={cornerRadius}
          stroke={colors.white}
          width={cornerRadius}
          x={offset.left + offset.width - cornerRadius}
          y={offset.top}
        />
        <Rectangle
          fill="none"
          height={cornerRadius}
          stroke={colors.white}
          width={cornerRadius}
          x={offset.left + offset.width - cornerRadius}
          y={offset.top + offset.height - cornerRadius}
        />
        <Rectangle
          fill="none"
          height={cornerRadius}
          stroke={colors.white}
          width={cornerRadius}
          x={offset.left}
          y={offset.top + offset.height - cornerRadius}
        />
        <Rectangle
          fill="none"
          height={offset.height}
          radius={cornerRadius}
          stroke={chartColors.grid}
          strokeWidth={1}
          width={offset.width}
          x={offset.left}
          y={offset.top}
        />
      </>
    )
  }

  return (
    <ChartContainer>
      <ResponsiveContainer height={300} width="99%">
        <AreaChart
          data={chartData}
          margin={{ top: 8, right: 0, bottom: 0, left: 0 }}
          style={{
            fontSize: '12px',
          }}
        >
          <defs>
            <linearGradient id="colorUv" x1="0" x2="0" y1="0" y2="1">
              <stop offset="5%" stopColor={chartColors.red} stopOpacity={0.2} />
              <stop offset="95%" stopColor={chartColors.red} stopOpacity={0} />
            </linearGradient>
          </defs>

          <CartesianGrid
            stroke={chartColors.grid}
            strokeDasharray="5 5"
            vertical={false}
          />

          <YAxis
            axisLine={false}
            padding={{ top: 5, bottom: 5 }}
            tickLine={false}
            width={30}
          />

          <XAxis axisLine={false} dataKey="date" tickLine={false} />

          <Area
            activeDot={renderActiveDot}
            animationDuration={500}
            dataKey="count"
            dot={false}
            fill="url(#colorUv)"
            stroke={chartColors.red}
            strokeWidth={3}
            type="monotone"
          />

          <ReferenceLine stroke={chartColors.blue} x={chartSetup?.refX} />

          <Customized
            component={data => renderCustomized(data as ICustomizedProps)}
            key="customized"
          />

          <Tooltip
            animationDuration={0}
            content={renderCustomTooltip}
            cursor={<CustomCursor />}
            offset={3}
          />
        </AreaChart>
      </ResponsiveContainer>
    </ChartContainer>
  )
}

export default TimeChart
