import React, { useCallback, useState } from 'react'

import { useMutation } from '@apollo/client'
import { IconCheck } from '@tabler/icons-react'
import acceptAllProposalsMutation from 'GraphQL/Mutations/Proposal/acceptAllProposals.graphql'

import { LinkButton, Modal } from 'Components/UI'

import { useAppContext, useCommunityContext } from 'Hooks'

import _, { useScopedI18n } from 'Services/I18n'
import toast from 'Services/Toast'

import { useTableContext } from '../context'

function AcceptAll() {
  const s = useScopedI18n('blocks.tables.proposalsTable')
  const { community } = useCommunityContext()
  const { onRefetch, entitiesCount } = useTableContext()
  const { refetchMe } = useAppContext()

  const [isShowDialog, setShowDialog] = useState(false)

  const [acceptAllProposal] = useMutation(acceptAllProposalsMutation)

  const handleAcceptAllProposals = useCallback(
    async success => {
      if (!success) {
        return
      }

      try {
        await acceptAllProposal({
          variables: {
            communityId: community?.id,
          },
        })

        refetchMe()
        onRefetch()

        toast.success({
          title: s('messages.title'),
          text: s('messages.accepted'),
        })
      } catch (error) {
        toast.error({
          title: s('messages.title'),
          text: _(`error.${error?.message || 'generic'}`),
        })
      }
    },
    [acceptAllProposal, community, onRefetch, refetchMe, s],
  )

  if (entitiesCount === 0) return null

  return (
    <>
      <LinkButton success onClick={() => setShowDialog(true)}>
        <IconCheck />
        {s('approveAll')}
      </LinkButton>

      <Modal
        cancelText={_('general.cancel')}
        confirmText={s('approveAll')}
        isOpen={isShowDialog}
        title={s('dialog.approve.title')}
        onClose={() => setShowDialog(false)}
        onConfirm={handleAcceptAllProposals}
      >
        {s('dialog.approve.content')}
      </Modal>
    </>
  )
}

export default AcceptAll
