import styled from 'styled-components'
import { padding, PaddingProps } from 'styled-system'
import { themeGet } from '@styled-system/theme-get'

export interface IContainerProps extends PaddingProps {}

export const Container = styled.div.attrs({
  px: [4, 4, 5],
  py: [4, 4, 3],
})<IContainerProps>`
  width: 100%;
  border-top: 1px solid ${themeGet('colors.divider.default')};

  ${padding}
`
