import React, { useMemo, useState } from 'react'

import { useTheme } from 'styled-components'

import userConnectionsOverTimeQuery from 'GraphQL/Queries/User/userConnectionsOverTime.graphql'
import { DateTime } from 'luxon'

import capitalize from 'lodash/capitalize'
import last from 'lodash/last'
import map from 'lodash/map'

import TimeChart from 'Components/Blocks/TimeChart'
import { Column, Divider, Loader, Row, Select, Text } from 'Components/UI'

import { Timeframe } from 'Constants/mainGraphQL'

import { useQuery } from 'Services/Apollo'

export interface INumberOfConnectionsSelectOption {
  value: Timeframe
  label: string
}

const SELECT_OPTIONS: INumberOfConnectionsSelectOption[] = [
  {
    value: Timeframe.Day,
    label: capitalize(Timeframe.Day),
  },
  {
    value: Timeframe.Week,
    label: capitalize(Timeframe.Week),
  },
  {
    value: Timeframe.Month,
    label: capitalize(Timeframe.Month),
  },
  {
    value: Timeframe.Quarter,
    label: capitalize(Timeframe.Quarter),
  },
  {
    value: Timeframe.Year,
    label: capitalize(Timeframe.Year),
  },
]

export interface INumberOfConnectionsChartData {
  date: string
  time?: string
  count: number
}

const X_DATA_KEYS = { TIME: 'time', DATE: 'date' }

export interface INumberOfConnectionsProps {
  communityId: string
  userId: string
}

function NumberOfConnections({
  userId,
  communityId,
}: INumberOfConnectionsProps) {
  const defaultSelectedOption = SELECT_OPTIONS[0]
  const [selectedOption, setSelectedOption] =
    useState<INumberOfConnectionsSelectOption>(defaultSelectedOption)

  const { colors } = useTheme()

  const chartColors = {
    red: colors.primaryCardinal,
    blue: colors.chartBlue,
    dark: colors.mineShaft,
    lightBlue: colors.chartLightBlue,
    lightRed: colors.chartLightRed,
    orange: colors.chartOrange,
    yellow: colors.chartYellow,
    grid: colors.grayWithWhite,
  }

  const { data: userConnectionsData, loading } = useQuery<
    Pick<MainSchema.Query, 'userConnectionsOverTime'>,
    MainSchema.QueryUserConnectionsOverTimeArgs
  >(userConnectionsOverTimeQuery, {
    skip: !userId,
    variables: { communityId, userId, period: selectedOption.value },
  })

  const chartData = useMemo<INumberOfConnectionsChartData[]>(
    () =>
      map(userConnectionsData?.userConnectionsOverTime, item => {
        if (selectedOption.value === Timeframe.Day) {
          return {
            date: DateTime.fromISO(item.date).toLocaleString(
              DateTime.DATE_SHORT,
            ),
            time: DateTime.fromISO(item.date).toLocaleString(
              DateTime.TIME_24_SIMPLE,
            ),
            count: item.connections ?? 0,
          }
        }
        return {
          date: DateTime.fromISO(item.date).toLocaleString(DateTime.DATE_SHORT),
          count: item.connections ?? 0,
        }
      }),
    [selectedOption, userConnectionsData],
  )

  const chartSetup = useMemo(() => {
    const max = last(chartData)
    const dataBorders = {
      min: chartData?.[0]?.count ?? 0,
      max: max?.count ?? 0,
    }

    const chartBorders = [
      Math.round((dataBorders.min * 0.5) / 10) * 10,
      Math.round((dataBorders.max * 1.5) / 10) * 10,
    ]

    switch (selectedOption.value) {
      case Timeframe.Day: {
        return {
          xDataKey: X_DATA_KEYS.TIME,
          refX: max?.time,
          domain: chartBorders,
        }
      }
      case Timeframe.Week:
      case Timeframe.Month:
      case Timeframe.Quarter:
      case Timeframe.Year:
        return {
          xDataKey: X_DATA_KEYS.DATE,
          refX: max?.date,
          domain: chartBorders,
        }
      default:
        return null
    }
  }, [selectedOption, chartData])

  const renderContent = () => {
    if (loading) {
      return (
        <Row center fullWidth height="200px" justifyCenter>
          <Loader />
        </Row>
      )
    }

    if (chartData.length === 0) {
      return (
        <Row center justifyCenter>
          <Text body bodyMedium>
            No data
          </Text>
        </Row>
      )
    }

    return (
      <TimeChart
        chartColors={chartColors}
        chartData={chartData}
        chartSetup={chartSetup}
      />
    )
  }

  return (
    <Column py={4}>
      <Row center px={5} spaceBetween>
        <Text header header3>
          Number of connections
        </Text>

        <Select<INumberOfConnectionsSelectOption>
          options={SELECT_OPTIONS}
          value={selectedOption}
          width="150px"
          onChange={value => setSelectedOption(value ?? defaultSelectedOption)}
        />
      </Row>

      <Divider my={4} />

      {renderContent()}
    </Column>
  )
}

export default NumberOfConnections
