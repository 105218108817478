import styled from 'styled-components'
import { themeGet } from '@styled-system/theme-get'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${themeGet('colors.bg.primary')};
  border-radius: 4px;
  box-shadow:
    0px 4px 6px -2px rgba(0, 0, 0, 0.05),
    0px 10px 15px -3px rgba(0, 0, 0, 0.1),
    0px 0px 0px 1px rgba(0, 0, 0, 0.05);
  overflow: hidden;
  min-width: 156px;
`

export const TriggerContainer = styled.div`
  position: relative;
`
