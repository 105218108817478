import React, { useCallback, useMemo, useState } from 'react'
import { Form } from 'react-final-form'
import PropTypes from 'prop-types'

import { useLazyQuery, useMutation } from '@apollo/client'
import debounce from 'awesome-debounce-promise'
import connectUsersToTagsMutation from 'GraphQL/Mutations/Community/connectUsersToTags.graphql'
import createTagsMutation from 'GraphQL/Mutations/Tag/createTags.graphql'
import listCommunityUsersQuery from 'GraphQL/Queries/CommunityUser/listCommunityUsers.full.graphql'
import listTagsQuery from 'GraphQL/Queries/listTags.graphql'
import { setMinSearchLength } from 'Utils/Form'
import { communityUsersToOptions, entitiesToOptions } from 'Utils/Options'
import validate from 'validate.js'

import forEach from 'lodash/forEach'
import map from 'lodash/map'
import noop from 'lodash/noop'
import upperFirst from 'lodash/upperFirst'

import { Column, Modal, Select, SelectField } from 'Components/UI'

import {
  DEFAULT_MIN_SEARCH_SIZE,
  DEFAULT_SEARCH_DEBOUNCE,
  TAG_KIND,
} from 'Constants/ids'
import { PERMISSION_ACTION, PERMISSION_SUBJECT } from 'Constants/permissions'

import { useCommunityContext, useModal, usePermission } from 'Hooks'

import _, { useScopedI18n } from 'Services/I18n'
import toast from 'Services/Toast'

const FIELD = {
  TAGS: 'tags',
  COMMUNITY_USERS: 'communityUserIds',
}

const initialValues = {
  [FIELD.TAGS]: [],
  [FIELD.COMMUNITY_USERS]: [],
}

function CreateTag({ isOpen, onClose, onRefetch, ...rest }) {
  const { can } = usePermission()
  const s = useScopedI18n('modals.createTag')
  const [modalProps] = useModal(rest)
  const { community } = useCommunityContext()

  const canCreateIndustryTags = can(
    PERMISSION_ACTION.CREATE,
    PERMISSION_SUBJECT.MANAGEMENT_INDUSTRIES_TAG,
    [],
  )

  const tagOptions = useMemo(() => {
    const options = [
      { value: TAG_KIND.PROJECT, label: upperFirst(TAG_KIND.PROJECT) },
      { value: TAG_KIND.EVENT, label: upperFirst(TAG_KIND.EVENT) },
      { value: TAG_KIND.ROLE, label: upperFirst(TAG_KIND.ROLE) },
      { value: TAG_KIND.GROUP, label: upperFirst(TAG_KIND.GROUP) },
      { value: TAG_KIND.CUSTOM, label: 'Custom tags' },
    ]

    if (canCreateIndustryTags)
      options.push({
        value: TAG_KIND.INDUSTRY,
        label: upperFirst(TAG_KIND.INDUSTRY),
      })

    return options
  }, [canCreateIndustryTags])

  const [selectedKind, setSelectedKind] = useState(tagOptions[0])

  const [loadTags] = useLazyQuery(listTagsQuery)
  const [loadUsers] = useLazyQuery(listCommunityUsersQuery)
  const [connectUsersToTags] = useMutation(connectUsersToTagsMutation)
  const [createTags] = useMutation(createTagsMutation)

  const constraints = useMemo(() => {
    const result = {
      [FIELD.TAGS]: {
        presence: { allowEmpty: false, message: `^Field is required` },
      },
      [FIELD.COMMUNITY_USERS]: {
        presence: { allowEmpty: false, message: `^Field is required` },
      },
    }

    if (selectedKind.value === TAG_KIND.INDUSTRY)
      delete result[FIELD.COMMUNITY_USERS]

    return result
  }, [selectedKind])

  const handleSelectTagKind = useCallback((option, handlerField) => {
    setSelectedKind(option)
    handlerField(FIELD.TAGS, undefined)
  }, [])

  const onSubmit = useCallback(
    async values => {
      const tagsIds = []
      const tags = []
      forEach(values?.[FIELD.TAGS], option =>
        option.id
          ? tagsIds.push(option.value)
          : tags.push({
              name: option.value.trim(),
              communityId: community?.id,
              kind: selectedKind.value,
            }),
      )
      const communityUserIds = map(values[FIELD.COMMUNITY_USERS], 'value')

      try {
        let createdTagIds = []

        if (tags.length > 0) {
          const response = await createTags({
            variables: {
              tags,
            },
          })

          const entities = response?.data?.createTags

          if (entities?.length > 0) {
            createdTagIds = map(entities, tag => tag.id)
          }
        }

        const tagIds = [...tagsIds, ...createdTagIds]

        const usersToTags = []
        tagIds.forEach(tagId => {
          communityUserIds.forEach(communityUserId => {
            usersToTags.push({
              tagId,
              communityUserId,
            })
          })
        })

        await connectUsersToTags({
          variables: {
            communityId: community?.id,
            usersToTags,
          },
          // TODO: How can we update the cache here?
        })

        await onRefetch()

        toast.success({
          title: s('messages.connectTitle'),
          text: s('messages.connectSuccess'),
        })

        onClose()
      } catch (error) {
        toast.error({
          title: s('messages.connectTitle'),
          text: _(`error.${error?.message || 'generic'}`),
        })
      }
    },
    [
      community,
      connectUsersToTags,
      createTags,
      onClose,
      onRefetch,
      s,
      selectedKind,
    ],
  )

  const loadUsersOptions = useCallback(
    excludeValues => async (inputValue, callback) => {
      try {
        const result = await loadUsers({
          variables: {
            search: inputValue,
            excludeUserIds: [...map(excludeValues, 'value')],
            communityIds: [community?.id],
            limit: 25,
          },
        })

        const users = result.data?.listCommunityUsers?.communityUsers || []

        callback(communityUsersToOptions(users))
      } catch (error) {
        toast.error({
          title: s('messages.loadUsersTitle'),
          text: _(`error.${error?.message || 'generic'}`),
        })
      }
    },
    [loadUsers, community, s],
  )

  const loadTagsOptions = useCallback(
    () => async (inputValue, callback) => {
      try {
        const result = await loadTags({
          variables: {
            communityId: community?.id,
            kind: selectedKind.value,
            search: inputValue,
            limit: 25,
          },
          fetchPolicy: 'network-only',
        })

        const tags = result?.data?.listTags?.rows || []

        callback(entitiesToOptions(tags))
      } catch (error) {
        toast.error({
          title: s('messages.loadTagsTitle'),
          text: _(`error.${error?.message || 'generic'}`),
        })
      }
    },
    [loadTags, community, selectedKind.value, s],
  )

  const debouncedLoadTagsOptions = useCallback(
    () =>
      setMinSearchLength(
        debounce(loadTagsOptions(), DEFAULT_SEARCH_DEBOUNCE),
        DEFAULT_MIN_SEARCH_SIZE,
      ),
    [loadTagsOptions],
  )

  const debouncedLoadUserOptions = useCallback(
    excludedValues =>
      setMinSearchLength(
        debounce(loadUsersOptions(excludedValues), DEFAULT_SEARCH_DEBOUNCE),
        DEFAULT_MIN_SEARCH_SIZE,
      ),
    [loadUsersOptions],
  )

  const renderForm = useCallback(
    ({ handleSubmit, form, values }) => (
      <Modal
        {...modalProps}
        cancelText={_('general.cancel')}
        confirmText={_('general.save')}
        isOpen
        title={s('title')}
        onClose={onClose}
        onConfirm={handleSubmit}
      >
        <Column gap={4} width={['100%', '100%', '600px']}>
          <SelectField
            async
            creatable
            isMulti
            label={s('form.nameLabel')}
            loadOptions={debouncedLoadTagsOptions()}
            name={FIELD.TAGS}
            placeholder={s('form.selectPlaceholder')}
            required
            withPortal
          />

          <Select
            label={s('form.typeLabel')}
            options={tagOptions}
            value={selectedKind}
            withPortal
            onChange={option => handleSelectTagKind(option, form.change)}
          />

          <SelectField
            async
            isMulti
            label={s('form.usersLabel')}
            loadOptions={debouncedLoadUserOptions(values.users)}
            name={FIELD.COMMUNITY_USERS}
            placeholder={s('form.selectPlaceholder')}
            required={selectedKind.value !== TAG_KIND.INDUSTRY}
            withPortal
          />
        </Column>
      </Modal>
    ),
    [
      debouncedLoadTagsOptions,
      debouncedLoadUserOptions,
      handleSelectTagKind,
      modalProps,
      onClose,
      s,
      selectedKind,
      tagOptions,
    ],
  )

  if (!isOpen) return null

  return (
    <Form
      initialValues={initialValues}
      render={renderForm}
      validate={values => validate(values, constraints)}
      onSubmit={onSubmit}
    />
  )
}

CreateTag.defaultProps = {
  isOpen: false,
  onClose: noop,
  onRefetch: noop,
}

CreateTag.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  onRefetch: PropTypes.func,
}

export default CreateTag
