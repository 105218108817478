import styled, { css } from 'styled-components'
import { space, SpaceProps } from 'styled-system'
import { themeGet } from '@styled-system/theme-get'

import { IconChevronDown } from '@tabler/icons-react'
import Tippy from '@tippyjs/react'

import { gap, GapProps } from 'Theme/system'

export interface IChevronIconOpenCssProps {
  open?: boolean
}

const chevronIconOpenCss = ({ open }: IChevronIconOpenCssProps) =>
  open &&
  css`
    transform: rotate(180deg);
  `

export interface IChevronIconProps extends IChevronIconOpenCssProps {}

export const ChevronIcon = styled(IconChevronDown)<IChevronIconProps>`
  stroke-width: 1;
  transition: all 0.3s ease;
  ${chevronIconOpenCss}
`

export interface IDropdownActiveCssProps {
  active?: boolean
}

const dropdownActiveCss = ({ active }: IDropdownActiveCssProps) =>
  active &&
  css`
    &:focus-within {
      outline: 0;
    }
  `

export interface IDropdownDisabledCssProps {
  disabled?: boolean
}

const dropdownDisabledCss = ({ disabled }: IDropdownDisabledCssProps) =>
  disabled &&
  css`
    background-color: gray;
  `

export interface IStyledDropdownProps
  extends IDropdownDisabledCssProps,
    IDropdownActiveCssProps,
    SpaceProps {}

export const StyledDropdown = styled.div<IStyledDropdownProps>`
  position: relative;
  border-radius: 6px;
  width: 100%;
  cursor: pointer;

  ${dropdownDisabledCss}
  ${dropdownActiveCss}
  ${space};
`

export interface IValueContainerProps extends GapProps {}

export const ValueContainer = styled.div<IValueContainerProps>`
  display: flex;
  align-items: center;
  justify-content: space-between;

  ${gap};
`

export const Container = styled.div`
  position: relative;
`

export const StyledTippy = styled(Tippy)`
  width: 100% !important;

  & .tippy-content {
    padding: 0;
  }

  & .tippy-arrow {
    z-index: 1;
  }
`

export const StyledMenu = styled.ul`
  padding: 0;
  border: 1px solid #dde1e4;
  border-radius: 4px;
  background-color: white;
  width: 100%;
  list-style-type: none;
  margin: 0;
  -webkit-overflow-scrolling: touch;
  overflow-y: auto;
  max-height: 300px;
`

export interface ISelectedStyleProps {
  selected?: boolean
}

const selectedStyle = ({ selected }: ISelectedStyleProps) =>
  selected &&
  css`
    background-color: #f2f4f5;
  `

export interface IMenuItemProps extends ISelectedStyleProps {}

export const MenuItem = styled.li<IMenuItemProps>`
  color: ${themeGet('colors.mineShaft')};
  white-space: nowrap;
  cursor: pointer;
  padding: ${themeGet('space.2')}px ${themeGet('space.3')}px;
  &:hover {
    background-color: #f2f4f5;
  }
  ${selectedStyle}
`

export const Label = styled.span`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: ${themeGet('colors.mineShaft')};
`
