import React from 'react'
import { components } from 'react-select'

import { InputWrapper } from './styles'

const Input: typeof components.Input = props => {
  // TODO: find a better way to satisfy the type
  const StyledInputWrapper = InputWrapper as any
  return <StyledInputWrapper {...props} as={components.Input} />
}

export default Input
