import React, { ReactNode, useMemo } from 'react'

import { ThemeProvider as BaseThemeProvider } from 'styled-components'

import themes from './themes'

export interface ThemeProviderProps {
  children: ReactNode
}

function ThemeProvider(props: ThemeProviderProps) {
  const theme = useMemo(() => {
    return themes.main
  }, [])

  // @ts-ignore
  return <BaseThemeProvider theme={theme}>{props.children}</BaseThemeProvider>
}

export default ThemeProvider
