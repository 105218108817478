import { NavLink } from 'react-router-dom'

import styled, { css } from 'styled-components'
import { mapToTheme } from 'styled-map'
import { margin, MarginProps } from 'styled-system'
import { themeGet } from '@styled-system/theme-get'

const styledItem = css`
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  user-select: none;
  white-space: nowrap;
  height: ${mapToTheme('menuItems.height')}px;
  border-radius: ${mapToTheme('menuItems.borderRadius')}px;
  padding-inline: ${mapToTheme('menuItems.padding')}px;
`

export interface IBase extends MarginProps {}

export interface IStyledActiveBox extends IBase {}

export const StyledActiveBox = styled.div<IStyledActiveBox>`
  cursor: default;
  background-color: ${themeGet('colors.menuItem.active.bg')};

  ${styledItem}

  ${margin}
`

export interface IStyledControl extends IBase {}

const styledControl = css<IStyledControl>`
  cursor: pointer;
  background-color: ${themeGet('colors.menuItem.bg')};
  ${styledItem}

  &:hover {
    background-color: ${themeGet('colors.menuItem.hover.bg')};
  }

  ${margin}
`

export const StyledNavLink = styled(NavLink)`
  ${styledControl}
`

export const StyledButton = styled.button`
  border: none;
  outline: none;

  ${styledControl}
`
