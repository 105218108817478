import styled, { css } from 'styled-components'
import { themeGet } from '@styled-system/theme-get'

import { IDDisabledOptionCssProps } from 'Components/Blocks/MentionTextarea/Components/Search/styles'

export interface IDisabledCssProps {
  disabled?: boolean
}

const disabledCss = ({ disabled }: IDisabledCssProps) =>
  disabled &&
  css`
    background-color: ${themeGet('colors.bg.disabled')};
  `
export interface IErrorCssProps {
  error?: boolean
}

const errorCss = ({ error }: IErrorCssProps) =>
  error &&
  css`
    background-color: ${themeGet('colors.bg.error')};
  `
export interface IInfoCssProps {
  info?: boolean
}

const infoCss = ({ info }: IInfoCssProps) =>
  info &&
  css`
    background-color: ${themeGet('colors.bg.info')};
  `
export interface IOverlayCssProps {
  overlay?: boolean
}

const overlayCss = ({ overlay }: IOverlayCssProps) =>
  overlay &&
  css`
    background-color: ${themeGet('colors.bg.overlay')};
  `
export interface IOverlayPopUpCssProps {
  overlayPopUp?: boolean
}

const overlayPopUpCss = ({ overlayPopUp }: IOverlayPopUpCssProps) =>
  overlayPopUp &&
  css`
    background-color: ${themeGet('colors.bg.overlayPopUp')};
  `
export interface ISuccessCssProps {
  success?: boolean
}

const successCss = ({ success }: ISuccessCssProps) =>
  success &&
  css`
    background-color: ${themeGet('colors.bg.success')};
  `
export interface ITooltipCssProps {
  tooltip?: boolean
}

const tooltipCss = ({ tooltip }: ITooltipCssProps) =>
  tooltip &&
  css`
    background-color: ${themeGet('colors.bg.tooltip')};
  `
export interface IWarningCssProps {
  warning?: boolean
}

const warningCss = ({ warning }: IWarningCssProps) =>
  warning &&
  css`
    background-color: ${themeGet('colors.bg.warning')};
  `
export interface IWinterLakesCssProps {
  winterLakes?: boolean
}

const winterLakesCss = ({ winterLakes }: IWinterLakesCssProps) =>
  winterLakes &&
  css`
    background-color: ${themeGet('colors.bg.winterLakes')};
  `
export interface ISkylarCssProps {
  skylar?: boolean
}

const skylarCss = ({ skylar }: ISkylarCssProps) =>
  skylar &&
  css`
    background-color: ${themeGet('colors.bg.skylar')};
  `
export interface IHokkaidoLavenderCssProps {
  hokkaidoLavender?: boolean
}

const hokkaidoLavenderCss = ({ hokkaidoLavender }: IHokkaidoLavenderCssProps) =>
  hokkaidoLavender &&
  css`
    background-color: ${themeGet('colors.bg.hokkaidoLavender')};
  `
export interface IPasadenaRoseCssProps {
  pasadenaRose?: boolean
}

const pasadenaRoseCss = ({ pasadenaRose }: IPasadenaRoseCssProps) =>
  pasadenaRose &&
  css`
    background-color: ${themeGet('colors.bg.pasadenaRose')};
  `
export interface IGoldenSpiceCssProps {
  goldenSpice?: boolean
}

const goldenSpiceCss = ({ goldenSpice }: IGoldenSpiceCssProps) =>
  goldenSpice &&
  css`
    background-color: ${themeGet('colors.bg.goldenSpice')};
  `
export interface IShutterCopperCssProps {
  shutterCopper?: boolean
}

const shutterCopperCss = ({ shutterCopper }: IShutterCopperCssProps) =>
  shutterCopper &&
  css`
    background-color: ${themeGet('colors.bg.shutterCopper')};
  `
export interface IClematisGreenCssProps {
  clematisGreen?: boolean
}

const clematisGreenCss = ({ clematisGreen }: IClematisGreenCssProps) =>
  clematisGreen &&
  css`
    background-color: ${themeGet('colors.bg.clematisGreen')};
  `

export interface IBoxProps
  extends IDDisabledOptionCssProps,
    IErrorCssProps,
    IInfoCssProps,
    IOverlayCssProps,
    IOverlayPopUpCssProps,
    ISuccessCssProps,
    ITooltipCssProps,
    IWarningCssProps,
    IWinterLakesCssProps,
    ISkylarCssProps,
    IHokkaidoLavenderCssProps,
    IPasadenaRoseCssProps,
    IGoldenSpiceCssProps,
    IShutterCopperCssProps,
    IClematisGreenCssProps {
  body?: boolean
}

export const Box = styled.div<IBoxProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 150px;
  height: 100px;
  border-radius: 4px;
  background-color: ${themeGet('colors.bg.body')};
  box-shadow: ${themeGet('shadows.box')};

  ${disabledCss}
  ${errorCss}
  ${infoCss}
  ${overlayCss}
  ${overlayPopUpCss}
  ${successCss}
  ${tooltipCss}
  ${warningCss}

  ${winterLakesCss}
  ${skylarCss}
  ${hokkaidoLavenderCss}
  ${pasadenaRoseCss}
  ${goldenSpiceCss}
  ${shutterCopperCss}
  ${clematisGreenCss}
`
