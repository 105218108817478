import React, { useCallback, useMemo, useState } from 'react'
import { Form } from 'react-final-form'
import PropTypes from 'prop-types'

import { useApolloClient, useMutation } from '@apollo/client'
import debounce from 'awesome-debounce-promise'
import createCommunityInvitesMutation from 'GraphQL/Mutations/Community/createCommunityInvites.graphql'
import inviteUsersByEmailMutation from 'GraphQL/Mutations/Community/inviteUsersByEmail.graphql'
import communityInviteLinkTokenQuery from 'GraphQL/Queries/Community/communityInviteLinkToken.graphql'
import communityInvitesQuery from 'GraphQL/Queries/Community/communityInvites.graphql'
import usersQuery from 'GraphQL/Queries/User/users.graphql'
import Utils from 'Utils'
import { setMinSearchLength } from 'Utils/Form'
import { tagsToOptions } from 'Utils/Options'
import validate from 'validate.js'

import forEach from 'lodash/forEach'
import map from 'lodash/map'
import noop from 'lodash/noop'

import {
  Column,
  CopyInput,
  InputField,
  Modal,
  SelectField,
  Tag,
} from 'Components/UI'

import { TAG_COLOR_KIND } from 'Constants/tags'

import { useAppContext, useCommunityContext } from 'Hooks'

import * as ROUTES from 'Router/routes'

import { useQuery } from 'Services/Apollo'
import _ from 'Services/I18n'
import toast from 'Services/Toast'

const FIELD = {
  INVITE_MESSAGE: 'inviteMessage',
  USERS: 'users',
}

const SEARCH_DEBOUNCE = 300
const SEARCH_MINIMUM_LENGTH = 3

validate.validators.customInputValidator = value => {
  const usersByEmail = value?.filter(object => !object.user)
  if (
    usersByEmail?.length > 0 &&
    usersByEmail.some(
      option =>
        typeof validate(
          { email: option.value.trim() },
          { email: { email: true } },
        ) !== 'undefined',
    )
  ) {
    return `^Invalid email entered`
  }
  return null
}

function hasEmail(options) {
  return options?.find(option => !option.id)
}

function InviteUserToCommunityModal({ isOpen, onClose }) {
  const [loading, setLoading] = useState(false)

  const { community } = useCommunityContext()
  const { slug } = useAppContext()

  const [createCommunityInvites] = useMutation(createCommunityInvitesMutation)
  const [inviteUsersByEmail] = useMutation(inviteUsersByEmailMutation)

  const { data } = useQuery(communityInviteLinkTokenQuery, {
    variables: community ? { communityId: community.id } : undefined,
    skip: !community,
  })

  const inviteLinkToken = useMemo(() => {
    if (!community?.open && data?.communityInviteLinkToken) {
      return Utils.URL.buildFrontendUrl({
        path: ROUTES.AUTH_SIGN_IN,
        queryParams: {
          community: slug,
          invite: data?.communityInviteLinkToken,
        },
      })
    }

    return Utils.URL.buildFrontendUrl({ slug })
  }, [community, data?.communityInviteLinkToken, slug])

  const submit = useCallback(
    async values => {
      setLoading(true)
      const usersIds = []
      const users = []
      forEach(values?.users, option =>
        option.user
          ? usersIds.push(option.value)
          : users.push({ email: option.value.trim() }),
      )
      try {
        if (usersIds.length > 0) {
          await createCommunityInvites({
            variables: {
              toUserIds: usersIds,
              communityId: community?.id,
              inviteMessage: values.inviteMessage,
            },
            refetchQueries: [
              {
                query: communityInvitesQuery,
                variables: { communityId: community?.id },
                fetchPolicy: 'network-only',
              },
            ],
          })
        }
        if (users.length > 0) {
          await inviteUsersByEmail({
            variables: {
              users,
              communityId: community?.id,
              inviteMessage: values.inviteMessage,
              shouldSendCommunityInvite: true,
            },
            refetchQueries: [
              {
                query: communityInvitesQuery,
                variables: { communityId: community?.id },
                fetchPolicy: 'network-only',
              },
            ],
          })
        }

        toast.success({
          title: 'Invite user',
          text: `Users successfully invited`,
        })

        onClose()
      } catch (error) {
        toast.error({
          title: 'Invite user',
          text: _(`error.${error?.message || 'generic'}`),
        })
      } finally {
        setLoading(false)
      }
    },
    [onClose, createCommunityInvites, community, inviteUsersByEmail],
  )

  const handleValidate = useCallback(values => {
    const constraints = {
      [FIELD.USERS]: {
        type: 'array',
        presence: {
          allowEmpty: false,
          presence: true,
          message: `^Field is required`,
        },
        customInputValidator: true,
      },
      [FIELD.INVITE_MESSAGE]: {
        type: 'string',
      },
    }

    return validate(values, constraints)
  }, [])

  const { me } = useAppContext()
  const client = useApolloClient()

  const loadUsersOptions = useCallback(
    excludeValues => async (inputValue, callback) => {
      const usersWithId = excludeValues?.filter(option => option.user)
      try {
        const result = await client.query({
          query: usersQuery,
          variables: {
            search: inputValue,
            excludeUserIds: [me?.id, ...map(usersWithId, 'value')],
            excludeCommunityIds: [community?.id],
            limit: 25,
          },
        })

        const users = result.data?.users?.rows || []

        const reducedUsers = users.map(user => ({
          id: user.id,
          name: Utils.User.getFullName(user.profile),
        }))

        callback(tagsToOptions(reducedUsers, TAG_COLOR_KIND.USER))
      } catch (error) {
        toast.error(`The server returned an error: "${error.message}"`)
      }
    },
    [client, me?.id, community?.id],
  )

  const debouncedLoadUserOptions = useCallback(
    excludedValues =>
      setMinSearchLength(
        debounce(loadUsersOptions(excludedValues), SEARCH_DEBOUNCE),
        SEARCH_MINIMUM_LENGTH,
      ),
    [loadUsersOptions],
  )

  const renderMultiValue = useCallback(
    selectProps => (
      <Tag
        colorKind={TAG_COLOR_KIND.USER}
        removable
        small
        text={selectProps?.children?.props?.text ?? selectProps?.children}
        onRemove={() => selectProps?.removeProps?.onClick()}
      />
    ),
    [],
  )

  const renderForm = useCallback(
    ({ handleSubmit, values }) => (
      <Modal
        cancelText="Cancel"
        confirmDisabled={loading}
        confirmText="Send invite"
        isOpen={isOpen}
        title={`Invite people to the ${community?.name} community`}
        width={['100%', '100%', '600px']}
        onClose={onClose}
        onConfirm={handleSubmit}
      >
        <Column fullWidth gap={4}>
          <SelectField
            async
            caption={
              hasEmail(values?.users)
                ? 'One of the listed persons is not in NetworkOS yet.'
                : null
            }
            components={{
              MultiValue: renderMultiValue,
            }}
            creatable
            isMulti
            loadOptions={debouncedLoadUserOptions(values.users)}
            name={FIELD.USERS}
            placeholder="Name or email, comma separated"
            withPortal
          />

          <InputField
            label="Message"
            name={FIELD.INVITE_MESSAGE}
            placeholder="Type your message"
            small
            textArea
          />

          <CopyInput
            inputValue={inviteLinkToken}
            title={_('general.copyInviteLink')}
          />
        </Column>
      </Modal>
    ),
    [
      community,
      debouncedLoadUserOptions,
      inviteLinkToken,
      isOpen,
      loading,
      onClose,
      renderMultiValue,
    ],
  )

  return (
    <Form render={renderForm} validate={handleValidate} onSubmit={submit} />
  )
}

InviteUserToCommunityModal.defaultProps = {
  isOpen: false,
  onClose: noop,
}

InviteUserToCommunityModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
}

export default InviteUserToCommunityModal
