import React from 'react'

import { pick } from '@styled-system/props'

import { IconLoader2 } from '@tabler/icons-react'

import * as Styled from './Loader.styles'

export interface ILoader extends Styled.IContainer {
  size?: number
  text?: string
}

function Loader({ size = 16, absolute = false, text, ...props }: ILoader) {
  return (
    <Styled.Container {...pick(props)} absolute={absolute}>
      <IconLoader2 size={size} />
      {!!text && <Styled.Text ml={2}>{text}</Styled.Text>}
    </Styled.Container>
  )
}

export default Loader
