import React, { useMemo } from 'react'
import PropTypes from 'prop-types'

import { Text } from 'Components/UI'

function TagTypeCell({ row }) {
  const tag = useMemo(() => row?.original, [row])
  return <Text>{tag.kind}</Text>
}

TagTypeCell.defaultProps = {
  row: {},
}

TagTypeCell.propTypes = {
  row: PropTypes.object,
}

export default TagTypeCell
