import React from 'react'
import PropTypes from 'prop-types'

import { pick } from '@styled-system/props'

import { RelationshipStrengthField } from 'Components/Blocks/Forms/Fields'
import { Column, Divider, ImageInputField, InputField } from 'Components/UI'

import _ from 'Services/I18n'

import { FIELD } from '../fields'
import { TabContainer } from '../styles'

function GeneralTab({ visible, canEditEmail, canEditPhone, ...rest }) {
  return (
    <TabContainer {...pick(rest)} visible={visible}>
      <Column gap={4} px={5}>
        <ImageInputField name={FIELD.PHOTO_URL} />

        <Column fullWidth gap={4}>
          <InputField
            label={_('auth.shared.firstName')}
            name={FIELD.FIRST_NAME}
            placeholder="First name"
            required
          />

          <InputField
            label={_('auth.shared.lastName')}
            name={FIELD.LAST_NAME}
            placeholder="Last name"
            required
          />

          <InputField
            disabled={!canEditEmail}
            label={_('auth.shared.email')}
            name={FIELD.EMAIL}
            placeholder="Email"
          />

          {canEditPhone && (
            <InputField
              disabled={!canEditPhone}
              label={_('auth.shared.phone')}
              name={FIELD.PHONE_NUMBER}
              phoneNumberFormat
              placeholder="Phone number"
            />
          )}
        </Column>
      </Column>

      <Divider my={5} />

      <Column fullWidth gap={4} px={5}>
        <InputField
          label={_('auth.shared.linkedIn')}
          name={FIELD.LINKED_IN}
          placeholder="LinkedIn"
        />

        <InputField
          label={_('auth.shared.job')}
          name={FIELD.JOB}
          placeholder="Job"
        />

        <RelationshipStrengthField name={FIELD.RELATIONSHIP} />

        <InputField
          label={_('auth.shared.organization')}
          name={FIELD.ORGANIZATION}
          placeholder="Company"
        />
      </Column>
    </TabContainer>
  )
}

GeneralTab.defaultProps = {
  canEditEmail: false,
  canEditPhone: false,
  visible: true,
}

GeneralTab.propTypes = {
  canEditEmail: PropTypes.bool,
  canEditPhone: PropTypes.bool,
  visible: PropTypes.bool,
}

export default GeneralTab
