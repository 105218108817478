import React, { useState } from 'react'

import { pick } from '@styled-system/props'

import Tippy, { TippyProps } from '@tippyjs/react/headless'
import { Plugin } from 'tippy.js'

import isNil from 'lodash/isNil'

import { Arrow, ContentWrapper, PopoverBlock } from './styles'

export interface IPopover extends TippyProps {
  placement?:
    | 'top'
    | 'top-start'
    | 'top-end'
    | 'right'
    | 'right-start'
    | 'right-end'
    | 'bottom'
    | 'bottom-start'
    | 'bottom-end'
    | 'left'
    | 'left-start'
    | 'left-end'
    | 'auto'
    | 'auto-start'
    | 'auto-end'
  trigger?:
    | 'click'
    | 'mouseenter'
    | 'focus'
    | 'focusin'
    | 'click'
    | 'manual'
    | 'mouseenter focus'
  withArrow?: boolean
  withBackground?: boolean
}

function Popover({
  interactive = false,
  content = null,
  delay = [270, 0],
  offset = [0, 8],
  placement = 'top',
  plugins = [],
  trigger = 'mouseenter focus',
  withArrow = false,
  withBackground = true,
  zIndex = 9999,
  ...rest
}: IPopover) {
  const [mounted, setMounted] = useState(false)
  const [arrow, setArrow] = useState<HTMLDivElement | null>(null)

  const lazyPlugin: Plugin = {
    fn: () => ({
      onShow: () => setMounted(true),
      onHidden: () => setMounted(false),
    }),
  }

  const computedProps: TippyProps = { plugins, ...rest }
  // Can't pass trigger together with visible props, a lot of warning rendered
  const triggerProps = isNil(rest.visible) ? { trigger } : {}

  computedProps.plugins = [lazyPlugin].concat(plugins || [])

  computedProps.render = attrs =>
    mounted ? (
      <PopoverBlock
        {...pick(rest)}
        {...attrs}
        withoutBackground={!withBackground}
      >
        {withArrow && <Arrow ref={setArrow} />}
        <ContentWrapper>{content}</ContentWrapper>
      </PopoverBlock>
    ) : null

  return (
    <Tippy
      arrow={withArrow}
      content={content}
      delay={delay}
      interactive={interactive}
      offset={offset}
      placement={placement}
      popperOptions={{
        modifiers: [
          {
            name: 'arrow',
            options: {
              element: arrow,
            },
          },
          {
            name: 'flip',
            options: {
              padding: { top: 64 },
            },
          },
        ],
      }}
      zIndex={zIndex}
      {...triggerProps}
      {...computedProps}
    />
  )
}

export default Popover
