import React, { useCallback, useMemo, useRef } from 'react'
import PropTypes from 'prop-types'

import { IconButton } from 'Components/UI'

import { DeletedUserIcon, DeleteIcon } from './styles'

import { useTableContext } from '../context'

function ActionsCell({ row, deleteCommunityUserIds }) {
  const deleteRef = useRef(null)

  const { onDeleteRow } = useTableContext()

  const user = row?.original

  const handleDelete = useCallback(() => {
    deleteRef.current.blur()
    onDeleteRow(user)
  }, [onDeleteRow, user])

  const isDeletedUser = useMemo(
    () => deleteCommunityUserIds.includes(user?.communityUserId),
    [deleteCommunityUserIds, user?.communityUserId],
  )

  return (
    <IconButton mr={2} outline ref={deleteRef} small onClick={handleDelete}>
      {isDeletedUser ? <DeletedUserIcon /> : <DeleteIcon />}
    </IconButton>
  )
}

ActionsCell.defaultProps = {
  deleteCommunityUserIds: [],
  row: {},
}

ActionsCell.propTypes = {
  deleteCommunityUserIds: PropTypes.array,
  row: PropTypes.object,
}

export default ActionsCell
