import { AnalyzerFunction } from 'Components/Blocks/Graph/utils'

export const ANALYZERS_NAME: Record<string, AnalyzerFunction> = {
  between: 'betweenness',
  close: 'closeness',
  degree: 'degrees',
  central: 'eigenCentrality',
  rank: 'pageRank',
}

export const NODE_KIND = {
  user: 'user',
  skill: 'skill',
  role: 'role',
  project: 'project',
  event: 'event',
  group: 'group',
  custom: 'custom',
  organization: 'organization',
  knowledge: 'knowledge',
  community: 'community',
  areaOfExperience: 'areaOfExperience',
} as const
export type NodeKindKeys = keyof typeof NODE_KIND
export type NodeKind = (typeof NODE_KIND)[NodeKindKeys]

export enum ItemType {
  User = 'user',
  SkillTag = 'skillTag',
  Organization = 'organization',
  Knowledge = 'knowledge',
  Community = 'community',
  Edge = 'edge',
  RelationshipStrengthEdge = 'relationshipStrengthEdge',
  AreaOfExperience = 'areaOfExperience',
}

export const ACTION_KIND = {
  addUser: 'addUser',
  meetUser: 'meetUser',
  sharedConnection: 'sharedConnection',
  mentionUser: 'mentionUser',
  user: 'user',
  skill: 'skill',
  needSkill: 'needSkill',
  role: 'role',
  project: 'project',
  event: 'event',
  group: 'group',
  custom: 'custom',
  connection: 'connection',
  myConnection: 'myConnection',
  mention: 'mention',
  organization: 'organization',
  knowledge: 'knowledge',
}
export type ActionKindKeys = keyof typeof ACTION_KIND
export type ActionKind = (typeof ACTION_KIND)[ActionKindKeys]

export type SkillTagKind = Exclude<NodeKind, 'user'>

export const COMBO_NAME: Record<SkillTagKind, string> = {
  [NODE_KIND.event]: 'Events',
  [NODE_KIND.skill]: 'Skills',
  [NODE_KIND.project]: 'Projects',
  [NODE_KIND.group]: 'Groups',
  [NODE_KIND.role]: 'Roles',
  [NODE_KIND.custom]: 'Custom Tags',
  [NODE_KIND.organization]: 'Organizations',
  [NODE_KIND.knowledge]: 'Knowledge',
  [NODE_KIND.community]: 'Communities',
  [NODE_KIND.areaOfExperience]: 'Areas of Experience',
}

export const NODE_NAME: Record<SkillTagKind, string> = {
  [NODE_KIND.event]: 'Event',
  [NODE_KIND.skill]: 'Skill',
  [NODE_KIND.project]: 'Project',
  [NODE_KIND.group]: 'Group',
  [NODE_KIND.role]: 'Role',
  [NODE_KIND.custom]: 'Custom',
  [NODE_KIND.organization]: 'Organization',
  [NODE_KIND.knowledge]: 'Knowledge',
  [NODE_KIND.community]: 'Community',
  [NODE_KIND.areaOfExperience]: 'Area of Experience',
}

export const QUICK_ACTION_KIND = {
  PATH_TO_USER: 'pathToUser',
  ADD_TO_COMMUNITY: 'addToCommunity',
  CREATE_NOTE: 'createNote',
  VIEW_PROFILE: 'viewProfile',
  INTRODUCE_TO: 'introduceTo',
  ADD_SKILLS_TAGS: 'addSkillsTags',
  QUICK_CONNECTOR: 'quickConnector',
  EDIT: 'edit',
  ARCHIVE: 'archive',
  HIDE: 'hide',
  VIEW_SELECTED_ITEMS: 'viewSelectedItems',
} as const
export type QuickActionKindKeys = keyof typeof QUICK_ACTION_KIND
export type QuickActionKind = (typeof QUICK_ACTION_KIND)[QuickActionKindKeys]
