import React from 'react'
import { Field } from 'react-final-form'

import { UPLOAD_TYPES, UploadType } from 'Constants/ids'

import ImageInput from './ImageInput'

export interface IImageInputField {
  defaultValue?: string
  entityId?: string
  entityText?: string
  name: string
  required?: boolean
  uploadType?: UploadType
}

function ImageInputField({
  name,
  uploadType = UPLOAD_TYPES.profilePhoto,
  defaultValue,
  required = false,
  ...rest
}: IImageInputField) {
  return (
    <Field defaultValue={defaultValue} name={name}>
      {({ input }) => (
        <ImageInput
          {...input}
          {...rest}
          required={required}
          uploadType={uploadType}
        />
      )}
    </Field>
  )
}

export default ImageInputField
