import React, { useCallback, useMemo } from 'react'

import getEmailConfirmationLinkMutation from 'GraphQL/Mutations/EmailConfirmation/getEmailConfirmationLink.graphql'
import removeUserEmailMutation from 'GraphQL/Mutations/User/removeUserEmail.graphql'
import listOAuthProvidersQuery from 'GraphQL/Queries/User/listOAuthProviders.graphql'
import { entityCacheRemover } from 'GraphQL/Updaters/Common'

import { Column } from 'Components/UI'
import { Text } from 'Components/UI/_v2'

import { EMAIL_CREDENTIAL_STATE } from 'Constants/ids'

import { useAppContext } from 'Hooks'

import { useMutation, useQuery } from 'Services/Apollo'
import _ from 'Services/I18n'
import toast from 'Services/Toast'

import colors from 'Theme/_v2/colors'

import Item from '../Item/Item'

function List() {
  const { me } = useAppContext()

  const [removeUserEmail] = useMutation(removeUserEmailMutation)
  const [getEmailConfirmationLink] = useMutation(
    getEmailConfirmationLinkMutation,
  )

  const handleRemove = useCallback(
    async (emailCredential: MainSchema.EmailCredential) => {
      try {
        await removeUserEmail({
          variables: {
            emailCredentialId: emailCredential.id,
          },
          update: entityCacheRemover({ entity: emailCredential }),
        })

        toast.success({
          title: 'Remove email',
          text: 'Your email has been removed',
        })
      } catch (error) {
        let message = _('error.generic')

        if (error instanceof Error) {
          message = _(`error.${error.message}`)
        }

        toast.error({
          title: 'Remove email',
          text: message,
        })
      }
    },
    [removeUserEmail],
  )

  const handleSendVerification = useCallback(
    async (emailCredential: MainSchema.EmailCredential) => {
      try {
        await getEmailConfirmationLink({
          variables: {
            emailCredentialId: emailCredential.id,
          },
        })

        toast.success({
          title: 'Email verification',
          text: 'Verification message has been sent',
        })
      } catch (error) {
        let message = _('error.generic')

        if (error instanceof Error) {
          message = _(`error.${error.message}`)
        }

        toast.error({
          title: 'Email verification',
          text: message,
        })
      }
    },
    [getEmailConfirmationLink],
  )

  const { data: oAuthAccounts } = useQuery<
    Pick<MainSchema.Query, 'listOAuthProviders'>
  >(listOAuthProvidersQuery)

  const memoizedAdditionalEmails = useMemo(
    () =>
      me?.emailCredentials.filter(
        credential =>
          !credential.isPrimary &&
          !oAuthAccounts?.listOAuthProviders
            .map(o => o.email)
            .includes(credential.email),
      ),
    [me, oAuthAccounts],
  )

  if (!memoizedAdditionalEmails?.length) {
    return <></>
  }

  return (
    <Column gap={2}>
      {memoizedAdditionalEmails?.map(emailCredential => (
        <Column gap={2} key={emailCredential.id}>
          <Text color={colors.text.primary} fontWeight={600} variant="body-s">
            Email Address
          </Text>
          <Item
            action={
              emailCredential.state === EMAIL_CREDENTIAL_STATE.PENDING
                ? {
                    label: 'Resend Verification Link',
                    onPress: () => handleSendVerification(emailCredential),
                  }
                : undefined
            }
            error={
              emailCredential.state === EMAIL_CREDENTIAL_STATE.PENDING
                ? 'This email will not be added to your account until it is verified.'
                : undefined
            }
            label={emailCredential.email}
            remove={{
              label: 'Remove email',
              onPress: () => handleRemove(emailCredential),
            }}
          />
        </Column>
      ))}
    </Column>
  )
}

export default List
