import React from 'react'

import { pick } from '@styled-system/props'

import { IconSearch } from '@tabler/icons-react'

import { Input } from 'Components/UI/Form/Input'

export interface ISearchInputProps {
  disabled?: boolean
  isLoading?: boolean
  label?: React.ReactNode
  placeholder?: string
  value?: string | number
  width?: number
  onBlur?: React.FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>
  onChange?: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>
  onClear?: React.MouseEventHandler<HTMLButtonElement>
  onFocus?: React.FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>
  onKeyDown?: React.KeyboardEventHandler<HTMLInputElement | HTMLTextAreaElement>
}

function SearchInput({
  disabled,
  isLoading,
  label,
  placeholder,
  value,
  width,
  onClear,
  onChange,
  onBlur,
  onFocus,
  onKeyDown,
  ...rest
}: ISearchInputProps) {
  return (
    <Input
      {...pick(rest)}
      clearable
      disabled={disabled}
      isLoading={isLoading}
      label={label}
      placeholder={placeholder}
      renderBeforeElement={() => <IconSearch />}
      value={value}
      width={width}
      onBlur={onBlur}
      onChange={onChange}
      onClear={onClear}
      onFocus={onFocus}
      onKeyDown={onKeyDown}
    />
  )
}

export default SearchInput
