import React, { useMemo } from 'react'

import { useTheme } from 'styled-components'

import userTopSkillsQuery from 'GraphQL/Queries/User/userTopSkills.graphql'
import pluralize from 'pluralize'
import { Cell, Pie, PieChart } from 'recharts'

import map from 'lodash/map'

import { Column, Loader, Row, Text } from 'Components/UI'

import { useQuery } from 'Services/Apollo'

import { TopSkillsColor } from './styles'

const SIZE_CHART = 170

export interface ITopSkillsProps {
  limit?: number
  userId?: string
}

const TopSkills = ({ userId, limit = 5 }: ITopSkillsProps) => {
  const theme = useTheme()

  const { data, loading } = useQuery<
    Pick<MainSchema.Query, 'userTopSkills'>,
    MainSchema.QueryUserTopSkillsArgs
  >(userTopSkillsQuery, {
    skip: !userId,
    variables: userId ? { userId, limit } : undefined,
  })

  const skills = useMemo(() => data?.userTopSkills || [], [data])

  const colors = useMemo(
    () => [
      theme.colors.mineShaft,
      theme.colors.chartBlue,
      theme.colors.primaryCardinal,
      theme.colors.chartOrange,
      theme.colors.chartYellow,
    ],
    [theme],
  )

  const chartData = useMemo(
    () =>
      map(skills, (skill, index) => ({
        name: skill.skill,
        value: skill.users,
        color: colors[index],
      })),
    [colors, skills],
  )

  if (loading) return <Loader absolute text="Loading..." />

  return (
    <Column height={204}>
      <Text bolder fontSize={2} mb={3}>
        Top Skills
      </Text>

      <Row alignItems="center" fullWidth>
        <PieChart height={SIZE_CHART} width={SIZE_CHART}>
          <Pie
            cx={SIZE_CHART / 2 - 5}
            cy={SIZE_CHART / 2 - 5}
            data={chartData}
            dataKey="value"
            innerRadius={SIZE_CHART / 2 - 15}
            outerRadius={SIZE_CHART / 2}
            paddingAngle={0}
          >
            {chartData.map(({ color }, index) => (
              <Cell
                fill={color}
                key={
                  // eslint-disable-next-line react/no-array-index-key
                  index
                }
              />
            ))}
          </Pie>
        </PieChart>

        <Column ml={5}>
          {chartData.map(({ color, name, value }, index) => (
            <Row
              alignItems="center"
              justifyContent="flex-start"
              key={
                // eslint-disable-next-line react/no-array-index-key
                index
              }
              mb={index !== chartData.length - 1 ? 4 : 0}
            >
              <TopSkillsColor color={color} mr={2} />
              <Text fontSize={14}>
                {name} • {value} {pluralize('user', value)}
              </Text>
            </Row>
          ))}
        </Column>
      </Row>
    </Column>
  )
}

export default TopSkills
