import React, { useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'

import { IconSearch } from '@tabler/icons-react'
import communityUsersBySkillIdQuery from 'GraphQL/Queries/Community/communityUsersBySkillId.graphql'
import communityUsersByTagIdQuery from 'GraphQL/Queries/Community/communityUsersByTagId.graphql'

import { Input, Pagination, Table } from 'Components/UI'

import { SEARCH_TYPES } from 'Constants/ids'

import { useCommunityContext, useEntityTable, useTableSearch } from 'Hooks'

import { useQuery } from 'Services/Apollo'
import _ from 'Services/I18n'

import { useColumns } from './columns'
import { Container } from './styles'

const DEFAULT_PAGE_SIZE = 10

function TagUsersTable({ type, tagId }) {
  const { community } = useCommunityContext()
  const [mostRecentPage, setMostRecentPage] = useState(0)
  const [mostRecentLimit, setMostRecentLimit] = useState(DEFAULT_PAGE_SIZE)

  const query = useMemo(
    () =>
      type === SEARCH_TYPES.skill
        ? communityUsersBySkillIdQuery
        : communityUsersByTagIdQuery,
    [type],
  )

  const { data, loading, refetch } = useQuery(query, {
    variables: {
      communityId: community?.id,
      tagId: type !== SEARCH_TYPES.skill ? tagId : undefined,
      skillId: type === SEARCH_TYPES.skill ? tagId : undefined,
      page: mostRecentPage,
      limit: mostRecentLimit,
    },
    fetchPolicy: 'network-only',
  })

  const columns = useColumns()
  const entities = useMemo(
    () =>
      (type === SEARCH_TYPES.skill
        ? data?.communityUsersBySkillId
        : data?.communityUsersByTagId) ?? {},
    [type, data?.communityUsersBySkillId, data?.communityUsersByTagId],
  )

  const [tableProps, rowsCount] = useEntityTable({
    data: entities,
    columns,
    disableSortBy: true,
  })

  const { pageSize, pageIndex } = tableProps.state

  useEffect(() => {
    setMostRecentPage(tableProps.state.pageIndex)
  }, [tableProps.state.pageIndex])

  useEffect(() => {
    setMostRecentPage(0)
    setMostRecentLimit(tableProps.state.pageSize)
  }, [tableProps.state.pageSize])

  const [search, changeSearch, clearSearch] = useTableSearch({
    onSearch: value => {
      tableProps.gotoPage(0)

      refetch({
        limit: mostRecentLimit,
        search: value || undefined,
      }).then()
    },
  })

  return (
    <>
      <Input
        fullWidth
        label={_('search.byName')}
        mb={4}
        name="search"
        placeholder="Search by users"
        renderBeforeElement={() => <IconSearch />}
        value={search}
        onChange={changeSearch}
        onClear={clearSearch}
      />

      <Container mb={4}>
        <Table {...tableProps} loading={loading} />
      </Container>

      <Pagination
        showTotal={false}
        state={{ pageIndex, pageSize }}
        total={rowsCount}
        onPageIndexChange={tableProps?.gotoPage}
        onPageSizeChange={tableProps?.setPageSize}
      />
    </>
  )
}

TagUsersTable.defaultProps = {
  tagId: null,
}

TagUsersTable.propTypes = {
  tagId: PropTypes.string,
  type: PropTypes.string.isRequired,
}

export default TagUsersTable
