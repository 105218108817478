import React from 'react'
import { Outlet } from 'react-router-dom'

import { Link } from 'Components/UI/_v2'

import { ROOT } from 'Router/routes'

import * as Styled from './styles'

function Auth() {
  return (
    <Styled.Container>
      <Styled.ContentContainer>
        <Link href={ROOT}>
          <Styled.Logo />
        </Link>

        <Styled.Content>
          <Outlet />
        </Styled.Content>
      </Styled.ContentContainer>

      <Styled.BackgroundContainer />
    </Styled.Container>
  )
}

export default Auth
