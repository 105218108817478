import React from 'react'
import PropTypes from 'prop-types'

import {
  IconArrowBackUp,
  IconCheck,
  IconPencil,
  IconShare,
  IconTrash,
} from '@tabler/icons-react'

import noop from 'lodash/noop'

import { IconButton, Row, Tooltip } from 'Components/UI'

import { Mark, StyledIconStar } from './styles'

function TableActions({
  approveTooltip,
  canApprove,
  canDelete,
  canRestore,
  canEdit,
  canShare,
  canToggleFavorite,
  deleteRef,
  deleteTooltip,
  disabled,
  editRef,
  editTooltip,
  isFavorite,
  isFavoriteDisabled,
  isShared,
  restoreRef,
  restoreTooltip,
  onApprove,
  onDelete,
  onEdit,
  onRestore,
  onFavoriteToggle,
  onShare,
}) {
  return (
    <Row gap={2}>
      {canApprove && (
        <Tooltip content={approveTooltip}>
          <IconButton disabled={disabled} outline small onClick={onApprove}>
            <IconCheck />
          </IconButton>
        </Tooltip>
      )}

      {canEdit && (
        <Tooltip content={editTooltip}>
          <IconButton
            disabled={disabled}
            outline
            ref={editRef}
            small
            onClick={onEdit}
          >
            <IconPencil />
          </IconButton>
        </Tooltip>
      )}

      {canRestore && (
        <Tooltip content={restoreTooltip}>
          <IconButton
            disabled={disabled}
            outline
            ref={restoreRef}
            small
            onClick={onRestore}
          >
            <IconArrowBackUp />
          </IconButton>
        </Tooltip>
      )}

      {canDelete && (
        <Tooltip content={deleteTooltip}>
          <IconButton
            disabled={disabled}
            outline
            ref={deleteRef}
            small
            onClick={onDelete}
          >
            <IconTrash />
          </IconButton>
        </Tooltip>
      )}

      {canShare && (
        <IconButton disabled={disabled} outline small onClick={onShare}>
          {isShared ?? <Mark />}
          <IconShare />
        </IconButton>
      )}

      {canToggleFavorite && (
        <IconButton
          disabled={isFavoriteDisabled}
          outline
          small
          onClick={onFavoriteToggle}
        >
          <StyledIconStar favorite={isFavorite ? 1 : 0} />
        </IconButton>
      )}
    </Row>
  )
}

const refShape = PropTypes.shape({ current: PropTypes.instanceOf(Element) })

TableActions.defaultProps = {
  approveTooltip: null,
  canApprove: false,
  canDelete: false,
  canEdit: false,
  canRestore: false,
  canShare: false,
  canToggleFavorite: false,
  deleteRef: null,
  deleteTooltip: null,
  disabled: false,
  editRef: null,
  editTooltip: null,
  isFavorite: false,
  isFavoriteDisabled: false,
  isShared: false,
  restoreRef: null,
  restoreTooltip: null,
  onApprove: noop,
  onDelete: noop,
  onEdit: noop,
  onFavoriteToggle: noop,
  onRestore: noop,
  onShare: noop,
}

TableActions.propTypes = {
  approveTooltip: PropTypes.string,
  canApprove: PropTypes.bool,
  canDelete: PropTypes.bool,
  canEdit: PropTypes.bool,
  canRestore: PropTypes.bool,
  canShare: PropTypes.bool,
  canToggleFavorite: PropTypes.bool,
  deleteRef: refShape,
  deleteTooltip: PropTypes.string,
  disabled: PropTypes.bool,
  editRef: refShape,
  editTooltip: PropTypes.string,
  isFavorite: PropTypes.bool,
  isFavoriteDisabled: PropTypes.bool,
  isShared: PropTypes.bool,
  restoreRef: refShape,
  restoreTooltip: PropTypes.string,
  onApprove: PropTypes.func,
  onDelete: PropTypes.func,
  onEdit: PropTypes.func,
  onFavoriteToggle: PropTypes.func,
  onRestore: PropTypes.func,
  onShare: PropTypes.func,
}

export default TableActions
