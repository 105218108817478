import React, { useCallback, useMemo } from 'react'
import PropTypes from 'prop-types'

import pick from 'lodash/pick'

import { Checkbox, Row, Tooltip } from 'Components/UI'

import _ from 'Services/I18n'

import { useTableContext } from '../context'
import useAbility from '../useAbility'

function CheckboxCell({ row }) {
  const { onSelectUser, selectedUsersIds } = useTableContext()
  const { canEditUserIfCreator, canEditUserIfNotCreator } = useAbility()

  const handleSelect = useCallback(() => {
    onSelectUser(pick(row?.original, ['id', 'userId', 'firstName', 'lastName']))
  }, [onSelectUser, row])

  const user = useMemo(() => row?.original, [row])

  const isChecked = useMemo(
    () => selectedUsersIds.includes(row?.original?.userId),
    [row, selectedUsersIds],
  )

  const isDisabled = useMemo(
    () => !(canEditUserIfCreator(user) || canEditUserIfNotCreator(user)),
    [canEditUserIfCreator, canEditUserIfNotCreator, user],
  )

  if (isDisabled)
    return (
      <Tooltip content={_('accountManagement.tooltips.notAllowedToEditUsers')}>
        <Row>
          <Checkbox disabled />
        </Row>
      </Tooltip>
    )

  return <Checkbox checked={isChecked} onChange={handleSelect} />
}

CheckboxCell.defaultProps = {
  row: {},
}

CheckboxCell.propTypes = {
  row: PropTypes.object,
}

export default CheckboxCell
