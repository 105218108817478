import reduce from 'lodash/reduce'

interface PromiseHandler<T, R> {
  (item: T, index: number): Promise<T | R | void | null | undefined>
}

function delay(ms: number) {
  return new Promise(resolve => {
    setTimeout(resolve, ms)
  })
}

function forEachPromise<T, R>(
  arr: undefined | T[],
  handler: PromiseHandler<T, R>,
) {
  return reduce(
    arr,
    (current: Promise<any>, item: T, index: number) =>
      current.then(() => handler(item, index)),
    Promise.resolve(),
  )
}

export { delay, forEachPromise }
