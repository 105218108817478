import React, { useCallback } from 'react'
import { Row } from 'react-table'

import { useAppContext } from 'Hooks'

import { TableActions } from '../../Blocks'
import { useTableContext } from '../context'

export interface IActionsProps {
  row: Row<MainSchema.Note>
}

function Actions({ row }: IActionsProps) {
  const { onUpdateFavorite, onDeleteNote } = useTableContext()
  const { me } = useAppContext()

  const noteId = row.original.id
  const isDisabled = row.original.creator?.id !== me?.id
  const isFavorite = row.original.favorite
  const canBeDeleted = row.original.creatorId === me?.id

  const handleToggleFavorite = useCallback(() => {
    onUpdateFavorite?.(noteId, !isFavorite)
  }, [isFavorite, noteId, onUpdateFavorite])

  const handleDelete = useCallback(() => {
    onDeleteNote(noteId)
  }, [noteId, onDeleteNote])

  return (
    <TableActions
      canDelete={canBeDeleted}
      canToggleFavorite
      isFavorite={isFavorite}
      isFavoriteDisabled={isDisabled}
      onDelete={handleDelete}
      onFavoriteToggle={handleToggleFavorite}
    />
  )
}

export default Actions
