import { CSSProperties } from 'styled-components'
import {
  RequiredTheme,
  ResponsiveValue,
  system,
  Theme,
  ThemeValue,
} from 'styled-system'

export interface GapProps<
  ThemeType extends Theme = RequiredTheme,
  TVal = ThemeValue<'space', ThemeType>,
> {
  /** Margin on top, left, bottom and right */
  gap?: ResponsiveValue<TVal, ThemeType> | undefined
}

export const gap = system({
  gap: {
    property: 'gap',
    scale: 'space',
  },
})

export interface WhiteSpaceProps<
  ThemeType extends Theme = RequiredTheme,
  TVal = CSSProperties['whiteSpace'],
> {
  whiteSpace?: ResponsiveValue<TVal, ThemeType> | undefined
}

export const whiteSpace = system({
  whiteSpace: {
    property: 'whiteSpace',
  },
})
