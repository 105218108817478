import React, { useMemo } from 'react'
import PropTypes from 'prop-types'

import { useTheme } from 'styled-components'

import Utils from 'Utils'

import { Text } from 'Components/UI'

function AddedAtCell({ row }) {
  const { colors } = useTheme()

  const addedAtDate = useMemo(
    () => Utils.Table.renderDateTimeCell({ value: row?.original?.addedAt }),
    [row?.original?.addedAt],
  )
  return <Text color={colors.grayBoulder}>{addedAtDate}</Text>
}

AddedAtCell.defaultProps = {
  row: {},
}

AddedAtCell.propTypes = {
  row: PropTypes.object,
}

export default AddedAtCell
