import { persistState as persistor } from '@storeon/localstorage'
import { createStoreon } from 'storeon'

import camelCase from 'lodash/camelCase'

import { APP_NAME } from 'Config'

import { CookieStorage } from 'Services/CookiePersist'
import { setStore } from 'Services/Shared'

import authStateModule, {
  AUTH_STATE_NAMESPACE,
  AuthState,
  AuthStateEvents,
} from './auth'
import communityStateModule, {
  COMMUNITY_STATE_NAMESPACE,
  CommunityState,
  CommunityStateEvents,
} from './community'
import i18nStateModule, {
  I18N_STATE_NAMESPACE,
  I18nState,
  I18nStateEvents,
} from './i18n'
import searchHistoryStateModule, {
  SEARCH_HISTORY_STATE_NAMESPACE,
  SearchHistoryState,
  SearchHistoryStateEvents,
} from './searchHistory'

interface State
  extends AuthState,
    I18nState,
    CommunityState,
    SearchHistoryState {}

interface StateEvents
  extends AuthStateEvents,
    I18nStateEvents,
    CommunityStateEvents,
    SearchHistoryStateEvents {}

export function createStore() {
  const store = createStoreon<State, StateEvents>([
    authStateModule,
    i18nStateModule,
    communityStateModule,
    searchHistoryStateModule,
    persistor([I18N_STATE_NAMESPACE, SEARCH_HISTORY_STATE_NAMESPACE], {
      key: camelCase(APP_NAME),
    }),
    persistor([AUTH_STATE_NAMESPACE, COMMUNITY_STATE_NAMESPACE], {
      key: 'store',
      storage: CookieStorage,
    }),
  ])

  setStore(store)

  return store
}

export type Store = ReturnType<typeof createStore>
