import React, { useCallback, useRef, useState } from 'react'
import { Form } from 'react-final-form'
import PropTypes from 'prop-types'

import { validate } from 'validate.js'

import get from 'lodash/get'
import noop from 'lodash/noop'

import csvTemplate from 'Assets/upload_admin_users_template.csv'

import { Button, Column, InputField, Loader, Modal, Row } from 'Components/UI'

import { UPLOAD_TYPES } from 'Constants/ids'

import { uploadFile } from 'Services/Api/Queries/upload'
import toast from 'Services/Toast'

import { Content, DownloadLink } from './styles'

const FIELD = {
  FILE: 'file',
}

const initialValues = {
  [FIELD.FILE]: undefined,
}

function ImportUsers({ community, isOpen, onClose, ...rest }) {
  const [loading, setLoading] = useState(false)

  const close = useRef(null)

  const handleMount = useCallback(instance => {
    close.current = get(instance, 'handleClose')
  }, [])

  const handleValidate = useCallback(values => {
    const constraints = {
      [FIELD.FILE]: {
        presence: true,
      },
    }

    return validate(values, constraints)
  }, [])

  const doOnSubmit = useCallback(
    async values => {
      setLoading(true)

      const file = values[FIELD.FILE]?.[0]

      try {
        if (file) {
          const response = await uploadFile({
            file,
            type: UPLOAD_TYPES.users,
            entityId: community.id,
          })

          if (response?.ok) {
            toast.success({
              title: 'Import users',
              text: 'Successfully imported users',
            })
          } else {
            toast.error({
              title: 'Import users',
              text: 'Something went wrong',
            })
          }
        }
      } catch (error) {
        toast.error({
          title: 'Import users',
          text: error.message,
        })
      }

      setLoading(false)
      onClose()
    },
    [community, onClose],
  )

  return (
    <Modal
      {...Modal.pickProps(rest)}
      isOpen={isOpen}
      shouldCloseOnOverlayClick={false}
      title="Import users"
      width="400px"
      onClose={onClose}
      onMount={handleMount}
    >
      <Form
        initialValues={initialValues}
        render={({ dirty, invalid, submitting, handleSubmit }) => (
          <Content>
            <Column mb={4}>
              <InputField
                accept="text/csv"
                fileMode
                name={FIELD.FILE}
                type="file"
              />
            </Column>

            <Row center fullWidth>
              <DownloadLink download href={csvTemplate}>
                <Button small onClick={e => e.stopPropagation()}>
                  Download csv template
                </Button>
              </DownloadLink>
            </Row>

            <Row center mt={5} spaceBetween>
              {loading ? (
                <Loader />
              ) : (
                <>
                  <Button secondary small onClick={onClose}>
                    Cancel
                  </Button>
                  <Button
                    disabled={!dirty || invalid || submitting}
                    small
                    onClick={handleSubmit}
                  >
                    Save
                  </Button>
                </>
              )}
            </Row>
          </Content>
        )}
        validate={handleValidate}
        onSubmit={doOnSubmit}
      />
    </Modal>
  )
}

ImportUsers.defaultProps = {
  isOpen: false,
  community: undefined,
  onClose: noop,
}

ImportUsers.propTypes = {
  community: PropTypes.object,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
}

export default ImportUsers
