import React, { useCallback, useRef } from 'react'
import PropTypes from 'prop-types'

import { FiEdit3, FiTrash } from 'react-icons/fi'

import { IconButton, Row, Tooltip } from 'Components/UI'

import { USER_ROLE } from 'Constants/ids'

import { useAppContext } from 'Hooks'

import { useScopedI18n } from 'Services/I18n'

import { useTableContext } from '../context'

function ActionsCell({ row }) {
  const editRef = useRef(null)
  const deleteRef = useRef(null)

  const s = useScopedI18n('skill.actions')

  const { me } = useAppContext()

  const { onEditRow, onDeleteRow } = useTableContext()

  const skill = row?.original

  const handleEdit = useCallback(() => {
    editRef.current.blur()
    onEditRow(skill)
  }, [onEditRow, skill])

  const handleDelete = useCallback(() => {
    deleteRef.current.blur()
    onDeleteRow(skill)
  }, [onDeleteRow, skill])

  const canInteract = me.role === USER_ROLE.SUPER_ADMIN

  if (!canInteract) {
    return null
  }

  return (
    <Row>
      <Tooltip content={s('edit')}>
        <IconButton mr={1} ref={editRef} small onClick={handleEdit}>
          <FiEdit3 />
        </IconButton>
      </Tooltip>

      <Tooltip content={s('delete')}>
        <IconButton icon ref={deleteRef} small onClick={handleDelete}>
          <FiTrash />
        </IconButton>
      </Tooltip>
    </Row>
  )
}

ActionsCell.defaultProps = {
  row: {},
}

ActionsCell.propTypes = {
  row: PropTypes.object,
}

export default ActionsCell
