import { useMemo } from 'react'

import Utils from 'Utils'

import upperFirst from 'lodash/upperFirst'

import { SEARCH_TYPES } from 'Constants/ids'

import { ActionsCell, CreatorCell, TagTypeCell } from './Cells'

const commonColumns = [
  {
    Header: 'Сreator',
    accessor: 'creator',
    Cell: CreatorCell,
    width: 1,
    disableSortBy: true,
  },
  {
    Header: 'Created at',
    accessor: 'createdAt',
    Cell: Utils.Table.renderDateTimeCell,
    width: 1,
  },
  {
    Header: 'Users',
    accessor: 'userCount',
    width: 1,
    disableSortBy: true,
  },
  {
    Header: 'Actions',
    Cell: ActionsCell,
    width: 0.5,
    cellRight: true,
    headerRight: true,
    disableSortBy: true,
  },
]

export function useColumns(nameTitle, tagType) {
  return useMemo(
    () =>
      tagType === SEARCH_TYPES.tag
        ? [
            {
              Header: `${upperFirst(nameTitle || tagType)}`,
              accessor: 'name',
              Cell: renderName,
              width: 1.5,
            },
            {
              Header: 'Tag type',
              Cell: TagTypeCell,
              width: 1,
              disableSortBy: true,
            },
            ...commonColumns,
          ]
        : [
            {
              Header: `${upperFirst(nameTitle || tagType)}`,
              accessor: 'name',
              Cell: renderName,
              width: 1.5,
            },

            ...commonColumns,
          ],
    [tagType, nameTitle],
  )
}

function renderName({ value }) {
  return value || '—'
}
