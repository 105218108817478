import React, { useCallback, useEffect, useState } from 'react'

import { useMutation } from '@apollo/client'
import { IconCheck } from '@tabler/icons-react'
import readAllNotificationsMutation from 'GraphQL/Mutations/Notification/readAllNotifications.graphql'
import notificationsQuery from 'GraphQL/Queries/Community/notifications.graphql'
import unreadNotificationsCountQuery from 'GraphQL/Queries/Community/unreadNotificationsCount.graphql'
import { readAllNotificationsUpdater } from 'GraphQL/Updaters/Notification'
import { unreadNotificationsDecreaseCountUpdater } from 'GraphQL/Updaters/UnreadNotificationsCount'

import { Divider, Pagination, Row, Table, Text } from 'Components/UI'

import { useCommunityContext, useEntityTable } from 'Hooks'

import { useQuery } from 'Services/Apollo'
import _, { useScopedI18n } from 'Services/I18n'
import toast from 'Services/Toast'

import { useColumns } from './columns'
import {
  Container,
  HeaderRow,
  MarkAllReadButton,
  ScrollContainer,
} from './styles'

function NotificationsTable() {
  const s = useScopedI18n('blocks.tables.notificationsTable')
  const { community } = useCommunityContext()

  const [mostRecentPage, setMostRecentPage] = useState(0)
  const [mostRecentLimit, setMostRecentLimit] = useState(10)

  const [readAllNotifications] = useMutation(readAllNotificationsMutation)

  const { data, loading } = useQuery(notificationsQuery, {
    variables: {
      communityId: community?.id,
      page: mostRecentPage,
      limit: mostRecentLimit,
    },
    fetchPolicy: 'network-only',
  })

  const { data: unreadNotificationsCountQueryData } = useQuery(
    unreadNotificationsCountQuery,
    {
      variables: {
        communityId: community?.id,
      },
      skip: !community?.id,
      fetchPolicy: 'network-only',
    },
  )

  const columns = useColumns()

  const entities = data?.notifications || {}

  const [tableProps, rowsCount] = useEntityTable({
    data: entities,
    columns,
  })
  const { pageSize, pageIndex } = tableProps.state

  const handleReadAll = useCallback(async () => {
    try {
      await readAllNotifications({
        variables: {
          communityId: community?.id,
        },
        update: store => {
          readAllNotificationsUpdater(
            store,
            community?.id,
            mostRecentLimit.current,
          )
          unreadNotificationsDecreaseCountUpdater(store, community?.id, 0)
        },
      })

      toast.success({
        title: s('messages.notifications.title'),
        text: s('messages.notifications.readAllSuccess'),
      })
    } catch (error) {
      toast.error({
        title: s('messages.notifications.title'),
        text: _(`error.${error?.message || 'generic'}`),
      })
    }
  }, [readAllNotifications, community, s, mostRecentLimit])

  useEffect(() => {
    setMostRecentPage(pageIndex)
  }, [pageIndex])

  useEffect(() => {
    setMostRecentPage(0)
    setMostRecentLimit(pageSize)
  }, [pageSize])

  const unreadCounter =
    unreadNotificationsCountQueryData?.unreadNotificationsCount?.count

  return (
    <Container>
      <HeaderRow>
        <Text header header3>
          {s('title')}
        </Text>

        {Boolean(unreadCounter) && unreadCounter > 0 && (
          <Row>
            <MarkAllReadButton onClick={handleReadAll}>
              <IconCheck size={16} />
              <Text actionMedium ml={2}>
                {s('allRead')}
              </Text>
            </MarkAllReadButton>
          </Row>
        )}
      </HeaderRow>

      <Divider />

      <ScrollContainer>
        <Table {...tableProps} loading={loading} />
      </ScrollContainer>

      <Divider />

      <Pagination
        loading={loading}
        px={5}
        py={3}
        showTotal={false}
        state={{ pageIndex, pageSize }}
        total={rowsCount}
        onPageIndexChange={tableProps?.gotoPage}
        onPageSizeChange={tableProps?.setPageSize}
      />
    </Container>
  )
}

export default NotificationsTable
