import { down } from 'styled-breakpoints'
import styled from 'styled-components'
import { themeGet } from '@styled-system/theme-get'

export const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: ${themeGet('space.2')}px ${themeGet('space.3')}px;
  border-radius: 6px;
  border: 1px solid ${themeGet('colors.divider.default')};

  svg {
    color: ${themeGet('colors.main')};
  }
`

export const HiddenInput = styled.input`
  border: none;
  outline: none;
  line-height: 21px;
  font-size: 14px;
  color: ${themeGet('colors.text.body')};
  padding-right: 20px;
  flex-grow: 1;

  ${down('md')} {
    display: none;
  }
`
