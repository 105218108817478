import styled from 'styled-components'
import { themeGet } from '@styled-system/theme-get'

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 400px;
  padding: ${themeGet('space.4')}px ${themeGet('space.4')}px
    ${themeGet('space.5')}px;
`
