import React, { useCallback, useMemo } from 'react'
import { Form } from 'react-final-form'
import PropTypes from 'prop-types'

import { useQuery } from '@apollo/client'
import updateCommunityUsersRoleMutation from 'GraphQL/Mutations/Community/updateCommunityUsersRole.graphql'
import communityRolesListQuery from 'GraphQL/Queries/Community/communityRolesList.graphql'
import { getFullName } from 'Utils/User'
import validate from 'validate.js'

import map from 'lodash/map'
import noop from 'lodash/noop'

import { Button, Column, Modal, Row, SelectField, Text } from 'Components/UI'
import DropdownIndicator from 'Components/UI/Form/Select/Components/DropdownIndicator'

import { useModal } from 'Hooks'

import { useAdminMutation } from 'Services/Apollo'
import { useScopedI18n } from 'Services/I18n'
import toast from 'Services/Toast'

const FIELD = {
  ROLE: 'role',
}

function entityToOption(entity) {
  return {
    label: entity.name,
    value: entity.id,
  }
}

function validateForm(values) {
  return validate(values, {
    [FIELD.ROLE]: {
      presence: true,
    },
  })
}

function UpdateCommunityUsersRole({
  communityId,
  content,
  isOpen,
  user,
  onRefetch,
  onClose,
  ...rest
}) {
  const s = useScopedI18n('modals.updateCommunityUsersRole')
  const [modalProps] = useModal(rest)

  const { data } = useQuery(communityRolesListQuery, {
    variables: {
      communityId,
      limit: 99,
    },
    skip: !communityId,
  })

  const initialValues = useMemo(
    () => ({
      [FIELD.ROLE]: {
        value: user?.communityRole?.id,
        label: user?.communityRole?.name,
      },
    }),
    [user],
  )

  const [updateCommunityUsersRole] = useAdminMutation(
    updateCommunityUsersRoleMutation,
  )

  const handleFinish = useCallback(
    async success => {
      onClose?.(success)
    },
    [onClose],
  )

  const handleCancel = useCallback(() => handleFinish(false), [handleFinish])

  const handleUpdate = useCallback(
    async values => {
      try {
        await updateCommunityUsersRole({
          variables: {
            communityId,
            userIds: [user?.id],
            communityRoleId: values[FIELD.ROLE].value,
          },
        })
        toast.success({
          title: s('title'),
          text: s('updateSuccess'),
        })

        onRefetch?.()
        await handleFinish(true)
      } catch (error) {
        toast.error({
          title: s('title'),
          text: error.message,
        })
        await handleFinish(false)
      }
    },
    [user, communityId, handleFinish, updateCommunityUsersRole, onRefetch, s],
  )

  const roles = data?.communityRolesList?.rows
  const options = useMemo(() => map(roles, entityToOption), [roles])

  return (
    <Modal
      {...modalProps}
      isOpen={isOpen}
      maxWidth="460px"
      title="Update user role"
      onClose={onClose}
    >
      <Column>
        <Text header header4 mb={3}>
          {s('content')} <span>{getFullName(user)}</span>?
        </Text>

        <Form
          initialValues={initialValues}
          render={({ handleSubmit, invalid }) => (
            <Column fullWidth>
              <Column>
                <SelectField
                  blurInputOnSelect
                  components={{
                    DropdownIndicator,
                  }}
                  fullWidth
                  label={s('form.label')}
                  name={FIELD.ROLE}
                  options={options}
                  placeholder={s('form.placeholder')}
                  withPortal
                />
              </Column>

              <Row mt={5} spaceBetween>
                <Button secondary small onClick={handleCancel}>
                  {s('actions.cancel')}
                </Button>

                <Button disabled={invalid} small onClick={handleSubmit}>
                  {s('actions.ok')}
                </Button>
              </Row>
            </Column>
          )}
          validate={validateForm}
          onSubmit={handleUpdate}
        />
      </Column>
    </Modal>
  )
}

UpdateCommunityUsersRole.defaultProps = {
  communityId: null,
  isOpen: false,
  user: null,
  onClose: noop,
  onRefetch: noop,
}

UpdateCommunityUsersRole.propTypes = {
  ...Modal.propTypes,
  communityId: PropTypes.string,
  isOpen: PropTypes.bool,
  user: PropTypes.object,
  onClose: PropTypes.func,
  onRefetch: PropTypes.func,
}

export default UpdateCommunityUsersRole
