import React, { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'

import { ConnectAccount, ConnectAccountButton } from 'Features/OAuth/Components'
import { ConnectAccountType } from 'Features/OAuth/Components/ConnectAccount'
import { ConnectAccountButtonSize } from 'Features/OAuth/Components/ConnectAccountButton'
import Utils from 'Utils'

import AdditionalEmails from 'Components/Blocks/OwnProfile/AdditionalEmails'
import ConnectedAccounts from 'Components/Blocks/OwnProfile/ConnectedAccounts'
import Section from 'Components/Blocks/OwnProfile/Section'
import { Button, Column, Icon, Row } from 'Components/UI'
import { Text } from 'Components/UI/_v2'

import { OAuthProvider } from 'Constants/mainGraphQL'

import { useAppContext } from 'Hooks'

import * as ROUTES from 'Router/routes'

import toast from 'Services/Toast'

import colors from 'Theme/_v2/colors'

import { Action, ConnectWrapper } from './styles'

export default function Step2() {
  const { slug } = useAppContext()
  const navigate = useNavigate()
  const onConnectError = useCallback((title: string, message: string) => {
    toast.error({
      title,
      text: message,
    })
  }, [])

  const communityDashboardPath = slug
    ? Utils.URL.generatePath(ROUTES.COMMUNITY_DASHBOARD, { slug })
    : ROUTES.COMMUNITY

  return (
    <>
      <ConnectWrapper>
        <ConnectAccount
          provider={OAuthProvider.Google}
          type={ConnectAccountType.Onboarding}
          onError={onConnectError}
        >
          {({ isLoading, provider, onClick, buttonLabel }) => (
            <Section title={'Connected Accounts'}>
              <Column gap={3}>
                <div>
                  <Row gap={4} spaceBetween>
                    <Row center gap={1}>
                      <Icon.Google size={24} />
                      <Text
                        color={colors.text.primary}
                        fontWeight={600}
                        variant="body-s"
                      >
                        Google
                      </Text>
                    </Row>
                    <ConnectAccountButton
                      isLoading={isLoading}
                      label={buttonLabel}
                      provider={provider}
                      size={ConnectAccountButtonSize.Small}
                      onClick={onClick}
                    />
                  </Row>
                </div>

                <ConnectedAccounts.List
                  hideLabels
                  hideScopes
                  // onClickItemReconnect={onClick}
                />
              </Column>
            </Section>
          )}
        </ConnectAccount>

        <Section title={'Additional Emails'}>
          <AdditionalEmails />
        </Section>
      </ConnectWrapper>
      <Action step={2}>
        <Button
          disabled={false}
          mt={5}
          secondary
          type="button"
          onClick={() => navigate(-1)}
        >
          Back
        </Button>
        <Button
          disabled={false}
          mt={5}
          type="button"
          onClick={() => navigate(communityDashboardPath)}
        >
          Continue
        </Button>
      </Action>
    </>
  )
}
