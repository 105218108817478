import styled, { css } from 'styled-components'
import { layout, margin, MarginProps, WidthProps } from 'styled-system'
import { themeGet } from '@styled-system/theme-get'

export interface IWrapper extends MarginProps, WidthProps {
  isDragActive?: boolean
}

export const Wrapper = styled.div<IWrapper>`
  outline: none;
  cursor: pointer;

  ${props =>
    props.isDragActive &&
    css`
      border-color: ${themeGet('colors.positive')};
      cursor: grabbing;
    `};

  ${layout}
  ${margin}
`

const styledButtonDisabledCss = ({ disabled }: { disabled?: boolean }) =>
  disabled &&
  css`
    color: ${themeGet('colors.text.disabled')};
    cursor: default;
  `

const styledButtonDangerCss = ({ danger }: { danger?: boolean }) =>
  danger &&
  css`
    color: ${themeGet('colors.error')};
  `

export interface IStyledButton {
  danger?: boolean
  disabled?: boolean
}

export const StyledButton = styled.button<IStyledButton>`
  display: flex;
  flex-shrink: 0;
  align-items: center;
  gap: ${themeGet('space.2')}px;
  background: none;
  outline: none;
  border: none;
  cursor: pointer;
  white-space: nowrap;
  color: ${themeGet('colors.info')};
  padding: 0;
  font-size: ${themeGet('fontSize.2')}px;
  font-weight: ${themeGet('fontWeight.1')};

  > svg {
    width: 16px;
    height: 16px;
  }

  ${styledButtonDangerCss}
  ${styledButtonDisabledCss}
`
