import React from 'react'
import { components } from 'react-select'

import { Indicators } from './styles'

const IndicatorsContainer: typeof components.IndicatorsContainer = props => {
  // TODO: find a better way to satisfy the type
  const StyledIndicators = Indicators as any
  return <StyledIndicators {...props} as={components.IndicatorsContainer} />
}

export default IndicatorsContainer
