import React from 'react'

import AvatarImage from 'Assets/Images/bg.jpg'

import { Column, ImageInput, Text } from 'Components/UI'

function ImageInputSection() {
  return (
    <Column gap={6}>
      <Text header1>Image Input</Text>
      <Column gap={5}>
        <ImageInput />
        <ImageInput value={AvatarImage} />
      </Column>
    </Column>
  )
}

export default ImageInputSection
