import React from 'react'
import { Row } from 'react-table'

import useAbility from 'Features/Notes/useAbility'

import { Checkbox, Tooltip } from 'Components/UI'

import _ from 'Services/I18n'

import { useTableContext } from '../context'

export interface INoteSelectProps {
  row: Row<MainSchema.Note>
}

function NoteSelect({ row }: INoteSelectProps) {
  const { onSelectNote, selectedNotesIds } = useTableContext()
  const { canDelete } = useAbility()

  const note = row.original
  const isChecked = selectedNotesIds.includes(note.id)
  const isDisabled = !canDelete(note)

  if (isDisabled)
    return (
      <Tooltip
        appendTo={document.body}
        content={_('accountManagement.tooltips.noteAllowedToDeleteNote')}
        placement="top-end"
        withArrow
      >
        <Checkbox
          checked={isChecked}
          disabled
          rounded
          secondary
          small
          onChange={() => onSelectNote(row.original)}
        />
      </Tooltip>
    )

  return (
    <Checkbox
      checked={isChecked}
      rounded
      secondary
      small
      onChange={() => onSelectNote(row.original)}
    />
  )
}

export default NoteSelect
