import React, { PropsWithChildren } from 'react'
import ReactModal from 'react-modal'

import { IconX } from '@tabler/icons-react'

import pick from 'lodash/pick'

import { Button } from 'Components/UI/Button'
import { Row } from 'Components/UI/Flex'
import Text from 'Components/UI/Text'

import {
  CloseButton,
  Content,
  ContentWrapper,
  Divider,
  IContentWrapper,
  StyledModal,
} from './styles'

ReactModal.setAppElement('body')

export interface IModal extends IContentWrapper, PropsWithChildren {
  cancelText?: string
  confirmDisabled?: boolean
  confirmText?: string
  isOpen?: boolean
  noScroll?: boolean
  shouldCloseOnEsc?: boolean
  shouldCloseOnOverlayClick?: boolean
  shouldReturnFocusAfterClose?: boolean
  title?: string
  width?: string | string[] | number
  glyphIcon?: React.ReactNode
  onClose?: () => void
  onConfirm?: () => void
}

function Modal({
  cancelText,
  children,
  confirmDisabled,
  confirmText,
  isOpen = false,
  noScroll = false,
  shouldCloseOnEsc = true,
  shouldCloseOnOverlayClick = true,
  shouldReturnFocusAfterClose = true,
  title,
  width,
  glyphIcon,
  onClose,
  onConfirm,
  ...rest
}: IModal) {
  if (!isOpen) {
    return null
  }

  return (
    <StyledModal
      isOpen={isOpen}
      shouldCloseOnEsc={shouldCloseOnEsc}
      shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
      shouldReturnFocusAfterClose={shouldReturnFocusAfterClose}
      width={width}
      onRequestClose={onClose}
    >
      <Row center fullWidth px={4} py={4} spaceBetween>
        <Row center gap={3}>
          {glyphIcon && <div>{glyphIcon}</div>}
          {title && (
            <Text header header3>
              {title}
            </Text>
          )}
        </Row>

        <CloseButton onClick={onClose}>
          <IconX />
        </CloseButton>
      </Row>

      <Divider />

      <ContentWrapper {...pick(rest)}>
        <Content noScroll={noScroll}>{children}</Content>
      </ContentWrapper>

      {(confirmText || cancelText) && (
        <>
          <Divider />
          <Row gap={3} justifyEnd px={4} py={4}>
            {cancelText && (
              <Button secondary onClick={onClose}>
                {cancelText}
              </Button>
            )}
            {confirmText && (
              <Button disabled={confirmDisabled} onClick={onConfirm}>
                {confirmText}
              </Button>
            )}
          </Row>
        </>
      )}
    </StyledModal>
  )
}

const PROPS = ['isOpen', 'title', 'onClose']

Modal.pickProps = (props: any) => pick(props, PROPS)

export default Modal
