import styled from 'styled-components'

export interface ICircle {
  backgroundColor?: string
}

export const Circle = styled.div<ICircle>`
  background-color: ${props => props.backgroundColor || '#a9a9a9'};
  border-radius: 21px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  font-weight: 700;
  font-size: 11px;
  line-height: 13px;
  text-align: center;
  color: #ffffff;
  flex-shrink: 0;
`
