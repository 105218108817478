import { useMemo } from 'react'

import Utils from 'Utils'

import { ActionsCell, StateCell } from './Cells'

export function useColumns() {
  return useMemo(
    () => [
      {
        Header: 'Creator',
        accessor: 'creator',
        Cell: Utils.Table.renderProfileFullName,
        width: 1,
        disableSortBy: true,
      },
      {
        Header: 'Community',
        accessor: 'community',
        Cell: Utils.Table.renderCommunityName,
        width: 1,
        disableSortBy: true,
      },
      {
        Header: 'Hub',
        accessor: 'hubId',
        width: 0.5,
        disableSortBy: true,
      },
      {
        Header: 'Hub Domain',
        accessor: 'domain',
        width: 2,
        disableSortBy: true,
      },
      {
        Header: 'Status',
        accessor: 'state',
        Cell: StateCell,
        width: 0.5,
        cellRight: true,
        headerRight: true,
      },
      {
        Header: 'Actions',
        Cell: ActionsCell,
        width: 0.5,
        cellRight: true,
        headerRight: true,
      },
    ],
    [],
  )
}
