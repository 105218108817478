import React, { useMemo } from 'react'

import { IconSquareRounded } from '@tabler/icons-react'

import { Column, Link, Row, Text } from 'Components/UI'

const TEXT_EXAMPLE = 'Link'

function Links() {
  const linkContent = useMemo(
    () => (
      <>
        <IconSquareRounded />
        {TEXT_EXAMPLE}
        <IconSquareRounded />
      </>
    ),
    [],
  )

  return (
    <Column gap={6}>
      <Text header1>Links</Text>
      <Row gap={5} wrapped>
        <Column gap={5}>
          <Text header2 primary80>
            Primary
          </Text>
          <Row gap={4}>
            <Column gap={3}>
              <Link href="https://www.google.com/" isExternal large>
                <IconSquareRounded />
                Google
                <IconSquareRounded />
              </Link>
              <Link href="https://www.google.com/">{linkContent}</Link>
              <Link href="https://www.google.com/" small>
                {linkContent}
              </Link>
            </Column>
            <Column gap={3}>
              <Link disabled href="https://www.google.com/" large>
                {linkContent}
              </Link>
              <Link disabled href="https://www.google.com/">
                {linkContent}
              </Link>
              <Link disabled href="https://www.google.com/" small>
                {linkContent}
              </Link>
            </Column>
          </Row>
        </Column>

        <Column gap={5}>
          <Text header2 primary80>
            Secondary
          </Text>
          <Row gap={4}>
            <Column gap={3}>
              <Link href="https://www.google.com/" isExternal large secondary>
                <IconSquareRounded />
                isExternal
                <IconSquareRounded />
              </Link>
              <Link href="https://www.google.com/" secondary>
                {linkContent}
              </Link>
              <Link href="https://www.google.com/" secondary small>
                {linkContent}
              </Link>
            </Column>
            <Column gap={3}>
              <Link disabled href="https://www.google.com/" large secondary>
                {linkContent}
              </Link>
              <Link disabled href="https://www.google.com/" secondary>
                {linkContent}
              </Link>
              <Link disabled href="https://www.google.com/" secondary small>
                {linkContent}
              </Link>
            </Column>
          </Row>
        </Column>

        <Column gap={5}>
          <Text header2 primary80>
            Danger
          </Text>
          <Row gap={4}>
            <Column gap={3}>
              <Link danger href="https://www.google.com/" large>
                {linkContent}
              </Link>
              <Link danger href="https://www.google.com/">
                {linkContent}
              </Link>
              <Link danger href="https://www.google.com/" small>
                {linkContent}
              </Link>
            </Column>
            <Column gap={3}>
              <Link danger disabled href="https://www.google.com/" large>
                {linkContent}
              </Link>
              <Link danger disabled href="https://www.google.com/">
                {linkContent}
              </Link>
              <Link danger disabled href="https://www.google.com/" small>
                {linkContent}
              </Link>
            </Column>
          </Row>
        </Column>

        <Column gap={5}>
          <Text header2 primary80>
            Success
          </Text>
          <Row gap={4}>
            <Column gap={3}>
              <Link href="https://www.google.com/" large success>
                {linkContent}
              </Link>
              <Link href="https://www.google.com/" success>
                {linkContent}
              </Link>
              <Link href="https://www.google.com/" small success>
                {linkContent}
              </Link>
            </Column>
            <Column gap={3}>
              <Link disabled href="https://www.google.com/" large success>
                {linkContent}
              </Link>
              <Link disabled href="https://www.google.com/" success>
                {linkContent}
              </Link>
              <Link disabled href="https://www.google.com/" small success>
                {linkContent}
              </Link>
            </Column>
          </Row>
        </Column>

        <Column gap={5}>
          <Text header2 primary80>
            Info
          </Text>
          <Row gap={4}>
            <Column gap={3}>
              <Link href="https://www.google.com/" info large>
                {linkContent}
              </Link>
              <Link href="https://www.google.com/" info>
                {linkContent}
              </Link>
              <Link href="https://www.google.com/" info small>
                {linkContent}
              </Link>
            </Column>
            <Column gap={3}>
              <Link disabled href="https://www.google.com/" info large>
                {linkContent}
              </Link>
              <Link disabled href="https://www.google.com/" info>
                {linkContent}
              </Link>
              <Link disabled href="https://www.google.com/" info small>
                {linkContent}
              </Link>
            </Column>
          </Row>
        </Column>
      </Row>
    </Column>
  )
}

export default Links
