import React, { useCallback, useMemo, useState } from 'react'
import {
  DateRange,
  DayClickEventHandler,
  DayModifiers,
  DayMouseEventHandler,
} from 'react-day-picker'

import { StyledPicker } from './styles'

function isSelectingFirstDay(day: Date, value: DateRange | undefined) {
  const from = value?.from
  const to = value?.to
  const isBeforeFirstDay = from && day < from
  const isRangeSelected = from && to
  return !from || isBeforeFirstDay || isRangeSelected
}

export interface IDateRangePickerProps {
  value?: DateRange
  onChange?: (value: DateRange) => void
}

function DateRangePicker({ value, onChange }: IDateRangePickerProps) {
  const [innerValue, setInnerValue] = useState<DateRange | undefined>(
    value?.from && value?.to ? value : undefined,
  )
  const [enteredTo, setEnteredTo] = useState(value?.to)

  const handleDayClick = useCallback<DayClickEventHandler>(
    (day, modifiers) => {
      if (modifiers?.disabled || !day) {
        return
      }

      if (isSelectingFirstDay(day, innerValue)) {
        setInnerValue({ from: day, to: undefined })
        setEnteredTo(undefined)
      } else {
        setInnerValue({ from: innerValue?.from, to: day })
        setEnteredTo(day)

        if (innerValue?.from) onChange?.({ ...innerValue, to: day })
      }
    },
    [innerValue, onChange],
  )

  const handleDayEnter = useCallback<DayMouseEventHandler>(
    day => {
      if (isSelectingFirstDay(day, innerValue)) {
        return
      }

      setEnteredTo(day)
    },
    [innerValue],
  )

  const modifiers = useMemo<DayModifiers>(
    () => ({
      start: innerValue?.from ?? false,
      end: enteredTo ?? false,
    }),
    [innerValue, enteredTo],
  )

  return (
    <StyledPicker
      mode="range"
      modifiers={modifiers}
      modifiersClassNames={{
        selected: 'rdp-day_single_selected',
      }}
      selected={innerValue}
      showOutsideDays
      onDayClick={handleDayClick}
      onDayMouseEnter={handleDayEnter}
    />
  )
}

export default DateRangePicker
