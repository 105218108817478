import React, { useCallback, useRef, useState } from 'react'

import { pick } from '@styled-system/props'

import useOnClickOutside from 'Hooks/useOnClickOutside'

import {
  ArrowIcon,
  Container,
  DropdownContainer,
  IContainer,
  Placeholder,
  Value,
  ValueContainer,
} from './styles'

export interface ISelectAccordion extends Omit<IContainer, '$opened'> {
  value?: string
  placeholder?: string
  renderDropdown: ({
    handleSelect,
  }: {
    handleSelect: (selected: any) => void
  }) => React.ReactNode
  onSelect?: (selected: any) => void
}

function SelectAccordion({
  value,
  placeholder,
  renderDropdown,
  onSelect,
  ...rest
}: ISelectAccordion) {
  const ref = useRef<HTMLDivElement>(null)

  const [isOpened, setOpened] = useState(false)

  useOnClickOutside(ref, () => setOpened(false))

  const handleSelect = useCallback(
    (selected: any) => {
      onSelect?.(selected)
      setOpened(false)
    },
    [onSelect],
  )

  return (
    <Container {...pick(rest)} $opened={isOpened} ref={ref}>
      <ValueContainer onClick={() => setOpened(prevState => !prevState)}>
        {value ? (
          <Value>{value}</Value>
        ) : (
          <Placeholder>{placeholder}</Placeholder>
        )}

        <ArrowIcon />
      </ValueContainer>

      {isOpened && (
        <DropdownContainer>
          {renderDropdown({
            handleSelect,
          })}
        </DropdownContainer>
      )}
    </Container>
  )
}

export default SelectAccordion
