import { down } from 'styled-breakpoints'
import styled from 'styled-components'
import { margin, MarginProps } from 'styled-system'
import { themeGet } from '@styled-system/theme-get'

import { Content as BaseContent } from 'Components/Blocks/Modals/styles'

export const Content = styled(BaseContent)`
  word-break: break-word;
  width: 557px;
  height: 250px;
  padding: 16px 24px;
  text-align: start;
  align-items: initial;

  p {
    span {
      font-weight: ${themeGet('fontWeights.3')};
    }
  }

  ${down('md')} {
    width: auto;
  }
`

export interface IFormContentProps extends MarginProps {}

export const FormContent = styled.form<IFormContentProps>`
  width: 100%;

  ${margin};
`
