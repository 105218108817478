import React from 'react'

import { FiPlus } from 'react-icons/fi'

import { pick } from '@styled-system/props'

import Avatar from 'Components/Blocks/Avatar'
import { Text } from 'Components/UI'

import { DashedCircle, IUserContainerProps, UserContainer } from './styles'

interface UserProps extends IUserContainerProps {
  id?: string
  imageUrl?: string
  fullName?: string
  onAddUserClick?: () => void
}

function User({ id, imageUrl, fullName, onAddUserClick, ...rest }: UserProps) {
  return (
    <UserContainer {...pick(rest)} onClick={onAddUserClick}>
      {id ? (
        <>
          <Avatar borderRadius={100} size={40} src={imageUrl} />
          <Text bold maxLines={1} ml={3} primary>
            {fullName}
          </Text>
        </>
      ) : (
        <>
          <DashedCircle>
            <FiPlus />
          </DashedCircle>
          <Text ml={3} secondary>
            Select user
          </Text>
        </>
      )}
    </UserContainer>
  )
}

export default User
