import styled, { css } from 'styled-components'
import { mapToTheme } from 'styled-map'
import {
  flexShrink,
  FlexShrinkProps,
  layout,
  LayoutProps,
  space,
  SpaceProps,
} from 'styled-system'
import { themeGet } from '@styled-system/theme-get'

export interface IFullWidthCssProps {
  fullWidth?: boolean
}

const fullWidthCss = ({ fullWidth }: IFullWidthCssProps) =>
  fullWidth &&
  css`
    width: 100%;
  `

export interface IWrapperProps
  extends SpaceProps,
    LayoutProps,
    FlexShrinkProps,
    IFullWidthCssProps {}

export const Wrapper = styled.div<IWrapperProps>`
  position: relative;
  min-height: ${themeGet('inputs.height.default')}px;

  ${space}
  ${layout}
  ${flexShrink};

  ${fullWidthCss}
`

export const ErrorWrapper = styled.div`
  color: ${themeGet('colors.error')};
  font-size: 12px;
  padding: 0 12px;
  margin-top: 8px;
  line-height: 1.2;
`

export const RequiredMark = styled.span`
  margin-left: 1px;
  font-size: 12px;
  color: ${mapToTheme('colors.mineShaft')};
`
