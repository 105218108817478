import React from 'react'
import { Field } from 'react-final-form'
import { GroupBase } from 'react-select'

import { pick } from '@styled-system/props'

import Utils from 'Utils'

import Select, { ISelect } from './Select'

export interface ISelectField<
  Option,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>,
  Additional = unknown,
> extends ISelect<Option, IsMulti, Group, Additional> {
  checkErrorIfDirty?: boolean
  displayError?: boolean
  format?: any
  initialValue?: any
  name: string
  parse?: any
  validateFields?: string[]
}

function SelectField<
  Option,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>,
  Additional = unknown,
>({
  caption,
  checkErrorIfDirty,
  displayError = true,
  format,
  initialValue,
  name,
  parse,
  validateFields,
  ...rest
}: ISelectField<Option, IsMulti, Group, Additional>) {
  return (
    <Field
      format={format}
      initialValue={initialValue}
      name={name}
      parse={parse}
      validateFields={validateFields}
    >
      {({
        input: { value, onChange: handleChange, onBlur, onFocus },
        meta,
      }) => {
        const { hasError, error } = Utils.Form.hasError(meta, checkErrorIfDirty)

        return (
          <Select<Option, IsMulti, Group, Additional>
            {...pick(rest)}
            {...rest}
            caption={(displayError && error) || caption}
            error={hasError}
            value={value}
            onBlur={onBlur}
            onChange={handleChange}
            onFocus={onFocus}
          />
        )
      }}
    </Field>
  )
}

export default SelectField
