import { css } from 'styled-components'
import { themeGet } from '@styled-system/theme-get'

export default css`
  --size: 4px;

  scrollbar-width: var(--size);
  scrollbar-color: ${themeGet('colors.alto')} transparent;

  ::-webkit-scrollbar {
    width: var(--size);
    height: var(--size);
  }

  ::-webkit-scrollbar-corner {
    background: none;
  }

  ::-webkit-scrollbar-track {
    height: var(--size);
    width: var(--size);
    border-radius: calc(var(--size) / 2);
    background-color: transparent;
  }

  ::-webkit-scrollbar-thumb {
    height: var(--size);
    width: var(--size);
    border-radius: calc(var(--size) / 2);
    background-color: ${themeGet('colors.alto')};
  }

  ::-webkit-scrollbar-thumb:hover {
    background: ${themeGet('colors.grayBoulder')};
  }
`
