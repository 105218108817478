import React, { useState } from 'react'

import { useTheme } from 'styled-components'

import { Bar, BarChart, CartesianGrid, Cell, XAxis, YAxis } from 'recharts'

import isEmpty from 'lodash/isEmpty'

import { Flex, Loader, Row, Text } from 'Components/UI'

import { Content, TopResultItem, TopResultList } from './styles'

export interface ITopResultProps {
  loading?: boolean
  skills?: MainSchema.TopSearchedSkill[]
  small?: boolean
}

function TopResult({
  small = false,
  skills = [],
  loading = false,
}: ITopResultProps) {
  const [bar, setBar] = useState<string | null>(null)
  const theme = useTheme()

  if (loading) return <Loader absolute text="Loading..." />

  return (
    <>
      <Content>
        <Text bolder fontSize={18} mineShaft>
          Top Search Results
        </Text>
      </Content>
      {isEmpty(skills) ? (
        <Row center height={220} justifyCenter>
          No data
        </Row>
      ) : (
        <Flex alignItems="flex-start">
          <BarChart
            data={skills}
            height={200}
            margin={{ left: -30 }}
            width={!small ? 300 : 194}
          >
            <CartesianGrid
              stroke={theme.colors.grayWithWhite}
              strokeDasharray="3 3"
              vertical={false}
            />
            <XAxis dataKey="count" padding={{ left: 10, right: 25 }} />
            <YAxis />
            <Bar
              barSize={15}
              dataKey="count"
              radius={[5, 5, 0, 0]}
              onMouseOut={() => setBar(null)}
              onMouseOver={e => setBar(e.name)}
            >
              {skills.map(({ skill }, index) => (
                <Cell
                  fill={
                    skill === bar ? theme.colors.darkenBlue : theme.colors.blue
                  }
                  key={
                    // eslint-disable-next-line react/no-array-index-key
                    index
                  }
                />
              ))}
            </Bar>
          </BarChart>

          <TopResultList ml={!small ? 7 : 5}>
            {skills.map(({ skill }, index) => (
              <Flex
                alignItems="center"
                justifyContent="flex-start"
                key={
                  // eslint-disable-next-line react/no-array-index-key
                  index
                }
                mb={index !== skills.length - 1 ? 4 : 0}
              >
                <TopResultItem
                  onMouseOut={() => setBar(null)}
                  onMouseOver={() => setBar(skill)}
                >
                  {index + 1}. {skill}
                </TopResultItem>
              </Flex>
            ))}
          </TopResultList>
        </Flex>
      )}
    </>
  )
}

export default TopResult
