import isUrl from 'is-url'
import { Editor, Element, Range, Transforms } from 'slate'
import { CustomEditor, ElementType, LinkElement } from 'Types/slate'

function isLinkActive(editor: CustomEditor) {
  const [link] = Editor.nodes(editor, {
    match: n => Element.isElementType(n, ElementType.Link),
  })
  return !!link
}

export function unwrapLink(editor: CustomEditor) {
  Transforms.unwrapNodes(editor, {
    match: n => Element.isElementType(n, ElementType.Link),
  })
}

function wrapLink(editor: CustomEditor, url: string, className?: string) {
  if (isLinkActive(editor)) {
    unwrapLink(editor)
  }

  const { selection } = editor
  const isCollapsed = selection && Range.isCollapsed(selection)

  const link: LinkElement = {
    type: ElementType.Link,
    url,
    className,
    children: isCollapsed ? [{ text: url }] : [],
  }

  if (isCollapsed) {
    Transforms.insertNodes(editor, link)
  } else {
    Transforms.wrapNodes(editor, link, { split: true })
    Transforms.collapse(editor, { edge: 'end' })
  }
}

export function insertLink(
  editor: CustomEditor,
  url: string,
  className?: string,
) {
  if (editor.selection) {
    wrapLink(editor, url, className)
  }
}

export default function withLinks<T extends CustomEditor>(editor: T): T {
  const { insertData, insertText, isInline } = editor

  // eslint-disable-next-line no-param-reassign
  editor.isInline = element =>
    element.type === ElementType.Link ? true : isInline(element)

  // eslint-disable-next-line no-param-reassign
  editor.insertText = text => {
    if (text && isUrl(text)) {
      wrapLink(editor, text)
    } else {
      insertText(text)
    }
  }

  // eslint-disable-next-line no-param-reassign
  editor.insertData = data => {
    const text = data.getData('text/plain')

    if (text && isUrl(text)) {
      wrapLink(editor, text)
    } else {
      insertData(data)
    }
  }

  return editor
}
