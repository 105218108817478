import { createContext } from 'react'

import { AnyAbility } from '@casl/ability'

import PermissionService from '.'

export interface IPermissionContext extends AnyAbility {}

export default createContext<IPermissionContext>(
  PermissionService.createAbility([]),
)
