import React from 'react'

import { Column, Input, Row, Text } from 'Components/UI'

function TextAreas() {
  return (
    <Column gap={6}>
      <Text header1>Text Areas</Text>
      <Row fullWidth gap={5}>
        <Input
          caption="Caption"
          infoText="Info text"
          label="Label"
          placeholder="Large"
          textArea
        />
        <Input
          caption="Caption"
          label="Label"
          placeholder="Small"
          small
          textArea
        />
        <Input
          caption="Caption"
          disabled
          label="Label"
          placeholder="Small - Disabled"
          small
          textArea
        />
      </Row>
    </Column>
  )
}

export default TextAreas
