import React, { MouseEvent, useCallback } from 'react'

import { pick } from '@styled-system/props'

import noop from 'lodash/noop'

import {
  AvatarEmptyIcon,
  Container,
  IContainer,
  StyledImage,
  StyledText,
} from './styles'

export interface IAvatar extends IContainer {
  src?: string
  text?: string
}

function Avatar({
  square,
  extraSmall,
  src,
  text,
  profileInput,
  small,
  large,
  isHeader,
  onClick,
  ...rest
}: IAvatar) {
  const handleClick = useCallback(
    (event: MouseEvent<HTMLDivElement>) => {
      onClick?.(event)
    },
    [onClick],
  )

  let contents = <AvatarEmptyIcon />

  if (src) {
    contents = <StyledImage src={src} />
  } else if (text) {
    contents = <StyledText>{text}</StyledText>
  }

  return (
    <Container
      {...pick(rest)}
      bordered={!src && !text}
      clickable={onClick !== noop}
      extraSmall={extraSmall}
      isHeader={isHeader}
      large={large}
      profileInput={profileInput}
      small={small}
      square={square}
      onClick={handleClick}
    >
      {contents}
    </Container>
  )
}

export default Avatar
