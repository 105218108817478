export interface IButtonSizes {
  large?: number
  default?: number
  small?: number
  extraSmall?: number
  smallSquare?: number
  mediumSquare?: number
}

export interface IButtons {
  height: IButtonSizes
  minWidth: IButtonSizes
  borderRadius: IButtonSizes
  fontSize: IButtonSizes
  iconSize: IButtonSizes
}

const buttons: IButtons = {
  height: {
    large: 48,
    default: 40,
    small: 32,
    extraSmall: 20,
    smallSquare: 30,
    mediumSquare: 40,
  },

  minWidth: { large: 150, default: 120, small: 100 },

  borderRadius: {
    large: 24,
    default: 20,
    small: 16,
    extraSmall: 10,
    smallSquare: 4,
    mediumSquare: 4,
  },

  fontSize: { large: 16, default: 14, small: 12 },

  iconSize: {
    large: 20,
    default: 16,
    small: 14,
    extraSmall: 12,
    mediumSquare: 20,
  },
}

export default buttons
