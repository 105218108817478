import styled from 'styled-components'
import { mapToTheme } from 'styled-map'
import { themeGet } from '@styled-system/theme-get'

import { TagColorKind } from 'Constants/tags'

export interface IContainer {
  colorKind: TagColorKind
}

export const Container = styled.div<IContainer>`
  display: flex;
  align-items: center;
  height: 100%;
  background-color: ${props => themeGet(`colors.tag.${props.colorKind}.bg`)};
  padding-right: ${themeGet('space.2')}px;
  border-top-right-radius: ${mapToTheme('tags.borderRadius')}px;
  border-bottom-right-radius: ${mapToTheme('tags.borderRadius')}px;
  transform: translateX(-4px);

  & > svg {
    width: 12px !important;
    height: 12px !important;
    color: ${props => themeGet(`colors.tag.${props.colorKind}.color`)};
  }
`
