import {
  PERMISSION_ACTION,
  PERMISSION_SCOPES,
  PERMISSION_SUBJECT,
} from 'Constants/permissions'

import { useAppContext, usePermission } from 'Hooks'

const useUserPermissions = () => {
  const { can } = usePermission()
  const { me } = useAppContext()

  const isMe = (user: MainSchema.CommunityUser) => user.id === me?.id
  const isCreator = (user: MainSchema.CommunityUser) => user.creator === me?.id

  const canEditCreator = can(
    PERMISSION_ACTION.EDIT,
    PERMISSION_SUBJECT.ACCOUNT,
    PERMISSION_SCOPES.ACCOUNT_OWN_CREATOR,
  )

  const canEditUserIfCreator = (user: MainSchema.CommunityUser) =>
    isCreator(user) && canEditCreator

  const canEditNotCreator = can(
    PERMISSION_ACTION.EDIT,
    PERMISSION_SUBJECT.ACCOUNT,
    PERMISSION_SCOPES.ACCOUNT_OTHER_CREATOR,
  )

  const canEditUserIfNotCreator = (user: MainSchema.CommunityUser) =>
    !isCreator(user) && canEditNotCreator

  const canEditAnyAccount = can(
    PERMISSION_ACTION.EDIT,
    PERMISSION_SUBJECT.ACCOUNT,
    PERMISSION_SCOPES.ACCOUNT_PRIVATE,
  )

  const canEditUser = (user: MainSchema.CommunityUser) =>
    isMe(user) ||
    isCreator(user) ||
    canEditUserIfCreator(user) ||
    canEditUserIfNotCreator(user) ||
    canEditAnyAccount

  return {
    canEditUser,
  }
}

export default useUserPermissions
