import { ApolloLink, Observable } from '@apollo/client'
import { print } from 'graphql'
import { createClient } from 'graphql-ws'

import { API } from 'Config'

import { setWebSocketLink } from 'Services/Shared'
import { getAccessToken } from 'Services/Store/auth'

class WebSocketLink extends ApolloLink {
  client

  constructor(path) {
    super()

    this.token = getAccessToken()

    this.client = createClient({
      url: `${API.WS}/${path}`,
      on: {
        connected: socket => {
          this.socket = socket
        },
        closed: () => {
          this.socket = null
        },
      },
      connectionParams: () => {
        const token = getAccessToken()
        return token ? { Authorization: `Bearer ${token}` } : {}
      },
    })

    setWebSocketLink(this)
  }

  request(operation) {
    return new Observable(observer =>
      this.client.subscribe(
        { ...operation, query: print(operation.query) },
        {
          next: observer.next.bind(observer),
          complete: observer.complete.bind(observer),
          error: err => {
            if (err instanceof Error) {
              observer.error(err)
            } else if (err instanceof CloseEvent) {
              observer.error(
                new Error(
                  `Socket closed with event ${err.code}: ${err.reason || ''}`,
                ),
              )
            } else {
              observer.error(
                new Error(
                  typeof err?.map === 'function'
                    ? err?.map(({ message }) => message).join(', ')
                    : 'An unknown error has occurred',
                ),
              )
            }
          },
        },
      ),
    )
  }

  async onTokenChanged(token) {
    if (this.socket && this.token !== token) {
      this.token = token
      this.socket.close(4205, 'Client Restart')
    }
  }
}

export default WebSocketLink
