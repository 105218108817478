import * as FormUtils from './Form'
import * as MeUtils from './Me'
import * as ObjectUtils from './Object'
import * as OptionsUtils from './Options'
import * as PromiseUtils from './Promises'
import * as SearchUtils from './Search'
import * as StringsUtils from './Strings'
import * as TableUtils from './Table'
import * as TagUtils from './Tag'
import * as URLUtils from './URL'
import * as UserUtils from './User'

const Utils = {
  Form: FormUtils,
  Me: MeUtils,
  Object: ObjectUtils,
  Promise: PromiseUtils,
  Strings: StringsUtils,
  Table: TableUtils,
  URL: URLUtils,
  User: UserUtils,
  Options: OptionsUtils,
  Tag: TagUtils,
  Search: SearchUtils,
}

export default Utils
