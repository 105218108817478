import styled, { css } from 'styled-components'
import { themeGet } from '@styled-system/theme-get'

export interface IPrimaryFocusCssProps {
  primaryFocus?: boolean
}

const primaryFocusCss = ({ primaryFocus }: IPrimaryFocusCssProps) =>
  primaryFocus &&
  css`
    box-shadow: ${themeGet('shadows.primaryFocus')};
  `

export interface ISecondaryFocusCssProps {
  secondaryFocus?: boolean
}

const secondaryFocusCss = ({ secondaryFocus }: ISecondaryFocusCssProps) =>
  secondaryFocus &&
  css`
    box-shadow: ${themeGet('shadows.secondaryFocus')};
  `

export interface IDangerFocusCssProps {
  dangerFocus?: boolean
}

const dangerFocusCss = ({ dangerFocus }: IDangerFocusCssProps) =>
  dangerFocus &&
  css`
    box-shadow: ${themeGet('shadows.dangerFocus')};
  `

export interface IBoxProps
  extends IPrimaryFocusCssProps,
    ISecondaryFocusCssProps,
    IDangerFocusCssProps {}

export const Box = styled.div<IBoxProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 150px;
  height: 100px;
  border-radius: 4px;
  box-shadow: ${themeGet('shadows.box')};

  ${primaryFocusCss}
  ${secondaryFocusCss}
  ${dangerFocusCss}
`
