import styled from 'styled-components'

import { Content as BaseContent } from '../styles'

export const Content = styled(BaseContent)`
  word-break: break-word;
  width: 100%;
  padding: 18px 24px 0;
  text-align: start;
  align-items: initial;
`
