import loadable from '@loadable/component'

import OAuthConnectView from './OAuth/Connect/index'
import AcceptCommunityInvite from './AcceptCommunityInvite'
import Nylas from './Nylas'
import Redirector from './Redirector'
import VerifyEmail from './VerifyEmail'

const Public = {
  Redirector,
  Root: loadable(() => import('./Root' /* webpackChunkName: "public-root" */)),
  Nylas,
  OAuthConnectView,
  AcceptCommunityInvite,
  VerifyEmail,
}

export default Public
