import styled, { css } from 'styled-components'
import { themeGet } from '@styled-system/theme-get'

export interface IContainerWrappedCssProps {
  wrapped?: boolean
}

const containerWrappedCss = ({ wrapped }: IContainerWrappedCssProps) =>
  wrapped &&
  css`
    flex-wrap: wrap;
  `

export interface IContainerProps extends IContainerWrappedCssProps {}

export const Container = styled.div<IContainerProps>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: ${themeGet('space.4')}px ${themeGet('space.5')}px;
  gap: ${themeGet('space.3')}px;
  width: 100%;
  flex-shrink: 0;

  ${containerWrappedCss}
`
