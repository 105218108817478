import React, { PropsWithChildren } from 'react'

import { pick } from '@styled-system/props'

import { Container, Content, IContainer } from './styles'

export interface IContentCard extends PropsWithChildren, IContainer {}

function ContentCard({ children, ...rest }: IContentCard) {
  return (
    <Container {...pick(rest)}>
      <Content>{children}</Content>
    </Container>
  )
}

export default ContentCard
