import styled from 'styled-components'

import { resetButton } from 'Components/Styles'

export const FavoriteButton = styled.button`
  ${resetButton}

  :enabled {
    cursor: pointer;
  }
`
